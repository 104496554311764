<template>
  <div class="body">
    <div class="page-name">{{ htmlPage() }}</div>
    <v-wait for="loading">
      <template slot="waiting">
        <div>
          <div class="wrap-loading"></div>
          <div class="v-spinner">
            <div class="loadingio-spinner-rolling-2n8ectvu1hk"><div class="ldio-gf8wf4cp9ou">
              <div></div>
            </div></div>
          </div>
        </div>
      </template>
    </v-wait>
    <router-view></router-view>
  </div>
</template>
<script>
  import { mapState } from 'vuex'
  export default {
    data: function () {
      return {
        HTML_NO: HTML_NO
      }
    },
    created() {

    },
    computed: {
      ...mapState('consultantUser', ['user', 'project']),
      ...mapState('project', ['newStep'])
    },
    mounted() {
      // this.$wait.start('loading')
    },
    methods: {
      htmlPage() {
        let html = ''
        switch(this.$route.name) {
          case 'LoginConsultant':
            html = ''
            break;
          case 'LoginSales':
            html = '1-1.html'
            break;
          case 'Login':
            html = '2-1.html'
            break;
          default:
        }

        if (!HTML_NO) {
          html = ''
        }
        return html
      }
    },
    beforeCreate() {
      // this.$store.dispatch('AUTH_TOKEN')
    },
    beforeRouteEnter(to, from, next) {
      // console.log('****')
    },
    // when route changes and this component is already rendered,
    // the logic will be slightly different.
    beforeRouteUpdate(to, from, next) {
      // console.log('&&&&')
    }
  }
</script>
<style lang="scss" scoped>

</style>
