var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("header-component"),
      _vm._v(" "),
      _vm.errors.length === 0
        ? _c("div", [
            _vm.csvFile && _vm.csvFile.name
              ? _c("div", { staticClass: "top_box_4_2" }, [
                  _c("div", { staticClass: "content_box_4_2" }, [
                    _c("p", [
                      _vm._v("以下のファイルをアップロードしてよろしいですか？")
                    ]),
                    _vm._v(" "),
                    _vm._m(0),
                    _vm._v(" "),
                    _c("p", { staticClass: "content_box_4_2_p" }, [
                      _vm._v(_vm._s(_vm.csvFile.name))
                    ])
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "footer_4_2" }, [
              _c("p", { staticClass: "btn_1" }, [
                _c("input", {
                  staticClass: "form_box_sub form_box_sub_4_2",
                  attrs: { type: "submit", value: "ファイルを送信する" },
                  on: {
                    click: function($event) {
                      return _vm.uploadCsv()
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _vm._m(1)
            ])
          ])
        : _c("div", [
            _c("div", { staticClass: "top_box_4_2" }, [
              _c(
                "div",
                { staticClass: "content_box_4_2 content_box_4_3" },
                [
                  _vm._m(2),
                  _vm._v(" "),
                  _vm._l(_vm.errors, function(error) {
                    return _c("div", { staticClass: "attention_box" }, [
                      _c("p", { staticClass: "attention" }, [
                        _vm._v(_vm._s(error))
                      ])
                    ])
                  })
                ],
                2
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "footer_4_2" }, [
              _c("p", { staticClass: "btn btn_2_5 btn_3_3 btn_4_1 btn_4_3" }, [
                _c("input", {
                  ref: "csvFile",
                  staticClass: "form_box_sub",
                  attrs: { size: "60", id: "03", type: "file", accept: ".csv" },
                  on: { change: _vm.changeFile }
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: "03" } }, [
                  _vm._v("ファイルを再アップロード")
                ])
              ])
            ])
          ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "content_img_box_4_2" }, [
      _c("img", {
        attrs: {
          src: "/images/up_exel.png",
          alt: "アップロードファイル",
          srcset:
            "/images/up_exel.png" + " 1x, " + "/images/up_exel@2x.png" + " 2x"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "btn_2" }, [
      _c("input", { attrs: { type: "file", id: "02", name: "03" } }),
      _c("label", { attrs: { for: "02", id: "input-label" } }, [
        _vm._v("ファイルを変更する")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("ファイルに以下のエラーが見られました。"),
      _c("br"),
      _vm._v("\n          ご確認の上、再アップロードをお願いします。")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }