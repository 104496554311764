<template>
  <div>
    <div class="top_box_4_1">
      <!--<div class="page-name">{{ htmlPage() }}</div>-->
      <div class="top_box_child_4_1">
        <p>アップロードが必要な資料は以下になります。</p>
        <div v-if="(project.project_type >=1 && project.project_type <=6) || (project.project_type >=11 && project.project_type <=16 && project.attribute_setting_type == 1)">
          <div class="fx_se">
            <div>
              <div class="top_img_box_child_4_1">
                <img src="images/atari2.jpg" alt="対象者リスト画像" width="358" height="199">
              </div>
              <p class="top_box_child_4_1_p">対象者リスト</p>
            </div>
          </div>
        </div>
        <div v-if="project.project_type >=11 && project.project_type <=16 && project.attribute_setting_type != 1">
          <div class="fx_se" v-if="project.progress_status == '2.1'">
            <div>
              <div class="top_img_box_child_4_4 fin_up">
                <img src="images/atari1.jpg" alt="区分コード画像" width="352" height="155" class="top_4_4_o">
              </div>
              <p class="top_box_child_4_1_p top_4_4_o">①属性一覧の設定</p>
            </div>
            <div>
              <div class="top_img_box_child_4_1">
                <img src="images/atari2.jpg" alt="対象者リスト画像" width="358" height="199">
              </div>
              <p class="top_box_child_4_1_p">②対象者リスト</p>
            </div>
          </div>
          <div class="fx_se" v-else>
            <div>
              <div class="top_img_box_child_4_1">
                <img src="images/atari1.jpg" alt="区分コード画像" width="352" height="155">
              </div>
              <p class="top_box_child_4_1_p">&#9312;属性一覧の設定</p>
            </div>
            <div>
              <div class="top_img_box_child_4_1">
                <img src="images/atari2.jpg" alt="対象者リスト画像" width="358" height="199">
              </div>
              <p class="top_box_child_4_1_p">&#9313;対象者リスト</p>
            </div>
          </div>
        </div>
        <div v-if="project.project_type >=21 && project.project_type <=24">
          <div class="fx_se">

            <div>
              <div class="top_img_box_child_4_1">
                <img src="images/atari2.jpg" alt="対象者リスト画像" width="358" height="199">
              </div>
              <p class="top_box_child_4_1_p">対象者リスト</p>
            </div>
          </div>
        </div>
      </div>
      <p class="top_box_4_1_p" v-if="project.project_type >=11 && project.project_type <=16 && project.attribute_setting_type != 1">「{{ project.progress_status == 2.1 ? '対象者リスト' : '属性一覧の設定' }}」を入力→アップロードしてください</p>
      <p class="top_box_4_1_p" v-else>「対象者リスト」を入力→アップロードしてください</p>
      <div class="top_img_box_4_1 fx_se">
        <div>
          <p><img src="images/up_1.png" alt="フォーマットをダウンロード" srcset="images/up_1.png 1x, images/up_1@2x.png 2x"></p>
          <!--<a class="no-a" href="/files/excels/個人診断_対象者リスト.xlsx" download v-if="(project.project_type >=1 && project.project_type <=6) || (project.project_type >=11 && project.project_type <=16 && project.attribute_setting_type == 1)">フォーマットをダウンロード</a>-->
          <!--<a class="no-a" href="/files/excels/組織診断_属性一覧.xlsx" download v-if="project.project_type >=11 && project.project_type <=16 && project.attribute_setting_type == 2 && project.progress_status == 2.0">フォーマットをダウンロード</a>-->
          <!--<a class="no-a" href="/files/excels/組織診断_対象者リスト.xlsx" download v-if="project.project_type >=11 && project.project_type <=16 && project.progress_status == 2.1">フォーマットをダウンロード</a>-->
          <!--<a class="no-a" href="/files/excels/多面診断_多面組合せ表.xlsx" download v-if="project.project_type >=21 && project.project_type <=24">フォーマットをダウンロード</a>-->

          <div class="no-a" v-if="(project.project_type >=1 && project.project_type <=6) || (project.project_type >=11 && project.project_type <=16 && project.attribute_setting_type == 1)">フォーマットをダウンロード</div>
          <div class="no-a" v-if="project.project_type >=11 && project.project_type <=16 && project.attribute_setting_type == 2 && project.progress_status == 2.0">フォーマットをダウンロード</div>
          <div class="no-a" v-if="project.project_type >=11 && project.project_type <=16 && project.progress_status == 2.1">フォーマットをダウンロード</div>
          <div class="no-a" v-if="project.project_type >=21 && project.project_type <=24">フォーマットをダウンロード</div>
        </div>
        <div>
          <p class="arrow"><img src="images/up_2.png" alt="デスクトップで入力" srcset="images/up_2.png 1x, images/up_2@2x.png 2x">
          </p>
          <p>デスクトップで入力</p>
        </div>
        <div>
          <p class="arrow"><img src="images/up_3.png" alt="ファイルをアップロード" srcset="images/up_3.png 1x, images/up_3@2x.png 2x">
          </p>
          <p>ファイルをアップロード</p>
        </div>
      </div>
    </div>

    <div class="footer_box_4_1" v-if="(project.project_type >=1 && project.project_type <=6) || (project.project_type >=11 && project.project_type <=16 && project.attribute_setting_type == 1)">
      <div class="fx_se">
        <div>
          <p v-if="project.project_type >=1 && project.project_type <=6" class="btn-s btn_2_5 btn_3_3 btn_4_1 btn_4_1_blue cur_pointer"><a :href="link_download_ppt_invidual+ project.id">「対象者リスト」フォーマットを<br>ダウンロード</a></p>
          <p v-else class="btn-s btn_2_5 btn_3_3 btn_4_1 btn_4_1_blue cur_pointer"><a :href="link_download_ptp+ project.id">「対象者リスト」フォーマットを<br>ダウンロード</a></p>
          <!--<p v-else :class="{ 'btn-s btn_2_5 btn_3_3 btn_4_1 btn_4_1_blue cur_pointer': true, 'disabled': invalidDeadline }">「対象者リスト」フォーマットを<br>ダウンロード</p>-->
          <p class="footer_box_4_1_d">
            <a href="javascript:;" @click="openTutorial()"><img src="images/file_d_2.png" alt="入力の仕方を見る" srcset="images/file_d_2.png 1x, images/file_d_2@2x.png 2x">
            入力の仕方を見る</a></p>
        </div>
        <div>
          <p :class="{ 'btn-s btn_2_5 btn_3_3 btn_4_1 cur_pointer': true, 'disabled': invalidDeadline }">
            <input v-if="!invalidDeadline" type="file" ref="file" @change="uploadPTP()" id="input-invidual" name="02" >
            <input v-else type="file" ref="file" :disabled="true" id="input-invidual" name="02" >
            <label for="input-invidual" class="cur_pointer">「対象者リスト」ファイルを<br>アップロード</label>
          </p>
          <p class="footer_box_4_1_u">アップロード期日 {{ deadlineSubmit | dateFormat}}</p>
          <p class="footer_box_4_1_u_2">この画面を終了して、後でアップロードする場合<br>は、再度ログインしてください。</p>
        </div>
      </div>
    </div>

    <div class="footer_box_4_1" v-if="project.project_type >=21 && project.project_type <=24">
      <div class="fx_se">
        <div>
          <p v-if="" class="btn-s btn_2_5 btn_3_3 btn_4_1 btn_4_1_blue cur_pointer"><a download :href="link_download_pmf + project.id">「対象者リスト」フォーマットを<br>ダウンロード</a></p>
          <!--<p v-else :class="{ 'btn-s btn_2_5 btn_3_3 btn_4_1 btn_4_1_blue cur_pointer': true, 'disabled': invalidDeadline }">「対象者リスト」フォーマットを<br>ダウンロード</p>-->
          <p class="footer_box_4_1_d"><a href="javascript:;" @click="openTutorial()"><img src="images/file_d_2.png" alt="入力の仕方を見る" srcset="images/file_d_2.png 1x, images/file_d_2@2x.png 2x">
            入力の仕方を見る</a></p>
        </div>
        <div>
          <p :class="{ 'btn-s btn_2_5 btn_3_3 btn_4_1 cur_pointer': true, 'disabled': invalidDeadline }">
            <input v-if="!invalidDeadline" type="file" ref="file" v-on:change="uploadPMF()" id="02" name="02" >
            <input v-else type="file" ref="file" :disabled="true" id="02" name="02" >
            <label for="02" class="cur_pointer" id="input-label">「対象者リスト」ファイルを<br>アップロード</label>
          </p>
          <p class="footer_box_4_1_u">アップロード期日 {{ deadlineSubmit | dateFormat}}</p>
          <p class="footer_box_4_1_u_2">この画面を終了して、後でアップロードする場合<br>は、再度ログインしてください。</p>
        </div>
      </div>
    </div>

    <div class="footer_box_4_1" v-if="project.project_type >=11 && project.project_type <=16 && project.attribute_setting_type != 1">
      <div class="fx_se">
        <div>
          <p v-if="" class="btn-s btn_2_5 btn_3_3 btn_4_1 btn_4_1_blue cur_pointer">
            <!--<a @click="project.progress_status == 2.1 ? downloadPTP() : download()">「対象者リスト」フォーマットを<br>ダウンロード</a>-->
            <a v-if="project.progress_status == 2.1" download :href="link_download_ptp + project.id">「対象者リスト」フォーマットを<br>ダウンロード</a>
            <a v-else download :href="link_download_pai">「属性一覧の設定」フォーマットを<br>ダウンロード</a>
          </p>

          <!--<p v-else :class="{ 'btn-s btn_2_5 btn_3_3 btn_4_1 btn_4_1_blue cur_pointer': true, 'disabled': invalidDeadline }">-->
            <!--&lt;!&ndash;<a @click="project.progress_status == 2.1 ? downloadPTP() : download()">「対象者リスト」フォーマットを<br>ダウンロード</a>&ndash;&gt;-->
            <!--<a v-if="project.progress_status == 2.1" href="javascipt:;">「対象者リスト」フォーマットを<br>ダウンロード</a>-->
            <!--<a v-else href="javascipt:;">「属性一覧の設定」フォーマットを<br>ダウンロード</a>-->
          <!--</p>-->


          <p class="footer_box_4_1_d"><a href="javascript:;" @click="openTutorial()"><img src="images/file_d_2.png" alt="入力の仕方を見る" srcset="images/file_d_2.png 1x, images/file_d_2@2x.png 2x">
            入力の仕方を見る</a></p>
        </div>
        <div>
          <p :class="{ 'btn-s btn_2_5 btn_3_3 btn_4_1 cur_pointer': true, 'disabled': invalidDeadline }">
            <input v-if="!invalidDeadline" type="file" ref="file" v-on:change="project.progress_status == 2.1 ? uploadPTP() : upload()" id="02" name="02" >
            <input v-else type="file" ref="file" :disabled="true" id="02" name="02" >
            <label v-if="project.progress_status == 2.1" for="02" class="cur_pointer" id="input-label">「対象者リスト」ファイルを<br>アップロード</label>
            <label v-else for="02" class="cur_pointer" id="input-label">「属性一覧の設定」ファイルを<br>アップロード</label>
          </p>
          <p class="footer_box_4_1_u">アップロード期日 {{ deadlineSubmit | dateFormat}}</p>
          <p class="footer_box_4_1_u_2">この画面を終了して、後でアップロードする場合<br>は、再度ログインしてください。</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {mapState} from 'vuex'
  import moment from 'moment'

  export default {
    data() {
      return {
    	  link_download_pai: '/api/admin/pai-download',
        link_download_ptp: '/api/admin/ptp-export/',
        link_download_pmf: '/api/admin/pmf-export/',
        link_download_ppt_invidual: '/api/admin/ppt-download/',
        HTML_NO: HTML_NO,
        deadlineSubmit: null,
        invalidDeadline: false
      }
    },
    computed: {
    	...mapState('consultantUser', ['project', 'holidays']),
    	...mapState('project', ['newStep']),
    },
    watch:{
      'project.web_start': function() {
        this.getDeadlineSubmit()
      },
      'holidays': function () {
        this.getDeadlineSubmit()
        this.invalidDeadline = moment().diff(this.deadlineSubmit, 'minutes') > 0 && !this.project.is_deadline_disabled
      }
    },
    methods: {
      openTutorial() {
        this.$emit('openTutorial', true)
      },
      download(){
        this.$store.dispatch('uploadFile/DOWNLOAD', {url: this.link_download_pai, filename: '組織診断_対象者リスト.xlsx'});
      },
      upload(){
        this.$store.dispatch('uploadFile/FILE_UPLOADED', this.$refs.file.files[0]);
        this.$store.dispatch('project/NEW_STEP', 3);
      },
      downloadPTP(){
        this.$store.dispatch('uploadFile/DOWNLOAD', {url: this.link_download_ptp, filename: '組織診断_対象者リスト.xlsx'});
      },
      uploadPTP(){
        this.$store.dispatch('uploadFile/FILE_UPLOADED', this.$refs.file.files[0]);
        this.$store.dispatch('project/NEW_STEP', 3);
      },
      uploadPMF(){
          this.$store.dispatch('uploadFile/FILE_UPLOADED', this.$refs.file.files[0]);
          this.$store.dispatch('project/NEW_STEP', 3);
      },
      getDates(startDate, stopDate) {
        var dateArray = [];
        var currentDate = moment(startDate);
        var stopDate = moment(stopDate);
        while (currentDate <= stopDate) {
          dateArray.push( moment(currentDate).format('YYYY-MM-DD') )
          currentDate = moment(currentDate).add(1, 'days');
        }
        return dateArray;
      },
      getDeadlineSubmit() {
        let fiveDate = this.project.web_start
        let fiveDate2 = null
        if (this.project.web_start) {
          let days = this.getDates(moment(this.project.web_start).subtract(30, 'days').format('YYYY-MM-DD'), moment(this.project.web_start).subtract(1, 'days').format('YYYY-MM-DD'))
          days.sort(function (a, b) {
            return new Date(b) - new Date(a);
          });
          let i = 1
          _.forEach(days, (d) => {
            if (!this.holidays.includes(d) && i <= 5) {
              fiveDate = d
              i = i + 1
            }
          })

          fiveDate = moment(fiveDate).subtract(1, 'days').format('YYYY-MM-DD')
          while (this.holidays.includes(fiveDate)) {
            fiveDate = moment(fiveDate).subtract(1, 'days').format('YYYY-MM-DD')
          }
        }
        if (this.project.medium_type == 2 || this.project.medium_type == 3) {
          let infDate = this.project.paper_preferred_date

          if (infDate) {
            let paper_people = this.project.paper_people
            let date_medium2 = ''
            switch (true) {
              case (paper_people > 0 && paper_people <= 100):
                date_medium2 = 7
                break
              case (paper_people > 100 && paper_people <= 200):
                date_medium2 = 8
                break
              case (paper_people > 200 && paper_people <= 300):
                date_medium2 = 9
                break
              case (paper_people > 300 && paper_people <= 400):
                date_medium2 = 10
                break
              case (paper_people > 400 && paper_people <= 500):
                date_medium2 = 11
                break
              case (paper_people > 500):
                date_medium2 = 12
                break
              default:
                break
            }
            let days2 = this.getDates(moment(infDate).subtract(30, 'days').format('YYYY-MM-DD'), infDate)
            days2.sort(function(a,b){
              return new Date(b) - new Date(a);
            });
            let i = 0
            _.forEach(days2, (d) => {
              if (!this.holidays.includes(d) && i <= date_medium2) {
                fiveDate2 = d
                i = i + 1
              }
            })
          }
        }

        let deadline = ''
        if (moment(fiveDate).isValid() && moment(fiveDate2).isValid()) {
          if (fiveDate < fiveDate2) {
            deadline = fiveDate
          } else {
            deadline = fiveDate2
          }
        } else if (!moment(fiveDate).isValid() && moment(fiveDate2).isValid()) {
          deadline = fiveDate2
        } else if (moment(fiveDate).isValid() &&  !moment(fiveDate2).isValid()) {
          deadline = fiveDate
        }
        this.deadlineSubmit = moment(deadline).isValid() ? moment(deadline).format('YYYY-MM-DD 23:59') : ''

      },
    },
    mounted() {
      if (this.project.id) {
        this.getDeadlineSubmit()
        this.invalidDeadline = moment().diff(this.deadlineSubmit, 'minutes') > 0 && !this.project.is_deadline_disabled
      }
    }
  }
</script>

<style scoped lang="scss">
	.cur_pointer{
		cursor: pointer;
	}
  .no-a {
    text-decoration: none;
    text-align: center;
    font-size: 16px;
    margin-top: 8px;
    color: #4e4e4e;
    display: block;
  }
</style>
