var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "body_1_2 container" },
    [
      !_vm.$route.params.id || (_vm.$route.params.id && _vm.sale.id)
        ? _c("header-component", {
            attrs: { title: "[営業登録]", back: { name: "SalesList" } }
          })
        : _c("header-component", { attrs: { title: "[営業登録]" } }),
      _vm._v(" "),
      !_vm.$route.params.id ||
      (_vm.$route.params.id && _vm.sale.id && _vm.user.id)
        ? _c("ValidationObserver", {
            ref: "observer",
            attrs: { tag: "form" },
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.previewRegister()
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(ref) {
                    var invalid = ref.invalid
                    return [
                      _c("div", { staticClass: "form_box" }, [
                        _c("div", { staticClass: "form_box_title" }, [
                          _c("p", [_vm._v("営業登録")])
                        ]),
                        _vm._v(" "),
                        _c("dl", [
                          _c("dt", { staticClass: "required required81" }, [
                            _vm._v("ログインID")
                          ]),
                          _vm._v(" "),
                          _c(
                            "dd",
                            [
                              _c("ValidationProvider", {
                                staticClass: "validate",
                                attrs: {
                                  name: "id_login",
                                  rules: {
                                    required: true,
                                    uniqueID: true,
                                    regex: /^([a-zA-Z0-9_-]){5,32}$/
                                  }
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.sale.id_login,
                                                expression: "sale.id_login"
                                              }
                                            ],
                                            class: {
                                              form_text: true,
                                              error: errors[0]
                                            },
                                            attrs: {
                                              type: "text",
                                              placeholder: "ログインID",
                                              name: "id_login"
                                            },
                                            domProps: {
                                              value: _vm.sale.id_login
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.sale,
                                                  "id_login",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("br", {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: errors[0],
                                                expression: "errors[0]"
                                              }
                                            ]
                                          }),
                                          _c("span", { staticClass: "error" }, [
                                            _vm._v(_vm._s(errors[0]))
                                          ])
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("dl", [
                          _c("dt", { staticClass: "required required1" }, [
                            _vm._v("氏名")
                          ]),
                          _vm._v(" "),
                          _c(
                            "dd",
                            [
                              _c("ValidationProvider", {
                                staticClass: "validate",
                                attrs: {
                                  name: "consultant_name",
                                  rules: "required"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.sale.name,
                                                expression: "sale.name"
                                              }
                                            ],
                                            class: {
                                              form_text: true,
                                              error: errors[0]
                                            },
                                            attrs: {
                                              type: "text",
                                              placeholder: "氏名",
                                              name: "name"
                                            },
                                            domProps: { value: _vm.sale.name },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.sale,
                                                  "name",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("br", {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: errors[0],
                                                expression: "errors[0]"
                                              }
                                            ]
                                          }),
                                          _c("span", { staticClass: "error" }, [
                                            _vm._v(_vm._s(errors[0]))
                                          ])
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("dl", [
                          _c("dt", { staticClass: "required required81" }, [
                            _vm._v("メールアドレス")
                          ]),
                          _vm._v(" "),
                          _c(
                            "dd",
                            [
                              _c("ValidationProvider", {
                                staticClass: "validate",
                                attrs: {
                                  name: "emailSales",
                                  rules: "required|email"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.sale.email,
                                                expression: "sale.email"
                                              }
                                            ],
                                            class: {
                                              form_text: true,
                                              error: errors[0]
                                            },
                                            attrs: {
                                              type: "text",
                                              placeholder: "メールアドレス",
                                              name: "emailSales"
                                            },
                                            domProps: { value: _vm.sale.email },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.sale,
                                                  "email",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("br", {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: errors[0],
                                                expression: "errors[0]"
                                              }
                                            ]
                                          }),
                                          _c("span", { staticClass: "error" }, [
                                            _vm._v(_vm._s(errors[0]))
                                          ])
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("dl", [
                          _c("dt", { staticClass: "required required81" }, [
                            _vm._v("パスワード")
                          ]),
                          _vm._v(" "),
                          _c(
                            "dd",
                            [
                              _c("ValidationProvider", {
                                staticClass: "validate",
                                attrs: {
                                  mode: _vm.submit ? "eager" : "passive",
                                  name: "password",
                                  rules: !_vm.sale.id ? "required" : ""
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.sale.password,
                                                expression: "sale.password"
                                              }
                                            ],
                                            class: {
                                              form_text: true,
                                              error: errors[0]
                                            },
                                            attrs: {
                                              type: "password",
                                              placeholder: _vm.sale.id
                                                ? "******"
                                                : "パスワード",
                                              name: "name"
                                            },
                                            domProps: {
                                              value: _vm.sale.password
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.sale,
                                                  "password",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("br", {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: errors[0],
                                                expression: "errors[0]"
                                              }
                                            ]
                                          }),
                                          _c("span", { staticClass: "error" }, [
                                            _vm._v(_vm._s(errors[0]))
                                          ])
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("dl", [
                          _c("dt", { staticClass: "required required1" }, [
                            _vm._v("営業所")
                          ]),
                          _vm._v(" "),
                          _c(
                            "dd",
                            [
                              _c("ValidationProvider", {
                                staticClass: "validate",
                                attrs: { name: "office", rules: "required" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.sale.office,
                                                expression: "sale.office"
                                              }
                                            ],
                                            class: {
                                              form_text: true,
                                              error: errors[0]
                                            },
                                            attrs: {
                                              type: "text",
                                              placeholder: "営業所",
                                              name: "office"
                                            },
                                            domProps: {
                                              value: _vm.sale.office
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.sale,
                                                  "office",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("br", {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: errors[0],
                                                expression: "errors[0]"
                                              }
                                            ]
                                          }),
                                          _c("span", { staticClass: "error" }, [
                                            _vm._v(_vm._s(errors[0]))
                                          ])
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            ],
                            1
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "footer_box footer_box_1_3" }, [
                        _c("p", { staticClass: "btn2" }, [
                          _c("input", {
                            staticClass: "form_box_sub_blue form_box_sub_2",
                            attrs: { type: "button", value: "前の画面に戻る" },
                            on: {
                              click: function($event) {
                                return _vm.backList()
                              }
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "btn3" }, [
                          _c("input", {
                            staticClass: "form_box_sub form_box_sub_2",
                            attrs: { type: "submit", value: "保存" }
                          })
                        ])
                      ])
                    ]
                  }
                }
              ],
              null,
              false,
              587300576
            )
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }