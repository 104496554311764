<template>
  <div>
    <div v-if="user && user.id">
      <div class="top_1_2" v-if="!(user.type === USER_CONSULTANT)">
        <div class="fx_ce">
          <img src="/images/1_1_logo.png" srcset="/images/1_1_logo.png 1x, /images/1_1_logo@2x.png 2x"
               alt="BCon">
          <h1>{{ title }}</h1>
        </div>
        <router-link
          v-if="(project.progress_status == 2 || project.progress_status == 2.1 || project.progress_status == 3.0) && (newStep == 2 || newStep == 2.1 || newStep == 3) && $route.name !== 'ApplyPreview'"
          :to="{ name: 'ApplyPreview' }" class="top_btn top_btn_4_1">申込内容確認
        </router-link>
        <p id="over">
          <a href="javascript:;" :class="hover ? 'link_1_under' : 'link_1'" @mouseover="mouseOver">
            <img src="/images/2_3_top.png" alt="ログイン"
                 srcset="/images/2_3_top.png 1x, /images/2_3_top@2x.png 2x" class="login_parson">
            <span v-if="user && user.name">{{ user.name }}</span>
          </a>
          <span id="off" :class="{'hover_text': true, 'no' : !hover}"><a href="javascript:;" @click="logout"
                                                                         class="link_2">ログアウト</a></span>
        </p>
      </div>
      <div :style="{width: (!margin ? 'auto': '1000px')}" class="top_1_2" v-else>
        <div class="fx_ce" v-if="back">
          <p class="top_6_2">
            <router-link :to="back ? back : {name: 'ProjectList'}">&lt; リスト一覧</router-link>
            <!--<router-link :to="{name: 'ProjectList'}">&lt; リスト一覧</router-link>-->
          </p>
        </div>
        <!--<div v-if="title && !back" class="fx_ce">-->
          <router-link v-if="title" class="fx_ce" :to="back !== null ? back: {name: 'ProjectList'}">
            <img src="/images/1_1_logo.png" srcset="/images/1_1_logo.png 1x, /images/1_1_logo@2x.png 2x" alt="BCon"><h1>{{ title }}</h1></router-link>
        <!--</div>-->
        <div v-if="back == null && !title">
          <nav class="navbar navbar-expand-lg navbar-light bg-light" style="margin-bottom: 30px;">
            <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
              <div class="navbar-nav" style="font-size: 14px;">
                <router-link class="nav-item nav-link" :to="{ name: 'ConsultantUserList'}">管理者</router-link> |
                <router-link class="nav-item nav-link" :to="{ name: 'SalesList'}">営業一覧</router-link>
                <!--<a class="nav-item nav-link" href="javascript:;">カレンダー(エクスポート)</a> |-->
                <!--<a class="nav-item nav-link" href="javascript:;"><input class="form_box_sub form_box_sub_4_2" type="submit" value="カレンダー(インポート)" @click="uploadCsv()"></a>-->
              </div>
            </div>
          </nav>
        </div>
        <p id="over" style=""><a href="javascript:;" :class="hover ? 'link_1_under' : 'link_1'"
                                 @mouseover="mouseOver"><img src="/images_2/1_2_top.png" alt="ログイン"
                                                             srcset="/images_2/1_2_top.png 1x, /images_2/1_2_top@2x.png 2x"
                                                             class="login_parson"><span v-if="user && user.name">{{ user.name }}</span>
        </a>
          <span id="off" :class="{'hover_text': true, 'no' : !hover}"><a href="javascript:;" @click="logout"
                                                                         class="link_2">ログアウト</a></span></p>
      </div>
    </div>

  </div>
</template>
<script>
  import {mapState} from 'vuex'
  import {USER_CONSULTANT, USER_PROJECT, USER_SALES} from './../contstant'

  export default {
    data() {
      return {
        hover: false,
        logoutTo: 'Login',
        USER_PROJECT: USER_PROJECT,
        USER_CONSULTANT: USER_CONSULTANT
      }
    },
    props: {
      title: String,
      default: null,
      back: {
        type: Object,
        default: null
      },
      margin: true
    },
    computed: {
      ...mapState('consultantUser', ['user', 'project']),
      ...mapState('project', ['newStep', 'query'])
    },
    methods: {
      logout() {
        if (this.user.type === USER_CONSULTANT) {
          this.logoutTo = 'LoginConsultant'
        } else if (this.user.type === USER_SALES) {
          this.logoutTo = 'LoginSales'
        }
        this.$store.dispatch('consultantUser/AUTH_LOGOUT').then(() => {
        }).catch(e => {

        })
      },
      mouseOver() {
        this.hover = !this.hover
      }
    }
  }
</script>
<style lang="scss" scoped>
  .navbar {
    padding: 0;
    .nav-link {
      background-color: #fff !important;
      font-size: 20px;
      color: #2b3295;
      padding: 0 15px;
      &:first-child {
        padding-left: 0;
      }
    }
  }
  .form_box_sub_4_2 {
    padding: 0 10px;
  }
  .fx_ce {
    &.router-link-active {
      text-decoration: none;
      color: #4e4e4e;
    }
  }
  .collapse:not(.show) {
    display: block;
  }
  .navbar-nav {
    flex-direction: row;
  }
</style>
