<template>
    <div id="body6_1">
      <header-component :margin="false"></header-component>
      <div v-if="user && user.id" :style="cssProps">
        <div data-v-55dc702e="" class="attention">
          <p data-v-55dc702e="" v-for="error in holidayErrors">{{ error }}</p>
        </div>
        <div class="fx_st">
          <p class="save_btn2 no-margin"><a :href="'javascript:;'" @click="exportHolidayCsv()">カレンダー(エクスポート)</a></p>
          <p class="save_btn2 no-margin"><label for="csv">カレンダー(インポート)</label><input ref="holiday" id="csv" type="file"
                                                                                     value="" @change="holidayImport()">
          </p>
        </div>
        <div id="title6_1" class="fx_st">
          <p>
            <!--<span>2020年x月x日 00:00</span>-->
            <br>案件リスト</p>
          <p class="save_btn2"><a :href="'javascript:;'" @click="exportCsv()">CSV出力</a></p>
        </div>
        <!--{{ // toValues(projects.data) }}-->
        <div class="box" style="position: relative">
          <div id="vertical_scrolling_div" ref="table">
            <div id="freeze_container">
              <table id="left_table" class="freeze_table" :style="{marginTop: scrollTopY}">
                <tbody>
                <tr class="tblTitle1" :style="tblTitleF">
                  <th
                  :class="{ 'th_1 t_width_1 th_yaji index': true, 'down':  sortType == 'desc' && sortName == 'id' && sortName == 'id', 'up':  sortType == 'asc' && sortName == 'id' && sortName == 'id' }"
                  width="40px" @click="changeSort('id')">No
                  </th>
                  <th
                  :class="{ 'th_2 th_yaji t_width_2 index': true, 'down':  sortType == 'desc' && sortName == 'client_name', 'up':  sortType == 'asc' && sortName == 'client_name' }"
                  width="170px" @click="changeSort('client_name')">クライアント名
                  </th>
                  <th
                  :class="{ 'th_3 th_yaji t_width_3 index': true, 'down':  sortType == 'desc' && sortName == 'project_type', 'up':  sortType == 'asc' && sortName == 'project_type' }"
                  width="146px" @click="changeSort('project_type')">診断名
                  </th>
                </tr>
                <tr v-for="(p, i) in projects.data" v-show="projects.data && projects.data.length > 0">
                  <td class="th_1_2 t_width_1 white">{{ p.id }}</td>
                  <td class="th_yaji t_width_2 white">
                    <router-link :to="{ name: 'ProjectDetail', params: { id: p.id }  }">{{ p.client_name }}
                    </router-link>
                  </td>
                  <td class="t_width_3 white">{{ projectType(p.project_type) }}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div id="horizontal_scrolling_div" ref="inner_table">
              <table id="inner_table">
                <tr class="tblTitle2" :style="tblTitle">

                  <th ref="head"
                      :class="{ 'th_17 th_yaji t_width_4': true, 'down':  sortType == 'desc' && sortName == 'staff_name', 'up':  sortType == 'asc' && sortName == 'staff_name' }"
                      @click="changeSort('staff_name')">担当営業
                  </th>
                  <th
                    :class="{ 'th_4 th_yaji t_width_4': true, 'down':  sortType == 'desc' && sortName == 'medium_type', 'up':  sortType == 'asc' && sortName == 'medium_type' }"
                    @click="changeSort('medium_type')">形式 (人数)
                  </th>
                  <th
                    :class="{ 'th_5 th_yaji t_width_5': true, 'down':  sortType == 'desc' && sortName == 'progress_status', 'up':  sortType == 'asc' && sortName == 'progress_status' }"
                    @click="changeSort('progress_status')">ステータス
                  </th>
                  <th
                    :class="{ 'th_6 th_yaji t_width_5': true, 'down':  sortType == 'desc' && sortName == 'preentry_alert_date', 'up':  sortType == 'asc' && sortName == 'preentry_alert_date' }"
                    @click="changeSort('preentry_alert_date')">促進メール<br>(開始日)
                  </th>
                  <th
                    :class="{ 'th_7 th_yaji t_width_5': true, 'down':  sortType == 'desc' && sortName == 'web_start', 'up':  sortType == 'asc' && sortName == 'web_start' }"
                    @click="changeSort('web_start')">Web回答<br>開始日時
                  </th>
                  <th
                    :class="{ 'th_8 th_yaji t_width_5 no-wrap': true, 'down':  sortType == 'desc' && sortName == 'web_end', 'up':  sortType == 'asc' && sortName == 'web_end' }"
                    @click="changeSort('web_end')">Web回答<br>終了日時
                  </th>
                  <th :class="{ 'th_9 th_yaji t_width_5 no-wrap': true }">質問紙到着<br>希望日</th>
                  <th
                    :class="{ 'th_10 th_yaji t_width_5': true, 'down':  sortType == 'desc' && sortName == 'paper_bcon_receipt_date', 'up':  sortType == 'asc' && sortName == 'paper_bcon_receipt_date' }"
                    @click="changeSort('paper_bcon_receipt_date')">質問紙BCon<br>到着日
                  </th>
                  <th
                    :class="{ 'th_11 th_yaji t_width_5': true, 'down':  sortType == 'desc' && sortName == 'report_preferred_date', 'up':  sortType == 'asc' && sortName == 'report_preferred_date' }"
                    @click="changeSort('report_preferred_date')">納品希望日
                  </th>
                  <th :class="{ 'th_12 th_yaji t_width_5': true, 'down':  sortType == 'desc' && sortName == 'submit_deadline', 'up':  sortType == 'asc' && sortName == 'submit_deadline' }"
                      @click="changeSort('submit_deadline')">対象者リスト提出締切日</th>
                  <th
                    :class="{ 'th_13 th_yaji t_width_5': true, 'down':  sortType == 'desc' && sortName == 'postentry_alert_date', 'up':  sortType == 'asc' && sortName == 'postentry_alert_date' }"
                    @click="changeSort('postentry_alert_date')">促進メール<br>(リスト)
                  </th>
                  <th
                    :class="{ 'th_14 th_yaji t_width_6': true, 'down':  sortType == 'desc' && sortName == 'target_employee_file_name', 'up':  sortType == 'asc' && sortName == 'target_employee_file_name' }"
                    @click="changeSort('target_employee_file_name')">リスト
                  </th>
                  <!--<th :class="{ 'th_14 th_yaji_2 t_width_6': true, 'down':  sortType == 'desc' && sortName == 'target_employee_file_name', 'up':  sortType == 'asc' && sortName == 'target_employee_file_name' }" @click="changeSort('target_employee_file_name')">リスト</th>-->
                  <th :class="{ 'th_15 t_width_7': true }">キャンセル</th>
                  <!--<th-->
                    <!--:class="{ 'th_16 th_yaji t_width_8': true, 'down':  sortType == 'desc' && sortName == 'finished_c_user_id', 'up':  sortType == 'asc' && sortName == 'finished_c_user_id' }"-->
                    <!--@click="changeSort('finished_c_user_id')">完了-->
                  <!--</th>-->
                </tr>
                <tbody>
                <tr v-for="(p, i) in projects.data" v-show="projects.data && projects.data.length > 0">
                  <td class="t_width_4 th_center no-wrap">{{ p.staff_name }}</td>
                  <td class="t_width_4">
                    <div v-if="p.medium_type == 1">
                      <span>Web {{ p.web_people ? p.web_people+'名' : '' }}</span>
                    </div>
                    <div v-if="p.medium_type == 2">
                      <span>質問紙 {{ p.paper_people ? p.paper_people+'名' : '' }}</span>
                    </div>
                    <div v-if="p.medium_type == 3">
                      <span v-if="p.web_people">Web {{ p.web_people ? p.web_people+'名' : '' }}<br></span>
                      <span v-if="p.paper_people">質問紙 {{ p.paper_people ? p.paper_people+'名' : '' }}</span>
                    </div>

                  </td>
                  <td class="t_width_5 th_center"><p :class="'sta_'+(parseInt(p.progress_status) + 1)"><span
                    v-html="projectStatusNumber(p.progress_status)"></span> {{
                    projectStatus(p.progress_status) }}</p></td>
                  <td :class="{ 't_width_5': true, 'th_no': !p.preentry_alert_date } ">{{ p.preentry_alert_date |
                    dateFormat }}<span v-if="p.preentry_alert_date"> （済）</span>
                  </td>
                  <td class="t_width_5 no-wrap"><span v-html="datetimeFormat(p.web_start)"></span></td>
                  <td class="t_width_5 no-wrap"><span v-html="datetimeFormat(p.web_end)"></span></td>
                  <td class="t_width_5 th_naname"></td>
                  <td :class="{'t_width_5': true, 'th_naname': p.medium_type == 1 }">{{
                    p.paper_bcon_receipt_date | dateFormat}}
                  </td>
                  <td class="t_width_5">{{ p.report_preferred_date | dateFormat}}</td>
                  <td class="t_width_5">{{ p.submit_deadline }}</td>
                  <td :class="{ 't_width_5': true, 'th_no': !p.postentry_alert_date } ">{{ p.postentry_alert_date |
                    dateFormat }} <span v-if="p.postentry_alert_date"> （済）</span>
                  </td>
                  <td :class="{'t_width_6': true, 'th_maru': p.file} "></td>
                  <td class="t_width_7 th_center"><p class="d_btn"><a href="javascript:;"
                                                                      @click="openDelete(p.id, p.client_name)">削除</a></p></td>
                  <!--<td :class="{'t_width_8 th_centr th_bottom': true, 'th_check': p.progress_status == 4 }">-->
                    <!--{{ p.progress_status == 4 ? p.consultant_user && p.consultant_user.name ? '(' +-->
                    <!--dateFormat(p.finished_at) + ')' : '' : '' }}-->
                  <!--</td>-->
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>


        <!--<div id="table6_1" class="scroll_div">-->
        <!--<div id="table-scroll" class="table-scroll">-->
        <!--<table id="main-table" class="main-table">-->
        <!--<thead>-->
        <!--<tr>-->
        <!--<th-->
        <!--:class="{ 'th_1 t_width_1 th_yaji index': true, 'down':  sortType == 'desc' && sortName == 'id' && sortName == 'id', 'up':  sortType == 'asc' && sortName == 'id' && sortName == 'id' }"-->
        <!--width="40px" @click="changeSort('id')">No-->
        <!--</th>-->
        <!--<th-->
        <!--:class="{ 'th_2 th_yaji t_width_2 index': true, 'down':  sortType == 'desc' && sortName == 'client_name', 'up':  sortType == 'asc' && sortName == 'client_name' }"-->
        <!--width="170px" @click="changeSort('client_name')">クライアント名-->
        <!--</th>-->
        <!--<th-->
        <!--:class="{ 'th_3 th_yaji t_width_3 index': true, 'down':  sortType == 'desc' && sortName == 'project_type', 'up':  sortType == 'asc' && sortName == 'project_type' }"-->
        <!--width="146px" @click="changeSort('project_type')">診断名-->
        <!--</th>-->
        <!--<th ref="head"-->
        <!--:class="{ 'th_17 th_yaji t_width_4': true, 'down':  sortType == 'desc' && sortName == 'staff_name', 'up':  sortType == 'asc' && sortName == 'staff_name' }"-->
        <!--@click="changeSort('staff_name')">担当営業-->
        <!--</th>-->
        <!--<th-->
        <!--:class="{ 'th_4 th_yaji t_width_4': true, 'down':  sortType == 'desc' && sortName == 'medium_type', 'up':  sortType == 'asc' && sortName == 'medium_type' }"-->
        <!--@click="changeSort('medium_type')">形式 (人数)-->
        <!--</th>-->
        <!--<th-->
        <!--:class="{ 'th_5 th_yaji t_width_5': true, 'down':  sortType == 'desc' && sortName == 'progress_status', 'up':  sortType == 'asc' && sortName == 'progress_status' }"-->
        <!--@click="changeSort('progress_status')">ステータス-->
        <!--</th>-->
        <!--<th :class="{ 'th_6 th_yaji t_width_5': true }">促進メール<br>(開始日)</th>-->
        <!--<th-->
        <!--:class="{ 'th_7 th_yaji t_width_5': true, 'down':  sortType == 'desc' && sortName == 'web_start', 'up':  sortType == 'asc' && sortName == 'web_start' }"-->
        <!--@click="changeSort('web_start')">Web回答<br>開始日時-->
        <!--</th>-->
        <!--<th-->
        <!--:class="{ 'th_8 th_yaji t_width_5': true, 'down':  sortType == 'desc' && sortName == 'web_end', 'up':  sortType == 'asc' && sortName == 'web_end' }"-->
        <!--@click="changeSort('web_end')">Web回答<br>終了日時-->
        <!--</th>-->
        <!--<th :class="{ 'th_9 th_yaji t_width_5': true }">質問紙到着<br>希望日</th>-->
        <!--<th :class="{ 'th_10 th_yaji t_width_5': true }">質問紙BCon<br>到着日</th>-->
        <!--<th-->
        <!--:class="{ 'th_11 th_yaji t_width_5': true, 'down':  sortType == 'desc' && sortName == 'report_preferred_date', 'up':  sortType == 'asc' && sortName == 'report_preferred_date' }"-->
        <!--@click="changeSort('report_preferred_date')">納品希望日-->
        <!--</th>-->
        <!--<th :class="{ 'th_12 th_yaji t_width_5': true }">リスト締切日</th>-->
        <!--<th :class="{ 'th_13 th_yaji t_width_5': true }">促進メール<br>(リスト)</th>-->
        <!--<th :class="{ 'th_14 th_yaji_2 t_width_6': true }">リスト</th>-->
        <!--<th :class="{ 'th_15 t_width_7': true }">キャンセル</th>-->
        <!--<th-->
        <!--:class="{ 'th_16 th_yaji t_width_8': true, 'down':  sortType == 'desc' && sortName == 'completed_user_id', 'up':  sortType == 'asc' && sortName == 'completed_user_id' }"-->
        <!--@click="changeSort('completed_user_id')">完了-->
        <!--</th>-->
        <!--</tr>-->
        <!--</thead>-->
        <!--<tbody>-->
        <!--<tr v-for="(p, i) in projects.data" v-show="projects.data && projects.data.length > 0">-->
        <!--<th class="th_1_2 t_width_1 white">{{ p.id }}</th>-->
        <!--<th class="th_yaji t_width_2 white">-->
        <!--<router-link :to="{ name: 'ProjectDetail', params: { id: p.id }  }">{{ p.client_name }}-->
        <!--</router-link>-->
        <!--</th>-->
        <!--<th class="t_width_3 white">{{ projectType(p.project_type) }}</th>-->
        <!--<td class="t_width_4 th_center">{{ p.staff_name }}</td>-->
        <!--<td class="t_width_4">-->
        <!--<div v-if="p.medium_type == 1">-->
        <!--<span>Web({{ p.web_people ? p.web_people : '&#45;&#45;' }})</span>-->
        <!--</div>-->
        <!--<div v-if="p.medium_type == 2">-->
        <!--<span>質問紙({{ p.paper_people ? p.paper_people : '&#45;&#45;' }})</span>-->
        <!--</div>-->
        <!--<div v-if="p.medium_type == 3">-->
        <!--<span>Web({{ p.web_people ? p.web_people : '&#45;&#45;' }}) +<br></span>-->
        <!--<span>質問紙({{ p.paper_people ? p.paper_people : '&#45;&#45;' }})</span>-->
        <!--</div>-->

        <!--</td>-->
        <!--<td class="t_width_5 th_center"><p :class="'sta_'+(parseInt(p.progress_status) + 1)"><span-->
        <!--v-html="projectStatusNumber(p.progress_status)"></span> {{-->
        <!--projectStatus(p.progress_status) }}</p></td>-->
        <!--<td class="t_width_5 th_no"></td>-->
        <!--<td class="t_width_5"><span v-html="datetimeFormat(p.web_start)"></span></td>-->
        <!--<td class="t_width_5"><span v-html="datetimeFormat(p.web_end)"></span></td>-->
        <!--<td class="t_width_5 th_naname"></td>-->
        <!--<td class="t_width_5 th_naname"></td>-->
        <!--<td class="t_width_5">{{ p.report_preferred_date | dateFormat}}</td>-->
        <!--<td class="t_width_5"></td>-->
        <!--<td class="t_width_5 th_no"></td>-->
        <!--<td class="t_width_6 th_maru"></td>-->
        <!--<td class="t_width_7 th_center"><p class="d_btn"><a href="javascript:;"-->
        <!--@click="openDelete(p.id)">削除</a></p></td>-->
        <!--<td :class="{'t_width_8 th_centr th_bottom': true, 'th_check': p.progress_status == 4 }">-->
        <!--{{ p.progress_status == 4 ? p.consultant_user && p.consultant_user.name ? '(' +-->
        <!--p.consultant_user.name + ')' : '' : '' }}-->
        <!--</td>-->
        <!--</tr>-->
        <!--</tbody>-->
        <!--</table>-->
        <!--</div>-->
        <!--</div>-->
        <pagination-component :current-page="projects.current_page"
                              :per-page="projects.per_page"
                              :total="projects.total"
                              @changePage="changePage"
                              v-if="projects.total > 1"
        ></pagination-component>
        <b-modal name="deletePopup" id="deletePopup" ref="deletePopup" :modal-class="['modal-action']" :centered="true"
                 :no-close-on-backdrop="true" header-close-label="" hide-footer hide-header>
          <h5 class="title">{{dName}}名を削除しますか？</h5>
          <div class="modal-footer top-30">
            <b-button class="btn-submit" @click="$bvModal.hide('deletePopup')">キャンセル</b-button>
            <a class="btn btn-red" @click="deleteProject()">削除</a>
          </div>
        </b-modal>
      </div>

    </div>
</template>
<script>
  import {mapActions, mapMutations, mapState} from 'vuex'
  import {MEDIUM_TYPE, PROJECT_STATUS, PROJECT_TYPE} from './../../../contstant'
  import moment from 'moment'

  export default {
    data() {
      return {
        dId: null,
        dName: null,
        page: 1,
        sortName: '',
        sortType: '',
        medium_types: MEDIUM_TYPE,
        href: '',
        holidayErrors: [],
        head: 0,
        tblTitle: {
          position: 'absolute',
          top: 0,
          // width: 'calc(100% - 360px)',
          // overflow: 'hidden'
        },
        tblTitleF: {
          position: 'absolute',
          top: 0
        },
        scrollLeft: 0,
        scrollTopY: 0,
      }
    },
    computed: {
      ...mapState('project', ['projects']),
      ...mapState('consultantUser', ['user']),
      cssProps() {
        let head = 0
        this.$nextTick(() => {
          head = this.$refs.hea !== undefined ? this.$refs.head.clientHeight + 'px' : '0'
        })
        return {
          '--table-head': this.head,
        }
      },
    },
    methods: {
      ...mapMutations('project', ['LIST_PROJECTS']),
      ...mapActions('project', ['GET_LIST_PROJECTS']),
      isEmpty(val) {
        return _.isEmpty(val)
      },
      projectType(project_type) {
        let type = ''
        if (PROJECT_TYPE[0][project_type]) {
          type = PROJECT_TYPE[0][project_type]
        } else if (PROJECT_TYPE[1][project_type]) {
          type = PROJECT_TYPE[1][project_type]
        }
        return type
      },
      projectStatus(status) {
        let s = parseInt(status)
        return PROJECT_STATUS[s] ? PROJECT_STATUS[s] : ''
      },
      projectStatusNumber(status) {
        let text = ''
        let newStatus = parseInt(status) + 1
        switch (newStatus) {
          case 1:
              text = '&#9312;'
              break
          case 2:
              text = '&#9313;'
              break
          case 3:
              text = '&#9314;'
              break
          case 4:
              text = '&#9315;'
              break
          case 5:
            text = '&#9316;'
            break
          default:
          }
          return text
        },
        datetimeFormat(date) {
          const day = moment(date).isValid() ? moment(date).format("YYYY年MM月DD日") : ''
          const time = moment(date).isValid() ? moment(date).format("HH:mm") : ''
          return day + '<br>' + time
        },
      dateFormat(date) {
        return moment(date).isValid() ? moment(date).format("YYYY年MM月DD日") : ''
      },
      changePage(page) {
        this.page = page;
        this.refreshList(true);
      },
      changeSort(columnName){
    	  this.sortName = columnName;
          this.sortType = this.sortType == '' ? 'desc' : this.sortType == 'desc' && this.sortName == columnName ? 'asc' : 'desc';

    	  this.refreshList(true);
    	  this.$forceUpdate()
      },
      refreshList(sort = false){
        let query = {};
        if(this.page){
          query['page'] = this.page;
        };
        if(this.sortName){
          query['sortName'] = this.sortName;
          query['sortType'] = this.sortType;
        }
        if (sort) {
            this.$router.push({name: 'ProjectList', query: query});
        }
        this.$store.dispatch('project/PROJECT_QUERY', query)
		  this.GET_LIST_PROJECTS({query : query});
      },
      openDelete(id, name) {
        this.$refs['deletePopup'].show()
        this.dId = id
        this.dName = name
      },
      deleteProject() {
        this.$store.dispatch('project/DELETE_PROJECT', this.dId).then(() => {
        	this.refreshList();
        	this.$refs.deletePopup.hide()
        })
        // this.$refs['deletePopup'].hide()
      },
      exportCsv() {
        axios({
          url: '/api/admin/projects/export-csv',
          method: 'GET',
          responseType: 'blob', // important
        }).then((response) => {
          // const url = window.URL.createObjectURL(new Blob([response.data]));
          // const link = document.createElement('a');
          // link.href = url;
          // link.setAttribute('download', '企業診断_案件リスト.csv');
          // document.body.appendChild(link);
          // link.click();
          let filename = "企業診断_案件リスト.csv";
          // var data = "some data";
          let blob = new Blob([response.data], { type: 'text/csv' });
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, filename);
          }
          else {
            var elem = window.document.createElement('a');
            elem.href = window.URL.createObjectURL(blob);
            elem.download = filename;
            document.body.appendChild(elem);
            elem.click();
            document.body.removeChild(elem);
          }

        });
        // axios.get('/api/admin/sales/export-csv')
        // this.href = '/api/admin/sales/export-csv?'
      },
      exportHolidayCsv() {
        axios({
          url: '/api/admin/calendar/export-csv',
          method: 'GET',
          responseType: 'blob', // important
        }).then((response) => {

          let filename = "営業日.csv";
          // var data = "some data";
          let blob = new Blob([response.data], { type: 'text/csv' });
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, filename);
          }
          else {
            var elem = window.document.createElement('a');
            elem.href = window.URL.createObjectURL(blob);
            elem.download = filename;
            document.body.appendChild(elem);
            elem.click();
            document.body.removeChild(elem);
          }

          // const url = window.URL.createObjectURL(new Blob([response.data]));
          // const link = document.createElement('a');
          // link.href = url;
          // link.setAttribute('download', '営業日.csv');
          // document.body.appendChild(link);
          // link.click();
        });
      },
      async holidayImport() {
        const csv = new FormData();
        csv.append("csv", this.$refs.holiday.files[0]);

        this.$wait.start('loading');
        try {
          let {data} = await axios.post('/api/admin/calendar/import-csv',
            csv, {
              headers: {
                'Content-Type': 'boundary=${csv._boundary}'
              }
            });
          this.$refs.holiday.value = null
          this.$wait.end('loading');
        } catch (e) {
          this.holidayErrors = e.response.data.message
          this.$wait.end('loading');
          this.$refs.holiday.value = null
        }
      },
      scrollMe(e) {
        const el = document.querySelector('#inner_table');
        let coordinate = el.getBoundingClientRect()
        this.scrollLeft = 420 - coordinate.left
        this.scrollTopY = coordinate.top - (290 - document.documentElement.scrollTop) + 'px'
        console.log(document.documentElement.scrollTop)
        let left = 370 - this.scrollLeft
        this.tblTitle.left = left + 'px'
        this.$forceUpdate()
      },
      scrollHorizontal() {
        this.tblTitle.position = 'absolute'
      }
    },
    created() {
      this.$forceUpdate()
      this.page = this.$route.query.page ? this.$route.query.page : 1;
      this.sortName = this.$route.query.sortName  ? this.$route.query.sortName : '';
      this.sortType = this.$route.query.sortType  ? this.$route.query.sortType : '';

      this.refreshList();
    },
    mounted() {
      this.projectType()
    },
    updated() {
      // console.log('&&&&&&')
      this.$nextTick(() => {
        this.$refs.inner_table.addEventListener('scroll', this.scrollMe)
        this.$refs.table.addEventListener('scroll', this.scrollHorizontal)
        this.head = this.$refs.head !== undefined ? this.$refs.head.clientHeight + 'px' : '0'
        // this.$forceUpdate()
        // console.log(this.$refs.head)
      })
    }

  }
</script>
<style scoped lang="scss">
  .container {
    padding-top: 30px;
  }

  .thead-light th {
    color: #fff;
    vertical-align: middle;
  }

  .delete {
    background: rgb(240, 38, 27);
    color: #fff;
    text-align: center;
    font-size: 11px;
  }

  /*.table-scroll {*/
  /*!*position: relative;*!*/
  /*!*width: 100%;*!*/
  /*z-index: 1;*/
  /*margin: auto;*/
  /*overflow: auto;*/
  /*!*height: 350px;*!*/
  /*}*/

  /*.table-scroll table {*/
  /*width: 100%;*/
  /*margin: auto;*/
  /*border-collapse: separate; !* Don't collapse *!*/
  /*border-spacing: 0;*/
  /*}*/

  /*table td {*/
  /*border-bottom: 1px solid;*/
  /*border-right: 1px solid;*/
  /*}*/

  /*.table-scroll thead th {*/
  /*color: #fff;*/
  /*overflow: hidden;*/
  /*border-top: 1px solid #707070;*/
  /*border-bottom: 1px solid #707070;*/
  /*border-right: 1px solid #707070;*/
  /*}*/

  /*th:nth-child(3) {*/
  /*word-wrap: break-word !important;*/
  /*white-space: normal !important;*/
  /*}*/

  /*table {*/
  /*table-layout: fixed;*/
  /*word-wrap: break-word;*/
  /*width: 1857px;*/
  /*border-collapse: separate;*/
  /*border-spacing: 0;*/
  /*}*/

  /*.white {*/
  /*background-color: #fff;*/
  /*}*/

  /*.index {*/
  /*z-index: 14 !important;*/

  /*&.t_width_2 {*/
  /*border-right: 0 !important;*/
  /*}*/

  /*&.t_width_1 {*/
  /*border-right: 0 !important;*/
  /*}*/
  /*}*/

  .save_btn2 {
    &.no-margin {
      margin-right: 10px !important;
      margin-bottom: 20px !important;
    }

    label {
      text-decoration: none;
      color: #fff;
      font-size: 18px;
      border-radius: 5px;
      padding: 7px 24px 7px 50px;
      background: linear-gradient(to bottom, #d5d5d5, #717171);
      display: inline;
    }

    input {
      /*display: none*/
      opacity: 0;
    }
  }

  .attention {
    margin: 0;
    margin-bottom: 15px;
    width: 100%;
  }

  /*!*@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {*!*/
  /*.table-scroll {*/
  /*margin-left: 356px;*/
  /*overflow-x: scroll;*/
  /*overflow-y: visible;*/
  /*}*/

  /*.scroll_div {*/
      /*position: relative;*/
  /*}*/

  /*.t_width_1 {*/
  /*height: 64px;*/
  /*left: 0px;*/
  /*position: absolute;*/
  /*top: auto;*/
  /*justify-content: center;*/
  /*border-left: 1px solid #707070;*/
  /*border-bottom: 1px solid #707070;*/
  /*display: flex;*/
  /*align-items: center;*/
  /*&.index {*/
  /*justify-content: flex-start;*/
  /*}*/
  /*}*/
  /*.t_width_2 {*/
  /*height: 64px;*/
  /*left: 40px;*/
  /*position: absolute;*/
  /*top: auto;*/
  /*!*border: 1px solid #707070;*!*/
  /*display: flex;*/
  /*align-items: center;*/
  /*border-left: 1px solid #707070;*/
  /*border-bottom: 1px solid #707070;*/
  /*}*/
  /*.t_width_3 {*/
  /*height: 64px;*/
  /*left: 210px;*/
  /*position: absolute;*/
  /*top: auto;*/
  /*!*border: 1px solid #707070;*!*/
  /*display: flex;*/
  /*align-items: center;*/
  /*border-left: 1px solid #707070;*/
  /*border-right: 1px solid #707070;*/
  /*border-bottom: 1px solid #707070;*/
  /*}*/


  .tblTitle {
    /*position:absolute;*/
    /*top:0px;*/
    /*margin-bottom:30px;*/
    /*background:lightblue;*/
  }

  #freeze_container th, #inner_table th {
    height: 62px;
    vertical-align: middle;
    color: #fff;
    font-size: 12px;
    border: 1px solid #707070;
  }

  #inner_table td, #freeze_container td {
    height: 64px;
    border: 1px solid #707070;
    vertical-align: middle;
    font-size: 12px;
  }

  #freeze_container td, #freeze_container th {
    /*padding: 5px;*/
    /*height:40px;*/
    /*width:40px;*/
    font-size: 12px;
    border: 1px solid #707070;
  }

  #vertical_scrolling_div {
    /*display:inline-block;*/
    /*zoom: 1;*/
    /**display:inline;*/
    /*padding-top:40px;*/
    max-height: calc(100vh - 350px);
    /*overflow-y: scroll;*/
    /*overflow-x: hidden;*/
  }

  #freeze_container {
    max-height: calc(100vh - 350px);
    /*display: inline-block;*/
    float: left;
    /*zoom: 1;*/
    /**display: inline;*/
    vertical-align: top;
    width: 370px;
    margin-top: 62px;
  }

  #horizontal_scrolling_div {
    float: left;
    /*display: inline-block;*/
    /*zoom: 1;*/
    *display: inline;
    width: calc(100% - 370px);
      overflow-x: scroll;
    vertical-align: top;
    margin-top: 62px;
    max-height: calc(100vh - 350px);
    }


  .freeze_table {
    background-color: #0099dd;
    z-index: 2;
    }

  #left_table {
    width: 356px;
  }

  #inner_table {
    width: 1501px;
    /*overflow: hidden;*/
    }

  .tblTitle1 {
    z-index: 10;
    }

  .tblTitle2 {
    z-index: 1;
    }

  .box {
    overflow: hidden;
    margin-top: 30px;
  }

  .white {
    background-color: #fff !important;
  }

  .no-wrap {
    white-space: nowrap;
    word-break: break-all;
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    /*-webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);*/
  }
</style>
