<template>
  <div class="body_1_2">
    <ValidationObserver ref="observer" v-show="!is_preview && project.progress_status == 0" v-slot="{ invalid }"
                        tag="form" @submit.prevent="agree ? saveProject() : ''">
      <div class="form_box form_box_2_3">
        <div class="form_box_title">
          <p>申込内容入力</p>
        </div>
        <dl class="">
          <dt class="required required10 height_20">組織名（会社名）</dt>
          <dd class="margin_top_10">
            <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="client_name" rules="required|max:50"
                                v-slot="{ errors }" class="validate">
              <input :class="{'form_text': true, 'error': errors[0] }" name="client_name" placeholder="" type="text" maxlength="50"
                     v-model="project.client_name">
              <br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>
            </ValidationProvider>
          </dd>
        </dl>
        <dl>
          <dt class="height_21">所属・役職</dt>
          <dd>
            <div class="fx_ce">
              <!--<ValidationProvider name="division" rules="required" v-slot="{ errors }" class="validate">-->
                <p class="position">所属
                  <input :class="{'form_text': true }" type="text" name="division" value="○○○部" maxlength="20"
                         v-model="user.division">
                  <!--<br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>-->
                </p>
              <!--</ValidationProvider>-->
              <!--<ValidationProvider name="position" rules="required" v-slot="{ errors }" class="validate">-->
                <p class="position">役職
                  <input :class="{'form_text': true }" type="text" name="position" value="部長" maxlength="20"
                         v-model="user.position">
                  <!--<br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>-->
                </p>
              <!--</ValidationProvider>-->
            </div>
          </dd>
        </dl>
        <dl>
          <dt class="required required8 height_22 top">氏名</dt>
          <dd class="margin_top_10">
            <ValidationProvider name="username" rules="required|max:20" v-slot="{ errors }" class="validate">
              <input :class="{'form_text': true, 'error': errors[0] }" type="text" name="name" value="田中一郎" maxlength="20"
                     v-model="user.name">
              <br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>
            </ValidationProvider>
          </dd>
        </dl>
        <dl class="">
          <dt class="required required7 top">電話番号</dt>
          <dd class="">
            <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="client_tel" :rules="{required: true, numeric: true, regex: /^[0-9a-zA-Z]*$/}"
                                v-slot="{ errors }" class="validate">
              <input :class="{'form_text tel_number': true, 'error': errors[0] }" name="client_tel"
                     placeholder="例：0332873421" type="tel" pattern="[0-9]+" maxlength="11"
                     v-model="project.client_tel"
                     onKeyUp="$(this).val($(this).val().replace(/[^\d]/ig, ''))"
                     autocomplete="none"
                     @keypress="preventAlphabetInput($event)"
              >
              <!--@keyup="prevent2byte($event)"-->
              <br><span>※半角数字のみ入力してください。</span>
              <br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>
            </ValidationProvider>
          </dd>
        </dl>
        <dl class="">
          <dt class="required required8 form_box_2_3_dt top">住所<br>
            <!--<span class="f12">レポート・報告書の納品先になります。</span>-->
          </dt>
          <dd class="margin_dd">
            <p>
              <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="zip_code" :rules="{required: true, numeric: true, regex: /^[0-9]*$/}"
                                  v-slot="{ errors }" class="validate label_p">
                〒<input :class="{ 'form_text form_address': true, 'error': errors[0] }" name="zip_code"
                        placeholder="例：1000005" type="tel" pattern="[0-9]+" maxlength="11"
                        v-model="project.zip_code"
                        autocomplete="none"
                        onKeyUp="$(this).val($(this).val().replace(/[^\d]/ig, ''))"
              >
                <input type="button" class="input_button" @click="searchAddress()" value="住所検索"><span
                class=""></span><br>
                <span>※半角数字のみでハイフンを入れずに入力してください。</span>
              </ValidationProvider>
            </p>
            <div style="color: #DB484A;" v-if="searchAddErr"><span class="error">{{ searchAddErr }}</span></div>
            <p class="">
              <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="address1" rules="required"
                                  v-slot="{ errors }" class="validate">
                都道府県
                <input :class="{ 'form_text form_address_1': true, 'error': errors[0] }" v-model="project.address1" maxlength="10"
                       placeholder="例：東京都" type="text">
                <br v-show="errors[0]"><span class="error left-150" v-show="errors[0]">{{ errors[0] }}</span>
              </ValidationProvider>
            </p>

            <p class="">
              <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="address2" rules="required"
                                  v-slot="{ errors }" class="validate">
                市区町村番地
                <input :class="{ 'form_text form_address_2': true, 'error': errors[0] }" v-model="project.address2" maxlength="50"
                       placeholder="例：千代田区丸の内　1-7-12"
                       type="text">
                <br v-show="errors[0]"><span class="error left-150" v-show="errors[0]">{{ errors[0] }}</span>
              </ValidationProvider>
            </p>

            <p class="">
                <span class="validate">
                  ビル名
                  <input class="form_text form_address_3" v-model="project.address3" placeholder="例：サピアタワー　18F" maxlength="50"
                         type="text">
                </span>
            </p>
          </dd>
        </dl>
        <dl class="radio_2">
          <dt class="required required9 form_box_2_3_dt_1 top">実施方法</dt>
          <dd>
            <p class="margin_dd">
              <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="medium_type" rules="required"
                                  v-slot="{ errors }" class="validate">
                <input :class="{ 'form_radio': true, 'error': errors[0] }" id="medium_type1" name="medium_type"
                       type="radio" value="1" @change="changeMedium()"
                       v-model="project.medium_type">
                <label class="form-check-label" for="medium_type1">Web</label>
                <input v-if="project.project_type !== 5" :class="{ 'form_radio': true, 'error': errors[0] }" id="medium_type2" name="medium_type"
                       type="radio" value="2" @change="changeMedium()"
                       v-model="project.medium_type">
                <label v-if="project.project_type !== 5" class="form-check-label" for="medium_type2">質問紙</label>
                <input v-if="project.project_type !== 5" :class="{ 'form_radio': true, 'error': errors[0] }" id="medium_type3" name="medium_type"
                       type="radio" value="3" @change="changeMedium()"
                       v-model="project.medium_type">
                <label v-if="project.project_type !== 5" class="form-check-label" for="medium_type3">Web + 質問紙</label>
                <br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>
              </ValidationProvider>
            </p>
            <div class="charge_box">
              <ValidationProvider ref="web_start" v-if="project.medium_type == 1" :mode="agree ? 'eager' : 'passive'" name="web_people" :rules="{max_value: 1000, required: isRequired}" v-slot="{ errors }"
                                  class="validate no-padding">
                <p id="web_box">
                  <input :class="{ 'form_text form_radio_text web_box_text': true, 'error': errors[0] }" name="web_people"
                         onKeyUp="$(this).val($(this).val().replace(/[^\d]/ig, ''))"
                         @keypress="preventAlphabetInput($event)" type="tel" pattern="[0-9]+" maxlength="4"
                         v-model="project.web_people" @input="webPeople"
                         autocomplete="none"
                  > 名<br><span>※想定人数で構いません。</span>
                  <br><span>※半角数字のみ入力してください。</span>
                  <br v-show="errors[0]"><span v-show="errors[0]" class="error">{{ errors[0] }}</span>
                </p>
              </ValidationProvider>
              <ValidationProvider ref="web_start" v-if="project.medium_type == 2" :mode="agree ? 'eager' : 'passive'" name="paper_people" :rules="{max_value: 1000, required: isRequired}" v-slot="{errors }"
                                  class="validate no-padding">
                <p id="web_box1">
                  <input :class="{ 'form_text form_radio_text web_box_text': true, 'error': errors[0] }" name="paper_people"
                         onKeyUp="$(this).val($(this).val().replace(/[^\d]/ig, ''))"
                         @keypress="preventAlphabetInput($event)" type="tel" pattern="[0-9]+" maxlength="4"
                         autocomplete="none"
                         v-model="project.paper_people" @input="paperPeople"> 名<br><span>※想定人数で構いません。</span><br><span>※半角数字のみ入力してください。</span>
                  <br v-show="errors[0]"><span v-show="errors[0]" class="error">{{ errors[0] }}</span>
                </p>
              </ValidationProvider>
              <ValidationProvider ref="web_start" v-if="project.medium_type == 3" :mode="agree ? 'eager' : 'passive'" name="web_people" :rules="{max_value: 1000, required: isRequired}" v-slot="{ errors }"
                                  class="validate no-padding">
                <p id="web_box2">　　Web

                  <input :class="{ 'form_text form_radio_text web_box_text': true, 'error': errors[0] }" name="web_people"
                         onKeyUp="$(this).val($(this).val().replace(/[^\d]/ig, ''))"
                         @keypress="preventAlphabetInput($event)" type="tel" pattern="[0-9]+" maxlength="4"
                         autocomplete="none"
                         v-model="project.web_people" @input="webPeople"> 名
                  <br><span>※想定人数で構いません。</span>
                  <br><span>※半角数字のみ入力してください。</span>
                  <br v-show="errors[0]"><span v-show="errors[0]" class="error">{{ errors[0] }}</span>
                </p>
              </ValidationProvider>
              <ValidationProvider ref="web_start2" v-if="project.medium_type == 3" :mode="agree ? 'eager' : 'passive'" name="paper_people" :rules="{max_value: 1000, required: isRequired}" v-slot="{errors }"
                                  class="validate no-padding">
              <p id="web_box3">　　質問紙
                <input :class="{ 'form_text form_radio_text web_box_text': true, 'error': errors[0] }" name="paper_people"
                       onKeyUp="$(this).val($(this).val().replace(/[^\d]/ig, ''))"
                       @keypress="preventAlphabetInput($event)" type="tel" pattern="[0-9]+" maxlength="4"
                       autocomplete="none"
                       v-model="project.paper_people" @input="paperPeople"> 名
                <br><span>※半角数字のみ入力してください。</span>
                <br v-show="errors[0]"><span v-show="errors[0]" class="error">{{ errors[0] }}</span>
              </p>
              </ValidationProvider>
            </div>
          </dd>
        </dl>
        <dl class="radio_2" v-if="((project.medium_type == 2 || project.medium_type == 3))">
          <dt class="required required3">質問紙の送付先拠点数</dt>
          <dd>
            <ValidationProvider style="display: flex" :mode="agree ? 'eager' : 'passive'" name="paper_send_type" rules="required"
                                v-slot="{ errors }">
              <input :class="{ 'form_radio': true, 'error': errors[0] }" id="paper_send_type1" name="paper_send_type"
                     type="radio" value="1"
                     v-model="project.paper_send_type">
              <label class="form-check-label" for="paper_send_type1">1拠点</label>
              <input v-if="!((project.project_type >= 1 && project.project_type <= 6) || (project.project_type >= 21 && project.project_type <= 24)) " :class="{ 'form_radio': true, 'error': errors[0] }" id="paper_send_type2" name="paper_send_type"
                     type="radio" value="2"
                     v-model="project.paper_send_type">
              <label v-if="!((project.project_type >= 1 && project.project_type <= 6) || (project.project_type >= 21 && project.project_type <= 24)) "  class="form-check-label" for="paper_send_type2">2拠点</label>
              <input v-if="!((project.project_type >= 1 && project.project_type <= 6) || (project.project_type >= 21 && project.project_type <= 24)) "  :class="{ 'form_radio': true, 'error': errors[0] }" id="paper_send_type3" name="paper_send_type"
                     type="radio" value="3"
                     v-model="project.paper_send_type">
              <label v-if="!((project.project_type >= 1 && project.project_type <= 6) || (project.project_type >= 21 && project.project_type <= 24)) "  class="form-check-label" for="paper_send_type3">3拠点以上(日程は目安となります。)<br>※３拠点以上の場合は申込完了後に担当者からご連絡致します。</label>
              <br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>
            </ValidationProvider>
          </dd>
        </dl>

        <dl class="" v-if="project.paper_send_type == 1 || project.paper_send_type == 2">
          <dt class="required required81 form_box_2_3_dt2 top">質問紙の宛先&#9312;</dt>
          <dd class="margin_dd">
            <span class="margin_l_2_3_3">※納品先と質問紙の納付先が異なる場合は、入力し直してください。</span>
            <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="paper1_division" rules="required"
                                v-slot="{ errors }" class="validate">
              <p>所属<input :class="{ 'form_text tel_number': true, 'error': errors[0] }" type="text" name="paper1_division" placeholder="○○○部"
                          v-model="project.paper1_division"></p>
            </ValidationProvider>
            <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="paper1_position"
                                v-slot="{ errors }" class="validate">
              <p>役職<input :class="{ 'form_text tel_number': true, 'error': errors[0] }" type="text" name="paper1_position" placeholder="部長"
                          v-model="project.paper1_position"></p>
            </ValidationProvider>
            <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="paper1_name" rules="required"
                                v-slot="{ errors }" class="validate">
              <p>氏名<input :class="{ 'form_text tel_number': true, 'error': errors[0] }" type="text" name="paper1_name" placeholder="田中一郎"
                          v-model="project.paper1_name"></p>
            </ValidationProvider>
            <p>
              <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="paper1_zip_code" rules="required"
                                  v-slot="{ errors }" class="validate">
                〒<input :class="{ 'form_text form_address': true, 'error': errors[0] }" name="paper1_zip_code"
                        placeholder="例：1000005" type="tel" pattern="[0-9]+" maxlength="11"
                        v-model="project.paper1_zip_code"
                        autocomplete="none"
                        onKeyUp="$(this).val($(this).val().replace(/[^\d]/ig, ''))">
                <input type="button" class="input_button" @click="searchAddressPaper()" value="住所検索"><span
                class=""></span><br>
                <span>※半角数字のみでハイフンを入れずに入力してください。</span>
              </ValidationProvider>
            </p>
            <div style="color: #DB484A;" v-if="searchAddErr1"><span class="error">{{ searchAddErr1 }}</span></div>
            <p class="">
              <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="paper1_address1" rules="required"
                                  v-slot="{ errors }" class="validate">
                都道府県
                <input :class="{ 'form_text form_address_1': true, 'error': errors[0] }"
                       v-model="project.paper1_address1" maxlength="10"
                       name="paper1_address1"
                       placeholder="例：東京都" type="text">
                <br v-show="errors[0]"><span class="error left-150" v-show="errors[0]">{{ errors[0] }}</span>
              </ValidationProvider>
            </p>

            <p class="">
              <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="paper1_address2" rules="required"
                                  v-slot="{ errors }" class="validate">
                市区町村番地
                <input :class="{ 'form_text form_address_2': true, 'error': errors[0] }" maxlength="50"
                       v-model="project.paper1_address2"
                       name="paper1_address2"
                       placeholder="例：千代田区丸の内　1-7-12"
                       type="text">
                <br v-show="errors[0]"><span class="error left-150" v-show="errors[0]">{{ errors[0] }}</span>
              </ValidationProvider>
            </p>

            <p class="">
              <span class="validate">
                ビル名
                <input class="form_text form_address_3" v-model="project.paper1_address3" placeholder="例：サピアタワー　18F" maxlength="50"
                       type="text">
              </span>
            </p>
            <p v-if="!((project.project_type >= 1 && project.project_type <= 6) || (project.project_type >= 21 && project.project_type <= 24))">部数
              <strong>
                <ValidationProvider ref="paper1_copies" :mode="agree ? 'eager' : 'passive'" name="paper1_copies" rules="required|numeric|max_value:10000"
                                    v-slot="{ errors }" class="validate">
                  <input :class="{ 'form_text form_text_2_3_2': true, 'error': errors[0] }" name="paper1_copies"
                         placeholder=""
                         type="number" max="10000"
                         onKeyUp="$(this).val($(this).val().replace(/[^\d]/ig, ''))"
                         autocomplete="none"
                         @keypress="preventAlphabetInput($event)"
                         v-model="project.paper1_copies"> 部
                  <br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>
                </ValidationProvider>
              </strong>
            </p>
          </dd>
        </dl>

        <dl class="" v-if="project.paper_send_type == 2 && (project.project_type >= 11 && project.project_type <= 16)">
          <dt class="required required81 form_box_2_3_dt2 top">質問紙の宛先&#9313;</dt>
          <dd class="margin_dd">
            <!--<span class="margin_l_2_3_3">※納品先と質問紙の納付先が異なる場合は、入力し直してください。</span>-->
            <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="paper2_division" rules="required"
                                v-slot="{ errors }" class="validate">
              <p>所属<input :class="{ 'form_text tel_number': true, 'error': errors[0] }" type="text" name="paper2_division" placeholder="○○○部"
                          v-model="project.paper2_division"></p>
            </ValidationProvider>
            <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="paper2_position"
                                v-slot="{ errors }" class="validate">
              <p>役職<input :class="{ 'form_text tel_number': true, 'error': errors[0] }" type="text" name="paper2_position" placeholder="部長"
                          v-model="project.paper2_position"></p>
            </ValidationProvider>
            <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="paper2_name" rules="required"
                                v-slot="{ errors }" class="validate">
              <p>氏名<input :class="{ 'form_text tel_number': true, 'error': errors[0] }" type="text" name="paper2_name" placeholder="田中一郎"
                          v-model="project.paper2_name"></p>
            </ValidationProvider>
            <p>
              <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="paper2_zip_code" rules="required"
                                  v-slot="{ errors }" class="validate">
                〒<input :class="{ 'form_text form_address': true, 'error': errors[0] }" name="paper2_zip_code"
                        placeholder="例：1000005" type="tel" pattern="[0-9]+" maxlength="11"
                        v-model="project.paper2_zip_code"
                        autocomplete="none"
                        onKeyUp="$(this).val($(this).val().replace(/[^\d]/ig, ''))">
                <input type="button" class="input_button" @click="searchAddressPaper2()" value="住所検索"><span
                class=""></span><br>
                <span>※半角数字のみでハイフンを入れずに入力してください。</span>
              </ValidationProvider>
            <div style="color: #DB484A;" v-if="searchAddErr2"><span class="error">{{ searchAddErr2 }}</span></div>
            </p>
            <p class="">
              <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="paper2_address1" rules="required"
                                  v-slot="{ errors }" class="validate">
                都道府県
                <input :class="{ 'form_text form_address_1': true, 'error': errors[0] }" maxlength="10"
                       v-model="project.paper2_address1"
                       placeholder="例：東京都" type="text">
                <br v-show="errors[0]"><span class="error left-150" v-show="errors[0]">{{ errors[0] }}</span>
              </ValidationProvider>
            </p>

            <p class="">
              <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="paper2_address2" rules="required"
                                  v-slot="{ errors }" class="validate">
                市区町村番地
                <input :class="{ 'form_text form_address_2': true, 'error': errors[0] }" maxlength="50"
                       v-model="project.paper2_address2"
                       placeholder="例：千代田区丸の内　1-7-12"
                       type="text">
                <br v-show="errors[0]"><span class="error left-150" v-show="errors[0]">{{ errors[0] }}</span>
              </ValidationProvider>
            </p>

            <p class="">
              <span class="validate">
                ビル名
                <input class="form_text form_address_3" v-model="project.paper2_address3" placeholder="例：サピアタワー　18F" maxlength="50"
                       type="text">
              </span>
            </p>
            <p>部数
              <strong>
                <ValidationProvider ref="paper2_copies" :mode="agree ? 'eager' : 'passive'" name="paper2_copies" rules="required|numeric|max_value:10000"
                                    v-slot="{ errors }" class="validate">
                  <input :class="{ 'form_text form_text_2_3_2': true, 'error': errors[0] }" name="paper2_copies"
                         placeholder=""
                         type="number" max="10000"
                         onKeyUp="$(this).val($(this).val().replace(/[^\d]/ig, ''))"
                         autocomplete="none"
                         @keypress="preventAlphabetInput($event)"
                         v-model="project.paper2_copies"> 部
                  <br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>
                </ValidationProvider>
              </strong>
            </p>
          </dd>
        </dl>
        <dl class="fx">
          <dt class="required required10 height_up_2_3_4 top">回答スケジュール
          </dt>
          <dd class="margin_dd position_r_2_3 no-padding">
            <div class="form_box_2_4">
              <p class="form_box_2_4_p form_box_2_3_p margin_t5">
                <span>
                  <span style="margin-left: 0; padding:0"
                        v-if="project.project_type >= 11 && project.project_type <=16">対象者リスト</span><span
                  style="margin-left: 0; padding:0" v-if="project.project_type >= 1 && project.project_type <=6">対象者リスト</span><span
                  style="margin-left: 0; padding:0"
                  v-if="project.project_type >= 21 && project.project_type <=24">多面組み合わせリスト</span>提出締切日　{{ deadlineSubmit }}</span>
              </p>
              <div class="margin_top_2_3">
                <div v-if="project.medium_type == 1">
                  <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="web_start1" rules="required"
                                      v-slot="{ errors }"
                                      class="validate margin_b_2_4 margin_b_2_3 fx_ce">
                    <p>Web回答
                      <br>開始日</p>
                    <div class="input-group stick_2_3 form_sch2_3_4 width-datepicker">
                      <date-picker :disabled="!project.web_people" v-model="project.web_start"
                                   :class="{'form_text form_sch': true, 'error': errors[0] }" name="project.web_start1"
                                   :wrap="true" :config="configs.web_start" @dp-change="onStartChange">
                      </date-picker>
                      <div class="input-group-append cal_icon">
                        <button class="btn datepickerbutton" type="button"></button>
                      </div>
                    </div>
                    <span>※メール配信開始は、11:00～13:00です。</span>
                    <!--<br v-show="errors[0]"><span class="error" v-show="errors[0]">{{errors[0]}}</span>-->
                  </ValidationProvider>
                <!--</div>-->

                <!--<div v-if="project.medium_type == 1">-->
                  <ValidationProvider :disabled="!empty(project.web_start)" :mode="agree ? 'eager' : 'passive'" name="web_end1" :rules="{required: true}"
                                      v-slot="{ errors }"
                                      class="validate margin_b_2_4 margin_b_2_3 fx_ce">
                    <p>Web回答
                      <br>終了日</p>
                    <div class="input-group form_sch2_3_4 width-datepicker">
                      <date-picker :id="'web_end1'" :disabled="!project.web_start" v-model="project.web_end"
                                   :class="{'form_text form_sch': true, 'error': errors[0] }" name="project.web_end1"
                                   :wrap="true" :config="configs.web_end" @dp-change="onEndChange" @dp-update="onNextChange"  @dp-show="onShowChange">
                      </date-picker>
                      <div class="input-group-append cal_icon">
                        <button class="btn datepickerbutton" type="button"></button>
                      </div>
                    </div>
                    <span>※WEB回答終了時刻は、17:00です。</span>
                    <!--<br v-show="errors[0]"><span class="error" v-show="errors[0]">{{errors[0]}}</span>-->
                  </ValidationProvider>
                </div>

                <div v-if="project.medium_type == 2">
                  <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="paper_preferred_date2" rules="required"
                                      v-slot="{ errors }"
                                      class="validate margin_b_2_4 margin_b_2_3 fx_ce">
                    <p>質問紙到着
                      <br>希望日</p>

                    <div class="input-group margin_l_2_5 stick_2_3_4 width-datepicker">
                      <date-picker :disabled="!project.paper_people" v-model="project.paper_preferred_date"
                                   :class="{'form_text form_sch 2': true, 'error': errors[0] }"
                                   name="project.paper_preferred_date2"
                                   :wrap="true" :config="configs.paper_preferred_date"
                                   @dp-change="onPaperPrefererChange" @dp-update="onNextChange"  @dp-show="onShowChange">
                      </date-picker>
                      <div class="input-group-append cal_icon">
                        <button class="btn datepickerbutton" type="button"></button>
                      </div>
                    </div>
                    <!--<br v-show="errors[0]"><span class="error" v-show="errors[0]">{{errors[0]}}</span>-->
                  </ValidationProvider>
                <!--</div>-->

                <!--<div v-if="project.medium_type == 2">-->
                  <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="paper_limit_date2" rules=""
                                      v-slot="{ errors }"
                                      class="validate margin_b_2_4 margin_b_2_3 fx_ce">
                    <p>質問紙提出期限
                      <br>(任意)</p>
                    <div class="input-group margin_l_2_7 stick_2_3_4 width-datepicker">
                      <date-picker :disabled="!project.paper_preferred_date" v-model="project.paper_limit_date"
                                   :class="{'form_text form_sch 2': true }" name="project.paper_limit_date2"
                                   :wrap="true" :config="configs.paper_limit_date" @dp-change="onPaperLimitChange">
                      </date-picker>
                      <div class="input-group-append cal_icon">
                        <button class="btn datepickerbutton" type="button"></button>
                      </div>
                    </div>
                    <p class="no-margin"><img src="images/info.png" srcset="images/info.png 1x, images/info@2x.png 2x"
                                              alt="インフォメーション"
                                              class="info_2" @mouseover="mouseOver" @mouseleave="mouseLeave">
                    <p v-show="fukidashi2" class="info_fukidashi_2">質問紙に職場での回収期限を<br>記載したい場合に入力してください。
                    </p>
                    <!--<br v-show="errors[0]"><span class="error" v-show="errors[0]">{{errors[0]}}</span>-->
                  </ValidationProvider>
                <!--</div>-->

                <!--<div v-if="project.medium_type == 2">-->
                  <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="paper_bcon_receipt_date2" :rules="'required'"
                                      v-slot="{ errors }"
                                      class="validate margin_b_2_4 margin_b_2_3 fx_ce">
                    <p>質問紙BCon
                      <br>到着日</p>
                    <div class="input-group margin_l_2_6 width-datepicker">
                      <date-picker :disabled="!project.paper_preferred_date" v-model="project.paper_bcon_receipt_date"
                                   :class="{'form_text form_sch 2': true, 'error': errors[0] }"
                                   name="project.paper_bcon_receipt_date2"
                                   :wrap="true" :config="configs.paper_bcon_receipt_date"
                                   @dp-change="onPaperBconChange" @dp-update="onNextChange"  @dp-show="onShowChange">
                      </date-picker>
                      <div class="input-group-append cal_icon">
                        <button class="btn datepickerbutton" type="button"></button>
                      </div>
                    </div>
                    <!--<span class="error absolute" v-show="errors[0]">{{errors[0]}}</span>-->
                  </ValidationProvider>
                </div>

                <div v-if="project.medium_type == 3">
                  <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="web_start3" rules="required"
                                      v-slot="{ errors }"
                                      class="validate margin_b_2_4 margin_b_2_3 fx_ce">
                    <p>Web回答
                      <br>開始日</p>

                    <div class="input-group form_sch2_3_4 stick_2_3_4 width-datepicker">
                      <date-picker :disabled="!project.web_people || !project.paper_people" v-model="project.web_start"
                                   :class="{'form_text form_sch': true, 'error': errors[0] }" name="project.web_start3"
                                   :wrap="true" :config="configs.web_start" @dp-change="onStartChange" @dp-update="onNextChange"  @dp-show="onShowChange">
                      </date-picker>
                      <div class="input-group-append cal_icon">
                        <button class="btn datepickerbutton" type="button"></button>
                      </div>
                    </div>
                    <span>※メール配信開始は、11:00～13:00です</span>
                    <!--<br v-show="errors[0]"><span class="error" v-show="errors[0]">{{errors[0]}}</span>-->
                  </ValidationProvider>
                <!--</div>-->

                <!--<div v-if="project.medium_type == 3">-->
                  <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="web_end3" :rules="'required'"
                                      v-slot="{ errors }"
                                      class="validate margin_b_2_4 margin_b_2_3 fx_ce">
                    <p>Web回答
                      <br>終了日</p>
                    <div class="input-group form_sch2_3_4 width-datepicker">
                      <date-picker :disabled="!project.web_start" v-model="project.web_end"
                                   :class="{'form_text form_sch': true, 'error': errors[0] }" name="project.web_end3"
                                   :wrap="true" :config="configs.web_end" @dp-change="onEndChange" @dp-update="onNextChange"  @dp-show="onShowChange">
                      </date-picker>
                      <div class="input-group-append cal_icon">
                        <button class="btn datepickerbutton" type="button"></button>
                      </div>
                    </div>
                    <span>※WEB回答終了時刻は、17:00です。</span>
                    <!--<span class="error absolute" v-show="errors[0]">{{errors[0]}}</span>-->
                  </ValidationProvider>
                <!--</div>-->

                <!--<div v-if="project.medium_type == 3">-->
                  <hr>
                <!--</div>-->

                <!-- 質問紙到着希望日 paper_preferred_date -->
                <!--<div v-if="project.medium_type == 3">-->
                  <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="paper_preferred_date3" rules="required"
                                      v-slot="{ errors }"
                                      class="validate margin_b_2_4 margin_b_2_3 fx_ce">
                    <p>質問紙到着
                      <br>希望日</p>
                    <div class="input-group margin_l_2_5 stick_2_3_4 width-datepicker">
                      <date-picker :disabled="!project.web_people || !project.paper_people"
                                   v-model="project.paper_preferred_date"
                                   :class="{'form_text form_sch': true, 'error': errors[0] }"
                                   name="project.paper_preferred_date3"
                                   :wrap="true" :config="configs.paper_preferred_date"
                                   @dp-change="onPaperPrefererChange" @dp-update="onNextChange"  @dp-show="onShowChange">
                      </date-picker>
                      <div class="input-group-append cal_icon">
                        <button class="btn datepickerbutton" type="button"></button>
                      </div>
                    </div>
                    <!--<br v-show="errors[0]"><span class="error" v-show="errors[0]">{{errors[0]}}</span>-->
                  </ValidationProvider>
                <!--</div>-->

                <!-- 質問紙提出期限 paper_limit_date -->
                <!--<div v-if="project.medium_type == 3">-->
                  <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="paper_limit_date3" rules=""
                                      v-slot="{ errors }"
                                      class="validate margin_b_2_4 margin_b_2_3 fx_ce">
                    <p>質問紙提出期限
                      <br>(任意)</p>
                    <div class="input-group margin_l_2_7 stick_2_3_4 width-datepicker">
                      <date-picker :disabled="!project.paper_preferred_date" v-model="project.paper_limit_date"
                                   :class="{'form_text form_sch': true }" name="project.paper_limit_date3"
                                   :wrap="true" :config="configs.paper_limit_date" @dp-change="onPaperLimitChange" @dp-update="onNextChange"  @dp-show="onShowChange">
                      </date-picker>
                      <div class="input-group-append cal_icon">
                        <button class="btn datepickerbutton" type="button"></button>
                      </div>
                    </div>
                    <p class="no-margin"><img src="images/info.png" srcset="images/info.png 1x, images/info@2x.png 2x"
                                              alt="インフォメーション"
                                              class="info_2" @mouseover="mouseOver" @mouseleave="mouseLeave">
                    <p v-show="fukidashi2" class="info_fukidashi_3">質問紙に職場での回収期限を<br>記載したい場合に入力してください。
                    </p>
                    <!--<br v-show="errors[0]"><span class="error" v-show="errors[0]">{{errors[0]}}</span>-->
                  </ValidationProvider>
                <!--</div>-->

                <!-- 質問紙BCon到着日 paper_bcon_receipt_date -->
                <!--<div v-if="project.medium_type == 3">-->
                  <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="paper_bcon_receipt_date3" :rules="'required'"
                                      v-slot="{ errors }"
                                      class="validate margin_b_2_4 margin_b_2_3 fx_ce position_r_2_3">
                    <p>質問紙BCon
                      <br>到着日</p>
                    <div class="input-group margin_l_2_6 width-datepicker">
                      <date-picker :disabled="!project.paper_preferred_date" v-model="project.paper_bcon_receipt_date"
                                   :class="{'form_text form_sch': true, 'error': errors[0] }"
                                   name="project.paper_bcon_receipt_date3"
                                   :wrap="true" :config="configs.paper_bcon_receipt_date"
                                   @dp-change="onPaperBconChange" @dp-update="onNextChange"  @dp-show="onShowChange">
                      </date-picker>
                      <div class="input-group-append cal_icon">
                        <button class="btn datepickerbutton" type="button"></button>
                      </div>
                    </div>
                    <span class="error absolute" v-show="errors[0]">{{errors[0]}}</span>
                  </ValidationProvider>
                </div>

                <p v-if="project.medium_type == 3" class="position_ant_2_3" :style="{paddingTop: '10px' }"><span>※到着が遅れた場合、報告書納品が遅れる場合がございます。</span></p>

              </div>
            </div>
          </dd>
        </dl>
        <!--報告書納品希望日 report_preferred_date -->
        <dl class="fx">
          <dt class="required required32 top">報告書納品希望日</dt>
          <dd class="margin_dd">
            <span class="f12">{{ project.medium_type == 1 ? 'Web回答終了日時を起点に報告書納品が算出されます。' : '質問紙BCon到着日を起点に報告書納品日が算出されます。' }}</span>
            <div class="fx_ce2">
              <div>
                <!--{{ maxDateValidate }}-->
                <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="report_preferred_date"
                                    :rules="'required'"
                                    v-slot="{ errors }"
                                    class="validate fx_ce">
                  <div class="input-group margin_b_2_4_2 form_text_2_3 width-datepicker">
                    <date-picker :disabled="!project.web_end && !project.paper_bcon_receipt_date" v-model="project.report_preferred_date" :class="{'form_text form_sch': true, 'error': errors[0] }" name="project.report_preferred_date"
                                 :wrap="true" :config="configs.report_preferred_date" @dp-update="onNextChange" @dp-show="onShowChange">
                    </date-picker>
                    <div class="input-group-append cal_icon">
                      <button class="btn datepickerbutton" type="button"></button>
                    </div>
                  </div>
                  <br v-show="errors[0]"><span class="error" v-show="errors[0]">{{ maxDateValidate }}{{errors[0]}}</span>
                </ValidationProvider>
              </div>

            </div>
          </dd>
        </dl>

        <dl class="fx" v-if="project.project_type == 5">
          <dt class=" top">配信開始日</dt>
          <dd class="margin_dd">
            <div class="fx_ce2">
              <div style="margin-left: 25px">
                <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="stream_start_date" rules=""
                                    v-slot="{ errors }"
                                    class="validate fx_ce">
                  <div class="input-group margin_b_2_4_2 form_text_2_3 width-datepicker">
                    <date-picker v-model="project.stream_start_date" :class="{'form_text form_sch': true, 'error': errors[0] }" name="project.stream_start_date"
                                 :wrap="true" :config="configs.stream_start_date">
                    </date-picker>
                    <div class="input-group-append cal_icon">
                      <button class="btn datepickerbutton" type="button"></button>
                    </div>
                  </div>
                </ValidationProvider>
              </div>

            </div>
          </dd>
        </dl>

        <!--industry type-->
        <dl class="fx">
          <dt class="required required3 top">該当する業種・組織規模</dt>
          <dd class="margin_dd">
            <p>業種</p>
            <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="industry_type" rules="required"
                                v-slot="{ errors }"
                                class="validate fx_between">
              <ul class="industry">
                <li v-for="(industry_type, index) in sliced(0, 12, industry_types)">
                  <input :class="{ 'form_radio': true, 'error': errors[0] }" :id="'industry_type' + index"
                         name="industry_type"
                         type="radio"
                         :value="index" v-model="project.industry_type">
                  <label :for="'industry_type' + index">{{ industry_type }}</label>
                </li>
              </ul>
              <ul class="industry">
                <li v-for="(industry_type, index) in sliced(12, 24, industry_types)">
                  <input :class="{ 'form_radio': true, 'error': errors[0] }" :id="'industry_type' + index"
                         name="industry_type"
                         type="radio"
                         :value="index" v-model="project.industry_type">
                  <label :for="'industry_type' + index">{{ industry_type }}</label>
                </li>
              </ul>
              <ul class="industry">
                <li v-for="(industry_type, index) in sliced(24, industry_types.length, industry_types)">
                  <input :class="{ 'form_radio': true, 'error': errors[0] }" :id="'industry_type' + index"
                         name="industry_type"
                         type="radio"
                         :value="index" v-model="project.industry_type">
                  <label :for="'industry_type' + index">{{ industry_type }}</label>
                </li>
              </ul>
              <!--<span class="error" v-show="errors[0]">{{errors[0]}}</span>-->
              </ValidationProvider>
              <!-- scale type -->
              <div class="scale">
                <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="scale_type" rules="required"
                                    v-slot="{ errors }" class="validate">
                  <p class="margin_dd">規模</p>
                  <div class="scale_1" v-for="i in Math.ceil(Object.keys(scale_types).length / 4)">
                    <p v-for="(scale_type, index) in sliced((i - 1) * 4, i * 4 < Object.keys(scale_types).length ? i * 4 : Object.keys(scale_types).length, scale_types)">
                      <input :class="{ 'form_radio': true, 'error': errors[0] }" :id="'scale_type' + index"
                             name="scale_type" type="radio"
                             :value="index" v-model="project.scale_type">
                      <label :for="'scale_type' + index">{{ scale_type }}</label>
                    </p>
                  </div>
                </ValidationProvider>
              </div>
          </dd>
        </dl>

        <dl class="fx"  v-if="project.project_type == 4 || project.project_type == 24">
          <dt class="required required11 top">対象</dt>
          <dd class="">
            <div class="scale no-margin" style="border-top: 0; padding-left: 25px;">
              <div class="row">
                <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="is_personnal_target1"
                                    :rules="{'required': (project.is_personnal_target1 != 1 && project.is_personnal_target2 != 1 && project.is_personnal_target3 != 1
                                    && project.is_personnal_target4 != 1 && project.is_personnal_target5 != 1 && project.is_personnal_target6 != 1
                                    && project.is_personnal_target7 != 1) }"
                                    v-slot="{ errors }" class="validate col-3 no-space">

                  <input :id="'is_personnal_target1'"
                         :class="{ 'error': errors[0] }" v-model="project.is_personnal_target1" type="checkbox" @change="uncheckX()"
                         name="is_personnal_target1">
                  <label :class="{ 'error': errors[0], 'check_2_4': true }" :for="'is_personnal_target1'">内定者</label>
                </ValidationProvider>

                <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="is_personnal_target2" :rules="{'required': (project.is_personnal_target1 != 1 && project.is_personnal_target2 != 1 && project.is_personnal_target3 != 1
                                    && project.is_personnal_target4 != 1 && project.is_personnal_target5 != 1 && project.is_personnal_target6 != 1
                                    && project.is_personnal_target7 != 1) }"
                                    v-slot="{ errors }" class="validate col-3 no-space">

                  <input :id="'is_personnal_target2'"
                         :class="{ 'error': errors[0] }" v-model="project.is_personnal_target2" type="checkbox" @change="uncheckX()" value="true"
                         name="is_personnal_target2">
                  <label :class="{ 'error': errors[0], 'check_2_4': true }"
                         :for="'is_personnal_target2'">半年-5年未満</label>

                </ValidationProvider>

                <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="is_personnal_target3" :rules="{'required': (project.is_personnal_target1 != 1 && project.is_personnal_target2 != 1 && project.is_personnal_target3 != 1
                                    && project.is_personnal_target4 != 1 && project.is_personnal_target5 != 1 && project.is_personnal_target6 != 1
                                    && project.is_personnal_target7 != 1) }"
                                    v-slot="{ errors }" class="validate col-3 no-space">

                  <input :id="'is_personnal_target3'"
                         :class="{ 'error': errors[0] }" v-model="project.is_personnal_target3" type="checkbox" @change="uncheckX()"
                         :value="1" name="is_personnal_target3">
                  <label :class="{ 'error': errors[0], 'check_2_4': true }"
                         :for="'is_personnal_target3'">監督職(主任・係長)</label>

                </ValidationProvider>

                <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="is_personnal_target4" :rules="{'required': (project.is_personnal_target1 != 1 && project.is_personnal_target2 != 1 && project.is_personnal_target3 != 1
                                    && project.is_personnal_target4 != 1 && project.is_personnal_target5 != 1 && project.is_personnal_target6 != 1
                                    && project.is_personnal_target7 != 1) }"
                                    v-slot="{ errors }" class="validate col-3 no-space">

                  <input :id="'is_personnal_target4'"
                         :class="{ 'error': errors[0] }" v-model="project.is_personnal_target4" type="checkbox" @change="uncheckX()"
                         :value="1" name="is_personnal_target4">
                  <label :class="{ 'error': errors[0], 'check_2_4': true }" :for="'is_personnal_target4'">役員</label>

                </ValidationProvider>

                <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="is_personnal_target5" :rules="{'required': (project.is_personnal_target1 != 1 && project.is_personnal_target2 != 1 && project.is_personnal_target3 != 1
                                    && project.is_personnal_target4 != 1 && project.is_personnal_target5 != 1 && project.is_personnal_target6 != 1
                                    && project.is_personnal_target7 != 1) }"
                                    v-slot="{ errors }" class="validate col-3 no-space">

                  <input :id="'is_personnal_target5'"
                         :class="{ 'error': errors[0] }" v-model="project.is_personnal_target5" type="checkbox" @change="uncheckX()"
                         :value="1" name="is_personnal_target5">
                  <label :class="{ 'error': errors[0], 'check_2_4': true }" :for="'is_personnal_target5'">新人</label>

                </ValidationProvider>

                <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="is_personnal_target6" :rules="{'required': (project.is_personnal_target1 != 1 && project.is_personnal_target2 != 1 && project.is_personnal_target3 != 1
                                    && project.is_personnal_target4 != 1 && project.is_personnal_target5 != 1 && project.is_personnal_target6 != 1
                                    && project.is_personnal_target7 != 1) }"
                                    v-slot="{ errors }" class="validate col-3 no-space">

                  <input :id="'is_personnal_target6'"
                         :class="{ 'error': errors[0] }" v-model="project.is_personnal_target6" type="checkbox" @change="uncheckX()"
                         :value="1" name="is_personnal_target6">
                  <label :class="{ 'error': errors[0], 'check_2_4': true }" :for="'is_personnal_target6'">5年以上</label>

                </ValidationProvider>

                <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="is_personnal_target7" :rules="{'required': (project.is_personnal_target1 != 1 && project.is_personnal_target2 != 1 && project.is_personnal_target3 != 1
                                    && project.is_personnal_target4 != 1 && project.is_personnal_target5 != 1 && project.is_personnal_target6 != 1
                                    && project.is_personnal_target7 != 1) }"
                                    v-slot="{ errors }" class="validate col-3 no-space">

                  <input :id="'is_personnal_target7'"
                         :class="{ 'error': errors[0] }" v-model="project.is_personnal_target7" type="checkbox" @change="uncheckX()"
                         :value="1" name="is_personnal_target7">
                  <label :class="{ 'error': errors[0], 'check_2_4': true }" :for="'is_personnal_target7'">課長・部長</label>
                </ValidationProvider>
              </div>

            </div>
          </dd>
        </dl>

        <!--memo-->
        <dl class="fx_normal">
          <dt class="top">備考欄</dt>
          <dd>
            <ValidationProvider ref="client_memo" :mode="agree ? 'eager' : 'passive'" name="client_memo" :rules="{max:1000}" v-slot="{ errors }" class="validate">
              <textarea :class="{ 'error': errors[0] }"  name="remarks" rows="7" cols="40" v-model="project.client_memo"></textarea>
              <br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>
            </ValidationProvider>
          </dd>
        </dl>
      </div>

      <!--consent-->
      <div class="consent">
        <p>【本書に記載される情報と本診断で取得する情報の取扱いについて】</p>
        <div class="consent_waku">
          <p>1.　利用目的及び提供：株式会社ビジネスコンサルタント（以下「BCon」といいます。）は、本書に記載される情報及び本診断で取得及び発生する情報（組織及び個人に関する情報を含み、以下総称して「対象情報」といいます。）を以下の目的で利用致します｡<br>
            <br>
            ①お申込みご担当者の確認<br>
            ②本診断に係るお申込み手続き<br>
            ③ご回答者の確認<br>
            ④本診断の実施<br>
            ⑤本診断報告書の作成<br>
            ⑥本診断結果の統計データ(a)<br>
            ⑦本診断結果に基づき、有益と判断するプログラム及びサービスに係る情報の提供(b) <br>
            (a)情報の主体（組織名又は個人）が特定されることのない形式に加工し、統計データの一部として利用致します。<br>
            (b)希望されない旨をお伝えいただければご意向に従います。<br>
            <br>
            2.　利用者の範囲：対象情報につきましては、以下に定める関係者が取扱います。<br>
            　　①本診断の運営担当者　②本診断の分析担当者　③担当営業　④担当コンサルタント<br>
            <br>
            3.　第三者開示・提供及び再委託の有無：対象情報を以下に定める以外の理由で、事前の承諾を得ることなく第三者に開示・提供することはございません。また、以下③以外で本診断に係る業務の全部又は一部を、事前の承諾を得ることなく第三者に再委託することもございません。なお、③に係る再委託先について、BConは一切の責任を負うものとします。<br>
            　　①法令又は命令等により、裁判所、警察、検察その他国家機関若しくはこれらに類する機関等に当該情報の開示について義務的に求められたとき<br>
            　　②生命・身体及び財産等に対する差し迫った危険があり、緊急の必要性があるとき<br>
            　　③本診断に係る印刷及び入力業務<br>
            <br>
            4.　情報提供の任意性：対象情報を含む情報の提供につきましては、任意となりますので、十分な情報をご提供頂けない場合は、お申込み手続き及び本診断の実施に支障をきたす場合がございます。<br>
            <br>
            5.　セキュリティ：BConは、対象情報について、紛失又は不当なアクセス・破壊・使用・修正・開示等が行われないよう合理的な安全保護措置を講じます。詳細につきましては、以下のWebに掲載しておりますプライバシーポリシー（http://www.bcon.jp/privacyp/）をご参照ください。<br>
            <br>
            6.　確認・開示・訂正：対象情報に関する確認・開示・訂正については、お申し出により、合理的な期間及び範囲で対応致します。<br>
            <br>
            7.　個人情報の削除：対象情報のうち、個人情報（特定の個人を識別できる情報）は原則、報告会・研修会等、報告書を使用した翌月末日を保管期限とし、翌々月には削除致します。この保管期限に関しては、該当欄の記載内容に準じます。<br>
            <br>
            8.　情報管理責任部門（問い合わせ窓口）：対象情報の取扱いに関するご質問・ご要望は下記にて承ります｡<br>
            <br>
            情報管理責任部門：株式会社ビジネスコンサルタント ESB本部 診断サービスグループ<br>
            〒100-0005　東京都千代田区丸の内1-7-12 サピアタワー18F<br>
            TEL:03-3287-3416  E-mail: survey-rcptn@info.bcon.jp</p>

        </div>
      </div>
      <p class="consent_box_p">以上の内容で同意いただける方は「同意する」にチェックを入れてください。</p>
      <p class="consent_box_p_p">
        <input type="checkbox" v-model="agree" name="agree" value="true" id="consent_check"><label for="consent_check" class="check_2_3">同意する</label>
      </p>

      <div id="check_box" style="display: block;">
        <!--<div class="form_box form_box_2_3_2"  v-show="agree">-->
          <!--<dl>-->
            <!--<dt>個人情報保管期間</dt>-->
            <!--<dd>-->
              <!--<p class="margin_b2">同意される場合は「同意する」にチェックを、期間延長を希望される場合は担当営業もしくは、<br>-->
                <!--お問い合わせ窓口までお問い合わせください。</p>-->
              <!--<p>-->
                <!--<input class="form-check-input" id="consent_check_2" type="checkbox" value="true" v-model="agree_store">-->
                <!--<label for="consent_check_2" class="check_2_3">個人情報保管期間について同意する</label>-->
              <!--</p>-->
            <!--</dd>-->
          <!--</dl>-->
        <!--</div>-->
        <div class="footer_box footer_2_3">
          <p class="btn_1"><input :class="{ 'form_box_sub': true, 'form_box_sub_o': !agree }" type="submit" value="入力内容を確認する"></p>
          <p class="save_btn"><a href="javascript:;" @click="saveWithoutValidate">一時保存</a></p>
        </div>
      </div>
    </ValidationObserver>
    <div v-if="is_preview && project.progress_status == 0">
      <div class="attention">
        <p>入力内容に誤りがないかご確認ください。</p>
      </div>
      <form @submit.prevent="updateProgress()">
        <div class="form_box form_box_1_3">
          <div class="form_box_title">
            <p class="">お客様情報入力</p>
          </div>
          <dl>
            <dt width="25%">組織名(会社名)</dt>
            <dd width="75%">{{ project.client_name }}</dd>
          </dl>
          <dl>
            <dt>所属・役職</dt>
            <dd>{{ user.division }}　{{ user.position }}</dd>
          </dl>
          <dl>
            <dt>氏名</dt>
            <dd>{{ user.name }}</dd>
          </dl>
          <dl>
            <dt>電話番号</dt>
            <dd>
              {{ project.client_tel }}
            </dd>
          </dl>
          <dl>
            <dt>住所</dt>
            <dd>
              <p>〒{{ project.zip_code }}</p>
              <p>{{ project.address1 }}{{ project.address2 }}{{ project.address3 }}</p>
            </dd>
          </dl>
          <dl>
            <dt width="25%">実施方法</dt>
            <dd width="75%">
              <label v-if="project.medium_type == 3">Web {{project.web_people}}名 + 質問紙 {{project.paper_people}}名</label>
              <label v-if="project.medium_type == 2">質問紙 {{project.paper_people}}名</label>
              <label v-if="project.medium_type == 1">Web {{project.web_people}}名</label>
              <!--<label>{{ this.medium_types[project.medium_type] ? this.medium_types[project.medium_type] : '' }}</label>-->
              <!--<label v-show="(parseInt(project.web_people) || 0) + (parseInt(project.paper_people) || 0) > 0">{{-->
                <!--(parseInt(project.web_people) || 0) + (parseInt(project.paper_people) || 0) }}名</label>-->
            </dd>
          </dl>
          <dl v-if="((project.medium_type == 2 || project.medium_type == 3) && (project.project_type >= 11 && project.project_type <= 16))">
            <dt>質問紙の送付先拠点数</dt>
            <dd>
              <label v-if="project.paper_send_type == 1">1拠点</label>
              <label v-if="project.paper_send_type == 2">2拠点</label>
              <label v-if="project.paper_send_type == 3">3拠点以上</label>
              <!--{{ paper_send_types[project.paper_send_type] ?  paper_send_types[project.paper_send_type] : ''}}-->
            </dd>
          </dl>
          <dl v-if="project.paper_send_type == 1 || project.paper_send_type == 2">
            <dt>質問紙の送付先住所①</dt>
            <dd>
              <p>〒{{ project.paper1_zip_code }}</p>
              <p>{{ project.paper1_address1 }}{{ project.paper1_address2 }}{{ project.paper1_address3 }}</p>
              <p>{{ project.paper1_division }} {{ project.paper1_position }}</p>
              <p>{{ project.paper1_name }}</p>
            </dd>
          </dl>
          <dl v-if="(project.paper_send_type == 1 || project.paper_send_type == 2) && !((project.project_type >= 1 && project.project_type <= 6) || (project.project_type >= 21 && project.project_type <= 24))">
            <dt>質問紙の送付部数①</dt>
            <dd>{{ project.paper1_copies }}</dd>
          </dl>
          <dl v-if="project.paper_send_type == 2">
            <dt>質問紙の送付先住所②</dt>
            <dd>
              <p>〒{{ project.paper2_zip_code }}</p>
              <p>{{ project.paper2_address1 }}{{ project.paper2_address2 }}{{ project.paper2_address3 }}</p>
              <p>{{ project.paper2_division }} {{ project.paper2_position }}</p>
              <p>{{ project.paper2_name }}</p>
            </dd>
          </dl>
          <dl v-if="project.paper_send_type == 2">
            <dt>質問紙の送付部数②</dt>
            <dd>{{ project.paper2_copies }}</dd>
          </dl>
          <dl>
            <dt class="top">回答スケジュール</dt>
            <dd class="fx">
              <div class="form_box_2_4" style="width: 100%">
                <p id="margin_left_20" class="form_box_2_4_p form_box_2_4_2_p">
                  <span>対象者リスト提出締切日　{{ deadlineSubmit }}</span>
                </p>
                <div v-if="project.medium_type == 1" class="form_box_360" style="width: 100%">
                  <div class="margin_b_2_4 fx_ce row">
                    <p class="col col-140">Web診断開始日時</p>
                    <p class="col margin_l_2_4 stick_2_4">{{ project.web_start | dateFormat }} </p>
                    <span class="col">※メール配信開始は、11:00～13:00です。</span>
                  </div>
                  <div class="row margin_b_2_4 margin_b_2_3 fx_ce">
                    <p class="col-140">Web診断終了日時</p>
                    <p class="col margin_l_2_4">{{ project.web_end | dateFormat }} </p>
                    <span class="col">※WEB回答終了時刻は、17:00です。</span>
                  </div>
                </div>
                <div v-if="project.medium_type == 2" class="form_box_360">
                  <div class="margin_b_2_4 fx_ce row">
                    <p class="col col-140">質問紙到着希望日</p>
                    <p class="col margin_l_2_4 stick_2_4">{{ project.paper_preferred_date | dateFormat }}</p>
                  </div>
                  <div class="margin_b_2_4 fx_ce">
                    <p>質問紙提出期限(任意)</p>
                    <p class="margin_l_2_4 stick_2_4">{{ project.paper_limit_date | dateFormat }}</p>
                  </div>
                  <div class="margin_b_2_4 fx_ce row">
                    <p class="col col-140">質問紙BCon到着日</p>
                    <p class="col margin_l_2_4">{{ project.paper_bcon_receipt_date | dateFormat }}</p>
                  </div>
                </div>
                <div v-if="project.medium_type == 3" class="form_box_360" style="width: 100%">
                  <div class="margin_b_2_4 fx_ce row">
                    <p class="col col-140">Web診断開始日時</p>
                    <p class="col margin_l_2_4 stick_2_4">{{ project.web_start | dateFormat }}</p>
                    <span class="col">※メール配信開始は、11:00～13:00です。</span>
                  </div>
                  <div class="row margin_b_2_4 margin_b_2_3 fx_ce">
                    <p class="col-140">Web診断終了日時</p>
                    <p class="col margin_l_2_4">{{ project.web_end | dateFormat }}</p>
                    <span class="col">※WEB回答終了時刻は、17:00です。</span>
                  </div>
                  <div>
                    <hr>
                  </div>
                  <div class="margin_b_2_4 fx_ce row">
                    <p class="col col-140">質問紙到着希望日</p>
                    <p class="col margin_l_2_4 stick_2_4">{{ project.paper_preferred_date | dateFormat }}</p>
                  </div>
                  <div class="margin_b_2_4 fx_ce">
                    <p>質問紙提出期限(任意)</p>
                    <p class="margin_l_2_4 stick_2_4">{{ project.paper_limit_date | dateFormat }}</p>
                  </div>
                  <div class="margin_b_2_4 fx_ce row">
                    <p class="col col-140">質問紙BCon到着日</p>
                    <p class="col margin_l_2_4">{{ project.paper_bcon_receipt_date | dateFormat }}</p>
                  </div>
                </div>
              </div>
            </dd>
          </dl>
          <dl>
            <dt>報告書納品希望日</dt>
            <dd class="margin_l_2_4_2">{{ project.report_preferred_date | dateFormat }}</dd>
          </dl>
          <dl class="fx" v-if="project.project_type == 5">
            <dt class="">配信開始日</dt>
            <dd class="">
              {{ project.stream_start_date | dateFormat }}
            </dd>
          </dl>
          <dl class="form_box_1_3_dl">
            <dt>該当する業界・規模</dt>
            <dd>業界・業種：　{{ this.industry_types[project.industry_type] ? this.industry_types[project.industry_type] : '' }}<br>
              規模　　　：　{{ this.scale_types[project.scale_type] ? this.scale_types[project.scale_type] : '' }}
            </dd>
          </dl>

          <dl class="fx" v-if="project.project_type == 4 || project.project_type == 24">
            <dt class="">対象</dt>
            <dd>
              <div v-if="project.is_personnal_target1">内定者</div>
              <div v-if="project.is_personnal_target2">半年-5年未満</div>
              <div v-if="project.is_personnal_target3">監督職(主任・係長)</div>
              <div v-if="project.is_personnal_target4">役員</div>
              <div v-if="project.is_personnal_target5">新人</div>
              <div v-if="project.is_personnal_target6">5年以上</div>
              <div v-if="project.is_personnal_target7">課長・部長</div>
            </dd>
          </dl>
          <dl>
            <dt>備考欄</dt>
            <dd>
              <div v-if="empty(project.client_memo) && project.client_memo.length > 0">
                <div v-for="(line, i) in project.client_memo.split('\n')">
                    {{ line }} <br v-if="i < project.client_memo.split('\n').length - 1">
                </div>
              </div>
              <div v-else>なし</div>
            </dd>
          </dl>
          <dl>
            <dt>本書に記載される情報と本診断で取得する情報の取扱いについて</dt>
            <dd>
              <p v-if="agree">同意する</p>
            </dd>
          </dl>

          <!--<dl>-->
            <!--<dt>個人情報保管期間</dt>-->
            <!--<dd>-->
              <!--{{ agree_store ? '個人情報保管期間について同意する' : ''}}-->
            <!--</dd>-->
          <!--</dl>-->
        </div>
        <div class="footer_box footer_box_1_3">
          <p class="btn2"><input @click="backInput()" class="form_box_sub_blue form_box_sub_2" type="button" value="前の画面に戻る"></p>
          <p class="btn3"><input class="form_box_sub form_box_sub_2 step1" type="submit" value="上記の内容で送信する" style="padding: 15px 100px"></p>
        </div>
      </form>
    </div>

    <div class="top_box_2_5" v-if="project.progress_status == 1">
      <p>ご登録ありがとうございました。<br>
        診断申込みを受け付けました。<br>
        申込受付完了メールを送信しますので内容をご確認ください。</p>
    </div>

    <div class="content_box_2_5 fx_se" v-if="project.progress_status == 1">
      <div class="content_box_child_2_5"  @click="newStep()">
        <img src="images/jizen_img1.png" alt="「詳細情報」を入力する" srcset="images/jizen_img1.png 1x, images/jizen_img1@2x.png 2x">
        <p>引き続き<br>「詳細情報」を入力する</p>
        <p class="btn-s btn_2_5"><a href="javascript:;" class="form_box_sub form_box_sub_2" style="padding: 15px 100px">詳細情報へ
        </a></p>
      </div>
      <!--<div class="content_box_child_2_5"  @click="logout()">-->
        <div class="content_box_child_2_5">
        <img src="images/jizen_img2.png" alt="「詳細情報」を入力する" srcset="images/jizen_img2.png 1x, images/jizen_img2@2x.png 2x">
        <p>後で<br>引き続き「詳細情報」を入力する</p>
        <p class="content_box_child_2_5_p">この画面を終了した後で事前情報を入力する場合<br>は、再度ログインしていただき、「詳細情報 登録」<br>へお進みください。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
    import {mapState} from 'vuex'
    import {
        INDUSTRY_TYPE,
        MEDIUM_TYPE,
        PAPER_SEND_TYPE,
        PERSONAL_TARGET,
        REPORT_PREFERRER_DATE,
        SCALE_TYPE,
        USER_CONSULTANT,
        USER_SALES
    } from './../../../contstant'
    import moment from 'moment'
    // import {ja} from 'vuejs-datepicker/dist/locale';

  moment.locale('ja', {
    months: '1月,2月,3月,4月,5月,6月,7月,8月,9月,10月,11月,12月'.split(','),
    monthsShort: '1月,2月,3月,4月,5月,6月,7月,8月,9月,10月,11月,12月'.split(','),
    monthsParseExact: true,
    weekdays: '日,月,火,水,木,金,土'.split(','),
    weekdaysShort: '日,月,火,水,木,金,土'.split(','),
    weekdaysMin: '日,月,火,水,木,金,土'.split(','),
    weekdaysParseExact: true
  })
  export default {
    data() {
      return {
        industry_types: INDUSTRY_TYPE,
        scale_types: SCALE_TYPE,
        medium_types: MEDIUM_TYPE,
        paper_send_types: PAPER_SEND_TYPE,
        report_preferrer_date_conditions: REPORT_PREFERRER_DATE,
        personal_targets: PERSONAL_TARGET,
        agree: false,
        agree_store: false,
        extend: false,
        value: '',
        formatted: '',
        selected: '',
        logoutTo: 'Login',
        fukidashi2: false,
        is_preview: false,
          deadlineSubmit: null,
          deadlineSubmitRaw: null,
        searchAddErr: '',
        searchAddErr1: '',
        searchAddErr2: '',
        configs: {
          web_start: {
            allowInputToggle: true,
            format: 'YYYY-MM-DD',
            minDate: moment().format('YYYY-MM-DD'),
            useCurrent: false,
            // inline: false
          },
          web_end: {
            allowInputToggle: true,
            format: 'YYYY-MM-DD',
            minDate: moment().format('YYYY-MM-DD'),
            useCurrent: false,
            // inline: true
          },
          paper_preferred_date: {
            allowInputToggle: true,
            format: 'YYYY-MM-DD',
            useCurrent: false,
            minDate: moment().format('YYYY-MM-DD')
          },
          paper_limit_date: {
            allowInputToggle: true,
            format: 'YYYY-MM-DD',
            useCurrent: false,
            minDate: moment().format('YYYY-MM-DD')
          },
          paper_bcon_receipt_date: {
            allowInputToggle: true,
            format: 'YYYY-MM-DD',
            useCurrent: false,
            minDate: moment().format('YYYY-MM-DD')
          },
          report_preferred_date: {
            allowInputToggle: true,
            format: 'YYYY-MM-DD',
            useCurrent: false,
            minDate: moment().format('YYYY-MM-DD'),
            // inline: true
          },
          stream_start_date: {
            allowInputToggle: true,
            format: 'YYYY-MM-DD',
            useCurrent: false,
            minDate: moment().format('YYYY-MM-DD'),
          }
        },
          report_preferred_date: null,
        maxDateValidate: '',
        isRequired: false
      }
    },
    props: {
      preview: {
        type: Boolean,
        default: false
      }
    },
    watch: {
      preview() {
        this.is_preview = this.preview
      },
      'project.medium_type': function () {
        if ((this.project.medium_type == 2 || this.project.medium_type == 3) && ((this.project.project_type >= 1 && this.project.project_type <= 6)  || (this.project.project_type >= 21 && this.project.project_type <= 24))) {
          this.project.paper_send_type = 1
        } else if (this.project.medium_type == 1 && !((this.project.project_type >= 1 && this.project.project_type <= 6)  || (this.project.project_type >= 21 && this.project.project_type <= 24))) {
          this.project.paper_send_type = null
        }
        // this.project.report_preferred_date = null
      },
      'project.paper_bcon_receipt_date': function () {
        let reportDate = this.getInputDate()
        if (moment(reportDate).isValid()) {
          this.$set(this.configs.report_preferred_date, 'minDate', moment(reportDate).add(0, 'days') || null);
        }
      },
      'project.web_end': function () {
        let reportDate = this.getInputDate()
        if (moment(reportDate).isValid()) {
          this.$set(this.configs.report_preferred_date, 'minDate', moment(reportDate).add(0, 'days') || null);
        }
      },
      'project.project_type': function () {
        if (this.project.project_type == 5) {
          this.project.medium_type = 1
        }

        // if(!(this.project.project_type >= 11 && this.project.project_type <= 16) && (this.project.medium_type == 1 || this.project.medium_type == 2)) {
        //   this.project.paper_send_type = 1
        // }
          },
      'project.web_start': function () {
        this.getDeadlineSubmit()
      },
        'project.paper_preferred_date': function () {
            this.getDeadlineSubmit()
        },
      'user.division': function() {
        if (this.project.paper_send_type == 1 && _.isEmpty(this.project.paper1_division)) {
          this.project.paper1_division = this.user.division
        }
        if (this.project.paper_send_type == 2) {
          if (_.isEmpty(this.project.paper1_division)) {
            this.project.paper1_division = this.user.division
          }
          // if (_.isEmpty(this.project.paper2_division)) {
          //   this.project.paper2_division = this.user.division
          // }
        }
      },
      'user.name': function() {
        if (this.project.paper_send_type == 1 && _.isEmpty(this.project.paper1_name)) {
          this.project.paper1_name = this.user.name
        }
        if (this.project.paper_send_type == 2) {
          if (_.isEmpty(this.project.paper1_name)) {
            this.project.paper1_name = this.user.name
          }
          // if (_.isEmpty(this.project.paper2_name)) {
          //   this.project.paper2_name = this.user.name
          // }
        }
      },
      'user.position': function() {
        if (this.project.paper_send_type == 1 && _.isEmpty(this.project.paper1_position)) {
          this.project.paper1_position = this.user.position
        }
        if (this.project.paper_send_type == 2) {
          if (_.isEmpty(this.project.paper1_position)) {
            this.project.paper1_position = this.user.position
          }
          // if (_.isEmpty(this.project.paper2_position)) {
          //   this.project.paper2_position = this.user.position
          // }
        }
      },
      'project.zip_code': function() {
        if (this.project.paper_send_type == 1 && _.isEmpty(this.project.paper1_zip_code)) {
          this.project.paper1_zip_code = this.project.zip_code
        }
        if (this.project.paper_send_type == 2) {
          if (_.isEmpty(this.project.paper1_zip_code)) {
            this.project.paper1_zip_code = this.project.zip_code
          }
          // if (_.isEmpty(this.project.paper2_zip_code)) {
          //   this.project.paper2_zip_code = this.project.zip_code
          // }
        }
      },
      'project.address1': function() {
        if (this.project.paper_send_type == 1 && _.isEmpty(this.project.paper1_address1) && _.isEmpty(this.project.paper1_address2) ) {
          this.project.paper1_address1 = this.project.address1
          this.project.paper1_address2 = this.project.address2
        }
        if (this.project.paper_send_type == 2) {
          if (_.isEmpty(this.project.paper1_address1) && _.isEmpty(this.project.paper1_address2)) {
            this.project.paper1_address1 = this.project.address1
            this.project.paper1_address2 = this.project.address2
          }
          // if (_.isEmpty(this.project.paper2_address1) && _.isEmpty(this.project.paper2_address2)) {
          //   this.project.paper2_address1 = this.project.address1
          //   this.project.paper2_address2 = this.project.address2
          // }
        }
      },
      'project.address3': function() {
        if (this.project.paper_send_type == 1 && _.isEmpty(this.project.paper1_address1) && _.isEmpty(this.project.paper1_address2) && _.isEmpty(this.project.paper1_address3) ) {
          this.project.paper1_address3 = this.project.address3
        }
        if (this.project.paper_send_type == 2) {
          if (_.isEmpty(this.project.paper1_address1) && _.isEmpty(this.project.paper1_address2)  && _.isEmpty(this.project.paper1_address3)) {
            this.project.paper1_address3 = this.project.address3
          }
          // if (_.isEmpty(this.project.paper2_address1) && _.isEmpty(this.project.paper2_address2)  && _.isEmpty(this.project.paper2_address3)) {
          //   this.project.paper2_address3 = this.project.address3
          // }
        }
      },
      'project.paper_send_type': function() {
        if (this.project.paper_send_type == 1 && _.isEmpty(this.project.paper1_address1) && _.isEmpty(this.project.paper1_address2) && _.isEmpty(this.project.paper1_address3) ) {
          this.project.paper1_address1 = this.project.address1
          this.project.paper1_address2 = this.project.address2
          this.project.paper1_address3 = this.project.address3

          this.project.paper2_address1 = ''
          this.project.paper2_address2 = ''
          this.project.paper2_address3 = ''
        }

        if (this.project.paper_send_type == 1 && _.isEmpty(this.project.paper1_zip_code)) {
          this.project.paper1_zip_code = this.project.zip_code
        }
        if (this.project.paper_send_type == 2) {
          if (_.isEmpty(this.project.paper1_zip_code)) {
            this.project.paper1_zip_code = this.project.zip_code
          }
          // if (_.isEmpty(this.project.paper2_zip_code)) {
          //   this.project.paper2_zip_code = this.project.zip_code
          // }
        }

        if (this.project.paper_send_type == 1 && _.isEmpty(this.project.paper1_division)) {
          this.project.paper1_division = this.user.division
        }
        if (this.project.paper_send_type == 2) {
          if (_.isEmpty(this.project.paper1_division)) {
            this.project.paper1_division = this.user.division
          }
          // if (_.isEmpty(this.project.paper2_division)) {
          //   this.project.paper2_division = this.user.division
          // }
        }

        if (this.project.paper_send_type == 2) {
          if (_.isEmpty(this.project.paper1_address1) && _.isEmpty(this.project.paper1_address2) && _.isEmpty(this.project.paper1_address3)) {
            this.project.paper1_address1 = this.project.address1
            this.project.paper1_address2 = this.project.address2
            this.project.paper1_address3 = this.project.address3
          }
          // if (_.isEmpty(this.project.paper2_address1) && _.isEmpty(this.project.paper2_address2) && _.isEmpty(this.project.paper2_address3)) {
          //   this.project.paper2_address1 = this.project.address1
          //   this.project.paper2_address2 = this.project.address2
          //   this.project.paper2_address3 = this.project.address3
          // }
        }

        if (this.project.paper_send_type == 1 && _.isEmpty(this.project.paper1_name)) {
          this.project.paper1_name = this.user.name
        }
        if (this.project.paper_send_type == 2) {
          if (_.isEmpty(this.project.paper1_name)) {
            this.project.paper1_name = this.user.name
          }
          // if (_.isEmpty(this.project.paper2_name)) {
          //   this.project.paper2_name = this.user.name
          // }
        }

        if (this.project.paper_send_type == 1 && _.isEmpty(this.project.paper1_position)) {
          this.project.paper1_position = this.user.position
        }
        if (this.project.paper_send_type == 2) {
          if (_.isEmpty(this.project.paper1_position)) {
            this.project.paper1_position = this.user.position
          }
          // if (_.isEmpty(this.project.paper2_position)) {
          //   this.project.paper2_position = this.user.position
          // }
        }

        // if (this.project.paper_send_type == 3) {
        //   this.project.paper1_address1 = ''
        //   this.project.paper1_address2 = ''
        //   this.project.paper1_address3 = ''
        //
        //   this.project.paper1_position = ''
        //   this.project.paper1_name = ''
        //   this.project.paper1_division = ''
        //   this.project.paper1_zip_code = ''
        //
        //   this.project.paper2_address1 = ''
        //   this.project.paper2_address2 = ''
        //   this.project.paper2_address3 = ''
        //
        //   this.project.paper2_position = ''
        //   this.project.paper2_name = ''
        //   this.project.paper2_division = ''
        //   this.project.paper2_zip_code = ''
        // }
      },
      'project.paper_people': function() {
        // this.project.report_preferred_date = null
       let dateInput = this.getInputDate()

        let paperMin = this.getPaperDate()

        this.$set(this.configs.paper_preferred_date, 'minDate', paperMin || null);
        this.$set(this.configs.paper_limit_date, 'minDate', paperMin || null);
        this.$set(this.configs.paper_bcon_receipt_date, 'minDate', moment(paperMin).add(1, 'days') || null);

        if (moment(dateInput).isValid()) {
          this.$set(this.configs.report_preferred_date, 'minDate', false || null);
          this.$set(this.configs.report_preferred_date, 'minDate', dateInput || null);
        }
      },
      holidays() {
        let dateInput = this.getInputDate()
        // let paperDate = this.getPaperDate()

        // this.$set(this.configs.paper_preferred_date, 'minDate', paperDate || null);
        if ((this.holidays instanceof Array)) {
          this.$set(this.configs.paper_preferred_date, 'disabledDates', this.holidays || null);
        }
        // this.$set(this.configs.paper_limit_date, 'minDate', paperDate || null);
        this.$set(this.configs.paper_limit_date, 'disabledDates', false);
        // this.$set(this.configs.paper_bcon_receipt_date, 'minDate', paperDate || null);
        if ((this.holidays instanceof Array)) {
          this.$set(this.configs.paper_bcon_receipt_date, 'disabledDates', this.holidays || null);
        }

        if (moment(dateInput).isValid()) {
          this.$set(this.configs.report_preferred_date, 'minDate', false || null);
          this.$set(this.configs.report_preferred_date, 'minDate', dateInput || null);
        }

        if (this.project.medium_type == 2) {
          if (this.project.paper_bcon_receipt_date && dateInput < this.project.paper_bcon_receipt_date) {
            this.report_preferred_date = this.project.paper_bcon_receipt_date
            this.$set(this.configs.paper_bcon_receipt_date, 'minDate', false || null);
            let dateReport = moment(this.project.paper_bcon_receipt_date).add(0, 'days').format('YYYY-MM-DD')
            this.$set(this.configs.report_preferred_date, 'minDate', dateReport || null);
          }
        } else if (this.project.medium_type == 3) {
          if (this.project.web_end || this.project.paper_bcon_receipt_date) {
            let dateReport = ''
            if (this.project.paper_bcon_receipt_date > this.project.web_end) {
              dateReport = this.project.paper_bcon_receipt_date;
            } else {
              dateReport = this.project.web_end
            }

            this.report_preferred_date = this.project.paper_bcon_receipt_date
            this.$set(this.configs.paper_bcon_receipt_date, 'minDate', false || null);
            this.$set(this.configs.report_preferred_date, 'minDate', dateReport || null);
          }
        }

        this.report_preferred_date = dateInput
        let days = this.getDates(moment().add(1, 'days').format('YYYY-MM-DD'), moment().add(30, 'days').format('YYYY-MM-DD'))
        days.sort(function(a,b){
          return  new Date(a) - new Date(b);
        });

        let total_web = 5
        if (this.holidays.includes(moment().format('YYYY-MM-DD'))) {
          total_web = 6
        }

        let fiveDate = null
        let i = 0
        _.forEach(days, (d) => {
          if (!this.holidays.includes(d) && i <= total_web) {
            fiveDate = d
            i = i + 1
          }
        })

        this.$set(this.configs.web_start, 'minDate', moment(fiveDate).format('YYYY-MM-DD') || null);
        if ((this.holidays instanceof Array)) {
          this.$set(this.configs.web_start, 'disabledDates', this.holidays || null);
        }
        this.$set(this.configs.web_end, 'minDate', moment(fiveDate).add(1, 'days').format('YYYY-MM-DD') || null);
        if ((this.holidays instanceof Array)) {
          this.$set(this.configs.web_end, 'disabledDates', this.holidays || null);
        }
        this.$set(this.configs.report_preferred_date, 'disabledDates', false);
        if ((this.holidays instanceof Array)) {
          this.$set(this.configs.report_preferred_date, 'disabledDates', this.holidays || null);
        }
      }
    },
    computed: {
      ...mapState('consultantUser', ['user', 'project', 'holidays']),
    },
    methods: {
      getDates(startDate, stopDate) {
        var dateArray = [];
        var currentDate = moment(startDate);
        var stopDate = moment(stopDate);
        while (currentDate <= stopDate) {
          dateArray.push( moment(currentDate).format('YYYY-MM-DD') )
          currentDate = moment(currentDate).add(1, 'days');
        }
        return dateArray;
      },
      getDeadlineSubmit() {
        let fiveDate = this.project.web_start
        let fiveDate2 = null
        if (this.project.web_start) {
          let days = this.getDates(moment(this.project.web_start).subtract(30, 'days').format('YYYY-MM-DD'), moment(this.project.web_start).subtract(1, 'days').format('YYYY-MM-DD'))
          days.sort(function (a, b) {
            return new Date(b) - new Date(a);
          });
          let i = 1
          _.forEach(days, (d) => {
            if (!this.holidays.includes(d) && i <= 5) {
              fiveDate = d
              i = i + 1
            }
          })

          fiveDate = moment(fiveDate).subtract(1, 'days').format('YYYY-MM-DD')
          while (this.holidays.includes(fiveDate)) {
            fiveDate = moment(fiveDate).subtract(1, 'days').format('YYYY-MM-DD')
          }
        }
        if (this.project.medium_type == 2 || this.project.medium_type == 3) {
          let infDate = this.project.paper_preferred_date

          if (infDate) {
            let paper_people = this.project.paper_people
            let date_medium2 = ''
            switch (true) {
              case (paper_people > 0 && paper_people <= 100):
                date_medium2 = 7
                break
              case (paper_people > 100 && paper_people <= 200):
                date_medium2 = 8
                break
              case (paper_people > 200 && paper_people <= 300):
                date_medium2 = 9
                break
              case (paper_people > 300 && paper_people <= 400):
                date_medium2 = 10
                break
              case (paper_people > 400 && paper_people <= 500):
                date_medium2 = 11
                break
              case (paper_people > 500):
                date_medium2 = 12
                break
              default:
                break
            }
            let days2 = this.getDates(moment(infDate).subtract(30, 'days').format('YYYY-MM-DD'), infDate)
            days2.sort(function(a,b){
              return new Date(b) - new Date(a);
            });
            let i = 0
            _.forEach(days2, (d) => {
              if (!this.holidays.includes(d) && i <= date_medium2) {
                fiveDate2 = d
                i = i + 1
              }
            })
          }
        }

        let deadline = ''
        if (moment(fiveDate).isValid() && moment(fiveDate2).isValid()) {
          if (fiveDate < fiveDate2) {
            deadline = fiveDate
          } else {
            deadline = fiveDate2
          }
        } else if (!moment(fiveDate).isValid() && moment(fiveDate2).isValid()) {
          deadline = fiveDate2
        } else if (moment(fiveDate).isValid() &&  !moment(fiveDate2).isValid()) {
          deadline = fiveDate
        }

        this.deadlineSubmitRaw = deadline
        this.deadlineSubmit = moment(deadline).isValid() ? moment(deadline).format('YYYY年MM月DD日') : ''

      },
      getPaperDate() {
        const paper_people = this.project.paper_people;
        let date = 0
        switch (true) {
          case (paper_people > 0 && paper_people <= 100):
            date = 6
            break
          case (paper_people > 100 && paper_people <= 200):
            date = 7
            break
          case (paper_people > 200 && paper_people <= 300):
            date = 8
            break
          case (paper_people > 300 && paper_people <= 400):
            date = 9
            break
          case (paper_people > 400 && paper_people <= 500):
            date = 10
            break
          case (paper_people > 500):
            date = 11
            break
          default:
            break
        }
        let date_to_input = parseInt(date) + 1

        let days = this.getDates(moment().format('YYYY-MM-DD'), moment().subtract(1, 'days').add(date_to_input + 30, 'days').format('YYYY-MM-DD'))
        days.sort(function(a,b){
          return  new Date(a) - new Date(b);
        });

        let i = 0
        let dateInput = null
        _.forEach(days, (d) => {
          if (!this.holidays.includes(d) && i <= date_to_input) {
            dateInput = d
            i = i + 1
          }
        })

        return dateInput
      },
      getInputDate() {
        const paper_people = this.project.paper_people;
        let date = 0
        switch (true) {
          case (paper_people > 0 && paper_people <= 100):
            date = 1
            break
          case (paper_people > 100 && paper_people <= 200):
            date = 2
            break
          case (paper_people > 200 && paper_people <= 300):
            date = 3
            break
          case (paper_people > 300 && paper_people <= 400):
            date = 4
            break
          case (paper_people > 400 && paper_people <= 500):
            date = 5
            break
          case (paper_people > 500):
            date = 7
            break
          default:
            break
        }

        let date_to_input = this.report_preferrer_date_conditions[this.project.project_type]
        date_to_input = parseInt(date_to_input) + parseInt(date) + 1

        if (this.project.medium_type == 1) {
          let dateInput = moment(this.project.web_end).add(date_to_input, 'days').format('YYYY-MM-DD')

          let days = this.getDates(moment(this.project.web_end).format('YYYY-MM-DD'), moment(this.project.web_end).add(date_to_input + 30, 'days').format('YYYY-MM-DD'))
          days.sort(function(a,b){
            return  new Date(a) - new Date(b);
          });

          if (this.holidays.includes(this.project.web_end)) {
            date_to_input = date_to_input - 1
          }
          let i = 0
          _.forEach(days, (d) => {
            if (!this.holidays.includes(d) && i <= date_to_input) {
              dateInput = d
              i = i + 1
            }
          })

          return dateInput
        } else {
          let dateInput = moment(this.project.paper_bcon_receipt_date).add(date_to_input, 'days').format('YYYY-MM-DD')

          let days = this.getDates(moment(this.project.paper_bcon_receipt_date).format('YYYY-MM-DD'), moment(this.project.paper_bcon_receipt_date).add(date_to_input + 30, 'days').format('YYYY-MM-DD'))
          days.sort(function(a,b){
            return  new Date(a) - new Date(b);
          });
          let i = 0
          if (this.holidays.includes(this.project.paper_bcon_receipt_date)) {
            date_to_input = date_to_input - 1
          }
          _.forEach(days, (d) => {
            if (!this.holidays.includes(d) && i <= date_to_input) {
              dateInput = d
              i = i + 1
            }
          })

          return dateInput
        }
      },
      preventAlphabetInput ($event) {
        let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
        if (((keyCode < 48 || keyCode > 57))) {
          $event.preventDefault();
        }
      },
      uncheckX() {
        if (this.project.is_personnal_target1 == false) {
          this.project.is_personnal_target1 = null
        }
        if (this.project.is_personnal_target2 == false) {
          this.project.is_personnal_target2 = null
        }
        if (this.project.is_personnal_target3 == false) {
          this.project.is_personnal_target3 = null
        }
        if (this.project.is_personnal_target4 == false) {
          this.project.is_personnal_target4 = null
        }
        if (this.project.is_personnal_target5 == false) {
          this.project.is_personnal_target5 = null
        }
        if (this.project.is_personnal_target6 == false) {
          this.project.is_personnal_target6 = null
        }
        if (this.project.is_personnal_target7 == false) {
          this.project.is_personnal_target7 = null
        }

      },
      paperPeople() {
        this.project.web_start = null
        this.project.paper_preferred_date = null
        this.project.report_preferred_date = null
      },
      webPeople() {
        this.project.web_start = null
        this.project.paper_preferred_date = null
        this.project.report_preferred_date = null
      },
      empty(value) {
        return !_.isEmpty(value)
      },
      async saveProject() {
        this.isRequired = true
        const isValid = await this.$refs.observer.validate();
        if (!isValid) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.observer.errors)
              .map(([key, value]) => ({key, value}))
              .filter(error => error["value"].length);

            this.$refs.observer.refs[errors[0]["key"]].$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 100);
          return
          // ABORT!!
        }

        this.$wait.start('loading')
        this.$store.dispatch('project/UPDATE_PROJECT', {'project': this.project, 'user': this.user }).then(() => {
          this.is_preview = true
          this.$emit('hidden',  true)
          this.$wait.end('loading')
          window.scrollTo(0,0)
        }).catch(e => {
          this.$wait.end('loading')
        })


      },
      async saveWithoutValidate() {
        this.isRequired = false
        let validate = {}
        validate.valid = true
        if (this.$refs.web_start !== undefined) {
          validate = await this.$refs.web_start.validate();
        }
        let validate3 = {}
        validate3.valid = true
        if (this.$refs.web_start2 !== undefined) {
          validate3 = await this.$refs.web_start2.validate();
        }
        let validate2 = {}
        validate2.valid = true
        if (this.$refs.client_memo !== undefined) {
          validate2 = await this.$refs.client_memo.validate();
        }
        let validate4 = {}
        validate4.valid = true
        if (this.$refs.paper1_copies !== undefined) {
          validate4 = await this.$refs.paper1_copies.validate();
        }

        let validate5 = {}
        validate5.valid = true
        if (this.$refs.paper2_copies !== undefined) {
          validate4 = await this.$refs.paper2_copies.validate();
        }

        setTimeout(() => {
          const errors = Object.entries(this.$refs.observer.errors)
            .map(([key, value]) => ({key, value}))
            .filter(error => error["value"].length);

          if (errors.length > 0) {
            this.$refs.observer.refs[errors[0]["key"]].$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }

        }, 100);

        if (validate.valid && validate2.valid && validate3.valid && validate4.valid && validate5.valid) {
          this.$wait.start('loading')
          this.$store.dispatch('project/UPDATE_PROJECT', {'project': this.project, 'user': this.user }).then(() => {
            // this.is_preview = true
            this.$emit('hidden',  true)
            this.$wait.end('loading')
          }).catch(e => {
            this.$wait.end('loading')
          })
        }

        console.log(validate);

        // const areValid = (await results).every(isValid => isValid);
        // console.log(areValid)



      },
      updateProgress() {
        // this.$store.dispatch('project/NEW_STEP', false)
        this.$wait.start('loading')
        this.$store.dispatch('project/UPDATE_PROJECT', {'project': {progress_status: 1}}).then(() => {
          this.$wait.end('loading')
          window.scrollTo(0,0)
        }).catch(e => {
          this.$wait.end('loading')
        })
      },
      searchAddress() {
        this.searchAddErr= ''
        this.$store.dispatch('project/SEARCH_ADDRESS', this.project.zip_code).then((data) => {
          if (_.isEmpty(this.project.address1)) {
            this.searchAddErr = '郵便番号が存在しません'
          }
        })
      },
      searchAddressPaper() {
        this.searchAddErr1 = ''
        this.$store.dispatch('project/SEARCH_ADDRESS_PAPER', this.project.paper1_zip_code).then(() => {
          if (_.isEmpty(this.project.paper1_address1)) {
            this.searchAddErr1 = '郵便番号が存在しません'
          }
        })
      },
      searchAddressPaper2() {
        this.searchAddErr2 = ''
        this.$store.dispatch('project/SEARCH_ADDRESS_PAPER2', this.project.paper2_zip_code).then(() => {
          if (_.isEmpty(this.project.paper2_address1)) {
            this.searchAddErr2 = '郵便番号が存在しません'
          }
        })
      },
      changeMedium() {
        this.project.web_start = null
        this.project.web_end = null
        this.project.paper_preferred_date = null
        this.project.paper_limit_date = null
        this.project.paper_bcon_receipt_date = null
        this.maxDateValidate = null
        if (this.project.medium_type == 1) {
          this.project.paper_send_type = null
        }

        let dateInput = this.getInputDate()
        if (moment(dateInput).isValid()) {
          this.$set(this.configs.report_preferred_date, 'minDate', dateInput || null);

          this.report_preferred_date = dateInput
        }

        this.project.web_people = null
        this.project.paper_people = null
        this.$forceUpdate()
      },
      logout() {
        if (this.user.type === USER_CONSULTANT) {
          this.logoutTo = 'LoginConsultant'
        } else if (this.user.type === USER_SALES) {
          this.logoutTo = 'LoginSales'
        }
        this.$store.dispatch('consultantUser/AUTH_LOGOUT').then(() => {
        }).catch(e => {

        })
      },
      newStep() {
        this.$store.dispatch('project/NEW_STEP', 1)
        this.$emit('hidden',  false)
      },
      mouseOver() {
        this.fukidashi2 = true
      },
      mouseLeave() {
        this.fukidashi2 = false
      },
      sliced(from, to, data) {
        const sliced = Object.keys(data).slice(from, to).reduce((result, key) => {
          result[key] = data[key];

          return result;
        }, {});
        return sliced
      },
      backInput() {
        this.is_preview = false
        this.$emit('hidden',  false)
        window.scrollTo(0,0)
      },
      onStartChange(e) {
        if (e.date) {
          this.project.report_preferred_date = null
          this.project.web_end = null
          this.getDeadlineSubmit()
          this.$set(this.configs.web_end, 'minDate', false || null);
          this.$set(this.configs.web_end, 'minDate', moment(e.date).add(1, 'days') || null);
          setTimeout(() => {
            if (document.querySelector('.next.disabled')) {
              document.querySelector('.next.disabled').classList.remove('disabled')
            }
          }, 200)
        }
      },
      onEndChange(e) {
        if (e.date) {
          this.project.report_preferred_date = null
          if (this.report_preferred_date < this.project.web_end) {
            this.report_preferred_date = this.project.web_end
            this.$set(this.configs.report_preferred_date, 'minDate', false || null);
            let dateInput = moment(this.project.web_end).add(0, 'days').format('YYYY-MM-DD')
            this.$set(this.configs.report_preferred_date, 'minDate', dateInput || null);
            setTimeout(() => {
              if (document.querySelector('.next.disabled')) {
                document.querySelector('.next.disabled').classList.remove('disabled')
              }
            }, 400)
          }
        }
      },
      onNextChange(e) {
        setTimeout(() => {
          if (document.querySelector('.next.disabled')) {
            document.querySelector('.next.disabled').classList.remove('disabled')
          }
        }, 200)
      },
      onShowChange(e) {
        if (document.querySelector('.next.disabled')) {
          document.querySelector('.next.disabled').classList.remove('disabled')
        }
      },
      onPaperBconChange(e) {
        if (e.date) {
          this.project.report_preferred_date = null
          // this.getDeadlineSubmit()
          if (this.report_preferred_date < this.project.paper_bcon_receipt_date) {
            this.report_preferred_date = this.project.paper_bcon_receipt_date
            this.$set(this.configs.paper_bcon_receipt_date, 'minDate', false || null);
            let dateInput = moment(this.project.paper_bcon_receipt_date).add(0, 'days').format('YYYY-MM-DD')
            this.$set(this.configs.report_preferred_date, 'minDate', dateInput || null);
            setTimeout(() => {
              if (document.querySelector('.next.disabled')) {
                document.querySelector('.next.disabled').classList.remove('disabled')
              }
            }, 200)

          }
        }
      },
      onPaperPrefererChange(e) {
        if (e.date) {
          this.project.report_preferred_date = null
          this.project.paper_limit_date = null
          this.project.paper_bcon_receipt_date = null
          this.$set(this.configs.paper_limit_date, 'minDate', e.date || null);
          this.getDeadlineSubmit()
          this.$set(this.configs.paper_bcon_receipt_date, 'minDate', e.date || null);
          if (this.project.paper_limit_date < this.project.paper_preferred_date) {
            this.project.paper_limit_date = null;
          }
          if (this.project.paper_bcon_receipt_date < this.project.paper_preferred_date) {
            this.project.paper_bcon_receipt_date = null;
          }

          this.$set(this.configs.paper_limit_date, 'minDate', moment(e.date).add(1, 'days') || null);
          this.$set(this.configs.paper_bcon_receipt_date, 'minDate', moment(e.date).add(1, 'days') || null);
        }
      },
      onPaperLimitChange(e) {
        if (e.date) {
          this.project.report_preferred_date = null
          if (this.project.paper_bcon_receipt_date < this.project.paper_limit_date) {
            this.project.paper_bcon_receipt_date = null;
          }
          this.$set(this.configs.paper_bcon_receipt_date, 'minDate', moment(e.date).add(1, 'days') || null);
          // this.$set(this.configs.paper_preferred_date, 'maxDate', e.date || null);
        } else {
          if (moment(this.project.paper_preferred_date).isValid()) {
            this.$set(this.configs.paper_bcon_receipt_date, 'minDate', moment(this.project.paper_preferred_date).add(1, 'days') || null);
          }
        }
      },
      firstData() {
        // let fiveDate = moment().add(6, 'days').format('YYYY-MM-DD')
        let fiveDate = null
        let days = this.getDates(moment().format('YYYY-MM-DD'), moment().add(30, 'days').format('YYYY-MM-DD'))
        days.sort(function(a,b){
          return  new Date(a) - new Date(b);
        });
        let i = 0
        _.forEach(days, (d) => {
          if (!this.holidays.includes(d) && i <= 6) {
            fiveDate = d
            i = i + 1
          }
          // if (i == 6) {
          //   return
          // }
        })

        let dateInput = this.getInputDate()
        if (moment(dateInput).isValid()) {
          this.$set(this.configs.report_preferred_date, 'minDate', dateInput || null);
        }

        this.getDeadlineSubmit()

        this.$set(this.configs.web_start, 'minDate', moment(fiveDate).format('YYYY-MM-DD') || null);
        this.$set(this.configs.web_end, 'minDate', moment(fiveDate).add(1, 'days').format('YYYY-MM-DD') || null);

        let paperDate = this.getPaperDate()
        this.$set(this.configs.paper_preferred_date, 'minDate', paperDate || null);
        this.$set(this.configs.paper_limit_date, 'minDate', paperDate || null);
        this.$set(this.configs.paper_bcon_receipt_date, 'minDate', paperDate || null);

        // this.$set(this.configs.web_start, 'disabledDates', this.holidays || null);
        // this.$set(this.configs.web_end, 'disabledDates', this.holidays || null);
        // this.$set(this.configs.paper_preferred_date, 'disabledDates', this.holidays || null);
        // this.$set(this.configs.paper_bcon_receipt_date, 'disabledDates', this.holidays || null);
        // this.$set(this.configs.report_preferred_date, 'disabledDates', this.holidays || null);

        if ((this.holidays instanceof Array)) {
          this.$set(this.configs.paper_preferred_date, 'disabledDates', this.holidays || null);
        }
        if ((this.holidays instanceof Array)) {
          this.$set(this.configs.paper_bcon_receipt_date, 'disabledDates', this.holidays || null);
        }

        this.$set(this.configs.paper_limit_date, 'disabledDates', false);
        if ((this.holidays instanceof Array)) {
          this.$set(this.configs.web_start, 'disabledDates', this.holidays || null);
        }
        if ((this.holidays instanceof Array)) {
          this.$set(this.configs.web_end, 'disabledDates', this.holidays || null);
        }
        this.$set(this.configs.report_preferred_date, 'disabledDates', false);
        if ((this.holidays instanceof Array)) {
          this.$set(this.configs.report_preferred_date, 'disabledDates', this.holidays || null);
        }
      }
    },
    created() {
    },
    mounted() {
      if (this.project.id) {
        if (this.holidays.length == 0) {
          this.GET_HOLIDAYS().then(() => {
            this.firstData()
          })
        } else {
          this.firstData()
        }

      }

    }
  }
</script>

<style lang="scss" scoped>
  .error {
    &.left-150 {
      margin-left: 150px;
    }
  }
  .width-datepicker{
    width: 300px;
  }

  .scale {
    &.no-margin {
      div {
        margin: 0;
        padding-top: 5px;
        padding-bottom: 5px;
        justify-content: flex-start;
      }
    }
  }

  .no-space {
    padding: 0;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .step1 {
    padding: 15px 100px;
  }
  span {
    &.absolute {
      position: absolute;
      top: 50px;
      left: 102px;
    }
  }
  .fx_ce {
    position: relative;
  }
</style>
