var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.user && _vm.user.id
      ? _c("div", [
          !(_vm.user.type === _vm.USER_CONSULTANT)
            ? _c(
                "div",
                { staticClass: "top_1_2" },
                [
                  _c("div", { staticClass: "fx_ce" }, [
                    _c("img", {
                      attrs: {
                        src: "/images/1_1_logo.png",
                        srcset:
                          "/images/1_1_logo.png" +
                          " 1x, " +
                          "/images/1_1_logo@2x.png" +
                          " 2x",
                        alt: "BCon"
                      }
                    }),
                    _vm._v(" "),
                    _c("h1", [_vm._v(_vm._s(_vm.title))])
                  ]),
                  _vm._v(" "),
                  (_vm.project.progress_status == 2 ||
                    _vm.project.progress_status == 2.1 ||
                    _vm.project.progress_status == 3.0) &&
                  (_vm.newStep == 2 ||
                    _vm.newStep == 2.1 ||
                    _vm.newStep == 3) &&
                  _vm.$route.name !== "ApplyPreview"
                    ? _c(
                        "router-link",
                        {
                          staticClass: "top_btn top_btn_4_1",
                          attrs: { to: { name: "ApplyPreview" } }
                        },
                        [_vm._v("申込内容確認\n      ")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("p", { attrs: { id: "over" } }, [
                    _c(
                      "a",
                      {
                        class: _vm.hover ? "link_1_under" : "link_1",
                        attrs: { href: "javascript:;" },
                        on: { mouseover: _vm.mouseOver }
                      },
                      [
                        _c("img", {
                          staticClass: "login_parson",
                          attrs: {
                            src: "/images/2_3_top.png",
                            alt: "ログイン",
                            srcset:
                              "/images/2_3_top.png" +
                              " 1x, " +
                              "/images/2_3_top@2x.png" +
                              " 2x"
                          }
                        }),
                        _vm._v(" "),
                        _vm.user && _vm.user.name
                          ? _c("span", [_vm._v(_vm._s(_vm.user.name))])
                          : _vm._e()
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        class: { hover_text: true, no: !_vm.hover },
                        attrs: { id: "off" }
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "link_2",
                            attrs: { href: "javascript:;" },
                            on: { click: _vm.logout }
                          },
                          [_vm._v("ログアウト")]
                        )
                      ]
                    )
                  ])
                ],
                1
              )
            : _c(
                "div",
                {
                  staticClass: "top_1_2",
                  style: { width: !_vm.margin ? "auto" : "1000px" }
                },
                [
                  _vm.back
                    ? _c("div", { staticClass: "fx_ce" }, [
                        _c(
                          "p",
                          { staticClass: "top_6_2" },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: _vm.back
                                    ? _vm.back
                                    : { name: "ProjectList" }
                                }
                              },
                              [_vm._v("< リスト一覧")]
                            )
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.title
                    ? _c(
                        "router-link",
                        {
                          staticClass: "fx_ce",
                          attrs: {
                            to:
                              _vm.back !== null
                                ? _vm.back
                                : { name: "ProjectList" }
                          }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: "/images/1_1_logo.png",
                              srcset:
                                "/images/1_1_logo.png" +
                                " 1x, " +
                                "/images/1_1_logo@2x.png" +
                                " 2x",
                              alt: "BCon"
                            }
                          }),
                          _c("h1", [_vm._v(_vm._s(_vm.title))])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.back == null && !_vm.title
                    ? _c("div", [
                        _c(
                          "nav",
                          {
                            staticClass:
                              "navbar navbar-expand-lg navbar-light bg-light",
                            staticStyle: { "margin-bottom": "30px" }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "collapse navbar-collapse",
                                attrs: { id: "navbarNavAltMarkup" }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "navbar-nav",
                                    staticStyle: { "font-size": "14px" }
                                  },
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "nav-item nav-link",
                                        attrs: {
                                          to: { name: "ConsultantUserList" }
                                        }
                                      },
                                      [_vm._v("管理者")]
                                    ),
                                    _vm._v(" |\n              "),
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "nav-item nav-link",
                                        attrs: { to: { name: "SalesList" } }
                                      },
                                      [_vm._v("営業一覧")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            )
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("p", { attrs: { id: "over" } }, [
                    _c(
                      "a",
                      {
                        class: _vm.hover ? "link_1_under" : "link_1",
                        attrs: { href: "javascript:;" },
                        on: { mouseover: _vm.mouseOver }
                      },
                      [
                        _c("img", {
                          staticClass: "login_parson",
                          attrs: {
                            src: "/images_2/1_2_top.png",
                            alt: "ログイン",
                            srcset:
                              "/images_2/1_2_top.png" +
                              " 1x, " +
                              "/images_2/1_2_top@2x.png" +
                              " 2x"
                          }
                        }),
                        _vm.user && _vm.user.name
                          ? _c("span", [_vm._v(_vm._s(_vm.user.name))])
                          : _vm._e()
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        class: { hover_text: true, no: !_vm.hover },
                        attrs: { id: "off" }
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "link_2",
                            attrs: { href: "javascript:;" },
                            on: { click: _vm.logout }
                          },
                          [_vm._v("ログアウト")]
                        )
                      ]
                    )
                  ])
                ],
                1
              )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }