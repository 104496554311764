var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "body_1_2" },
    [
      _c("ValidationObserver", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.is_preview && _vm.project.progress_status == 0,
            expression: "!is_preview && project.progress_status == 0"
          }
        ],
        ref: "observer",
        attrs: { tag: "form" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            _vm.agree ? _vm.saveProject() : ""
          }
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var invalid = ref.invalid
              return [
                _c("div", { staticClass: "form_box form_box_2_3" }, [
                  _c("div", { staticClass: "form_box_title" }, [
                    _c("p", [_vm._v("申込内容入力")])
                  ]),
                  _vm._v(" "),
                  _c("dl", {}, [
                    _c("dt", { staticClass: "required required10 height_20" }, [
                      _vm._v("組織名（会社名）")
                    ]),
                    _vm._v(" "),
                    _c(
                      "dd",
                      { staticClass: "margin_top_10" },
                      [
                        _c("ValidationProvider", {
                          staticClass: "validate",
                          attrs: {
                            mode: _vm.agree ? "eager" : "passive",
                            name: "client_name",
                            rules: "required|max:50"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.project.client_name,
                                          expression: "project.client_name"
                                        }
                                      ],
                                      class: {
                                        form_text: true,
                                        error: errors[0]
                                      },
                                      attrs: {
                                        name: "client_name",
                                        placeholder: "",
                                        type: "text",
                                        maxlength: "50"
                                      },
                                      domProps: {
                                        value: _vm.project.client_name
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.project,
                                            "client_name",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("br", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: errors[0],
                                          expression: "errors[0]"
                                        }
                                      ]
                                    }),
                                    _c("span", { staticClass: "error" }, [
                                      _vm._v(_vm._s(errors[0]))
                                    ])
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("dl", [
                    _c("dt", { staticClass: "height_21" }, [
                      _vm._v("所属・役職")
                    ]),
                    _vm._v(" "),
                    _c("dd", [
                      _c("div", { staticClass: "fx_ce" }, [
                        _c("p", { staticClass: "position" }, [
                          _vm._v("所属\n                "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.user.division,
                                expression: "user.division"
                              }
                            ],
                            class: { form_text: true },
                            attrs: {
                              type: "text",
                              name: "division",
                              value: "○○○部",
                              maxlength: "20"
                            },
                            domProps: { value: _vm.user.division },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.user,
                                  "division",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "position" }, [
                          _vm._v("役職\n                "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.user.position,
                                expression: "user.position"
                              }
                            ],
                            class: { form_text: true },
                            attrs: {
                              type: "text",
                              name: "position",
                              value: "部長",
                              maxlength: "20"
                            },
                            domProps: { value: _vm.user.position },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.user,
                                  "position",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("dl", [
                    _c(
                      "dt",
                      { staticClass: "required required8 height_22 top" },
                      [_vm._v("氏名")]
                    ),
                    _vm._v(" "),
                    _c(
                      "dd",
                      { staticClass: "margin_top_10" },
                      [
                        _c("ValidationProvider", {
                          staticClass: "validate",
                          attrs: { name: "username", rules: "required|max:20" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.user.name,
                                          expression: "user.name"
                                        }
                                      ],
                                      class: {
                                        form_text: true,
                                        error: errors[0]
                                      },
                                      attrs: {
                                        type: "text",
                                        name: "name",
                                        value: "田中一郎",
                                        maxlength: "20"
                                      },
                                      domProps: { value: _vm.user.name },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.user,
                                            "name",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("br", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: errors[0],
                                          expression: "errors[0]"
                                        }
                                      ]
                                    }),
                                    _c("span", { staticClass: "error" }, [
                                      _vm._v(_vm._s(errors[0]))
                                    ])
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("dl", {}, [
                    _c("dt", { staticClass: "required required7 top" }, [
                      _vm._v("電話番号")
                    ]),
                    _vm._v(" "),
                    _c(
                      "dd",
                      {},
                      [
                        _c("ValidationProvider", {
                          staticClass: "validate",
                          attrs: {
                            mode: _vm.agree ? "eager" : "passive",
                            name: "client_tel",
                            rules: {
                              required: true,
                              numeric: true,
                              regex: /^[0-9a-zA-Z]*$/
                            }
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.project.client_tel,
                                          expression: "project.client_tel"
                                        }
                                      ],
                                      class: {
                                        "form_text tel_number": true,
                                        error: errors[0]
                                      },
                                      attrs: {
                                        name: "client_tel",
                                        placeholder: "例：0332873421",
                                        type: "tel",
                                        pattern: "[0-9]+",
                                        maxlength: "11",
                                        onKeyUp:
                                          "$(this).val($(this).val().replace(/[^\\d]/ig, ''))",
                                        autocomplete: "none"
                                      },
                                      domProps: {
                                        value: _vm.project.client_tel
                                      },
                                      on: {
                                        keypress: function($event) {
                                          return _vm.preventAlphabetInput(
                                            $event
                                          )
                                        },
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.project,
                                            "client_tel",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("br"),
                                    _c("span", [
                                      _vm._v("※半角数字のみ入力してください。")
                                    ]),
                                    _vm._v(" "),
                                    _c("br", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: errors[0],
                                          expression: "errors[0]"
                                        }
                                      ]
                                    }),
                                    _c("span", { staticClass: "error" }, [
                                      _vm._v(_vm._s(errors[0]))
                                    ])
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("dl", {}, [
                    _c(
                      "dt",
                      { staticClass: "required required8 form_box_2_3_dt top" },
                      [_vm._v("住所"), _c("br")]
                    ),
                    _vm._v(" "),
                    _c("dd", { staticClass: "margin_dd" }, [
                      _c(
                        "p",
                        [
                          _c("ValidationProvider", {
                            staticClass: "validate label_p",
                            attrs: {
                              mode: _vm.agree ? "eager" : "passive",
                              name: "zip_code",
                              rules: {
                                required: true,
                                numeric: true,
                                regex: /^[0-9]*$/
                              }
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var errors = ref.errors
                                    return [
                                      _vm._v("\n              〒"),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.project.zip_code,
                                            expression: "project.zip_code"
                                          }
                                        ],
                                        class: {
                                          "form_text form_address": true,
                                          error: errors[0]
                                        },
                                        attrs: {
                                          name: "zip_code",
                                          placeholder: "例：1000005",
                                          type: "tel",
                                          pattern: "[0-9]+",
                                          maxlength: "11",
                                          autocomplete: "none",
                                          onKeyUp:
                                            "$(this).val($(this).val().replace(/[^\\d]/ig, ''))"
                                        },
                                        domProps: {
                                          value: _vm.project.zip_code
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.project,
                                              "zip_code",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("input", {
                                        staticClass: "input_button",
                                        attrs: {
                                          type: "button",
                                          value: "住所検索"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.searchAddress()
                                          }
                                        }
                                      }),
                                      _c("span", {}),
                                      _c("br"),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          "※半角数字のみでハイフンを入れずに入力してください。"
                                        )
                                      ])
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.searchAddErr
                        ? _c("div", { staticStyle: { color: "#DB484A" } }, [
                            _c("span", { staticClass: "error" }, [
                              _vm._v(_vm._s(_vm.searchAddErr))
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "p",
                        {},
                        [
                          _c("ValidationProvider", {
                            staticClass: "validate",
                            attrs: {
                              mode: _vm.agree ? "eager" : "passive",
                              name: "address1",
                              rules: "required"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var errors = ref.errors
                                    return [
                                      _vm._v(
                                        "\n              都道府県\n              "
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.project.address1,
                                            expression: "project.address1"
                                          }
                                        ],
                                        class: {
                                          "form_text form_address_1": true,
                                          error: errors[0]
                                        },
                                        attrs: {
                                          maxlength: "10",
                                          placeholder: "例：東京都",
                                          type: "text"
                                        },
                                        domProps: {
                                          value: _vm.project.address1
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.project,
                                              "address1",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("br", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: errors[0],
                                            expression: "errors[0]"
                                          }
                                        ]
                                      }),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: errors[0],
                                              expression: "errors[0]"
                                            }
                                          ],
                                          staticClass: "error left-150"
                                        },
                                        [_vm._v(_vm._s(errors[0]))]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        {},
                        [
                          _c("ValidationProvider", {
                            staticClass: "validate",
                            attrs: {
                              mode: _vm.agree ? "eager" : "passive",
                              name: "address2",
                              rules: "required"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var errors = ref.errors
                                    return [
                                      _vm._v(
                                        "\n              市区町村番地\n              "
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.project.address2,
                                            expression: "project.address2"
                                          }
                                        ],
                                        class: {
                                          "form_text form_address_2": true,
                                          error: errors[0]
                                        },
                                        attrs: {
                                          maxlength: "50",
                                          placeholder:
                                            "例：千代田区丸の内　1-7-12",
                                          type: "text"
                                        },
                                        domProps: {
                                          value: _vm.project.address2
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.project,
                                              "address2",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("br", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: errors[0],
                                            expression: "errors[0]"
                                          }
                                        ]
                                      }),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: errors[0],
                                              expression: "errors[0]"
                                            }
                                          ],
                                          staticClass: "error left-150"
                                        },
                                        [_vm._v(_vm._s(errors[0]))]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("p", {}, [
                        _c("span", { staticClass: "validate" }, [
                          _vm._v("\n                ビル名\n                "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.project.address3,
                                expression: "project.address3"
                              }
                            ],
                            staticClass: "form_text form_address_3",
                            attrs: {
                              placeholder: "例：サピアタワー　18F",
                              maxlength: "50",
                              type: "text"
                            },
                            domProps: { value: _vm.project.address3 },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.project,
                                  "address3",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("dl", { staticClass: "radio_2" }, [
                    _c(
                      "dt",
                      {
                        staticClass: "required required9 form_box_2_3_dt_1 top"
                      },
                      [_vm._v("実施方法")]
                    ),
                    _vm._v(" "),
                    _c("dd", [
                      _c(
                        "p",
                        { staticClass: "margin_dd" },
                        [
                          _c("ValidationProvider", {
                            staticClass: "validate",
                            attrs: {
                              mode: _vm.agree ? "eager" : "passive",
                              name: "medium_type",
                              rules: "required"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var errors = ref.errors
                                    return [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.project.medium_type,
                                            expression: "project.medium_type"
                                          }
                                        ],
                                        class: {
                                          form_radio: true,
                                          error: errors[0]
                                        },
                                        attrs: {
                                          id: "medium_type1",
                                          name: "medium_type",
                                          type: "radio",
                                          value: "1"
                                        },
                                        domProps: {
                                          checked: _vm._q(
                                            _vm.project.medium_type,
                                            "1"
                                          )
                                        },
                                        on: {
                                          change: [
                                            function($event) {
                                              return _vm.$set(
                                                _vm.project,
                                                "medium_type",
                                                "1"
                                              )
                                            },
                                            function($event) {
                                              return _vm.changeMedium()
                                            }
                                          ]
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "label",
                                        {
                                          staticClass: "form-check-label",
                                          attrs: { for: "medium_type1" }
                                        },
                                        [_vm._v("Web")]
                                      ),
                                      _vm._v(" "),
                                      _vm.project.project_type !== 5
                                        ? _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.project.medium_type,
                                                expression:
                                                  "project.medium_type"
                                              }
                                            ],
                                            class: {
                                              form_radio: true,
                                              error: errors[0]
                                            },
                                            attrs: {
                                              id: "medium_type2",
                                              name: "medium_type",
                                              type: "radio",
                                              value: "2"
                                            },
                                            domProps: {
                                              checked: _vm._q(
                                                _vm.project.medium_type,
                                                "2"
                                              )
                                            },
                                            on: {
                                              change: [
                                                function($event) {
                                                  return _vm.$set(
                                                    _vm.project,
                                                    "medium_type",
                                                    "2"
                                                  )
                                                },
                                                function($event) {
                                                  return _vm.changeMedium()
                                                }
                                              ]
                                            }
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.project.project_type !== 5
                                        ? _c(
                                            "label",
                                            {
                                              staticClass: "form-check-label",
                                              attrs: { for: "medium_type2" }
                                            },
                                            [_vm._v("質問紙")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.project.project_type !== 5
                                        ? _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.project.medium_type,
                                                expression:
                                                  "project.medium_type"
                                              }
                                            ],
                                            class: {
                                              form_radio: true,
                                              error: errors[0]
                                            },
                                            attrs: {
                                              id: "medium_type3",
                                              name: "medium_type",
                                              type: "radio",
                                              value: "3"
                                            },
                                            domProps: {
                                              checked: _vm._q(
                                                _vm.project.medium_type,
                                                "3"
                                              )
                                            },
                                            on: {
                                              change: [
                                                function($event) {
                                                  return _vm.$set(
                                                    _vm.project,
                                                    "medium_type",
                                                    "3"
                                                  )
                                                },
                                                function($event) {
                                                  return _vm.changeMedium()
                                                }
                                              ]
                                            }
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.project.project_type !== 5
                                        ? _c(
                                            "label",
                                            {
                                              staticClass: "form-check-label",
                                              attrs: { for: "medium_type3" }
                                            },
                                            [_vm._v("Web + 質問紙")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("br", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: errors[0],
                                            expression: "errors[0]"
                                          }
                                        ]
                                      }),
                                      _c("span", { staticClass: "error" }, [
                                        _vm._v(_vm._s(errors[0]))
                                      ])
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "charge_box" },
                        [
                          _vm.project.medium_type == 1
                            ? _c("ValidationProvider", {
                                ref: "web_start",
                                staticClass: "validate no-padding",
                                attrs: {
                                  mode: _vm.agree ? "eager" : "passive",
                                  name: "web_people",
                                  rules: {
                                    max_value: 1000,
                                    required: _vm.isRequired
                                  }
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var errors = ref.errors
                                        return [
                                          _c(
                                            "p",
                                            { attrs: { id: "web_box" } },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.project.web_people,
                                                    expression:
                                                      "project.web_people"
                                                  }
                                                ],
                                                class: {
                                                  "form_text form_radio_text web_box_text": true,
                                                  error: errors[0]
                                                },
                                                attrs: {
                                                  name: "web_people",
                                                  onKeyUp:
                                                    "$(this).val($(this).val().replace(/[^\\d]/ig, ''))",
                                                  type: "tel",
                                                  pattern: "[0-9]+",
                                                  maxlength: "4",
                                                  autocomplete: "none"
                                                },
                                                domProps: {
                                                  value: _vm.project.web_people
                                                },
                                                on: {
                                                  keypress: function($event) {
                                                    return _vm.preventAlphabetInput(
                                                      $event
                                                    )
                                                  },
                                                  input: [
                                                    function($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        _vm.project,
                                                        "web_people",
                                                        $event.target.value
                                                      )
                                                    },
                                                    _vm.webPeople
                                                  ]
                                                }
                                              }),
                                              _vm._v(" 名"),
                                              _c("br"),
                                              _c("span", [
                                                _vm._v(
                                                  "※想定人数で構いません。"
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _c("br"),
                                              _c("span", [
                                                _vm._v(
                                                  "※半角数字のみ入力してください。"
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _c("br", {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: errors[0],
                                                    expression: "errors[0]"
                                                  }
                                                ]
                                              }),
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value: errors[0],
                                                      expression: "errors[0]"
                                                    }
                                                  ],
                                                  staticClass: "error"
                                                },
                                                [_vm._v(_vm._s(errors[0]))]
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.project.medium_type == 2
                            ? _c("ValidationProvider", {
                                ref: "web_start",
                                staticClass: "validate no-padding",
                                attrs: {
                                  mode: _vm.agree ? "eager" : "passive",
                                  name: "paper_people",
                                  rules: {
                                    max_value: 1000,
                                    required: _vm.isRequired
                                  }
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var errors = ref.errors
                                        return [
                                          _c(
                                            "p",
                                            { attrs: { id: "web_box1" } },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.project.paper_people,
                                                    expression:
                                                      "project.paper_people"
                                                  }
                                                ],
                                                class: {
                                                  "form_text form_radio_text web_box_text": true,
                                                  error: errors[0]
                                                },
                                                attrs: {
                                                  name: "paper_people",
                                                  onKeyUp:
                                                    "$(this).val($(this).val().replace(/[^\\d]/ig, ''))",
                                                  type: "tel",
                                                  pattern: "[0-9]+",
                                                  maxlength: "4",
                                                  autocomplete: "none"
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.project.paper_people
                                                },
                                                on: {
                                                  keypress: function($event) {
                                                    return _vm.preventAlphabetInput(
                                                      $event
                                                    )
                                                  },
                                                  input: [
                                                    function($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        _vm.project,
                                                        "paper_people",
                                                        $event.target.value
                                                      )
                                                    },
                                                    _vm.paperPeople
                                                  ]
                                                }
                                              }),
                                              _vm._v(" 名"),
                                              _c("br"),
                                              _c("span", [
                                                _vm._v(
                                                  "※想定人数で構いません。"
                                                )
                                              ]),
                                              _c("br"),
                                              _c("span", [
                                                _vm._v(
                                                  "※半角数字のみ入力してください。"
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _c("br", {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: errors[0],
                                                    expression: "errors[0]"
                                                  }
                                                ]
                                              }),
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value: errors[0],
                                                      expression: "errors[0]"
                                                    }
                                                  ],
                                                  staticClass: "error"
                                                },
                                                [_vm._v(_vm._s(errors[0]))]
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.project.medium_type == 3
                            ? _c("ValidationProvider", {
                                ref: "web_start",
                                staticClass: "validate no-padding",
                                attrs: {
                                  mode: _vm.agree ? "eager" : "passive",
                                  name: "web_people",
                                  rules: {
                                    max_value: 1000,
                                    required: _vm.isRequired
                                  }
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var errors = ref.errors
                                        return [
                                          _c(
                                            "p",
                                            { attrs: { id: "web_box2" } },
                                            [
                                              _vm._v(
                                                "　　Web\n\n                "
                                              ),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.project.web_people,
                                                    expression:
                                                      "project.web_people"
                                                  }
                                                ],
                                                class: {
                                                  "form_text form_radio_text web_box_text": true,
                                                  error: errors[0]
                                                },
                                                attrs: {
                                                  name: "web_people",
                                                  onKeyUp:
                                                    "$(this).val($(this).val().replace(/[^\\d]/ig, ''))",
                                                  type: "tel",
                                                  pattern: "[0-9]+",
                                                  maxlength: "4",
                                                  autocomplete: "none"
                                                },
                                                domProps: {
                                                  value: _vm.project.web_people
                                                },
                                                on: {
                                                  keypress: function($event) {
                                                    return _vm.preventAlphabetInput(
                                                      $event
                                                    )
                                                  },
                                                  input: [
                                                    function($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        _vm.project,
                                                        "web_people",
                                                        $event.target.value
                                                      )
                                                    },
                                                    _vm.webPeople
                                                  ]
                                                }
                                              }),
                                              _vm._v(" 名\n                "),
                                              _c("br"),
                                              _c("span", [
                                                _vm._v(
                                                  "※想定人数で構いません。"
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _c("br"),
                                              _c("span", [
                                                _vm._v(
                                                  "※半角数字のみ入力してください。"
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _c("br", {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: errors[0],
                                                    expression: "errors[0]"
                                                  }
                                                ]
                                              }),
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value: errors[0],
                                                      expression: "errors[0]"
                                                    }
                                                  ],
                                                  staticClass: "error"
                                                },
                                                [_vm._v(_vm._s(errors[0]))]
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.project.medium_type == 3
                            ? _c("ValidationProvider", {
                                ref: "web_start2",
                                staticClass: "validate no-padding",
                                attrs: {
                                  mode: _vm.agree ? "eager" : "passive",
                                  name: "paper_people",
                                  rules: {
                                    max_value: 1000,
                                    required: _vm.isRequired
                                  }
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var errors = ref.errors
                                        return [
                                          _c(
                                            "p",
                                            { attrs: { id: "web_box3" } },
                                            [
                                              _vm._v(
                                                "　　質問紙\n              "
                                              ),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.project.paper_people,
                                                    expression:
                                                      "project.paper_people"
                                                  }
                                                ],
                                                class: {
                                                  "form_text form_radio_text web_box_text": true,
                                                  error: errors[0]
                                                },
                                                attrs: {
                                                  name: "paper_people",
                                                  onKeyUp:
                                                    "$(this).val($(this).val().replace(/[^\\d]/ig, ''))",
                                                  type: "tel",
                                                  pattern: "[0-9]+",
                                                  maxlength: "4",
                                                  autocomplete: "none"
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.project.paper_people
                                                },
                                                on: {
                                                  keypress: function($event) {
                                                    return _vm.preventAlphabetInput(
                                                      $event
                                                    )
                                                  },
                                                  input: [
                                                    function($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        _vm.project,
                                                        "paper_people",
                                                        $event.target.value
                                                      )
                                                    },
                                                    _vm.paperPeople
                                                  ]
                                                }
                                              }),
                                              _vm._v(" 名\n              "),
                                              _c("br"),
                                              _c("span", [
                                                _vm._v(
                                                  "※半角数字のみ入力してください。"
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _c("br", {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: errors[0],
                                                    expression: "errors[0]"
                                                  }
                                                ]
                                              }),
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value: errors[0],
                                                      expression: "errors[0]"
                                                    }
                                                  ],
                                                  staticClass: "error"
                                                },
                                                [_vm._v(_vm._s(errors[0]))]
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _vm.project.medium_type == 2 || _vm.project.medium_type == 3
                    ? _c("dl", { staticClass: "radio_2" }, [
                        _c("dt", { staticClass: "required required3" }, [
                          _vm._v("質問紙の送付先拠点数")
                        ]),
                        _vm._v(" "),
                        _c(
                          "dd",
                          [
                            _c("ValidationProvider", {
                              staticStyle: { display: "flex" },
                              attrs: {
                                mode: _vm.agree ? "eager" : "passive",
                                name: "paper_send_type",
                                rules: "required"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.project.paper_send_type,
                                              expression:
                                                "project.paper_send_type"
                                            }
                                          ],
                                          class: {
                                            form_radio: true,
                                            error: errors[0]
                                          },
                                          attrs: {
                                            id: "paper_send_type1",
                                            name: "paper_send_type",
                                            type: "radio",
                                            value: "1"
                                          },
                                          domProps: {
                                            checked: _vm._q(
                                              _vm.project.paper_send_type,
                                              "1"
                                            )
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.$set(
                                                _vm.project,
                                                "paper_send_type",
                                                "1"
                                              )
                                            }
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "label",
                                          {
                                            staticClass: "form-check-label",
                                            attrs: { for: "paper_send_type1" }
                                          },
                                          [_vm._v("1拠点")]
                                        ),
                                        _vm._v(" "),
                                        !(
                                          (_vm.project.project_type >= 1 &&
                                            _vm.project.project_type <= 6) ||
                                          (_vm.project.project_type >= 21 &&
                                            _vm.project.project_type <= 24)
                                        )
                                          ? _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.project.paper_send_type,
                                                  expression:
                                                    "project.paper_send_type"
                                                }
                                              ],
                                              class: {
                                                form_radio: true,
                                                error: errors[0]
                                              },
                                              attrs: {
                                                id: "paper_send_type2",
                                                name: "paper_send_type",
                                                type: "radio",
                                                value: "2"
                                              },
                                              domProps: {
                                                checked: _vm._q(
                                                  _vm.project.paper_send_type,
                                                  "2"
                                                )
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.$set(
                                                    _vm.project,
                                                    "paper_send_type",
                                                    "2"
                                                  )
                                                }
                                              }
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        !(
                                          (_vm.project.project_type >= 1 &&
                                            _vm.project.project_type <= 6) ||
                                          (_vm.project.project_type >= 21 &&
                                            _vm.project.project_type <= 24)
                                        )
                                          ? _c(
                                              "label",
                                              {
                                                staticClass: "form-check-label",
                                                attrs: {
                                                  for: "paper_send_type2"
                                                }
                                              },
                                              [_vm._v("2拠点")]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        !(
                                          (_vm.project.project_type >= 1 &&
                                            _vm.project.project_type <= 6) ||
                                          (_vm.project.project_type >= 21 &&
                                            _vm.project.project_type <= 24)
                                        )
                                          ? _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.project.paper_send_type,
                                                  expression:
                                                    "project.paper_send_type"
                                                }
                                              ],
                                              class: {
                                                form_radio: true,
                                                error: errors[0]
                                              },
                                              attrs: {
                                                id: "paper_send_type3",
                                                name: "paper_send_type",
                                                type: "radio",
                                                value: "3"
                                              },
                                              domProps: {
                                                checked: _vm._q(
                                                  _vm.project.paper_send_type,
                                                  "3"
                                                )
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.$set(
                                                    _vm.project,
                                                    "paper_send_type",
                                                    "3"
                                                  )
                                                }
                                              }
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        !(
                                          (_vm.project.project_type >= 1 &&
                                            _vm.project.project_type <= 6) ||
                                          (_vm.project.project_type >= 21 &&
                                            _vm.project.project_type <= 24)
                                        )
                                          ? _c(
                                              "label",
                                              {
                                                staticClass: "form-check-label",
                                                attrs: {
                                                  for: "paper_send_type3"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "3拠点以上(日程は目安となります。)"
                                                ),
                                                _c("br"),
                                                _vm._v(
                                                  "※３拠点以上の場合は申込完了後に担当者からご連絡致します。"
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c("br", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: errors[0],
                                              expression: "errors[0]"
                                            }
                                          ]
                                        }),
                                        _c("span", { staticClass: "error" }, [
                                          _vm._v(_vm._s(errors[0]))
                                        ])
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.project.paper_send_type == 1 ||
                  _vm.project.paper_send_type == 2
                    ? _c("dl", {}, [
                        _c(
                          "dt",
                          {
                            staticClass:
                              "required required81 form_box_2_3_dt2 top"
                          },
                          [_vm._v("質問紙の宛先①")]
                        ),
                        _vm._v(" "),
                        _c(
                          "dd",
                          { staticClass: "margin_dd" },
                          [
                            _c("span", { staticClass: "margin_l_2_3_3" }, [
                              _vm._v(
                                "※納品先と質問紙の納付先が異なる場合は、入力し直してください。"
                              )
                            ]),
                            _vm._v(" "),
                            _c("ValidationProvider", {
                              staticClass: "validate",
                              attrs: {
                                mode: _vm.agree ? "eager" : "passive",
                                name: "paper1_division",
                                rules: "required"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("p", [
                                          _vm._v("所属"),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.project.paper1_division,
                                                expression:
                                                  "project.paper1_division"
                                              }
                                            ],
                                            class: {
                                              "form_text tel_number": true,
                                              error: errors[0]
                                            },
                                            attrs: {
                                              type: "text",
                                              name: "paper1_division",
                                              placeholder: "○○○部"
                                            },
                                            domProps: {
                                              value: _vm.project.paper1_division
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.project,
                                                  "paper1_division",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        ])
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _vm._v(" "),
                            _c("ValidationProvider", {
                              staticClass: "validate",
                              attrs: {
                                mode: _vm.agree ? "eager" : "passive",
                                name: "paper1_position"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("p", [
                                          _vm._v("役職"),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.project.paper1_position,
                                                expression:
                                                  "project.paper1_position"
                                              }
                                            ],
                                            class: {
                                              "form_text tel_number": true,
                                              error: errors[0]
                                            },
                                            attrs: {
                                              type: "text",
                                              name: "paper1_position",
                                              placeholder: "部長"
                                            },
                                            domProps: {
                                              value: _vm.project.paper1_position
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.project,
                                                  "paper1_position",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        ])
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _vm._v(" "),
                            _c("ValidationProvider", {
                              staticClass: "validate",
                              attrs: {
                                mode: _vm.agree ? "eager" : "passive",
                                name: "paper1_name",
                                rules: "required"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("p", [
                                          _vm._v("氏名"),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.project.paper1_name,
                                                expression:
                                                  "project.paper1_name"
                                              }
                                            ],
                                            class: {
                                              "form_text tel_number": true,
                                              error: errors[0]
                                            },
                                            attrs: {
                                              type: "text",
                                              name: "paper1_name",
                                              placeholder: "田中一郎"
                                            },
                                            domProps: {
                                              value: _vm.project.paper1_name
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.project,
                                                  "paper1_name",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        ])
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _vm._v(" "),
                            _c(
                              "p",
                              [
                                _c("ValidationProvider", {
                                  staticClass: "validate",
                                  attrs: {
                                    mode: _vm.agree ? "eager" : "passive",
                                    name: "paper1_zip_code",
                                    rules: "required"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _vm._v("\n              〒"),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.project.paper1_zip_code,
                                                  expression:
                                                    "project.paper1_zip_code"
                                                }
                                              ],
                                              class: {
                                                "form_text form_address": true,
                                                error: errors[0]
                                              },
                                              attrs: {
                                                name: "paper1_zip_code",
                                                placeholder: "例：1000005",
                                                type: "tel",
                                                pattern: "[0-9]+",
                                                maxlength: "11",
                                                autocomplete: "none",
                                                onKeyUp:
                                                  "$(this).val($(this).val().replace(/[^\\d]/ig, ''))"
                                              },
                                              domProps: {
                                                value:
                                                  _vm.project.paper1_zip_code
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.project,
                                                    "paper1_zip_code",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("input", {
                                              staticClass: "input_button",
                                              attrs: {
                                                type: "button",
                                                value: "住所検索"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.searchAddressPaper()
                                                }
                                              }
                                            }),
                                            _c("span", {}),
                                            _c("br"),
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(
                                                "※半角数字のみでハイフンを入れずに入力してください。"
                                              )
                                            ])
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.searchAddErr1
                              ? _c(
                                  "div",
                                  { staticStyle: { color: "#DB484A" } },
                                  [
                                    _c("span", { staticClass: "error" }, [
                                      _vm._v(_vm._s(_vm.searchAddErr1))
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "p",
                              {},
                              [
                                _c("ValidationProvider", {
                                  staticClass: "validate",
                                  attrs: {
                                    mode: _vm.agree ? "eager" : "passive",
                                    name: "paper1_address1",
                                    rules: "required"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _vm._v(
                                              "\n              都道府県\n              "
                                            ),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.project.paper1_address1,
                                                  expression:
                                                    "project.paper1_address1"
                                                }
                                              ],
                                              class: {
                                                "form_text form_address_1": true,
                                                error: errors[0]
                                              },
                                              attrs: {
                                                maxlength: "10",
                                                name: "paper1_address1",
                                                placeholder: "例：東京都",
                                                type: "text"
                                              },
                                              domProps: {
                                                value:
                                                  _vm.project.paper1_address1
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.project,
                                                    "paper1_address1",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("br", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: errors[0],
                                                  expression: "errors[0]"
                                                }
                                              ]
                                            }),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: errors[0],
                                                    expression: "errors[0]"
                                                  }
                                                ],
                                                staticClass: "error left-150"
                                              },
                                              [_vm._v(_vm._s(errors[0]))]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "p",
                              {},
                              [
                                _c("ValidationProvider", {
                                  staticClass: "validate",
                                  attrs: {
                                    mode: _vm.agree ? "eager" : "passive",
                                    name: "paper1_address2",
                                    rules: "required"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _vm._v(
                                              "\n              市区町村番地\n              "
                                            ),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.project.paper1_address2,
                                                  expression:
                                                    "project.paper1_address2"
                                                }
                                              ],
                                              class: {
                                                "form_text form_address_2": true,
                                                error: errors[0]
                                              },
                                              attrs: {
                                                maxlength: "50",
                                                name: "paper1_address2",
                                                placeholder:
                                                  "例：千代田区丸の内　1-7-12",
                                                type: "text"
                                              },
                                              domProps: {
                                                value:
                                                  _vm.project.paper1_address2
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.project,
                                                    "paper1_address2",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("br", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: errors[0],
                                                  expression: "errors[0]"
                                                }
                                              ]
                                            }),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: errors[0],
                                                    expression: "errors[0]"
                                                  }
                                                ],
                                                staticClass: "error left-150"
                                              },
                                              [_vm._v(_vm._s(errors[0]))]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("p", {}, [
                              _c("span", { staticClass: "validate" }, [
                                _vm._v(
                                  "\n              ビル名\n              "
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.project.paper1_address3,
                                      expression: "project.paper1_address3"
                                    }
                                  ],
                                  staticClass: "form_text form_address_3",
                                  attrs: {
                                    placeholder: "例：サピアタワー　18F",
                                    maxlength: "50",
                                    type: "text"
                                  },
                                  domProps: {
                                    value: _vm.project.paper1_address3
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.project,
                                        "paper1_address3",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ])
                            ]),
                            _vm._v(" "),
                            !(
                              (_vm.project.project_type >= 1 &&
                                _vm.project.project_type <= 6) ||
                              (_vm.project.project_type >= 21 &&
                                _vm.project.project_type <= 24)
                            )
                              ? _c("p", [
                                  _vm._v("部数\n            "),
                                  _c(
                                    "strong",
                                    [
                                      _c("ValidationProvider", {
                                        ref: "paper1_copies",
                                        staticClass: "validate",
                                        attrs: {
                                          mode: _vm.agree ? "eager" : "passive",
                                          name: "paper1_copies",
                                          rules:
                                            "required|numeric|max_value:10000"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.project
                                                            .paper1_copies,
                                                        expression:
                                                          "project.paper1_copies"
                                                      }
                                                    ],
                                                    class: {
                                                      "form_text form_text_2_3_2": true,
                                                      error: errors[0]
                                                    },
                                                    attrs: {
                                                      name: "paper1_copies",
                                                      placeholder: "",
                                                      type: "number",
                                                      max: "10000",
                                                      onKeyUp:
                                                        "$(this).val($(this).val().replace(/[^\\d]/ig, ''))",
                                                      autocomplete: "none"
                                                    },
                                                    domProps: {
                                                      value:
                                                        _vm.project
                                                          .paper1_copies
                                                    },
                                                    on: {
                                                      keypress: function(
                                                        $event
                                                      ) {
                                                        return _vm.preventAlphabetInput(
                                                          $event
                                                        )
                                                      },
                                                      input: function($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          _vm.project,
                                                          "paper1_copies",
                                                          $event.target.value
                                                        )
                                                      }
                                                    }
                                                  }),
                                                  _vm._v(
                                                    " 部\n                "
                                                  ),
                                                  _c("br", {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value: errors[0],
                                                        expression: "errors[0]"
                                                      }
                                                    ]
                                                  }),
                                                  _c(
                                                    "span",
                                                    { staticClass: "error" },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ])
                              : _vm._e()
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.project.paper_send_type == 2 &&
                  _vm.project.project_type >= 11 &&
                    _vm.project.project_type <= 16
                    ? _c("dl", {}, [
                        _c(
                          "dt",
                          {
                            staticClass:
                              "required required81 form_box_2_3_dt2 top"
                          },
                          [_vm._v("質問紙の宛先②")]
                        ),
                        _vm._v(" "),
                        _c(
                          "dd",
                          { staticClass: "margin_dd" },
                          [
                            _c("ValidationProvider", {
                              staticClass: "validate",
                              attrs: {
                                mode: _vm.agree ? "eager" : "passive",
                                name: "paper2_division",
                                rules: "required"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("p", [
                                          _vm._v("所属"),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.project.paper2_division,
                                                expression:
                                                  "project.paper2_division"
                                              }
                                            ],
                                            class: {
                                              "form_text tel_number": true,
                                              error: errors[0]
                                            },
                                            attrs: {
                                              type: "text",
                                              name: "paper2_division",
                                              placeholder: "○○○部"
                                            },
                                            domProps: {
                                              value: _vm.project.paper2_division
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.project,
                                                  "paper2_division",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        ])
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _vm._v(" "),
                            _c("ValidationProvider", {
                              staticClass: "validate",
                              attrs: {
                                mode: _vm.agree ? "eager" : "passive",
                                name: "paper2_position"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("p", [
                                          _vm._v("役職"),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.project.paper2_position,
                                                expression:
                                                  "project.paper2_position"
                                              }
                                            ],
                                            class: {
                                              "form_text tel_number": true,
                                              error: errors[0]
                                            },
                                            attrs: {
                                              type: "text",
                                              name: "paper2_position",
                                              placeholder: "部長"
                                            },
                                            domProps: {
                                              value: _vm.project.paper2_position
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.project,
                                                  "paper2_position",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        ])
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _vm._v(" "),
                            _c("ValidationProvider", {
                              staticClass: "validate",
                              attrs: {
                                mode: _vm.agree ? "eager" : "passive",
                                name: "paper2_name",
                                rules: "required"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("p", [
                                          _vm._v("氏名"),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.project.paper2_name,
                                                expression:
                                                  "project.paper2_name"
                                              }
                                            ],
                                            class: {
                                              "form_text tel_number": true,
                                              error: errors[0]
                                            },
                                            attrs: {
                                              type: "text",
                                              name: "paper2_name",
                                              placeholder: "田中一郎"
                                            },
                                            domProps: {
                                              value: _vm.project.paper2_name
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.project,
                                                  "paper2_name",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        ])
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _vm._v(" "),
                            _c(
                              "p",
                              [
                                _c("ValidationProvider", {
                                  staticClass: "validate",
                                  attrs: {
                                    mode: _vm.agree ? "eager" : "passive",
                                    name: "paper2_zip_code",
                                    rules: "required"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _vm._v("\n              〒"),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.project.paper2_zip_code,
                                                  expression:
                                                    "project.paper2_zip_code"
                                                }
                                              ],
                                              class: {
                                                "form_text form_address": true,
                                                error: errors[0]
                                              },
                                              attrs: {
                                                name: "paper2_zip_code",
                                                placeholder: "例：1000005",
                                                type: "tel",
                                                pattern: "[0-9]+",
                                                maxlength: "11",
                                                autocomplete: "none",
                                                onKeyUp:
                                                  "$(this).val($(this).val().replace(/[^\\d]/ig, ''))"
                                              },
                                              domProps: {
                                                value:
                                                  _vm.project.paper2_zip_code
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.project,
                                                    "paper2_zip_code",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("input", {
                                              staticClass: "input_button",
                                              attrs: {
                                                type: "button",
                                                value: "住所検索"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.searchAddressPaper2()
                                                }
                                              }
                                            }),
                                            _c("span", {}),
                                            _c("br"),
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(
                                                "※半角数字のみでハイフンを入れずに入力してください。"
                                              )
                                            ])
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            ),
                            _vm.searchAddErr2
                              ? _c(
                                  "div",
                                  { staticStyle: { color: "#DB484A" } },
                                  [
                                    _c("span", { staticClass: "error" }, [
                                      _vm._v(_vm._s(_vm.searchAddErr2))
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("p"),
                            _vm._v(" "),
                            _c(
                              "p",
                              {},
                              [
                                _c("ValidationProvider", {
                                  staticClass: "validate",
                                  attrs: {
                                    mode: _vm.agree ? "eager" : "passive",
                                    name: "paper2_address1",
                                    rules: "required"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _vm._v(
                                              "\n              都道府県\n              "
                                            ),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.project.paper2_address1,
                                                  expression:
                                                    "project.paper2_address1"
                                                }
                                              ],
                                              class: {
                                                "form_text form_address_1": true,
                                                error: errors[0]
                                              },
                                              attrs: {
                                                maxlength: "10",
                                                placeholder: "例：東京都",
                                                type: "text"
                                              },
                                              domProps: {
                                                value:
                                                  _vm.project.paper2_address1
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.project,
                                                    "paper2_address1",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("br", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: errors[0],
                                                  expression: "errors[0]"
                                                }
                                              ]
                                            }),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: errors[0],
                                                    expression: "errors[0]"
                                                  }
                                                ],
                                                staticClass: "error left-150"
                                              },
                                              [_vm._v(_vm._s(errors[0]))]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "p",
                              {},
                              [
                                _c("ValidationProvider", {
                                  staticClass: "validate",
                                  attrs: {
                                    mode: _vm.agree ? "eager" : "passive",
                                    name: "paper2_address2",
                                    rules: "required"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _vm._v(
                                              "\n              市区町村番地\n              "
                                            ),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.project.paper2_address2,
                                                  expression:
                                                    "project.paper2_address2"
                                                }
                                              ],
                                              class: {
                                                "form_text form_address_2": true,
                                                error: errors[0]
                                              },
                                              attrs: {
                                                maxlength: "50",
                                                placeholder:
                                                  "例：千代田区丸の内　1-7-12",
                                                type: "text"
                                              },
                                              domProps: {
                                                value:
                                                  _vm.project.paper2_address2
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.project,
                                                    "paper2_address2",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("br", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: errors[0],
                                                  expression: "errors[0]"
                                                }
                                              ]
                                            }),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: errors[0],
                                                    expression: "errors[0]"
                                                  }
                                                ],
                                                staticClass: "error left-150"
                                              },
                                              [_vm._v(_vm._s(errors[0]))]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("p", {}, [
                              _c("span", { staticClass: "validate" }, [
                                _vm._v(
                                  "\n              ビル名\n              "
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.project.paper2_address3,
                                      expression: "project.paper2_address3"
                                    }
                                  ],
                                  staticClass: "form_text form_address_3",
                                  attrs: {
                                    placeholder: "例：サピアタワー　18F",
                                    maxlength: "50",
                                    type: "text"
                                  },
                                  domProps: {
                                    value: _vm.project.paper2_address3
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.project,
                                        "paper2_address3",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ])
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v("部数\n            "),
                              _c(
                                "strong",
                                [
                                  _c("ValidationProvider", {
                                    ref: "paper2_copies",
                                    staticClass: "validate",
                                    attrs: {
                                      mode: _vm.agree ? "eager" : "passive",
                                      name: "paper2_copies",
                                      rules: "required|numeric|max_value:10000"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var errors = ref.errors
                                            return [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.project.paper2_copies,
                                                    expression:
                                                      "project.paper2_copies"
                                                  }
                                                ],
                                                class: {
                                                  "form_text form_text_2_3_2": true,
                                                  error: errors[0]
                                                },
                                                attrs: {
                                                  name: "paper2_copies",
                                                  placeholder: "",
                                                  type: "number",
                                                  max: "10000",
                                                  onKeyUp:
                                                    "$(this).val($(this).val().replace(/[^\\d]/ig, ''))",
                                                  autocomplete: "none"
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.project.paper2_copies
                                                },
                                                on: {
                                                  keypress: function($event) {
                                                    return _vm.preventAlphabetInput(
                                                      $event
                                                    )
                                                  },
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      _vm.project,
                                                      "paper2_copies",
                                                      $event.target.value
                                                    )
                                                  }
                                                }
                                              }),
                                              _vm._v(" 部\n                "),
                                              _c("br", {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: errors[0],
                                                    expression: "errors[0]"
                                                  }
                                                ]
                                              }),
                                              _c(
                                                "span",
                                                { staticClass: "error" },
                                                [_vm._v(_vm._s(errors[0]))]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  })
                                ],
                                1
                              )
                            ])
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("dl", { staticClass: "fx" }, [
                    _c(
                      "dt",
                      {
                        staticClass: "required required10 height_up_2_3_4 top"
                      },
                      [_vm._v("回答スケジュール\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "dd",
                      { staticClass: "margin_dd position_r_2_3 no-padding" },
                      [
                        _c("div", { staticClass: "form_box_2_4" }, [
                          _c(
                            "p",
                            {
                              staticClass:
                                "form_box_2_4_p form_box_2_3_p margin_t5"
                            },
                            [
                              _c("span", [
                                _vm.project.project_type >= 11 &&
                                _vm.project.project_type <= 16
                                  ? _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "margin-left": "0",
                                          padding: "0"
                                        }
                                      },
                                      [_vm._v("対象者リスト")]
                                    )
                                  : _vm._e(),
                                _vm.project.project_type >= 1 &&
                                _vm.project.project_type <= 6
                                  ? _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "margin-left": "0",
                                          padding: "0"
                                        }
                                      },
                                      [_vm._v("対象者リスト")]
                                    )
                                  : _vm._e(),
                                _vm.project.project_type >= 21 &&
                                _vm.project.project_type <= 24
                                  ? _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "margin-left": "0",
                                          padding: "0"
                                        }
                                      },
                                      [_vm._v("多面組み合わせリスト")]
                                    )
                                  : _vm._e(),
                                _vm._v(
                                  "提出締切日　" + _vm._s(_vm.deadlineSubmit)
                                )
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "margin_top_2_3" }, [
                            _vm.project.medium_type == 1
                              ? _c(
                                  "div",
                                  [
                                    _c("ValidationProvider", {
                                      staticClass:
                                        "validate margin_b_2_4 margin_b_2_3 fx_ce",
                                      attrs: {
                                        mode: _vm.agree ? "eager" : "passive",
                                        name: "web_start1",
                                        rules: "required"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("p", [
                                                  _vm._v(
                                                    "Web回答\n                    "
                                                  ),
                                                  _c("br"),
                                                  _vm._v("開始日")
                                                ]),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "input-group stick_2_3 form_sch2_3_4 width-datepicker"
                                                  },
                                                  [
                                                    _c("date-picker", {
                                                      class: {
                                                        "form_text form_sch": true,
                                                        error: errors[0]
                                                      },
                                                      attrs: {
                                                        disabled: !_vm.project
                                                          .web_people,
                                                        name:
                                                          "project.web_start1",
                                                        wrap: true,
                                                        config:
                                                          _vm.configs.web_start
                                                      },
                                                      on: {
                                                        "dp-change":
                                                          _vm.onStartChange
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.project.web_start,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.project,
                                                            "web_start",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "project.web_start"
                                                      }
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "input-group-append cal_icon"
                                                      },
                                                      [
                                                        _c("button", {
                                                          staticClass:
                                                            "btn datepickerbutton",
                                                          attrs: {
                                                            type: "button"
                                                          }
                                                        })
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(
                                                    "※メール配信開始は、11:00～13:00です。"
                                                  )
                                                ])
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    }),
                                    _vm._v(" "),
                                    _c("ValidationProvider", {
                                      staticClass:
                                        "validate margin_b_2_4 margin_b_2_3 fx_ce",
                                      attrs: {
                                        disabled: !_vm.empty(
                                          _vm.project.web_start
                                        ),
                                        mode: _vm.agree ? "eager" : "passive",
                                        name: "web_end1",
                                        rules: { required: true }
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("p", [
                                                  _vm._v(
                                                    "Web回答\n                    "
                                                  ),
                                                  _c("br"),
                                                  _vm._v("終了日")
                                                ]),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "input-group form_sch2_3_4 width-datepicker"
                                                  },
                                                  [
                                                    _c("date-picker", {
                                                      class: {
                                                        "form_text form_sch": true,
                                                        error: errors[0]
                                                      },
                                                      attrs: {
                                                        id: "web_end1",
                                                        disabled: !_vm.project
                                                          .web_start,
                                                        name:
                                                          "project.web_end1",
                                                        wrap: true,
                                                        config:
                                                          _vm.configs.web_end
                                                      },
                                                      on: {
                                                        "dp-change":
                                                          _vm.onEndChange,
                                                        "dp-update":
                                                          _vm.onNextChange,
                                                        "dp-show":
                                                          _vm.onShowChange
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.project.web_end,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.project,
                                                            "web_end",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "project.web_end"
                                                      }
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "input-group-append cal_icon"
                                                      },
                                                      [
                                                        _c("button", {
                                                          staticClass:
                                                            "btn datepickerbutton",
                                                          attrs: {
                                                            type: "button"
                                                          }
                                                        })
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(
                                                    "※WEB回答終了時刻は、17:00です。"
                                                  )
                                                ])
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.project.medium_type == 2
                              ? _c(
                                  "div",
                                  [
                                    _c("ValidationProvider", {
                                      staticClass:
                                        "validate margin_b_2_4 margin_b_2_3 fx_ce",
                                      attrs: {
                                        mode: _vm.agree ? "eager" : "passive",
                                        name: "paper_preferred_date2",
                                        rules: "required"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("p", [
                                                  _vm._v(
                                                    "質問紙到着\n                    "
                                                  ),
                                                  _c("br"),
                                                  _vm._v("希望日")
                                                ]),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "input-group margin_l_2_5 stick_2_3_4 width-datepicker"
                                                  },
                                                  [
                                                    _c("date-picker", {
                                                      class: {
                                                        "form_text form_sch 2": true,
                                                        error: errors[0]
                                                      },
                                                      attrs: {
                                                        disabled: !_vm.project
                                                          .paper_people,
                                                        name:
                                                          "project.paper_preferred_date2",
                                                        wrap: true,
                                                        config:
                                                          _vm.configs
                                                            .paper_preferred_date
                                                      },
                                                      on: {
                                                        "dp-change":
                                                          _vm.onPaperPrefererChange,
                                                        "dp-update":
                                                          _vm.onNextChange,
                                                        "dp-show":
                                                          _vm.onShowChange
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.project
                                                            .paper_preferred_date,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.project,
                                                            "paper_preferred_date",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "project.paper_preferred_date"
                                                      }
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "input-group-append cal_icon"
                                                      },
                                                      [
                                                        _c("button", {
                                                          staticClass:
                                                            "btn datepickerbutton",
                                                          attrs: {
                                                            type: "button"
                                                          }
                                                        })
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    }),
                                    _vm._v(" "),
                                    _c("ValidationProvider", {
                                      staticClass:
                                        "validate margin_b_2_4 margin_b_2_3 fx_ce",
                                      attrs: {
                                        mode: _vm.agree ? "eager" : "passive",
                                        name: "paper_limit_date2",
                                        rules: ""
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("p", [
                                                  _vm._v(
                                                    "質問紙提出期限\n                    "
                                                  ),
                                                  _c("br"),
                                                  _vm._v("(任意)")
                                                ]),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "input-group margin_l_2_7 stick_2_3_4 width-datepicker"
                                                  },
                                                  [
                                                    _c("date-picker", {
                                                      class: {
                                                        "form_text form_sch 2": true
                                                      },
                                                      attrs: {
                                                        disabled: !_vm.project
                                                          .paper_preferred_date,
                                                        name:
                                                          "project.paper_limit_date2",
                                                        wrap: true,
                                                        config:
                                                          _vm.configs
                                                            .paper_limit_date
                                                      },
                                                      on: {
                                                        "dp-change":
                                                          _vm.onPaperLimitChange
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.project
                                                            .paper_limit_date,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.project,
                                                            "paper_limit_date",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "project.paper_limit_date"
                                                      }
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "input-group-append cal_icon"
                                                      },
                                                      [
                                                        _c("button", {
                                                          staticClass:
                                                            "btn datepickerbutton",
                                                          attrs: {
                                                            type: "button"
                                                          }
                                                        })
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "p",
                                                  { staticClass: "no-margin" },
                                                  [
                                                    _c("img", {
                                                      staticClass: "info_2",
                                                      attrs: {
                                                        src: "images/info.png",
                                                        srcset:
                                                          "images/info.png" +
                                                          " 1x, " +
                                                          "images/info@2x.png" +
                                                          " 2x",
                                                        alt:
                                                          "インフォメーション"
                                                      },
                                                      on: {
                                                        mouseover:
                                                          _vm.mouseOver,
                                                        mouseleave:
                                                          _vm.mouseLeave
                                                      }
                                                    })
                                                  ]
                                                ),
                                                _c(
                                                  "p",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value: _vm.fukidashi2,
                                                        expression: "fukidashi2"
                                                      }
                                                    ],
                                                    staticClass:
                                                      "info_fukidashi_2"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "質問紙に職場での回収期限を"
                                                    ),
                                                    _c("br"),
                                                    _vm._v(
                                                      "記載したい場合に入力してください。\n                  "
                                                    )
                                                  ]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    }),
                                    _vm._v(" "),
                                    _c("ValidationProvider", {
                                      staticClass:
                                        "validate margin_b_2_4 margin_b_2_3 fx_ce",
                                      attrs: {
                                        mode: _vm.agree ? "eager" : "passive",
                                        name: "paper_bcon_receipt_date2",
                                        rules: "required"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("p", [
                                                  _vm._v(
                                                    "質問紙BCon\n                    "
                                                  ),
                                                  _c("br"),
                                                  _vm._v("到着日")
                                                ]),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "input-group margin_l_2_6 width-datepicker"
                                                  },
                                                  [
                                                    _c("date-picker", {
                                                      class: {
                                                        "form_text form_sch 2": true,
                                                        error: errors[0]
                                                      },
                                                      attrs: {
                                                        disabled: !_vm.project
                                                          .paper_preferred_date,
                                                        name:
                                                          "project.paper_bcon_receipt_date2",
                                                        wrap: true,
                                                        config:
                                                          _vm.configs
                                                            .paper_bcon_receipt_date
                                                      },
                                                      on: {
                                                        "dp-change":
                                                          _vm.onPaperBconChange,
                                                        "dp-update":
                                                          _vm.onNextChange,
                                                        "dp-show":
                                                          _vm.onShowChange
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.project
                                                            .paper_bcon_receipt_date,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.project,
                                                            "paper_bcon_receipt_date",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "project.paper_bcon_receipt_date"
                                                      }
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "input-group-append cal_icon"
                                                      },
                                                      [
                                                        _c("button", {
                                                          staticClass:
                                                            "btn datepickerbutton",
                                                          attrs: {
                                                            type: "button"
                                                          }
                                                        })
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.project.medium_type == 3
                              ? _c(
                                  "div",
                                  [
                                    _c("ValidationProvider", {
                                      staticClass:
                                        "validate margin_b_2_4 margin_b_2_3 fx_ce",
                                      attrs: {
                                        mode: _vm.agree ? "eager" : "passive",
                                        name: "web_start3",
                                        rules: "required"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("p", [
                                                  _vm._v(
                                                    "Web回答\n                    "
                                                  ),
                                                  _c("br"),
                                                  _vm._v("開始日")
                                                ]),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "input-group form_sch2_3_4 stick_2_3_4 width-datepicker"
                                                  },
                                                  [
                                                    _c("date-picker", {
                                                      class: {
                                                        "form_text form_sch": true,
                                                        error: errors[0]
                                                      },
                                                      attrs: {
                                                        disabled:
                                                          !_vm.project
                                                            .web_people ||
                                                          !_vm.project
                                                            .paper_people,
                                                        name:
                                                          "project.web_start3",
                                                        wrap: true,
                                                        config:
                                                          _vm.configs.web_start
                                                      },
                                                      on: {
                                                        "dp-change":
                                                          _vm.onStartChange,
                                                        "dp-update":
                                                          _vm.onNextChange,
                                                        "dp-show":
                                                          _vm.onShowChange
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.project.web_start,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.project,
                                                            "web_start",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "project.web_start"
                                                      }
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "input-group-append cal_icon"
                                                      },
                                                      [
                                                        _c("button", {
                                                          staticClass:
                                                            "btn datepickerbutton",
                                                          attrs: {
                                                            type: "button"
                                                          }
                                                        })
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(
                                                    "※メール配信開始は、11:00～13:00です"
                                                  )
                                                ])
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    }),
                                    _vm._v(" "),
                                    _c("ValidationProvider", {
                                      staticClass:
                                        "validate margin_b_2_4 margin_b_2_3 fx_ce",
                                      attrs: {
                                        mode: _vm.agree ? "eager" : "passive",
                                        name: "web_end3",
                                        rules: "required"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("p", [
                                                  _vm._v(
                                                    "Web回答\n                    "
                                                  ),
                                                  _c("br"),
                                                  _vm._v("終了日")
                                                ]),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "input-group form_sch2_3_4 width-datepicker"
                                                  },
                                                  [
                                                    _c("date-picker", {
                                                      class: {
                                                        "form_text form_sch": true,
                                                        error: errors[0]
                                                      },
                                                      attrs: {
                                                        disabled: !_vm.project
                                                          .web_start,
                                                        name:
                                                          "project.web_end3",
                                                        wrap: true,
                                                        config:
                                                          _vm.configs.web_end
                                                      },
                                                      on: {
                                                        "dp-change":
                                                          _vm.onEndChange,
                                                        "dp-update":
                                                          _vm.onNextChange,
                                                        "dp-show":
                                                          _vm.onShowChange
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.project.web_end,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.project,
                                                            "web_end",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "project.web_end"
                                                      }
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "input-group-append cal_icon"
                                                      },
                                                      [
                                                        _c("button", {
                                                          staticClass:
                                                            "btn datepickerbutton",
                                                          attrs: {
                                                            type: "button"
                                                          }
                                                        })
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(
                                                    "※WEB回答終了時刻は、17:00です。"
                                                  )
                                                ])
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    }),
                                    _vm._v(" "),
                                    _c("hr"),
                                    _vm._v(" "),
                                    _c("ValidationProvider", {
                                      staticClass:
                                        "validate margin_b_2_4 margin_b_2_3 fx_ce",
                                      attrs: {
                                        mode: _vm.agree ? "eager" : "passive",
                                        name: "paper_preferred_date3",
                                        rules: "required"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("p", [
                                                  _vm._v(
                                                    "質問紙到着\n                    "
                                                  ),
                                                  _c("br"),
                                                  _vm._v("希望日")
                                                ]),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "input-group margin_l_2_5 stick_2_3_4 width-datepicker"
                                                  },
                                                  [
                                                    _c("date-picker", {
                                                      class: {
                                                        "form_text form_sch": true,
                                                        error: errors[0]
                                                      },
                                                      attrs: {
                                                        disabled:
                                                          !_vm.project
                                                            .web_people ||
                                                          !_vm.project
                                                            .paper_people,
                                                        name:
                                                          "project.paper_preferred_date3",
                                                        wrap: true,
                                                        config:
                                                          _vm.configs
                                                            .paper_preferred_date
                                                      },
                                                      on: {
                                                        "dp-change":
                                                          _vm.onPaperPrefererChange,
                                                        "dp-update":
                                                          _vm.onNextChange,
                                                        "dp-show":
                                                          _vm.onShowChange
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.project
                                                            .paper_preferred_date,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.project,
                                                            "paper_preferred_date",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "project.paper_preferred_date"
                                                      }
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "input-group-append cal_icon"
                                                      },
                                                      [
                                                        _c("button", {
                                                          staticClass:
                                                            "btn datepickerbutton",
                                                          attrs: {
                                                            type: "button"
                                                          }
                                                        })
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    }),
                                    _vm._v(" "),
                                    _c("ValidationProvider", {
                                      staticClass:
                                        "validate margin_b_2_4 margin_b_2_3 fx_ce",
                                      attrs: {
                                        mode: _vm.agree ? "eager" : "passive",
                                        name: "paper_limit_date3",
                                        rules: ""
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("p", [
                                                  _vm._v(
                                                    "質問紙提出期限\n                    "
                                                  ),
                                                  _c("br"),
                                                  _vm._v("(任意)")
                                                ]),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "input-group margin_l_2_7 stick_2_3_4 width-datepicker"
                                                  },
                                                  [
                                                    _c("date-picker", {
                                                      class: {
                                                        "form_text form_sch": true
                                                      },
                                                      attrs: {
                                                        disabled: !_vm.project
                                                          .paper_preferred_date,
                                                        name:
                                                          "project.paper_limit_date3",
                                                        wrap: true,
                                                        config:
                                                          _vm.configs
                                                            .paper_limit_date
                                                      },
                                                      on: {
                                                        "dp-change":
                                                          _vm.onPaperLimitChange,
                                                        "dp-update":
                                                          _vm.onNextChange,
                                                        "dp-show":
                                                          _vm.onShowChange
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.project
                                                            .paper_limit_date,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.project,
                                                            "paper_limit_date",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "project.paper_limit_date"
                                                      }
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "input-group-append cal_icon"
                                                      },
                                                      [
                                                        _c("button", {
                                                          staticClass:
                                                            "btn datepickerbutton",
                                                          attrs: {
                                                            type: "button"
                                                          }
                                                        })
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "p",
                                                  { staticClass: "no-margin" },
                                                  [
                                                    _c("img", {
                                                      staticClass: "info_2",
                                                      attrs: {
                                                        src: "images/info.png",
                                                        srcset:
                                                          "images/info.png" +
                                                          " 1x, " +
                                                          "images/info@2x.png" +
                                                          " 2x",
                                                        alt:
                                                          "インフォメーション"
                                                      },
                                                      on: {
                                                        mouseover:
                                                          _vm.mouseOver,
                                                        mouseleave:
                                                          _vm.mouseLeave
                                                      }
                                                    })
                                                  ]
                                                ),
                                                _c(
                                                  "p",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value: _vm.fukidashi2,
                                                        expression: "fukidashi2"
                                                      }
                                                    ],
                                                    staticClass:
                                                      "info_fukidashi_3"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "質問紙に職場での回収期限を"
                                                    ),
                                                    _c("br"),
                                                    _vm._v(
                                                      "記載したい場合に入力してください。\n                  "
                                                    )
                                                  ]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    }),
                                    _vm._v(" "),
                                    _c("ValidationProvider", {
                                      staticClass:
                                        "validate margin_b_2_4 margin_b_2_3 fx_ce position_r_2_3",
                                      attrs: {
                                        mode: _vm.agree ? "eager" : "passive",
                                        name: "paper_bcon_receipt_date3",
                                        rules: "required"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("p", [
                                                  _vm._v(
                                                    "質問紙BCon\n                    "
                                                  ),
                                                  _c("br"),
                                                  _vm._v("到着日")
                                                ]),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "input-group margin_l_2_6 width-datepicker"
                                                  },
                                                  [
                                                    _c("date-picker", {
                                                      class: {
                                                        "form_text form_sch": true,
                                                        error: errors[0]
                                                      },
                                                      attrs: {
                                                        disabled: !_vm.project
                                                          .paper_preferred_date,
                                                        name:
                                                          "project.paper_bcon_receipt_date3",
                                                        wrap: true,
                                                        config:
                                                          _vm.configs
                                                            .paper_bcon_receipt_date
                                                      },
                                                      on: {
                                                        "dp-change":
                                                          _vm.onPaperBconChange,
                                                        "dp-update":
                                                          _vm.onNextChange,
                                                        "dp-show":
                                                          _vm.onShowChange
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.project
                                                            .paper_bcon_receipt_date,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.project,
                                                            "paper_bcon_receipt_date",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "project.paper_bcon_receipt_date"
                                                      }
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "input-group-append cal_icon"
                                                      },
                                                      [
                                                        _c("button", {
                                                          staticClass:
                                                            "btn datepickerbutton",
                                                          attrs: {
                                                            type: "button"
                                                          }
                                                        })
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value: errors[0],
                                                        expression: "errors[0]"
                                                      }
                                                    ],
                                                    staticClass:
                                                      "error absolute"
                                                  },
                                                  [_vm._v(_vm._s(errors[0]))]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.project.medium_type == 3
                              ? _c(
                                  "p",
                                  {
                                    staticClass: "position_ant_2_3",
                                    style: { paddingTop: "10px" }
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        "※到着が遅れた場合、報告書納品が遅れる場合がございます。"
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ])
                        ])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("dl", { staticClass: "fx" }, [
                    _c("dt", { staticClass: "required required32 top" }, [
                      _vm._v("報告書納品希望日")
                    ]),
                    _vm._v(" "),
                    _c("dd", { staticClass: "margin_dd" }, [
                      _c("span", { staticClass: "f12" }, [
                        _vm._v(
                          _vm._s(
                            _vm.project.medium_type == 1
                              ? "Web回答終了日時を起点に報告書納品が算出されます。"
                              : "質問紙BCon到着日を起点に報告書納品日が算出されます。"
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "fx_ce2" }, [
                        _c(
                          "div",
                          [
                            _c("ValidationProvider", {
                              staticClass: "validate fx_ce",
                              attrs: {
                                mode: _vm.agree ? "eager" : "passive",
                                name: "report_preferred_date",
                                rules: "required"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "input-group margin_b_2_4_2 form_text_2_3 width-datepicker"
                                          },
                                          [
                                            _c("date-picker", {
                                              class: {
                                                "form_text form_sch": true,
                                                error: errors[0]
                                              },
                                              attrs: {
                                                disabled:
                                                  !_vm.project.web_end &&
                                                  !_vm.project
                                                    .paper_bcon_receipt_date,
                                                name:
                                                  "project.report_preferred_date",
                                                wrap: true,
                                                config:
                                                  _vm.configs
                                                    .report_preferred_date
                                              },
                                              on: {
                                                "dp-update": _vm.onNextChange,
                                                "dp-show": _vm.onShowChange
                                              },
                                              model: {
                                                value:
                                                  _vm.project
                                                    .report_preferred_date,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.project,
                                                    "report_preferred_date",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "project.report_preferred_date"
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "input-group-append cal_icon"
                                              },
                                              [
                                                _c("button", {
                                                  staticClass:
                                                    "btn datepickerbutton",
                                                  attrs: { type: "button" }
                                                })
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("br", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: errors[0],
                                              expression: "errors[0]"
                                            }
                                          ]
                                        }),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: errors[0],
                                                expression: "errors[0]"
                                              }
                                            ],
                                            staticClass: "error"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.maxDateValidate) +
                                                _vm._s(errors[0])
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        )
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _vm.project.project_type == 5
                    ? _c("dl", { staticClass: "fx" }, [
                        _c("dt", { staticClass: " top" }, [
                          _vm._v("配信開始日")
                        ]),
                        _vm._v(" "),
                        _c("dd", { staticClass: "margin_dd" }, [
                          _c("div", { staticClass: "fx_ce2" }, [
                            _c(
                              "div",
                              { staticStyle: { "margin-left": "25px" } },
                              [
                                _c("ValidationProvider", {
                                  staticClass: "validate fx_ce",
                                  attrs: {
                                    mode: _vm.agree ? "eager" : "passive",
                                    name: "stream_start_date",
                                    rules: ""
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "input-group margin_b_2_4_2 form_text_2_3 width-datepicker"
                                              },
                                              [
                                                _c("date-picker", {
                                                  class: {
                                                    "form_text form_sch": true,
                                                    error: errors[0]
                                                  },
                                                  attrs: {
                                                    name:
                                                      "project.stream_start_date",
                                                    wrap: true,
                                                    config:
                                                      _vm.configs
                                                        .stream_start_date
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.project
                                                        .stream_start_date,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.project,
                                                        "stream_start_date",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "project.stream_start_date"
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "input-group-append cal_icon"
                                                  },
                                                  [
                                                    _c("button", {
                                                      staticClass:
                                                        "btn datepickerbutton",
                                                      attrs: { type: "button" }
                                                    })
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            )
                          ])
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("dl", { staticClass: "fx" }, [
                    _c("dt", { staticClass: "required required3 top" }, [
                      _vm._v("該当する業種・組織規模")
                    ]),
                    _vm._v(" "),
                    _c(
                      "dd",
                      { staticClass: "margin_dd" },
                      [
                        _c("p", [_vm._v("業種")]),
                        _vm._v(" "),
                        _c("ValidationProvider", {
                          staticClass: "validate fx_between",
                          attrs: {
                            mode: _vm.agree ? "eager" : "passive",
                            name: "industry_type",
                            rules: "required"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c(
                                      "ul",
                                      { staticClass: "industry" },
                                      _vm._l(
                                        _vm.sliced(0, 12, _vm.industry_types),
                                        function(industry_type, index) {
                                          return _c("li", [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.project.industry_type,
                                                  expression:
                                                    "project.industry_type"
                                                }
                                              ],
                                              class: {
                                                form_radio: true,
                                                error: errors[0]
                                              },
                                              attrs: {
                                                id: "industry_type" + index,
                                                name: "industry_type",
                                                type: "radio"
                                              },
                                              domProps: {
                                                value: index,
                                                checked: _vm._q(
                                                  _vm.project.industry_type,
                                                  index
                                                )
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.$set(
                                                    _vm.project,
                                                    "industry_type",
                                                    index
                                                  )
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "label",
                                              {
                                                attrs: {
                                                  for: "industry_type" + index
                                                }
                                              },
                                              [_vm._v(_vm._s(industry_type))]
                                            )
                                          ])
                                        }
                                      ),
                                      0
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "ul",
                                      { staticClass: "industry" },
                                      _vm._l(
                                        _vm.sliced(12, 24, _vm.industry_types),
                                        function(industry_type, index) {
                                          return _c("li", [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.project.industry_type,
                                                  expression:
                                                    "project.industry_type"
                                                }
                                              ],
                                              class: {
                                                form_radio: true,
                                                error: errors[0]
                                              },
                                              attrs: {
                                                id: "industry_type" + index,
                                                name: "industry_type",
                                                type: "radio"
                                              },
                                              domProps: {
                                                value: index,
                                                checked: _vm._q(
                                                  _vm.project.industry_type,
                                                  index
                                                )
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.$set(
                                                    _vm.project,
                                                    "industry_type",
                                                    index
                                                  )
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "label",
                                              {
                                                attrs: {
                                                  for: "industry_type" + index
                                                }
                                              },
                                              [_vm._v(_vm._s(industry_type))]
                                            )
                                          ])
                                        }
                                      ),
                                      0
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "ul",
                                      { staticClass: "industry" },
                                      _vm._l(
                                        _vm.sliced(
                                          24,
                                          _vm.industry_types.length,
                                          _vm.industry_types
                                        ),
                                        function(industry_type, index) {
                                          return _c("li", [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.project.industry_type,
                                                  expression:
                                                    "project.industry_type"
                                                }
                                              ],
                                              class: {
                                                form_radio: true,
                                                error: errors[0]
                                              },
                                              attrs: {
                                                id: "industry_type" + index,
                                                name: "industry_type",
                                                type: "radio"
                                              },
                                              domProps: {
                                                value: index,
                                                checked: _vm._q(
                                                  _vm.project.industry_type,
                                                  index
                                                )
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.$set(
                                                    _vm.project,
                                                    "industry_type",
                                                    index
                                                  )
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "label",
                                              {
                                                attrs: {
                                                  for: "industry_type" + index
                                                }
                                              },
                                              [_vm._v(_vm._s(industry_type))]
                                            )
                                          ])
                                        }
                                      ),
                                      0
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "scale" },
                          [
                            _c("ValidationProvider", {
                              staticClass: "validate",
                              attrs: {
                                mode: _vm.agree ? "eager" : "passive",
                                name: "scale_type",
                                rules: "required"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("p", { staticClass: "margin_dd" }, [
                                          _vm._v("規模")
                                        ]),
                                        _vm._v(" "),
                                        _vm._l(
                                          Math.ceil(
                                            Object.keys(_vm.scale_types)
                                              .length / 4
                                          ),
                                          function(i) {
                                            return _c(
                                              "div",
                                              { staticClass: "scale_1" },
                                              _vm._l(
                                                _vm.sliced(
                                                  (i - 1) * 4,
                                                  i * 4 <
                                                    Object.keys(_vm.scale_types)
                                                      .length
                                                    ? i * 4
                                                    : Object.keys(
                                                        _vm.scale_types
                                                      ).length,
                                                  _vm.scale_types
                                                ),
                                                function(scale_type, index) {
                                                  return _c("p", [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.project
                                                              .scale_type,
                                                          expression:
                                                            "project.scale_type"
                                                        }
                                                      ],
                                                      class: {
                                                        form_radio: true,
                                                        error: errors[0]
                                                      },
                                                      attrs: {
                                                        id:
                                                          "scale_type" + index,
                                                        name: "scale_type",
                                                        type: "radio"
                                                      },
                                                      domProps: {
                                                        value: index,
                                                        checked: _vm._q(
                                                          _vm.project
                                                            .scale_type,
                                                          index
                                                        )
                                                      },
                                                      on: {
                                                        change: function(
                                                          $event
                                                        ) {
                                                          return _vm.$set(
                                                            _vm.project,
                                                            "scale_type",
                                                            index
                                                          )
                                                        }
                                                      }
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "label",
                                                      {
                                                        attrs: {
                                                          for:
                                                            "scale_type" + index
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(scale_type)
                                                        )
                                                      ]
                                                    )
                                                  ])
                                                }
                                              ),
                                              0
                                            )
                                          }
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _vm.project.project_type == 4 ||
                  _vm.project.project_type == 24
                    ? _c("dl", { staticClass: "fx" }, [
                        _c("dt", { staticClass: "required required11 top" }, [
                          _vm._v("対象")
                        ]),
                        _vm._v(" "),
                        _c("dd", {}, [
                          _c(
                            "div",
                            {
                              staticClass: "scale no-margin",
                              staticStyle: {
                                "border-top": "0",
                                "padding-left": "25px"
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "row" },
                                [
                                  _c("ValidationProvider", {
                                    staticClass: "validate col-3 no-space",
                                    attrs: {
                                      mode: _vm.agree ? "eager" : "passive",
                                      name: "is_personnal_target1",
                                      rules: {
                                        required:
                                          _vm.project.is_personnal_target1 !=
                                            1 &&
                                          _vm.project.is_personnal_target2 !=
                                            1 &&
                                          _vm.project.is_personnal_target3 !=
                                            1 &&
                                          _vm.project.is_personnal_target4 !=
                                            1 &&
                                          _vm.project.is_personnal_target5 !=
                                            1 &&
                                          _vm.project.is_personnal_target6 !=
                                            1 &&
                                          _vm.project.is_personnal_target7 != 1
                                      }
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var errors = ref.errors
                                            return [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.project
                                                        .is_personnal_target1,
                                                    expression:
                                                      "project.is_personnal_target1"
                                                  }
                                                ],
                                                class: { error: errors[0] },
                                                attrs: {
                                                  id: "is_personnal_target1",
                                                  type: "checkbox",
                                                  name: "is_personnal_target1"
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm.project
                                                      .is_personnal_target1
                                                  )
                                                    ? _vm._i(
                                                        _vm.project
                                                          .is_personnal_target1,
                                                        null
                                                      ) > -1
                                                    : _vm.project
                                                        .is_personnal_target1
                                                },
                                                on: {
                                                  change: [
                                                    function($event) {
                                                      var $$a =
                                                          _vm.project
                                                            .is_personnal_target1,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm.project,
                                                              "is_personnal_target1",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm.project,
                                                              "is_personnal_target1",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm.project,
                                                          "is_personnal_target1",
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                    function($event) {
                                                      return _vm.uncheckX()
                                                    }
                                                  ]
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "label",
                                                {
                                                  class: {
                                                    error: errors[0],
                                                    check_2_4: true
                                                  },
                                                  attrs: {
                                                    for: "is_personnal_target1"
                                                  }
                                                },
                                                [_vm._v("内定者")]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _vm._v(" "),
                                  _c("ValidationProvider", {
                                    staticClass: "validate col-3 no-space",
                                    attrs: {
                                      mode: _vm.agree ? "eager" : "passive",
                                      name: "is_personnal_target2",
                                      rules: {
                                        required:
                                          _vm.project.is_personnal_target1 !=
                                            1 &&
                                          _vm.project.is_personnal_target2 !=
                                            1 &&
                                          _vm.project.is_personnal_target3 !=
                                            1 &&
                                          _vm.project.is_personnal_target4 !=
                                            1 &&
                                          _vm.project.is_personnal_target5 !=
                                            1 &&
                                          _vm.project.is_personnal_target6 !=
                                            1 &&
                                          _vm.project.is_personnal_target7 != 1
                                      }
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var errors = ref.errors
                                            return [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.project
                                                        .is_personnal_target2,
                                                    expression:
                                                      "project.is_personnal_target2"
                                                  }
                                                ],
                                                class: { error: errors[0] },
                                                attrs: {
                                                  id: "is_personnal_target2",
                                                  type: "checkbox",
                                                  value: "true",
                                                  name: "is_personnal_target2"
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm.project
                                                      .is_personnal_target2
                                                  )
                                                    ? _vm._i(
                                                        _vm.project
                                                          .is_personnal_target2,
                                                        "true"
                                                      ) > -1
                                                    : _vm.project
                                                        .is_personnal_target2
                                                },
                                                on: {
                                                  change: [
                                                    function($event) {
                                                      var $$a =
                                                          _vm.project
                                                            .is_personnal_target2,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = "true",
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm.project,
                                                              "is_personnal_target2",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm.project,
                                                              "is_personnal_target2",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm.project,
                                                          "is_personnal_target2",
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                    function($event) {
                                                      return _vm.uncheckX()
                                                    }
                                                  ]
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "label",
                                                {
                                                  class: {
                                                    error: errors[0],
                                                    check_2_4: true
                                                  },
                                                  attrs: {
                                                    for: "is_personnal_target2"
                                                  }
                                                },
                                                [_vm._v("半年-5年未満")]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _vm._v(" "),
                                  _c("ValidationProvider", {
                                    staticClass: "validate col-3 no-space",
                                    attrs: {
                                      mode: _vm.agree ? "eager" : "passive",
                                      name: "is_personnal_target3",
                                      rules: {
                                        required:
                                          _vm.project.is_personnal_target1 !=
                                            1 &&
                                          _vm.project.is_personnal_target2 !=
                                            1 &&
                                          _vm.project.is_personnal_target3 !=
                                            1 &&
                                          _vm.project.is_personnal_target4 !=
                                            1 &&
                                          _vm.project.is_personnal_target5 !=
                                            1 &&
                                          _vm.project.is_personnal_target6 !=
                                            1 &&
                                          _vm.project.is_personnal_target7 != 1
                                      }
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var errors = ref.errors
                                            return [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.project
                                                        .is_personnal_target3,
                                                    expression:
                                                      "project.is_personnal_target3"
                                                  }
                                                ],
                                                class: { error: errors[0] },
                                                attrs: {
                                                  id: "is_personnal_target3",
                                                  type: "checkbox",
                                                  name: "is_personnal_target3"
                                                },
                                                domProps: {
                                                  value: 1,
                                                  checked: Array.isArray(
                                                    _vm.project
                                                      .is_personnal_target3
                                                  )
                                                    ? _vm._i(
                                                        _vm.project
                                                          .is_personnal_target3,
                                                        1
                                                      ) > -1
                                                    : _vm.project
                                                        .is_personnal_target3
                                                },
                                                on: {
                                                  change: [
                                                    function($event) {
                                                      var $$a =
                                                          _vm.project
                                                            .is_personnal_target3,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = 1,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm.project,
                                                              "is_personnal_target3",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm.project,
                                                              "is_personnal_target3",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm.project,
                                                          "is_personnal_target3",
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                    function($event) {
                                                      return _vm.uncheckX()
                                                    }
                                                  ]
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "label",
                                                {
                                                  class: {
                                                    error: errors[0],
                                                    check_2_4: true
                                                  },
                                                  attrs: {
                                                    for: "is_personnal_target3"
                                                  }
                                                },
                                                [_vm._v("監督職(主任・係長)")]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _vm._v(" "),
                                  _c("ValidationProvider", {
                                    staticClass: "validate col-3 no-space",
                                    attrs: {
                                      mode: _vm.agree ? "eager" : "passive",
                                      name: "is_personnal_target4",
                                      rules: {
                                        required:
                                          _vm.project.is_personnal_target1 !=
                                            1 &&
                                          _vm.project.is_personnal_target2 !=
                                            1 &&
                                          _vm.project.is_personnal_target3 !=
                                            1 &&
                                          _vm.project.is_personnal_target4 !=
                                            1 &&
                                          _vm.project.is_personnal_target5 !=
                                            1 &&
                                          _vm.project.is_personnal_target6 !=
                                            1 &&
                                          _vm.project.is_personnal_target7 != 1
                                      }
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var errors = ref.errors
                                            return [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.project
                                                        .is_personnal_target4,
                                                    expression:
                                                      "project.is_personnal_target4"
                                                  }
                                                ],
                                                class: { error: errors[0] },
                                                attrs: {
                                                  id: "is_personnal_target4",
                                                  type: "checkbox",
                                                  name: "is_personnal_target4"
                                                },
                                                domProps: {
                                                  value: 1,
                                                  checked: Array.isArray(
                                                    _vm.project
                                                      .is_personnal_target4
                                                  )
                                                    ? _vm._i(
                                                        _vm.project
                                                          .is_personnal_target4,
                                                        1
                                                      ) > -1
                                                    : _vm.project
                                                        .is_personnal_target4
                                                },
                                                on: {
                                                  change: [
                                                    function($event) {
                                                      var $$a =
                                                          _vm.project
                                                            .is_personnal_target4,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = 1,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm.project,
                                                              "is_personnal_target4",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm.project,
                                                              "is_personnal_target4",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm.project,
                                                          "is_personnal_target4",
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                    function($event) {
                                                      return _vm.uncheckX()
                                                    }
                                                  ]
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "label",
                                                {
                                                  class: {
                                                    error: errors[0],
                                                    check_2_4: true
                                                  },
                                                  attrs: {
                                                    for: "is_personnal_target4"
                                                  }
                                                },
                                                [_vm._v("役員")]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _vm._v(" "),
                                  _c("ValidationProvider", {
                                    staticClass: "validate col-3 no-space",
                                    attrs: {
                                      mode: _vm.agree ? "eager" : "passive",
                                      name: "is_personnal_target5",
                                      rules: {
                                        required:
                                          _vm.project.is_personnal_target1 !=
                                            1 &&
                                          _vm.project.is_personnal_target2 !=
                                            1 &&
                                          _vm.project.is_personnal_target3 !=
                                            1 &&
                                          _vm.project.is_personnal_target4 !=
                                            1 &&
                                          _vm.project.is_personnal_target5 !=
                                            1 &&
                                          _vm.project.is_personnal_target6 !=
                                            1 &&
                                          _vm.project.is_personnal_target7 != 1
                                      }
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var errors = ref.errors
                                            return [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.project
                                                        .is_personnal_target5,
                                                    expression:
                                                      "project.is_personnal_target5"
                                                  }
                                                ],
                                                class: { error: errors[0] },
                                                attrs: {
                                                  id: "is_personnal_target5",
                                                  type: "checkbox",
                                                  name: "is_personnal_target5"
                                                },
                                                domProps: {
                                                  value: 1,
                                                  checked: Array.isArray(
                                                    _vm.project
                                                      .is_personnal_target5
                                                  )
                                                    ? _vm._i(
                                                        _vm.project
                                                          .is_personnal_target5,
                                                        1
                                                      ) > -1
                                                    : _vm.project
                                                        .is_personnal_target5
                                                },
                                                on: {
                                                  change: [
                                                    function($event) {
                                                      var $$a =
                                                          _vm.project
                                                            .is_personnal_target5,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = 1,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm.project,
                                                              "is_personnal_target5",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm.project,
                                                              "is_personnal_target5",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm.project,
                                                          "is_personnal_target5",
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                    function($event) {
                                                      return _vm.uncheckX()
                                                    }
                                                  ]
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "label",
                                                {
                                                  class: {
                                                    error: errors[0],
                                                    check_2_4: true
                                                  },
                                                  attrs: {
                                                    for: "is_personnal_target5"
                                                  }
                                                },
                                                [_vm._v("新人")]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _vm._v(" "),
                                  _c("ValidationProvider", {
                                    staticClass: "validate col-3 no-space",
                                    attrs: {
                                      mode: _vm.agree ? "eager" : "passive",
                                      name: "is_personnal_target6",
                                      rules: {
                                        required:
                                          _vm.project.is_personnal_target1 !=
                                            1 &&
                                          _vm.project.is_personnal_target2 !=
                                            1 &&
                                          _vm.project.is_personnal_target3 !=
                                            1 &&
                                          _vm.project.is_personnal_target4 !=
                                            1 &&
                                          _vm.project.is_personnal_target5 !=
                                            1 &&
                                          _vm.project.is_personnal_target6 !=
                                            1 &&
                                          _vm.project.is_personnal_target7 != 1
                                      }
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var errors = ref.errors
                                            return [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.project
                                                        .is_personnal_target6,
                                                    expression:
                                                      "project.is_personnal_target6"
                                                  }
                                                ],
                                                class: { error: errors[0] },
                                                attrs: {
                                                  id: "is_personnal_target6",
                                                  type: "checkbox",
                                                  name: "is_personnal_target6"
                                                },
                                                domProps: {
                                                  value: 1,
                                                  checked: Array.isArray(
                                                    _vm.project
                                                      .is_personnal_target6
                                                  )
                                                    ? _vm._i(
                                                        _vm.project
                                                          .is_personnal_target6,
                                                        1
                                                      ) > -1
                                                    : _vm.project
                                                        .is_personnal_target6
                                                },
                                                on: {
                                                  change: [
                                                    function($event) {
                                                      var $$a =
                                                          _vm.project
                                                            .is_personnal_target6,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = 1,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm.project,
                                                              "is_personnal_target6",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm.project,
                                                              "is_personnal_target6",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm.project,
                                                          "is_personnal_target6",
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                    function($event) {
                                                      return _vm.uncheckX()
                                                    }
                                                  ]
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "label",
                                                {
                                                  class: {
                                                    error: errors[0],
                                                    check_2_4: true
                                                  },
                                                  attrs: {
                                                    for: "is_personnal_target6"
                                                  }
                                                },
                                                [_vm._v("5年以上")]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _vm._v(" "),
                                  _c("ValidationProvider", {
                                    staticClass: "validate col-3 no-space",
                                    attrs: {
                                      mode: _vm.agree ? "eager" : "passive",
                                      name: "is_personnal_target7",
                                      rules: {
                                        required:
                                          _vm.project.is_personnal_target1 !=
                                            1 &&
                                          _vm.project.is_personnal_target2 !=
                                            1 &&
                                          _vm.project.is_personnal_target3 !=
                                            1 &&
                                          _vm.project.is_personnal_target4 !=
                                            1 &&
                                          _vm.project.is_personnal_target5 !=
                                            1 &&
                                          _vm.project.is_personnal_target6 !=
                                            1 &&
                                          _vm.project.is_personnal_target7 != 1
                                      }
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var errors = ref.errors
                                            return [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.project
                                                        .is_personnal_target7,
                                                    expression:
                                                      "project.is_personnal_target7"
                                                  }
                                                ],
                                                class: { error: errors[0] },
                                                attrs: {
                                                  id: "is_personnal_target7",
                                                  type: "checkbox",
                                                  name: "is_personnal_target7"
                                                },
                                                domProps: {
                                                  value: 1,
                                                  checked: Array.isArray(
                                                    _vm.project
                                                      .is_personnal_target7
                                                  )
                                                    ? _vm._i(
                                                        _vm.project
                                                          .is_personnal_target7,
                                                        1
                                                      ) > -1
                                                    : _vm.project
                                                        .is_personnal_target7
                                                },
                                                on: {
                                                  change: [
                                                    function($event) {
                                                      var $$a =
                                                          _vm.project
                                                            .is_personnal_target7,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = 1,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm.project,
                                                              "is_personnal_target7",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm.project,
                                                              "is_personnal_target7",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm.project,
                                                          "is_personnal_target7",
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                    function($event) {
                                                      return _vm.uncheckX()
                                                    }
                                                  ]
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "label",
                                                {
                                                  class: {
                                                    error: errors[0],
                                                    check_2_4: true
                                                  },
                                                  attrs: {
                                                    for: "is_personnal_target7"
                                                  }
                                                },
                                                [_vm._v("課長・部長")]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("dl", { staticClass: "fx_normal" }, [
                    _c("dt", { staticClass: "top" }, [_vm._v("備考欄")]),
                    _vm._v(" "),
                    _c(
                      "dd",
                      [
                        _c("ValidationProvider", {
                          ref: "client_memo",
                          staticClass: "validate",
                          attrs: {
                            mode: _vm.agree ? "eager" : "passive",
                            name: "client_memo",
                            rules: { max: 1000 }
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("textarea", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.project.client_memo,
                                          expression: "project.client_memo"
                                        }
                                      ],
                                      class: { error: errors[0] },
                                      attrs: {
                                        name: "remarks",
                                        rows: "7",
                                        cols: "40"
                                      },
                                      domProps: {
                                        value: _vm.project.client_memo
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.project,
                                            "client_memo",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("br", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: errors[0],
                                          expression: "errors[0]"
                                        }
                                      ]
                                    }),
                                    _c("span", { staticClass: "error" }, [
                                      _vm._v(_vm._s(errors[0]))
                                    ])
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "consent" }, [
                  _c("p", [
                    _vm._v(
                      "【本書に記載される情報と本診断で取得する情報の取扱いについて】"
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "consent_waku" }, [
                    _c("p", [
                      _vm._v(
                        "1.　利用目的及び提供：株式会社ビジネスコンサルタント（以下「BCon」といいます。）は、本書に記載される情報及び本診断で取得及び発生する情報（組織及び個人に関する情報を含み、以下総称して「対象情報」といいます。）を以下の目的で利用致します｡"
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v("\n          ①お申込みご担当者の確認"),
                      _c("br"),
                      _vm._v("\n          ②本診断に係るお申込み手続き"),
                      _c("br"),
                      _vm._v("\n          ③ご回答者の確認"),
                      _c("br"),
                      _vm._v("\n          ④本診断の実施"),
                      _c("br"),
                      _vm._v("\n          ⑤本診断報告書の作成"),
                      _c("br"),
                      _vm._v("\n          ⑥本診断結果の統計データ(a)"),
                      _c("br"),
                      _vm._v(
                        "\n          ⑦本診断結果に基づき、有益と判断するプログラム及びサービスに係る情報の提供(b) "
                      ),
                      _c("br"),
                      _vm._v(
                        "\n          (a)情報の主体（組織名又は個人）が特定されることのない形式に加工し、統計データの一部として利用致します。"
                      ),
                      _c("br"),
                      _vm._v(
                        "\n          (b)希望されない旨をお伝えいただければご意向に従います。"
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(
                        "\n          2.　利用者の範囲：対象情報につきましては、以下に定める関係者が取扱います。"
                      ),
                      _c("br"),
                      _vm._v(
                        "\n          　　①本診断の運営担当者　②本診断の分析担当者　③担当営業　④担当コンサルタント"
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(
                        "\n          3.　第三者開示・提供及び再委託の有無：対象情報を以下に定める以外の理由で、事前の承諾を得ることなく第三者に開示・提供することはございません。また、以下③以外で本診断に係る業務の全部又は一部を、事前の承諾を得ることなく第三者に再委託することもございません。なお、③に係る再委託先について、BConは一切の責任を負うものとします。"
                      ),
                      _c("br"),
                      _vm._v(
                        "\n          　　①法令又は命令等により、裁判所、警察、検察その他国家機関若しくはこれらに類する機関等に当該情報の開示について義務的に求められたとき"
                      ),
                      _c("br"),
                      _vm._v(
                        "\n          　　②生命・身体及び財産等に対する差し迫った危険があり、緊急の必要性があるとき"
                      ),
                      _c("br"),
                      _vm._v("\n          　　③本診断に係る印刷及び入力業務"),
                      _c("br"),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(
                        "\n          4.　情報提供の任意性：対象情報を含む情報の提供につきましては、任意となりますので、十分な情報をご提供頂けない場合は、お申込み手続き及び本診断の実施に支障をきたす場合がございます。"
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(
                        "\n          5.　セキュリティ：BConは、対象情報について、紛失又は不当なアクセス・破壊・使用・修正・開示等が行われないよう合理的な安全保護措置を講じます。詳細につきましては、以下のWebに掲載しておりますプライバシーポリシー（http://www.bcon.jp/privacyp/）をご参照ください。"
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(
                        "\n          6.　確認・開示・訂正：対象情報に関する確認・開示・訂正については、お申し出により、合理的な期間及び範囲で対応致します。"
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(
                        "\n          7.　個人情報の削除：対象情報のうち、個人情報（特定の個人を識別できる情報）は原則、報告会・研修会等、報告書を使用した翌月末日を保管期限とし、翌々月には削除致します。この保管期限に関しては、該当欄の記載内容に準じます。"
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(
                        "\n          8.　情報管理責任部門（問い合わせ窓口）：対象情報の取扱いに関するご質問・ご要望は下記にて承ります｡"
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(
                        "\n          情報管理責任部門：株式会社ビジネスコンサルタント ESB本部 診断サービスグループ"
                      ),
                      _c("br"),
                      _vm._v(
                        "\n          〒100-0005　東京都千代田区丸の内1-7-12 サピアタワー18F"
                      ),
                      _c("br"),
                      _vm._v(
                        "\n          TEL:03-3287-3416  E-mail: survey-rcptn@info.bcon.jp"
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "consent_box_p" }, [
                  _vm._v(
                    "以上の内容で同意いただける方は「同意する」にチェックを入れてください。"
                  )
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "consent_box_p_p" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.agree,
                        expression: "agree"
                      }
                    ],
                    attrs: {
                      type: "checkbox",
                      name: "agree",
                      value: "true",
                      id: "consent_check"
                    },
                    domProps: {
                      checked: Array.isArray(_vm.agree)
                        ? _vm._i(_vm.agree, "true") > -1
                        : _vm.agree
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.agree,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = "true",
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.agree = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.agree = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.agree = $$c
                        }
                      }
                    }
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "check_2_3",
                      attrs: { for: "consent_check" }
                    },
                    [_vm._v("同意する")]
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticStyle: { display: "block" },
                    attrs: { id: "check_box" }
                  },
                  [
                    _c("div", { staticClass: "footer_box footer_2_3" }, [
                      _c("p", { staticClass: "btn_1" }, [
                        _c("input", {
                          class: {
                            form_box_sub: true,
                            form_box_sub_o: !_vm.agree
                          },
                          attrs: { type: "submit", value: "入力内容を確認する" }
                        })
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "save_btn" }, [
                        _c(
                          "a",
                          {
                            attrs: { href: "javascript:;" },
                            on: { click: _vm.saveWithoutValidate }
                          },
                          [_vm._v("一時保存")]
                        )
                      ])
                    ])
                  ]
                )
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _vm.is_preview && _vm.project.progress_status == 0
        ? _c("div", [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "form",
              {
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.updateProgress()
                  }
                }
              },
              [
                _c("div", { staticClass: "form_box form_box_1_3" }, [
                  _vm._m(1),
                  _vm._v(" "),
                  _c("dl", [
                    _c("dt", { attrs: { width: "25%" } }, [
                      _vm._v("組織名(会社名)")
                    ]),
                    _vm._v(" "),
                    _c("dd", { attrs: { width: "75%" } }, [
                      _vm._v(_vm._s(_vm.project.client_name))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("dl", [
                    _c("dt", [_vm._v("所属・役職")]),
                    _vm._v(" "),
                    _c("dd", [
                      _vm._v(
                        _vm._s(_vm.user.division) +
                          "　" +
                          _vm._s(_vm.user.position)
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("dl", [
                    _c("dt", [_vm._v("氏名")]),
                    _vm._v(" "),
                    _c("dd", [_vm._v(_vm._s(_vm.user.name))])
                  ]),
                  _vm._v(" "),
                  _c("dl", [
                    _c("dt", [_vm._v("電話番号")]),
                    _vm._v(" "),
                    _c("dd", [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.project.client_tel) +
                          "\n          "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("dl", [
                    _c("dt", [_vm._v("住所")]),
                    _vm._v(" "),
                    _c("dd", [
                      _c("p", [_vm._v("〒" + _vm._s(_vm.project.zip_code))]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          _vm._s(_vm.project.address1) +
                            _vm._s(_vm.project.address2) +
                            _vm._s(_vm.project.address3)
                        )
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("dl", [
                    _c("dt", { attrs: { width: "25%" } }, [_vm._v("実施方法")]),
                    _vm._v(" "),
                    _c("dd", { attrs: { width: "75%" } }, [
                      _vm.project.medium_type == 3
                        ? _c("label", [
                            _vm._v(
                              "Web " +
                                _vm._s(_vm.project.web_people) +
                                "名 + 質問紙 " +
                                _vm._s(_vm.project.paper_people) +
                                "名"
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.project.medium_type == 2
                        ? _c("label", [
                            _vm._v(
                              "質問紙 " +
                                _vm._s(_vm.project.paper_people) +
                                "名"
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.project.medium_type == 1
                        ? _c("label", [
                            _vm._v(
                              "Web " + _vm._s(_vm.project.web_people) + "名"
                            )
                          ])
                        : _vm._e()
                    ])
                  ]),
                  _vm._v(" "),
                  (_vm.project.medium_type == 2 ||
                    _vm.project.medium_type == 3) &&
                  _vm.project.project_type >= 11 &&
                    _vm.project.project_type <= 16
                    ? _c("dl", [
                        _c("dt", [_vm._v("質問紙の送付先拠点数")]),
                        _vm._v(" "),
                        _c("dd", [
                          _vm.project.paper_send_type == 1
                            ? _c("label", [_vm._v("1拠点")])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.project.paper_send_type == 2
                            ? _c("label", [_vm._v("2拠点")])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.project.paper_send_type == 3
                            ? _c("label", [_vm._v("3拠点以上")])
                            : _vm._e()
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.project.paper_send_type == 1 ||
                  _vm.project.paper_send_type == 2
                    ? _c("dl", [
                        _c("dt", [_vm._v("質問紙の送付先住所①")]),
                        _vm._v(" "),
                        _c("dd", [
                          _c("p", [
                            _vm._v("〒" + _vm._s(_vm.project.paper1_zip_code))
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              _vm._s(_vm.project.paper1_address1) +
                                _vm._s(_vm.project.paper1_address2) +
                                _vm._s(_vm.project.paper1_address3)
                            )
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              _vm._s(_vm.project.paper1_division) +
                                " " +
                                _vm._s(_vm.project.paper1_position)
                            )
                          ]),
                          _vm._v(" "),
                          _c("p", [_vm._v(_vm._s(_vm.project.paper1_name))])
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  (_vm.project.paper_send_type == 1 ||
                    _vm.project.paper_send_type == 2) &&
                  !(
                    (_vm.project.project_type >= 1 &&
                      _vm.project.project_type <= 6) ||
                    (_vm.project.project_type >= 21 &&
                      _vm.project.project_type <= 24)
                  )
                    ? _c("dl", [
                        _c("dt", [_vm._v("質問紙の送付部数①")]),
                        _vm._v(" "),
                        _c("dd", [_vm._v(_vm._s(_vm.project.paper1_copies))])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.project.paper_send_type == 2
                    ? _c("dl", [
                        _c("dt", [_vm._v("質問紙の送付先住所②")]),
                        _vm._v(" "),
                        _c("dd", [
                          _c("p", [
                            _vm._v("〒" + _vm._s(_vm.project.paper2_zip_code))
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              _vm._s(_vm.project.paper2_address1) +
                                _vm._s(_vm.project.paper2_address2) +
                                _vm._s(_vm.project.paper2_address3)
                            )
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              _vm._s(_vm.project.paper2_division) +
                                " " +
                                _vm._s(_vm.project.paper2_position)
                            )
                          ]),
                          _vm._v(" "),
                          _c("p", [_vm._v(_vm._s(_vm.project.paper2_name))])
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.project.paper_send_type == 2
                    ? _c("dl", [
                        _c("dt", [_vm._v("質問紙の送付部数②")]),
                        _vm._v(" "),
                        _c("dd", [_vm._v(_vm._s(_vm.project.paper2_copies))])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("dl", [
                    _c("dt", { staticClass: "top" }, [
                      _vm._v("回答スケジュール")
                    ]),
                    _vm._v(" "),
                    _c("dd", { staticClass: "fx" }, [
                      _c(
                        "div",
                        {
                          staticClass: "form_box_2_4",
                          staticStyle: { width: "100%" }
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass: "form_box_2_4_p form_box_2_4_2_p",
                              attrs: { id: "margin_left_20" }
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  "対象者リスト提出締切日　" +
                                    _vm._s(_vm.deadlineSubmit)
                                )
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _vm.project.medium_type == 1
                            ? _c(
                                "div",
                                {
                                  staticClass: "form_box_360",
                                  staticStyle: { width: "100%" }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "margin_b_2_4 fx_ce row" },
                                    [
                                      _c("p", { staticClass: "col col-140" }, [
                                        _vm._v("Web診断開始日時")
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "col margin_l_2_4 stick_2_4"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("dateFormat")(
                                                _vm.project.web_start
                                              )
                                            ) + " "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "col" }, [
                                        _vm._v(
                                          "※メール配信開始は、11:00～13:00です。"
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "row margin_b_2_4 margin_b_2_3 fx_ce"
                                    },
                                    [
                                      _c("p", { staticClass: "col-140" }, [
                                        _vm._v("Web診断終了日時")
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "p",
                                        { staticClass: "col margin_l_2_4" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("dateFormat")(
                                                _vm.project.web_end
                                              )
                                            ) + " "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "col" }, [
                                        _vm._v(
                                          "※WEB回答終了時刻は、17:00です。"
                                        )
                                      ])
                                    ]
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.project.medium_type == 2
                            ? _c("div", { staticClass: "form_box_360" }, [
                                _c(
                                  "div",
                                  { staticClass: "margin_b_2_4 fx_ce row" },
                                  [
                                    _c("p", { staticClass: "col col-140" }, [
                                      _vm._v("質問紙到着希望日")
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "col margin_l_2_4 stick_2_4"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("dateFormat")(
                                              _vm.project.paper_preferred_date
                                            )
                                          )
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "margin_b_2_4 fx_ce" },
                                  [
                                    _c("p", [_vm._v("質問紙提出期限(任意)")]),
                                    _vm._v(" "),
                                    _c(
                                      "p",
                                      { staticClass: "margin_l_2_4 stick_2_4" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("dateFormat")(
                                              _vm.project.paper_limit_date
                                            )
                                          )
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "margin_b_2_4 fx_ce row" },
                                  [
                                    _c("p", { staticClass: "col col-140" }, [
                                      _vm._v("質問紙BCon到着日")
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "p",
                                      { staticClass: "col margin_l_2_4" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("dateFormat")(
                                              _vm.project
                                                .paper_bcon_receipt_date
                                            )
                                          )
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.project.medium_type == 3
                            ? _c(
                                "div",
                                {
                                  staticClass: "form_box_360",
                                  staticStyle: { width: "100%" }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "margin_b_2_4 fx_ce row" },
                                    [
                                      _c("p", { staticClass: "col col-140" }, [
                                        _vm._v("Web診断開始日時")
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "col margin_l_2_4 stick_2_4"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("dateFormat")(
                                                _vm.project.web_start
                                              )
                                            )
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "col" }, [
                                        _vm._v(
                                          "※メール配信開始は、11:00～13:00です。"
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "row margin_b_2_4 margin_b_2_3 fx_ce"
                                    },
                                    [
                                      _c("p", { staticClass: "col-140" }, [
                                        _vm._v("Web診断終了日時")
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "p",
                                        { staticClass: "col margin_l_2_4" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("dateFormat")(
                                                _vm.project.web_end
                                              )
                                            )
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "col" }, [
                                        _vm._v(
                                          "※WEB回答終了時刻は、17:00です。"
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm._m(2),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "margin_b_2_4 fx_ce row" },
                                    [
                                      _c("p", { staticClass: "col col-140" }, [
                                        _vm._v("質問紙到着希望日")
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "col margin_l_2_4 stick_2_4"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("dateFormat")(
                                                _vm.project.paper_preferred_date
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "margin_b_2_4 fx_ce" },
                                    [
                                      _c("p", [_vm._v("質問紙提出期限(任意)")]),
                                      _vm._v(" "),
                                      _c(
                                        "p",
                                        {
                                          staticClass: "margin_l_2_4 stick_2_4"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("dateFormat")(
                                                _vm.project.paper_limit_date
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "margin_b_2_4 fx_ce row" },
                                    [
                                      _c("p", { staticClass: "col col-140" }, [
                                        _vm._v("質問紙BCon到着日")
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "p",
                                        { staticClass: "col margin_l_2_4" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("dateFormat")(
                                                _vm.project
                                                  .paper_bcon_receipt_date
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("dl", [
                    _c("dt", [_vm._v("報告書納品希望日")]),
                    _vm._v(" "),
                    _c("dd", { staticClass: "margin_l_2_4_2" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("dateFormat")(
                            _vm.project.report_preferred_date
                          )
                        )
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _vm.project.project_type == 5
                    ? _c("dl", { staticClass: "fx" }, [
                        _c("dt", {}, [_vm._v("配信開始日")]),
                        _vm._v(" "),
                        _c("dd", {}, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm._f("dateFormat")(
                                  _vm.project.stream_start_date
                                )
                              ) +
                              "\n          "
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("dl", { staticClass: "form_box_1_3_dl" }, [
                    _c("dt", [_vm._v("該当する業界・規模")]),
                    _vm._v(" "),
                    _c("dd", [
                      _vm._v(
                        "業界・業種：　" +
                          _vm._s(
                            this.industry_types[_vm.project.industry_type]
                              ? this.industry_types[_vm.project.industry_type]
                              : ""
                          )
                      ),
                      _c("br"),
                      _vm._v(
                        "\n            規模　　　：　" +
                          _vm._s(
                            this.scale_types[_vm.project.scale_type]
                              ? this.scale_types[_vm.project.scale_type]
                              : ""
                          ) +
                          "\n          "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _vm.project.project_type == 4 ||
                  _vm.project.project_type == 24
                    ? _c("dl", { staticClass: "fx" }, [
                        _c("dt", {}, [_vm._v("対象")]),
                        _vm._v(" "),
                        _c("dd", [
                          _vm.project.is_personnal_target1
                            ? _c("div", [_vm._v("内定者")])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.project.is_personnal_target2
                            ? _c("div", [_vm._v("半年-5年未満")])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.project.is_personnal_target3
                            ? _c("div", [_vm._v("監督職(主任・係長)")])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.project.is_personnal_target4
                            ? _c("div", [_vm._v("役員")])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.project.is_personnal_target5
                            ? _c("div", [_vm._v("新人")])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.project.is_personnal_target6
                            ? _c("div", [_vm._v("5年以上")])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.project.is_personnal_target7
                            ? _c("div", [_vm._v("課長・部長")])
                            : _vm._e()
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("dl", [
                    _c("dt", [_vm._v("備考欄")]),
                    _vm._v(" "),
                    _c("dd", [
                      _vm.empty(_vm.project.client_memo) &&
                      _vm.project.client_memo.length > 0
                        ? _c(
                            "div",
                            _vm._l(
                              _vm.project.client_memo.split("\n"),
                              function(line, i) {
                                return _c("div", [
                                  _vm._v(
                                    "\n                  " + _vm._s(line) + " "
                                  ),
                                  i <
                                  _vm.project.client_memo.split("\n").length - 1
                                    ? _c("br")
                                    : _vm._e()
                                ])
                              }
                            ),
                            0
                          )
                        : _c("div", [_vm._v("なし")])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("dl", [
                    _c("dt", [
                      _vm._v(
                        "本書に記載される情報と本診断で取得する情報の取扱いについて"
                      )
                    ]),
                    _vm._v(" "),
                    _c("dd", [
                      _vm.agree ? _c("p", [_vm._v("同意する")]) : _vm._e()
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "footer_box footer_box_1_3" }, [
                  _c("p", { staticClass: "btn2" }, [
                    _c("input", {
                      staticClass: "form_box_sub_blue form_box_sub_2",
                      attrs: { type: "button", value: "前の画面に戻る" },
                      on: {
                        click: function($event) {
                          return _vm.backInput()
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _vm._m(3)
                ])
              ]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.project.progress_status == 1
        ? _c("div", { staticClass: "top_box_2_5" }, [_vm._m(4)])
        : _vm._e(),
      _vm._v(" "),
      _vm.project.progress_status == 1
        ? _c("div", { staticClass: "content_box_2_5 fx_se" }, [
            _c(
              "div",
              {
                staticClass: "content_box_child_2_5",
                on: {
                  click: function($event) {
                    return _vm.newStep()
                  }
                }
              },
              [
                _c("img", {
                  attrs: {
                    src: "images/jizen_img1.png",
                    alt: "「詳細情報」を入力する",
                    srcset:
                      "images/jizen_img1.png" +
                      " 1x, " +
                      "images/jizen_img1@2x.png" +
                      " 2x"
                  }
                }),
                _vm._v(" "),
                _vm._m(5),
                _vm._v(" "),
                _vm._m(6)
              ]
            ),
            _vm._v(" "),
            _vm._m(7)
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "attention" }, [
      _c("p", [_vm._v("入力内容に誤りがないかご確認ください。")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form_box_title" }, [
      _c("p", {}, [_vm._v("お客様情報入力")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("hr")])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "btn3" }, [
      _c("input", {
        staticClass: "form_box_sub form_box_sub_2 step1",
        staticStyle: { padding: "15px 100px" },
        attrs: { type: "submit", value: "上記の内容で送信する" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("ご登録ありがとうございました。"),
      _c("br"),
      _vm._v("\n      診断申込みを受け付けました。"),
      _c("br"),
      _vm._v("\n      申込受付完了メールを送信しますので内容をご確認ください。")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("引き続き"),
      _c("br"),
      _vm._v("「詳細情報」を入力する")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "btn-s btn_2_5" }, [
      _c(
        "a",
        {
          staticClass: "form_box_sub form_box_sub_2",
          staticStyle: { padding: "15px 100px" },
          attrs: { href: "javascript:;" }
        },
        [_vm._v("詳細情報へ\n      ")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "content_box_child_2_5" }, [
      _c("img", {
        attrs: {
          src: "images/jizen_img2.png",
          alt: "「詳細情報」を入力する",
          srcset:
            "images/jizen_img2.png" +
            " 1x, " +
            "images/jizen_img2@2x.png" +
            " 2x"
        }
      }),
      _vm._v(" "),
      _c("p", [
        _vm._v("後で"),
        _c("br"),
        _vm._v("引き続き「詳細情報」を入力する")
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "content_box_child_2_5_p" }, [
        _vm._v("この画面を終了した後で事前情報を入力する場合"),
        _c("br"),
        _vm._v("は、再度ログインしていただき、「詳細情報 登録」"),
        _c("br"),
        _vm._v("へお進みください。\n      ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }