var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.projectInfo && _vm.projectInfo.id,
          expression: "projectInfo && projectInfo.id"
        }
      ],
      staticClass: "container",
      attrs: { id: "body_1_2" }
    },
    [
      _c("header-component", {
        attrs: { back: { name: "ProjectList" }, margin: true }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "fx_between_2", attrs: { id: "top_box_6_2" } }, [
        _c("div", { staticClass: "fx" }, [
          _c("h1", { staticClass: "h1_6_2" }, [
            _vm._v("申込No." + _vm._s(_vm.projectInfo.id))
          ]),
          _vm._v(" "),
          _vm.projectInfo.progress_status
            ? _c(
                "p",
                {
                  class:
                    "sta_6_2_" + (parseInt(_vm.projectInfo.progress_status) + 1)
                },
                [
                  _c("span", {
                    domProps: { innerHTML: _vm._s(_vm.projectStatusNumber()) }
                  }),
                  _vm._v(" " + _vm._s(_vm.progressStatus()))
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("p", { staticClass: "user" }, [
            _vm._v(
              _vm._s(
                _vm.projectInfo.consultant_user &&
                  _vm.projectInfo.consultant_user.id
                  ? _vm.projectInfo.consultant_user.name
                  : ""
              )
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "fx" }, [
          _c("p", { staticClass: "top_p_6_2" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.projectInfo.is_deadline_disabled,
                  expression: "projectInfo.is_deadline_disabled"
                }
              ],
              attrs: {
                type: "checkbox",
                name: "is_deadline_disabled",
                disabled: parseInt(_vm.projectInfo.progress_status) >= 4,
                id: "is_deadline_disabled"
              },
              domProps: {
                checked: Array.isArray(_vm.projectInfo.is_deadline_disabled)
                  ? _vm._i(_vm.projectInfo.is_deadline_disabled, null) > -1
                  : _vm.projectInfo.is_deadline_disabled
              },
              on: {
                change: [
                  function($event) {
                    var $$a = _vm.projectInfo.is_deadline_disabled,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.projectInfo,
                            "is_deadline_disabled",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.projectInfo,
                            "is_deadline_disabled",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.projectInfo, "is_deadline_disabled", $$c)
                    }
                  },
                  function($event) {
                    return _vm.changeDeadlineDisabled()
                  }
                ]
              }
            }),
            _vm._v(" "),
            _c(
              "label",
              {
                class: {
                  check_6_2: true,
                  gray: parseInt(_vm.projectInfo.progress_status) >= 4
                },
                attrs: { for: "is_deadline_disabled" }
              },
              [_vm._v("締切日を無効にする")]
            )
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "top_p_6_2" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.progress_end,
                  expression: "progress_end"
                }
              ],
              attrs: {
                disabled: parseInt(_vm.projectInfo.progress_status) != 3,
                type: "checkbox",
                name: "progress_end",
                id: "progress_end"
              },
              domProps: {
                checked: Array.isArray(_vm.progress_end)
                  ? _vm._i(_vm.progress_end, null) > -1
                  : _vm.progress_end
              },
              on: {
                change: [
                  function($event) {
                    var $$a = _vm.progress_end,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.progress_end = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.progress_end = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.progress_end = $$c
                    }
                  },
                  function($event) {
                    return _vm.endProject()
                  }
                ]
              }
            }),
            _c(
              "label",
              {
                class: {
                  check_6_2: true,
                  gray: parseInt(_vm.projectInfo.progress_status) != 3
                },
                attrs: { for: "progress_end" }
              },
              [_vm._v("完了にする")]
            )
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "del_6_2" }, [
            _c(
              "a",
              {
                attrs: { href: "javascript:;" },
                on: {
                  click: function($event) {
                    return _vm.showDelete()
                  }
                }
              },
              [_vm._v("削除")]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "fx_ce", attrs: { id: "sta_box" } }, [
        _c("p", { staticClass: "sta_p" }, [_vm._v("ステータス")]),
        _vm._v(" "),
        _c(
          "p",
          {
            class: {
              "sta_6_2_1 m_right": true,
              sta_opacity: parseInt(_vm.projectInfo.progress_status) != 0
            }
          },
          [_vm._v("①案件登録済")]
        ),
        _vm._v(" "),
        _c(
          "p",
          {
            class: {
              "sta_6_2_2 m_right": true,
              sta_opacity: parseInt(_vm.projectInfo.progress_status) != 1
            }
          },
          [_vm._v("②申込済")]
        ),
        _vm._v(" "),
        _c(
          "p",
          {
            class: {
              "sta_6_2_3 m_right": true,
              sta_opacity: parseInt(_vm.projectInfo.progress_status) != 2
            }
          },
          [_vm._v("③詳細情報登録済")]
        ),
        _vm._v(" "),
        _c(
          "p",
          {
            class: {
              "sta_6_2_4 m_right": true,
              sta_opacity: parseInt(_vm.projectInfo.progress_status) != 3
            }
          },
          [_vm._v("④リスト登録済")]
        ),
        _vm._v(" "),
        _c(
          "p",
          {
            class: {
              "sta_6_2_5 m_right": true,
              sta_opacity: parseInt(_vm.projectInfo.progress_status) != 4
            }
          },
          [_vm._v("⑤診断SG受理済")]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "attention" }, [
        parseInt(_vm.projectInfo.progress_status) == 0
          ? _c("p", [
              _vm._v("催促メール(開始日)　　　　"),
              _vm.projectInfo.preentry_alert_date
                ? _c("span", [
                    _vm._v(_vm._s(_vm.projectInfo.preentry_alert_date))
                  ])
                : _vm._e()
            ])
          : _c("p", [
              _vm._v("催促メール（リスト)　　　　"),
              _vm.projectInfo.postentry_alert_date
                ? _c("span", [
                    _vm._v(_vm._s(_vm.projectInfo.postentry_alert_date))
                  ])
                : _vm._e()
            ])
      ]),
      _vm._v(" "),
      _c("form", { attrs: { action: "", method: "post" } }, [
        _c("div", { staticClass: "form_box form_box_1_3" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("dl", [
            _c("dt", [_vm._v("組織名(会社名)")]),
            _vm._v(" "),
            _c("dd", [_vm._v(_vm._s(_vm.projectInfo.client_name))])
          ]),
          _vm._v(" "),
          _c("dl", [
            _c("dt", [_vm._v("診断名")]),
            _vm._v(" "),
            _c("dd", [
              _vm._v(_vm._s(_vm.projectType(_vm.projectInfo.project_type)))
            ])
          ]),
          _vm._v(" "),
          _c("dl", [
            _c("dt", [_vm._v("担当営業名")]),
            _vm._v(" "),
            _c("dd", [_vm._v(_vm._s(_vm.projectInfo.staff_name))])
          ]),
          _vm._v(" "),
          _c("dl", [
            _c("dt", [_vm._v("共有先メールアドレス①")]),
            _vm._v(" "),
            _c("dd", [
              _vm._v(
                _vm._s(
                  _vm.projectInfo.staff_email1
                    ? _vm.projectInfo.staff_email1
                    : ""
                )
              )
            ])
          ]),
          _vm._v(" "),
          _c("dl", [
            _c("dt", [_vm._v("共有先メールアドレス②")]),
            _vm._v(" "),
            _c("dd", [
              _vm._v(
                _vm._s(
                  _vm.projectInfo.staff_email2
                    ? _vm.projectInfo.staff_email2
                    : ""
                )
              )
            ])
          ]),
          _vm._v(" "),
          _c("dl", [
            _c("dt", [_vm._v("備考")]),
            _vm._v(" "),
            _c("dd", { staticClass: "break-word" }, [
              _vm.empty(_vm.projectInfo.memo) && _vm.projectInfo.memo.length > 0
                ? _c(
                    "div",
                    _vm._l(_vm.projectInfo.memo.split("\n"), function(line, i) {
                      return _c("div", [
                        _vm._v("\n              " + _vm._s(line) + " "),
                        i < _vm.projectInfo.memo.split("\n").length - 1
                          ? _c("br")
                          : _vm._e()
                      ])
                    }),
                    0
                  )
                : _vm._e()
            ])
          ]),
          _vm._v(" "),
          _vm.projectInfo.project_type == 11
            ? _c("dl", [
                _c("dt", [_vm._v("経年比較の有無")]),
                _vm._v(" "),
                _c("dd", [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.projectInfo.is_comparison_age
                          ? "有り"
                          : _vm.projectInfo.is_comparison_age == 0
                          ? "無し"
                          : ""
                      ) +
                      "\n        "
                  )
                ])
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form_box form_box_1_3" }, [
          _vm._m(1),
          _vm._v(" "),
          _c("dl", [
            _c("dt", [_vm._v("担当者　所属")]),
            _vm._v(" "),
            _c("dd", [
              _vm._v(
                _vm._s(
                  _vm.projectInfo &&
                    _vm.projectInfo.user &&
                    _vm.projectInfo.user[0]
                    ? _vm.projectInfo.user[0].division
                    : ""
                ) + "　"
              )
            ])
          ]),
          _vm._v(" "),
          _c("dl", [
            _c("dt", [_vm._v("担当者　役職")]),
            _vm._v(" "),
            _c("dd", [
              _vm._v(
                _vm._s(
                  _vm.projectInfo &&
                    _vm.projectInfo.user &&
                    _vm.projectInfo.user[0]
                    ? _vm.projectInfo.user[0].position
                    : ""
                )
              )
            ])
          ]),
          _vm._v(" "),
          _c("dl", [
            _c("dt", [_vm._v("担当者　氏名")]),
            _vm._v(" "),
            _c("dd", [
              _vm._v(
                _vm._s(
                  _vm.projectInfo &&
                    _vm.projectInfo.user &&
                    _vm.projectInfo.user[0]
                    ? _vm.projectInfo.user[0].name
                    : ""
                )
              )
            ])
          ]),
          _vm._v(" "),
          _c("dl", [
            _c("dt", [_vm._v("担当者　メールアドレス")]),
            _vm._v(" "),
            _c("dd", [
              _c(
                "div",
                {
                  staticClass: "fx_between_2",
                  staticStyle: { "padding-right": "20px" }
                },
                [
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.projectInfo &&
                          _vm.projectInfo.user &&
                          _vm.projectInfo.user[0]
                          ? _vm.projectInfo.user[0].email
                          : ""
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "ac_btn" }, [
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:;" },
                        on: {
                          click: function($event) {
                            return _vm.resendPassword()
                          }
                        }
                      },
                      [_vm._v("アカウント再発行")]
                    )
                  ])
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _c("dl", [
            _c("dt", [_vm._v("担当者2人目　氏名")]),
            _vm._v(" "),
            _c("dd", [
              _vm._v(
                _vm._s(
                  _vm.projectInfo &&
                    _vm.projectInfo.user &&
                    _vm.projectInfo.user[1]
                    ? _vm.projectInfo.user[1].name
                    : ""
                )
              )
            ])
          ]),
          _vm._v(" "),
          _c("dl", [
            _c("dt", [_vm._v("担当者2人目　メールアドレス")]),
            _vm._v(" "),
            _c("dd", [
              _c(
                "div",
                {
                  staticClass: "fx_between_2",
                  staticStyle: { "padding-right": "20px" }
                },
                [
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.projectInfo &&
                          _vm.projectInfo.user &&
                          _vm.projectInfo.user[1]
                          ? _vm.projectInfo.user[1].email
                          : ""
                      )
                    )
                  ])
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _c("dl", [
            _c("dt", [_vm._v("電話番号")]),
            _vm._v(" "),
            _c("dd", [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.projectInfo.client_tel) +
                  "\n        "
              )
            ])
          ]),
          _vm._v(" "),
          _c("dl", { staticClass: "form_box_1_3_dl" }, [
            _c("dt", [_vm._v("住所")]),
            _vm._v(" "),
            _c("dd", [
              _c("p", [_vm._v("〒" + _vm._s(_vm.projectInfo.zip_code))]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  _vm._s(_vm.projectInfo.address1) +
                    _vm._s(_vm.projectInfo.address2) +
                    _vm._s(_vm.projectInfo.address3)
                )
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form_box form_box_1_3" }, [
          _vm._m(2),
          _vm._v(" "),
          _c("dl", [
            _c("dt", { attrs: { width: "25%" } }, [_vm._v("実施方法")]),
            _vm._v(" "),
            _c("dd", { attrs: { width: "75%" } }, [
              _vm.projectInfo.medium_type == 3
                ? _c("label", [
                    _vm._v(
                      "Web " +
                        _vm._s(_vm.projectInfo.web_people) +
                        "名 + 質問紙 " +
                        _vm._s(_vm.projectInfo.paper_people) +
                        "名"
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.projectInfo.medium_type == 2
                ? _c("label", [
                    _vm._v(
                      "質問紙 " + _vm._s(_vm.projectInfo.paper_people) + "名"
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.projectInfo.medium_type == 1
                ? _c("label", [
                    _vm._v("Web " + _vm._s(_vm.projectInfo.web_people) + "名")
                  ])
                : _vm._e()
            ])
          ]),
          _vm._v(" "),
          (_vm.projectInfo.medium_type == 2 ||
            _vm.projectInfo.medium_type == 3) &&
          _vm.projectInfo.project_type >= 11 &&
            _vm.projectInfo.project_type <= 16
            ? _c("dl", [
                _c("dt", [_vm._v("質問紙の送付先拠点数")]),
                _vm._v(" "),
                _c("dd", [
                  _vm.projectInfo.paper_send_type == 1
                    ? _c("label", [_vm._v("1拠点")])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.projectInfo.paper_send_type == 2
                    ? _c("label", [_vm._v("2拠点")])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.projectInfo.paper_send_type == 3
                    ? _c("label", [_vm._v("3拠点以上")])
                    : _vm._e()
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.projectInfo.paper_send_type == 1 ||
          _vm.projectInfo.paper_send_type == 2
            ? _c("dl", [
                _c("dt", [_vm._v("質問紙の送付先住所①")]),
                _vm._v(" "),
                _c("dd", [
                  _c("p", [
                    _vm._v("〒" + _vm._s(_vm.projectInfo.paper1_zip_code))
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      _vm._s(_vm.projectInfo.paper1_address1) +
                        _vm._s(_vm.projectInfo.paper1_address2) +
                        _vm._s(_vm.projectInfo.paper1_address3)
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      _vm._s(_vm.projectInfo.paper1_division) +
                        " " +
                        _vm._s(_vm.projectInfo.paper1_position)
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(_vm.projectInfo.paper1_name))])
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          (_vm.projectInfo.paper_send_type == 1 ||
            _vm.projectInfo.paper_send_type == 2) &&
          !(
            (_vm.projectInfo.project_type >= 1 &&
              _vm.projectInfo.project_type <= 6) ||
            (_vm.projectInfo.project_type >= 21 &&
              _vm.projectInfo.project_type <= 24)
          )
            ? _c("dl", [
                _c("dt", [_vm._v("質問紙の送付部数①")]),
                _vm._v(" "),
                _c("dd", [_vm._v(_vm._s(_vm.projectInfo.paper1_copies))])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.projectInfo.paper_send_type == 2
            ? _c("dl", [
                _c("dt", [_vm._v("質問紙の送付先住所②")]),
                _vm._v(" "),
                _c("dd", [
                  _c("p", [
                    _vm._v("〒" + _vm._s(_vm.projectInfo.paper2_zip_code))
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      _vm._s(_vm.projectInfo.paper2_address1) +
                        _vm._s(_vm.projectInfo.paper2_address2) +
                        _vm._s(_vm.projectInfo.paper2_address3)
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      _vm._s(_vm.projectInfo.paper2_division) +
                        " " +
                        _vm._s(_vm.projectInfo.paper2_position)
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(_vm.projectInfo.paper2_name))])
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.projectInfo.paper_send_type == 2
            ? _c("dl", [
                _c("dt", [_vm._v("質問紙の送付部数②")]),
                _vm._v(" "),
                _c("dd", [_vm._v(_vm._s(_vm.project.paper2_copies))])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("dl", [
            _c("dt", { staticClass: "top" }, [_vm._v("回答スケジュール")]),
            _vm._v(" "),
            _c("dd", { staticClass: "fx" }, [
              _c("div", { staticClass: "form_box_2_4" }, [
                _c(
                  "p",
                  {
                    staticClass: "form_box_2_4_p form_box_2_4_2_p",
                    attrs: { id: "margin_left_20" }
                  },
                  [
                    _c("span", [
                      _vm._v(
                        "対象者リスト提出締切日　" + _vm._s(_vm.deadlineSubmit)
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _vm.projectInfo.medium_type == 1
                  ? _c("div", { staticClass: "form_box_360" }, [
                      _c("div", { staticClass: "margin_b_2_4 fx_ce row" }, [
                        _c("p", { staticClass: "col col-140" }, [
                          _vm._v("Web診断開始日時")
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "col margin_l_2_4 stick_2_4" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("dateFormat")(_vm.projectInfo.web_start)
                            )
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "row margin_b_2_4 margin_b_2_3 fx_ce" },
                        [
                          _c("p", { staticClass: "col-140" }, [
                            _vm._v("Web診断終了日時")
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "col margin_l_2_4" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("dateFormat")(_vm.projectInfo.web_end)
                              )
                            )
                          ])
                        ]
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.projectInfo.medium_type == 2
                  ? _c("div", { staticClass: "form_box_360" }, [
                      _c("div", { staticClass: "margin_b_2_4 fx_ce row" }, [
                        _c("p", { staticClass: "col col-140" }, [
                          _vm._v("質問紙到着希望日")
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "col margin_l_2_4 stick_2_4" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("dateFormat")(
                                _vm.projectInfo.paper_preferred_date
                              )
                            )
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "margin_b_2_4 fx_ce" }, [
                        _c("p", [_vm._v("質問紙提出期限(任意)")]),
                        _vm._v(" "),
                        _c("p", { staticClass: "margin_l_2_4 stick_2_4" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("dateFormat")(
                                _vm.projectInfo.paper_limit_date
                              )
                            )
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "margin_b_2_4 fx_ce row" }, [
                        _c("p", { staticClass: "col col-140" }, [
                          _vm._v("質問紙BCon到着日")
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "col margin_l_2_4" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("dateFormat")(
                                _vm.projectInfo.paper_bcon_receipt_date
                              )
                            )
                          )
                        ])
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.projectInfo.medium_type == 3
                  ? _c("div", { staticClass: "form_box_360" }, [
                      _c("div", { staticClass: "margin_b_2_4 fx_ce row" }, [
                        _c("p", { staticClass: "col col-140" }, [
                          _vm._v("Web診断開始日時")
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "col margin_l_2_4 stick_2_4" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("dateFormat")(_vm.projectInfo.web_start)
                            )
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "row margin_b_2_4 margin_b_2_3 fx_ce" },
                        [
                          _c("p", { staticClass: "col-140" }, [
                            _vm._v("Web診断終了日時")
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "col margin_l_2_4" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("dateFormat")(_vm.projectInfo.web_end)
                              )
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _vm._m(3),
                      _vm._v(" "),
                      _c("div", { staticClass: "margin_b_2_4 fx_ce row" }, [
                        _c("p", { staticClass: "col col-140" }, [
                          _vm._v("質問紙到着希望日")
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "col margin_l_2_4 stick_2_4" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("dateFormat")(
                                _vm.projectInfo.paper_preferred_date
                              )
                            )
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "margin_b_2_4 fx_ce" }, [
                        _c("p", [_vm._v("質問紙提出期限(任意)")]),
                        _vm._v(" "),
                        _c("p", { staticClass: "margin_l_2_4 stick_2_4" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("dateFormat")(
                                _vm.projectInfo.paper_limit_date
                              )
                            )
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "margin_b_2_4 fx_ce row" }, [
                        _c("p", { staticClass: "col col-140" }, [
                          _vm._v("質問紙BCon到着日")
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "col margin_l_2_4" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("dateFormat")(
                                _vm.projectInfo.paper_bcon_receipt_date
                              )
                            )
                          )
                        ])
                      ])
                    ])
                  : _vm._e()
              ])
            ])
          ]),
          _vm._v(" "),
          _c("dl", [
            _c("dt", [_vm._v("報告書納品希望日")]),
            _vm._v(" "),
            _c("dd", [
              _vm._v(
                _vm._s(
                  _vm._f("dateFormat")(_vm.projectInfo.report_preferred_date)
                )
              )
            ])
          ]),
          _vm._v(" "),
          _c("dl", { staticClass: "form_box_1_3_dl" }, [
            _c("dt", [_vm._v("該当する業種・組織規模")]),
            _vm._v(" "),
            _c("dd", [
              _vm._v(
                "業種　　　：　" +
                  _vm._s(
                    this.industry_types[_vm.projectInfo.industry_type]
                      ? this.industry_types[_vm.projectInfo.industry_type]
                      : ""
                  )
              ),
              _c("br"),
              _vm._v(
                "\n          規模　　　：　" +
                  _vm._s(
                    this.scale_types[_vm.projectInfo.scale_type]
                      ? this.scale_types[_vm.projectInfo.scale_type]
                      : ""
                  ) +
                  "\n        "
              )
            ])
          ]),
          _vm._v(" "),
          _vm.projectInfo.project_type == 4 ||
          _vm.projectInfo.project_type == 24
            ? _c("dl", { staticClass: "fx" }, [
                _c("dt", {}, [_vm._v("対象")]),
                _vm._v(" "),
                _c("dd", [
                  _vm.projectInfo.is_personnal_target1
                    ? _c("div", [_vm._v("内定者")])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.projectInfo.is_personnal_target2
                    ? _c("div", [_vm._v("半年-5年未満")])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.projectInfo.is_personnal_target3
                    ? _c("div", [_vm._v("監督職(主任・係長)")])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.projectInfo.is_personnal_target4
                    ? _c("div", [_vm._v("役員")])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.projectInfo.is_personnal_target5
                    ? _c("div", [_vm._v("新人")])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.projectInfo.is_personnal_target6
                    ? _c("div", [_vm._v("5年以上")])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.projectInfo.is_personnal_target7
                    ? _c("div", [_vm._v("課長・部長")])
                    : _vm._e()
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("dl", [
            _c("dt", [_vm._v("備考欄")]),
            _vm._v(" "),
            _c("dd", [
              _vm.projectInfo &&
              _vm.projectInfo.client_memo &&
              _vm.projectInfo.client_memo.length > 0
                ? _c(
                    "div",
                    _vm._l(_vm.projectInfo.client_memo.split("\n"), function(
                      line
                    ) {
                      return _c("div", [
                        _vm._v(_vm._s(line) + "\n            ")
                      ])
                    }),
                    0
                  )
                : _c("div", [_vm._v("なし")])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form_box form_box_1_3 form_box_5_2" }, [
          _vm._m(4),
          _vm._v(" "),
          _c("dl", [
            _c("dt", [_vm._v("組織名")]),
            _vm._v(" "),
            _c("dd", [_vm._v(_vm._s(_vm.projectInfo.org_name))])
          ]),
          _vm._v(" "),
          _c("dl", [
            _c("dt", [_vm._v("診断名")]),
            _vm._v(" "),
            _c("dd", [_vm._v(_vm._s(_vm.projectInfo.diagnosis_name))])
          ]),
          _vm._v(" "),
          _vm.projectInfo.project_type >= 11 &&
          _vm.projectInfo.project_type <= 16
            ? _c("dl", [
                _c("dt", [_vm._v("属性一覧の設定")]),
                _vm._v(" "),
                _c("dd", [
                  _vm._v(
                    _vm._s(
                      _vm.attribute_setting_types[
                        _vm.projectInfo.attribute_setting_type
                      ]
                        ? _vm.attribute_setting_types[
                            _vm.projectInfo.attribute_setting_type
                          ]
                        : ""
                    ) + "\n        "
                  )
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.projectInfo.project_type >= 11 &&
          _vm.projectInfo.project_type <= 16 &&
          _vm.projectInfo.attribute_setting_type == 2
            ? _c("dl", [
                _c("dt", [_vm._v("属性一覧(Excel)")]),
                _vm._v(" "),
                _c("dd", [
                  parseFloat(_vm.projectInfo.progress_status) >= "2.1" &&
                  _vm.projectInfo.p_attribute_info_file_name
                    ? _c(
                        "a",
                        {
                          attrs: {
                            href: _vm.link_download_pai + _vm.projectInfo.id
                          }
                        },
                        [_vm._v("ダウンロード")]
                      )
                    : _c("span", [_vm._v("未")])
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("dl", { staticClass: "form_box_1_3_dl" }, [
            _c("dt", [_vm._v("ID・パスワード設定")]),
            _vm._v(" "),
            _c("dd", { staticClass: "l_height" }, [
              _vm._v(
                "\n          ID：　　　　" +
                  _vm._s(
                    _vm.setting_types[_vm.projectInfo.id_setting_type]
                      ? _vm.setting_types[_vm.projectInfo.id_setting_type]
                      : ""
                  )
              ),
              _c("br"),
              _vm._v(
                "\n          パスワード：" +
                  _vm._s(
                    _vm.setting_types[_vm.projectInfo.pass_setting_type]
                      ? _vm.setting_types[_vm.projectInfo.pass_setting_type]
                      : ""
                  ) +
                  "\n        "
              )
            ])
          ]),
          _vm._v(" "),
          _c("dl", { staticClass: "form_box_1_3_dl" }, [
            _c("dt", { staticClass: "height_up_3_2" }, [
              _vm._v("貴社お問合せ先")
            ]),
            _vm._v(" "),
            _c("dd", { staticClass: "l_height" }, [
              _vm._v(
                "\n          部署・役職　　" +
                  _vm._s(_vm.projectInfo.inquiry_division)
              ),
              _c("br"),
              _vm._v(
                "\n          氏名　　　　　" +
                  _vm._s(_vm.projectInfo.inquiry_name)
              ),
              _c("br"),
              _vm._v(
                "\n          電話番号　　　" +
                  _vm._s(_vm.projectInfo.inquiry_tel)
              ),
              _c("br"),
              _vm._v(
                "\n          メール　　　　" +
                  _vm._s(_vm.projectInfo.inquiry_email)
              ),
              _c("br")
            ])
          ]),
          _vm._v(" "),
          _vm.projectInfo.medium_type != 1 &&
          _vm.projectInfo.project_type >= 11 &&
          _vm.projectInfo.project_type <= 16
            ? _c("dl", [
                _c("dt", [_vm._v("質問紙提出先")]),
                _vm._v(" "),
                _c("dd", [_vm._v(_vm._s(_vm.projectInfo.paper_recipient))])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.projectInfo.project_type == 11
            ? _c("dl", {}, [
                _c("dt", {}, [_vm._v("業種別比較")]),
                _vm._v(" "),
                _c("dd", [
                  _vm._v(
                    _vm._s(
                      _vm.projectInfo.comparison_industry_type == 1
                        ? "有り"
                        : "無し"
                    )
                  )
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("dl", [
            _c("dt", [_vm._v("回答者への案内メール")]),
            _vm._v(" "),
            _c("dd", [
              _vm._v(
                "\n          " +
                  _vm._s(
                    _vm.guidance_email_types[
                      _vm.projectInfo.guidance_email_type
                    ]
                      ? _vm.guidance_email_types[
                          _vm.projectInfo.guidance_email_type
                        ]
                      : ""
                  ) +
                  "\n        "
              )
            ])
          ]),
          _vm._v(" "),
          _vm.projectInfo.project_type == 11
            ? _c("dl", [
                _c("dt", [_vm._v("質問文の主語変更")]),
                _vm._v(" "),
                _c("dd", [
                  _vm._v(_vm._s(_vm.projectInfo.question_subject_type))
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.projectInfo.project_type !== 11 &&
          _vm.projectInfo.project_type > 11 &&
          _vm.projectInfo.project_type <= 16
            ? _c("dl", { staticClass: "radio_2 fx" }, [
                _c("dt", [_vm._v("追加質問文")]),
                _vm._v(" "),
                _c("dd", { staticStyle: { "padding-right": "35px" } }, [
                  _vm.projectInfo.qestion_type !== ""
                    ? _c("div", [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.projectInfo.qestion_type == 1
                                ? "追加質問が5問以内/追加質問の尺度が下記の7点法を利用"
                                : "追加質問が6問以上/追加質問の尺度が7点法以外(個別対応)"
                            ) +
                            "\n            "
                        ),
                        _vm.projectInfo.qestion_type == 1
                          ? _c(
                              "div",
                              _vm._l(_vm.project.questions, function(
                                question,
                                i
                              ) {
                                return _c("div", [
                                  _c("div", [_vm._v("【質問文】")]),
                                  _vm._v(" "),
                                  _c("p", [_vm._v(_vm._s(question.body))]),
                                  _vm._v(" "),
                                  _c("div", [_vm._v("【回答方式】")]),
                                  _vm._v(" "),
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        question.format_type == 1
                                          ? "尺度(7点法)"
                                          : "自由記入"
                                      )
                                    )
                                  ])
                                ])
                              }),
                              0
                            )
                          : _vm._e()
                      ])
                    : _vm._e()
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.projectInfo.project_type >= 11 &&
          _vm.projectInfo.project_type <= 16 &&
          _vm.projectInfo.attribute_setting_type != 1
            ? _c("dl", [
                _c("dt", { staticClass: "hight_up_5_2" }, [
                  _vm._v("対象者リスト(Excel)")
                ]),
                _vm._v(" "),
                _c("dd", [
                  _c("p", { staticClass: "form_box_sub_5_2_p" }, [
                    parseFloat(_vm.projectInfo.progress_status) >= 3
                      ? _c(
                          "a",
                          {
                            attrs: {
                              href:
                                _vm.link_download_ptp +
                                _vm.projectInfo.id +
                                "?last=true"
                            }
                          },
                          [_vm._v("ダウンロード")]
                        )
                      : _c("span", [_vm._v("未")])
                  ])
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.projectInfo.project_type >= 21 &&
          _vm.projectInfo.project_type <= 24
            ? _c("dl", [
                _c("dt", { staticClass: "hight_up_5_2" }, [
                  _vm._v("対象者リスト(Excel)")
                ]),
                _vm._v(" "),
                _c("dd", [
                  _c("p", { staticClass: "form_box_sub_5_2_p" }, [
                    parseFloat(_vm.projectInfo.progress_status) >= 3.0
                      ? _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.link_download_pmf + _vm.projectInfo.id
                            }
                          },
                          [_vm._v("ダウンロード")]
                        )
                      : _c("span", [_vm._v("未")])
                  ])
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          (_vm.projectInfo.project_type >= 1 &&
            _vm.projectInfo.project_type <= 6) ||
          (_vm.projectInfo.project_type >= 11 &&
            _vm.projectInfo.project_type <= 16 &&
            _vm.projectInfo.attribute_setting_type == 1)
            ? _c("dl", [
                _c("dt", { staticClass: "hight_up_5_2" }, [
                  _vm._v("対象者リスト(Excel)")
                ]),
                _vm._v(" "),
                _vm.projectInfo.project_type >= 1 &&
                _vm.projectInfo.project_type <= 6
                  ? _c("dd", [
                      _c("p", { staticClass: "form_box_sub_5_2_p" }, [
                        parseFloat(_vm.projectInfo.progress_status) >= "3.0"
                          ? _c(
                              "a",
                              {
                                attrs: {
                                  href:
                                    _vm.link_download_ppt_invidual +
                                    _vm.projectInfo.id
                                }
                              },
                              [_vm._v("ダウンロード")]
                            )
                          : _c("span", [_vm._v("未")])
                      ])
                    ])
                  : _c("dd", [
                      _c("p", { staticClass: "form_box_sub_5_2_p" }, [
                        parseFloat(_vm.projectInfo.progress_status) >= "3.0"
                          ? _c(
                              "a",
                              {
                                attrs: {
                                  href:
                                    _vm.link_download_ptp +
                                    _vm.projectInfo.id +
                                    "?last=true"
                                }
                              },
                              [_vm._v("ダウンロード")]
                            )
                          : _c("span", [_vm._v("未")])
                      ])
                    ])
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "footer_box footer_box_1_3" }, [
          _c("p"),
          _vm._v(" "),
          _c(
            "p",
            { staticClass: "btn" },
            [
              parseFloat(_vm.projectInfo.progress_status) < 4
                ? _c(
                    "router-link",
                    {
                      staticClass: "form_box_sub form_box_sub_2",
                      attrs: {
                        to: {
                          name: "ProjectEdit",
                          params: { id: _vm.projectInfo.id }
                        }
                      }
                    },
                    [_vm._v("入力内容を編集する")]
                  )
                : _c(
                    "button",
                    {
                      staticClass: "form_box_sub form_box_sub_2 disabled",
                      attrs: { disabled: "true", type: "button" }
                    },
                    [_vm._v("入力内容を編集する")]
                  )
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "deletePopup",
          attrs: {
            name: "deletePopup",
            id: "deletePopup",
            "modal-class": ["modal-action  "],
            centered: true,
            "no-close-on-backdrop": true,
            "header-close-label": "",
            "hide-footer": "",
            "hide-header": ""
          }
        },
        [
          _c("h5", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.projectInfo.client_name) + "を削除しますか？")
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-footer top-30" },
            [
              _c(
                "b-button",
                {
                  staticClass: "btn-submit",
                  on: {
                    click: function($event) {
                      return _vm.$bvModal.hide("deletePopup")
                    }
                  }
                },
                [_vm._v("キャンセル")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "btn btn-red",
                  on: {
                    click: function($event) {
                      return _vm.deleteProject()
                    }
                  }
                },
                [_vm._v("削除")]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "endProjectInfo",
          attrs: {
            name: "endProjectInfo",
            id: "endProjectInfo",
            "modal-class": ["modal-action  "],
            centered: true,
            "no-close-on-backdrop": true,
            "header-close-label": "",
            "hide-footer": "",
            "hide-header": ""
          }
        },
        [
          _c("h5", { staticClass: "title" }, [_vm._v("案件が完了しますか？")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-footer top-30" },
            [
              _c(
                "b-button",
                {
                  staticClass: "btn-submit",
                  on: {
                    click: function($event) {
                      return _vm.closeEnd()
                    }
                  }
                },
                [_vm._v("キャンセル")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "btn btn-red",
                  on: {
                    click: function($event) {
                      return _vm.end()
                    }
                  }
                },
                [_vm._v("完了")]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "sendPasswordPopup",
          attrs: {
            name: "sendPasswordPopup",
            id: "sendPasswordPopup",
            "modal-class": ["modal-action"],
            centered: true,
            "no-close-on-backdrop": true,
            "header-close-label": "",
            "hide-footer": "",
            "hide-header": ""
          }
        },
        [
          _c("h5", { staticClass: "title" }, [
            _vm._v("パスワードをリセットしてユーザーに送信しますか？")
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-footer top-30 resend" },
            [
              _c(
                "b-button",
                {
                  staticClass: "btn-submit",
                  on: {
                    click: function($event) {
                      return _vm.$bvModal.hide("sendPasswordPopup")
                    }
                  }
                },
                [_vm._v("キャンセル")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary",
                  on: {
                    click: function($event) {
                      return _vm.sendPassword()
                    }
                  }
                },
                [_vm._v("アカウント再発行")]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "isDisabled",
          attrs: {
            name: "isDisabled",
            id: "isDisabled",
            "modal-class": ["modal-action  "],
            centered: true,
            "no-close-on-backdrop": true,
            "header-close-label": "",
            "hide-footer": "",
            "hide-header": ""
          }
        },
        [
          _c("h5", { staticClass: "title" }, [
            _vm._v(
              "リスト提出締切日を" +
                _vm._s(
                  _vm.projectInfo.is_deadline_disabled == true ? "無効" : "有効"
                ) +
                "にしますか？"
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-footer top-30" },
            [
              _c(
                "b-button",
                {
                  staticClass: "btn-submit",
                  on: {
                    click: function($event) {
                      return _vm.closeDeadline()
                    }
                  }
                },
                [_vm._v("キャンセル")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "btn btn-red",
                  on: {
                    click: function($event) {
                      return _vm.changeDeadline()
                    }
                  }
                },
                [_vm._v("はい")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form_box_title" }, [
      _c("p", [_vm._v("基本情報")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form_box_title" }, [
      _c("p", [_vm._v("お客様情報")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form_box_title" }, [
      _c("p", [_vm._v("申込内容")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("hr")])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form_box_title" }, [
      _c("p", [_vm._v("詳細情報")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }