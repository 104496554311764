var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.projectInfo && _vm.projectInfo.id,
          expression: "projectInfo && projectInfo.id"
        }
      ],
      staticClass: "container",
      attrs: { id: "body_1_2" }
    },
    [
      _c("header-component", {
        attrs: {
          back: { name: "ProjectDetail", params: { id: this.projectInfo.id } },
          margin: true
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "fx_between_2", attrs: { id: "top_box_6_2" } }, [
        _c("div", { staticClass: "fx" }, [
          _c("h1", { staticClass: "h1_6_2" }, [
            _vm._v("申込No." + _vm._s(_vm.projectInfo.id))
          ]),
          _vm._v(" "),
          _vm.projectInfo.progress_status
            ? _c(
                "p",
                {
                  class:
                    "sta_6_2_" + (parseInt(_vm.projectInfo.progress_status) + 1)
                },
                [
                  _c("span", {
                    domProps: { innerHTML: _vm._s(_vm.projectStatusNumber()) }
                  }),
                  _vm._v(" " + _vm._s(_vm.progressStatus()))
                ]
              )
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "fx" }, [
          _c("p", { staticClass: "top_p_6_2" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.projectInfo.is_deadline_disabled,
                  expression: "projectInfo.is_deadline_disabled"
                }
              ],
              attrs: {
                type: "checkbox",
                name: "is_deadline_disabled",
                disabled: parseInt(_vm.projectInfo.progress_status) >= 4,
                id: "is_deadline_disabled"
              },
              domProps: {
                checked: Array.isArray(_vm.projectInfo.is_deadline_disabled)
                  ? _vm._i(_vm.projectInfo.is_deadline_disabled, null) > -1
                  : _vm.projectInfo.is_deadline_disabled
              },
              on: {
                change: [
                  function($event) {
                    var $$a = _vm.projectInfo.is_deadline_disabled,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.projectInfo,
                            "is_deadline_disabled",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.projectInfo,
                            "is_deadline_disabled",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.projectInfo, "is_deadline_disabled", $$c)
                    }
                  },
                  function($event) {
                    return _vm.changeDeadlineDisabled()
                  }
                ]
              }
            }),
            _vm._v(" "),
            _c(
              "label",
              {
                class: {
                  check_6_2: true,
                  gray: parseInt(_vm.projectInfo.progress_status) >= 4
                },
                attrs: { for: "is_deadline_disabled" }
              },
              [_vm._v("締切日を無効にする")]
            )
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "top_p_6_2" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.progress_end,
                  expression: "progress_end"
                }
              ],
              attrs: {
                disabled: parseInt(_vm.projectInfo.progress_status) != 3,
                type: "checkbox",
                name: "progress_end",
                id: "progress_end"
              },
              domProps: {
                checked: Array.isArray(_vm.progress_end)
                  ? _vm._i(_vm.progress_end, null) > -1
                  : _vm.progress_end
              },
              on: {
                change: [
                  function($event) {
                    var $$a = _vm.progress_end,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.progress_end = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.progress_end = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.progress_end = $$c
                    }
                  },
                  function($event) {
                    return _vm.endProject()
                  }
                ]
              }
            }),
            _c(
              "label",
              {
                class: {
                  check_6_2: true,
                  gray: parseInt(_vm.projectInfo.progress_status) != 3
                },
                attrs: { for: "progress_end" }
              },
              [_vm._v("完了にする")]
            )
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "del_6_2" }, [
            _c(
              "a",
              {
                attrs: { href: "javascript:;" },
                on: {
                  click: function($event) {
                    return _vm.showDelete()
                  }
                }
              },
              [_vm._v("削除")]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "fx_ce", attrs: { id: "sta_box" } }, [
        _c("p", { staticClass: "sta_p" }, [_vm._v("ステータス")]),
        _vm._v(" "),
        _c(
          "p",
          {
            class: {
              "sta_6_2_1 m_right": true,
              sta_opacity: parseInt(_vm.projectInfo.progress_status) != 0
            }
          },
          [_vm._v("①案件登録済")]
        ),
        _vm._v(" "),
        _c(
          "p",
          {
            class: {
              "sta_6_2_2 m_right": true,
              sta_opacity: parseInt(_vm.projectInfo.progress_status) != 1
            }
          },
          [_vm._v("②申込済")]
        ),
        _vm._v(" "),
        _c(
          "p",
          {
            class: {
              "sta_6_2_3 m_right": true,
              sta_opacity: parseInt(_vm.projectInfo.progress_status) != 2
            }
          },
          [_vm._v("③詳細情報登録済")]
        ),
        _vm._v(" "),
        _c(
          "p",
          {
            class: {
              "sta_6_2_4 m_right": true,
              sta_opacity: parseInt(_vm.projectInfo.progress_status) != 3
            }
          },
          [_vm._v("④リスト登録済")]
        ),
        _vm._v(" "),
        _c(
          "p",
          {
            class: {
              "sta_6_2_5 m_right": true,
              sta_opacity: parseInt(_vm.projectInfo.progress_status) != 4
            }
          },
          [_vm._v("⑤診断SG受理済")]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "attention" }, [
        parseInt(_vm.projectInfo.progress_status) == 0
          ? _c("p", [
              _vm._v("催促メール(開始日)　　　　"),
              _vm.projectInfo.preentry_alert_date
                ? _c("span", [
                    _vm._v(_vm._s(_vm.projectInfo.preentry_alert_date))
                  ])
                : _vm._e()
            ])
          : _c("p", [
              _vm._v("催促メール（リスト）　　　　"),
              _vm.projectInfo.postentry_alert_date
                ? _c("span", [
                    _vm._v(_vm._s(_vm.projectInfo.postentry_alert_date))
                  ])
                : _vm._e()
            ])
      ]),
      _vm._v(" "),
      _c("ValidationObserver", {
        ref: "observer",
        attrs: { tag: "form" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.editProject()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var invalid = ref.invalid
              return [
                _c("div", { staticClass: "form_box form_box_2_3" }, [
                  _c("div", { staticClass: "form_box_title" }, [
                    _c("p", [_vm._v("基本情報")])
                  ]),
                  _vm._v(" "),
                  _c("dl", [
                    _c("dt", [_vm._v("組織名(会社名)")]),
                    _vm._v(" "),
                    _c(
                      "dd",
                      [
                        _c("ValidationProvider", {
                          staticClass: "validate",
                          attrs: { name: "client_name", rules: "required" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.projectInfo.client_name,
                                          expression: "projectInfo.client_name"
                                        }
                                      ],
                                      class: {
                                        form_text: true,
                                        error: errors[0]
                                      },
                                      attrs: {
                                        type: "text",
                                        name: "client",
                                        value: "株式会社●○",
                                        maxlength: "50"
                                      },
                                      domProps: {
                                        value: _vm.projectInfo.client_name
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.projectInfo,
                                            "client_name",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("br", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: errors[0],
                                          expression: "errors[0]"
                                        }
                                      ]
                                    }),
                                    _c("span", { staticClass: "error" }, [
                                      _vm._v(_vm._s(errors[0]))
                                    ])
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("dl", [
                    _c("dt", [_vm._v("診断名")]),
                    _vm._v(" "),
                    _c("dd", [
                      _c("div", { staticStyle: { "padding-left": "35px" } }, [
                        _vm._v(
                          _vm._s(_vm.projectType(_vm.projectInfo.project_type))
                        )
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("dl", [
                    _c("dt", [_vm._v("担当営業名")]),
                    _vm._v(" "),
                    _c(
                      "dd",
                      [
                        _c("ValidationProvider", {
                          staticClass: "validate",
                          attrs: { name: "staff_name", rules: "required" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.projectInfo.staff_name,
                                          expression: "projectInfo.staff_name"
                                        }
                                      ],
                                      class: {
                                        form_text: true,
                                        error: errors[0]
                                      },
                                      attrs: {
                                        type: "text",
                                        name: "client",
                                        value: "営業哲夫",
                                        maxlength: "20"
                                      },
                                      domProps: {
                                        value: _vm.projectInfo.staff_name
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.projectInfo,
                                            "staff_name",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("br", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: errors[0],
                                          expression: "errors[0]"
                                        }
                                      ]
                                    }),
                                    _c("span", { staticClass: "error" }, [
                                      _vm._v(_vm._s(errors[0]))
                                    ])
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("dl", [
                    _c("dt", [_vm._v("共有先メールアドレス①")]),
                    _vm._v(" "),
                    _c("dd", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.projectInfo.staff_email1,
                            expression: "projectInfo.staff_email1"
                          }
                        ],
                        staticClass: "form_text",
                        attrs: {
                          type: "text",
                          name: "client",
                          value: "mailadress01@BCon.co.jp",
                          onKeyUp:
                            "$(this).val($(this).val().replace(/[^a-zA-Z.\\-+@+_\\d]/ig, ''))",
                          maxlength: "100"
                        },
                        domProps: { value: _vm.projectInfo.staff_email1 },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.projectInfo,
                              "staff_email1",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("dl", [
                    _c("dt", [_vm._v("共有先メールアドレス②")]),
                    _vm._v(" "),
                    _c("dd", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.projectInfo.staff_email2,
                            expression: "projectInfo.staff_email2"
                          }
                        ],
                        staticClass: "form_text",
                        attrs: {
                          type: "text",
                          name: "client",
                          value: "mailadress02@BCon.co.jp",
                          onKeyUp:
                            "$(this).val($(this).val().replace(/[^a-zA-Z.\\-+@+_\\d]/ig, ''))",
                          maxlength: "100"
                        },
                        domProps: { value: _vm.projectInfo.staff_email2 },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.projectInfo,
                              "staff_email2",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("dl", { staticClass: "fx_normal" }, [
                    _c("dt", { staticClass: "top" }, [_vm._v("備考")]),
                    _vm._v(" "),
                    _c(
                      "dd",
                      [
                        _c("ValidationProvider", {
                          staticClass: "validate",
                          attrs: { name: "memo", rules: "max:1000" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("textarea", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.projectInfo.memo,
                                          expression: "projectInfo.memo"
                                        }
                                      ],
                                      class: { error: errors[0] },
                                      attrs: {
                                        name: "memo",
                                        rows: "7",
                                        cols: "40",
                                        maxlength: "1000",
                                        placeholder:
                                          "診断チームに申し伝えることがあれば入力してください。"
                                      },
                                      domProps: { value: _vm.projectInfo.memo },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.projectInfo,
                                            "memo",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("br", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: errors[0],
                                          expression: "errors[0]"
                                        }
                                      ]
                                    }),
                                    _c("span", { staticClass: "error" }, [
                                      _vm._v(_vm._s(errors[0]))
                                    ])
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _vm.projectInfo.project_type == 11
                    ? _c("dl", [
                        _c("dt", [_vm._v("経年比較の有無")]),
                        _vm._v(" "),
                        _c(
                          "dd",
                          [
                            _c("ValidationProvider", {
                              attrs: { name: "is_comparison_age", rules: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.projectInfo
                                                  .is_comparison_age,
                                              expression:
                                                "projectInfo.is_comparison_age"
                                            }
                                          ],
                                          class: {
                                            form_radio: true,
                                            error: errors[0]
                                          },
                                          attrs: {
                                            type: "radio",
                                            name: "is_comparison_age",
                                            value: "1",
                                            id: "is_comparison_age1"
                                          },
                                          domProps: {
                                            checked: _vm._q(
                                              _vm.projectInfo.is_comparison_age,
                                              "1"
                                            )
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.$set(
                                                _vm.projectInfo,
                                                "is_comparison_age",
                                                "1"
                                              )
                                            }
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "label",
                                          {
                                            attrs: { for: "is_comparison_age1" }
                                          },
                                          [_vm._v("有り")]
                                        ),
                                        _vm._v(" "),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.projectInfo
                                                  .is_comparison_age,
                                              expression:
                                                "projectInfo.is_comparison_age"
                                            }
                                          ],
                                          class: {
                                            form_radio: true,
                                            error: errors[0]
                                          },
                                          attrs: {
                                            type: "radio",
                                            name: "is_comparison_age",
                                            value: "0",
                                            id: "is_comparison_age2"
                                          },
                                          domProps: {
                                            checked: _vm._q(
                                              _vm.projectInfo.is_comparison_age,
                                              "0"
                                            )
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.$set(
                                                _vm.projectInfo,
                                                "is_comparison_age",
                                                "0"
                                              )
                                            }
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "label",
                                          {
                                            attrs: { for: "is_comparison_age2" }
                                          },
                                          [_vm._v("無し")]
                                        ),
                                        _vm._v(" "),
                                        _c("br", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: errors[0],
                                              expression: "errors[0]"
                                            }
                                          ]
                                        }),
                                        _c("span", { staticClass: "error" }, [
                                          _vm._v(_vm._s(errors[0]))
                                        ])
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        )
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form_box form_box_2_3" }, [
                  _c("div", { staticClass: "form_box_title" }, [
                    _c("p", [_vm._v("お客さま情報")])
                  ]),
                  _vm._v(" "),
                  _c("dl", [
                    _c("dt", [_vm._v("担当者　所属")]),
                    _vm._v(" "),
                    _c(
                      "dd",
                      [
                        _c("ValidationProvider", {
                          staticClass: "validate",
                          attrs: { rules: "max:50", name: "division1" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _vm.projectInfo.user &&
                                    _vm.projectInfo.user[0] !== undefined
                                      ? _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.projectInfo.user[0]
                                                  .division,
                                              expression:
                                                "projectInfo.user[0].division"
                                            }
                                          ],
                                          class: {
                                            form_text: true,
                                            error: errors[0]
                                          },
                                          attrs: {
                                            type: "text",
                                            name: "client",
                                            value: "division",
                                            maxlength: "50"
                                          },
                                          domProps: {
                                            value:
                                              _vm.projectInfo.user[0].division
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.projectInfo.user[0],
                                                "division",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      : _vm._e()
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("dl", [
                    _c("dt", [_vm._v("担当者　役職")]),
                    _vm._v(" "),
                    _c(
                      "dd",
                      [
                        _c("ValidationProvider", {
                          staticClass: "validate",
                          attrs: { rules: "max:50", name: "position1" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _vm.projectInfo.user &&
                                    _vm.projectInfo.user[0] !== undefined
                                      ? _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.projectInfo.user[0]
                                                  .position,
                                              expression:
                                                "projectInfo.user[0].position"
                                            }
                                          ],
                                          class: {
                                            form_text: true,
                                            error: errors[0]
                                          },
                                          attrs: {
                                            type: "text",
                                            name: "position",
                                            value: "部長"
                                          },
                                          domProps: {
                                            value:
                                              _vm.projectInfo.user[0].position
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.projectInfo.user[0],
                                                "position",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      : _vm._e()
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("dl", [
                    _c("dt", [_vm._v("担当者　氏名")]),
                    _vm._v(" "),
                    _c(
                      "dd",
                      [
                        _c("ValidationProvider", {
                          staticClass: "validate",
                          attrs: { name: "name1", rules: "required|max:50" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _vm.projectInfo.user &&
                                    _vm.projectInfo.user[0] !== undefined
                                      ? _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.projectInfo.user[0].name,
                                              expression:
                                                "projectInfo.user[0].name "
                                            }
                                          ],
                                          class: {
                                            form_text: true,
                                            error: errors[0]
                                          },
                                          attrs: {
                                            type: "text",
                                            maxlength: "50",
                                            name: "client",
                                            value: "田中一郎"
                                          },
                                          domProps: {
                                            value: _vm.projectInfo.user[0].name
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.projectInfo.user[0],
                                                "name",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("br", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: errors[0],
                                          expression: "errors[0]"
                                        }
                                      ]
                                    }),
                                    _c("span", { staticClass: "error" }, [
                                      _vm._v(_vm._s(errors[0]))
                                    ])
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("dl", [
                    _c("dt", [_vm._v("担当者　メールアドレス")]),
                    _vm._v(" "),
                    _c("dd", [
                      _c(
                        "div",
                        { staticClass: "fx_between_2" },
                        [
                          _c("ValidationProvider", {
                            staticClass: "validate",
                            attrs: {
                              name: "email1",
                              rules: {
                                required: true,
                                email: true,
                                regex: /^[0-9A-Za-z._\-+@]+$/,
                                max: 100
                              }
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var errors = ref.errors
                                    return [
                                      _vm.projectInfo.user &&
                                      _vm.projectInfo.user[0] !== undefined
                                        ? _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.projectInfo.user[0].email,
                                                expression:
                                                  "projectInfo.user[0].email"
                                              }
                                            ],
                                            class: {
                                              "form_text form_text2": true,
                                              error: errors[0]
                                            },
                                            attrs: {
                                              type: "text",
                                              name: "client",
                                              value: "mailadress03@BCon.co.jp",
                                              maxlength: "100",
                                              onKeyUp:
                                                "$(this).val($(this).val().replace(/[^a-zA-Z.\\-+@+\\d]/ig, ''))"
                                            },
                                            domProps: {
                                              value:
                                                _vm.projectInfo.user[0].email
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.projectInfo.user[0],
                                                  "email",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("br", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: errors[0],
                                            expression: "errors[0]"
                                          }
                                        ]
                                      }),
                                      _c("span", { staticClass: "error" }, [
                                        _vm._v(_vm._s(errors[0]))
                                      ])
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("dl", [
                    _c("dt", [_vm._v("担当者2人目　氏名")]),
                    _vm._v(" "),
                    _vm.projectInfo.user &&
                    _vm.projectInfo.user[1] !== undefined
                      ? _c(
                          "dd",
                          [
                            _c("ValidationProvider", {
                              staticClass: "validate",
                              attrs: {
                                rules: !_vm.isEmpty(
                                  _vm.projectInfo.user[1].email
                                )
                                  ? "required"
                                  : "",
                                name: "name2"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _vm.projectInfo.user &&
                                        _vm.projectInfo.user[1] !== undefined
                                          ? _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.projectInfo.user[1]
                                                      .name,
                                                  expression:
                                                    "projectInfo.user[1].name "
                                                }
                                              ],
                                              class: {
                                                form_text: true,
                                                error: errors[0]
                                              },
                                              attrs: {
                                                type: "text",
                                                maxlength: "20",
                                                name: "client",
                                                value: "田中一郎"
                                              },
                                              domProps: {
                                                value:
                                                  _vm.projectInfo.user[1].name
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.projectInfo.user[1],
                                                    "name",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c("br", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: errors[0],
                                              expression: "errors[0]"
                                            }
                                          ]
                                        }),
                                        _c("span", { staticClass: "error" }, [
                                          _vm._v(_vm._s(errors[0]))
                                        ])
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("dl", [
                    _c("dt", [_vm._v("担当者2人目　メールアドレス")]),
                    _vm._v(" "),
                    _vm.projectInfo.user &&
                    _vm.projectInfo.user[1] !== undefined
                      ? _c("dd", [
                          _c(
                            "div",
                            { staticClass: "fx_between_2" },
                            [
                              _c("ValidationProvider", {
                                staticClass: "validate",
                                attrs: {
                                  rules: {
                                    same: _vm.projectInfo.user[0].email,
                                    required: !_vm.isEmpty(
                                      _vm.projectInfo.user[1].name
                                    ),
                                    regex: /^[0-9A-Za-z._\-+@]+$/,
                                    max: 100
                                  },
                                  name: "email2"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var errors = ref.errors
                                        return [
                                          _vm.projectInfo.user &&
                                          _vm.projectInfo.user[1] !== undefined
                                            ? _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.projectInfo.user[1]
                                                        .email,
                                                    expression:
                                                      "projectInfo.user[1].email"
                                                  }
                                                ],
                                                class: {
                                                  "form_text form_text2": true,
                                                  error: errors[0]
                                                },
                                                attrs: {
                                                  maxlength: "100",
                                                  type: "text",
                                                  name: "client",
                                                  value:
                                                    "mailadress03@BCon.co.jp",
                                                  onKeyUp:
                                                    "$(this).val($(this).val().replace(/[^a-zA-Z.\\-+@_\\d]/ig, ''))"
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.projectInfo.user[1]
                                                      .email
                                                },
                                                on: {
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      _vm.projectInfo.user[1],
                                                      "email",
                                                      $event.target.value
                                                    )
                                                  }
                                                }
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("br", {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: errors[0],
                                                expression: "errors[0]"
                                              }
                                            ]
                                          }),
                                          _c("span", { staticClass: "error" }, [
                                            _vm._v(_vm._s(errors[0]))
                                          ])
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            ],
                            1
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("dl", [
                    _c("dt", [_vm._v("電話番号")]),
                    _vm._v(" "),
                    _c(
                      "dd",
                      [
                        _c("ValidationProvider", {
                          staticClass: "validate",
                          attrs: {
                            name: "client_tel",
                            rules: {
                              required: true,
                              numeric: true,
                              regex: /^[0-9a-zA-Z]*$/
                            }
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.projectInfo.client_tel,
                                          expression: "projectInfo.client_tel"
                                        }
                                      ],
                                      class: {
                                        form_text: true,
                                        error: errors[0]
                                      },
                                      attrs: {
                                        type: "tel",
                                        pattern: "[0-9]+",
                                        maxlength: "11",
                                        name: "client",
                                        value: "03xxxxxxxx",
                                        onKeyUp:
                                          "$(this).val($(this).val().replace(/[^\\d]/ig, ''))",
                                        autocomplete: "none"
                                      },
                                      domProps: {
                                        value: _vm.projectInfo.client_tel
                                      },
                                      on: {
                                        keypress: function($event) {
                                          return _vm.preventAlphabetInput(
                                            $event
                                          )
                                        },
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.projectInfo,
                                            "client_tel",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("br", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: errors[0],
                                          expression: "errors[0]"
                                        }
                                      ]
                                    }),
                                    _c("span", { staticClass: "error" }, [
                                      _vm._v(_vm._s(errors[0]))
                                    ])
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("dl", [
                    _c("dt", { staticClass: "required8 form_box_2_3_dt top" }, [
                      _vm._v("住所"),
                      _c("br")
                    ]),
                    _vm._v(" "),
                    _c("dd", { staticClass: "margin_dd" }, [
                      _c(
                        "p",
                        [
                          _c("ValidationProvider", {
                            staticClass: "validate label_p",
                            attrs: { name: "zip_code", rules: "required" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var errors = ref.errors
                                    return [
                                      _vm._v("\n              〒"),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.projectInfo.zip_code,
                                            expression: "projectInfo.zip_code"
                                          }
                                        ],
                                        class: {
                                          "form_text form_address": true,
                                          error: errors[0]
                                        },
                                        attrs: {
                                          name: "zip_code",
                                          placeholder: "例：1000005",
                                          type: "tel",
                                          pattern: "[0-9]+",
                                          maxlength: "11",
                                          onKeyUp:
                                            "$(this).val($(this).val().replace(/[^\\d]/ig, ''))",
                                          autocomplete: "none"
                                        },
                                        domProps: {
                                          value: _vm.projectInfo.zip_code
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.projectInfo,
                                              "zip_code",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("input", {
                                        staticClass: "input_button",
                                        attrs: {
                                          type: "button",
                                          value: "住所検索"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.searchAddress()
                                          }
                                        }
                                      }),
                                      _c("span", {}),
                                      _c("br"),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          "※ハイフンを入れずに入力してください。"
                                        )
                                      ])
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.searchAddErr
                        ? _c("div", { staticStyle: { color: "#DB484A" } }, [
                            _c("span", { staticClass: "error" }, [
                              _vm._v(_vm._s(_vm.searchAddErr))
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "p",
                        {},
                        [
                          _c("ValidationProvider", {
                            staticClass: "validate",
                            attrs: { name: "address1", rules: "required" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var errors = ref.errors
                                    return [
                                      _vm._v(
                                        "\n              都道府県\n              "
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.projectInfo.address1,
                                            expression: "projectInfo.address1"
                                          }
                                        ],
                                        class: {
                                          "form_text form_address_1": true,
                                          error: errors[0]
                                        },
                                        attrs: {
                                          maxlength: "10",
                                          placeholder: "例：東京都",
                                          type: "text"
                                        },
                                        domProps: {
                                          value: _vm.projectInfo.address1
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.projectInfo,
                                              "address1",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        {},
                        [
                          _c("ValidationProvider", {
                            staticClass: "validate",
                            attrs: { name: "address2", rules: "required" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var errors = ref.errors
                                    return [
                                      _vm._v(
                                        "\n              市区町村番地\n              "
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.projectInfo.address2,
                                            expression: "projectInfo.address2"
                                          }
                                        ],
                                        class: {
                                          "form_text form_address_2": true,
                                          error: errors[0]
                                        },
                                        attrs: {
                                          maxlength: "50",
                                          placeholder:
                                            "例：千代田区丸の内　1-7-12",
                                          type: "text"
                                        },
                                        domProps: {
                                          value: _vm.projectInfo.address2
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.projectInfo,
                                              "address2",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("p", {}, [
                        _c("span", { staticClass: "validate" }, [
                          _vm._v("\n                ビル名\n                "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.projectInfo.address3,
                                expression: "projectInfo.address3"
                              }
                            ],
                            staticClass: "form_text form_address_3",
                            attrs: {
                              placeholder: "例：サピアタワー　18F",
                              maxlength: "50",
                              type: "text"
                            },
                            domProps: { value: _vm.projectInfo.address3 },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.projectInfo,
                                  "address3",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      ])
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form_box form_box_2_3" }, [
                  _c("div", { staticClass: "form_box_title" }, [
                    _c("p", [_vm._v("申込内容入力")])
                  ]),
                  _vm._v(" "),
                  _c("dl", [
                    _c("dt", { staticClass: " form_box_2_3_dt_1 top" }, [
                      _vm._v("実施方法\n        ")
                    ]),
                    _vm._v(" "),
                    _c("dd", [
                      _c(
                        "p",
                        { staticClass: "margin_dd" },
                        [
                          _c("ValidationProvider", {
                            staticClass: "validate",
                            attrs: { name: "medium_type", rules: "required" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var errors = ref.errors
                                    return [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.projectInfo.medium_type,
                                            expression:
                                              "projectInfo.medium_type"
                                          }
                                        ],
                                        class: {
                                          form_radio: true,
                                          error: errors[0]
                                        },
                                        attrs: {
                                          id: "medium_type1",
                                          name: "medium_type",
                                          type: "radio",
                                          value: "1"
                                        },
                                        domProps: {
                                          checked: _vm._q(
                                            _vm.projectInfo.medium_type,
                                            "1"
                                          )
                                        },
                                        on: {
                                          change: [
                                            function($event) {
                                              return _vm.$set(
                                                _vm.projectInfo,
                                                "medium_type",
                                                "1"
                                              )
                                            },
                                            function($event) {
                                              return _vm.changeMedium()
                                            }
                                          ]
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "label",
                                        {
                                          staticClass: "form-check-label",
                                          attrs: { for: "medium_type1" }
                                        },
                                        [_vm._v("Web")]
                                      ),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.projectInfo.medium_type,
                                            expression:
                                              "projectInfo.medium_type"
                                          }
                                        ],
                                        class: {
                                          form_radio: true,
                                          error: errors[0]
                                        },
                                        attrs: {
                                          id: "medium_type2",
                                          name: "medium_type",
                                          type: "radio",
                                          value: "2"
                                        },
                                        domProps: {
                                          checked: _vm._q(
                                            _vm.projectInfo.medium_type,
                                            "2"
                                          )
                                        },
                                        on: {
                                          change: [
                                            function($event) {
                                              return _vm.$set(
                                                _vm.projectInfo,
                                                "medium_type",
                                                "2"
                                              )
                                            },
                                            function($event) {
                                              return _vm.changeMedium()
                                            }
                                          ]
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "label",
                                        {
                                          staticClass: "form-check-label",
                                          attrs: { for: "medium_type2" }
                                        },
                                        [_vm._v("質問紙")]
                                      ),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.projectInfo.medium_type,
                                            expression:
                                              "projectInfo.medium_type"
                                          }
                                        ],
                                        class: {
                                          form_radio: true,
                                          error: errors[0]
                                        },
                                        attrs: {
                                          id: "medium_type3",
                                          name: "medium_type",
                                          type: "radio",
                                          value: "3"
                                        },
                                        domProps: {
                                          checked: _vm._q(
                                            _vm.projectInfo.medium_type,
                                            "3"
                                          )
                                        },
                                        on: {
                                          change: [
                                            function($event) {
                                              return _vm.$set(
                                                _vm.projectInfo,
                                                "medium_type",
                                                "3"
                                              )
                                            },
                                            function($event) {
                                              return _vm.changeMedium()
                                            }
                                          ]
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "label",
                                        {
                                          staticClass: "form-check-label",
                                          attrs: { for: "medium_type3" }
                                        },
                                        [_vm._v("Web + 質問紙")]
                                      ),
                                      _vm._v(" "),
                                      _c("br", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: errors[0],
                                            expression: "errors[0]"
                                          }
                                        ]
                                      }),
                                      _c("span", { staticClass: "error" }, [
                                        _vm._v(_vm._s(errors[0]))
                                      ])
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "charge_box" },
                        [
                          _vm.projectInfo.medium_type == 1
                            ? _c("ValidationProvider", {
                                staticClass: "validate no-padding",
                                attrs: {
                                  name: "web_people",
                                  rules: "required|max_value:1000"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var errors = ref.errors
                                        return [
                                          _c(
                                            "p",
                                            { attrs: { id: "web_box" } },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.projectInfo
                                                        .web_people,
                                                    expression:
                                                      "projectInfo.web_people"
                                                  }
                                                ],
                                                class: {
                                                  "form_text form_radio_text web_box_text": true,
                                                  error: errors[0]
                                                },
                                                attrs: {
                                                  name: "web_people",
                                                  type: "tel",
                                                  pattern: "[0-9]+",
                                                  maxlength: "4",
                                                  onKeyUp:
                                                    "$(this).val($(this).val().replace(/[^\\d]/ig, ''))",
                                                  autocomplete: "none"
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.projectInfo.web_people
                                                },
                                                on: {
                                                  input: [
                                                    function($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        _vm.projectInfo,
                                                        "web_people",
                                                        $event.target.value
                                                      )
                                                    },
                                                    _vm.webPeople
                                                  ],
                                                  keypress: function($event) {
                                                    return _vm.preventAlphabetInput(
                                                      $event
                                                    )
                                                  }
                                                }
                                              }),
                                              _vm._v(" 名"),
                                              _c("br"),
                                              _c("span", [
                                                _vm._v(
                                                  "※想定人数で構いません。"
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _c("br", {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: errors[0],
                                                    expression: "errors[0]"
                                                  }
                                                ]
                                              }),
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value: errors[0],
                                                      expression: "errors[0]"
                                                    }
                                                  ],
                                                  staticClass: "error"
                                                },
                                                [_vm._v(_vm._s(errors[0]))]
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.projectInfo.medium_type == 2
                            ? _c("ValidationProvider", {
                                staticClass: "validate no-padding",
                                attrs: {
                                  name: "paper_people",
                                  rules: "required|max_value:1000"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var errors = ref.errors
                                        return [
                                          _c(
                                            "p",
                                            { attrs: { id: "web_box1" } },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.projectInfo
                                                        .paper_people,
                                                    expression:
                                                      "projectInfo.paper_people"
                                                  }
                                                ],
                                                class: {
                                                  "form_text form_radio_text web_box_text": true,
                                                  error: errors[0]
                                                },
                                                attrs: {
                                                  name: "paper_people",
                                                  onKeyUp:
                                                    "$(this).val($(this).val().replace(/[^\\d]/ig, ''))",
                                                  type: "tel",
                                                  pattern: "[0-9]+",
                                                  maxlength: "4",
                                                  autocomplete: "none"
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.projectInfo.paper_people
                                                },
                                                on: {
                                                  input: [
                                                    function($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        _vm.projectInfo,
                                                        "paper_people",
                                                        $event.target.value
                                                      )
                                                    },
                                                    _vm.paperPeople
                                                  ],
                                                  keypress: function($event) {
                                                    return _vm.preventAlphabetInput(
                                                      $event
                                                    )
                                                  }
                                                }
                                              }),
                                              _vm._v(" 名"),
                                              _c("br"),
                                              _c("span", [
                                                _vm._v(
                                                  "※想定人数で構いません。"
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _c("br", {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: errors[0],
                                                    expression: "errors[0]"
                                                  }
                                                ]
                                              }),
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value: errors[0],
                                                      expression: "errors[0]"
                                                    }
                                                  ],
                                                  staticClass: "error"
                                                },
                                                [_vm._v(_vm._s(errors[0]))]
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.projectInfo.medium_type == 3
                            ? _c("ValidationProvider", {
                                staticClass: "validate no-padding",
                                attrs: {
                                  name: "web_people",
                                  rules: "required|max_value:1000"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var errors = ref.errors
                                        return [
                                          _c(
                                            "p",
                                            { attrs: { id: "web_box2" } },
                                            [
                                              _vm._v(
                                                "　　Web\n\n                "
                                              ),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.projectInfo
                                                        .web_people,
                                                    expression:
                                                      "projectInfo.web_people"
                                                  }
                                                ],
                                                class: {
                                                  "form_text form_radio_text web_box_text": true,
                                                  error: errors[0]
                                                },
                                                attrs: {
                                                  name: "web_people",
                                                  type: "tel",
                                                  pattern: "[0-9]+",
                                                  maxlength: "4",
                                                  onKeyUp:
                                                    "$(this).val($(this).val().replace(/[^\\d]/ig, ''))",
                                                  autocomplete: "none"
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.projectInfo.web_people
                                                },
                                                on: {
                                                  input: [
                                                    function($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        _vm.projectInfo,
                                                        "web_people",
                                                        $event.target.value
                                                      )
                                                    },
                                                    _vm.webPeople
                                                  ],
                                                  keypress: function($event) {
                                                    return _vm.preventAlphabetInput(
                                                      $event
                                                    )
                                                  }
                                                }
                                              }),
                                              _vm._v(" 名\n                "),
                                              _c("br"),
                                              _c("span", [
                                                _vm._v(
                                                  "※想定人数で構いません。"
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _c("br", {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: errors[0],
                                                    expression: "errors[0]"
                                                  }
                                                ]
                                              }),
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value: errors[0],
                                                      expression: "errors[0]"
                                                    }
                                                  ],
                                                  staticClass: "error"
                                                },
                                                [_vm._v(_vm._s(errors[0]))]
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.projectInfo.medium_type == 3
                            ? _c("ValidationProvider", {
                                staticClass: "validate no-padding",
                                attrs: {
                                  name: "paper_people",
                                  rules: "required|max_value:1000"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var errors = ref.errors
                                        return [
                                          _c(
                                            "p",
                                            { attrs: { id: "web_box3" } },
                                            [
                                              _vm._v(
                                                "　　質問紙\n                "
                                              ),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.projectInfo
                                                        .paper_people,
                                                    expression:
                                                      "projectInfo.paper_people"
                                                  }
                                                ],
                                                class: {
                                                  "form_text form_radio_text web_box_text": true,
                                                  error: errors[0]
                                                },
                                                attrs: {
                                                  name: "paper_people",
                                                  type: "tel",
                                                  pattern: "[0-9]+",
                                                  maxlength: "4",
                                                  onKeyUp:
                                                    "$(this).val($(this).val().replace(/[^\\d]/ig, ''))",
                                                  autocomplete: "none"
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.projectInfo.paper_people
                                                },
                                                on: {
                                                  input: [
                                                    function($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        _vm.projectInfo,
                                                        "paper_people",
                                                        $event.target.value
                                                      )
                                                    },
                                                    _vm.paperPeople
                                                  ],
                                                  keypress: function($event) {
                                                    return _vm.preventAlphabetInput(
                                                      $event
                                                    )
                                                  }
                                                }
                                              }),
                                              _vm._v(" 名\n                "),
                                              _c("br", {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: errors[0],
                                                    expression: "errors[0]"
                                                  }
                                                ]
                                              }),
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value: errors[0],
                                                      expression: "errors[0]"
                                                    }
                                                  ],
                                                  staticClass: "error"
                                                },
                                                [_vm._v(_vm._s(errors[0]))]
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _vm.projectInfo.medium_type == 2 ||
                  _vm.projectInfo.medium_type == 3
                    ? _c("dl", { staticClass: "radio_2" }, [
                        _c("dt", {}, [_vm._v("質問紙の送付先拠点数")]),
                        _vm._v(" "),
                        _c(
                          "dd",
                          [
                            _c("ValidationProvider", {
                              staticStyle: {
                                display: "flex",
                                "margin-left": "0"
                              },
                              attrs: {
                                name: "paper_send_type",
                                rules: "required"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.projectInfo.paper_send_type,
                                              expression:
                                                "projectInfo.paper_send_type"
                                            }
                                          ],
                                          class: {
                                            form_radio: true,
                                            error: errors[0]
                                          },
                                          attrs: {
                                            id: "paper_send_type1",
                                            name: "paper_send_type",
                                            type: "radio",
                                            value: "1"
                                          },
                                          domProps: {
                                            checked: _vm._q(
                                              _vm.projectInfo.paper_send_type,
                                              "1"
                                            )
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.$set(
                                                _vm.projectInfo,
                                                "paper_send_type",
                                                "1"
                                              )
                                            }
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "label",
                                          {
                                            staticClass: "form-check-label",
                                            attrs: { for: "paper_send_type1" }
                                          },
                                          [_vm._v("1拠点")]
                                        ),
                                        _vm._v(" "),
                                        !(
                                          (_vm.projectInfo.project_type >= 1 &&
                                            _vm.projectInfo.project_type <=
                                              6) ||
                                          (_vm.projectInfo.project_type >= 21 &&
                                            _vm.projectInfo.project_type <= 24)
                                        )
                                          ? _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.projectInfo
                                                      .paper_send_type,
                                                  expression:
                                                    "projectInfo.paper_send_type"
                                                }
                                              ],
                                              class: {
                                                form_radio: true,
                                                error: errors[0]
                                              },
                                              attrs: {
                                                id: "paper_send_type2",
                                                name: "paper_send_type",
                                                type: "radio",
                                                value: "2"
                                              },
                                              domProps: {
                                                checked: _vm._q(
                                                  _vm.projectInfo
                                                    .paper_send_type,
                                                  "2"
                                                )
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.$set(
                                                    _vm.projectInfo,
                                                    "paper_send_type",
                                                    "2"
                                                  )
                                                }
                                              }
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        !(
                                          (_vm.projectInfo.project_type >= 1 &&
                                            _vm.projectInfo.project_type <=
                                              6) ||
                                          (_vm.projectInfo.project_type >= 21 &&
                                            _vm.projectInfo.project_type <= 24)
                                        )
                                          ? _c(
                                              "label",
                                              {
                                                staticClass: "form-check-label",
                                                attrs: {
                                                  for: "paper_send_type2"
                                                }
                                              },
                                              [_vm._v("2拠点")]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        !(
                                          (_vm.projectInfo.project_type >= 1 &&
                                            _vm.projectInfo.project_type <=
                                              6) ||
                                          (_vm.projectInfo.project_type >= 21 &&
                                            _vm.projectInfo.project_type <= 24)
                                        )
                                          ? _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.projectInfo
                                                      .paper_send_type,
                                                  expression:
                                                    "projectInfo.paper_send_type"
                                                }
                                              ],
                                              class: {
                                                form_radio: true,
                                                error: errors[0]
                                              },
                                              attrs: {
                                                id: "paper_send_type3",
                                                name: "paper_send_type",
                                                type: "radio",
                                                value: "3"
                                              },
                                              domProps: {
                                                checked: _vm._q(
                                                  _vm.projectInfo
                                                    .paper_send_type,
                                                  "3"
                                                )
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.$set(
                                                    _vm.projectInfo,
                                                    "paper_send_type",
                                                    "3"
                                                  )
                                                }
                                              }
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        !(
                                          (_vm.projectInfo.project_type >= 1 &&
                                            _vm.projectInfo.project_type <=
                                              6) ||
                                          (_vm.projectInfo.project_type >= 21 &&
                                            _vm.projectInfo.project_type <= 24)
                                        )
                                          ? _c(
                                              "label",
                                              {
                                                staticClass: "form-check-label",
                                                attrs: {
                                                  for: "paper_send_type3"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "3拠点以上(日程は目安となります。)"
                                                ),
                                                _c("br"),
                                                _vm._v(
                                                  "※３拠点以上の場合は申込完了後に担当者からご連絡致します。"
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.projectInfo.paper_send_type == 1 ||
                  _vm.projectInfo.paper_send_type == 2
                    ? _c("dl", {}, [
                        _c("dt", { staticClass: "form_box_2_3_dt2 top" }, [
                          _vm._v("質問紙の宛先①")
                        ]),
                        _vm._v(" "),
                        _c(
                          "dd",
                          { staticClass: "margin_dd" },
                          [
                            _c("span", { staticClass: "margin_l_2_3_3" }, [
                              _vm._v(
                                "※納品先と質問紙の納付先が異なる場合は、入力し直してください。"
                              )
                            ]),
                            _vm._v(" "),
                            _c("ValidationProvider", {
                              staticClass: "validate",
                              attrs: {
                                name: "paper1_division",
                                rules: "required"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("p", [
                                          _vm._v("部署"),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.projectInfo
                                                    .paper1_division,
                                                expression:
                                                  "projectInfo.paper1_division"
                                              }
                                            ],
                                            class: {
                                              "form_text tel_number": true,
                                              error: errors[0]
                                            },
                                            attrs: {
                                              type: "text",
                                              name: "paper1_division",
                                              placeholder: "○○○部"
                                            },
                                            domProps: {
                                              value:
                                                _vm.projectInfo.paper1_division
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.projectInfo,
                                                  "paper1_division",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        ])
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _vm._v(" "),
                            _c("ValidationProvider", {
                              staticClass: "validate",
                              attrs: { name: "paper1_name", rules: "required" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("p", [
                                          _vm._v("氏名"),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.projectInfo.paper1_name,
                                                expression:
                                                  "projectInfo.paper1_name"
                                              }
                                            ],
                                            class: {
                                              "form_text tel_number": true,
                                              error: errors[0]
                                            },
                                            attrs: {
                                              type: "text",
                                              name: "paper1_name",
                                              placeholder: "田中一郎"
                                            },
                                            domProps: {
                                              value: _vm.projectInfo.paper1_name
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.projectInfo,
                                                  "paper1_name",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        ])
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _vm._v(" "),
                            _c("ValidationProvider", {
                              staticClass: "validate",
                              attrs: { name: "paper1_position", rules: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("p", [
                                          _vm._v("役職"),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.projectInfo
                                                    .paper1_position,
                                                expression:
                                                  "projectInfo.paper1_position"
                                              }
                                            ],
                                            class: {
                                              "form_text tel_number": true,
                                              error: errors[0]
                                            },
                                            attrs: {
                                              type: "text",
                                              name: "paper1_position",
                                              placeholder: "部長"
                                            },
                                            domProps: {
                                              value:
                                                _vm.projectInfo.paper1_position
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.projectInfo,
                                                  "paper1_position",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        ])
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _vm._v(" "),
                            _c(
                              "p",
                              [
                                _c("ValidationProvider", {
                                  staticClass: "validate",
                                  attrs: {
                                    name: "paper1_zip_code",
                                    rules: "required"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _vm._v("\n              〒"),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.projectInfo
                                                      .paper1_zip_code,
                                                  expression:
                                                    "projectInfo.paper1_zip_code"
                                                }
                                              ],
                                              class: {
                                                "form_text form_address": true,
                                                error: errors[0]
                                              },
                                              attrs: {
                                                name: "paper1_zip_code",
                                                placeholder: "例：1000005",
                                                type: "tel",
                                                pattern: "[0-9]+",
                                                maxlength: "11",
                                                onKeyUp:
                                                  "$(this).val($(this).val().replace(/[^\\d]/ig, ''))",
                                                autocomplete: "none"
                                              },
                                              domProps: {
                                                value:
                                                  _vm.projectInfo
                                                    .paper1_zip_code
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.projectInfo,
                                                    "paper1_zip_code",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("input", {
                                              staticClass: "input_button",
                                              attrs: {
                                                type: "button",
                                                value: "住所検索"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.searchAddressPaper()
                                                }
                                              }
                                            }),
                                            _c("span", {}),
                                            _c("br"),
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(
                                                "※ハイフンを入れずに入力してください。"
                                              )
                                            ])
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.searchAddErr1
                              ? _c(
                                  "div",
                                  { staticStyle: { color: "#DB484A" } },
                                  [
                                    _c("span", { staticClass: "error" }, [
                                      _vm._v(_vm._s(_vm.searchAddErr1))
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "p",
                              {},
                              [
                                _c("ValidationProvider", {
                                  staticClass: "validate",
                                  attrs: {
                                    name: "paper1_address1",
                                    rules: "required"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _vm._v(
                                              "\n              都道府県\n              "
                                            ),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.projectInfo
                                                      .paper1_address1,
                                                  expression:
                                                    "projectInfo.paper1_address1"
                                                }
                                              ],
                                              class: {
                                                "form_text form_address_1": true,
                                                error: errors[0]
                                              },
                                              attrs: {
                                                maxlength: "10",
                                                name: "paper1_address1",
                                                placeholder: "例：東京都",
                                                type: "text"
                                              },
                                              domProps: {
                                                value:
                                                  _vm.projectInfo
                                                    .paper1_address1
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.projectInfo,
                                                    "paper1_address1",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("br", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: errors[0],
                                                  expression: "errors[0]"
                                                }
                                              ]
                                            }),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: errors[0],
                                                    expression: "errors[0]"
                                                  }
                                                ],
                                                staticClass: "error left-150"
                                              },
                                              [_vm._v(_vm._s(errors[0]))]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "p",
                              {},
                              [
                                _c("ValidationProvider", {
                                  staticClass: "validate",
                                  attrs: {
                                    name: "paper1_address2",
                                    rules: "required"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _vm._v(
                                              "\n              市区町村番地\n              "
                                            ),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.projectInfo
                                                      .paper1_address2,
                                                  expression:
                                                    "projectInfo.paper1_address2"
                                                }
                                              ],
                                              class: {
                                                "form_text form_address_2": true,
                                                error: errors[0]
                                              },
                                              attrs: {
                                                maxlength: "50",
                                                name: "paper1_address2",
                                                placeholder:
                                                  "例：千代田区丸の内　1-7-12",
                                                type: "text"
                                              },
                                              domProps: {
                                                value:
                                                  _vm.projectInfo
                                                    .paper1_address2
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.projectInfo,
                                                    "paper1_address2",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("br", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: errors[0],
                                                  expression: "errors[0]"
                                                }
                                              ]
                                            }),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: errors[0],
                                                    expression: "errors[0]"
                                                  }
                                                ],
                                                staticClass: "error left-150"
                                              },
                                              [_vm._v(_vm._s(errors[0]))]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("p", {}, [
                              _c("span", { staticClass: "validate" }, [
                                _vm._v(
                                  "\n              ビル名\n              "
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.projectInfo.paper1_address3,
                                      expression: "projectInfo.paper1_address3"
                                    }
                                  ],
                                  staticClass: "form_text form_address_3",
                                  attrs: {
                                    placeholder: "例：サピアタワー　18F",
                                    maxlength: "50",
                                    type: "text"
                                  },
                                  domProps: {
                                    value: _vm.projectInfo.paper1_address3
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.projectInfo,
                                        "paper1_address3",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ])
                            ]),
                            _vm._v(" "),
                            _vm.projectInfo.project_type >= 11 &&
                            _vm.projectInfo.project_type <= 16
                              ? _c("p", [
                                  _vm._v("部数\n            "),
                                  _c(
                                    "strong",
                                    [
                                      _c("ValidationProvider", {
                                        staticClass: "validate",
                                        attrs: {
                                          name: "paper1_copies",
                                          rules: "numeric|max_value:10000"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.projectInfo
                                                            .paper1_copies,
                                                        expression:
                                                          "projectInfo.paper1_copies"
                                                      }
                                                    ],
                                                    class: {
                                                      "form_text form_text_2_3_2": true,
                                                      error: errors[0]
                                                    },
                                                    attrs: {
                                                      name: "paper1_copies",
                                                      max: "10000",
                                                      placeholder: "",
                                                      type: "number",
                                                      onKeyUp:
                                                        "$(this).val($(this).val().replace(/[^\\d]/ig, ''))",
                                                      autocomplete: "none"
                                                    },
                                                    domProps: {
                                                      value:
                                                        _vm.projectInfo
                                                          .paper1_copies
                                                    },
                                                    on: {
                                                      keypress: function(
                                                        $event
                                                      ) {
                                                        return _vm.preventAlphabetInput(
                                                          $event
                                                        )
                                                      },
                                                      input: function($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          _vm.projectInfo,
                                                          "paper1_copies",
                                                          $event.target.value
                                                        )
                                                      }
                                                    }
                                                  }),
                                                  _vm._v(
                                                    " 部\n                "
                                                  ),
                                                  _c("br", {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value: errors[0],
                                                        expression: "errors[0]"
                                                      }
                                                    ]
                                                  }),
                                                  _c(
                                                    "span",
                                                    { staticClass: "error" },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ])
                              : _vm._e()
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.projectInfo.paper_send_type == 2 &&
                  _vm.projectInfo.project_type >= 11 &&
                  _vm.projectInfo.project_type <= 16
                    ? _c("dl", {}, [
                        _c("dt", { staticClass: "form_box_2_3_dt2 top" }, [
                          _vm._v("質問紙の宛先②")
                        ]),
                        _vm._v(" "),
                        _c(
                          "dd",
                          { staticClass: "margin_dd" },
                          [
                            _c("span", { staticClass: "margin_l_2_3_3" }, [
                              _vm._v(
                                "※納品先と質問紙の納付先が異なる場合は、入力し直してください。"
                              )
                            ]),
                            _vm._v(" "),
                            _c("ValidationProvider", {
                              staticClass: "validate",
                              attrs: {
                                name: "paper2_division",
                                rules: "required"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("p", [
                                          _vm._v("部署"),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.projectInfo
                                                    .paper2_division,
                                                expression:
                                                  "projectInfo.paper2_division"
                                              }
                                            ],
                                            class: {
                                              "form_text tel_number": true,
                                              error: errors[0]
                                            },
                                            attrs: {
                                              type: "text",
                                              name: "paper2_division",
                                              placeholder: "○○○部"
                                            },
                                            domProps: {
                                              value:
                                                _vm.projectInfo.paper2_division
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.projectInfo,
                                                  "paper2_division",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        ])
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _vm._v(" "),
                            _c("ValidationProvider", {
                              staticClass: "validate",
                              attrs: { name: "paper2_name", rules: "required" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("p", [
                                          _vm._v("氏名"),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.projectInfo.paper2_name,
                                                expression:
                                                  "projectInfo.paper2_name"
                                              }
                                            ],
                                            class: {
                                              "form_text tel_number": true,
                                              error: errors[0]
                                            },
                                            attrs: {
                                              type: "text",
                                              name: "paper2_name",
                                              placeholder: "田中一郎"
                                            },
                                            domProps: {
                                              value: _vm.projectInfo.paper2_name
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.projectInfo,
                                                  "paper2_name",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        ])
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _vm._v(" "),
                            _c("ValidationProvider", {
                              staticClass: "validate",
                              attrs: { name: "paper2_position", rules: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("p", [
                                          _vm._v("役職"),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.projectInfo
                                                    .paper2_position,
                                                expression:
                                                  "projectInfo.paper2_position"
                                              }
                                            ],
                                            class: {
                                              "form_text tel_number": true,
                                              error: errors[0]
                                            },
                                            attrs: {
                                              type: "text",
                                              name: "paper2_position",
                                              placeholder: "部長"
                                            },
                                            domProps: {
                                              value:
                                                _vm.projectInfo.paper2_position
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.projectInfo,
                                                  "paper2_position",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        ])
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _vm._v(" "),
                            _c(
                              "p",
                              [
                                _c("ValidationProvider", {
                                  staticClass: "validate",
                                  attrs: {
                                    name: "paper2_zip_code",
                                    rules: "required"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _vm._v("\n              〒"),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.projectInfo
                                                      .paper2_zip_code,
                                                  expression:
                                                    "projectInfo.paper2_zip_code"
                                                }
                                              ],
                                              class: {
                                                "form_text form_address": true,
                                                error: errors[0]
                                              },
                                              attrs: {
                                                name: "paper2_zip_code",
                                                placeholder: "例：1000005",
                                                type: "tel",
                                                pattern: "[0-9]+",
                                                maxlength: "11",
                                                onKeyUp:
                                                  "$(this).val($(this).val().replace(/[^\\d]/ig, ''))",
                                                autocomplete: "none"
                                              },
                                              domProps: {
                                                value:
                                                  _vm.projectInfo
                                                    .paper2_zip_code
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.projectInfo,
                                                    "paper2_zip_code",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("input", {
                                              staticClass: "input_button",
                                              attrs: {
                                                type: "button",
                                                value: "住所検索"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.searchAddressPaper2()
                                                }
                                              }
                                            }),
                                            _c("span", {}),
                                            _c("br"),
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(
                                                "※ハイフンを入れずに入力してください。"
                                              )
                                            ])
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.searchAddErr2
                              ? _c(
                                  "div",
                                  { staticStyle: { color: "#DB484A" } },
                                  [
                                    _c("span", { staticClass: "error" }, [
                                      _vm._v(_vm._s(_vm.searchAddErr2))
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "p",
                              {},
                              [
                                _c("ValidationProvider", {
                                  staticClass: "validate",
                                  attrs: {
                                    name: "paper2_address1",
                                    rules: "required"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _vm._v(
                                              "\n              都道府県\n              "
                                            ),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.projectInfo
                                                      .paper2_address1,
                                                  expression:
                                                    "projectInfo.paper2_address1"
                                                }
                                              ],
                                              class: {
                                                "form_text form_address_1": true,
                                                error: errors[0]
                                              },
                                              attrs: {
                                                maxlength: "10",
                                                placeholder: "例：東京都",
                                                type: "text"
                                              },
                                              domProps: {
                                                value:
                                                  _vm.projectInfo
                                                    .paper2_address1
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.projectInfo,
                                                    "paper2_address1",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("br", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: errors[0],
                                                  expression: "errors[0]"
                                                }
                                              ]
                                            }),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: errors[0],
                                                    expression: "errors[0]"
                                                  }
                                                ],
                                                staticClass: "error left-150"
                                              },
                                              [_vm._v(_vm._s(errors[0]))]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "p",
                              {},
                              [
                                _c("ValidationProvider", {
                                  staticClass: "validate",
                                  attrs: {
                                    name: "paper2_address2",
                                    rules: "required",
                                    maxlength: "50"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _vm._v(
                                              "\n              市区町村番地\n              "
                                            ),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.projectInfo
                                                      .paper2_address2,
                                                  expression:
                                                    "projectInfo.paper2_address2"
                                                }
                                              ],
                                              class: {
                                                "form_text form_address_2": true,
                                                error: errors[0]
                                              },
                                              attrs: {
                                                placeholder:
                                                  "例：千代田区丸の内　1-7-12",
                                                type: "text"
                                              },
                                              domProps: {
                                                value:
                                                  _vm.projectInfo
                                                    .paper2_address2
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.projectInfo,
                                                    "paper2_address2",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("br", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: errors[0],
                                                  expression: "errors[0]"
                                                }
                                              ]
                                            }),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: errors[0],
                                                    expression: "errors[0]"
                                                  }
                                                ],
                                                staticClass: "error left-150"
                                              },
                                              [_vm._v(_vm._s(errors[0]))]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("p", {}, [
                              _c("span", { staticClass: "validate" }, [
                                _vm._v(
                                  "\n              ビル名\n              "
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.projectInfo.paper2_address3,
                                      expression: "projectInfo.paper2_address3"
                                    }
                                  ],
                                  staticClass: "form_text form_address_3",
                                  attrs: {
                                    placeholder: "例：サピアタワー　18F",
                                    maxlength: "50",
                                    type: "text"
                                  },
                                  domProps: {
                                    value: _vm.projectInfo.paper2_address3
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.projectInfo,
                                        "paper2_address3",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ])
                            ]),
                            _vm._v(" "),
                            _vm.projectInfo.project_type >= 11 &&
                            _vm.projectInfo.project_type <= 16
                              ? _c("p", [
                                  _vm._v("部数\n            "),
                                  _c(
                                    "strong",
                                    [
                                      _c("ValidationProvider", {
                                        staticClass: "validate",
                                        attrs: {
                                          name: "paper1_copies",
                                          rules: "numeric|max_value:10000"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.projectInfo
                                                            .paper2_copies,
                                                        expression:
                                                          "projectInfo.paper2_copies"
                                                      }
                                                    ],
                                                    class: {
                                                      "form_text form_text_2_3_2": true,
                                                      error: errors[0]
                                                    },
                                                    attrs: {
                                                      name: "paper2_copies",
                                                      max: "10000",
                                                      placeholder: "",
                                                      type: "number",
                                                      onKeyUp:
                                                        "$(this).val($(this).val().replace(/[^\\d]/ig, ''))",
                                                      autocomplete: "none"
                                                    },
                                                    domProps: {
                                                      value:
                                                        _vm.projectInfo
                                                          .paper2_copies
                                                    },
                                                    on: {
                                                      keypress: function(
                                                        $event
                                                      ) {
                                                        return _vm.preventAlphabetInput(
                                                          $event
                                                        )
                                                      },
                                                      input: function($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          _vm.projectInfo,
                                                          "paper2_copies",
                                                          $event.target.value
                                                        )
                                                      }
                                                    }
                                                  }),
                                                  _vm._v(
                                                    " 部\n                "
                                                  ),
                                                  _c("br", {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value: errors[0],
                                                        expression: "errors[0]"
                                                      }
                                                    ]
                                                  }),
                                                  _c(
                                                    "span",
                                                    { staticClass: "error" },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ])
                              : _vm._e()
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { attrs: { id: "web" } }, [
                    _c("dl", { staticClass: "fx" }, [
                      _c("dt", { staticClass: "height_up_2_3_4 top" }, [
                        _vm._v("回答スケジュール\n          ")
                      ]),
                      _vm._v(" "),
                      _c(
                        "dd",
                        { staticClass: "margin_dd position_r_2_3 no-padding" },
                        [
                          _c("div", { staticClass: "form_box_2_4" }, [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "form_box_2_4_p form_box_2_3_p margin_t5"
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    "対象者リスト提出締切日　" +
                                      _vm._s(_vm.deadlineSubmit)
                                  )
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "margin_top_2_3" }, [
                              _vm.projectInfo.medium_type == 1
                                ? _c(
                                    "div",
                                    [
                                      _c("ValidationProvider", {
                                        staticClass:
                                          "validate margin_b_2_4 margin_b_2_3 fx_ce",
                                        attrs: {
                                          name: "web_start1",
                                          rules: "required"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("p", [
                                                    _vm._v("Web回答開始日時")
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "input-group stick_2_3 form_sch2_3_4 width-datepicker"
                                                    },
                                                    [
                                                      _c("date-picker", {
                                                        class: {
                                                          "form_text form_sch": true,
                                                          error: errors[0]
                                                        },
                                                        attrs: {
                                                          disabled: !_vm
                                                            .projectInfo
                                                            .web_people,
                                                          name:
                                                            "projectInfo.web_start1",
                                                          wrap: true,
                                                          config:
                                                            _vm.configs
                                                              .web_start
                                                        },
                                                        on: {
                                                          "dp-change":
                                                            _vm.onStartChange
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.projectInfo
                                                              .web_start,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.projectInfo,
                                                              "web_start",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "projectInfo.web_start"
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "input-group-append cal_icon"
                                                        },
                                                        [
                                                          _c("button", {
                                                            staticClass:
                                                              "btn datepickerbutton",
                                                            attrs: {
                                                              type: "button"
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      }),
                                      _vm._v(" "),
                                      _c("ValidationProvider", {
                                        staticClass:
                                          "validate margin_b_2_4 margin_b_2_3 fx_ce",
                                        attrs: {
                                          name: "web_end1",
                                          rules: "required"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("p", [
                                                    _vm._v("Web回答終了日時")
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "input-group form_sch2_3_4 width-datepicker"
                                                    },
                                                    [
                                                      _c("date-picker", {
                                                        class: {
                                                          "form_text form_sch": true,
                                                          error: errors[0]
                                                        },
                                                        attrs: {
                                                          disabled: !_vm
                                                            .projectInfo
                                                            .web_start,
                                                          name:
                                                            "projectInfo.web_end1",
                                                          wrap: true,
                                                          config:
                                                            _vm.configs.web_end
                                                        },
                                                        on: {
                                                          "dp-change":
                                                            _vm.onEndChange,
                                                          "dp-update":
                                                            _vm.onNextChange,
                                                          "dp-show":
                                                            _vm.onShowChange
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.projectInfo
                                                              .web_end,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.projectInfo,
                                                              "web_end",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "projectInfo.web_end"
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "input-group-append cal_icon"
                                                        },
                                                        [
                                                          _c("button", {
                                                            staticClass:
                                                              "btn datepickerbutton",
                                                            attrs: {
                                                              type: "button"
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.projectInfo.medium_type == 2
                                ? _c(
                                    "div",
                                    [
                                      _c("ValidationProvider", {
                                        staticClass:
                                          "validate margin_b_2_4 margin_b_2_3 fx_ce",
                                        attrs: {
                                          name: "paper_preferred_date2",
                                          rules: "required"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("p", [
                                                    _vm._v(
                                                      "質問紙到着\n                      "
                                                    ),
                                                    _c("br"),
                                                    _vm._v("希望日")
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "input-group margin_l_2_5 stick_2_3_4 width-datepicker"
                                                    },
                                                    [
                                                      _c("date-picker", {
                                                        class: {
                                                          "form_text form_sch 2": true,
                                                          error: errors[0]
                                                        },
                                                        attrs: {
                                                          disabled: !_vm
                                                            .projectInfo
                                                            .paper_people,
                                                          name:
                                                            "projectInfo.paper_preferred_date2",
                                                          wrap: true,
                                                          config:
                                                            _vm.configs
                                                              .paper_preferred_date
                                                        },
                                                        on: {
                                                          "dp-change":
                                                            _vm.onPaperPrefererChange,
                                                          "dp-update":
                                                            _vm.onNextChange,
                                                          "dp-show":
                                                            _vm.onShowChange
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.projectInfo
                                                              .paper_preferred_date,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.projectInfo,
                                                              "paper_preferred_date",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "projectInfo.paper_preferred_date"
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "input-group-append cal_icon"
                                                        },
                                                        [
                                                          _c("button", {
                                                            staticClass:
                                                              "btn datepickerbutton",
                                                            attrs: {
                                                              type: "button"
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      }),
                                      _vm._v(" "),
                                      _c("ValidationProvider", {
                                        staticClass:
                                          "validate margin_b_2_4 margin_b_2_3 fx_ce",
                                        attrs: {
                                          name: "paper_limit_date2",
                                          rules: ""
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("p", [
                                                    _vm._v(
                                                      "質問紙提出期限\n                      "
                                                    ),
                                                    _c("br"),
                                                    _vm._v("(任意)")
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "input-group margin_l_2_7 stick_2_3_4 width-datepicker"
                                                    },
                                                    [
                                                      _c("date-picker", {
                                                        class: {
                                                          "form_text form_sch 2": true
                                                        },
                                                        attrs: {
                                                          disabled: !_vm
                                                            .projectInfo
                                                            .paper_preferred_date,
                                                          name:
                                                            "projectInfo.paper_limit_date2",
                                                          wrap: true,
                                                          config:
                                                            _vm.configs
                                                              .paper_limit_date
                                                        },
                                                        on: {
                                                          "dp-change":
                                                            _vm.onPaperLimitChange,
                                                          "dp-update":
                                                            _vm.onNextChange,
                                                          "dp-show":
                                                            _vm.onShowChange
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.projectInfo
                                                              .paper_limit_date,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.projectInfo,
                                                              "paper_limit_date",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "projectInfo.paper_limit_date"
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "input-group-append cal_icon"
                                                        },
                                                        [
                                                          _c("button", {
                                                            staticClass:
                                                              "btn datepickerbutton",
                                                            attrs: {
                                                              type: "button"
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass: "no-margin"
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticClass: "info_2",
                                                        attrs: {
                                                          src:
                                                            "/images/info.png",
                                                          srcset:
                                                            "/images/info.png" +
                                                            " 1x, " +
                                                            "/images/info@2x.png" +
                                                            " 2x",
                                                          alt:
                                                            "インフォメーション"
                                                        },
                                                        on: {
                                                          mouseover:
                                                            _vm.mouseOver,
                                                          mouseleave:
                                                            _vm.mouseLeave
                                                        }
                                                      })
                                                    ]
                                                  ),
                                                  _c(
                                                    "p",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value: _vm.fukidashi2,
                                                          expression:
                                                            "fukidashi2"
                                                        }
                                                      ],
                                                      staticClass:
                                                        "info_fukidashi_2"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "質問紙に職場での回収期限を"
                                                      ),
                                                      _c("br"),
                                                      _vm._v(
                                                        "記載したい場合に入力してください。\n                    "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      }),
                                      _vm._v(" "),
                                      _c("ValidationProvider", {
                                        staticClass:
                                          "validate margin_b_2_4 margin_b_2_3 fx_ce",
                                        attrs: {
                                          name: "paper_bcon_receipt_date2",
                                          rules: "required"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("p", [
                                                    _vm._v(
                                                      "質問紙BCon\n                      "
                                                    ),
                                                    _c("br"),
                                                    _vm._v("到着日")
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "input-group margin_l_2_6 width-datepicker"
                                                    },
                                                    [
                                                      _c("date-picker", {
                                                        class: {
                                                          "form_text form_sch 2": true,
                                                          error: errors[0]
                                                        },
                                                        attrs: {
                                                          disabled: !_vm
                                                            .projectInfo
                                                            .paper_preferred_date,
                                                          name:
                                                            "projectInfo.paper_bcon_receipt_date2",
                                                          wrap: true,
                                                          config:
                                                            _vm.configs
                                                              .paper_bcon_receipt_date
                                                        },
                                                        on: {
                                                          "dp-change":
                                                            _vm.onPaperBconChange,
                                                          "dp-update":
                                                            _vm.onNextChange,
                                                          "dp-show":
                                                            _vm.onShowChange
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.projectInfo
                                                              .paper_bcon_receipt_date,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.projectInfo,
                                                              "paper_bcon_receipt_date",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "projectInfo.paper_bcon_receipt_date"
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "input-group-append cal_icon"
                                                        },
                                                        [
                                                          _c("button", {
                                                            staticClass:
                                                              "btn datepickerbutton",
                                                            attrs: {
                                                              type: "button"
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.projectInfo.medium_type == 3
                                ? _c(
                                    "div",
                                    [
                                      _c("ValidationProvider", {
                                        staticClass:
                                          "validate margin_b_2_4 margin_b_2_3 fx_ce",
                                        attrs: {
                                          name: "web_start3",
                                          rules: "required"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("p", [
                                                    _vm._v("Web回答 "),
                                                    _c("br"),
                                                    _vm._v("開始日時")
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "input-group form_sch2_3_4 stick_2_3_4 width-datepicker"
                                                    },
                                                    [
                                                      _c("date-picker", {
                                                        class: {
                                                          "form_text form_sch": true,
                                                          error: errors[0]
                                                        },
                                                        attrs: {
                                                          disabled:
                                                            !_vm.projectInfo
                                                              .web_people ||
                                                            !_vm.projectInfo
                                                              .paper_people,
                                                          name:
                                                            "projectInfo.web_start3",
                                                          wrap: true,
                                                          config:
                                                            _vm.configs
                                                              .web_start
                                                        },
                                                        on: {
                                                          "dp-change":
                                                            _vm.onStartChange,
                                                          "dp-update":
                                                            _vm.onNextChange,
                                                          "dp-show":
                                                            _vm.onShowChange
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.projectInfo
                                                              .web_start,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.projectInfo,
                                                              "web_start",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "projectInfo.web_start"
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "input-group-append cal_icon"
                                                        },
                                                        [
                                                          _c("button", {
                                                            staticClass:
                                                              "btn datepickerbutton",
                                                            attrs: {
                                                              type: "button"
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      }),
                                      _vm._v(" "),
                                      _c("ValidationProvider", {
                                        staticClass:
                                          "validate margin_b_2_4 margin_b_2_3 fx_ce",
                                        attrs: {
                                          name: "web_end3",
                                          rules: "required"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("p", [
                                                    _vm._v("Web回答 "),
                                                    _c("br"),
                                                    _vm._v("終了日時")
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "input-group form_sch2_3_4 width-datepicker"
                                                    },
                                                    [
                                                      _c("date-picker", {
                                                        class: {
                                                          "form_text form_sch": true,
                                                          error: errors[0]
                                                        },
                                                        attrs: {
                                                          disabled: !_vm
                                                            .projectInfo
                                                            .web_start,
                                                          name:
                                                            "projectInfo.web_end3",
                                                          wrap: true,
                                                          config:
                                                            _vm.configs.web_end
                                                        },
                                                        on: {
                                                          "dp-change":
                                                            _vm.onEndChange,
                                                          "dp-update":
                                                            _vm.onNextChange,
                                                          "dp-show":
                                                            _vm.onShowChange
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.projectInfo
                                                              .web_end,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.projectInfo,
                                                              "web_end",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "projectInfo.web_end"
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "input-group-append cal_icon"
                                                        },
                                                        [
                                                          _c("button", {
                                                            staticClass:
                                                              "btn datepickerbutton",
                                                            attrs: {
                                                              type: "button"
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      }),
                                      _vm._v(" "),
                                      _c("hr"),
                                      _vm._v(" "),
                                      _c("ValidationProvider", {
                                        staticClass:
                                          "validate margin_b_2_4 margin_b_2_3 fx_ce",
                                        attrs: {
                                          name: "paper_preferred_date3",
                                          rules: "required"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("p", [
                                                    _vm._v(
                                                      "質問紙到着\n                      "
                                                    ),
                                                    _c("br"),
                                                    _vm._v("希望日")
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "input-group margin_l_2_5 stick_2_3_4 width-datepicker"
                                                    },
                                                    [
                                                      _c("date-picker", {
                                                        class: {
                                                          "form_text form_sch": true,
                                                          error: errors[0]
                                                        },
                                                        attrs: {
                                                          disabled:
                                                            !_vm.projectInfo
                                                              .web_people ||
                                                            !_vm.projectInfo
                                                              .paper_people,
                                                          name:
                                                            "projectInfo.paper_preferred_date3",
                                                          wrap: true,
                                                          config:
                                                            _vm.configs
                                                              .paper_preferred_date
                                                        },
                                                        on: {
                                                          "dp-change":
                                                            _vm.onPaperPrefererChange,
                                                          "dp-update":
                                                            _vm.onNextChange,
                                                          "dp-show":
                                                            _vm.onShowChange
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.projectInfo
                                                              .paper_preferred_date,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.projectInfo,
                                                              "paper_preferred_date",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "projectInfo.paper_preferred_date"
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "input-group-append cal_icon"
                                                        },
                                                        [
                                                          _c("button", {
                                                            staticClass:
                                                              "btn datepickerbutton",
                                                            attrs: {
                                                              type: "button"
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      }),
                                      _vm._v(" "),
                                      _c("ValidationProvider", {
                                        staticClass:
                                          "validate margin_b_2_4 margin_b_2_3 fx_ce",
                                        attrs: {
                                          name: "paper_limit_date3",
                                          rules: ""
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("p", [
                                                    _vm._v(
                                                      "質問紙提出期限\n                      "
                                                    ),
                                                    _c("br"),
                                                    _vm._v("(任意)")
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "input-group margin_l_2_7 stick_2_3_4 width-datepicker"
                                                    },
                                                    [
                                                      _c("date-picker", {
                                                        class: {
                                                          "form_text form_sch": true
                                                        },
                                                        attrs: {
                                                          disabled: !_vm
                                                            .projectInfo
                                                            .paper_preferred_date,
                                                          name:
                                                            "projectInfo.paper_limit_date3",
                                                          wrap: true,
                                                          config:
                                                            _vm.configs
                                                              .paper_limit_date
                                                        },
                                                        on: {
                                                          "dp-change":
                                                            _vm.onPaperLimitChange,
                                                          "dp-update":
                                                            _vm.onNextChange,
                                                          "dp-show":
                                                            _vm.onShowChange
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.projectInfo
                                                              .paper_limit_date,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.projectInfo,
                                                              "paper_limit_date",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "projectInfo.paper_limit_date"
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "input-group-append cal_icon"
                                                        },
                                                        [
                                                          _c("button", {
                                                            staticClass:
                                                              "btn datepickerbutton",
                                                            attrs: {
                                                              type: "button"
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass: "no-margin"
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticClass: "info_2",
                                                        attrs: {
                                                          src:
                                                            "/images/info.png",
                                                          srcset:
                                                            "/images/info.png" +
                                                            " 1x, " +
                                                            "/images/info@2x.png" +
                                                            " 2x",
                                                          alt:
                                                            "インフォメーション"
                                                        },
                                                        on: {
                                                          mouseover:
                                                            _vm.mouseOver,
                                                          mouseleave:
                                                            _vm.mouseLeave
                                                        }
                                                      })
                                                    ]
                                                  ),
                                                  _c(
                                                    "p",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value: _vm.fukidashi2,
                                                          expression:
                                                            "fukidashi2"
                                                        }
                                                      ],
                                                      staticClass:
                                                        "info_fukidashi_3"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "質問紙に職場での回収期限を"
                                                      ),
                                                      _c("br"),
                                                      _vm._v(
                                                        "記載したい場合に入力してください。\n                    "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      }),
                                      _vm._v(" "),
                                      _c("ValidationProvider", {
                                        staticClass:
                                          "validate margin_b_2_4 margin_b_2_3 fx_ce position_r_2_3",
                                        attrs: {
                                          name: "paper_bcon_receipt_date3",
                                          rules: "required"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("p", [
                                                    _vm._v(
                                                      "質問紙BCon\n                      "
                                                    ),
                                                    _c("br"),
                                                    _vm._v("到着日")
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "input-group margin_l_2_6 width-datepicker"
                                                    },
                                                    [
                                                      _c("date-picker", {
                                                        class: {
                                                          "form_text form_sch": true,
                                                          error: errors[0]
                                                        },
                                                        attrs: {
                                                          disabled: !_vm
                                                            .projectInfo
                                                            .paper_preferred_date,
                                                          name:
                                                            "projectInfo.paper_bcon_receipt_date3",
                                                          wrap: true,
                                                          config:
                                                            _vm.configs
                                                              .paper_bcon_receipt_date
                                                        },
                                                        on: {
                                                          "dp-change":
                                                            _vm.onPaperBconChange,
                                                          "dp-update":
                                                            _vm.onNextChange,
                                                          "dp-show":
                                                            _vm.onShowChange
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.projectInfo
                                                              .paper_bcon_receipt_date,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.projectInfo,
                                                              "paper_bcon_receipt_date",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "projectInfo.paper_bcon_receipt_date"
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "input-group-append cal_icon"
                                                        },
                                                        [
                                                          _c("button", {
                                                            staticClass:
                                                              "btn datepickerbutton",
                                                            attrs: {
                                                              type: "button"
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.projectInfo.medium_type == 3
                                ? _c("p", { staticClass: "position_ant_2_3" }, [
                                    _c("span", [
                                      _vm._v(
                                        "※到着が遅れた場合、報告書納品が遅れる場合がございます。"
                                      )
                                    ])
                                  ])
                                : _vm._e()
                            ])
                          ])
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("dl", { staticClass: "fx" }, [
                      _c("dt", { staticClass: " top" }, [
                        _vm._v("報告書納品希望日")
                      ]),
                      _vm._v(" "),
                      _c("dd", { staticClass: "margin_dd" }, [
                        _c("span", { staticClass: "f12" }, [
                          _vm._v(
                            "回答スケジュールを起点に報告書納品日が算出されます"
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "fx_ce2" }, [
                          _c(
                            "div",
                            [
                              _c("ValidationProvider", {
                                staticClass: "validate fx_ce",
                                attrs: {
                                  name: "report_preferred_date",
                                  rules: "required"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var errors = ref.errors
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "input-group margin_b_2_4_2 form_text_2_3 width-datepicker"
                                            },
                                            [
                                              _c("date-picker", {
                                                class: {
                                                  "form_text form_sch": true,
                                                  error: errors[0]
                                                },
                                                attrs: {
                                                  disabled:
                                                    !_vm.projectInfo.web_end &&
                                                    !_vm.projectInfo
                                                      .paper_bcon_receipt_date,
                                                  name:
                                                    "projectInfo.report_preferred_date",
                                                  wrap: true,
                                                  config:
                                                    _vm.configs
                                                      .report_preferred_date
                                                },
                                                on: {
                                                  "dp-update": _vm.onNextChange,
                                                  "dp-show": _vm.onShowChange
                                                },
                                                model: {
                                                  value:
                                                    _vm.projectInfo
                                                      .report_preferred_date,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.projectInfo,
                                                      "report_preferred_date",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "projectInfo.report_preferred_date"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "input-group-append cal_icon"
                                                },
                                                [
                                                  _c("button", {
                                                    staticClass:
                                                      "btn datepickerbutton",
                                                    attrs: { type: "button" }
                                                  })
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            ],
                            1
                          )
                        ])
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("dl", { staticClass: "fx" }, [
                    _c("dt", { staticClass: " top" }, [
                      _vm._v("該当する業界・組織規模")
                    ]),
                    _vm._v(" "),
                    _c(
                      "dd",
                      { staticClass: "margin_dd" },
                      [
                        _c("p", [_vm._v("業種")]),
                        _vm._v(" "),
                        _c("ValidationProvider", {
                          staticClass: "validate fx_between",
                          attrs: { name: "industry_type", rules: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c(
                                      "ul",
                                      { staticClass: "industry" },
                                      _vm._l(
                                        _vm.sliced(0, 12, _vm.industry_types),
                                        function(industry_type, index) {
                                          return _c("li", [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.projectInfo
                                                      .industry_type,
                                                  expression:
                                                    "projectInfo.industry_type"
                                                }
                                              ],
                                              class: {
                                                form_radio: true,
                                                error: errors[0]
                                              },
                                              attrs: {
                                                id: "industry_type" + index,
                                                name: "industry_type",
                                                type: "radio"
                                              },
                                              domProps: {
                                                value: index,
                                                checked: _vm._q(
                                                  _vm.projectInfo.industry_type,
                                                  index
                                                )
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.$set(
                                                    _vm.projectInfo,
                                                    "industry_type",
                                                    index
                                                  )
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "label",
                                              {
                                                attrs: {
                                                  for: "industry_type" + index
                                                }
                                              },
                                              [_vm._v(_vm._s(industry_type))]
                                            )
                                          ])
                                        }
                                      ),
                                      0
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "ul",
                                      { staticClass: "industry" },
                                      _vm._l(
                                        _vm.sliced(12, 24, _vm.industry_types),
                                        function(industry_type, index) {
                                          return _c("li", [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.projectInfo
                                                      .industry_type,
                                                  expression:
                                                    "projectInfo.industry_type"
                                                }
                                              ],
                                              class: {
                                                form_radio: true,
                                                error: errors[0]
                                              },
                                              attrs: {
                                                id: "industry_type" + index,
                                                name: "industry_type",
                                                type: "radio"
                                              },
                                              domProps: {
                                                value: index,
                                                checked: _vm._q(
                                                  _vm.projectInfo.industry_type,
                                                  index
                                                )
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.$set(
                                                    _vm.projectInfo,
                                                    "industry_type",
                                                    index
                                                  )
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "label",
                                              {
                                                attrs: {
                                                  for: "industry_type" + index
                                                }
                                              },
                                              [_vm._v(_vm._s(industry_type))]
                                            )
                                          ])
                                        }
                                      ),
                                      0
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "ul",
                                      { staticClass: "industry" },
                                      _vm._l(
                                        _vm.sliced(
                                          24,
                                          _vm.industry_types.length,
                                          _vm.industry_types
                                        ),
                                        function(industry_type, index) {
                                          return _c("li", [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.projectInfo
                                                      .industry_type,
                                                  expression:
                                                    "projectInfo.industry_type"
                                                }
                                              ],
                                              class: {
                                                form_radio: true,
                                                error: errors[0]
                                              },
                                              attrs: {
                                                id: "industry_type" + index,
                                                name: "industry_type",
                                                type: "radio"
                                              },
                                              domProps: {
                                                value: index,
                                                checked: _vm._q(
                                                  _vm.projectInfo.industry_type,
                                                  index
                                                )
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.$set(
                                                    _vm.projectInfo,
                                                    "industry_type",
                                                    index
                                                  )
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "label",
                                              {
                                                attrs: {
                                                  for: "industry_type" + index
                                                }
                                              },
                                              [_vm._v(_vm._s(industry_type))]
                                            )
                                          ])
                                        }
                                      ),
                                      0
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "scale" },
                          [
                            _c("ValidationProvider", {
                              staticClass: "validate",
                              attrs: { name: "scale_type", rules: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("p", { staticClass: "margin_dd" }, [
                                          _vm._v("規模")
                                        ]),
                                        _vm._v(" "),
                                        _vm._l(
                                          Math.ceil(
                                            Object.keys(_vm.scale_types)
                                              .length / 4
                                          ),
                                          function(i) {
                                            return _c(
                                              "div",
                                              { staticClass: "scale_1" },
                                              _vm._l(
                                                _vm.sliced(
                                                  (i - 1) * 4,
                                                  i * 4 <
                                                    Object.keys(_vm.scale_types)
                                                      .length
                                                    ? i * 4
                                                    : Object.keys(
                                                        _vm.scale_types
                                                      ).length,
                                                  _vm.scale_types
                                                ),
                                                function(scale_type, index) {
                                                  return _c("p", [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.projectInfo
                                                              .scale_type,
                                                          expression:
                                                            "projectInfo.scale_type"
                                                        }
                                                      ],
                                                      class: {
                                                        form_radio: true,
                                                        error: errors[0]
                                                      },
                                                      attrs: {
                                                        id:
                                                          "scale_type" + index,
                                                        name: "scale_type",
                                                        type: "radio"
                                                      },
                                                      domProps: {
                                                        value: index,
                                                        checked: _vm._q(
                                                          _vm.projectInfo
                                                            .scale_type,
                                                          index
                                                        )
                                                      },
                                                      on: {
                                                        change: function(
                                                          $event
                                                        ) {
                                                          return _vm.$set(
                                                            _vm.projectInfo,
                                                            "scale_type",
                                                            index
                                                          )
                                                        }
                                                      }
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "label",
                                                      {
                                                        attrs: {
                                                          for:
                                                            "scale_type" + index
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(scale_type)
                                                        )
                                                      ]
                                                    )
                                                  ])
                                                }
                                              ),
                                              0
                                            )
                                          }
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _vm.projectInfo.project_type == 4 ||
                  _vm.projectInfo.project_type == 24
                    ? _c("dl", { staticClass: "fx" }, [
                        _c("dt", { staticClass: "top" }, [_vm._v("対象")]),
                        _vm._v(" "),
                        _c("dd", {}, [
                          _c(
                            "div",
                            {
                              staticClass: "scale scale2 no-margin",
                              staticStyle: {
                                "border-top": "0",
                                "padding-left": "25px"
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "row" },
                                [
                                  _c("ValidationProvider", {
                                    staticClass: "validate col-3 no-space",
                                    attrs: { name: "is_personnal_target1" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var errors = ref.errors
                                            return [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.projectInfo
                                                        .is_personnal_target1,
                                                    expression:
                                                      "projectInfo.is_personnal_target1"
                                                  }
                                                ],
                                                class: { error: errors[0] },
                                                attrs: {
                                                  id: "is_personnal_target1",
                                                  type: "checkbox",
                                                  name: "is_personnal_target1"
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm.projectInfo
                                                      .is_personnal_target1
                                                  )
                                                    ? _vm._i(
                                                        _vm.projectInfo
                                                          .is_personnal_target1,
                                                        null
                                                      ) > -1
                                                    : _vm.projectInfo
                                                        .is_personnal_target1
                                                },
                                                on: {
                                                  change: [
                                                    function($event) {
                                                      var $$a =
                                                          _vm.projectInfo
                                                            .is_personnal_target1,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm.projectInfo,
                                                              "is_personnal_target1",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm.projectInfo,
                                                              "is_personnal_target1",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm.projectInfo,
                                                          "is_personnal_target1",
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                    function($event) {
                                                      return _vm.uncheckX()
                                                    }
                                                  ]
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "label",
                                                {
                                                  class: {
                                                    error: errors[0],
                                                    check_2_4: true
                                                  },
                                                  attrs: {
                                                    for: "is_personnal_target1"
                                                  }
                                                },
                                                [_vm._v("内定者")]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _vm._v(" "),
                                  _c("ValidationProvider", {
                                    staticClass: "validate col-3 no-space",
                                    attrs: { name: "is_personnal_target2" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var errors = ref.errors
                                            return [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.projectInfo
                                                        .is_personnal_target2,
                                                    expression:
                                                      "projectInfo.is_personnal_target2"
                                                  }
                                                ],
                                                class: { error: errors[0] },
                                                attrs: {
                                                  id: "is_personnal_target2",
                                                  type: "checkbox",
                                                  value: "true",
                                                  name: "is_personnal_target2"
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm.projectInfo
                                                      .is_personnal_target2
                                                  )
                                                    ? _vm._i(
                                                        _vm.projectInfo
                                                          .is_personnal_target2,
                                                        "true"
                                                      ) > -1
                                                    : _vm.projectInfo
                                                        .is_personnal_target2
                                                },
                                                on: {
                                                  change: [
                                                    function($event) {
                                                      var $$a =
                                                          _vm.projectInfo
                                                            .is_personnal_target2,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = "true",
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm.projectInfo,
                                                              "is_personnal_target2",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm.projectInfo,
                                                              "is_personnal_target2",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm.projectInfo,
                                                          "is_personnal_target2",
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                    function($event) {
                                                      return _vm.uncheckX()
                                                    }
                                                  ]
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "label",
                                                {
                                                  class: {
                                                    error: errors[0],
                                                    check_2_4: true
                                                  },
                                                  attrs: {
                                                    for: "is_personnal_target2"
                                                  }
                                                },
                                                [_vm._v("半年-5年未満")]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _vm._v(" "),
                                  _c("ValidationProvider", {
                                    staticClass: "validate col-3 no-space",
                                    attrs: { name: "is_personnal_target3" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var errors = ref.errors
                                            return [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.projectInfo
                                                        .is_personnal_target3,
                                                    expression:
                                                      "projectInfo.is_personnal_target3"
                                                  }
                                                ],
                                                class: { error: errors[0] },
                                                attrs: {
                                                  id: "is_personnal_target3",
                                                  type: "checkbox",
                                                  name: "is_personnal_target3"
                                                },
                                                domProps: {
                                                  value: 1,
                                                  checked: Array.isArray(
                                                    _vm.projectInfo
                                                      .is_personnal_target3
                                                  )
                                                    ? _vm._i(
                                                        _vm.projectInfo
                                                          .is_personnal_target3,
                                                        1
                                                      ) > -1
                                                    : _vm.projectInfo
                                                        .is_personnal_target3
                                                },
                                                on: {
                                                  change: [
                                                    function($event) {
                                                      var $$a =
                                                          _vm.projectInfo
                                                            .is_personnal_target3,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = 1,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm.projectInfo,
                                                              "is_personnal_target3",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm.projectInfo,
                                                              "is_personnal_target3",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm.projectInfo,
                                                          "is_personnal_target3",
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                    function($event) {
                                                      return _vm.uncheckX()
                                                    }
                                                  ]
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "label",
                                                {
                                                  class: {
                                                    error: errors[0],
                                                    check_2_4: true
                                                  },
                                                  attrs: {
                                                    for: "is_personnal_target3"
                                                  }
                                                },
                                                [_vm._v("監督職(主任・係長)")]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _vm._v(" "),
                                  _c("ValidationProvider", {
                                    staticClass: "validate col-3 no-space",
                                    attrs: { name: "is_personnal_target4" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var errors = ref.errors
                                            return [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.projectInfo
                                                        .is_personnal_target4,
                                                    expression:
                                                      "projectInfo.is_personnal_target4"
                                                  }
                                                ],
                                                class: { error: errors[0] },
                                                attrs: {
                                                  id: "is_personnal_target4",
                                                  type: "checkbox",
                                                  name: "is_personnal_target4"
                                                },
                                                domProps: {
                                                  value: 1,
                                                  checked: Array.isArray(
                                                    _vm.projectInfo
                                                      .is_personnal_target4
                                                  )
                                                    ? _vm._i(
                                                        _vm.projectInfo
                                                          .is_personnal_target4,
                                                        1
                                                      ) > -1
                                                    : _vm.projectInfo
                                                        .is_personnal_target4
                                                },
                                                on: {
                                                  change: [
                                                    function($event) {
                                                      var $$a =
                                                          _vm.projectInfo
                                                            .is_personnal_target4,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = 1,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm.projectInfo,
                                                              "is_personnal_target4",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm.projectInfo,
                                                              "is_personnal_target4",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm.projectInfo,
                                                          "is_personnal_target4",
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                    function($event) {
                                                      return _vm.uncheckX()
                                                    }
                                                  ]
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "label",
                                                {
                                                  class: {
                                                    error: errors[0],
                                                    check_2_4: true
                                                  },
                                                  attrs: {
                                                    for: "is_personnal_target4"
                                                  }
                                                },
                                                [_vm._v("役員")]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _vm._v(" "),
                                  _c("ValidationProvider", {
                                    staticClass: "validate col-3 no-space",
                                    attrs: { name: "is_personnal_target5" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var errors = ref.errors
                                            return [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.projectInfo
                                                        .is_personnal_target5,
                                                    expression:
                                                      "projectInfo.is_personnal_target5"
                                                  }
                                                ],
                                                class: { error: errors[0] },
                                                attrs: {
                                                  id: "is_personnal_target5",
                                                  type: "checkbox",
                                                  name: "is_personnal_target5"
                                                },
                                                domProps: {
                                                  value: 1,
                                                  checked: Array.isArray(
                                                    _vm.projectInfo
                                                      .is_personnal_target5
                                                  )
                                                    ? _vm._i(
                                                        _vm.projectInfo
                                                          .is_personnal_target5,
                                                        1
                                                      ) > -1
                                                    : _vm.projectInfo
                                                        .is_personnal_target5
                                                },
                                                on: {
                                                  change: [
                                                    function($event) {
                                                      var $$a =
                                                          _vm.projectInfo
                                                            .is_personnal_target5,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = 1,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm.projectInfo,
                                                              "is_personnal_target5",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm.projectInfo,
                                                              "is_personnal_target5",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm.projectInfo,
                                                          "is_personnal_target5",
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                    function($event) {
                                                      return _vm.uncheckX()
                                                    }
                                                  ]
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "label",
                                                {
                                                  class: {
                                                    error: errors[0],
                                                    check_2_4: true
                                                  },
                                                  attrs: {
                                                    for: "is_personnal_target5"
                                                  }
                                                },
                                                [_vm._v("新人")]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _vm._v(" "),
                                  _c("ValidationProvider", {
                                    staticClass: "validate col-3 no-space",
                                    attrs: { name: "is_personnal_target6" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var errors = ref.errors
                                            return [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.projectInfo
                                                        .is_personnal_target6,
                                                    expression:
                                                      "projectInfo.is_personnal_target6"
                                                  }
                                                ],
                                                class: { error: errors[0] },
                                                attrs: {
                                                  id: "is_personnal_target6",
                                                  type: "checkbox",
                                                  name: "is_personnal_target6"
                                                },
                                                domProps: {
                                                  value: 1,
                                                  checked: Array.isArray(
                                                    _vm.projectInfo
                                                      .is_personnal_target6
                                                  )
                                                    ? _vm._i(
                                                        _vm.projectInfo
                                                          .is_personnal_target6,
                                                        1
                                                      ) > -1
                                                    : _vm.projectInfo
                                                        .is_personnal_target6
                                                },
                                                on: {
                                                  change: [
                                                    function($event) {
                                                      var $$a =
                                                          _vm.projectInfo
                                                            .is_personnal_target6,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = 1,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm.projectInfo,
                                                              "is_personnal_target6",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm.projectInfo,
                                                              "is_personnal_target6",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm.projectInfo,
                                                          "is_personnal_target6",
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                    function($event) {
                                                      return _vm.uncheckX()
                                                    }
                                                  ]
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "label",
                                                {
                                                  class: {
                                                    error: errors[0],
                                                    check_2_4: true
                                                  },
                                                  attrs: {
                                                    for: "is_personnal_target6"
                                                  }
                                                },
                                                [_vm._v("5年以上")]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _vm._v(" "),
                                  _c("ValidationProvider", {
                                    staticClass: "validate col-3 no-space",
                                    attrs: { name: "is_personnal_target7" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var errors = ref.errors
                                            return [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.projectInfo
                                                        .is_personnal_target7,
                                                    expression:
                                                      "projectInfo.is_personnal_target7"
                                                  }
                                                ],
                                                class: { error: errors[0] },
                                                attrs: {
                                                  id: "is_personnal_target7",
                                                  type: "checkbox",
                                                  name: "is_personnal_target7"
                                                },
                                                domProps: {
                                                  value: 1,
                                                  checked: Array.isArray(
                                                    _vm.projectInfo
                                                      .is_personnal_target7
                                                  )
                                                    ? _vm._i(
                                                        _vm.projectInfo
                                                          .is_personnal_target7,
                                                        1
                                                      ) > -1
                                                    : _vm.projectInfo
                                                        .is_personnal_target7
                                                },
                                                on: {
                                                  change: [
                                                    function($event) {
                                                      var $$a =
                                                          _vm.projectInfo
                                                            .is_personnal_target7,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = 1,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm.projectInfo,
                                                              "is_personnal_target7",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm.projectInfo,
                                                              "is_personnal_target7",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm.projectInfo,
                                                          "is_personnal_target7",
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                    function($event) {
                                                      return _vm.uncheckX()
                                                    }
                                                  ]
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "label",
                                                {
                                                  class: {
                                                    error: errors[0],
                                                    check_2_4: true
                                                  },
                                                  attrs: {
                                                    for: "is_personnal_target7"
                                                  }
                                                },
                                                [_vm._v("課長・部長")]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("dl", { staticClass: "fx_normal" }, [
                    _c("dt", { staticClass: "top" }, [_vm._v("備考欄")]),
                    _vm._v(" "),
                    _c(
                      "dd",
                      [
                        _c("ValidationProvider", {
                          staticClass: "validate",
                          attrs: { name: "client_memo", rules: "max:1000" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("textarea", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.projectInfo.client_memo,
                                          expression: "projectInfo.client_memo"
                                        }
                                      ],
                                      class: { error: errors[0] },
                                      attrs: {
                                        name: "memo",
                                        rows: "7",
                                        cols: "40",
                                        maxlength: "1000",
                                        placeholder:
                                          "診断チームに申し伝えることがあれば入力してください。"
                                      },
                                      domProps: {
                                        value: _vm.projectInfo.client_memo
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.projectInfo,
                                            "client_memo",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("br", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: errors[0],
                                          expression: "errors[0]"
                                        }
                                      ]
                                    }),
                                    _c("span", { staticClass: "error" }, [
                                      _vm._v(_vm._s(errors[0]))
                                    ])
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form_box form_box_2_3 form_box_3_1" },
                  [
                    _c("div", { staticClass: "form_box_title" }, [
                      _c("p", [_vm._v("詳細情報")])
                    ]),
                    _vm._v(" "),
                    _c("dl", [
                      _c("dt", { staticClass: "form_dt_3_1_1 height_23" }, [
                        _vm._v("組織名")
                      ]),
                      _vm._v(" "),
                      _c("dd", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.projectInfo.org_name,
                              expression: "projectInfo.org_name"
                            }
                          ],
                          staticClass: "form_text tel_number",
                          attrs: {
                            type: "text",
                            name: "company",
                            placeholder: "株式会社○○○○"
                          },
                          domProps: { value: _vm.projectInfo.org_name },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.projectInfo,
                                "org_name",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("dl", [
                      _c("dt", [_vm._v("診断名")]),
                      _vm._v(" "),
                      _c("dd", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.projectInfo.diagnosis_name,
                              expression: "projectInfo.diagnosis_name"
                            }
                          ],
                          staticClass: "form_text",
                          attrs: {
                            type: "text",
                            name: "client",
                            placeholder: "例：○○○研修　事前WEB診断"
                          },
                          domProps: { value: _vm.projectInfo.diagnosis_name },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.projectInfo,
                                "diagnosis_name",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _vm.projectInfo.project_type >= 11 &&
                    _vm.projectInfo.project_type <= 16
                      ? _c("dl", [
                          _c("dt", { staticClass: " form_dt_3_1_3" }, [
                            _vm._v("属性一覧の設定"),
                            _c("img", {
                              staticClass: "info",
                              attrs: {
                                src: "/images/info.png",
                                srcset:
                                  "/images/info.png" +
                                  " 1x, " +
                                  "/images/info@2x.png" +
                                  " 2x",
                                alt: "インフォメーション"
                              }
                            }),
                            _vm._v(" "),
                            _c("p", { staticClass: "info_fukidashi_4" }, [
                              _vm._v(
                                "属性一覧とは：回答者の役職・所属などのことです。"
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("dd", [
                            _c(
                              "p",
                              { staticClass: "margin_dd" },
                              [
                                _c("ValidationProvider", {
                                  staticClass: "validate",
                                  attrs: {
                                    name: "attribute_setting_type",
                                    rules: "required"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.projectInfo
                                                      .attribute_setting_type,
                                                  expression:
                                                    "projectInfo.attribute_setting_type"
                                                }
                                              ],
                                              class: {
                                                form_radio: true,
                                                error: errors[0]
                                              },
                                              attrs: {
                                                id: "attribute_setting_type1",
                                                type: "radio",
                                                value: "1",
                                                name: "attribute_setting_type"
                                              },
                                              domProps: {
                                                checked: _vm._q(
                                                  _vm.projectInfo
                                                    .attribute_setting_type,
                                                  "1"
                                                )
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.$set(
                                                    _vm.projectInfo,
                                                    "attribute_setting_type",
                                                    "1"
                                                  )
                                                }
                                              }
                                            }),
                                            _c(
                                              "label",
                                              {
                                                attrs: {
                                                  for: "attribute_setting_type1"
                                                }
                                              },
                                              [_vm._v("回答時に選択（推奨）")]
                                            ),
                                            _vm._v(" "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.projectInfo
                                                      .attribute_setting_type,
                                                  expression:
                                                    "projectInfo.attribute_setting_type"
                                                }
                                              ],
                                              class: {
                                                form_radio: true,
                                                error: errors[0]
                                              },
                                              attrs: {
                                                id: "attribute_setting_type2",
                                                type: "radio",
                                                value: "2",
                                                name: "attribute_setting_type"
                                              },
                                              domProps: {
                                                checked: _vm._q(
                                                  _vm.projectInfo
                                                    .attribute_setting_type,
                                                  "2"
                                                )
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.$set(
                                                    _vm.projectInfo,
                                                    "attribute_setting_type",
                                                    "2"
                                                  )
                                                }
                                              }
                                            }),
                                            _c(
                                              "label",
                                              {
                                                attrs: {
                                                  for: "attribute_setting_type2"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "対象者リスト提出時に設定"
                                                )
                                              ]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            )
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.projectInfo.project_type >= 11 &&
                    _vm.projectInfo.project_type <= 16 &&
                    _vm.projectInfo.attribute_setting_type == 2
                      ? _c("dl", { staticClass: "radio_2" }, [
                          _c("dt", { staticClass: "form_dt_3_1_4" }, [
                            _vm._v("属性一覧(Excel)")
                          ]),
                          _vm._v(" "),
                          _c(
                            "dd",
                            { staticClass: "form_box_5_2" },
                            [
                              parseFloat(_vm.projectInfo.progress_status) >=
                                2 && _vm.projectInfo.p_attribute_info_file_name
                                ? _c(
                                    "router-link",
                                    {
                                      staticClass:
                                        "form_box_sub_2 form_box_sub_5_2",
                                      attrs: {
                                        to: {
                                          name: "FileComponent",
                                          params: { id: _vm.projectInfo.id },
                                          query: { progress: "2.0" }
                                        }
                                      }
                                    },
                                    [_vm._v("ファイルを入力→アップロード")]
                                  )
                                : _c("span", [_vm._v("未")])
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("dl", [
                      _c(
                        "dt",
                        { staticClass: " height_up_2_3 height_up_3_1_2" },
                        [_vm._v("ID・パスワード設定")]
                      ),
                      _vm._v(" "),
                      _c(
                        "dd",
                        [
                          _c("ValidationProvider", {
                            staticClass:
                              "validate margin_dd fx_st btn_form_3_1",
                            attrs: {
                              name: "id_setting_type",
                              rules: "required"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var errors = ref.errors
                                    return [
                                      _c("p", [_vm._v("ID")]),
                                      _vm._v(" "),
                                      _c("p", { staticClass: "id_btn1" }, [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.projectInfo.id_setting_type,
                                              expression:
                                                "projectInfo.id_setting_type"
                                            }
                                          ],
                                          class: {
                                            form_radio: true,
                                            error: errors[0]
                                          },
                                          attrs: {
                                            type: "radio",
                                            name: "id_setting_type",
                                            value: "1",
                                            id: "id_setting_type1"
                                          },
                                          domProps: {
                                            checked: _vm._q(
                                              _vm.projectInfo.id_setting_type,
                                              "1"
                                            )
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.$set(
                                                _vm.projectInfo,
                                                "id_setting_type",
                                                "1"
                                              )
                                            }
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "label",
                                          {
                                            attrs: { for: "id_setting_type1" }
                                          },
                                          [_vm._v("事務局(貴社)が設定")]
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("p", [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.projectInfo.id_setting_type,
                                              expression:
                                                "projectInfo.id_setting_type"
                                            }
                                          ],
                                          class: {
                                            form_radio: true,
                                            error: errors[0]
                                          },
                                          attrs: {
                                            type: "radio",
                                            name: "id_setting_type",
                                            value: "2",
                                            id: "id_setting_type2"
                                          },
                                          domProps: {
                                            checked: _vm._q(
                                              _vm.projectInfo.id_setting_type,
                                              "2"
                                            )
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.$set(
                                                _vm.projectInfo,
                                                "id_setting_type",
                                                "2"
                                              )
                                            }
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "label",
                                          {
                                            attrs: { for: "id_setting_type2" }
                                          },
                                          [
                                            _vm._v(
                                              "ビジネスコンサルタントが設定"
                                            )
                                          ]
                                        )
                                      ])
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          }),
                          _vm._v(" "),
                          _c("ValidationProvider", {
                            staticClass:
                              "validate margin_dd fx_st btn_form_3_1 btn_form_3_1_2",
                            attrs: {
                              name: "pass_setting_type",
                              rules: "required"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var errors = ref.errors
                                    return [
                                      _c("p", [_vm._v("パスワード")]),
                                      _vm._v(" "),
                                      _c("p", { staticClass: "id_btn2" }, [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.projectInfo
                                                  .pass_setting_type,
                                              expression:
                                                "projectInfo.pass_setting_type"
                                            }
                                          ],
                                          class: {
                                            form_radio: true,
                                            error: errors[0]
                                          },
                                          attrs: {
                                            type: "radio",
                                            name: "pass_setting_type",
                                            value: "1",
                                            id: "pass_setting_type1"
                                          },
                                          domProps: {
                                            checked: _vm._q(
                                              _vm.projectInfo.pass_setting_type,
                                              "1"
                                            )
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.$set(
                                                _vm.projectInfo,
                                                "pass_setting_type",
                                                "1"
                                              )
                                            }
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "label",
                                          {
                                            attrs: { for: "pass_setting_type1" }
                                          },
                                          [_vm._v("事務局(貴社)が設定")]
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("p", [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.projectInfo
                                                  .pass_setting_type,
                                              expression:
                                                "projectInfo.pass_setting_type"
                                            }
                                          ],
                                          class: {
                                            form_radio: true,
                                            error: errors[0]
                                          },
                                          attrs: {
                                            type: "radio",
                                            name: "pass_setting_type",
                                            value: "2",
                                            id: "pass_setting_type2"
                                          },
                                          domProps: {
                                            checked: _vm._q(
                                              _vm.projectInfo.pass_setting_type,
                                              "2"
                                            )
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.$set(
                                                _vm.projectInfo,
                                                "pass_setting_type",
                                                "2"
                                              )
                                            }
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "label",
                                          {
                                            attrs: { for: "pass_setting_type2" }
                                          },
                                          [
                                            _vm._v(
                                              "ビジネスコンサルタントが設定"
                                            )
                                          ]
                                        )
                                      ])
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("dl", [
                      _c(
                        "dt",
                        { staticClass: " form_box_2_3_dt height_up_3_1" },
                        [_vm._v("貴社お問合せ先")]
                      ),
                      _vm._v(" "),
                      _c(
                        "dd",
                        { staticClass: "margin_dd" },
                        [
                          _c("div", { staticClass: "fx_st" }, [
                            _c("p", [_vm._v("部署・役職")]),
                            _vm._v(" "),
                            _c("p", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.projectInfo.inquiry_division,
                                    expression: "projectInfo.inquiry_division"
                                  }
                                ],
                                staticClass: "form_text form_sch",
                                attrs: {
                                  type: "text",
                                  name: "inquiry_division",
                                  placeholder: "◯◯部　部長",
                                  maxlength: "50"
                                },
                                domProps: {
                                  value: _vm.projectInfo.inquiry_division
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.projectInfo,
                                      "inquiry_division",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "fx_st" }, [
                            _c("p", [_vm._v("氏名")]),
                            _vm._v(" "),
                            _c("p", { staticClass: "margin_dd2" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.projectInfo.inquiry_name,
                                    expression: "projectInfo.inquiry_name"
                                  }
                                ],
                                staticClass: "form_text form_sch",
                                attrs: {
                                  type: "text",
                                  name: "inquiry_name",
                                  placeholder: "田中　一郎",
                                  maxlength: "20"
                                },
                                domProps: {
                                  value: _vm.projectInfo.inquiry_name
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.projectInfo,
                                      "inquiry_name",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "fx_st" }, [
                            _c("p", { staticClass: "sch_1" }, [
                              _vm._v("電話番号")
                            ]),
                            _vm._v(" "),
                            _c(
                              "p",
                              { staticClass: "margin_dd3" },
                              [
                                _c("ValidationProvider", {
                                  staticClass: "validate",
                                  attrs: {
                                    name: "inquiry_tel",
                                    rules: {
                                      numeric: true,
                                      regex: /^[0-9a-zA-Z]*$/
                                    }
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.projectInfo.inquiry_tel,
                                                  expression:
                                                    "projectInfo.inquiry_tel"
                                                }
                                              ],
                                              staticClass: "form_text form_sch",
                                              attrs: {
                                                type: "tel",
                                                pattern: "[0-9]+",
                                                maxlength: "11",
                                                name: "inquiry_tel",
                                                placeholder: "03xxxxxxxx",
                                                onKeyUp:
                                                  "$(this).val($(this).val().replace(/[^\\d]/ig, ''))",
                                                autocomplete: "none"
                                              },
                                              domProps: {
                                                value:
                                                  _vm.projectInfo.inquiry_tel
                                              },
                                              on: {
                                                keypress: function($event) {
                                                  return _vm.preventAlphabetInput(
                                                    $event
                                                  )
                                                },
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.projectInfo,
                                                    "inquiry_tel",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("ValidationProvider", {
                            staticClass: "validate",
                            attrs: { name: "inquiry_email", rules: "email" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var errors = ref.errors
                                    return [
                                      _c("div", { staticClass: "fx_st" }, [
                                        _c("p", { staticClass: "sch_1" }, [
                                          _vm._v("メール")
                                        ]),
                                        _vm._v(" "),
                                        _c("p", { staticClass: "margin_dd4" }, [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.projectInfo.inquiry_email,
                                                expression:
                                                  "projectInfo.inquiry_email"
                                              }
                                            ],
                                            class: {
                                              "form_text form_sch": true,
                                              error: errors[0]
                                            },
                                            attrs: {
                                              type: "text",
                                              maxlength: "100",
                                              name: "inquiry_email",
                                              placeholder:
                                                "Ichiro.t@cliant.co.jp",
                                              onKeyUp:
                                                "$(this).val($(this).val().replace(/[^a-zA-Z.\\-+@_\\d]/ig, ''))"
                                            },
                                            domProps: {
                                              value:
                                                _vm.projectInfo.inquiry_email
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.projectInfo,
                                                  "inquiry_email",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        ])
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: errors[0],
                                              expression: "errors[0]"
                                            }
                                          ],
                                          staticClass: "error left-95"
                                        },
                                        [_vm._v(_vm._s(errors[0]))]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _vm.projectInfo.medium_type != 1 &&
                    _vm.projectInfo.project_type >= 11 &&
                    _vm.projectInfo.project_type <= 16
                      ? _c("dl", [
                          _c("dt", { staticClass: "form_dt_3_1_5" }, [
                            _vm._v("質問紙提出先"),
                            _c("img", {
                              staticClass: "info",
                              attrs: {
                                src: "/images/info.png",
                                srcset:
                                  "/images/info.png" +
                                  " 1x, " +
                                  "/images/info@2x.png" +
                                  " 2x",
                                alt: "インフォメーション"
                              }
                            }),
                            _vm._v(" "),
                            _c("p", { staticClass: "info_fukidashi_10" }, [
                              _vm._v("質問紙に記載されます。（下記例）"),
                              _c("br"),
                              _vm._v(" "),
                              _c("img", {
                                attrs: {
                                  src: "/images/sample02.png",
                                  alt: "サンプル"
                                }
                              })
                            ])
                          ]),
                          _vm._v(" "),
                          _c("dd", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.projectInfo.paper_recipient,
                                  expression: "projectInfo.paper_recipient"
                                }
                              ],
                              staticClass: "form_text tel",
                              attrs: {
                                type: "text",
                                name: "paper_recipient",
                                placeholder: "例：●●部署 ▲▲さん",
                                maxlength: "50"
                              },
                              domProps: {
                                value: _vm.projectInfo.paper_recipient
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.projectInfo,
                                    "paper_recipient",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.projectInfo.project_type == 11
                      ? _c("dl", { staticClass: "radio_2" }, [
                          _c("dt", { staticClass: "form_dt_3_1_4 top" }, [
                            _vm._v("業種別比較")
                          ]),
                          _vm._v(" "),
                          _c(
                            "dd",
                            [
                              _c("ValidationProvider", {
                                staticClass: "validate",
                                attrs: {
                                  name: "comparison_industry_type",
                                  rules: "required"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.projectInfo
                                                    .comparison_industry_type,
                                                expression:
                                                  "projectInfo.comparison_industry_type"
                                              }
                                            ],
                                            class: {
                                              form_radio: true,
                                              error: errors[0]
                                            },
                                            attrs: {
                                              type: "radio",
                                              name: "comparison_industry_type",
                                              value: "1",
                                              id: "comparison_industry_type1"
                                            },
                                            domProps: {
                                              checked: _vm._q(
                                                _vm.projectInfo
                                                  .comparison_industry_type,
                                                "1"
                                              )
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.$set(
                                                  _vm.projectInfo,
                                                  "comparison_industry_type",
                                                  "1"
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "label",
                                            {
                                              attrs: {
                                                for: "comparison_industry_type1"
                                              }
                                            },
                                            [_vm._v("有り")]
                                          ),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.projectInfo
                                                    .comparison_industry_type,
                                                expression:
                                                  "projectInfo.comparison_industry_type"
                                              }
                                            ],
                                            class: {
                                              form_radio: true,
                                              error: errors[0]
                                            },
                                            attrs: {
                                              type: "radio",
                                              name: "comparison_industry_type",
                                              value: "0",
                                              id: "comparison_industry_type2"
                                            },
                                            domProps: {
                                              checked: _vm._q(
                                                _vm.projectInfo
                                                  .comparison_industry_type,
                                                "0"
                                              )
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.$set(
                                                  _vm.projectInfo,
                                                  "comparison_industry_type",
                                                  "0"
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "label",
                                            {
                                              attrs: {
                                                for: "comparison_industry_type2"
                                              }
                                            },
                                            [_vm._v("無し")]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("dl", { staticClass: "radio_2" }, [
                      _c("dt", { staticClass: "form_dt_3_1_4 top" }, [
                        _vm._v("回答者への案内メール")
                      ]),
                      _vm._v(" "),
                      _c(
                        "dd",
                        [
                          _c("ValidationProvider", {
                            staticClass: "validate",
                            attrs: {
                              name: "guidance_email_type",
                              rules: "required"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var errors = ref.errors
                                    return [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.projectInfo
                                                .guidance_email_type,
                                            expression:
                                              "projectInfo.guidance_email_type"
                                          }
                                        ],
                                        class: {
                                          form_radio: true,
                                          error: errors[0]
                                        },
                                        attrs: {
                                          id: "guidance_email_type_1",
                                          type: "radio",
                                          value: "1",
                                          name: "guidance_email_type"
                                        },
                                        domProps: {
                                          checked: _vm._q(
                                            _vm.projectInfo.guidance_email_type,
                                            "1"
                                          )
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.$set(
                                              _vm.projectInfo,
                                              "guidance_email_type",
                                              "1"
                                            )
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "label",
                                        {
                                          attrs: {
                                            for: "guidance_email_type_1"
                                          }
                                        },
                                        [_vm._v("BConがメール送信")]
                                      ),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.projectInfo
                                                .guidance_email_type,
                                            expression:
                                              "projectInfo.guidance_email_type"
                                          }
                                        ],
                                        class: {
                                          form_radio: true,
                                          error: errors[0]
                                        },
                                        attrs: {
                                          id: "guidance_email_type_2",
                                          type: "radio",
                                          value: "2",
                                          name: "guidance_email_type"
                                        },
                                        domProps: {
                                          checked: _vm._q(
                                            _vm.projectInfo.guidance_email_type,
                                            "2"
                                          )
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.$set(
                                              _vm.projectInfo,
                                              "guidance_email_type",
                                              "2"
                                            )
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "label",
                                        {
                                          attrs: {
                                            for: "guidance_email_type_2"
                                          }
                                        },
                                        [_vm._v("お客様が実施")]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _vm.projectInfo.project_type == 11
                      ? _c("dl", { staticClass: "radio_2" }, [
                          _c("dt", { staticClass: "form_dt_3_1_4" }, [
                            _vm._v("質問文の主語変更")
                          ]),
                          _vm._v(" "),
                          _c("dd", [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center"
                                }
                              },
                              [
                                _c("ValidationProvider", {
                                  staticClass: "validate",
                                  attrs: {
                                    name: "question_subject_type_other",
                                    rules: "required"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.question_subject_type_other,
                                                  expression:
                                                    "question_subject_type_other"
                                                }
                                              ],
                                              class: {
                                                form_radio: true,
                                                error: errors[0]
                                              },
                                              attrs: {
                                                id: "radio_3_1_3",
                                                type: "radio",
                                                value: "当社",
                                                name: "question_subject_type"
                                              },
                                              domProps: {
                                                checked: _vm._q(
                                                  _vm.question_subject_type_other,
                                                  "当社"
                                                )
                                              },
                                              on: {
                                                change: [
                                                  function($event) {
                                                    _vm.question_subject_type_other =
                                                      "当社"
                                                  },
                                                  function($event) {
                                                    return _vm.subjectType()
                                                  }
                                                ]
                                              }
                                            }),
                                            _c(
                                              "label",
                                              { attrs: { for: "radio_3_1_3" } },
                                              [_vm._v("当社")]
                                            ),
                                            _vm._v(" "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.question_subject_type_other,
                                                  expression:
                                                    "question_subject_type_other"
                                                }
                                              ],
                                              class: {
                                                form_radio: true,
                                                error: errors[0]
                                              },
                                              attrs: {
                                                id: "radio_3_1_4",
                                                type: "radio",
                                                value: "私の職場",
                                                name: "question_subject_type"
                                              },
                                              domProps: {
                                                checked: _vm._q(
                                                  _vm.question_subject_type_other,
                                                  "私の職場"
                                                )
                                              },
                                              on: {
                                                change: [
                                                  function($event) {
                                                    _vm.question_subject_type_other =
                                                      "私の職場"
                                                  },
                                                  function($event) {
                                                    return _vm.subjectType()
                                                  }
                                                ]
                                              }
                                            }),
                                            _c(
                                              "label",
                                              { attrs: { for: "radio_3_1_4" } },
                                              [_vm._v("私の職場")]
                                            ),
                                            _vm._v(" "),
                                            (!_vm.isEmpty(
                                              _vm.question_subject_type_other_text
                                            ) ||
                                              _vm.other) &&
                                            _vm.question_subject_type_other !=
                                              "1"
                                              ? _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.question_subject_type_other,
                                                      expression:
                                                        "question_subject_type_other"
                                                    }
                                                  ],
                                                  staticClass: "form_radio",
                                                  attrs: {
                                                    id: "radio_3_1_5",
                                                    type: "radio",
                                                    name:
                                                      "question_subject_type"
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.question_subject_type_other_text,
                                                    checked: _vm._q(
                                                      _vm.question_subject_type_other,
                                                      _vm.question_subject_type_other_text
                                                    )
                                                  },
                                                  on: {
                                                    change: [
                                                      function($event) {
                                                        _vm.question_subject_type_other =
                                                          _vm.question_subject_type_other_text
                                                      },
                                                      function($event) {
                                                        return _vm.subjectType()
                                                      }
                                                    ]
                                                  }
                                                })
                                              : _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.question_subject_type_other,
                                                      expression:
                                                        "question_subject_type_other"
                                                    }
                                                  ],
                                                  class: {
                                                    form_radio: true,
                                                    error: errors[0]
                                                  },
                                                  attrs: {
                                                    id: "radio_3_1_5",
                                                    type: "radio",
                                                    name:
                                                      "question_subject_type"
                                                  },
                                                  domProps: {
                                                    value: 1,
                                                    checked: _vm._q(
                                                      _vm.question_subject_type_other,
                                                      1
                                                    )
                                                  },
                                                  on: {
                                                    change: [
                                                      function($event) {
                                                        _vm.question_subject_type_other = 1
                                                      },
                                                      function($event) {
                                                        return _vm.subjectType()
                                                      }
                                                    ]
                                                  }
                                                }),
                                            _vm._v(" "),
                                            _c(
                                              "label",
                                              {
                                                staticClass: "no-margin",
                                                attrs: { for: "radio_3_1_5" }
                                              },
                                              [_vm._v("その他")]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                }),
                                _vm._v(" "),
                                _c("ValidationProvider", {
                                  staticClass: "validate",
                                  staticStyle: {
                                    display: "inline-block",
                                    width: "50%"
                                  },
                                  attrs: {
                                    name: "question_subject_type_other_text",
                                    rules:
                                      (_vm.question_subject_type_other !==
                                        "当社" &&
                                        _vm.question_subject_type_other !==
                                          "私の職場" &&
                                        _vm.empty(
                                          _vm.question_subject_type_other
                                        )) ||
                                      _vm.question_subject_type_other == "1"
                                        ? "required"
                                        : ""
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.question_subject_type_other_text,
                                                  expression:
                                                    "question_subject_type_other_text"
                                                }
                                              ],
                                              class: {
                                                "form_text tel_number": true,
                                                error: errors[0]
                                              },
                                              staticStyle: {
                                                "max-width": "100%"
                                              },
                                              attrs: {
                                                type: "text",
                                                name: "other",
                                                maxlength: "20",
                                                placeholder:
                                                  "例：部門、本部、事業部"
                                              },
                                              domProps: {
                                                value:
                                                  _vm.question_subject_type_other_text
                                              },
                                              on: {
                                                input: [
                                                  function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.question_subject_type_other_text =
                                                      $event.target.value
                                                  },
                                                  function($event) {
                                                    return _vm.inputOther()
                                                  }
                                                ]
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("br", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: errors[0],
                                                  expression: "errors[0]"
                                                }
                                              ]
                                            }),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "error",
                                                staticStyle: {
                                                  "padding-left": "0px"
                                                }
                                              },
                                              [_vm._v(_vm._s(errors[0]))]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            )
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.projectInfo.project_type !== 11 &&
                    _vm.projectInfo.project_type > 11 &&
                    _vm.projectInfo.project_type <= 16
                      ? _c("dl", { staticClass: "radio_2 fx" }, [
                          _c("dt", [_vm._v("追加質問文")]),
                          _vm._v(" "),
                          _c("dd", { staticClass: "padding_t" }, [
                            _c("span", { staticClass: "margin_no" }, [
                              _vm._v(
                                "※見積・報告書形式については担当営業にお問い合わせください。"
                              )
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _c("input", {
                                staticClass: "none",
                                attrs: {
                                  id: "qestion_type1",
                                  type: "checkbox",
                                  name: "qestion_type1"
                                },
                                domProps: { checked: _vm.qestion_type1 },
                                on: {
                                  click: function($event) {
                                    return _vm.uncheck1()
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass: "check_2_3",
                                  attrs: { for: "qestion_type1" }
                                },
                                [
                                  _vm._v(
                                    "追加質問が5問以内/追加質問の尺度が下記の7点法を利用"
                                  )
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "padding_b" }, [
                              _c("input", {
                                staticClass: "none",
                                attrs: {
                                  id: "qestion_type2",
                                  type: "checkbox",
                                  name: "qestion_type2"
                                },
                                domProps: { checked: _vm.qestion_type2 },
                                on: {
                                  click: function($event) {
                                    return _vm.uncheck2()
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass: "check_2_3",
                                  attrs: { for: "qestion_type2" }
                                },
                                [
                                  _vm._v(
                                    "追加質問が6問以上/追加質問の尺度が7点法以外(個別対応)"
                                  )
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _vm.projectInfo.qestion_type == 1
                              ? _c("div", { attrs: { id: "seven" } }, [
                                  _c("div", { staticClass: "b_bottom" }, [
                                    _c("p", [
                                      _c("span", { staticClass: "margin_no" }, [
                                        _vm._v("【7点法尺度】")
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c("p", [
                                      _c("img", {
                                        staticStyle: { "max-width": "100%" },
                                        attrs: {
                                          src: "/images/img3-1-2@2x.png",
                                          width: "266",
                                          height: "137"
                                        }
                                      })
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "b_bottom2" },
                                    [
                                      _vm._l(
                                        _vm.projectInfo.questions,
                                        function(question, i) {
                                          return _c(
                                            "div",
                                            { staticClass: "fx_st2" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: question.id,
                                                    expression: "question.id"
                                                  }
                                                ],
                                                attrs: { type: "hidden" },
                                                domProps: {
                                                  value: question.id
                                                },
                                                on: {
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      question,
                                                      "id",
                                                      $event.target.value
                                                    )
                                                  }
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("div", [
                                                _c("p", [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "margin_no"
                                                    },
                                                    [_vm._v("質問文")]
                                                  )
                                                ]),
                                                _vm._v(" "),
                                                _c(
                                                  "p",
                                                  [
                                                    _c("ValidationProvider", {
                                                      staticClass: "validate",
                                                      attrs: {
                                                        name: "body",
                                                        rules: "max:100"
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function(ref) {
                                                              var errors =
                                                                ref.errors
                                                              return [
                                                                _c("textarea", {
                                                                  directives: [
                                                                    {
                                                                      name:
                                                                        "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        question.body,
                                                                      expression:
                                                                        "question.body"
                                                                    }
                                                                  ],
                                                                  class: {
                                                                    error:
                                                                      errors[0]
                                                                  },
                                                                  attrs: {
                                                                    name:
                                                                      "body" +
                                                                      i,
                                                                    rows: "3",
                                                                    cols: "40",
                                                                    id:
                                                                      "textarea_3_1",
                                                                    placeholder:
                                                                      "質問文を入れてください。",
                                                                    maxlength:
                                                                      "200"
                                                                  },
                                                                  domProps: {
                                                                    value:
                                                                      question.body
                                                                  },
                                                                  on: {
                                                                    input: function(
                                                                      $event
                                                                    ) {
                                                                      if (
                                                                        $event
                                                                          .target
                                                                          .composing
                                                                      ) {
                                                                        return
                                                                      }
                                                                      _vm.$set(
                                                                        question,
                                                                        "body",
                                                                        $event
                                                                          .target
                                                                          .value
                                                                      )
                                                                    }
                                                                  }
                                                                }),
                                                                _vm._v(" "),
                                                                _c("br", {
                                                                  directives: [
                                                                    {
                                                                      name:
                                                                        "show",
                                                                      rawName:
                                                                        "v-show",
                                                                      value:
                                                                        errors[0],
                                                                      expression:
                                                                        "errors[0]"
                                                                    }
                                                                  ]
                                                                }),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "error"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        errors[0]
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                [
                                                  _c("p", [
                                                    _c("span", [
                                                      _vm._v("回答方式")
                                                    ])
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("ValidationProvider", {
                                                    staticClass: "validate",
                                                    attrs: {
                                                      name: "format_type" + i,
                                                      rules: {
                                                        required:
                                                          question.body !== ""
                                                      }
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c("p", [
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name:
                                                                        "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        question.format_type,
                                                                      expression:
                                                                        "question.format_type"
                                                                    }
                                                                  ],
                                                                  class: {
                                                                    form_radio: true,
                                                                    error:
                                                                      errors[0]
                                                                  },
                                                                  attrs: {
                                                                    id:
                                                                      "answer1" +
                                                                      i,
                                                                    type:
                                                                      "radio",
                                                                    value: "1",
                                                                    name:
                                                                      "format_type" +
                                                                      i
                                                                  },
                                                                  domProps: {
                                                                    checked: _vm._q(
                                                                      question.format_type,
                                                                      "1"
                                                                    )
                                                                  },
                                                                  on: {
                                                                    change: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.$set(
                                                                        question,
                                                                        "format_type",
                                                                        "1"
                                                                      )
                                                                    }
                                                                  }
                                                                }),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "label",
                                                                  {
                                                                    attrs: {
                                                                      for:
                                                                        "answer1" +
                                                                        i
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "尺度(7点法)"
                                                                    )
                                                                  ]
                                                                )
                                                              ]),
                                                              _vm._v(" "),
                                                              _c("p", [
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name:
                                                                        "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        question.format_type,
                                                                      expression:
                                                                        "question.format_type"
                                                                    }
                                                                  ],
                                                                  class: {
                                                                    form_radio: true,
                                                                    error:
                                                                      errors[0]
                                                                  },
                                                                  attrs: {
                                                                    id:
                                                                      "answer2" +
                                                                      i,
                                                                    type:
                                                                      "radio",
                                                                    value: "2",
                                                                    name:
                                                                      "format_type" +
                                                                      i
                                                                  },
                                                                  domProps: {
                                                                    checked: _vm._q(
                                                                      question.format_type,
                                                                      "2"
                                                                    )
                                                                  },
                                                                  on: {
                                                                    change: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.$set(
                                                                        question,
                                                                        "format_type",
                                                                        "2"
                                                                      )
                                                                    }
                                                                  }
                                                                }),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "label",
                                                                  {
                                                                    attrs: {
                                                                      for:
                                                                        "answer2" +
                                                                        i
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "自由記入"
                                                                    )
                                                                  ]
                                                                )
                                                              ])
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        }
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "action" }, [
                                        _c(
                                          "button",
                                          {
                                            class: { add: true },
                                            attrs: {
                                              disabled:
                                                _vm.projectInfo.questions
                                                  .length == 5,
                                              type: "button"
                                            },
                                            on: { click: _vm.addQuestion }
                                          },
                                          [_vm._v("＋")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "button",
                                          {
                                            staticClass: "del",
                                            attrs: { type: "button" },
                                            on: { click: _vm.removeQuestion }
                                          },
                                          [_vm._v("－")]
                                        )
                                      ])
                                    ],
                                    2
                                  )
                                ])
                              : _vm._e()
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.projectInfo.project_type >= 11 &&
                    _vm.projectInfo.project_type <= 16 &&
                    _vm.projectInfo.attribute_setting_type == 2
                      ? _c("dl", { staticClass: "radio_2" }, [
                          _c("dt", { staticClass: "form_dt_3_1_4" }, [
                            _vm._v("対象者リスト(Excel)")
                          ]),
                          _vm._v(" "),
                          _c(
                            "dd",
                            { staticClass: "form_box_5_2" },
                            [
                              _vm.projectInfo.p_target_people_file_name
                                ? _c(
                                    "router-link",
                                    {
                                      staticClass:
                                        "form_box_sub_2 form_box_sub_5_2",
                                      attrs: {
                                        to: {
                                          name: "FileComponent",
                                          params: { id: _vm.projectInfo.id },
                                          query: { progress: "2.1" }
                                        }
                                      }
                                    },
                                    [_vm._v("ファイルを入力→アップロード")]
                                  )
                                : _c("span", [_vm._v("未")])
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.projectInfo.project_type >= 21 &&
                    _vm.projectInfo.project_type <= 24
                      ? _c("dl", { staticClass: "radio_2" }, [
                          _c("dt", { staticClass: "form_dt_3_1_4" }, [
                            _vm._v("対象者リスト(Excel)")
                          ]),
                          _vm._v(" "),
                          _c(
                            "dd",
                            { staticClass: "form_box_5_2" },
                            [
                              _vm.projectInfo.p_multifaceted_file_name
                                ? _c(
                                    "router-link",
                                    {
                                      staticClass:
                                        "form_box_sub_2 form_box_sub_5_2",
                                      attrs: {
                                        to: {
                                          name: "FileComponent",
                                          params: { id: _vm.projectInfo.id },
                                          query: { progress: "2.0" }
                                        }
                                      }
                                    },
                                    [_vm._v("ファイルを入力→アップロード")]
                                  )
                                : _c("span", [_vm._v("未")])
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    (_vm.projectInfo.project_type >= 1 &&
                      _vm.projectInfo.project_type <= 6) ||
                    (_vm.projectInfo.project_type >= 11 &&
                      _vm.projectInfo.project_type <= 16 &&
                      _vm.projectInfo.attribute_setting_type == 1)
                      ? _c("dl", { staticClass: "radio_2" }, [
                          _c("dt", { staticClass: "form_dt_3_1_4" }, [
                            _vm._v("対象者リスト(Excel)")
                          ]),
                          _vm._v(" "),
                          _vm.projectInfo.project_type >= 1 &&
                          _vm.projectInfo.project_type <= 6
                            ? _c(
                                "dd",
                                { staticClass: "form_box_5_2" },
                                [
                                  _vm.projectInfo.p_personal_target_file_name
                                    ? _c(
                                        "router-link",
                                        {
                                          staticClass:
                                            "form_box_sub_2 form_box_sub_5_2",
                                          attrs: {
                                            to: {
                                              name: "FileComponent",
                                              params: {
                                                id: _vm.projectInfo.id
                                              },
                                              query: { progress: "2.0" }
                                            }
                                          }
                                        },
                                        [_vm._v("ファイルを入力→アップロード")]
                                      )
                                    : _c("span", [_vm._v("未")])
                                ],
                                1
                              )
                            : _c(
                                "dd",
                                { staticClass: "form_box_5_2" },
                                [
                                  _vm.projectInfo.p_target_people_file_name
                                    ? _c(
                                        "router-link",
                                        {
                                          staticClass:
                                            "form_box_sub_2 form_box_sub_5_2",
                                          attrs: {
                                            to: {
                                              name: "FileComponent",
                                              params: {
                                                id: _vm.projectInfo.id
                                              },
                                              query: { progress: "2.1" }
                                            }
                                          }
                                        },
                                        [_vm._v("ファイルを入力→アップロード")]
                                      )
                                    : _c("span", [_vm._v("未")])
                                ],
                                1
                              )
                        ])
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "footer_box footer_box_1_3" }, [
                  _c("p", { staticClass: "btn" }, [
                    _c("input", {
                      staticClass: "form_box_sub form_box_sub_2",
                      attrs: { type: "submit", value: "保存" }
                    })
                  ])
                ])
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "deletePopup",
          attrs: {
            name: "deletePopup",
            id: "deletePopup",
            "modal-class": ["modal-action  "],
            centered: true,
            "no-close-on-backdrop": true,
            "header-close-label": "",
            "hide-footer": "",
            "hide-header": ""
          }
        },
        [
          _c("h5", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.projectInfo.client_name) + "を削除しますか？")
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-footer top-30" },
            [
              _c(
                "b-button",
                {
                  staticClass: "btn-submit",
                  on: {
                    click: function($event) {
                      return _vm.$bvModal.hide("deletePopup")
                    }
                  }
                },
                [_vm._v("キャンセル")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "btn btn-red",
                  on: {
                    click: function($event) {
                      return _vm.deleteProject()
                    }
                  }
                },
                [_vm._v("削除")]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "isDisabled",
          attrs: {
            name: "isDisabled",
            id: "isDisabled",
            "modal-class": ["modal-action  "],
            centered: true,
            "no-close-on-backdrop": true,
            "header-close-label": "",
            "hide-footer": "",
            "hide-header": ""
          }
        },
        [
          _c("h5", { staticClass: "title" }, [
            _vm._v(
              "リスト提出締切日を" +
                _vm._s(
                  _vm.projectInfo.is_deadline_disabled == true ? "無効" : "有効"
                ) +
                "にしますか？"
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-footer top-30" },
            [
              _c(
                "b-button",
                {
                  staticClass: "btn-submit",
                  on: {
                    click: function($event) {
                      return _vm.closeDeadline()
                    }
                  }
                },
                [_vm._v("キャンセル")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "btn btn-red",
                  on: {
                    click: function($event) {
                      return _vm.changeDeadline()
                    }
                  }
                },
                [_vm._v("はい")]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "endprojectInfo",
          attrs: {
            name: "endprojectInfo",
            id: "endprojectInfo",
            "modal-class": ["modal-action  "],
            centered: true,
            "no-close-on-backdrop": true,
            "header-close-label": "",
            "hide-footer": "",
            "hide-header": ""
          }
        },
        [
          _c("h5", { staticClass: "title" }, [_vm._v("案件が完了しますか？")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-footer top-30" },
            [
              _c(
                "b-button",
                {
                  staticClass: "btn-submit",
                  on: {
                    click: function($event) {
                      return _vm.closeEnd()
                    }
                  }
                },
                [_vm._v("キャンセル")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "btn btn-red",
                  on: {
                    click: function($event) {
                      return _vm.end()
                    }
                  }
                },
                [_vm._v("完了")]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "sendPasswordPopup",
          attrs: {
            name: "sendPasswordPopup",
            id: "sendPasswordPopup",
            "modal-class": ["modal-action"],
            centered: true,
            "no-close-on-backdrop": true,
            "header-close-label": "",
            "hide-footer": "",
            "hide-header": ""
          }
        },
        [
          _c("h5", { staticClass: "title" }, [
            _vm._v("パスワードをリセットしてユーザーに送信しますか？")
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-footer top-30 resend" },
            [
              _c(
                "b-button",
                {
                  staticClass: "btn-submit",
                  on: {
                    click: function($event) {
                      return _vm.$bvModal.hide("sendPasswordPopup")
                    }
                  }
                },
                [_vm._v("キャンセル")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary",
                  on: {
                    click: function($event) {
                      return _vm.sendPassword()
                    }
                  }
                },
                [_vm._v("アカウント再発行")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }