import axios from 'axios'
import Cookies from "js-cookie";
import _ from 'lodash'

if (!_.isUndefined(Cookies.get('user-token'))) {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('user-token');
}

// Add a request interceptor
axios.interceptors.request.use(function (config) {
  // Do something before request is sent
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

export default axios;
