import 'babel-polyfill'
require('./bootstrap');
import 'es6-promise/auto'
import Vue from 'vue';
import router from './router';
import App from './components/AppComponent'
import store from './store';
import VueRouter from 'vue-router';
import VueWait from 'vue-wait';
import VueAxios from 'vue-axios';
import axios from './axios';
import {setInteractionMode, ValidationObserver, ValidationProvider} from 'vee-validate';
import PaginationComponent from './components/PaginationComponent.vue';
import HeaderComponent from './components/HeaderComponent.vue';
import {i18n} from "./lang/i18n";
import "./vee-validate";
import "./filter"

import { BootstrapVue } from 'bootstrap-vue'
Vue.use(BootstrapVue)
import moment from 'moment-timezone';
Vue.use(VueWait);
Vue.router = router;
App.router = Vue.router;
Vue.use(VueRouter);

setInteractionMode('eager');
// Install components globally
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
Vue.use(VueAxios, axios);

Vue.component('pagination-component', PaginationComponent);
Vue.component('header-component', HeaderComponent);

import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
Vue.use(datePicker);

jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle'
  }
});

moment.tz.setDefault('Asia/Tokyo');

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) document.title = nearestWithTitle.meta.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create, so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));

  next();
});

const app = new Vue({
  i18n,
  store,
  router,
  wait: new VueWait(),
  render: h => h(App),
  methods: {}
}).$mount('#app');

window.app = app;
