import {configure, extend, localize} from "vee-validate";
import {email, min, required, regex, digits, numeric, max, max_value } from "vee-validate/dist/rules";
// import {i18n} from "./lang/i18n";
import Vue from "vue";

// configure({
//   defaultMessage: (field, values) => {
//     // override the field name.
//     values._field_ = i18n.t(`fields.${field}`);
//
//     return i18n.t(`validation.${values._rule_}`, values);
//   }
// });

// Install required rule and message.
extend("required", required);
extend("max", max);

// Install email rule and message.
extend("email", email);

// Install min rule and message.
extend("min", min);

// Install regex rule and message.
extend("regex", regex);

// Install min digits and message.
extend("digits", digits);

// Install min digits and message.
extend("numeric", numeric);
extend("max_value", max_value);

extend('same', {
  validate(value, args) {
    return value !== args[0];
  },
  message: 'メールは担当者のメールと重複しています。もう一度ご入力してください。'
});

extend('exceed', {
  validate(value, args) {
    return value <= args[0];
  },
  message: '報告書納品希望日は上記の日時が過ぎられません。'
})

extend('exceed2', {
  validate(value, args) {
    return value >= args[0];
  },
  message: '以降を入力してください。'
})


import ja from "vee-validate/dist/locale/ja.json";
import en from "vee-validate/dist/locale/en.json";



localize({
  en: {
    messages: ja.messages,
    names: {
      inquiry_email: "メール",
      client_memo: "備考欄",
      body: "質問文",
      memo: "備考欄",
      c_role_id: '権限',
      consultant_name: '氏名',
      client_name: "組織名",
      name1: "担当者　氏名",
      name2: "担当者2人目　氏名",
      email1: "担当者　メールアドレス",
      email2: "担当者2人目",
      email: "ログインID",
      emailSales: 'アドレスメール',
      staff_name: "担当営業名",
      is_multisite_diagnosis: "診断種別選択",
      is_comparison_age: "経年比較",
      password: 'パスワード',
      project_type: "診断種別選択",
      division2: '',
      staff_email1: '共有先メールアドレス①',
      staff_email2: '共有先メールアドレス②',
      apply_zip_code: '〒',
      apply_address1: '都道府県',
      apply_address2: '市区町村番地',
      client_tel: '電話番号',
      zip_code: '〒',
      address1: '都道府県',
      address2: '市区町村番地',
      paper2_address1: '都道府県',
      paper2_address2: '市区町村番地',
      paper1_address1: '都道府県',
      paper1_address2: '市区町村番地',
      industry_type: '業界・業種',
      comparison_industry_type: '業界業種別比較',
      scale_type: '規模',
      select_kubun_type: '区分コードの選択者',
      id_setting_type: 'ID',
      pass_setting_type: 'パスワード',
      web_start1: 'Web回答開始日時',
      web_end1: 'Web回答終了日時',
      report_preferred_date: 'レポート納品希望日',
      paper_preferred_date: '質問紙到着希望日',
      paper_preferred_date3: '質問紙到着希望日',
      paper_preferred_date2: '質問紙提出期限',
      end: '診断終了日時',
      paper_bcon_receipt_date2: '質問紙BCon到着日（任意）',
      paper_bcon_receipt_date: '質問紙BCon到着日（任意）',
      paper_bcon_receipt_date3: '質問紙BCon到着日（任意）',
      report_preferred_date2: '報告書納品希望日',
      web_start2: 'Web回答開始日時',
      web_end2: 'web診断終了日時',
      web_start3: 'web診断開始日時',
      web_end3: 'web診断終了日時',
      id_login: 'ログインID',
      paper1_copies: '質問紙の送付部数①',
      paper2_copies: '質問紙の送付部数②',
      paper_send_type: '質問紙の送付先点数',
      paper_limit_date: '質問紙提出期限',
      paper_limit_date2: '質問紙提出期限',
      paper_limit_date3: '質問紙提出期限',
      paper_people: '質問紙',
      medium_type: '実施方法',
      web_people: 'Web',
      position: '役職',
      division: '所属',
      username: '氏名',
      attribute_setting_type: '属性一覧の設定',
      paper1_division: '部署',
      paper1_name: '氏名',
      paper1_position: '役職',
      paper2_division: '部署',
      paper2_name: '氏名',
      paper2_position: '役職',
      question_subject_type_other_text: 'text'
    },
    fields: {
      web_end: {
        required: "      "
      },
      email2: {
        required: "{_field_}を設定の場合はメールアドレスは必須項目です"
      },
      staff_email1: {
        regex: 'フォーマットが正しくありません'
      },
      staff_email2: {
        regex: 'フォーマットが正しくありません'
      },
      client_tel: {
        numeric: '電話番号は半角数字のみ使用できます',
        regex: '電話番号は半角数字のみ使用できます'
      },
      question_subject_type_other_text: {
        required: 'その他を選択した場合はこちらの入力が必須です。'
      },
      inquiry_email: {
        email: '有効なメールアドレスではありません'
      },
      web_people: {
        max_value: '1～1000以内の数字を入力してください'
      },
      paper_people: {
        max_value: '1～1000以内の数字を入力してください'
      },
      paper1_copies: {
        max_value: '1～10000以内の数字を入力してください'
      },
      paper2_copies: {
        max_value: '1～10000以内の数字を入力してください'
      }
    }
  }
});

let LOCALE = "ja";

// A simple get/set interface to manage our locale in components.
// This is not reactive, so don't create any computed properties/watchers off it.
Object.defineProperty(Vue.prototype, "locale", {
  get() {
    return LOCALE;
  },
  set(val) {
    console.log(val)
    LOCALE = val;
    localize(val);
  }
});
