var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _vm.project && _vm.project.id
        ? _c("header-component", { attrs: { title: _vm.projectType() } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.project && _vm.project.id,
              expression: "project && project.id"
            }
          ],
          staticClass: "top_contact fx_cece"
        },
        [_vm._m(0), _vm._v(" "), _vm._m(1)]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.project && _vm.project.progress_status !== undefined,
              expression: "project && project.progress_status !== undefined"
            }
          ]
        },
        [
          _c("div", { attrs: { id: "step_bar_box" } }, [
            _c("ol", { staticClass: "step_bar" }, [
              _c(
                "li",
                {
                  class: {
                    current:
                      (_vm.project.progress_status == 0 ||
                        _vm.project.progress_status == 1) &&
                      _vm.newStep == 0
                  }
                },
                [_vm._v("STEP1 診断申込 登録")]
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  class: {
                    current:
                      (_vm.project.progress_status == 1 ||
                        _vm.project.progress_status == 2) &&
                      _vm.newStep == 1
                  }
                },
                [_vm._v("STEP2 詳細情報 登録")]
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  class: {
                    current:
                      ((_vm.project.progress_status == 2 ||
                        _vm.project.progress_status == 3 ||
                        _vm.project.progress_status == 2.2 ||
                        _vm.project.progress_status == 2.3) &&
                        (_vm.newStep == 2 || _vm.newStep == 3)) ||
                      (_vm.project.progress_status == 2.1 &&
                        (_vm.newStep == 2.1 || _vm.newStep == 3)) ||
                      (_vm.project.progress_status == 2.2 &&
                        _vm.newStep == 2.2) ||
                      (_vm.project.progress_status == 2.3 && _vm.newStep == 2.3)
                  }
                },
                [_vm._v("STEP3 対象者リスト登録")]
              )
            ]),
            _vm._v(" "),
            _c(
              "p",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      (_vm.project.progress_status == 0 ||
                        _vm.project.progress_status == 1) &&
                      !_vm.hideBtn,
                    expression:
                      "(project.progress_status == 0 || project.progress_status == 1) && !hideBtn"
                  }
                ],
                staticClass: "save_btn"
              },
              [
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:;" },
                    on: { click: _vm.saveWithoutValidate }
                  },
                  [_vm._v("一時保存")]
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("step-first", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.project.progress_status == 0 ||
                      (_vm.project.progress_status == 1 && _vm.newStep == 0),
                    expression:
                      "project.progress_status == 0 || (project.progress_status == 1 && newStep == 0)"
                  }
                ],
                ref: "first",
                attrs: { preview: _vm.preview },
                on: { hidden: _vm.hidden }
              }),
              _vm._v(" "),
              _c("step-second", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      (_vm.project.progress_status == 1 ||
                        _vm.project.progress_status == 2) &&
                      _vm.newStep == 1,
                    expression:
                      "(project.progress_status == 1 || project.progress_status == 2) && newStep == 1"
                  }
                ],
                ref: "second",
                attrs: { preview: _vm.preview },
                on: { hidden: _vm.hidden }
              }),
              _vm._v(" "),
              _c("step-third", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      ((_vm.project.progress_status == 2 ||
                        _vm.project.progress_status == 2.2 ||
                        _vm.project.progress_status == 2.3 ||
                        _vm.project.progress_status == 3) &&
                        _vm.newStep == 2) ||
                      (_vm.project.progress_status == 2.1 &&
                        _vm.newStep == 2.1) ||
                      (_vm.project.progress_status == 2.2 &&
                        _vm.newStep == 2.2) ||
                      (_vm.project.progress_status == 2.3 &&
                        _vm.newStep == 2.3),
                    expression:
                      "((project.progress_status == 2 || project.progress_status == 2.2 || project.progress_status == 2.3 || project.progress_status == 3) && newStep == 2)\n      || (project.progress_status == 2.1 && newStep == 2.1)\n      || (project.progress_status == 2.2 && newStep == 2.2) || (project.progress_status == 2.3 && newStep == 2.3) "
                  }
                ],
                on: { openTutorial: _vm.openTutorial }
              }),
              _vm._v(" "),
              _c("file-confirmation", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      (_vm.project.progress_status == 2 ||
                        _vm.project.progress_status == 2.2 ||
                        _vm.project.progress_status == 2.3 ||
                        _vm.project.progress_status == 2.1 ||
                        _vm.project.progress_status == 3) &&
                      _vm.newStep == 3,
                    expression:
                      "(project.progress_status == 2 || project.progress_status == 2.2 || project.progress_status == 2.3 || project.progress_status == 2.1  || project.progress_status == 3) && newStep == 3"
                  }
                ]
              })
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _vm.project && _vm.project.id
        ? _c("project-guide", {
            attrs: { isOpenTuror: _vm.isOpenTuror },
            on: { openTutorial: _vm.openTutorial }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "top_con1" }, [
      _c("img", {
        attrs: {
          src: "images/top_qu.png",
          srcset:
            "images/top_qu.png" + " 1x, " + "images/top_qu@2x.png" + " 2x",
          alt: "お問合せ先"
        }
      }),
      _c("br"),
      _vm._v("お問合せ先\n    ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "top_con2" }, [
      _c("p", [
        _vm._v(
          "不明点や確認事項がありましたら担当営業もしくはこちらまでお問い合わせください。"
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "fx_ce" }, [
        _c("p", { staticClass: "top_con2_p border_left" }, [
          _c("img", {
            attrs: {
              src: "images/top_mail.png",
              srcset:
                "images/top_mail.png" +
                " 1x, " +
                "images/top_mail@2x.png" +
                " 2x",
              alt: "メールでのお問合せ"
            }
          }),
          _vm._v("メールでのお問い合わせ"),
          _c("br"),
          _c("span", { staticClass: "span_color" }, [
            _c("a", { attrs: { href: "mailto:survey-rcptn@info.bcon.jp" } }, [
              _vm._v("survey-rcptn@info.bcon.jp")
            ])
          ])
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "top_con2_p" }, [
          _c("img", {
            attrs: {
              src: "images/top_tel.png",
              srcset:
                "images/top_tel.png" +
                " 1x, " +
                "images/top_tel@2x.png" +
                " 2x",
              alt: "お電話でのお問合せ"
            }
          }),
          _vm._v("電話でのお問い合わせ"),
          _c("br"),
          _c("span", [_vm._v("03-3287-3416(平日 10:00～17:00)")])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }