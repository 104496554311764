<template>
  <div class="body_1_2 container">
    <header-component v-if="!$route.params.id || $route.params.id && sale.id" :title="'[営業登録]'" :back="{ name: 'SalesList'}"></header-component>
    <header-component :title="'[営業登録]'" v-else></header-component>
    <ValidationObserver ref="observer" v-slot="{ invalid }" tag="form" @submit.prevent="previewRegister()" v-if="!$route.params.id || $route.params.id && sale.id && user.id">
      <div class="form_box">
        <div class="form_box_title">
          <p>営業登録</p>
        </div>
        <dl>
          <dt class="required required81">ログインID</dt>
          <dd>
            <ValidationProvider name="id_login" :rules="{required: true, uniqueID: true, regex: /^([a-zA-Z0-9_-]){5,32}$/}" v-slot="{ errors }" class="validate">
              <input :class="{ 'form_text': true, 'error': errors[0] }" type="text" v-model="sale.id_login"
                     placeholder="ログインID"
                     name="id_login">
              <br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>
            </ValidationProvider>
          </dd>
        </dl>
        <dl>
          <dt class="required required1">氏名</dt>
          <dd>
            <ValidationProvider name="consultant_name" rules="required" v-slot="{ errors }" class="validate">
              <input :class="{ 'form_text': true, 'error': errors[0] }" type="text" v-model="sale.name"
                     placeholder="氏名"
                     name="name">
              <br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>
            </ValidationProvider>
          </dd>
        </dl>

        <dl>
          <dt class="required required81">メールアドレス</dt>
          <dd>
            <ValidationProvider name="emailSales" rules="required|email" v-slot="{ errors }" class="validate">
              <!--<ValidationProvider name="emailSales" rules="required|email|uniqueEmail" v-slot="{ errors }" class="validate">-->
              <input :class="{ 'form_text': true, 'error': errors[0] }" type="text" v-model="sale.email"
                     placeholder="メールアドレス"
                     name="emailSales">
              <br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>
            </ValidationProvider>
          </dd>
        </dl>

        <dl>
          <dt class="required required81">パスワード</dt>
          <dd>
            <ValidationProvider  :mode="submit ? 'eager' : 'passive'" name="password" :rules="!sale.id ? 'required' : ''" v-slot="{ errors }" class="validate">
              <input :class="{ 'form_text': true, 'error': errors[0] }" type="password" v-model="sale.password"
                     :placeholder="sale.id ? '******' : 'パスワード'"
                     name="name">
              <br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>
            </ValidationProvider>
          </dd>
        </dl>

        <dl>
          <dt class="required required1">営業所</dt>
          <dd>
            <ValidationProvider name="office" rules="required" v-slot="{ errors }" class="validate">
              <input :class="{ 'form_text': true, 'error': errors[0] }" type="text" v-model="sale.office"
                     placeholder="営業所"
                     name="office">
              <br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>
            </ValidationProvider>
          </dd>
        </dl>
      </div>
      <div class="footer_box footer_box_1_3">
        <p class="btn2"><input @click="backList()" class="form_box_sub_blue form_box_sub_2" type="button"
                               value="前の画面に戻る"></p>
        <p class="btn3"><input class="form_box_sub form_box_sub_2" type="submit" value="保存"></p>
      </div>
    </ValidationObserver>
  </div>
</template>
<script>
  import {extend} from "vee-validate";
  import {mapActions, mapMutations, mapState} from 'vuex'

  export default {
    data() {
      return {
        is_preview: false,
        submit: false
      }
    },
    methods: {
      async previewRegister() {
        this.submit = true
        const isValid = await this.$refs.observer.validate();
        if (!isValid) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.observer.errors)
              .map(([key, value]) => ({key, value}))
              .filter(error => error["value"].length);
            // console.log([errors[0]["key"]])
            this.$refs.observer.refs[errors[0]["key"]].$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 100);
          return
        } else {
          this.$wait.start('loading')
          this.$store.dispatch('consultantUser/CREATE_SALES_USER', this.sale).then(() => {
            this.$router.push({
              name: 'SalesList'
            })
            this.$wait.end('loading')
          })
        }
      },
      async isUsernameEmail() {
        try {
          if (this.$route.params.id) {
            let {data} = await axios.get('/api/admin/sales/unique/'+this.sale.email+'/'+this.$route.params.id);
          } else {
            let {data} = await axios.get('/api/admin/sales/unique/'+this.sale.email);
          }
          return true
        } catch (err) {
          return false;
        }
      },
      async isUniqueID() {
        try {
          if (this.$route.params.id) {
            let {data} = await axios.get('/api/admin/sales/unique-id/'+this.sale.id_login+'/'+this.$route.params.id);
          } else {
            let {data} = await axios.get('/api/admin/sales/unique-id/'+this.sale.id_login);
          }

          return true
        } catch (err) {
          return false;
        }
      },
      ...mapMutations('consultantUser', ['SET_SALES']),
      ...mapActions('consultantUser', ['GET_SALES_USER', 'SET_SALES_DETAULT']),
      backList() {
        this.$router.push({
          name: 'SalesList'
        })
      }
    },
    computed: {
      ...mapState('consultantUser', ['sale', 'user'])
    },
    watch: {
      user() {
        // if (this.user.id && (_.isNull(this.user.role) || (!_.isNull(this.user.role) && !this.user.role.is_edit_c_user))) {
        //   this.$router.push({
        //     name: 'SalesList'
        //   })
        // }
      }
    },
    created() {
      // if (this.user && this.user.id && ((_.isEmpty(this.user.role) || (!_.isEmpty(this.user.role) && !this.user.role.is_edit_c_user)))) {
      //   this.$router.push({
      //     name: 'SalesList'
      //   })
      // }

      if (this.$route.params && this.$route.params.id !== undefined) {
        this.GET_SALES_USER(this.$route.params.id).then((data) => {
          if (_.isUndefined(this.sale.id)) {
            this.$router.push({
              name: 'SalesCreate'
            })
          }
        }).catch(e => {
          this.$router.push({
            name: 'SalesCreate'
          })
        });
      } else {
        this.SET_SALES_DETAULT({});
      }
    },
    mounted() {
      extend("uniqueEmail", {
        validate: this.isUsernameEmail,
        message: "このメールは存在しました。"
      });
      extend("uniqueID", {
        validate: this.isUniqueID,
        message: "このログインIDは存在しました。"
      });
    }
  }
</script>
<style scoped lang="scss">
</style>

