<template>
	<div id="body_2_2" :style="{'display': isOpenTuror ? '' : 'none'}">
		<div class="body_bg_2_2">
			<div class="ls-carousels content_2_2">
				<div class="content_bg_2_2">
					<div class="ls-carousel-item active">
						<p class="content_2_2_p">ようこそ。<br> ビジネスコンサルタントの診断申込へ。</p>
						<p class="content_2_2_img">
							<img src="/images/img2_2_1.png" alt="ようこそ。ビジネスコンサルタントの企業診断へ。" srcset="/images/img2_2_1.png 1x, /images/img2_2_1@2x.png 2x">
						</p>

					</div>

					<div class="ls-carousel-item">
						<p class="content_2_2_p">詳細情報<br> <span>これから段階的に3ステップの作業をしていただきます。</span></p>
						<p class="content_2_2_img">
							<img style="width: 577px" width="577" src="/images/img2_2_2x@2x.png" alt="詳細情報" srcset="/images/img2_2_2x@2x.png 1x, /images/img2_2_2x@2x.png 2x">
						</p>
					</div>

					<div class="ls-carousel-item">
						<p class="content_2_2_p">途中でログアウトしても大丈夫。</p>
						<p class="content_2_2_p2">一旦、ログアウトしても再ログインすれば<br>ご自分のステップのステータスへ戻ってこられます。</p>
						<p class="content_2_2_3_img">
							<img src="/images/img2_2_3.png" alt="途中でログアウトしても大丈夫。" srcset="/images/img2_2_3.png 1x, /images/img2_2_3@2x.png 2x">
						</p>
					</div>

					<div class="ls-carousel-item">
						<p class="content_2_2_p">対象者リスト提出締切日をご確認ください。</p>
						<p class="content_2_2_p2">フォーム上で「回答開始日時」をご指定いただくと<br>対象者リストのアップロード期日が決定いたします。</p>
						<p class="content_2_2_p3">※質問紙の場合は「質問紙到着希望日」が基準となります。</p>
						<p class="content_2_2_4_img">
							<img src="/images/img2_2_4.png" alt="途中でログアウトしても大丈夫。" srcset="/images/img2_2_4.png 1x, /images/img2_2_4@2x.png 2x">
						</p>
					</div>

					<p><a @click="close()">
						<img src="/images/close.png" alt="閉じる" srcset="/images/close.png 1x, /images/close@2x.png 2x" class="close pointer">
					</a></p>
					<p v-show="carousel > 0">
						<a @click="preCarousel()">
							<img src="/images/arrow_left.png" alt="前へ" srcset="/images/arrow_left.png 1x, images/arrow_left@2x.png 2x" class="arrow_left pointer">
						</a>
					</p>
					<p v-show="carousel < (carousel_total - 1)">
						<a @click="nextCarousel()">
							<img src="/images/arrow_right.png" alt="次へ" srcset="/images/arrow_right.png 1x, /images/arrow_right@2x.png 2x" class="arrow_right pointer">
						</a>
					</p>
					<div class="m_m">
						<div class="text-center">
							<template v-for="n in carousel_total">
								<a :key="n">
									<img @click="showCarousel(n - 1)" :src="(n - 1) == carousel ? '/images/blue_m.png' : '/images/gray_m.png'" :srcset="n == (carousel + 1) ? '/images/blue_m.png 1x, /images/blue_m@2x.png 2x' : '/images/gray_m.png 1x, /images/gray_m@2x.png 2x'">
								</a>
							</template>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
    export default {
        data() {
			return {
				carousel: 0,
				carousel_total: 0,
				isShow: false
			}
		},
			props: {
				isOpenTuror: {
					type: Boolean,
					default: false
				}
			},
		methods: {
			showCarousel(n) {
				this.carousel = n == undefined ? this.carousel : n;
				$('.ls-carousels .ls-carousel-item').removeClass('active').hide();
				$('.ls-carousels .ls-carousel-item:eq('+this.carousel+')').fadeIn('slow');
			},
			nextCarousel(){
				this.carousel = this.carousel == this.carousel_total ? this.carousel_total : this.carousel + 1;
				this.showCarousel();
			},
			preCarousel(){
				this.carousel = this.carousel == 0 ? 0 : this.carousel - 1;
				this.showCarousel();
			},
			close(){
				this.isShow = false
				this.$emit('openTutorial', false)
				// $('#body_2_2').hide();
			}
		},
		mounted () {
        	this.isShow = this.isOpenTuror
			this.carousel_total = $('.ls-carousels .ls-carousel-item').length;
		}
		}
</script>

<style lang="scss" scoped>
.ls-carousel-item{
	display: none;
}
.ls-carousel-item.active{
	display: block;
}
.content_2_2{
	margin-top: 0px;
	position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
#body_2_2{
	top: 0px;
	width: 100%;
	height: 100vh;
	position: fixed;
	z-index: 100;
}

.body_bg_2_2{
	padding: 15px;
	height: 100vh;
	position: relative;
}
.content_2_2_p3 {
    font-size: 12px !important;
}
.m_m{
	margin: 0px;
    position: absolute;
    width: 100%;
    bottom: 32px;
}
.m_m img {
    margin-left: 20px;
    padding: 5px;
	cursor: pointer;
}
.content_bg_2_2{
	position: relative;
	height: 100%;
}
.pointer{
	cursor: pointer;
}
</style>
