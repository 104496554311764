<template>
	<div>
		<div id="body_1_2" v-if="errors.length === 0">
			<div class="top_box_4_2">
				<div class="content_box_4_2">
					<p>以下のファイルをアップロードしてよろしいですか？</p>
					<p class="content_img_box_4_2">
						<img src="images/up_exel.png" alt="アップロードファイル" srcset="images/up_exel.png 1x, images/up_exel@2x.png 2x">
					</p>
					<p class="content_box_4_2_p" v-html="file_name"></p>
				</div>
			</div>

			<div class="footer_4_2" v-if="(project.project_type >=1 && project.project_type <=6) || (project.project_type >=11 && project.project_type <=16 && project.attribute_setting_type == 1)">
				<p class="btn_1">
					<input v-if="project.project_type >=1 && project.project_type <=6" class="form_box_sub form_box_sub_4_2" @click="uploadPPT()" type="submit" value="ファイルを送信する">
					<input v-else class="form_box_sub form_box_sub_4_2" @click="uploadPTP()" type="submit" value="ファイルを送信する">
				</p>
				<p class="btn_2">
					<input v-if="project.project_type >=1 && project.project_type <=6"  type="file" ref="file" @change="reUploadPTP()" id="02" name="03">
					<input v-else  type="file" ref="file" @change="reUploadPPT()" id="02" name="03">
					<label for="02" id="input-label">ファイルを変更する</label>
				</p>
			</div>

			<div class="footer_4_2" v-if="project.project_type >=11 && project.project_type <=16 && project.attribute_setting_type != 1">
				<p class="btn_1">
					<input class="form_box_sub form_box_sub_4_2" @click="project.progress_status == 2.1 ? uploadPTP() : upload()" type="submit" value="ファイルを送信する">
				</p>
				<p class="btn_2">
					<input type="file" ref="file" @change="project.progress_status == 2.1 ? reUploadPTP() : reUpload()" id="02" name="03"><label for="02" id="input-label">ファイルを変更する</label>
				</p>
			</div>

			<div class="footer_4_2" v-if="project.project_type >=21 && project.project_type <=24">
				<p class="btn_1">
					<input class="form_box_sub form_box_sub_4_2" @click="uploadPMF()" type="submit" value="ファイルを送信する">
				</p>
				<p class="btn_2">
					<input type="file" ref="file" @change="reUploadPMF()" id="02" name="03"><label for="02" id="input-label">ファイルを変更する</label>
				</p>
			</div>
		</div>
		<div v-else>
			<div class="top_box_4_2">
				<div class="content_box_4_2 content_box_4_3">
					<p>ファイルに以下のエラーが見られました。<br>
						ご確認の上、再アップロードをお願いします。</p>
					<div class="attention_box" v-for="error in errors">
						<p class="attention">{{ error }}</p>
					</div>
				</div>
			</div>

			<div class="footer_4_2" v-if="project.project_type >=11 && project.project_type <=16 && project.attribute_setting_type != 1">
				<p class="btn btn_2_5 btn_3_3 btn_4_1 btn_4_3">
					<input type="file" ref="file2" @change="project.progress_status == 2.1 ? reUploadPTP() : reUpload()" id="03" name="03">
					<label for="03">ファイルを再アップロード</label></p>
				<p class="footer_box_4_1_u footer_box_4_3_u">アップロード期日 {{deadlineSubmit}}</p>
			</div>

			<div class="footer_4_2" v-if="project.project_type >=21 && project.project_type <=24">
				<p class="btn btn_2_5 btn_3_3 btn_4_1 btn_4_3">
					<input type="file" ref="filePPT" @change="reUploadPMF()" id="03" name="03">
					<label for="03">ファイルを再アップロード</label></p>
				<p class="footer_box_4_1_u footer_box_4_3_u">アップロード期日 {{deadlineSubmit}}</p>
			</div>

			<div class="footer_4_2" v-if="(project.project_type >=1 && project.project_type <=6) || (project.project_type >=11 && project.project_type <=16 && project.attribute_setting_type == 1)">
				<p class="btn btn_2_5 btn_3_3 btn_4_1 btn_4_3">
					<input type="file" ref="filePPT" @change="reUploadPPT()" id="03" name="03">
					<label for="03">ファイルを再アップロード</label></p>
				<p class="footer_box_4_1_u footer_box_4_3_u">アップロード期日 {{deadlineSubmit}}</p>
			</div>
		</div>
	</div>
</template>
<script>
	import {mapState} from 'vuex'
	import moment from 'moment'

	export default {
    data() {
      return {
				// link_upload_attr: '/api/admin/p-attribute-info/',
				link_upload: '/api/admin/pai-import',
				link_upload_ptp: '/api/admin/ptp-import',
				link_upload_pmf: '/api/admin/pmf-import',
				link_upload_ppt: '/api/admin/ppt-import',
    		file_name: '',
				errors: [],
				deadlineSubmit: null
      }
    },
    watch:{
			file_uploaded() {
				if (this.file_uploaded && this.file_uploaded.name) {
					this.file_name = this.file_uploaded.name;
				}
			},
			'project.web_start': function() {
				this.getDeadlineSubmit()
			},
			'holidays': function () {
				this.getDeadlineSubmit()
			}
    },
    computed: {
    	...mapState('consultantUser', ['project', 'holidays']),
    	...mapState('uploadFile', ['file_uploaded']),
		},
		methods: {
			upload() {
				this.$wait.start('loading')
				this.$store.dispatch('uploadFile/UPLOAD', {url: this.link_upload}).then((rs) => {
					this.$store.dispatch('uploadFile/FILE_UPLOADED', null);
					if (rs.errors && rs.errors.length > 0) {
						this.errors = rs.errors
					} else {
						if (this.project.progress_status == 3.0) {
							this.$router.push({ name: 'apply-completed' });
						} else {
							this.$store.dispatch('project/NEW_STEP', 2.1);
						}
					}
					this.$wait.end('loading')
				});
			},
			reUpload() {
				this.$store.dispatch('uploadFile/FILE_UPLOADED', this.$refs.file2.files[0]);
				this.$wait.start('loading')
				if (this.$refs.file2.files[0]) {
					this.$store.dispatch('uploadFile/UPLOAD', {url: this.link_upload}).then((rs) => {
						if (this.$refs.file2) {
							this.$refs.file2.value = null
						}

						this.$store.dispatch('uploadFile/FILE_UPLOADED', null);
						if (rs.errors && rs.errors.length > 0) {
							this.errors = rs.errors
						} else {
							this.$store.dispatch('project/NEW_STEP', 2.1);
						}
						this.$wait.end('loading')
					});
				}

			},
			uploadPTP() {
				this.$wait.start('loading')
				this.$store.dispatch('uploadFile/UPLOAD', {url: this.link_upload_ptp}).then((rs) => {
					this.$store.dispatch('uploadFile/FILE_UPLOADED', null);
					if (rs.errors && rs.errors.length > 0) {
						this.errors = rs.errors
					} else {
						if (this.$route.name !== 'appy-completed')
						this.$router.push({ name: 'apply-completed' });
					}
					this.$wait.end('loading')
				});
			},
			reUploadPTP() {
				this.$store.dispatch('uploadFile/FILE_UPLOADED', this.$refs.file2.files[0]);
				this.$wait.start('loading')
				if (this.$refs.file2.files[0]) {
					this.$store.dispatch('uploadFile/UPLOAD', {url: this.link_upload_ptp}).then((rs) => {
						if (this.$refs.file2) {
							this.$refs.file2.value = null
						}

						this.$store.dispatch('uploadFile/FILE_UPLOADED', null);
						if (rs.errors && rs.errors.length > 0) {
							this.errors = rs.errors
						} else {
							if (this.$route.name !== 'appy-completed')
								this.$router.push({ name: 'apply-completed' });
						}
						this.$wait.end('loading')
					});
				}
			},
			uploadPPT() {
				this.$wait.start('loading')
				this.$store.dispatch('uploadFile/UPLOAD', {url: this.link_upload_ppt}).then((rs) => {
					this.$store.dispatch('uploadFile/FILE_UPLOADED', null);
					if (rs.errors && rs.errors.length > 0) {
						this.errors = rs.errors
					} else {
						if (this.$route.name !== 'appy-completed')
							this.$router.push({name: 'apply-completed'});
					}
					this.$wait.end('loading')
				});
			},
			reUploadPPT() {
				this.$store.dispatch('uploadFile/FILE_UPLOADED', this.$refs.filePPT.files[0]);
				this.$wait.start('loading')
				this.$store.dispatch('uploadFile/UPLOAD', {url: this.link_upload_ppt}).then((rs) => {
					this.$refs.filePPT.value = null
					this.$store.dispatch('uploadFile/FILE_UPLOADED', null);
					if (rs.errors && rs.errors.length > 0) {
						this.errors = rs.errors
					} else {
						if (this.$route.name !== 'appy-completed')
							this.$router.push({name: 'apply-completed'});
					}
					this.$wait.end('loading')
				});
			},

			uploadPMF() {
				this.$wait.start('loading')
				this.$store.dispatch('uploadFile/UPLOAD', {url: this.link_upload_pmf}).then((rs) => {
					this.$store.dispatch('uploadFile/FILE_UPLOADED', null);
					if (rs.errors && rs.errors.length > 0) {
						this.errors = rs.errors
					} else {
						if (this.$route.name !== 'appy-completed')
							this.$router.push({name: 'apply-completed'});
					}
					this.$wait.end('loading')
				});
			},
			reUploadPMF() {
				this.$store.dispatch('uploadFile/FILE_UPLOADED', this.$refs.filePPT.files[0]);
				this.$wait.start('loading')
				this.$store.dispatch('uploadFile/UPLOAD', {url: this.link_upload_pmf}).then((rs) => {
					if (this.$refs.filePPT !== undefined) {
						this.$refs.filePPT.value = null
					}

					this.$store.dispatch('uploadFile/FILE_UPLOADED', null);
					if (rs.errors && rs.errors.length > 0) {
						this.errors = rs.errors
					} else {
						if (this.$route.name !== 'appy-completed')
							this.$router.push({name: 'apply-completed'});
					}
					this.$wait.end('loading')
				});
			},
			getDates(startDate, stopDate) {
				var dateArray = [];
				var currentDate = moment(startDate);
				var stopDate = moment(stopDate);
				while (currentDate <= stopDate) {
					dateArray.push( moment(currentDate).format('YYYY-MM-DD') )
					currentDate = moment(currentDate).add(1, 'days');
				}
				return dateArray;
			},
			getDeadlineSubmit() {
				console.log('=============')
				let fiveDate = this.project.web_start
				let fiveDate2 = null
				if (this.project.web_start) {
					let days = this.getDates(moment(this.project.web_start).subtract(30, 'days').format('YYYY-MM-DD'), moment(this.project.web_start).subtract(1, 'days').format('YYYY-MM-DD'))
					days.sort(function (a, b) {
						return new Date(b) - new Date(a);
					});
					let i = 1
					_.forEach(days, (d) => {
						if (!this.holidays.includes(d) && i <= 5) {
							fiveDate = d
							i = i + 1
						}
					})

					fiveDate = moment(fiveDate).subtract(1, 'days').format('YYYY-MM-DD')
					while (this.holidays.includes(fiveDate)) {
						fiveDate = moment(fiveDate).subtract(1, 'days').format('YYYY-MM-DD')
					}
				}
				if (this.project.medium_type == 2 || this.project.medium_type == 3) {
					let infDate = this.project.paper_preferred_date

					if (infDate) {
						let paper_people = this.project.paper_people
						let date_medium2 = ''
						switch (true) {
							case (paper_people > 0 && paper_people <= 100):
								date_medium2 = 7
								break
							case (paper_people > 100 && paper_people <= 200):
								date_medium2 = 8
								break
							case (paper_people > 200 && paper_people <= 300):
								date_medium2 = 9
								break
							case (paper_people > 300 && paper_people <= 400):
								date_medium2 = 10
								break
							case (paper_people > 400 && paper_people <= 500):
								date_medium2 = 11
								break
							case (paper_people > 500):
								date_medium2 = 12
								break
							default:
								break
						}
						let days2 = this.getDates(moment(infDate).subtract(30, 'days').format('YYYY-MM-DD'), infDate)
						days2.sort(function(a,b){
							return new Date(b) - new Date(a);
						});
						let i = 0
						_.forEach(days2, (d) => {
							if (!this.holidays.includes(d) && i <= date_medium2) {
								fiveDate2 = d
								i = i + 1
							}
						})
					}
				}

				let deadline = ''
				if (moment(fiveDate).isValid() && moment(fiveDate2).isValid()) {
					if (fiveDate < fiveDate2) {
						deadline = fiveDate
					} else {
						deadline = fiveDate2
					}
				} else if (!moment(fiveDate).isValid() && moment(fiveDate2).isValid()) {
					deadline = fiveDate2
				} else if (moment(fiveDate).isValid() &&  !moment(fiveDate2).isValid()) {
					deadline = fiveDate
				}
				this.deadlineSubmit = moment(deadline).isValid() ? moment(deadline).format('YYYY年MM月DD日') : ''

			},
		},
		mounted() {
    	this.getDeadlineSubmit()
		}
	}
</script>

<style scoped lang="scss">
	.cur_pointer{
		cursor: pointer;
	}
</style>
