var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("header-component", {
        attrs: { margin: true, title: _vm.projectType() }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.projectInfo && _vm.projectInfo.id,
              expression: "projectInfo && projectInfo.id"
            }
          ]
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          !_vm.isConfirm
            ? _c("div", [
                _c("div", { staticClass: "top_box_4_1" }, [
                  _c("div", { staticClass: "top_box_child_4_1" }, [
                    _c("p", [
                      _vm._v("アップロードが必要な資料は以下になります。")
                    ]),
                    _vm._v(" "),
                    (_vm.projectInfo.project_type >= 1 &&
                      _vm.projectInfo.project_type <= 6) ||
                    (_vm.projectInfo.project_type >= 11 &&
                      _vm.projectInfo.project_type <= 16 &&
                      _vm.projectInfo.attribute_setting_type == 1)
                      ? _c("div", [_vm._m(2)])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.projectInfo.project_type >= 11 &&
                    _vm.projectInfo.project_type <= 16 &&
                    _vm.projectInfo.attribute_setting_type != 1
                      ? _c("div", [
                          _vm.newStep == "2.1"
                            ? _c("div", { staticClass: "fx_se" }, [
                                _vm._m(3),
                                _vm._v(" "),
                                _vm._m(4)
                              ])
                            : _c("div", { staticClass: "fx_se" }, [
                                _vm._m(5),
                                _vm._v(" "),
                                _vm._m(6)
                              ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.projectInfo.project_type >= 21 &&
                    _vm.projectInfo.project_type <= 24
                      ? _c("div", [_vm._m(7)])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _vm.projectInfo.project_type >= 11 &&
                  _vm.projectInfo.project_type <= 16 &&
                  _vm.projectInfo.attribute_setting_type != 1
                    ? _c("p", { staticClass: "top_box_4_1_p" }, [
                        _vm._v(
                          "「" +
                            _vm._s(
                              _vm.newStep == 2.1
                                ? "対象者リスト"
                                : "属性一覧の設定"
                            ) +
                            "」を入力→アップロードしてください"
                        )
                      ])
                    : _c("p", { staticClass: "top_box_4_1_p" }, [
                        _vm._v(
                          "「対象者リスト」を入力→アップロードしてください"
                        )
                      ]),
                  _vm._v(" "),
                  _vm._m(8)
                ]),
                _vm._v(" "),
                (_vm.projectInfo.project_type >= 1 &&
                  _vm.projectInfo.project_type <= 6) ||
                (_vm.projectInfo.project_type >= 11 &&
                  _vm.projectInfo.project_type <= 16 &&
                  _vm.projectInfo.attribute_setting_type == 1)
                  ? _c("div", { staticClass: "footer_box_4_1" }, [
                      _c("div", { staticClass: "fx_se" }, [
                        _c("div", [
                          _c(
                            "p",
                            {
                              staticClass:
                                "btn-s btn_2_5 btn_3_3 btn_4_1 btn_4_1_blue cur_pointer"
                            },
                            [
                              _vm.projectInfo.project_type >= 1 &&
                              _vm.projectInfo.project_type <= 6
                                ? _c(
                                    "a",
                                    {
                                      attrs: {
                                        download: "",
                                        href:
                                          "/files/excels/個人診断_対象者リスト.xlsx"
                                      }
                                    },
                                    [
                                      _vm._v("「対象者リスト」フォーマットを"),
                                      _c("br"),
                                      _vm._v("ダウンロード")
                                    ]
                                  )
                                : _c(
                                    "a",
                                    {
                                      attrs: {
                                        download: "",
                                        href:
                                          _vm.link_download_ptp +
                                          _vm.projectInfo.id
                                      }
                                    },
                                    [
                                      _vm._v("「対象者リスト」フォーマットを"),
                                      _c("br"),
                                      _vm._v("ダウンロード")
                                    ]
                                  )
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "footer_box_4_1_d" }, [
                            _c(
                              "a",
                              {
                                attrs: { href: "javascript:;" },
                                on: {
                                  click: function($event) {
                                    return _vm.openTutorial()
                                  }
                                }
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: "/images/file_d_2.png",
                                    alt: "入力の仕方を見る",
                                    srcset:
                                      "/images/file_d_2.png" +
                                      " 1x, " +
                                      "/images/file_d_2@2x.png" +
                                      " 2x"
                                  }
                                }),
                                _vm._v("\n                入力の仕方を見る")
                              ]
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "p",
                            {
                              staticClass:
                                "btn-s btn_2_5 btn_3_3 btn_4_1 cur_pointer"
                            },
                            [
                              _c("input", {
                                ref: "file",
                                attrs: {
                                  type: "file",
                                  id: "input-invidual",
                                  name: "02"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.uploadPTP()
                                  }
                                }
                              }),
                              _vm._m(9)
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "footer_box_4_1_u" }, [
                            _vm._v(
                              "アップロード期日 " +
                                _vm._s(
                                  _vm._f("dateFormat")(_vm.deadlineSubmit)
                                ) +
                                " "
                            )
                          ]),
                          _vm._v(" "),
                          _vm._m(10)
                        ])
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.projectInfo.project_type >= 21 &&
                _vm.projectInfo.project_type <= 24
                  ? _c("div", { staticClass: "footer_box_4_1" }, [
                      _c("div", { staticClass: "fx_se" }, [
                        _c("div", [
                          _c(
                            "p",
                            {
                              staticClass:
                                "btn-s btn_2_5 btn_3_3 btn_4_1 btn_4_1_blue cur_pointer"
                            },
                            [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    download: "",
                                    href:
                                      "/files/excels/多面診断_多面組合せ表.xlsx"
                                  }
                                },
                                [
                                  _vm._v("「対象者リスト」フォーマットを"),
                                  _c("br"),
                                  _vm._v("ダウンロード")
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "footer_box_4_1_d" }, [
                            _c(
                              "a",
                              {
                                attrs: { href: "javascript:;" },
                                on: {
                                  click: function($event) {
                                    return _vm.openTutorial()
                                  }
                                }
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: "/images/file_d_2.png",
                                    alt: "入力の仕方を見る",
                                    srcset:
                                      "/images/file_d_2.png" +
                                      " 1x, " +
                                      "/images/file_d_2@2x.png" +
                                      " 2x"
                                  }
                                }),
                                _vm._v("\n                入力の仕方を見る")
                              ]
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "p",
                            {
                              staticClass:
                                "btn-s btn_2_5 btn_3_3 btn_4_1 cur_pointer"
                            },
                            [
                              _c("input", {
                                ref: "file",
                                attrs: { type: "file", id: "02", name: "02" },
                                on: {
                                  change: function($event) {
                                    return _vm.uploadPMF()
                                  }
                                }
                              }),
                              _vm._m(11)
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "footer_box_4_1_u" }, [
                            _vm._v(
                              "アップロード期日 " +
                                _vm._s(
                                  _vm._f("dateFormat")(_vm.deadlineSubmit)
                                ) +
                                " "
                            )
                          ]),
                          _vm._v(" "),
                          _vm._m(12)
                        ])
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.projectInfo.project_type >= 11 &&
                _vm.projectInfo.project_type <= 16 &&
                _vm.projectInfo.attribute_setting_type != 1
                  ? _c("div", { staticClass: "footer_box_4_1" }, [
                      _c("div", { staticClass: "fx_se" }, [
                        _c("div", [
                          _c(
                            "p",
                            {
                              staticClass:
                                "btn-s btn_2_5 btn_3_3 btn_4_1 btn_4_1_blue cur_pointer"
                            },
                            [
                              _vm.newStep == 2.1
                                ? _c(
                                    "a",
                                    {
                                      attrs: {
                                        download: "",
                                        href:
                                          _vm.link_download_ptp +
                                          _vm.projectInfo.id
                                      }
                                    },
                                    [
                                      _vm._v("「対象者リスト」フォーマットを"),
                                      _c("br"),
                                      _vm._v("ダウンロード")
                                    ]
                                  )
                                : _c(
                                    "a",
                                    {
                                      attrs: {
                                        download: "",
                                        href: _vm.link_download_pai
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "「属性一覧の設定」フォーマットを"
                                      ),
                                      _c("br"),
                                      _vm._v("ダウンロード")
                                    ]
                                  )
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "footer_box_4_1_d" }, [
                            _c(
                              "a",
                              {
                                attrs: { href: "javascript:;" },
                                on: {
                                  click: function($event) {
                                    return _vm.openTutorial()
                                  }
                                }
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: "/images/file_d_2.png",
                                    alt: "入力の仕方を見る",
                                    srcset:
                                      "/images/file_d_2.png" +
                                      " 1x, " +
                                      "/images/file_d_2@2x.png" +
                                      " 2x"
                                  }
                                }),
                                _vm._v("\n                入力の仕方を見る")
                              ]
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "p",
                            {
                              staticClass:
                                "btn-s btn_2_5 btn_3_3 btn_4_1 cur_pointer"
                            },
                            [
                              _c("input", {
                                ref: "file",
                                attrs: { type: "file", id: "02", name: "02" },
                                on: {
                                  change: function($event) {
                                    _vm.newStep == 2.1
                                      ? _vm.uploadPTP()
                                      : _vm.upload()
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _vm.newStep == 2.1
                                ? _c(
                                    "label",
                                    {
                                      staticClass: "cur_pointer",
                                      attrs: { for: "02", id: "input-label" }
                                    },
                                    [
                                      _vm._v("「対象者リスト」ファイルを"),
                                      _c("br"),
                                      _vm._v("アップロード")
                                    ]
                                  )
                                : _c(
                                    "label",
                                    {
                                      staticClass: "cur_pointer",
                                      attrs: { for: "02", id: "input-label" }
                                    },
                                    [
                                      _vm._v("「属性一覧の設定」ファイルを"),
                                      _c("br"),
                                      _vm._v("アップロード")
                                    ]
                                  )
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "footer_box_4_1_u" }, [
                            _vm._v(
                              "アップロード期日 " +
                                _vm._s(
                                  _vm._f("dateFormat")(_vm.deadlineSubmit)
                                ) +
                                " "
                            )
                          ]),
                          _vm._v(" "),
                          _vm._m(13)
                        ])
                      ])
                    ])
                  : _vm._e()
              ])
            : _c("file-confirm", { on: { confirm: _vm.confirm } }),
          _vm._v(" "),
          _c(
            "div",
            {
              style: { display: _vm.isOpenTuror ? "" : "none" },
              attrs: { id: "body_2_2" }
            },
            [
              _c("div", { staticClass: "body_bg_2_2" }, [
                _c("div", { staticClass: "ls-carousels content_2_2" }, [
                  _c("div", { staticClass: "content_bg_2_2" }, [
                    _vm._m(14),
                    _vm._v(" "),
                    _vm._m(15),
                    _vm._v(" "),
                    _vm._m(16),
                    _vm._v(" "),
                    _vm._m(17),
                    _vm._v(" "),
                    _c("p", [
                      _c(
                        "a",
                        {
                          on: {
                            click: function($event) {
                              return _vm.close()
                            }
                          }
                        },
                        [
                          _c("img", {
                            staticClass: "close pointer",
                            attrs: {
                              src: "/images/close.png",
                              alt: "閉じる",
                              srcset:
                                "/images/close.png" +
                                " 1x, " +
                                "/images/close@2x.png" +
                                " 2x"
                            }
                          })
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.carousel > 0,
                            expression: "carousel > 0"
                          }
                        ]
                      },
                      [
                        _c(
                          "a",
                          {
                            on: {
                              click: function($event) {
                                return _vm.preCarousel()
                              }
                            }
                          },
                          [
                            _c("img", {
                              staticClass: "arrow_left pointer",
                              attrs: {
                                src: "/images/arrow_left.png",
                                alt: "前へ",
                                srcset:
                                  "/images/arrow_left.png" +
                                  " 1x, " +
                                  "images/arrow_left@2x.png" +
                                  " 2x"
                              }
                            })
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.carousel < _vm.carousel_total - 1,
                            expression: "carousel < (carousel_total - 1)"
                          }
                        ]
                      },
                      [
                        _c(
                          "a",
                          {
                            on: {
                              click: function($event) {
                                return _vm.nextCarousel()
                              }
                            }
                          },
                          [
                            _c("img", {
                              staticClass: "arrow_right pointer",
                              attrs: {
                                src: "/images/arrow_right.png",
                                alt: "次へ",
                                srcset:
                                  "/images/arrow_right.png" +
                                  " 1x, " +
                                  "/images/arrow_right@2x.png" +
                                  " 2x"
                              }
                            })
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "m_m" }, [
                      _c(
                        "div",
                        { staticClass: "text-center" },
                        [
                          _vm._l(_vm.carousel_total, function(n) {
                            return [
                              _c("a", { key: n }, [
                                _c("img", {
                                  attrs: {
                                    src:
                                      n - 1 == _vm.carousel
                                        ? "/images/blue_m.png"
                                        : "/images/gray_m.png",
                                    srcset:
                                      n == _vm.carousel + 1
                                        ? "/images/blue_m.png 1x, /images/blue_m@2x.png 2x"
                                        : "/images/gray_m.png 1x, /images/gray_m@2x.png 2x"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.showCarousel(n - 1)
                                    }
                                  }
                                })
                              ])
                            ]
                          })
                        ],
                        2
                      )
                    ])
                  ])
                ])
              ])
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "top_contact fx_cece" }, [
      _c("div", { staticClass: "top_con1" }, [
        _c("img", {
          attrs: {
            src: "/images/top_qu.png",
            srcset:
              "/images/top_qu.png" + " 1x, " + "/images/top_qu@2x.png" + " 2x",
            alt: "お問合せ先"
          }
        }),
        _c("br"),
        _vm._v("お問合せ先\n      ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "top_con2" }, [
        _c("p", [
          _vm._v(
            "不明点や確認事項がありましたら担当営業もしくはこちらまでお問い合わせください。"
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "fx_ce" }, [
          _c("p", { staticClass: "top_con2_p border_left" }, [
            _c("img", {
              attrs: {
                src: "/images/top_mail.png",
                srcset:
                  "/images/top_mail.png" +
                  " 1x, " +
                  "/images/top_mail@2x.png" +
                  " 2x",
                alt: "メールでのお問合せ"
              }
            }),
            _vm._v("メールでのお問い合わせ"),
            _c("br"),
            _c("span", { staticClass: "span_color" }, [
              _c("a", { attrs: { href: "mailto:survey-rcptn@info.bcon.jp" } }, [
                _vm._v("survey-rcptn@info.bcon.jp")
              ])
            ])
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "top_con2_p" }, [
            _c("img", {
              attrs: {
                src: "/images/top_tel.png",
                srcset:
                  "/images/top_tel.png" +
                  " 1x, " +
                  "/images/top_tel@2x.png" +
                  " 2x",
                alt: "お電話でのお問合せ"
              }
            }),
            _vm._v("電話でのお問い合わせ"),
            _c("br"),
            _c("span", [_vm._v("03-3287-3416(平日 10:00～17:00)")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "step_bar_box" } }, [
      _c("ol", { staticClass: "step_bar" }, [
        _c("li", [_vm._v("STEP1 診断申込 登録")]),
        _vm._v(" "),
        _c("li", [_vm._v("STEP2 詳細情報 登録")]),
        _vm._v(" "),
        _c("li", { staticClass: "current" }, [
          _vm._v("STEP3 対象者リストアップロード")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "fx_se" }, [
      _c("div", [
        _c("div", { staticClass: "top_img_box_child_4_1" }, [
          _c("img", {
            attrs: {
              src: "/images/atari2.jpg",
              alt: "対象者リスト画像",
              width: "358",
              height: "199"
            }
          })
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "top_box_child_4_1_p" }, [
          _vm._v("対象者リスト")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "top_img_box_child_4_4 fin_up" }, [
        _c("img", {
          staticClass: "top_4_4_o",
          attrs: {
            src: "/images/atari1.jpg",
            alt: "区分コード画像",
            width: "352",
            height: "155"
          }
        })
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "top_box_child_4_1_p top_4_4_o" }, [
        _vm._v("①属性一覧の設定")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "top_img_box_child_4_1" }, [
        _c("img", {
          attrs: {
            src: "/images/atari2.jpg",
            alt: "対象者リスト画像",
            width: "358",
            height: "199"
          }
        })
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "top_box_child_4_1_p" }, [_vm._v("②対象者リスト")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "top_img_box_child_4_1" }, [
        _c("img", {
          attrs: {
            src: "/images/atari1.jpg",
            alt: "区分コード画像",
            width: "352",
            height: "155"
          }
        })
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "top_box_child_4_1_p" }, [
        _vm._v("①属性一覧の設定")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "top_img_box_child_4_1" }, [
        _c("img", {
          attrs: {
            src: "/images/atari2.jpg",
            alt: "対象者リスト画像",
            width: "358",
            height: "199"
          }
        })
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "top_box_child_4_1_p" }, [_vm._v("②対象者リスト")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "fx_se" }, [
      _c("div", [
        _c("div", { staticClass: "top_img_box_child_4_1" }, [
          _c("img", {
            attrs: {
              src: "/images/atari2.jpg",
              alt: "対象者リスト画像",
              width: "358",
              height: "199"
            }
          })
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "top_box_child_4_1_p" }, [
          _vm._v("対象者リスト")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "top_img_box_4_1 fx_se" }, [
      _c("div", [
        _c("p", [
          _c("img", {
            attrs: {
              src: "/images/up_1.png",
              alt: "フォーマットをダウンロード",
              srcset:
                "/images/up_1.png" + " 1x, " + "/images/up_1@2x.png" + " 2x"
            }
          })
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("フォーマットをダウンロード")])
      ]),
      _vm._v(" "),
      _c("div", [
        _c("p", { staticClass: "arrow" }, [
          _c("img", {
            attrs: {
              src: "/images/up_2.png",
              alt: "デスクトップで入力",
              srcset:
                "/images/up_2.png" + " 1x, " + "/images/up_2@2x.png" + " 2x"
            }
          })
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("デスクトップで入力")])
      ]),
      _vm._v(" "),
      _c("div", [
        _c("p", { staticClass: "arrow" }, [
          _c("img", {
            attrs: {
              src: "/images/up_3.png",
              alt: "ファイルをアップロード",
              srcset:
                "/images/up_3.png" + " 1x, " + "/images/up_3@2x.png" + " 2x"
            }
          })
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("ファイルをアップロード")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "cur_pointer", attrs: { for: "input-invidual" } },
      [_vm._v("「対象者リスト」ファイルを"), _c("br"), _vm._v("アップロード")]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "footer_box_4_1_u_2" }, [
      _vm._v("この画面を終了して、後でアップロードする場合"),
      _c("br"),
      _vm._v("は、再度ログインしてください。")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "cur_pointer", attrs: { for: "02", id: "input-label" } },
      [_vm._v("「対象者リスト」ファイルを"), _c("br"), _vm._v("アップロード")]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "footer_box_4_1_u_2" }, [
      _vm._v("この画面を終了して、後でアップロードする場合"),
      _c("br"),
      _vm._v("は、再度ログインしてください。")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "footer_box_4_1_u_2" }, [
      _vm._v("この画面を終了して、後でアップロードする場合"),
      _c("br"),
      _vm._v("は、再度ログインしてください。")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ls-carousel-item active" }, [
      _c("p", { staticClass: "content_2_2_p" }, [
        _vm._v("ようこそ。"),
        _c("br"),
        _vm._v(" ビジネスコンサルタントの診断申込へ。")
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "content_2_2_img" }, [
        _c("img", {
          attrs: {
            src: "/images/img2_2_1.png",
            alt: "ようこそ。ビジネスコンサルタントの企業診断へ。",
            srcset:
              "/images/img2_2_1.png" +
              " 1x, " +
              "/images/img2_2_1@2x.png" +
              " 2x"
          }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ls-carousel-item" }, [
      _c("p", { staticClass: "content_2_2_p" }, [
        _vm._v("詳細情報"),
        _c("br"),
        _vm._v(" "),
        _c("span", [
          _vm._v("これから段階的に3ステップの作業をしていただきます。")
        ])
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "content_2_2_img" }, [
        _c("img", {
          staticStyle: { width: "577px" },
          attrs: {
            width: "577",
            src: "/images/img2_2_2x@2x.png",
            alt: "詳細情報",
            srcset:
              "/images/img2_2_2x@2x.png" +
              " 1x, " +
              "/images/img2_2_2x@2x.png" +
              " 2x"
          }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ls-carousel-item" }, [
      _c("p", { staticClass: "content_2_2_p" }, [
        _vm._v("途中でログアウトしても大丈夫。")
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "content_2_2_p2" }, [
        _vm._v("一旦、ログアウトしても再ログインすれば"),
        _c("br"),
        _vm._v("ご自分のステップのステータスへ戻ってこられます。")
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "content_2_2_3_img" }, [
        _c("img", {
          attrs: {
            src: "/images/img2_2_3.png",
            alt: "途中でログアウトしても大丈夫。",
            srcset:
              "/images/img2_2_3.png" +
              " 1x, " +
              "/images/img2_2_3@2x.png" +
              " 2x"
          }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ls-carousel-item" }, [
      _c("p", { staticClass: "content_2_2_p" }, [
        _vm._v("対象者リスト提出締切日をご確認ください。")
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "content_2_2_p2" }, [
        _vm._v("フォーム上で「回答開始日時」をご指定いただくと"),
        _c("br"),
        _vm._v("対象者リストのアップロード期日が決定いたします。")
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "content_2_2_p3" }, [
        _vm._v("※質問紙の場合は「質問紙到着希望日」が基準となります。")
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "content_2_2_4_img" }, [
        _c("img", {
          attrs: {
            src: "/images/img2_2_4.png",
            alt: "途中でログアウトしても大丈夫。",
            srcset:
              "/images/img2_2_4.png" +
              " 1x, " +
              "/images/img2_2_4@2x.png" +
              " 2x"
          }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }