<template>
  <div class="container" v-show="projectInfo && projectInfo.id" id="body_1_2">
    <header-component :back="{name: 'ProjectList'}" :margin="true"></header-component>
    <div class="fx_between_2" id="top_box_6_2">
      <div class="fx">
        <h1 class="h1_6_2">申込No.{{projectInfo.id}}</h1>
        <p :class="'sta_6_2_' + (parseInt(projectInfo.progress_status) + 1)"
           v-if="projectInfo.progress_status"><span v-html="projectStatusNumber()"></span> {{ progressStatus()
          }}</p> <p class="user">{{ projectInfo.consultant_user && projectInfo.consultant_user.id ? projectInfo.consultant_user.name : '' }}</p>
      </div>
      <div class="fx">
        <p class="top_p_6_2"><input type="checkbox" name="is_deadline_disabled" :disabled="parseInt(projectInfo.progress_status) >= 4"
                                    id="is_deadline_disabled"
                                    v-model="projectInfo.is_deadline_disabled"
                                    @change="changeDeadlineDisabled()">
          <label for="is_deadline_disabled" :class="{'check_6_2': true, 'gray': parseInt(projectInfo.progress_status) >= 4 }">締切日を無効にする</label></p>
        <p class="top_p_6_2"><input :disabled="parseInt(projectInfo.progress_status) != 3" type="checkbox" name="progress_end" id="progress_end" v-model="progress_end" @change="endProject()"><label
          for="progress_end" :class="{'check_6_2': true, 'gray': parseInt(projectInfo.progress_status) != 3 }">完了にする</label></p>
        <p class="del_6_2"><a href="javascript:;" @click="showDelete()">削除</a></p>
      </div>
    </div>
    <div id="sta_box" class="fx_ce">
      <p class="sta_p">ステータス</p>
      <p :class="{'sta_6_2_1 m_right': true, 'sta_opacity': parseInt(projectInfo.progress_status) != 0}">①案件登録済</p>
      <p :class="{'sta_6_2_2 m_right': true, 'sta_opacity': parseInt(projectInfo.progress_status) != 1}">②申込済</p>
      <p :class="{'sta_6_2_3 m_right': true, 'sta_opacity': parseInt(projectInfo.progress_status) != 2}">③詳細情報登録済</p>
      <p :class="{'sta_6_2_4 m_right': true, 'sta_opacity': parseInt(projectInfo.progress_status) != 3}">④リスト登録済</p>
      <p :class="{'sta_6_2_5 m_right': true, 'sta_opacity': parseInt(projectInfo.progress_status) != 4}">⑤診断SG受理済</p>
    </div>
    <div class="attention">
      <p v-if="parseInt(projectInfo.progress_status) == 0">催促メール(開始日)　　　　<span v-if="projectInfo.preentry_alert_date">{{ projectInfo.preentry_alert_date }}</span></p>
      <p v-else>催促メール（リスト)　　　　<span v-if="projectInfo.postentry_alert_date">{{ projectInfo.postentry_alert_date }}</span></p>
    </div>

    <form action="" method="post">
      <div class="form_box form_box_1_3">
        <div class="form_box_title">
          <p>基本情報</p>
        </div>
        <dl>
          <dt>組織名(会社名)</dt>
          <dd>{{ projectInfo.client_name }}</dd>
        </dl>
        <dl>
          <dt>診断名</dt>
          <dd>{{ projectType(projectInfo.project_type)  }}</dd>
        </dl>
        <dl>
          <dt>担当営業名</dt>
          <dd>{{ projectInfo.staff_name }}</dd>
        </dl>
        <dl>
          <dt>共有先メールアドレス①</dt>
          <dd>{{ projectInfo.staff_email1 ? projectInfo.staff_email1 : '' }}</dd>
        </dl>
        <dl>
          <dt>共有先メールアドレス②</dt>
          <dd>{{ projectInfo.staff_email2 ? projectInfo.staff_email2 : '' }}</dd>
        </dl>
        <dl>
          <dt>備考</dt>
          <dd class="break-word">
            <div v-if="empty(projectInfo.memo) && projectInfo.memo.length > 0">
              <div v-for="(line, i) in projectInfo.memo.split('\n')">
                {{ line }} <br v-if="i < projectInfo.memo.split('\n').length - 1">
              </div>
            </div>
          </dd>
        </dl>
        <dl v-if="projectInfo.project_type == 11">
          <dt>経年比較の有無</dt>
          <dd>
            {{ projectInfo.is_comparison_age ? '有り' : (projectInfo.is_comparison_age == 0 ? '無し' : '') }}
          </dd>
        </dl>
      </div>
      <div class="form_box form_box_1_3">
        <div class="form_box_title">
          <p>お客様情報</p>
        </div>
        <!--<dl>-->
          <!--<dt>組織名(会社名)</dt>-->
          <!--<dd>{{ projectInfo.client_name }}</dd>-->
        <!--</dl>-->
        <dl>
          <dt>担当者　所属</dt>
          <dd>{{ projectInfo && projectInfo.user && projectInfo.user[0] ? projectInfo.user[0].division : '' }}　</dd>
        </dl>
        <dl>
          <dt>担当者　役職</dt>
          <dd>{{ projectInfo && projectInfo.user && projectInfo.user[0] ? projectInfo.user[0].position : '' }}</dd>
        </dl>
        <dl>
          <dt>担当者　氏名</dt>
          <dd>{{ projectInfo && projectInfo.user && projectInfo.user[0] ? projectInfo.user[0].name : '' }}</dd>
        </dl>
        <dl>
          <dt>担当者　メールアドレス</dt>
          <dd>
            <div class="fx_between_2" style="padding-right: 20px;">
              <p>{{ projectInfo && projectInfo.user && projectInfo.user[0] ? projectInfo.user[0].email : '' }}</p>
              <p class="ac_btn"><a href="javascript:;" @click="resendPassword()">アカウント再発行</a></p>
            </div>
          </dd>
        </dl>
        <dl>
          <dt>担当者2人目　氏名</dt>
          <dd>{{ projectInfo && projectInfo.user && projectInfo.user[1] ? projectInfo.user[1].name : '' }}</dd>
        </dl>
        <dl>
          <dt>担当者2人目　メールアドレス</dt>
          <dd>
            <div class="fx_between_2" style="padding-right: 20px;">
              <p>{{ projectInfo && projectInfo.user && projectInfo.user[1] ? projectInfo.user[1].email : '' }}</p>
            </div>
          </dd>
        </dl>
        <dl>
          <dt>電話番号</dt>
          <dd>
            {{ projectInfo.client_tel }}
          </dd>
        </dl>
        <dl class="form_box_1_3_dl">
          <dt>住所</dt>
          <dd>
            <p>〒{{ projectInfo.zip_code }}</p>
            <p>{{ projectInfo.address1 }}{{ projectInfo.address2 }}{{ projectInfo.address3 }}</p>
          </dd>
        </dl>
      </div>

      <div class="form_box form_box_1_3">
        <div class="form_box_title">
          <p>申込内容</p>
        </div>
        <dl>
          <dt width="25%">実施方法</dt>
          <dd width="75%">
            <label v-if="projectInfo.medium_type == 3">Web {{projectInfo.web_people}}名 + 質問紙 {{projectInfo.paper_people}}名</label>
            <label v-if="projectInfo.medium_type == 2">質問紙 {{projectInfo.paper_people}}名</label>
            <label v-if="projectInfo.medium_type == 1">Web {{projectInfo.web_people}}名</label>
          </dd>
        </dl>
        <dl v-if="((projectInfo.medium_type == 2 || projectInfo.medium_type == 3) && (projectInfo.project_type >= 11 && projectInfo.project_type <= 16))">
          <dt>質問紙の送付先拠点数</dt>
          <dd>
            <label v-if="projectInfo.paper_send_type == 1">1拠点</label>
            <label v-if="projectInfo.paper_send_type == 2">2拠点</label>
            <label v-if="projectInfo.paper_send_type == 3">3拠点以上</label>
            <!--{{ paper_send_types[projectInfo.paper_send_type] ?  paper_send_types[projectInfo.paper_send_type] : ''}}-->
          </dd>
        </dl>
        <dl v-if="projectInfo.paper_send_type == 1 || projectInfo.paper_send_type == 2">
          <dt>質問紙の送付先住所①</dt>
          <dd>
            <p>〒{{ projectInfo.paper1_zip_code }}</p>
            <p>{{ projectInfo.paper1_address1 }}{{ projectInfo.paper1_address2 }}{{ projectInfo.paper1_address3 }}</p>
            <p>{{ projectInfo.paper1_division }} {{ projectInfo.paper1_position }}</p>
            <p>{{ projectInfo.paper1_name }}</p>
          </dd>
        </dl>
        <dl v-if="(projectInfo.paper_send_type == 1 || projectInfo.paper_send_type == 2) && !((projectInfo.project_type >= 1 && projectInfo.project_type <= 6) || (projectInfo.project_type >= 21 && projectInfo.project_type <= 24))">
          <dt>質問紙の送付部数①</dt>
          <dd>{{ projectInfo.paper1_copies }}</dd>
        </dl>
        <dl v-if="projectInfo.paper_send_type == 2">
          <dt>質問紙の送付先住所②</dt>
          <dd>
            <p>〒{{ projectInfo.paper2_zip_code }}</p>
            <p>{{ projectInfo.paper2_address1 }}{{ projectInfo.paper2_address2 }}{{ projectInfo.paper2_address3 }}</p>
            <p>{{ projectInfo.paper2_division }} {{ projectInfo.paper2_position }}</p>
            <p>{{ projectInfo.paper2_name }}</p>
          </dd>
        </dl>
        <dl v-if="projectInfo.paper_send_type == 2">
          <dt>質問紙の送付部数②</dt>
          <dd>{{ project.paper2_copies }}</dd>
        </dl>
        <dl>
          <dt class="top">回答スケジュール</dt>
          <dd class="fx">
            <div class="form_box_2_4">
              <p id="margin_left_20" class="form_box_2_4_p form_box_2_4_2_p">
                <span>対象者リスト提出締切日　{{ deadlineSubmit}}</span>
              </p>
              <div v-if="projectInfo.medium_type == 1" class="form_box_360">
                <div class="margin_b_2_4 fx_ce row">
                  <p class="col col-140">Web診断開始日時</p>
                  <p class="col margin_l_2_4 stick_2_4">{{ projectInfo.web_start | dateFormat }}</p>
                </div>
                <div class="row margin_b_2_4 margin_b_2_3 fx_ce">
                  <p class="col-140">Web診断終了日時</p>
                  <p class="col margin_l_2_4">{{ projectInfo.web_end | dateFormat }}</p>
                </div>
              </div>
              <div v-if="projectInfo.medium_type == 2" class="form_box_360">
                <div class="margin_b_2_4 fx_ce row">
                  <p class="col col-140">質問紙到着希望日</p>
                  <p class="col margin_l_2_4 stick_2_4">{{ projectInfo.paper_preferred_date | dateFormat }}</p>
                </div>
                <div class="margin_b_2_4 fx_ce">
                  <p>質問紙提出期限(任意)</p>
                  <p class="margin_l_2_4 stick_2_4">{{ projectInfo.paper_limit_date | dateFormat }}</p>
                </div>
                <div class="margin_b_2_4 fx_ce row">
                  <p class="col col-140">質問紙BCon到着日</p>
                  <p class="col margin_l_2_4">{{ projectInfo.paper_bcon_receipt_date | dateFormat }}</p>
                </div>
              </div>
              <div v-if="projectInfo.medium_type == 3" class="form_box_360">
                <div class="margin_b_2_4 fx_ce row">
                  <p class="col col-140">Web診断開始日時</p>
                  <p class="col margin_l_2_4 stick_2_4">{{ projectInfo.web_start | dateFormat }}</p>
                </div>
                <div class="row margin_b_2_4 margin_b_2_3 fx_ce">
                  <p class="col-140">Web診断終了日時</p>
                  <p class="col margin_l_2_4">{{ projectInfo.web_end | dateFormat }}</p>
                </div>
                <div>
                  <hr>
                </div>
                <div class="margin_b_2_4 fx_ce row">
                  <p class="col col-140">質問紙到着希望日</p>
                  <p class="col margin_l_2_4 stick_2_4">{{ projectInfo.paper_preferred_date | dateFormat }}</p>
                </div>
                <div class="margin_b_2_4 fx_ce">
                  <p>質問紙提出期限(任意)</p>
                  <p class="margin_l_2_4 stick_2_4">{{ projectInfo.paper_limit_date | dateFormat }}</p>
                </div>
                <div class="margin_b_2_4 fx_ce row">
                  <p class="col col-140">質問紙BCon到着日</p>
                  <p class="col margin_l_2_4">{{ projectInfo.paper_bcon_receipt_date | dateFormat }}</p>
                </div>
              </div>
            </div>
          </dd>
        </dl>
        <dl>
          <dt>報告書納品希望日</dt>
          <dd>{{ projectInfo.report_preferred_date | dateFormat }}</dd>
        </dl>
        <dl class="form_box_1_3_dl">
          <dt>該当する業種・組織規模</dt>
          <dd>業種　　　：　{{ this.industry_types[projectInfo.industry_type] ? this.industry_types[projectInfo.industry_type]
            : '' }}<br>
            規模　　　：　{{ this.scale_types[projectInfo.scale_type] ? this.scale_types[projectInfo.scale_type] : '' }}
          </dd>
        </dl>
        <dl class="fx" v-if="projectInfo.project_type == 4 || projectInfo.project_type == 24">
          <dt class="">対象</dt>
          <dd>
            <div v-if="projectInfo.is_personnal_target1">内定者</div>
            <div v-if="projectInfo.is_personnal_target2">半年-5年未満</div>
            <div v-if="projectInfo.is_personnal_target3">監督職(主任・係長)</div>
            <div v-if="projectInfo.is_personnal_target4">役員</div>
            <div v-if="projectInfo.is_personnal_target5">新人</div>
            <div v-if="projectInfo.is_personnal_target6">5年以上</div>
            <div v-if="projectInfo.is_personnal_target7">課長・部長</div>
          </dd>
        </dl>
        <dl>
          <dt>備考欄</dt>
          <dd>
            <div v-if="projectInfo && projectInfo.client_memo && projectInfo.client_memo.length > 0">
              <div v-for="line in projectInfo.client_memo.split('\n')">{{ line }}
              </div>
            </div>
            <div v-else>なし</div>
          </dd>
        </dl>
        <!--<dl>-->
          <!--<dt>個人情報保管期間</dt>-->
          <!--<dd></dd>-->
        <!--</dl>-->
      </div>

      <div class="form_box form_box_1_3 form_box_5_2">
        <div class="form_box_title">
          <p>詳細情報</p>
        </div>
        <dl>
          <dt>組織名</dt>
          <dd>{{ projectInfo.org_name }}</dd>
        </dl>
        <dl>
          <dt>診断名</dt>
          <dd>{{ projectInfo.diagnosis_name }}</dd>
        </dl>
        <dl v-if="projectInfo.project_type >= 11 && projectInfo.project_type <= 16">
          <dt >属性一覧の設定</dt>
          <dd>{{ attribute_setting_types[projectInfo.attribute_setting_type] ?
            attribute_setting_types[projectInfo.attribute_setting_type] : '' }}
          </dd>
        </dl>
        <dl v-if="projectInfo.project_type >=11 && projectInfo.project_type <= 16 && projectInfo.attribute_setting_type == 2">
          <dt>属性一覧(Excel)</dt>
          <dd>
            <a :href="link_download_pai+ projectInfo.id" v-if="parseFloat(projectInfo.progress_status) >= '2.1' && projectInfo.p_attribute_info_file_name">ダウンロード</a>
            <span v-else>未</span>
          </dd>
        </dl>
        <dl class="form_box_1_3_dl">
          <dt>ID・パスワード設定</dt>
          <dd class="l_height">
            ID：　　　　{{ setting_types[projectInfo.id_setting_type] ? setting_types[projectInfo.id_setting_type] : ''
            }}<br>
            パスワード：{{ setting_types[projectInfo.pass_setting_type] ? setting_types[projectInfo.pass_setting_type] : '' }}
          </dd>
        </dl>
        <dl class="form_box_1_3_dl">
          <dt class="height_up_3_2">貴社お問合せ先</dt>
          <dd class="l_height">
            部署・役職　　{{ projectInfo.inquiry_division }}<br>
            氏名　　　　　{{ projectInfo.inquiry_name }}<br>
            電話番号　　　{{ projectInfo.inquiry_tel }}<br>
            メール　　　　{{ projectInfo.inquiry_email }}<br>
          </dd>
        </dl>
        <dl v-if="projectInfo.medium_type != 1 && projectInfo.project_type >= 11 && projectInfo.project_type <= 16">
          <dt>質問紙提出先</dt>
          <dd>{{ projectInfo.paper_recipient }}</dd>
        </dl>
        <dl class="" v-if="projectInfo.project_type == 11">
          <dt class="">業種別比較</dt>
          <dd>{{ projectInfo.comparison_industry_type == 1 ? '有り' : '無し' }}</dd>
        </dl>
        <dl>
          <dt>回答者への案内メール</dt>
          <dd>
            {{ guidance_email_types[projectInfo.guidance_email_type] ? guidance_email_types[projectInfo.guidance_email_type] : ''}}
          </dd>
        </dl>
        <dl v-if="projectInfo.project_type == 11">
          <dt>質問文の主語変更</dt>
          <dd>{{ projectInfo.question_subject_type}}</dd>
        </dl>
        <dl class="radio_2 fx" v-if="projectInfo.project_type !== 11 && projectInfo.project_type > 11 && projectInfo.project_type <= 16">
          <dt>追加質問文</dt>
          <dd style="padding-right: 35px;">
            <div v-if="projectInfo.qestion_type !== ''">
              {{ projectInfo.qestion_type == 1 ? '追加質問が5問以内/追加質問の尺度が下記の7点法を利用' : '追加質問が6問以上/追加質問の尺度が7点法以外(個別対応)'}}
              <div v-if="projectInfo.qestion_type == 1">
                <div v-for="(question, i) in project.questions">
                  <div>【質問文】</div>
                  <p>{{ question.body }}</p>
                  <div>【回答方式】</div>
                  <p>{{ question.format_type == 1 ? '尺度(7点法)' : '自由記入' }}</p>
                </div>
              </div>
            </div>
          </dd>
        </dl>
        <dl v-if="projectInfo.project_type >= 11 && projectInfo.project_type <= 16 && projectInfo.attribute_setting_type != 1">
          <dt class="hight_up_5_2">対象者リスト(Excel)</dt>
          <dd>
            <p class="form_box_sub_5_2_p">
              <a  :href="link_download_ptp+ projectInfo.id+'?last=true'" v-if="parseFloat(projectInfo.progress_status) >= 3">ダウンロード</a>
              <span v-else>未</span>
            </p>
          </dd>
        </dl>
        <dl v-if="projectInfo.project_type >= 21 && projectInfo.project_type<= 24 ">
          <dt class="hight_up_5_2">対象者リスト(Excel)</dt>
          <dd>
            <p class="form_box_sub_5_2_p" >
              <a v-if="parseFloat(projectInfo.progress_status) >= 3.0" :href="link_download_pmf+ projectInfo.id">ダウンロード</a>
              <span v-else>未</span>
            </p>
          </dd>
        </dl>
        <dl v-if="(projectInfo.project_type >=1 && projectInfo.project_type <=6) || (projectInfo.project_type >=11 && projectInfo.project_type <=16 && projectInfo.attribute_setting_type == 1)">
          <dt class="hight_up_5_2">対象者リスト(Excel)</dt>
          <dd v-if="projectInfo.project_type >=1 && projectInfo.project_type <=6">
            <p class="form_box_sub_5_2_p" >
            <a :href="link_download_ppt_invidual+ projectInfo.id" v-if="parseFloat(projectInfo.progress_status) >= '3.0'">ダウンロード</a>
            <span v-else>未</span>
            </p>
          </dd>
          <dd v-else>
            <p class="form_box_sub_5_2_p" >
              <a :href="link_download_ptp+ projectInfo.id+'?last=true'" v-if="parseFloat(projectInfo.progress_status) >= '3.0'">ダウンロード</a>
              <span v-else>未</span>
            </p>
          </dd>
        </dl>
      </div>

      <div class="footer_box footer_box_1_3">
        <p></p>
        <p class="btn">
          <router-link class="form_box_sub form_box_sub_2" :to="{ name: 'ProjectEdit', params: { id: projectInfo.id }}" v-if="parseFloat(projectInfo.progress_status) < 4">入力内容を編集する</router-link>
          <button disabled="true" type="button" class="form_box_sub form_box_sub_2 disabled" v-else>入力内容を編集する</button>
        </p>
      </div>
    </form>

    <b-modal name="deletePopup" id="deletePopup" ref="deletePopup" :modal-class="['modal-action  ']"
             :centered="true" :no-close-on-backdrop="true" header-close-label="" hide-footer hide-header>
      <h5 class="title">{{ projectInfo.client_name }}を削除しますか？</h5>
      <div class="modal-footer top-30">
        <b-button class="btn-submit" @click="$bvModal.hide('deletePopup')">キャンセル</b-button>
        <a class="btn btn-red" @click="deleteProject()">削除</a>
      </div>
    </b-modal>

    <b-modal name="endProjectInfo" id="endProjectInfo" ref="endProjectInfo" :modal-class="['modal-action  ']"
             :centered="true" :no-close-on-backdrop="true" header-close-label="" hide-footer hide-header>
      <h5 class="title">案件が完了しますか？</h5>
      <div class="modal-footer top-30">
        <b-button class="btn-submit" @click="closeEnd()">キャンセル</b-button>
        <a class="btn btn-red" @click="end()">完了</a>
      </div>
    </b-modal>

    <b-modal name="sendPasswordPopup" id="sendPasswordPopup" ref="sendPasswordPopup" :modal-class="['modal-action']"
             :centered="true" :no-close-on-backdrop="true" header-close-label="" hide-footer hide-header>
      <h5 class="title">パスワードをリセットしてユーザーに送信しますか？</h5>
      <div class="modal-footer top-30 resend">
        <b-button class="btn-submit" @click="$bvModal.hide('sendPasswordPopup')">キャンセル</b-button>
        <a class="btn btn-primary" @click="sendPassword()">アカウント再発行</a>
      </div>
    </b-modal>

    <b-modal name="isDisabled" id="isDisabled" ref="isDisabled" :modal-class="['modal-action  ']"
             :centered="true" :no-close-on-backdrop="true" header-close-label="" hide-footer hide-header>
      <h5 class="title">リスト提出締切日を{{ projectInfo.is_deadline_disabled == true ? '無効' : '有効'  }}にしますか？</h5>
      <div class="modal-footer top-30">
        <b-button class="btn-submit" @click="closeDeadline()">キャンセル</b-button>
        <a class="btn btn-red" @click="changeDeadline()">はい</a>
      </div>
    </b-modal>
  </div>



</template>
<script>
  import {mapActions, mapMutations, mapState} from 'vuex'
  import {
    ATTRIBUTE_SETTING_TYPE,
    GUIDENCE_EMAIL_TYPE,
    INDUSTRY_TYPE,
    MEDIUM_TYPE,
    PROJECT_STATUS,
    PROJECT_TYPE,
    SCALE_TYPE,
    SELECT_KUBUN_TYPE,
    SETTING_TYPE
  } from './../../../contstant'
  import moment from 'moment'

  export default {
		data() {
			return {
				select_kubun_types: SELECT_KUBUN_TYPE,
				scale_types: SCALE_TYPE,
				industry_types: INDUSTRY_TYPE,
				medium_types: MEDIUM_TYPE,
				setting_types: SETTING_TYPE,
        guidance_email_types: GUIDENCE_EMAIL_TYPE,
        attribute_setting_types: ATTRIBUTE_SETTING_TYPE,
        hover: false,
        progress_end: false,
        link_download_pai: '/api/admin/pai-export/',
        link_download_ptp: '/api/admin/ptp-export/',
        link_download_pmf: '/api/admin/pmf-export/',
        link_download_ppt_invidual: '/api/admin/ppt-download/',
        deadlineSubmit: null
			}
		},
		computed: {
      ...mapState('project', ['projectInfo', 'query', 'projects']),
			...mapState('consultantUser', ['user', 'project', 'holidays'])
		},
    watch: {
      holidays() {
        this.getDeadlineSubmit()
      },
      "projectInfo.progress_status": function () {
        if (this.projectInfo.progress_status > 3) {
          this.progress_end = true
        }
      }
    },
		methods: {
			...mapMutations('project', ['PROJECT']),
			...mapActions('project', ['GET_PROJECT_DETAIL']),
      ...mapMutations('consultantUser', ['SET_HOLIDAYS']),
      ...mapActions('consultantUser', ['GET_HOLIDAYS']),
			progressStatus() {
        // console.log(parseInt(this.projectInfo.progress_status) + 1 + 1)
        return PROJECT_STATUS[parseInt(this.projectInfo.progress_status)] ? PROJECT_STATUS[parseInt(this.projectInfo.progress_status)] : ''
			},
			showDelete() {
        this.$refs.deletePopup.show();
      },
			deleteProject(){
				this.$store.dispatch('project/DELETE_PROJECT', this.projectInfo.id).then(() => {
					this.$refs.deletePopup.hide();
					this.$router.push({ name: 'ProjectList' });
				});
			},
      changeDeadlineDisabled() {
        this.$refs.isDisabled.show()
      },
      changeDeadline() {
        this.$wait.start('loading')
        this.$store.dispatch('project/UPDATE_PROJECT', {
          'project': this.projectInfo, 'url': '/api/admin/projects'
        }).then((data) => {
          this.$wait.end('loading')
          this.$refs.isDisabled.hide()
        }).catch(e => {
          this.$wait.end('loading')
          this.$refs.isDisabled.end()
        })
      },
      closeDeadline() {
        this.projectInfo.is_deadline_disabled = null
        this.$refs.isDisabled.hide()
      },
      resendPassword() {
			  this.$refs.sendPasswordPopup.show()
      },
			sendPassword(){
				this.$refs.sendPasswordPopup.hide();
        this.$wait.start('loading')
				this.$store.dispatch('project/SEND_PASSWORD', this.projectInfo).then(() => {
				  this.$wait.end('loading')
        });
      },
      logout() {
        this.$store.dispatch('consultantUser/AUTH_LOGOUT').then(() => {
        }).catch(e => {

        })
      },
      mouseOver() {
        this.hover = !this.hover
      },
      projectStatusNumber() {
        let status = parseInt(this.projectInfo.progress_status) + 1
        let text = ''
        switch (status) {
          case 1:
            text = '&#9312;'
            break
          case 2:
            text = '&#9313;'
            break
          case 3:
            text = '&#9314;'
            break
          case 4:
            text = '&#9315;'
            break
          case 5:
            text = '&#9316;'
            break
          default:
        }
        return text
      },
      projectType(project_type) {
        let type = ''
        if (PROJECT_TYPE[0][project_type]) {
          type = PROJECT_TYPE[0][project_type]
        } else if (PROJECT_TYPE[1][project_type]) {
          type = PROJECT_TYPE[1][project_type]
        }
        return type
      },
      empty(value) {
        return !_.isEmpty(value)
      },
      endProject() {
			  if (this.progress_end) {
			    this.$refs.endProjectInfo.show()
        }
      },
      closeEnd() {
			  this.progress_end = false
        this.$refs.endProjectInfo.hide()
      },
      end() {
        this.$wait.start('loading')
        this.$store.dispatch('project/UPDATE_PROJECT', {
          'project': {
            progress_status: 4,
            id: this.projectInfo.id,
            'finished_c_user_id': this.user.id,
            finished_at: moment().format('YYYY-MM-DD HH:mm:ss'),
            // 'is_deadline_disabled': true
          }, 'url': '/api/admin/projects'
        }).then((data) => {
          this.$wait.end('loading')
          // this.projectInfo.progress_status = this.projectInfo.progress_status
          // this.$store.dispatch()
          this.$refs.endProjectInfo.hide()
        }).catch(e => {
          this.$wait.end('loading')
          this.$refs.endProjectInfo.end()
        })
      },
      getDates(startDate, stopDate) {
        var dateArray = [];
        var currentDate = moment(startDate);
        var stopDate = moment(stopDate);
        while (currentDate <= stopDate) {
          dateArray.push( moment(currentDate).format('YYYY-MM-DD') )
          currentDate = moment(currentDate).add(1, 'days');
        }
        return dateArray;
      },
      getDeadlineSubmit() {
        let fiveDate = this.projectInfo.web_start
        let fiveDate2 = null
        if (this.projectInfo.web_start) {
          let days = this.getDates(moment(this.projectInfo.web_start).subtract(30, 'days').format('YYYY-MM-DD'), moment(this.projectInfo.web_start).subtract(1, 'days').format('YYYY-MM-DD'))
          days.sort(function (a, b) {
            return new Date(b) - new Date(a);
          });
          let i = 1
          _.forEach(days, (d) => {
            if (!this.holidays.includes(d) && i <= 5) {
              fiveDate = d
              i = i + 1
            }
          })
          fiveDate = moment(fiveDate).subtract(1, 'days').format('YYYY-MM-DD')
          while (this.holidays.includes(fiveDate)) {
            fiveDate = moment(fiveDate).subtract(1, 'days').format('YYYY-MM-DD')
          }
        }

        if (this.projectInfo.medium_type == 2 || this.projectInfo.medium_type == 3) {
          let infDate = this.projectInfo.paper_preferred_date

          if (infDate) {
            let paper_people = this.projectInfo.paper_people
            let date_medium2 = ''
            switch (true) {
              case (paper_people > 0 && paper_people <= 100):
                date_medium2 = 7
                break
              case (paper_people > 100 && paper_people <= 200):
                date_medium2 = 8
                break
              case (paper_people > 200 && paper_people <= 300):
                date_medium2 = 9
                break
              case (paper_people > 300 && paper_people <= 400):
                date_medium2 = 10
                break
              case (paper_people > 400 && paper_people <= 500):
                date_medium2 = 11
                break
              case (paper_people > 500):
                date_medium2 = 12
                break
              default:
                break
            }

            let days2 = this.getDates(moment(infDate).subtract(30, 'days').format('YYYY-MM-DD'), infDate)
            days2.sort(function (a, b) {
              return new Date(b) - new Date(a);
            });
            let i = 0
            _.forEach(days2, (d) => {
              if (!this.holidays.includes(d) && i <= date_medium2) {
                fiveDate2 = d
                i = i + 1
              }
            })
          }
        }

        let deadline = ''
        if (moment(fiveDate).isValid() && moment(fiveDate2).isValid()) {
          if (fiveDate < fiveDate2) {
            deadline = fiveDate
          } else {
            deadline = fiveDate2
          }
        } else if (!moment(fiveDate).isValid() && moment(fiveDate2).isValid()) {
          deadline = fiveDate2
        } else if (moment(fiveDate).isValid() &&  !moment(fiveDate2).isValid()) {
          deadline = fiveDate
        }
        this.deadlineSubmit = moment(deadline).isValid() ? moment(deadline).format('YYYY年MM月DD日') : ''

      },
		},
		async created() {
			let id = this.$route.params.id
			await this.GET_PROJECT_DETAIL(id);
      // await this.GET_HOLIDAYS()
		},
    mounted() {
      // this.getDeadlineSubmit()
      window.scrollTo(0,0)
    }
  }
</script>
<style scoped lang="scss">
  input[type=checkbox] {
    display: none;
  }
  .btn-primary {
    color: #fff !important;
  }
  .form_box_sub_5_2 {
    color: #fff !important;
  }
  .user {
    font-size: 20px;
    line-height: 34px;
    /*margin-top: 6px;*/
    padding-left: 10px;
  }
  .gray {
    color: #ddd;
  }
  .disabled {
    background-color: #ddd !important;
  }
</style>
