<template>
  <div class="paging" v-if="total > 1">
    <ul class="pagination">
      <li :class="{ page: true, disabled: disabledToPrevious }" @click="toPrevious" v-if="currentPage > 1">
        <a href="javascript:;"><<
          <!--<i class="fas fa-angle-double-left"></i>-->
        </a>
      </li>
      <li
        :class="{ page: true, active: Number.isInteger(page) && page === currentPage, disabled: page === '...' }"
        :key="index"
        @click="changePage(page)"
        v-for="(page, index) in pages"
      >
        <a href="javascript:;">{{ page }}</a>
      </li>
      <li :class="{ page: true, disabled: disabledToNext }" @click="toNext"
          v-if="currentPage < pages[pages.length - 1]">
        <a href="javascript:;">>>
          <!--<i class="fas fa-angle-double-right"></i>-->
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    props: {
      currentPage: {
        type: Number,
        default: 1
      },
      total: {
        type: Number,
        default: 0
      },
      perPage: {
        type: Number,
        default: 50
      }
    },
    computed: {
      pages: function () {
        if (this.total === 0) {
          return [];
        } else if (this.total <= this.perPage) {
          return [1];
        } else {
          const mode = this.total % this.perPage;
          let div = Math.floor(this.total / this.perPage);
          const rs = [];
          if (mode > 0) {
            div++;
          }
          if (div <= 8) {
            for (let i = 1; i <= div; i++) {
              rs.push(i);
            }
          } else {
            if (this.currentPage >= 3 && this.currentPage < 5) {
              // >= 3 && < 5
              for (let i = 1; i <= this.currentPage + 1; i++) {
                rs.push(i);
              }
              rs.push("...");
              rs.push(div);
            } else if (this.currentPage >= 5 && this.currentPage < div - 3) {
              // >= 5 && < div - 3
              rs.push(1);
              rs.push("...");
              rs.push(this.currentPage - 1, this.currentPage, this.currentPage + 1);
              rs.push("...");
              rs.push(div);
            } else if (this.currentPage <= div - 2 && this.currentPage >= div - 3) {
              // <= div - 2 && >= div - 3
              rs.push(1);
              rs.push("...");
              for (let i = this.currentPage - 1; i <= div; i++) {
                rs.push(i);
              }
            } else {
              rs.push(1, 2, 3);
              rs.push("...");
              rs.push(div - 2, div - 1, div);
            }
          }
          return rs;
        }
      },
      disabledToPrevious: function () {
        return this.currentPage === 1;
      },
      disabledToNext: function () {
        const lastPage = this.pages[this.pages.length - 1];
        return lastPage === this.currentPage;
      }
    },
    data() {
      return {};
    },
    methods: {
      changePage: function (page) {
        if (page !== "...") {
          this.$emit("changePage", page);
        }
      },
      toPrevious: function () {
        if (!this.disabledToPrevious) {
          this.$emit("changePage", this.currentPage - 1);
        }
      },
      toNext: function () {
        if (!this.disabledToNext) {
          this.$emit("changePage", this.currentPage + 1);
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .page {
    &:hover {
      a {
        background-color: #d1d1d1;
      }
    }

    &:active {
      a {
        background-color: #fff;
      }
    }
  }

  .disabled {
    cursor: default;

    a {
      cursor: default;
      background-color: #eee !important;
      opacity: 0.6;
    }
  }

  .paging {
    text-align: right;
    font-family: "SFProDisplay";

    .pagination {
      display: inline-block;
      padding-left: 0;
      margin: 20px 0;
      border-radius: 4px;

      li {
        list-style: none;
        float: left;
        padding: 0 2px;

        &.active a {
          background-color: #666;
          border: 1px solid #666;
          color: #fff;
        }

        a {
          position: relative;
          float: left;
          width: 36px;
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: -1px;
          text-decoration: none;
          background-color: #fff;
          border: 1px solid #666;
          font-size: 14px;
          border-radius: 50%;
          color: #666;
        }
      }
    }

    .disabled a {
      cursor: default;
      background-color: #eee !important;
      opacity: 0.6;
    }
  }
</style>


