<template>
  <div class="">
    <header-component :title="projectType()"></header-component>
	<div id="body_1_2" v-if="user && user.id">
		<div class="content_box_4_5">
			<p>
				ご登録ありがとうございます。<br> {{ project.diagnosis_name ? project.diagnosis_name : projectType() }}についてのお申込み・事前情報・診断用資料を受理致しました。<br>
				後ほど、ご登録いただいたメールアドレス宛に【BCon】の件名で受付完了メールが届きます。<br> ご登録内容に相違ないかご確認ください。<br>
				尚、スケジュールに関しては、ご要望によって変動の可能性もありますことをご留意ください。<br>
				弊社にてお申込み内容を確認させて頂いた上で、3営業日以内に弊社担当者からご連絡をさせて頂きます。<br>
				今後、対象者に関する情報変更やご相談ごとなどは、担当者に直接メールにてご連絡下さい。<br>
				皆さまにとりまして、株式会社ビジネスコンサルタントのソリューションが<br> 組織開発・人材開発の一助となれば幸いです。<br> <br>
				(株)ビジネスコンサルタント<br> 診断サービスグループ 担当<br> <a
					href="mailto:survey-rcptn@info.bcon.jp">survey-rcptn@info.bcon.jp</a><br>
				TEL：03-3287-3416 平日 10:00～17:00
			</p>
		</div>
		<div class="back_box_4_5">
			<img src="/images/4_5_back.png" alt="「組織診断サーベイ診断」を受け付けました" srcset="/images/4_5_back.png 1x, /images/4_5_back@2x.png 2x">
		</div>
	</div>
</div>
</template>

<script>
	import {mapState} from 'vuex'
	import {PROJECT_TYPE} from '../../../contstant'

	export default {
    data() {
      return {

      }
    },
		computed: {
			...mapState('consultantUser', ['user', 'project']),
		},
		watch: {
    	'project.progress_status': function () {
				if (parseFloat(this.project.progress_status) < 3) {
					this.$router.push({
						name: 'apply'
					})
				}
			}
		},
		methods: {
			projectType() {
				let type = ''
				if (PROJECT_TYPE[0][this.project.project_type]) {
					type = PROJECT_TYPE[0][this.project.project_type]
				} else if (PROJECT_TYPE[1][this.project.project_type]) {
					type = PROJECT_TYPE[1][this.project.project_type]
				}
				return type
			}
		}
  }
</script>

<style>
.content_box_4_5 p {
    font-size: 16px;
    line-height: 1.6;
    font-family: "ヒラギノ角ゴ ProN W3", HiraKakuProN-W3;
}
</style>
