<template>
  <div class="container" v-show="projectInfo && projectInfo.id" id="body_1_2">
    <!--<pre>{{ projectInfo | pretty }}</pre>-->
    <header-component :back="{ name: 'ProjectDetail', params: {id: this.projectInfo.id }}" :margin="true"></header-component>
    <div class="fx_between_2" id="top_box_6_2">
      <div class="fx">
        <h1 class="h1_6_2">申込No.{{projectInfo.id}}</h1>
        <p :class="'sta_6_2_' + (parseInt(projectInfo.progress_status) + 1)"
           v-if="projectInfo.progress_status"><span v-html="projectStatusNumber()"></span> {{ progressStatus()
          }}</p>
      </div>
      <div class="fx">
        <p class="top_p_6_2"><input type="checkbox" name="is_deadline_disabled" :disabled="parseInt(projectInfo.progress_status) >= 4"
                                    id="is_deadline_disabled"
                                    v-model="projectInfo.is_deadline_disabled"
                                    @change="changeDeadlineDisabled()">
          <label for="is_deadline_disabled" :class="{'check_6_2': true, 'gray': parseInt(projectInfo.progress_status) >= 4 }">締切日を無効にする</label></p>
        <p class="top_p_6_2"><input :disabled="parseInt(projectInfo.progress_status) != 3" type="checkbox" name="progress_end" id="progress_end" v-model="progress_end" @change="endProject()"><label
          for="progress_end" :class="{'check_6_2': true, 'gray': parseInt(projectInfo.progress_status) != 3 }">完了にする</label></p>
        <p class="del_6_2"><a href="javascript:;" @click="showDelete()">削除</a></p>
      </div>
    </div>
    <div id="sta_box" class="fx_ce">
      <p class="sta_p">ステータス</p>
      <p :class="{'sta_6_2_1 m_right': true, 'sta_opacity': parseInt(projectInfo.progress_status) != 0}">①案件登録済</p>
      <p :class="{'sta_6_2_2 m_right': true, 'sta_opacity': parseInt(projectInfo.progress_status) != 1}">②申込済</p>
      <p :class="{'sta_6_2_3 m_right': true, 'sta_opacity': parseInt(projectInfo.progress_status) != 2}">③詳細情報登録済</p>
      <p :class="{'sta_6_2_4 m_right': true, 'sta_opacity': parseInt(projectInfo.progress_status) != 3}">④リスト登録済</p>
      <p :class="{'sta_6_2_5 m_right': true, 'sta_opacity': parseInt(projectInfo.progress_status) != 4}">⑤診断SG受理済</p>
    </div>
    <div class="attention">
      <p v-if="parseInt(projectInfo.progress_status) == 0">催促メール(開始日)　　　　<span v-if="projectInfo.preentry_alert_date">{{ projectInfo.preentry_alert_date }}</span></p>
      <p v-else>催促メール（リスト）　　　　<span v-if="projectInfo.postentry_alert_date">{{ projectInfo.postentry_alert_date }}</span></p>
    </div>

    <ValidationObserver ref="observer" v-slot="{ invalid }" tag="form" @submit.prevent="editProject()">
      <div class="form_box form_box_2_3">
        <div class="form_box_title">
          <p>基本情報</p>
        </div>
        <dl>
          <dt>組織名(会社名)</dt>
          <dd>
            <ValidationProvider name="client_name" rules="required" v-slot="{ errors }" class="validate">
              <input :class="{ 'form_text': true, 'error': errors[0] }" type="text" name="client" value="株式会社●○" v-model="projectInfo.client_name" maxlength="50">
              <br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>
            </ValidationProvider>
          </dd>
        </dl>
        <dl>
          <dt>診断名</dt>
          <dd><div style="padding-left: 35px;">{{ projectType(projectInfo.project_type)  }}</div></dd>
        </dl>
        <dl>
          <dt>担当営業名</dt>
          <dd>
            <ValidationProvider name="staff_name" rules="required" v-slot="{ errors }" class="validate">
              <input :class="{ 'form_text': true, 'error': errors[0] }" type="text" name="client" value="営業哲夫" v-model="projectInfo.staff_name" maxlength="20">
              <br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>
            </ValidationProvider>
          </dd>
        </dl>
        <dl>
          <dt>共有先メールアドレス&#9312;</dt>
          <dd><input class="form_text" type="text" name="client" value="mailadress01@BCon.co.jp"
                     v-model="projectInfo.staff_email1"
                     onKeyUp="$(this).val($(this).val().replace(/[^a-zA-Z.\-+@+_\d]/ig, ''))" maxlength="100">
          </dd>
        </dl>
        <dl>
          <dt>共有先メールアドレス&#9313;</dt>
          <dd><input class="form_text" type="text" name="client" value="mailadress02@BCon.co.jp"
                     v-model="projectInfo.staff_email2"
                     onKeyUp="$(this).val($(this).val().replace(/[^a-zA-Z.\-+@+_\d]/ig, ''))" maxlength="100"></dd>
        </dl>
        <dl class="fx_normal">
          <dt class="top">備考</dt>
          <dd>
            <ValidationProvider  name="memo" rules="max:1000" v-slot="{ errors }" class="validate">
            <textarea :class="{ 'error': errors[0] }" name="memo" v-model="projectInfo.memo" rows="7" cols="40" maxlength="1000"
                      placeholder="診断チームに申し伝えることがあれば入力してください。"></textarea>
              <br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>
            </ValidationProvider>
          </dd>
        </dl>
        <dl v-if="projectInfo.project_type == 11">
          <dt>経年比較の有無</dt>
          <dd>
            <ValidationProvider  name="is_comparison_age" rules="" v-slot="{ errors }">
              <input :class="{ 'form_radio': true, 'error': errors[0] }" type="radio" name="is_comparison_age"
                     v-model="projectInfo.is_comparison_age" value="1" id="is_comparison_age1">
              <label for="is_comparison_age1">有り</label>
              <input :class="{ 'form_radio': true, 'error': errors[0] }" type="radio" name="is_comparison_age"
                     v-model="projectInfo.is_comparison_age" value="0" id="is_comparison_age2">
              <label for="is_comparison_age2">無し</label>
              <br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>
            </ValidationProvider>
          </dd>
        </dl>
      </div>

      <div class="form_box form_box_2_3">
        <div class="form_box_title">
          <p>お客さま情報</p>
        </div>
        <!--<dl>-->
          <!--<dt>組織名(会社名)</dt>-->
          <!--<dd><input class="form_text" type="text" name="client_name" value="株式会社○○○" v-model="projectInfo.client_name">-->
          <!--</dd>-->
        <!--</dl>-->
        <dl>
          <dt>担当者　所属</dt>
          <dd>
            <ValidationProvider rules="max:50"  name="division1" v-slot="{ errors }" class="validate">
            <input v-if="projectInfo.user && projectInfo.user[0] !== undefined" :class="{ 'form_text': true, 'error': errors[0] }" type="text" name="client" value="division" v-model="projectInfo.user[0].division" maxlength="50">
            </ValidationProvider>
          </dd>
        </dl>
        <dl>
          <dt>担当者　役職</dt>
          <dd>
            <ValidationProvider rules="max:50"  name="position1" v-slot="{ errors }" class="validate">
            <input v-if="projectInfo.user && projectInfo.user[0] !== undefined" :class="{ 'form_text': true, 'error': errors[0] }" type="text" name="position" value="部長" v-model="projectInfo.user[0].position">
            </ValidationProvider>
          </dd>
        </dl>
        <dl>
          <dt>担当者　氏名</dt>
          <!--{{ // projectInfo.user[0].name }}-->
          <dd>
            <ValidationProvider name="name1" rules="required|max:50" v-slot="{ errors }" class="validate">
            <input v-if="projectInfo.user && projectInfo.user[0] !== undefined" :class="{ 'form_text': true, 'error': errors[0] }" type="text" maxlength="50"
                     name="client" value="田中一郎" v-model="projectInfo.user[0].name ">
              <br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>
            </ValidationProvider>
          </dd>
        </dl>
        <dl>
          <dt>担当者　メールアドレス</dt>
          <dd>
            <div class="fx_between_2">
              <ValidationProvider  name="email1" :rules="{ required: true, email: true,regex: /^[0-9A-Za-z._\-+@]+$/, max: 100} " v-slot="{ errors }" class="validate">
              <input v-if="projectInfo.user && projectInfo.user[0] !== undefined" :class="{ 'form_text form_text2': true, 'error': errors[0] }"
                     type="text" name="client" value="mailadress03@BCon.co.jp" v-model="projectInfo.user[0].email" maxlength="100"
                     onKeyUp="$(this).val($(this).val().replace(/[^a-zA-Z.\-+@+\d]/ig, ''))">
                <br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </dd>
        </dl>
        <dl>
          <dt>担当者2人目　氏名</dt>
          <dd v-if="projectInfo.user && projectInfo.user[1] !== undefined">
            <ValidationProvider  :rules="!isEmpty(projectInfo.user[1].email) ? 'required' : ''" name="name2" class="validate"
                                v-slot="{ errors }">
            <input v-if="projectInfo.user && projectInfo.user[1] !== undefined" :class="{ 'form_text': true, 'error': errors[0] }" type="text" maxlength="20"
                     name="client" value="田中一郎" v-model="projectInfo.user[1].name ">
              <br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>
            </ValidationProvider>
          </dd>
        </dl>
        <dl>
          <dt>担当者2人目　メールアドレス</dt>
          <dd v-if="projectInfo.user && projectInfo.user[1] !== undefined">
            <div class="fx_between_2">
              <ValidationProvider
                                  :rules="{ same: projectInfo.user[0].email, required: !isEmpty(projectInfo.user[1].name),regex: /^[0-9A-Za-z._\-+@]+$/, max: 100}"
                                  name="email2"
                                  v-slot="{ errors }" class="validate">
              <input v-if="projectInfo.user && projectInfo.user[1] !== undefined" :class="{ 'form_text form_text2': true, 'error': errors[0] }" maxlength="100"
                     type="text" name="client" value="mailadress03@BCon.co.jp" v-model="projectInfo.user[1].email"
                     onKeyUp="$(this).val($(this).val().replace(/[^a-zA-Z.\-+@_\d]/ig, ''))">
                <br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </dd>
        </dl>
        <dl>
          <dt>電話番号</dt>
          <dd>
            <ValidationProvider name="client_tel" :rules="{required: true, numeric: true, regex: /^[0-9a-zA-Z]*$/}"
                                v-slot="{ errors }" class="validate">
            <input
              :class="{'form_text': true, 'error': errors[0] }" type="tel" pattern="[0-9]+" maxlength="11" name="client" value="03xxxxxxxx" v-model="projectInfo.client_tel"
                     onKeyUp="$(this).val($(this).val().replace(/[^\d]/ig, ''))" autocomplete="none"
                     @keypress="preventAlphabetInput($event)">
              <br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>
            </ValidationProvider>
          </dd>
        </dl>
        <dl>
          <dt class="required8 form_box_2_3_dt top">住所<br>
          </dt>
          <dd class="margin_dd">
            <p>
              <ValidationProvider name="zip_code" rules="required"
                                  v-slot="{ errors }" class="validate label_p">
                〒<input :class="{ 'form_text form_address': true, 'error': errors[0] }" name="zip_code"
                        placeholder="例：1000005" type="tel" pattern="[0-9]+" maxlength="11"
                        v-model="projectInfo.zip_code"
                        onKeyUp="$(this).val($(this).val().replace(/[^\d]/ig, ''))"
                        autocomplete="none">
                <input type="button" class="input_button" @click="searchAddress()" value="住所検索"><span
                class=""></span><br>
                <span>※ハイフンを入れずに入力してください。</span>
              </ValidationProvider>
            </p>
            <div style="color: #DB484A;" v-if="searchAddErr"><span class="error">{{ searchAddErr }}</span></div>
            <p class="">
              <ValidationProvider name="address1" rules="required"
                                  v-slot="{ errors }" class="validate">
                都道府県
                <input :class="{ 'form_text form_address_1': true, 'error': errors[0] }" v-model="projectInfo.address1" maxlength="10"
                       placeholder="例：東京都" type="text">
                <!--<br v-show="errors[0]"><span class="error left-150" v-show="errors[0]">{{ errors[0] }}</span>-->
              </ValidationProvider>
            </p>

            <p class="">
              <ValidationProvider name="address2" rules="required"
                                  v-slot="{ errors }" class="validate">
                市区町村番地
                <input :class="{ 'form_text form_address_2': true, 'error': errors[0] }" v-model="projectInfo.address2" maxlength="50"
                       placeholder="例：千代田区丸の内　1-7-12"
                       type="text">
                <!--<br v-show="errors[0]"><span class="error left-150" v-show="errors[0]">{{ errors[0] }}</span>-->
              </ValidationProvider>
            </p>

            <p class="">
                <span class="validate">
                  ビル名
                  <input class="form_text form_address_3" v-model="projectInfo.address3" placeholder="例：サピアタワー　18F" maxlength="50"
                         type="text">
                </span>
            </p>
          </dd>
        </dl>
      </div>


      <div class="form_box form_box_2_3">
        <div class="form_box_title">
          <p>申込内容入力</p>
        </div>
        <dl>
          <dt class=" form_box_2_3_dt_1 top">実施方法
          </dt>
          <dd>
            <p class="margin_dd">
              <ValidationProvider name="medium_type" rules="required"
                                  v-slot="{ errors }" class="validate">
                <input :class="{ 'form_radio': true, 'error': errors[0] }" id="medium_type1" name="medium_type"
                       type="radio" value="1" @change="changeMedium()"
                       v-model="projectInfo.medium_type">
                <label class="form-check-label" for="medium_type1">Web</label>
                <input :class="{ 'form_radio': true, 'error': errors[0] }" id="medium_type2" name="medium_type"
                       type="radio" value="2" @change="changeMedium()"
                       v-model="projectInfo.medium_type">
                <label class="form-check-label" for="medium_type2">質問紙</label>
                <input :class="{ 'form_radio': true, 'error': errors[0] }" id="medium_type3" name="medium_type"
                       type="radio" value="3" @change="changeMedium()"
                       v-model="projectInfo.medium_type">
                <label class="form-check-label" for="medium_type3">Web + 質問紙</label>
                <br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>
              </ValidationProvider>
            </p>
            <div class="charge_box">
              <ValidationProvider v-if="projectInfo.medium_type == 1" name="web_people" rules="required|max_value:1000"
                                  v-slot="{ errors }"
                                  class="validate no-padding">
                <p id="web_box">
                  <input :class="{ 'form_text form_radio_text web_box_text': true, 'error': errors[0] }"
                         name="web_people" type="tel" pattern="[0-9]+" maxlength="4" @input="webPeople"　onKeyUp="$(this).val($(this).val().replace(/[^\d]/ig, ''))"
                         @keypress="preventAlphabetInput($event)"  autocomplete="none"
                         v-model="projectInfo.web_people"> 名<br><span>※想定人数で構いません。</span>
                  <br v-show="errors[0]"><span v-show="errors[0]" class="error">{{ errors[0] }}</span>
                </p>
              </ValidationProvider>
              <ValidationProvider v-if="projectInfo.medium_type == 2" name="paper_people" rules="required|max_value:1000"
                                  v-slot="{errors }"
                                  class="validate no-padding">
                <p id="web_box1">
                  <input :class="{ 'form_text form_radio_text web_box_text': true, 'error': errors[0] }"
                         name="paper_people"  @input="paperPeople"　onKeyUp="$(this).val($(this).val().replace(/[^\d]/ig, ''))"
                         @keypress="preventAlphabetInput($event)" type="tel" pattern="[0-9]+" maxlength="4"
                         autocomplete="none"
                         v-model="projectInfo.paper_people"> 名<br><span>※想定人数で構いません。</span>
                  <br v-show="errors[0]"><span v-show="errors[0]" class="error">{{ errors[0] }}</span>
                </p>
              </ValidationProvider>
              <ValidationProvider v-if="projectInfo.medium_type == 3" name="web_people" rules="required|max_value:1000"
                                  v-slot="{ errors }"
                                  class="validate no-padding">
                <p id="web_box2">　　Web

                  <input :class="{ 'form_text form_radio_text web_box_text': true, 'error': errors[0] }"
                         name="web_people" type="tel" pattern="[0-9]+" maxlength="4" @input="webPeople"
                         v-model="projectInfo.web_people"
                         onKeyUp="$(this).val($(this).val().replace(/[^\d]/ig, ''))"
                         @keypress="preventAlphabetInput($event)"
                         autocomplete="none"
                  > 名
                  <br><span>※想定人数で構いません。</span>
                  <br v-show="errors[0]"><span v-show="errors[0]" class="error">{{ errors[0] }}</span>
                </p>
              </ValidationProvider>
              <ValidationProvider v-if="projectInfo.medium_type == 3" name="paper_people" rules="required|max_value:1000"
                                  v-slot="{errors }"
                                  class="validate no-padding">
                <p id="web_box3">　　質問紙
                  <input :class="{ 'form_text form_radio_text web_box_text': true, 'error': errors[0] }"
                         name="paper_people" type="tel" pattern="[0-9]+" maxlength="4" @input="paperPeople"
                         onKeyUp="$(this).val($(this).val().replace(/[^\d]/ig, ''))"
                         @keypress="preventAlphabetInput($event)"
                         autocomplete="none"
                         v-model="projectInfo.paper_people"> 名
                  <br v-show="errors[0]"><span v-show="errors[0]" class="error">{{ errors[0] }}</span>
                </p>
              </ValidationProvider>
            </div>
          </dd>
        </dl>

        <!-- 質問紙を選んだ場合 -->
        <!--<div id="paper">-->
        <dl class="radio_2" v-if="((projectInfo.medium_type == 2 || projectInfo.medium_type == 3))">
          <dt class="">質問紙の送付先拠点数</dt>
          <dd>
            <ValidationProvider style="display: flex; margin-left: 0" name="paper_send_type" rules="required"
                                v-slot="{ errors }">
              <input :class="{ 'form_radio': true, 'error': errors[0] }" id="paper_send_type1" name="paper_send_type"
                     type="radio" value="1"
                     v-model="projectInfo.paper_send_type">
              <label class="form-check-label" for="paper_send_type1">1拠点</label>
              <input v-if="!((projectInfo.project_type >= 1 && projectInfo.project_type <= 6) || (projectInfo.project_type >= 21 && projectInfo.project_type <= 24)) " :class="{ 'form_radio': true, 'error': errors[0] }" id="paper_send_type2" name="paper_send_type"
                     type="radio" value="2"
                     v-model="projectInfo.paper_send_type">
              <label v-if="!((projectInfo.project_type >= 1 && projectInfo.project_type <= 6) || (projectInfo.project_type >= 21 && projectInfo.project_type <= 24)) "  class="form-check-label" for="paper_send_type2">2拠点</label>
              <input v-if="!((projectInfo.project_type >= 1 && projectInfo.project_type <= 6) || (projectInfo.project_type >= 21 && projectInfo.project_type <= 24)) "  :class="{ 'form_radio': true, 'error': errors[0] }" id="paper_send_type3" name="paper_send_type"
                     type="radio" value="3"
                     v-model="projectInfo.paper_send_type">
              <label v-if="!((projectInfo.project_type >= 1 && projectInfo.project_type <= 6) || (projectInfo.project_type >= 21 && projectInfo.project_type <= 24)) "  class="form-check-label" for="paper_send_type3">3拠点以上(日程は目安となります。)<br>※３拠点以上の場合は申込完了後に担当者からご連絡致します。</label>
              <!--<br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>-->
            </ValidationProvider>
          </dd>
        </dl>

        <dl class="" v-if="projectInfo.paper_send_type == 1 || projectInfo.paper_send_type == 2">
          <dt class="form_box_2_3_dt2 top">質問紙の宛先&#9312;</dt>
          <dd class="margin_dd">
            <span class="margin_l_2_3_3">※納品先と質問紙の納付先が異なる場合は、入力し直してください。</span>
            <ValidationProvider name="paper1_division" rules="required"
                                v-slot="{ errors }" class="validate">
              <p>部署<input :class="{ 'form_text tel_number': true, 'error': errors[0] }" type="text"
                          name="paper1_division" placeholder="○○○部"
                          v-model="projectInfo.paper1_division"></p>
            </ValidationProvider>
            <ValidationProvider name="paper1_name" rules="required"
                                v-slot="{ errors }" class="validate">
              <p>氏名<input :class="{ 'form_text tel_number': true, 'error': errors[0] }" type="text" name="paper1_name"
                          placeholder="田中一郎"
                          v-model="projectInfo.paper1_name"></p>
            </ValidationProvider>
            <ValidationProvider name="paper1_position" rules=""
                                v-slot="{ errors }" class="validate">
              <p>役職<input :class="{ 'form_text tel_number': true, 'error': errors[0] }" type="text"
                          name="paper1_position" placeholder="部長"
                          v-model="projectInfo.paper1_position"></p>
            </ValidationProvider>
            <p>
              <ValidationProvider name="paper1_zip_code" rules="required"
                                  v-slot="{ errors }" class="validate">
                〒<input :class="{ 'form_text form_address': true, 'error': errors[0] }" name="paper1_zip_code"
                        placeholder="例：1000005" type="tel" pattern="[0-9]+" maxlength="11"
                        v-model="projectInfo.paper1_zip_code"
                        onKeyUp="$(this).val($(this).val().replace(/[^\d]/ig, ''))"
                        autocomplete="none">
                <input type="button" class="input_button" @click="searchAddressPaper()" value="住所検索"><span
                class=""></span><br>
                <span>※ハイフンを入れずに入力してください。</span>
              </ValidationProvider>
            </p>
            <div style="color: #DB484A;" v-if="searchAddErr1"><span class="error">{{ searchAddErr1 }}</span></div>
            <p class="">
              <ValidationProvider name="paper1_address1" rules="required"
                                  v-slot="{ errors }" class="validate">
                都道府県
                <input :class="{ 'form_text form_address_1': true, 'error': errors[0] }" maxlength="10"
                       v-model="projectInfo.paper1_address1"
                       name="paper1_address1"
                       placeholder="例：東京都" type="text">
                <br v-show="errors[0]"><span class="error left-150" v-show="errors[0]">{{ errors[0] }}</span>
              </ValidationProvider>
            </p>

            <p class="">
              <ValidationProvider name="paper1_address2" rules="required"
                                  v-slot="{ errors }" class="validate">
                市区町村番地
                <input :class="{ 'form_text form_address_2': true, 'error': errors[0] }"
                       v-model="projectInfo.paper1_address2" maxlength="50"
                       name="paper1_address2"
                       placeholder="例：千代田区丸の内　1-7-12"
                       type="text">
                <br v-show="errors[0]"><span class="error left-150" v-show="errors[0]">{{ errors[0] }}</span>
              </ValidationProvider>
            </p>

            <p class="">
              <span class="validate">
                ビル名
                <input class="form_text form_address_3" v-model="projectInfo.paper1_address3" placeholder="例：サピアタワー　18F" maxlength="50"
                       type="text">
              </span>
            </p>
            <p v-if="projectInfo.project_type >= 11 && projectInfo.project_type <= 16">部数
              <strong>
                <ValidationProvider name="paper1_copies" rules="numeric|max_value:10000"
                                    v-slot="{ errors }" class="validate">
                  <input :class="{ 'form_text form_text_2_3_2': true, 'error': errors[0] }" name="paper1_copies" max="10000"
                         placeholder=""
                         type="number"
                         onKeyUp="$(this).val($(this).val().replace(/[^\d]/ig, ''))"
                         autocomplete="none"
                         @keypress="preventAlphabetInput($event)"
                         v-model="projectInfo.paper1_copies"> 部
                  <br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>
                </ValidationProvider>
              </strong>
            </p>
          </dd>
        </dl>

        <dl class="" v-if="projectInfo.paper_send_type == 2 && projectInfo.project_type >= 11 && projectInfo.project_type <=16">
          <dt class="form_box_2_3_dt2 top">質問紙の宛先&#9313;</dt>
          <dd class="margin_dd">
            <span class="margin_l_2_3_3">※納品先と質問紙の納付先が異なる場合は、入力し直してください。</span>
            <ValidationProvider name="paper2_division" rules="required"
                                v-slot="{ errors }" class="validate">
              <p>部署<input :class="{ 'form_text tel_number': true, 'error': errors[0] }" type="text"
                          name="paper2_division" placeholder="○○○部"
                          v-model="projectInfo.paper2_division"></p>
            </ValidationProvider>
            <ValidationProvider name="paper2_name" rules="required"
                                v-slot="{ errors }" class="validate">
              <p>氏名<input :class="{ 'form_text tel_number': true, 'error': errors[0] }" type="text" name="paper2_name"
                          placeholder="田中一郎"
                          v-model="projectInfo.paper2_name"></p>
            </ValidationProvider>
            <ValidationProvider name="paper2_position" rules=""
                                v-slot="{ errors }" class="validate">
              <p>役職<input :class="{ 'form_text tel_number': true, 'error': errors[0] }" type="text"
                          name="paper2_position" placeholder="部長"
                          v-model="projectInfo.paper2_position"></p>
            </ValidationProvider>
            <p>
              <ValidationProvider name="paper2_zip_code" rules="required"
                                  v-slot="{ errors }" class="validate">
                〒<input :class="{ 'form_text form_address': true, 'error': errors[0] }" name="paper2_zip_code"
                        placeholder="例：1000005" type="tel" pattern="[0-9]+" maxlength="11"
                        v-model="projectInfo.paper2_zip_code"
                        onKeyUp="$(this).val($(this).val().replace(/[^\d]/ig, ''))"
                        autocomplete="none">
                <input type="button" class="input_button" @click="searchAddressPaper2()" value="住所検索"><span
                class=""></span><br>
                <span>※ハイフンを入れずに入力してください。</span>
              </ValidationProvider>
            </p>
            <div style="color: #DB484A;" v-if="searchAddErr2"><span class="error">{{ searchAddErr2 }}</span></div>
            <p class="">
              <ValidationProvider name="paper2_address1" rules="required"
                                  v-slot="{ errors }" class="validate">
                都道府県
                <input :class="{ 'form_text form_address_1': true, 'error': errors[0] }" maxlength="10"
                       v-model="projectInfo.paper2_address1"
                       placeholder="例：東京都" type="text">
                <br v-show="errors[0]"><span class="error left-150" v-show="errors[0]">{{ errors[0] }}</span>
              </ValidationProvider>
            </p>

            <p class="">
              <ValidationProvider name="paper2_address2" rules="required" maxlength="50"
                                  v-slot="{ errors }" class="validate">
                市区町村番地
                <input :class="{ 'form_text form_address_2': true, 'error': errors[0] }"
                       v-model="projectInfo.paper2_address2"
                       placeholder="例：千代田区丸の内　1-7-12"
                       type="text">
                <br v-show="errors[0]"><span class="error left-150" v-show="errors[0]">{{ errors[0] }}</span>
              </ValidationProvider>
            </p>

            <p class="">
              <span class="validate">
                ビル名
                <input class="form_text form_address_3" v-model="projectInfo.paper2_address3" placeholder="例：サピアタワー　18F" maxlength="50"
                       type="text">
              </span>
            </p>
            <p v-if="projectInfo.project_type >= 11 && projectInfo.project_type <= 16">部数
              <strong>
                <ValidationProvider name="paper1_copies" rules="numeric|max_value:10000"
                                    v-slot="{ errors }" class="validate">
                  <input :class="{ 'form_text form_text_2_3_2': true, 'error': errors[0] }" name="paper2_copies" max="10000"
                         placeholder=""
                         type="number"
                         onKeyUp="$(this).val($(this).val().replace(/[^\d]/ig, ''))"
                         autocomplete="none"
                         @keypress="preventAlphabetInput($event)"
                         v-model="projectInfo.paper2_copies"> 部
                  <br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>
                </ValidationProvider>
              </strong>
            </p>
          </dd>
        </dl>
        <!-- 質問紙を選んだ場合 end -->

        <!-- webを選んだ場合 -->
        <div id="web">
          <dl class="fx">
            <dt class="height_up_2_3_4 top">回答スケジュール
            </dt>
            <dd class="margin_dd position_r_2_3 no-padding">
              <div class="form_box_2_4">
                <p class="form_box_2_4_p form_box_2_3_p margin_t5">
                  <span>対象者リスト提出締切日　{{ deadlineSubmit }}</span></p>
                <div class="margin_top_2_3">
                  <div v-if="projectInfo.medium_type == 1">
                    <ValidationProvider name="web_start1" rules="required"
                                        v-slot="{ errors }"
                                        class="validate margin_b_2_4 margin_b_2_3 fx_ce">
                      <p>Web回答開始日時</p>
                      <div class="input-group stick_2_3 form_sch2_3_4 width-datepicker">
                        <date-picker :disabled="!projectInfo.web_people" v-model="projectInfo.web_start"
                                     :class="{'form_text form_sch': true, 'error': errors[0] }"
                                     name="projectInfo.web_start1"
                                     :wrap="true" :config="configs.web_start" @dp-change="onStartChange">
                        </date-picker>
                        <div class="input-group-append cal_icon">
                          <button class="btn datepickerbutton" type="button"></button>
                        </div>
                      </div>
                      <!--<br v-show="errors[0]"><span class="error" v-show="errors[0]">{{errors[0]}}</span>-->
                    </ValidationProvider>
                    <!--</div>-->

                    <!--<div v-if="projectInfo.medium_type == 1">-->
                    <ValidationProvider name="web_end1" rules="required"
                                        v-slot="{ errors }"
                                        class="validate margin_b_2_4 margin_b_2_3 fx_ce">
                      <p>Web回答終了日時</p>
                      <div class="input-group form_sch2_3_4 width-datepicker">
                        <date-picker :disabled="!projectInfo.web_start" v-model="projectInfo.web_end"
                                     :class="{'form_text form_sch': true, 'error': errors[0] }"
                                     name="projectInfo.web_end1"
                                     :wrap="true" :config="configs.web_end" @dp-change="onEndChange" @dp-update="onNextChange"  @dp-show="onShowChange">
                        </date-picker>
                        <div class="input-group-append cal_icon">
                          <button class="btn datepickerbutton" type="button"></button>
                        </div>
                      </div>
                      <!--<br v-show="errors[0]"><span class="error" v-show="errors[0]">{{errors[0]}}</span>-->
                    </ValidationProvider>
                  </div>

                  <div v-if="projectInfo.medium_type == 2">
                    <ValidationProvider name="paper_preferred_date2" rules="required"
                                        v-slot="{ errors }"
                                        class="validate margin_b_2_4 margin_b_2_3 fx_ce">
                      <p>質問紙到着
                        <br>希望日</p>

                      <div class="input-group margin_l_2_5 stick_2_3_4 width-datepicker">
                        <date-picker :disabled="!projectInfo.paper_people" v-model="projectInfo.paper_preferred_date"
                                     :class="{'form_text form_sch 2': true, 'error': errors[0] }"
                                     name="projectInfo.paper_preferred_date2"
                                     :wrap="true" :config="configs.paper_preferred_date"
                                     @dp-change="onPaperPrefererChange" @dp-update="onNextChange"  @dp-show="onShowChange">
                        </date-picker>
                        <div class="input-group-append cal_icon">
                          <button class="btn datepickerbutton" type="button"></button>
                        </div>
                      </div>
                      <!--<br v-show="errors[0]"><span class="error" v-show="errors[0]">{{errors[0]}}</span>-->
                    </ValidationProvider>
                    <!--</div>-->

                    <!--<div v-if="projectInfo.medium_type == 2">-->
                    <ValidationProvider name="paper_limit_date2" rules=""
                                        v-slot="{ errors }"
                                        class="validate margin_b_2_4 margin_b_2_3 fx_ce">
                      <p>質問紙提出期限
                        <br>(任意)</p>
                      <div class="input-group margin_l_2_7 stick_2_3_4 width-datepicker">
                        <date-picker :disabled="!projectInfo.paper_preferred_date"
                                     v-model="projectInfo.paper_limit_date" :class="{'form_text form_sch 2': true }"
                                     name="projectInfo.paper_limit_date2"
                                     :wrap="true" :config="configs.paper_limit_date" @dp-change="onPaperLimitChange" @dp-update="onNextChange"  @dp-show="onShowChange">
                        </date-picker>
                        <div class="input-group-append cal_icon">
                          <button class="btn datepickerbutton" type="button"></button>
                        </div>
                      </div>
                      <p class="no-margin"><img src="/images/info.png"
                                                srcset="/images/info.png 1x, /images/info@2x.png 2x"
                                                alt="インフォメーション"
                                                class="info_2" @mouseover="mouseOver" @mouseleave="mouseLeave">
                      <p v-show="fukidashi2" class="info_fukidashi_2">質問紙に職場での回収期限を<br>記載したい場合に入力してください。
                      </p>
                      <!--<br v-show="errors[0]"><span class="error" v-show="errors[0]">{{errors[0]}}</span>-->
                    </ValidationProvider>
                    <!--</div>-->

                    <!--<div v-if="projectInfo.medium_type == 2">-->
                    <ValidationProvider name="paper_bcon_receipt_date2" rules="required"
                                        v-slot="{ errors }"
                                        class="validate margin_b_2_4 margin_b_2_3 fx_ce">
                      <p>質問紙BCon
                        <br>到着日</p>
                      <div class="input-group margin_l_2_6 width-datepicker">
                        <date-picker :disabled="!projectInfo.paper_preferred_date"
                                     v-model="projectInfo.paper_bcon_receipt_date"
                                     :class="{'form_text form_sch 2': true, 'error': errors[0] }"
                                     name="projectInfo.paper_bcon_receipt_date2"
                                     :wrap="true" :config="configs.paper_bcon_receipt_date"
                                     @dp-change="onPaperBconChange" @dp-update="onNextChange"  @dp-show="onShowChange">
                        </date-picker>
                        <div class="input-group-append cal_icon">
                          <button class="btn datepickerbutton" type="button"></button>
                        </div>
                      </div>
                      <!--<br v-show="errors[0]"><span class="error" v-show="errors[0]">{{errors[0]}}</span>-->
                    </ValidationProvider>
                  </div>

                  <div v-if="projectInfo.medium_type == 3">
                    <ValidationProvider name="web_start3" rules="required"
                                        v-slot="{ errors }"
                                        class="validate margin_b_2_4 margin_b_2_3 fx_ce">
                      <p>Web回答 <br>開始日時</p>

                      <div class="input-group form_sch2_3_4 stick_2_3_4 width-datepicker">
                        <date-picker :disabled="!projectInfo.web_people || !projectInfo.paper_people"
                                     v-model="projectInfo.web_start"
                                     :class="{'form_text form_sch': true, 'error': errors[0] }"
                                     name="projectInfo.web_start3"
                                     :wrap="true" :config="configs.web_start" @dp-change="onStartChange" @dp-update="onNextChange"  @dp-show="onShowChange">
                        </date-picker>
                        <div class="input-group-append cal_icon">
                          <button class="btn datepickerbutton" type="button"></button>
                        </div>
                      </div>
                      <!--<br v-show="errors[0]"><span class="error" v-show="errors[0]">{{errors[0]}}</span>-->
                    </ValidationProvider>
                    <!--</div>-->

                    <!--<div v-if="projectInfo.medium_type == 3">-->
                    <ValidationProvider name="web_end3" rules="required"
                                        v-slot="{ errors }"
                                        class="validate margin_b_2_4 margin_b_2_3 fx_ce">
                      <p>Web回答 <br>終了日時</p>
                      <div class="input-group form_sch2_3_4 width-datepicker">
                        <date-picker :disabled="!projectInfo.web_start" v-model="projectInfo.web_end"
                                     :class="{'form_text form_sch': true, 'error': errors[0] }"
                                     name="projectInfo.web_end3"
                                     :wrap="true" :config="configs.web_end" @dp-change="onEndChange" @dp-update="onNextChange"  @dp-show="onShowChange">
                        </date-picker>
                        <div class="input-group-append cal_icon">
                          <button class="btn datepickerbutton" type="button"></button>
                        </div>
                      </div>
                      <!--<br v-show="errors[0]"><span class="error" v-show="errors[0]">{{errors[0]}}</span>-->
                    </ValidationProvider>
                    <!--</div>-->

                    <!--<div v-if="projectInfo.medium_type == 3">-->
                    <hr>
                    <!--</div>-->

                    <!-- 質問紙到着希望日 paper_preferred_date -->
                    <!--<div v-if="projectInfo.medium_type == 3">-->
                    <ValidationProvider name="paper_preferred_date3" rules="required"
                                        v-slot="{ errors }"
                                        class="validate margin_b_2_4 margin_b_2_3 fx_ce">
                      <p>質問紙到着
                        <br>希望日</p>
                      <div class="input-group margin_l_2_5 stick_2_3_4 width-datepicker">
                        <date-picker :disabled="!projectInfo.web_people || !projectInfo.paper_people"
                                     v-model="projectInfo.paper_preferred_date"
                                     :class="{'form_text form_sch': true, 'error': errors[0] }"
                                     name="projectInfo.paper_preferred_date3"
                                     :wrap="true" :config="configs.paper_preferred_date"
                                     @dp-change="onPaperPrefererChange" @dp-update="onNextChange"  @dp-show="onShowChange">
                        </date-picker>
                        <div class="input-group-append cal_icon">
                          <button class="btn datepickerbutton" type="button"></button>
                        </div>
                      </div>
                      <!--<br v-show="errors[0]"><span class="error" v-show="errors[0]">{{errors[0]}}</span>-->
                    </ValidationProvider>
                    <!--</div>-->

                    <!-- 質問紙提出期限 paper_limit_date -->
                    <!--<div v-if="projectInfo.medium_type == 3">-->
                    <ValidationProvider name="paper_limit_date3" rules=""
                                        v-slot="{ errors }"
                                        class="validate margin_b_2_4 margin_b_2_3 fx_ce">
                      <p>質問紙提出期限
                        <br>(任意)</p>
                      <div class="input-group margin_l_2_7 stick_2_3_4 width-datepicker">
                        <date-picker :disabled="!projectInfo.paper_preferred_date"
                                     v-model="projectInfo.paper_limit_date" :class="{'form_text form_sch': true }"
                                     name="projectInfo.paper_limit_date3"
                                     :wrap="true" :config="configs.paper_limit_date" @dp-change="onPaperLimitChange" @dp-update="onNextChange"  @dp-show="onShowChange">
                        </date-picker>
                        <div class="input-group-append cal_icon">
                          <button class="btn datepickerbutton" type="button"></button>
                        </div>
                      </div>
                      <p class="no-margin"><img src="/images/info.png"
                                                srcset="/images/info.png 1x, /images/info@2x.png 2x"
                                                alt="インフォメーション"
                                                class="info_2" @mouseover="mouseOver" @mouseleave="mouseLeave">
                      <p v-show="fukidashi2" class="info_fukidashi_3">質問紙に職場での回収期限を<br>記載したい場合に入力してください。
                      </p>
                      <!--<br v-show="errors[0]"><span class="error" v-show="errors[0]">{{errors[0]}}</span>-->
                    </ValidationProvider>
                    <!--</div>-->

                    <!-- 質問紙BCon到着日 paper_bcon_receipt_date -->
                    <!--<div v-if="projectInfo.medium_type == 3">-->
                    <ValidationProvider name="paper_bcon_receipt_date3" rules="required"
                                        v-slot="{ errors }"
                                        class="validate margin_b_2_4 margin_b_2_3 fx_ce position_r_2_3">
                      <p>質問紙BCon
                        <br>到着日</p>
                      <div class="input-group margin_l_2_6 width-datepicker">
                        <date-picker :disabled="!projectInfo.paper_preferred_date"
                                     v-model="projectInfo.paper_bcon_receipt_date"
                                     :class="{'form_text form_sch': true, 'error': errors[0] }"
                                     name="projectInfo.paper_bcon_receipt_date3"
                                     :wrap="true" :config="configs.paper_bcon_receipt_date"
                                     @dp-change="onPaperBconChange" @dp-update="onNextChange"  @dp-show="onShowChange">
                        </date-picker>
                        <div class="input-group-append cal_icon">
                          <button class="btn datepickerbutton" type="button"></button>
                        </div>
                      </div>
                      <!--<br v-show="errors[0]"><span class="error" v-show="errors[0]">{{errors[0]}}</span>-->
                    </ValidationProvider>
                  </div>

                  <p v-if="projectInfo.medium_type == 3" class="position_ant_2_3">
                    <span>※到着が遅れた場合、報告書納品が遅れる場合がございます。</span></p>

                </div>
              </div>
            </dd>
          </dl>
          <dl class="fx">
            <dt class=" top">報告書納品希望日</dt>
            <dd class="margin_dd">
              <span class="f12">回答スケジュールを起点に報告書納品日が算出されます</span>
              <div class="fx_ce2">
                <div>
                  <ValidationProvider name="report_preferred_date" rules="required"
                                      v-slot="{ errors }"
                                      class="validate fx_ce">
                    <div class="input-group margin_b_2_4_2 form_text_2_3 width-datepicker">
                      <date-picker :disabled="!projectInfo.web_end && !projectInfo.paper_bcon_receipt_date"
                                   v-model="projectInfo.report_preferred_date"
                                   :class="{'form_text form_sch': true, 'error': errors[0] }"
                                   name="projectInfo.report_preferred_date"
                                   :wrap="true" :config="configs.report_preferred_date" @dp-update="onNextChange"  @dp-show="onShowChange">
                      </date-picker>
                      <div class="input-group-append cal_icon">
                        <button class="btn datepickerbutton" type="button"></button>
                      </div>
                    </div>
                    <!--<br v-show="errors[0]"><span class="error" v-show="errors[0]">{{errors[0]}}</span>-->
                  </ValidationProvider>
                </div>

              </div>
            </dd>
          </dl>
        </div>
        <!-- webを選んだ場合 end -->

        <!--industry type-->
        <dl class="fx">
          <dt class=" top">該当する業界・組織規模</dt>
          <dd class="margin_dd">
            <p>業種</p>
            <ValidationProvider name="industry_type" rules=""
                                v-slot="{ errors }"
                                class="validate fx_between">
              <ul class="industry">
                <li v-for="(industry_type, index) in sliced(0, 12, industry_types)">
                  <input :class="{ 'form_radio': true, 'error': errors[0] }" :id="'industry_type' + index"
                         name="industry_type"
                         type="radio"
                         :value="index" v-model="projectInfo.industry_type">
                  <label :for="'industry_type' + index">{{ industry_type }}</label>
                </li>
              </ul>
              <ul class="industry">
                <li v-for="(industry_type, index) in sliced(12, 24, industry_types)">
                  <input :class="{ 'form_radio': true, 'error': errors[0] }" :id="'industry_type' + index"
                         name="industry_type"
                         type="radio"
                         :value="index" v-model="projectInfo.industry_type">
                  <label :for="'industry_type' + index">{{ industry_type }}</label>
                </li>
              </ul>
              <ul class="industry">
                <li v-for="(industry_type, index) in sliced(24, industry_types.length, industry_types)">
                  <input :class="{ 'form_radio': true, 'error': errors[0] }" :id="'industry_type' + index"
                         name="industry_type"
                         type="radio"
                         :value="index" v-model="projectInfo.industry_type">
                  <label :for="'industry_type' + index">{{ industry_type }}</label>
                </li>
              </ul>
              <!--<span class="error" v-show="errors[0]">{{errors[0]}}</span>-->
            </ValidationProvider>
            <!-- scale type -->
            <div class="scale">
              <ValidationProvider name="scale_type" rules=""
                                  v-slot="{ errors }" class="validate">
                <p class="margin_dd">規模</p>
                <div class="scale_1" v-for="i in Math.ceil(Object.keys(scale_types).length / 4)">
                  <p v-for="(scale_type, index) in sliced((i - 1) * 4, i * 4 < Object.keys(scale_types).length ? i * 4 : Object.keys(scale_types).length, scale_types)">
                    <input :class="{ 'form_radio': true, 'error': errors[0] }" :id="'scale_type' + index"
                           name="scale_type" type="radio"
                           :value="index" v-model="projectInfo.scale_type">
                    <label :for="'scale_type' + index">{{ scale_type }}</label>
                  </p>
                </div>
              </ValidationProvider>
            </div>
          </dd>
        </dl>
        <dl class="fx"  v-if="projectInfo.project_type == 4 || projectInfo.project_type == 24">
          <dt class="top">対象</dt>
          <dd class="">
            <div class="scale scale2 no-margin" style="border-top: 0; padding-left: 25px;">
              <div class="row">
                <ValidationProvider name="is_personnal_target1"
                                    v-slot="{ errors }" class="validate col-3 no-space">

                  <input :id="'is_personnal_target1'"
                         :class="{ 'error': errors[0] }" v-model="projectInfo.is_personnal_target1" type="checkbox" @change="uncheckX()"
                         name="is_personnal_target1">
                  <label :class="{ 'error': errors[0], 'check_2_4': true }" :for="'is_personnal_target1'">内定者</label>
                </ValidationProvider>

                <ValidationProvider name="is_personnal_target2"
                                    v-slot="{ errors }" class="validate col-3 no-space">

                  <input :id="'is_personnal_target2'"
                         :class="{ 'error': errors[0] }" v-model="projectInfo.is_personnal_target2" type="checkbox" @change="uncheckX()" value="true"
                         name="is_personnal_target2">
                  <label :class="{ 'error': errors[0], 'check_2_4': true }"
                         :for="'is_personnal_target2'">半年-5年未満</label>

                </ValidationProvider>

                <ValidationProvider name="is_personnal_target3"
                                    v-slot="{ errors }" class="validate col-3 no-space">

                  <input :id="'is_personnal_target3'"
                         :class="{ 'error': errors[0] }" v-model="projectInfo.is_personnal_target3" type="checkbox" @change="uncheckX()"
                         :value="1" name="is_personnal_target3">
                  <label :class="{ 'error': errors[0], 'check_2_4': true }"
                         :for="'is_personnal_target3'">監督職(主任・係長)</label>

                </ValidationProvider>

                <ValidationProvider name="is_personnal_target4"
                                    v-slot="{ errors }" class="validate col-3 no-space">

                  <input :id="'is_personnal_target4'"
                         :class="{ 'error': errors[0] }" v-model="projectInfo.is_personnal_target4" type="checkbox" @change="uncheckX()"
                         :value="1" name="is_personnal_target4">
                  <label :class="{ 'error': errors[0], 'check_2_4': true }" :for="'is_personnal_target4'">役員</label>

                </ValidationProvider>

                <ValidationProvider name="is_personnal_target5"
                                    v-slot="{ errors }" class="validate col-3 no-space">

                  <input :id="'is_personnal_target5'"
                         :class="{ 'error': errors[0] }" v-model="projectInfo.is_personnal_target5" type="checkbox" @change="uncheckX()"
                         :value="1" name="is_personnal_target5">
                  <label :class="{ 'error': errors[0], 'check_2_4': true }" :for="'is_personnal_target5'">新人</label>

                </ValidationProvider>

                <ValidationProvider name="is_personnal_target6"
                                    v-slot="{ errors }" class="validate col-3 no-space">

                  <input :id="'is_personnal_target6'"
                         :class="{ 'error': errors[0] }" v-model="projectInfo.is_personnal_target6" type="checkbox" @change="uncheckX()"
                         :value="1" name="is_personnal_target6">
                  <label :class="{ 'error': errors[0], 'check_2_4': true }" :for="'is_personnal_target6'">5年以上</label>

                </ValidationProvider>

                <ValidationProvider name="is_personnal_target7"
                                    v-slot="{ errors }" class="validate col-3 no-space">

                  <input :id="'is_personnal_target7'"
                         :class="{ 'error': errors[0] }" v-model="projectInfo.is_personnal_target7" type="checkbox" @change="uncheckX()"
                         :value="1" name="is_personnal_target7">
                  <label :class="{ 'error': errors[0], 'check_2_4': true }" :for="'is_personnal_target7'">課長・部長</label>
                </ValidationProvider>
              </div>

            </div>
          </dd>
        </dl>
        <dl class="fx_normal">
          <dt class="top">備考欄</dt>
          <dd>
            <ValidationProvider  name="client_memo" rules="max:1000" v-slot="{ errors }" class="validate">
            <textarea :class="{ 'error': errors[0] }" name="memo" v-model="projectInfo.client_memo" rows="7" cols="40" maxlength="1000"
                      placeholder="診断チームに申し伝えることがあれば入力してください。"></textarea>
              <br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>
            </ValidationProvider>
          </dd>
        </dl>
        <!--<dl class="radio_2">-->
          <!--<dt class="form_dt_3_1_4">個人情報保管期間</dt>-->
          <!--<dd>-->
            <!--<input class="form_radio" id="radio_10" type="radio" value="有り" name="diagnosis_5" checked><label-->
            <!--for="">同意する</label>-->
            <!--<input class="form_radio" id="radio_11" type="radio" value="無し" name="diagnosis_5"><label-->
            <!--for="">同意しない</label>-->
          <!--</dd>-->
        <!--</dl>-->
      </div>

      <div class="form_box form_box_2_3 form_box_3_1">
        <div class="form_box_title">
          <p>詳細情報</p>
        </div>
        <dl>
          <dt class="form_dt_3_1_1 height_23">組織名</dt>
          <dd>
            <input class="form_text tel_number" type="text" name="company" placeholder="株式会社○○○○" v-model="projectInfo.org_name"></dd>
        </dl>
        <dl>
          <dt>診断名</dt>
          <dd><input class="form_text" type="text" name="client" placeholder="例：○○○研修　事前WEB診断" v-model="projectInfo.diagnosis_name"></dd>
        </dl>
        <dl v-if="projectInfo.project_type >= 11 && projectInfo.project_type <= 16">
          <dt class=" form_dt_3_1_3">属性一覧の設定<img src="/images/info.png"
                                                           srcset="/images/info.png 1x, /images/info@2x.png 2x"
                                                           alt="インフォメーション" class="info">
            <p class="info_fukidashi_4">属性一覧とは：回答者の役職・所属などのことです。</p></dt>
          <dd>
            <p class="margin_dd">
              <ValidationProvider name="attribute_setting_type" rules="required" v-slot="{ errors }" class="validate">
                <input :class="{ 'form_radio': true, 'error': errors[0] }" id="attribute_setting_type1" type="radio" value="1"
                       name="attribute_setting_type" v-model="projectInfo.attribute_setting_type"><label
                for="attribute_setting_type1">回答時に選択（推奨）</label>
                <input :class="{ 'form_radio': true, 'error': errors[0] }" id="attribute_setting_type2" type="radio" value="2"
                       name="attribute_setting_type" v-model="projectInfo.attribute_setting_type"><label
                for="attribute_setting_type2">対象者リスト提出時に設定</label>
              </ValidationProvider>
            </p>
          </dd>
        </dl>
        <!--<dl class="radio_2" v-if="projectInfo.project_type >=1 && projectInfo.project_type <= 6">-->
          <!--<dt class="form_dt_3_1_4">属性一覧(Excel)</dt>-->
          <!--<dd class="form_box_6_2">-->
            <!--<router-link class="form_box_sub_2 form_box_sub_5_2" v-if="parseInt(projectInfo.progress_status) >= 2" :to="{ name: 'FileComponent', params: { id: projectInfo.id }, query: {progress: '2.0'}}">ファイルを入力→アップロード</router-link>-->
            <!--<span v-else>未</span>-->
          <!--</dd>-->
        <!--</dl>-->
        <dl class="radio_2" v-if="projectInfo.project_type >=11 && projectInfo.project_type <= 16 && projectInfo.attribute_setting_type == 2">
          <dt class="form_dt_3_1_4">属性一覧(Excel)</dt>
          <dd class="form_box_5_2">
            <router-link class="form_box_sub_2 form_box_sub_5_2" v-if="parseFloat(projectInfo.progress_status) >= 2 && projectInfo.p_attribute_info_file_name" :to="{ name: 'FileComponent', params: { id: projectInfo.id }, query: {progress: '2.0'}}">ファイルを入力→アップロード</router-link>
            <span v-else>未</span>
          </dd>
        </dl>
        <!--<dl class="radio_2" v-if="projectInfo.project_type >=1 && projectInfo.project_type <= 6">-->
          <!--<dt class="form_dt_3_1_4">属性一覧(Excel)</dt>-->
          <!--<dd class="form_box_5_2">-->
            <!--<router-link class="form_box_sub_2 form_box_sub_5_2" v-if="parseFloat(projectInfo.progress_status) >= 2" :to="{ name: 'FileComponent', params: { id: projectInfo.id }, query: {progress: '2.0'}}">ファイルを入力→アップロード</router-link>-->
            <!--<span v-else>未</span>-->
          <!--</dd>-->
        <!--</dl>-->
        <dl>
          <dt class=" height_up_2_3 height_up_3_1_2">ID・パスワード設定</dt>
          <dd>
            <ValidationProvider name="id_setting_type" rules="required" v-slot="{ errors }"
                                class="validate margin_dd fx_st btn_form_3_1">
              <p>ID</p>
              <p class="id_btn1">
                <input :class="{ 'form_radio': true, 'error': errors[0] }" type="radio" name="id_setting_type"
                       v-model="projectInfo.id_setting_type" value="1" id="id_setting_type1">
                <label for="id_setting_type1">事務局(貴社)が設定</label>
              </p>
              <p>
                <input :class="{ 'form_radio': true, 'error': errors[0] }" type="radio" name="id_setting_type"
                       v-model="projectInfo.id_setting_type" value="2" id="id_setting_type2">
                <label for="id_setting_type2">ビジネスコンサルタントが設定</label>
              </p>
            </ValidationProvider>
            <ValidationProvider name="pass_setting_type" rules="required" v-slot="{ errors }"
                                class="validate margin_dd fx_st btn_form_3_1 btn_form_3_1_2">
              <p>パスワード</p>
              <p class="id_btn2">
                <input :class="{ 'form_radio': true, 'error': errors[0] }" type="radio" name="pass_setting_type"
                       v-model="projectInfo.pass_setting_type" value="1" id="pass_setting_type1">
                <label for="pass_setting_type1">事務局(貴社)が設定</label>
              </p>
              <p>
                <input :class="{ 'form_radio': true, 'error': errors[0] }" type="radio" name="pass_setting_type"
                       v-model="projectInfo.pass_setting_type" value="2" id="pass_setting_type2">
                <label for="pass_setting_type2">ビジネスコンサルタントが設定</label>
              </p>
            </ValidationProvider>
          </dd>
        </dl>
        <dl>
          <dt class=" form_box_2_3_dt height_up_3_1">貴社お問合せ先</dt>
          <dd class="margin_dd">
            <!--<p class="form_contact_p">Web画面・質問紙に記載される貴社お問い合わせ先です。</p>-->
            <div class="fx_st">
              <p>部署・役職</p>
              <p><input class="form_text form_sch" type="text" name="inquiry_division" placeholder="◯◯部　部長" maxlength="50"
                        v-model="projectInfo.inquiry_division">
              </p>
            </div>
            <div class="fx_st">
              <p>氏名</p>
              <p class="margin_dd2"><input class="form_text form_sch" type="text" name="inquiry_name" placeholder="田中　一郎" maxlength="20"
                                           v-model="projectInfo.inquiry_name">
              </p>
            </div>
            <div class="fx_st">
              <p class="sch_1">電話番号</p>
              <p class="margin_dd3">
                <ValidationProvider name="inquiry_tel" :rules="{numeric: true, regex: /^[0-9a-zA-Z]*$/}"
                                    v-slot="{ errors }" class="validate">
                <input class="form_text form_sch" type="tel" pattern="[0-9]+" maxlength="11" name="inquiry_tel" placeholder="03xxxxxxxx"
                                           v-model="projectInfo.inquiry_tel"
                                           onKeyUp="$(this).val($(this).val().replace(/[^\d]/ig, ''))" autocomplete="none"
                                           @keypress="preventAlphabetInput($event)">
                </ValidationProvider>
              </p>
            </div>
            <ValidationProvider name="inquiry_email" rules="email" v-slot="{ errors }" class="validate">
              <div class="fx_st">
                <p class="sch_1">メール</p>
                <p class="margin_dd4">
                  <input :class="{ 'form_text form_sch': true, 'error': errors[0] }" type="text" maxlength="100"
                         name="inquiry_email"
                         placeholder="Ichiro.t@cliant.co.jp" v-model="projectInfo.inquiry_email"
                         onKeyUp="$(this).val($(this).val().replace(/[^a-zA-Z.\-+@_\d]/ig, ''))">
                </p>
              </div>
              <span class="error left-95" v-show="errors[0]">{{ errors[0] }}</span>
            </ValidationProvider>
          </dd>
        </dl>

        <dl v-if="projectInfo.medium_type != 1 && projectInfo.project_type >= 11 && projectInfo.project_type <= 16">
          <dt class="form_dt_3_1_5">質問紙提出先<img src="/images/info.png" srcset="/images/info.png 1x, /images/info@2x.png 2x"
                                               alt="インフォメーション" class="info">
            <p class="info_fukidashi_10">質問紙に記載されます。（下記例）<br>
              <img src="/images/sample02.png" alt="サンプル"></p></dt>
          <dd>
            <input class="form_text tel" type="text" name="paper_recipient" placeholder="例：●●部署 ▲▲さん" v-model="projectInfo.paper_recipient" maxlength="50">
          </dd>
        </dl>
        <!--v-if="projectInfo.project_type >= 11 && projectInfo.projec_type <=16"-->
        <!--<dl class="radio_2" v-if="projectInfo.project_type >= 11 && projectInfo.projec_type <=16">-->
        <dl class="radio_2" v-if="projectInfo.project_type == 11">
          <dt class="form_dt_3_1_4 top">業種別比較</dt>
          <dd>
            <ValidationProvider name="comparison_industry_type" rules="required" v-slot="{ errors }" class="validate">
              <input :class="{ 'form_radio': true, 'error' : errors[0] }" type="radio" name="comparison_industry_type"
                     v-model="projectInfo.comparison_industry_type" value="1" id="comparison_industry_type1">
              <label for="comparison_industry_type1">有り</label>
              <input :class="{ 'form_radio': true, 'error' : errors[0] }" type="radio" name="comparison_industry_type"
                     v-model="projectInfo.comparison_industry_type" value="0" id="comparison_industry_type2">
              <label for="comparison_industry_type2">無し</label>
              <!--<br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>-->
            </ValidationProvider>
          </dd>
        </dl>

        <dl class="radio_2">
          <dt class="form_dt_3_1_4 top">回答者への案内メール</dt>
          <dd>
            <ValidationProvider name="guidance_email_type" rules="required" v-slot="{ errors }" class="validate">
              <input :class="{ 'form_radio': true, 'error' : errors[0] }" id="guidance_email_type_1" type="radio" value="1" name="guidance_email_type"
                     v-model="projectInfo.guidance_email_type">
              <label for="guidance_email_type_1">BConがメール送信</label>
              <input :class="{ 'form_radio': true, 'error' : errors[0] }" id="guidance_email_type_2" type="radio" value="2" name="guidance_email_type"
                     v-model="projectInfo.guidance_email_type">
              <label for="guidance_email_type_2">お客様が実施</label>
            </ValidationProvider>
          </dd>
        </dl>

        <dl class="radio_2" v-if="projectInfo.project_type == 11">
          <dt class="form_dt_3_1_4">質問文の主語変更</dt>
          <dd>
            <div style="display: flex; align-items: center">
              <ValidationProvider name="question_subject_type_other" rules="required" v-slot="{ errors }" class="validate">
                <input  :class="{ 'form_radio': true, 'error' : errors[0] }" id="radio_3_1_3" type="radio" value="当社" name="question_subject_type"
                        @change="subjectType()"
                        v-model="question_subject_type_other"><label
                for="radio_3_1_3">当社</label>
                <input  :class="{ 'form_radio': true, 'error' : errors[0] }" id="radio_3_1_4" type="radio" value="私の職場" name="question_subject_type"
                        @change="subjectType()"
                        v-model="question_subject_type_other"><label
                for="radio_3_1_4">私の職場</label>
                <input  v-if="(!isEmpty(question_subject_type_other_text) || other) && question_subject_type_other != '1'" class="form_radio" id="radio_3_1_5" type="radio"
                        name="question_subject_type" @change="subjectType()"
                        v-model="question_subject_type_other"
                        :value="question_subject_type_other_text">
                <input  :class="{ 'form_radio': true, 'error' : errors[0] }" id="radio_3_1_5" type="radio"
                        name="question_subject_type" @change="subjectType()"
                        v-model="question_subject_type_other"
                        :value="1"
                        v-else>
                <label class="no-margin" for="radio_3_1_5">その他</label>
              </ValidationProvider>
              <ValidationProvider style="display: inline-block; width: 50%" name="question_subject_type_other_text"
                                  :rules="((question_subject_type_other !== '当社' && question_subject_type_other !== '私の職場' && empty(question_subject_type_other))) || question_subject_type_other == '1' ? 'required' : ''"
                                  v-slot="{ errors }" class="validate">
                <input :class="{ 'form_text tel_number': true, 'error': errors[0] }" type="text" name="other" maxlength="20"
                       placeholder="例：部門、本部、事業部" v-model="question_subject_type_other_text" @input="inputOther()" style="max-width: 100%">
                <br v-show="errors[0]"><span style="padding-left: 0px" class="error">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </dd>
        </dl>

        <dl class="radio_2 fx" v-if="projectInfo.project_type !== 11 && projectInfo.project_type > 11 && projectInfo.project_type <= 16">
          <dt>追加質問文</dt>
          <dd class="padding_t">
            <span class="margin_no">※見積・報告書形式については担当営業にお問い合わせください。</span>
            <p>
              <input class="none" id="qestion_type1" type="checkbox"  name="qestion_type1"
                     :checked="qestion_type1" @click="uncheck1()">
              <label class="check_2_3" for="qestion_type1">追加質問が5問以内/追加質問の尺度が下記の7点法を利用</label>
            </p>
            <p class="padding_b">
              <input class="none" id="qestion_type2" type="checkbox"
                     :checked="qestion_type2" name="qestion_type2"  @click="uncheck2()">
              <label class="check_2_3" for="qestion_type2">追加質問が6問以上/追加質問の尺度が7点法以外(個別対応)</label>
            </p>
            <div id="seven" v-if="projectInfo.qestion_type == 1">
              <div class="b_bottom">
                <p><span class="margin_no">【7点法尺度】</span></p>
                <p><img style="max-width: 100%" src="/images/img3-1-2@2x.png" width="266" height="137"></p>
              </div>
              <div class="b_bottom2">
                <div v-for="(question, i) in projectInfo.questions" class="fx_st2">
                  <input type="hidden" v-model="question.id">
                  <div>
                    <p><span class="margin_no">質問文</span></p>
                    <p>
                      <ValidationProvider name="body" rules="max:100"
                                          v-slot="{ errors }" class="validate">
                        <textarea :class="{'error': errors[0] }" v-model="question.body" :name="'body' + i" rows="3" cols="40" id="textarea_3_1" placeholder="質問文を入れてください。" maxlength="200"></textarea>
                        <br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </p>
                  </div>
                  <div>
                    <p><span>回答方式</span></p>
                    <ValidationProvider :name="'format_type' + i" :rules="{required: question.body !== '' }"
                                        v-slot="{ errors }" class="validate">
                      <p>
                        <input :class="{ 'form_radio': true, 'error': errors[0] }" :id="'answer1' + i" type="radio" value="1" :name="'format_type' + i" v-model="question.format_type">
                        <label :for="'answer1' + i">尺度(7点法)</label>
                      </p>
                      <p>
                        <input :class="{ 'form_radio': true, 'error': errors[0] }" :id="'answer2' + i" type="radio" value="2" :name="'format_type' + i" v-model="question.format_type">
                        <label :for="'answer2' + i">自由記入</label>
                      </p>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="action">
                  <button :class="{'add': true}" :disabled="projectInfo.questions.length == 5" type="button" @click="addQuestion">＋</button>
                  <button class="del" type="button" @click="removeQuestion">－</button>
                </div>
              </div>
            </div>
          </dd>
        </dl>

        <dl class="radio_2" v-if="projectInfo.project_type >= 11 && projectInfo.project_type <= 16 && projectInfo.attribute_setting_type == 2">
          <dt class="form_dt_3_1_4">対象者リスト(Excel)</dt>
          <dd class="form_box_5_2">
            <router-link class="form_box_sub_2 form_box_sub_5_2" v-if="projectInfo.p_target_people_file_name" :to="{ name: 'FileComponent', params: { id: projectInfo.id }, query: {progress: '2.1'}}">ファイルを入力→アップロード</router-link>
            <span v-else>未</span>
          </dd>
        </dl>

        <dl class="radio_2" v-if="projectInfo.project_type >= 21 && projectInfo.project_type<= 24 ">
          <dt class="form_dt_3_1_4">対象者リスト(Excel)</dt>
          <dd class="form_box_5_2">
            <router-link class="form_box_sub_2 form_box_sub_5_2" v-if="projectInfo.p_multifaceted_file_name" :to="{ name: 'FileComponent', params: { id: projectInfo.id }, query: {progress: '2.0'}}">ファイルを入力→アップロード</router-link>
            <span v-else>未</span>
          </dd>
        </dl>

        <dl class="radio_2" v-if="(projectInfo.project_type >=1 && projectInfo.project_type <= 6) || (projectInfo.project_type >=11 && projectInfo.project_type <=16 && projectInfo.attribute_setting_type == 1)">
          <dt class="form_dt_3_1_4">対象者リスト(Excel)</dt>
          <dd class="form_box_5_2" v-if="projectInfo.project_type >=1 && projectInfo.project_type <= 6">
            <router-link class="form_box_sub_2 form_box_sub_5_2" v-if="projectInfo.p_personal_target_file_name" :to="{ name: 'FileComponent', params: { id: projectInfo.id }, query: {progress: '2.0'}}">ファイルを入力→アップロード</router-link>
            <span v-else>未</span>
          </dd>
          <dd class="form_box_5_2" v-else>
            <router-link class="form_box_sub_2 form_box_sub_5_2" v-if="projectInfo.p_target_people_file_name" :to="{ name: 'FileComponent', params: { id: projectInfo.id }, query: {progress: '2.1'}}">ファイルを入力→アップロード</router-link>
            <span v-else>未</span>
          </dd>
        </dl>

      </div>


      <div class="footer_box footer_box_1_3">
        <p class="btn"><input class="form_box_sub form_box_sub_2" type="submit" value="保存"></p>
      </div>
    </ValidationObserver>

    <b-modal name="deletePopup" id="deletePopup" ref="deletePopup" :modal-class="['modal-action  ']"
             :centered="true" :no-close-on-backdrop="true" header-close-label="" hide-footer hide-header>
      <h5 class="title">{{ projectInfo.client_name }}を削除しますか？</h5>
      <div class="modal-footer top-30">
        <b-button class="btn-submit" @click="$bvModal.hide('deletePopup')">キャンセル</b-button>
        <a class="btn btn-red" @click="deleteProject()">削除</a>
      </div>
    </b-modal>

    <b-modal name="isDisabled" id="isDisabled" ref="isDisabled" :modal-class="['modal-action  ']"
             :centered="true" :no-close-on-backdrop="true" header-close-label="" hide-footer hide-header>
      <h5 class="title">リスト提出締切日を{{ projectInfo.is_deadline_disabled == true ? '無効' : '有効'  }}にしますか？</h5>
      <div class="modal-footer top-30">
        <b-button class="btn-submit" @click="closeDeadline()">キャンセル</b-button>
        <a class="btn btn-red" @click="changeDeadline()">はい</a>
      </div>
    </b-modal>

    <b-modal name="endprojectInfo" id="endprojectInfo" ref="endprojectInfo" :modal-class="['modal-action  ']"
             :centered="true" :no-close-on-backdrop="true" header-close-label="" hide-footer hide-header>
      <h5 class="title">案件が完了しますか？</h5>
      <div class="modal-footer top-30">
        <b-button class="btn-submit" @click="closeEnd()">キャンセル</b-button>
        <a class="btn btn-red" @click="end()">完了</a>
      </div>
    </b-modal>



    <b-modal name="sendPasswordPopup" id="sendPasswordPopup" ref="sendPasswordPopup" :modal-class="['modal-action']"
             :centered="true" :no-close-on-backdrop="true" header-close-label="" hide-footer hide-header>
      <h5 class="title">パスワードをリセットしてユーザーに送信しますか？</h5>
      <div class="modal-footer top-30 resend">
        <b-button class="btn-submit" @click="$bvModal.hide('sendPasswordPopup')">キャンセル</b-button>
        <a class="btn btn-primary" @click="sendPassword()">アカウント再発行</a>
      </div>
    </b-modal>
  </div>


</template>
<script>
  import {mapActions, mapMutations, mapState} from 'vuex'
  import {
    ATTRIBUTE_SETTING_TYPE,
    GUIDENCE_EMAIL_TYPE,
    INDUSTRY_TYPE,
    MEDIUM_TYPE,
    PROJECT_STATUS,
    PROJECT_TYPE,
    REPORT_PREFERRER_DATE,
    SCALE_TYPE,
    SELECT_KUBUN_TYPE,
    SETTING_TYPE
  } from './../../../contstant'

  import moment from 'moment'

  moment.locale('ja', {
    months: '1月,2月,3月,4月,5月,6月,7月,8月,9月,10月,11月,12月'.split(','),
    monthsShort: '1月,2月,3月,4月,5月,6月,7月,8月,9月,10月,11月,12月'.split(','),
    monthsParseExact: true,
    weekdays: '日,月,火,水,木,金,土'.split(','),
    weekdaysShort: '日,月,火,水,木,金,土'.split(','),
    weekdaysMin: '日,月,火,水,木,金,土'.split(','),
    weekdaysParseExact: true
  })

  export default {
    data() {
      return {
        select_kubun_types: SELECT_KUBUN_TYPE,
        scale_types: SCALE_TYPE,
        industry_types: INDUSTRY_TYPE,
        medium_types: MEDIUM_TYPE,
        setting_types: SETTING_TYPE,
        guidance_email_types: GUIDENCE_EMAIL_TYPE,
        attribute_setting_types: ATTRIBUTE_SETTING_TYPE,
        hover: false,
        progress_end: false,
        link_download_pai: '/api/admin/pai-download',
        link_download_ptp: '/api/admin/ptp-export/',
        link_download_pmf: '/api/admin/pmf-export/',
        link_download_ppt_invidual: '/api/admin/ppt-download/',
        project_types: [],
        report_preferrer_date_conditions: REPORT_PREFERRER_DATE,
        deadlineSubmit: null,
        configs: {
          web_start: {
            allowInputToggle: true,
            format: 'YYYY-MM-DD',
            useCurrent: false,
            inline: false
          },
          web_end: {
            allowInputToggle: true,
            format: 'YYYY-MM-DD',
            useCurrent: false,
            // inline: true
          },
          paper_preferred_date: {
            allowInputToggle: true,
            format: 'YYYY-MM-DD',
            useCurrent: false
          },
          paper_limit_date: {
            allowInputToggle: true,
            format: 'YYYY-MM-DD',
            useCurrent: false
          },
          paper_bcon_receipt_date: {
            allowInputToggle: true,
            format: 'YYYY-MM-DD',
            useCurrent: false
          },
          report_preferred_date: {
            allowInputToggle: true,
            format: 'YYYY-MM-DD',
            useCurrent: false
          },
          date: {
            allowInputToggle: true,
            format: 'YYYY-MM-DD',
            useCurrent: false,
            inline: false
          }
        },
        fukidashi2: false,
        searchAddErr: '',
        searchAddErr1: '',
        searchAddErr2: '',
        question_subject_type_other: '',
        question_subject_type_other_text: '',
        other: false,
        qestion_type1: false,
        qestion_type2: false
      }
    },
    computed: {
      ...mapState('project', ['projectInfo', 'query', 'projects']),
      ...mapState('consultantUser', ['user', 'project', 'holidays'])
    },
    watch: {
    "projectInfo.progress_status": function () {
      if (this.projectInfo.progress_status > 3) {
        this.progress_end = true
        this.$router.push({name: 'ProjectList'});
      }
    },
      'projectInfo.medium_type': function () {
        if ((this.projectInfo.medium_type == 2 || this.projectInfo.medium_type == 3) && ((this.projectInfo.project_type >= 1 && this.projectInfo.project_type <= 6)  || (this.projectInfo.project_type >= 21 && this.projectInfo.project_type <= 24))) {
          this.projectInfo.paper_send_type = 1
        } else if (this.projectInfo.medium_type == 1 && !((this.projectInfo.project_type >= 1 && this.projectInfo.project_type <= 6)|| (this.projectInfo.project_type >= 21 && this.projectInfo.project_type <= 24))) {
          this.projectInfo.paper_send_type = null
        }
        // this.projectInfo.report_preferred_date = null
      },
    'projectInfo.question_subject_type': function () {
      if (this.projectInfo.question_subject_type !== '' && this.projectInfo.question_subject_type !== '当社' && this.projectInfo.question_subject_type !== '私の職場') {
        this.question_subject_type_other_text = this.projectInfo.question_subject_type
      }

      if (this.question_subject_type_other === '') {
        this.question_subject_type_other = this.projectInfo.question_subject_type
      }
    },
    question_subject_type_other_text() {
      if (this.question_subject_type_other_text) {
        this.question_subject_type_other = this.question_subject_type_other_text
        this.projectInfo.question_subject_type = this.question_subject_type_other_text
      }
    },
      'projectInfo.qestion_type': function() {
        if (this.projectInfo.qestion_type == 1) {
          console.log('qestion_type1')
          this.qestion_type1 =  true
        }
        if (this.projectInfo.qestion_type == 2) {
          console.log('qestion_type2')
          this.qestion_type2 =  true
        }
        if (this.projectInfo.qestion_type == 1 && this.projectInfo.questions.length === 0) {
          const newQ = {
            id: '',
            body: '',
            format_type: ''
          }
          this.projectInfo.questions.push(newQ)
        }
      },
      projectInfo() {
        if (PROJECT_TYPE[0][this.projectInfo.project_type]) {
          this.project_types = PROJECT_TYPE[0]
        } else if (PROJECT_TYPE[0][this.projectInfo.project_type]) {
          this.project_types = PROJECT_TYPE[1]
        }

        if (this.projectInfo.qestion_type == 1 && this.projectInfo.questions.length === 0) {
          const newQ = {
            id: '',
            body: '',
            format_type: ''
          }
          this.project.questions.push(newQ)
        }

        if (this.projectInfo.question_subject_type !== '' && this.projectInfo.question_subject_type !== '当社' && this.projectInfo.question_subject_type !== '私の職場') {
          this.question_subject_type_other_text = this.projectInfo.question_subject_type
        }

        if (this.projectInfo.user.length == 1) {
          this.projectInfo.user.push({
            name: '',
            email: ''
          })
        }
      },
      'projectInfo.web_end': function () {
        let reportDate = this.getInputDate()
        if (moment(reportDate).isValid()) {
          this.$set(this.configs.report_preferred_date, 'minDate', moment(reportDate).add(0, 'days') || null);
        }
        this.getDeadlineSubmit()
      },
      'projectInfo.paper_bcon_receipt_date': function () {
        let reportDate = this.getInputDate()
        if (moment(reportDate).isValid()) {
          this.$set(this.configs.report_preferred_date, 'minDate', moment(reportDate).add(0, 'days') || null);
        }
      },
      'projectInfo.web_start': function () {
        this.getDeadlineSubmit()
        let reportDate = this.getInputDate()
        if (moment(reportDate).isValid()) {
          this.$set(this.configs.report_preferred_date, 'minDate', moment(reportDate).add(0, 'days') || null);
        }
      },
    'projectInfo.paper_preferred_date': function () {
      this.getDeadlineSubmit()
    },
      'projectInfo.paper_people': function () {
        let dateInput = this.getInputDate()

        let paperMin = this.getPaperDate()
        if (moment(paperMin).isValid()) {
          this.$set(this.configs.paper_preferred_date, 'minDate', paperMin || null);
          this.$set(this.configs.paper_limit_date, 'minDate', paperMin || null);
          this.$set(this.configs.paper_bcon_receipt_date, 'minDate', moment(paperMin).add(1, 'days') || null);
        }

        if (moment(dateInput).isValid()) {
          this.$set(this.configs.report_preferred_date, 'minDate', false || null);
          this.$set(this.configs.report_preferred_date, 'minDate', dateInput || null);
        }
      },
      holidays() {
        this.getDeadlineSubmit()
        let dateInput = this.getInputDate()
        let paperDate = this.getPaperDate()

        let paper_preferred_date = this.projectInfo.paper_preferred_date;
        let paper_limit_date = this.projectInfo.paper_limit_date;

        // if (paperDate < paper_preferred_date) {
        //   paperDate = paper_preferred_date
        // }

        let paper_bcon_receipt_date = this.projectInfo.paper_bcon_receipt_date
        if (paper_bcon_receipt_date && paper_limit_date && paper_bcon_receipt_date >= paper_limit_date) {
          paper_bcon_receipt_date = moment(paper_limit_date).add(1, 'days')
        } else {
          paper_bcon_receipt_date = moment(paper_preferred_date).add(1, 'days')
        }

        if (paperDate && moment(paperDate).isValid()) {
          this.$set(this.configs.paper_preferred_date, 'minDate', paperDate || null);
          this.$set(this.configs.paper_limit_date, 'minDate', moment(paperDate).add(1, 'days') || null);
          this.$set(this.configs.paper_bcon_receipt_date, 'minDate', moment(paperDate).add(1, 'days') || null);
        }
        if (dateInput && moment(dateInput).isValid()) {
          this.$set(this.configs.report_preferred_date, 'minDate', false || null);
          this.$set(this.configs.report_preferred_date, 'minDate', dateInput || null);
        }

        if (this.projectInfo.medium_type == 2) {
          if (this.projectInfo.paper_bcon_receipt_date && dateInput < this.projectInfo.paper_bcon_receipt_date) {
            this.report_preferred_date = this.projectInfo.paper_bcon_receipt_date
            this.$set(this.configs.paper_bcon_receipt_date, 'minDate', false || null);
            let dateReport = moment(this.projectInfo.paper_bcon_receipt_date).add(0, 'days').format('YYYY-MM-DD')
            if (moment(dateReport).isValid()) {
              this.$set(this.configs.report_preferred_date, 'minDate', dateReport || null);
            }
          }
        } else if (this.projectInfo.medium_type == 3) {
          if (this.projectInfo.web_end || this.projectInfo.paper_bcon_receipt_date) {
            let dateReport = ''
            if (this.projectInfo.paper_bcon_receipt_date > this.projectInfo.web_end) {
              dateReport = this.projectInfo.paper_bcon_receipt_date;
            } else {
              dateReport = this.projectInfo.web_end
            }

            if (moment(dateReport).isValid()) {
              this.report_preferred_date = this.projectInfo.paper_bcon_receipt_date
              this.$set(this.configs.paper_bcon_receipt_date, 'minDate', false || null);
              this.$set(this.configs.report_preferred_date, 'minDate', dateReport || null);
            }
          }
        }

        this.report_preferred_date = dateInput
        // let fiveDate = moment().add(6, 'days').format('YYYY-MM-DD')
        let days = this.getDates(moment().format('YYYY-MM-DD'), moment().add(30, 'days').format('YYYY-MM-DD'))
        days.sort(function (a, b) {
          return new Date(a) - new Date(b);
        });

        let fiveDate = null
        let i = 0
        _.forEach(days, (d) => {
          if (!this.holidays.includes(d) && i <= 6) {
            fiveDate = d
            i = i + 1
          }
        })

        if (moment(fiveDate).isValid()) {
          this.$set(this.configs.web_start, 'minDate', moment(fiveDate).format('YYYY-MM-DD') || null);
          this.$set(this.configs.web_end, 'minDate', moment(fiveDate).add(1, 'days').format('YYYY-MM-DD') || null);
        }

        this.$set(this.configs.paper_preferred_date, 'disabledDates', this.holidays || null);
        this.$set(this.configs.paper_bcon_receipt_date, 'disabledDates', this.holidays || null);
        this.$set(this.configs.paper_limit_date, 'disabledDates', false);
        this.$set(this.configs.web_end, 'disabledDates', this.holidays || null);
        this.$set(this.configs.web_start, 'disabledDates', this.holidays || null);
        if (this.projectInfo.medium_type != 3 && this.projectInfo.medium_type !== undefined) {
          this.$set(this.configs.report_preferred_date, 'disabledDates', this.holidays || null);
        }
      }
    },
    methods: {
      ...mapMutations('project', ['PROJECT']),
      ...mapActions('project', ['GET_PROJECT_DETAIL']),
      ...mapMutations('consultantUser', ['SET_HOLIDAYS']),
      ...mapActions('consultantUser', ['GET_HOLIDAYS']),
      uncheckX() {
        if (this.projectInfo.is_personnal_target1 == false) {
          this.projectInfo.is_personnal_target1 = null
        }
        if (this.projectInfo.is_personnal_target2 == false) {
          this.projectInfo.is_personnal_target2 = null
        }
        if (this.projectInfo.is_personnal_target3 == false) {
          this.projectInfo.is_personnal_target3 = null
        }
        if (this.projectInfo.is_personnal_target4 == false) {
          this.projectInfo.is_personnal_target4 = null
        }
        if (this.projectInfo.is_personnal_target5 == false) {
          this.projectInfo.is_personnal_target5 = null
        }
        if (this.projectInfo.is_personnal_target6 == false) {
          this.projectInfo.is_personnal_target6 = null
        }
        if (this.projectInfo.is_personnal_target7 == false) {
          this.projectInfo.is_personnal_target7 = null
        }

      },
      isEmpty(val) {
        return _.isEmpty(val)
      },
      inputOther() {
        if (_.isEmpty(this.question_subject_type_other_text)) {
          this.question_subject_type_other = 1
        }
        this.other = true
      },
      progressStatus() {
        return PROJECT_STATUS[parseInt(this.projectInfo.progress_status)] ? PROJECT_STATUS[parseInt(this.projectInfo.progress_status)] : ''
      },
      showDelete() {
        this.$refs.deletePopup.show();
      },
      deleteProject() {
        this.$store.dispatch('project/DELETE_PROJECT', this.projectInfo.id).then(() => {
          this.$refs.deletePopup.hide();
          this.$router.push({name: 'ProjectList'});
        });
      },
      resendPassword() {
        this.$refs.sendPasswordPopup.show()
      },
      sendPassword() {
        this.$refs.sendPasswordPopup.hide();
        this.$wait.start('loading')
        this.$store.dispatch('project/SEND_PASSWORD', this.projectInfo).then(() => {
          this.$wait.end('loading')
        });
      },
      logout() {
        this.$store.dispatch('consultantUser/AUTH_LOGOUT').then(() => {
        }).catch(e => {

        })
      },
      addQuestion() {
        const newQ = {
          id: '',
          body: '',
          format_type: ''
        }
        if (this.projectInfo.questions.length <= 4) {
          this.projectInfo.questions.push(newQ)
        }
      },
      removeQuestion() {
        if (this.projectInfo.questions.length > 1) {
          this.projectInfo.questions.pop()
        }
      },
      mouseOver() {
        this.hover = !this.hover
      },
      uncheck1() {
        if (this.qestion_type1) {
          this.qestion_type1 = null
          if (this.projectInfo.qestion_type == 1) {
            this.projectInfo.qestion_type = null
          }

        } else {

          this.projectInfo.qestion_type = 1
          if (this.qestion_type2) {
            this.qestion_type2 = null
          }
        }

      },
      uncheck2() {
        if (this.qestion_type2) {
          if (this.projectInfo.qestion_type == 2) {
            this.projectInfo.qestion_type = null
          }
          this.qestion_type2 = null
        } else {
          this.projectInfo.qestion_type = 2
          if (this.qestion_type1) {
            this.qestion_type1 = null
          }
        }

      },
      projectStatusNumber() {
        let status = parseInt(this.projectInfo.progress_status) + 1
        let text = ''
        switch (status) {
          case 1:
            text = '&#9312;'
            break
          case 2:
            text = '&#9313;'
            break
          case 3:
            text = '&#9314;'
            break
          case 4:
            text = '&#9315;'
            break
          case 5:
            text = '&#9316;'
            break
          default:
        }
        return text
      },
      projectType(project_type) {
        let type = ''
        if (PROJECT_TYPE[0][project_type]) {
          type = PROJECT_TYPE[0][project_type]
        } else if (PROJECT_TYPE[1][project_type]) {
          type = PROJECT_TYPE[1][project_type]
        }
        return type
      },
      empty(value) {
        return !_.isEmpty(value)
      },
      changeDeadlineDisabled() {
        this.$refs.isDisabled.show()
      },
      endProject() {
        if (this.progress_end) {
          this.$refs.endprojectInfo.show()
        }
      },
      closeEnd() {
        this.progress_end = false
        this.$refs.endprojectInfo.hide()
      },
      end() {
        this.$wait.start('loading')
        this.$store.dispatch('project/UPDATE_PROJECT', {
          'project': {
            progress_status: 4,
            id: this.projectInfo.id,
            'completed_user_id': this.projectInfo.id,
            // 'is_deadline_disabled': true
          }, 'url': '/api/admin/projects'
        }).then((data) => {
          this.$wait.end('loading')
          // this.projectInfo.progress_status = this.projectInfo.progress_status
          // this.$store.dispatch()
          this.$refs.endprojectInfo.hide()
        }).catch(e => {
          this.$wait.end('loading')
          this.$refs.endprojectInfo.end()
        })
      },
      changeDeadlineDisabled() {
        this.$refs.isDisabled.show()
      },
      closeDeadline() {
        this.projectInfo.is_deadline_disabled = null
        this.$refs.isDisabled.hide()
      },
      changeDeadline() {
        this.$wait.start('loading')
        this.$store.dispatch('project/UPDATE_PROJECT', {
          'project': this.projectInfo, 'url': '/api/admin/projects'
        }).then((data) => {
          this.$wait.end('loading')
          this.$refs.isDisabled.hide()
        }).catch(e => {
          this.$wait.end('loading')
          this.$refs.isDisabled.end()
        })
      },
      changeDiagnosis() {
        this.projectInfo.project_type = null
      },
      async editProject() {
        const isValid = await this.$refs.observer.validate();
        if (!isValid) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.observer.errors)
              .map(([key, value]) => ({key, value}))
              .filter(error => error["value"].length);
            this.$refs.observer.refs[errors[0]["key"]].$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });


          }, 100);
          return
          // ABORT!!
        } else {
          this.$wait.start('loading')
          delete this.projectInfo.preentry_alert_date;
          delete this.projectInfo.postentry_alert_date;
          this.$store.dispatch('project/UPDATE_PROJECT', {
            'project': this.projectInfo, 'url': '/api/admin/projects'
          }).then((data) => {
            // window.scrollTo(0,0)
            this.$wait.end('loading')
            this.$router.push({
              name: 'ProjectDetail',
              params: {
                id: this.projectInfo.id
              }
            })
          })
        }
      },
      changeMedium() {
        this.projectInfo.web_start = null
        this.projectInfo.web_end = null
        this.projectInfo.paper_preferred_date = null
        this.projectInfo.paper_limit_date = null
        this.projectInfo.paper_bcon_receipt_date = null
        if (this.projectInfo.medium_type == 1) {
          this.projectInfo.paper_send_type = null
        }

        let dateInput = this.getInputDate()
        if (moment(dateInput).isValid()) {
          this.$set(this.configs.report_preferred_date, 'minDate', dateInput || null);

          this.report_preferred_date = dateInput
        }

        this.projectInfo.web_people = null
        this.projectInfo.paper_people = null
        this.$forceUpdate()
      },
      mouseOver() {
        this.fukidashi2 = true
      },
      mouseLeave() {
        this.fukidashi2 = false
      },
      sliced(from, to, data) {
        const sliced = Object.keys(data).slice(from, to).reduce((result, key) => {
          result[key] = data[key];

          return result;
        }, {});
        return sliced
      },
      searchAddress() {
        this.searchAddErr= ''
        this.$store.dispatch('project/SEARCH_ADDRESS', this.projectInfo.zip_code).then((data) => {
          if (_.isEmpty(this.projectInfo.address1)) {
            this.searchAddErr = '郵便番号が存在しません'
          }
        })
      },
      searchAddressPaper() {
        this.searchAddErr1 = ''
        this.$store.dispatch('project/SEARCH_ADDRESS_PAPER', this.projectInfo.paper1_zip_code).then(() => {
          if (_.isEmpty(this.projectInfo.paper1_address1)) {
            this.searchAddErr1 = '郵便番号が存在しません'
          }
        })
      },
      searchAddressPaper2() {
        this.searchAddErr2 = ''
        this.$store.dispatch('project/SEARCH_ADDRESS_PAPER2', this.projectInfo.paper2_zip_code).then(() => {
          if (_.isEmpty(this.projectInfo.paper2_address1)) {
            this.searchAddErr2 = '郵便番号が存在しません'
          }
        })
      },
      onStartChange(e) {
        if (e.date) {
          this.projectInfo.report_preferred_date = null
          this.projectInfo.web_end = null
          this.getDeadlineSubmit()
          this.$set(this.configs.web_end, 'minDate', false || null);
          this.$set(this.configs.web_end, 'minDate', moment(e.date).add(1, 'days') || null);
          setTimeout(() => {
            if (document.querySelector('.next.disabled')) {
              document.querySelector('.next.disabled').classList.remove('disabled')
            }
          }, 200)
        }
      },
      onEndChange(e) {
        if (e.date) {
          this.$set(this.configs.report_preferred_date, 'disabledDates', this.holidays || null);
          this.projectInfo.report_preferred_date = null
          if (this.report_preferred_date < this.projectInfo.web_end) {
            this.report_preferred_date = this.projectInfo.web_end
            this.$set(this.configs.report_preferred_date, 'minDate', false || null);
            let dateInput = moment(this.projectInfo.web_end).add(0, 'days').format('YYYY-MM-DD')
            this.$set(this.configs.report_preferred_date, 'minDate', dateInput || null);
            setTimeout(() => {
              if (document.querySelector('.next.disabled')) {
                document.querySelector('.next.disabled').classList.remove('disabled')
              }
            }, 400)
          }
        }
      },
      onNextChange(e) {
        setTimeout(() => {
          if (document.querySelector('.next.disabled')) {
            document.querySelector('.next.disabled').classList.remove('disabled')
          }
        }, 200)
      },
      onShowChange(e) {
        if (document.querySelector('.next.disabled')) {
          document.querySelector('.next.disabled').classList.remove('disabled')
        }
      },
      onPaperPrefererChange(e) {
        if (e.date) {
          this.projectInfo.report_preferred_date = null
          this.projectInfo.paper_limit_date = null
          this.projectInfo.paper_bcon_receipt_date = null
          // this.$set(this.configs.paper_limit_date, 'minDate', e.date || null);
          this.$set(this.configs.paper_bcon_receipt_date, 'minDate', e.date || null);
          if (this.projectInfo.paper_limit_date < this.projectInfo.paper_preferred_date) {
            this.projectInfo.paper_limit_date = null;
          }
          if (this.projectInfo.paper_bcon_receipt_date < this.projectInfo.paper_preferred_date) {
            this.projectInfo.paper_bcon_receipt_date = null;
          }

          this.$set(this.configs.paper_limit_date, 'minDate', moment(e.date).add(1, 'days') || null);
          this.$set(this.configs.paper_bcon_receipt_date, 'minDate', moment(e.date).add(1, 'days') || null);
        }
      },
      onPaperLimitChange(e) {
        if (e.date) {
          this.projectInfo.report_preferred_date = null
          if (this.projectInfo.paper_bcon_receipt_date < this.projectInfo.paper_limit_date) {
            this.projectInfo.paper_bcon_receipt_date = null;
          }
          this.$set(this.configs.paper_bcon_receipt_date, 'minDate', moment(e.date).add(1, 'days') || null);
          // this.$set(this.configs.paper_preferred_date, 'maxDate', e.date || null);
        } else {
          if (moment(this.projectInfo.paper_preferred_date).isValid()) {
            this.$set(this.configs.paper_bcon_receipt_date, 'minDate', moment(this.projectInfo.paper_preferred_date).add(1, 'days') || null);
          }
        }
      },
      onPaperBconChange(e) {
        if (e.date) {
          this.$set(this.configs.report_preferred_date, 'disabledDates', this.holidays || null);
          this.projectInfo.report_preferred_date = null
          this.getDeadlineSubmit()
          if (this.report_preferred_date < this.projectInfo.paper_bcon_receipt_date) {
            this.report_preferred_date = this.projectInfo.paper_bcon_receipt_date
            this.$set(this.configs.paper_bcon_receipt_date, 'minDate', false || null);
            let dateInput = moment(this.projectInfo.paper_bcon_receipt_date).add(0, 'days').format('YYYY-MM-DD')
            this.$set(this.configs.report_preferred_date, 'minDate', dateInput || null);
            setTimeout(() => {
              if (document.querySelector('.next.disabled')) {
                document.querySelector('.next.disabled').classList.remove('disabled')
              }
            }, 200)

          }
        }
      },
      getDates(startDate, stopDate) {
        let dateArray = [];
        let currentDate = moment(startDate).add(1, 'days');
        stopDate = moment(stopDate);
        while (currentDate < stopDate) {
          dateArray.push(moment(currentDate).format('YYYY-MM-DD'))
          currentDate = moment(currentDate).add(1, 'days');
        }
        return dateArray;
      },
      paperPeople() {
        this.projectInfo.web_start = null
        this.projectInfo.web_end = null
        this.projectInfo.paper_preferred_date = null
        this.projectInfo.paper_limit_date = null
        this.projectInfo.paper_bcon_receipt_date = null
      },
      webPeople() {
        this.projectInfo.web_start = null
        this.projectInfo.web_end = null
        this.projectInfo.paper_preferred_date = null
        this.projectInfo.paper_limit_date = null
        this.projectInfo.paper_bcon_receipt_date = null
      },
      preventAlphabetInput ($event) {
        let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
        if (((keyCode < 48 || keyCode > 57))) {
          $event.preventDefault();
        }
      },
       getDeadline() {
            let fiveDate = moment(this.projectInfo.web_start).subtract(6, 'days').format('YYYY-MM-DD')
            _.forEach(this.holidays, (d) => {
                if (d >= fiveDate && d < moment(this.projectInfo.web_start).format('YYYY-MM-DD')) {
                    fiveDate = moment(fiveDate).subtract(1, 'days').format('YYYY-MM-DD')
                } else {
                    return
                }
            })

            let paper_people = this.projectInfo.paper_people
            if (this.projectInfo.medium_type == 2 || this.projectInfo.medium_type == 3) {
                let date_medium2 = ''
                switch (true) {
                    case (paper_people > 0 &&paper_people <= 100):
                        date_medium2 = 7
                        break
                    case (paper_people > 100 && paper_people <= 200):
                        date_medium2 = 8
                        break
                    case (paper_people > 200 && paper_people <= 300):
                        date_medium2 = 9
                        break
                    case (paper_people > 300 && paper_people <= 400):
                        date_medium2 = 10
                        break
                    case (paper_people > 400 && paper_people <= 500):
                        date_medium2 = 11
                        break
                    case (paper_people > 500):
                        date_medium2 = 12
                        break
                    default:
                        break

                }
                if (date_medium2) {
                    let deadlineCase2 = moment(this.projectInfo.paper_preferred_date).subtract(date_medium2, 'days').format('YYYY-MM-DD')
                    _.forEach(this.holidays, (d) => {
                        if (d >= deadlineCase2 && d < moment(this.projectInfo.paper_preferred_date).format('YYYY-MM-DD')) {
                            deadlineCase2 = moment(deadlineCase2).subtract(1, 'days').format('YYYY-MM-DD')
                        } else {
                            return
                        }
                    })

                    if (this.projectInfo.medium_type == 2) {
                        this.deadlineSubmit = moment(deadlineCase2).isValid() ? moment(deadlineCase2).format('YYYY年MM月DD日') : ''
                    } else {
                        let deadline = ''
                        if (deadlineCase2 > fiveDate && moment(deadlineCase2).isValid() && moment(fiveDate).isValid()) {
                            deadline = deadlineCase2
                        } else {
                            deadline = fiveDate;
                        }
                        this.deadlineSubmit = moment(deadline).isValid() ? moment(deadline).format('YYYY年MM月DD日') : ''
                    }
                }
            } else {

                if (moment(fiveDate).isValid()) {
                    this.deadlineSubmit = moment(fiveDate).format('YYYY年MM月DD日')
                } else {
                    this.deadlineSubmit = ''
                }
            }
       },
      getDates(startDate, stopDate) {
        var dateArray = [];
        var currentDate = moment(startDate);
        var stopDate = moment(stopDate);
        while (currentDate <= stopDate) {
          dateArray.push(moment(currentDate).format('YYYY-MM-DD'))
          currentDate = moment(currentDate).add(1, 'days');
        }
        return dateArray;
      },
      getDeadlineSubmit() {
        let fiveDate = this.projectInfo.web_start
        let fiveDate2 = null
        if (this.projectInfo.web_start) {
          let days = this.getDates(moment(this.projectInfo.web_start).subtract(30, 'days').format('YYYY-MM-DD'), moment(this.projectInfo.web_start).subtract(1, 'days').format('YYYY-MM-DD'))
          days.sort(function (a, b) {
            return new Date(b) - new Date(a);
          });
          let i = 1
          _.forEach(days, (d) => {
            if (!this.holidays.includes(d) && i <= 5) {
              fiveDate = d
              i = i + 1
            }
          })

          fiveDate = moment(fiveDate).subtract(1, 'days').format('YYYY-MM-DD')
          while (this.holidays.includes(fiveDate)) {
            fiveDate = moment(fiveDate).subtract(1, 'days').format('YYYY-MM-DD')
          }
        }
        if (this.projectInfo.medium_type == 2 || this.projectInfo.medium_type == 3) {
          let infDate = this.projectInfo.paper_preferred_date

          if (infDate) {
            let paper_people = this.projectInfo.paper_people
            let date_medium2 = ''
            switch (true) {
              case (paper_people > 0 && paper_people <= 100):
                date_medium2 = 7
                break
              case (paper_people > 100 && paper_people <= 200):
                date_medium2 = 8
                break
              case (paper_people > 200 && paper_people <= 300):
                date_medium2 = 9
                break
              case (paper_people > 300 && paper_people <= 400):
                date_medium2 = 10
                break
              case (paper_people > 400 && paper_people <= 500):
                date_medium2 = 11
                break
              case (paper_people > 500):
                date_medium2 = 12
                break
              default:
                break
            }
            let days2 = this.getDates(moment(infDate).subtract(30, 'days').format('YYYY-MM-DD'), infDate)
            days2.sort(function(a,b){
              return new Date(b) - new Date(a);
            });
            let i = 0
            _.forEach(days2, (d) => {
              if (!this.holidays.includes(d) && i <= date_medium2) {
                fiveDate2 = d
                i = i + 1
              }
            })
          }
        }

        let deadline = ''
        if (moment(fiveDate).isValid() && moment(fiveDate2).isValid()) {
          if (fiveDate < fiveDate2) {
            deadline = fiveDate
          } else {
            deadline = fiveDate2
          }
        } else if (!moment(fiveDate).isValid() && moment(fiveDate2).isValid()) {
          deadline = fiveDate2
        } else if (moment(fiveDate).isValid() &&  !moment(fiveDate2).isValid()) {
          deadline = fiveDate
        }

        this.deadlineSubmitRaw = deadline
        this.deadlineSubmit = moment(deadline).isValid() ? moment(deadline).format('YYYY年MM月DD日') : ''

      },
      getPaperDate() {
        const paper_people = this.projectInfo.paper_people;
        let date = 0
        switch (true) {
          case (paper_people > 0 && paper_people <= 100):
            date = 6
            break
          case (paper_people > 100 && paper_people <= 200):
            date = 7
            break
          case (paper_people > 200 && paper_people <= 300):
            date = 8
            break
          case (paper_people > 300 && paper_people <= 400):
            date = 9
            break
          case (paper_people > 400 && paper_people <= 500):
            date = 10
            break
          case (paper_people > 500):
            date = 11
            break
          default:
            break
        }
        let date_to_input = parseInt(date) + 1

        let days = this.getDates(moment().format('YYYY-MM-DD'), moment().subtract(1, 'days').add(date_to_input + 30, 'days').format('YYYY-MM-DD'))
        days.sort(function(a,b){
          return  new Date(a) - new Date(b);
        });

        let i = 0
        let dateInput = null
        _.forEach(days, (d) => {
          if (!this.holidays.includes(d) && i <= date_to_input) {
            dateInput = d
            i = i + 1
          }
        })

        return dateInput
      },
      getInputDate() {
        const paper_people = this.projectInfo.paper_people;
        let date = 0
        let dateInput = null;
        switch (true) {
          case (paper_people > 0 && paper_people <= 100):
            date = 1
            break
          case (paper_people > 100 && paper_people <= 200):
            date = 2
            break
          case (paper_people > 200 && paper_people <= 300):
            date = 3
            break
          case (paper_people > 300 && paper_people <= 400):
            date = 4
            break
          case (paper_people > 400 && paper_people <= 500):
            date = 5
            break
          case (paper_people > 500):
            date = 7
            break
          default:
            break
        }

        let date_to_input = this.report_preferrer_date_conditions[this.projectInfo.project_type]
        console.log(date_to_input)
        date_to_input = parseInt(date_to_input) + parseInt(date) + 1

        if (this.projectInfo.medium_type == 1) {
          dateInput = moment(this.projectInfo.web_end).add(date_to_input, 'days').format('YYYY-MM-DD')

          let days = this.getDates(moment(this.projectInfo.web_end).format('YYYY-MM-DD'), moment(this.projectInfo.web_end).add(date_to_input + 30, 'days').format('YYYY-MM-DD'))
          days.sort(function (a, b) {
            return new Date(a) - new Date(b);
          });

          if (this.holidays.includes(this.projectInfo.web_end)) {
            date_to_input = date_to_input - 1
          }
          let i = 0
          _.forEach(days, (d) => {
            if (!this.holidays.includes(d) && i <= date_to_input) {
              dateInput = d
              i = i + 1
            }
          })

          return dateInput
        } else {
          if (this.projectInfo.paper_bcon_receipt_date) {
            dateInput = moment(this.projectInfo.paper_bcon_receipt_date).add(date_to_input, 'days').format('YYYY-MM-DD')

            let days = this.getDates(moment(this.projectInfo.paper_bcon_receipt_date).format('YYYY-MM-DD'), moment(this.projectInfo.paper_bcon_receipt_date).add(date_to_input + 30, 'days').format('YYYY-MM-DD'))
            days.sort(function (a, b) {
              return new Date(a) - new Date(b);
            });
            let i = 0
            if (this.holidays.includes(this.projectInfo.paper_bcon_receipt_date)) {
              date_to_input = date_to_input - 1
            }

            console.log('date_to_input')
            console.log(date_to_input)
            _.forEach(days, (d) => {
              if (!this.holidays.includes(d) && i <= date_to_input) {
                dateInput = d
                i = i + 1
              }
            })
          }

          return dateInput
        }

        return dateInput
      },
      preventAlphabetInput($event) {
        let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
        if (((keyCode < 48 || keyCode > 57))) {
          $event.preventDefault();
        }
      },
      subjectType() {
        if (this.question_subject_type_other === '当社' || this.question_subject_type_other === '私の職場') {
          this.projectInfo.question_subject_type = this.question_subject_type_other
          this.question_subject_type_other_text = null
          this.other = false
        }
      },
    },
    async created() {
      let id = this.$route.params.id
      await this.GET_PROJECT_DETAIL(id);
      // await this.GET_HOLIDAYS()

    },
      mounted() {
          // if (this.holidays.length == 0) {
          //     this.GET_HOLIDAYS().then(() => {
          //       this.getDeadlineSubmit()
          //     })
          // } else {
          //   this.getDeadlineSubmit()
          // }
        window.scrollTo(0,0)

        // if (this.projectInfo.question_subject_type !== '' && this.projectInfo.question_subject_type !== '当社' && this.projectInfo.question_subject_type !== '私の職場') {
          this.question_subject_type_other = this.projectInfo.question_subject_type
        // }

        if (this.projectInfo.qestion_type == 1 && this.projectInfo.questions.length === 0) {
          const newQ = {
            id: '',
            body: '',
            format_type: ''
          }
          this.projectInfo.questions.push(newQ)
        }

        if (this.projectInfo.qestion_type == 1) {
          this.qestion_type1 =  true
        }
        if (this.projectInfo.qestion_type == 2) {
          this.qestion_type2 =  true
        }
      }
  }
</script>
<style scoped lang="scss">
  input[type=checkbox] {
    display: none;
  }

  .btn-primary {
    color: #fff !important;
  }

  .info_fukidashi_2 {
    right: -104px;
  }

  .info_fukidashi_3 {
    right: -104px;
  }

  .form_box_2_3 dd .width-datepicker {
    margin-top: 10px;
  }

  .gray {
    color: #ddd;
  }
  .info_fukidashi_2 {
    right: -343px;
    bottom: 90px;
  }
  .info_fukidashi_3 {
    right: -330px;
    bottom: 110px;
  }
  .scale {
    &.scale2 {
      padding-left: 0;
      margin-left: 0;
      div {
        justify-content: flex-start;
        span {
          padding-left: 0;
          padding-right: 0;
          padding-bottom: 15px;
        }
      }
    }
  }
  .check_2_3 {
    background-color: transparent;
    border: 0;
    padding: 0;
    padding-left: 30px;
    font-size: 14px !important;
    &:before {
      left: 0;
    }
    &:checked {
      &:after {
        opacity: 1;
      }
    }
    &:after {
      top: 0;
      left: 0;
    }
  }
</style>
