import Cookies from 'js-cookie'
import axios from "axios";

export default {
  state: {},
  getters: {},
  mutations: {
    AUTH_REQUEST_SALES(state) {
      state.status = 'loading'
    },
    AUTH_ERROR_SALES(state) {
      state.status = 'error'
    },
    CREATE_PROJECT_USER(stats) {

    }
  },
  actions: {
    AUTH_REQUEST_SALES({commit, dispatch}, user) {
      return new Promise((resolve, reject) => { // The Promise used for router redirect in login
        commit('consultantUser/AUTH_REQUEST')
        axios({url: '/api/sales-users/login', data: user, method: 'POST'})
          .then(resp => {
            const token = resp.data.token
            const user = resp.data.user
            Cookies.set('user-token', token)
            commit('consultantUser/AUTH_SUCCESS', token)
            commit('consultantUser/AUTH_USER', user)
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            resolve(resp)
          })
          .catch(err => {
            commit('AUTH_ERROR_SALES', err)
            Cookies.remove('user-token')
            reject(err)
          })
      })
    },
    CREATE_PROJECT_USER({commit, dispatch}, project_user) {
      return new Promise((resolve, reject) => { // The Promise used for router redirect in login
        axios({url: '/api/sales-users/users/create', data: project_user, method: 'POST'})
          .then(resp => {
            resolve(resp)
          })
          .catch(err => {
            reject(err)
          })
      })
    }
  }
}
