import Cookies from 'js-cookie'
import {USER_PROJECT} from '../../contstant';
import axios from "axios";

export default {
  namespaced: true,
  state: {
    token: Cookies.get('user-token') || '',
    status: '',
    user: {},
    remember: Cookies.get('rememberMe'),
    project: {},
    sales: {},
    consultants: {},
    consultant: {},
    sale: {},
    csvFile: {},
    holidays: {},
    roles: {}
  },
  getters: {
    isAuthenticated: state => !!state.token,
    user: state => state.user,
    remember: state => state.remember,
    holidays: state => state.holidays,
  },
  mutations: {
    AUTH_REQUEST(state) {
      state.status = 'loading'
    },
    AUTH_SUCCESS(state, token) {
      state.status = 'success'
      state.token = token
    },
    AUTH_ERROR(state) {
      state.status = 'error'
    },
    AUTH_USER(state, user) {
      state.user = user
      state.project = user.project ? user.project : {}
    },
    SET_PROJECT(state, project) {
      state.project = project
    },
    SET_USER(state, user) {
      state.user = user
    },
    SET_PROJECT_PROGRESS_STATUS(state, progress_status) {
      state.project.progress_status = progress_status
    },

    LIST_SALES(state, sales) {
      state.sales = sales
    },

    LIST_CONSULTANTS(state, consultants) {
      state.consultants = consultants
    },

    SET_CONSULTANT(state, consultant) {
      state.consultant = consultant
    },

    SET_SALES(state, sale) {
      state.sale = sale
    },

    CSV_FILE(state, csvFile) {
      state.csvFile = csvFile
    },

    SET_HOLIDAYS(state, holidays) {
      state.holidays = holidays
    },

    SET_ROLES(state, roles) {
      state.roles = roles
    }
  },
  actions: {
    AUTH_REQUEST({commit, dispatch, state}, user) {
      // try {
      //   let { data } = await axios.post('/api/admin/login', user)
      //   let token = data.token
      //   const userInfo = data.user
      //   axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      //   Cookies.set('user-token', token)
      //   commit('AUTH_SUCCESS', token)
      //   commit('AUTH_USER', userInfo)
      //   return userInfo
      // } catch (e) {
      //   commit('AUTH_ERROR', e)
      //   Cookies.remove('user-token')
      //   return e
      // }


      return new Promise((resolve, reject) => { // The Promise used for router redirect in login
        // commit('AUTH_REQUEST')
        axios({url: '/api/admin/login', data: user, method: 'POST'})
          .then(resp => {
            let token = resp.data.token
            const user = resp.data.user
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            Cookies.set('user-token', token)
            commit('AUTH_SUCCESS', token)
            commit('AUTH_USER', user)
            console.log(state.user)
            resolve(resp)
          })
          .catch(err => {
            commit('AUTH_ERROR', err)
            Cookies.remove('user-token') // if the request fails, remove any possible user token if possible
            reject(err)
          })
      })
    },

    // async AUTH_REQUEST({commit, dispatch, getters}, user) {
    //   try {
    //     console.log(user)
    //     let resp = await axios.post('/api/admin/login', user)
    //     let token = resp.data.token
    //     const user = resp.data.user
    //     axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    //     Cookies.set('user-token', token)
    //     commit('AUTH_SUCCESS', token)
    //     commit('AUTH_USER', user)
    //     console.log('===========')
    //     console.log(state.user)
    //   } catch (e) {
    //     commit('AUTH_ERROR', e)
    //     Cookies.remove('user-token') // if the request fails, remove any possible user token if possible
    //     // reject(e)
    //   }
    // },


    AUTH_LOGOUT({commit, dispatch}) {
      return new Promise((resolve, reject) => {
        Cookies.remove('user-token')
        Cookies.remove('rememberMe')
        commit('AUTH_USER', {})
        commit('AUTH_SUCCESS', '')
        // remove the axios default header
        delete axios.defaults.headers.common['Authorization']
        resolve()
      })
    },
    async AUTH_TOKEN({commit, dispatch, getters}) {
      try {
        let {data} = await axios.get('/api/users/info')
        let user = data
        const holidays = data.holidays ? data.holidays : {}
        if (holidays.length > 0) {
          commit('SET_HOLIDAYS', holidays)
        }

        // if (user.type === USER_PROJECT && getters.remember !== 'true') {
        //   Cookies.remove('user-token')
        //   Cookies.remove('rememberMe')
        //   commit('AUTH_USER', {})
        // } else {
          commit('AUTH_USER', user)
          if (user.project && user.project.progress_status) {
            commit('project/NEW_STEP', user.project.progress_status, {root: true})
          }
        // }
        return user
      } catch (e) {
        dispatch('AUTH_LOGOUT')
        commit('AUTH_SUCCESS', '')
        return e
      }
    },

    async SET_USER({commit}, user) {
      commit('AUTH_USER', user)
    },

    async CREATE_SALES_USER({commit, dispatch}, user) {
      try {

        if (!user.id) {
          let {data} = await axios.post('/api/admin/sales/create', user);
        } else {
          let {data} = await axios.post('/api/admin/sales/update/'+ user.id, user);
        }
        // let {data} = await axios.post('/api/admin/sales/create', user);

        commit('SET_SALES', {})
        return data;
      } catch (err) {
        return err
      }
    },

    async CREATE_CONSULTANT_USER({commit, dispatch}, user) {
      try {
        if (!user.id) {
          let {data} = await axios.post('/api/admin/consultant/create', user);
        } else {
          let {data} = await axios.post('/api/admin/consultant/update/'+ user.id, user);
        }
        commit('SET_CONSULTANT', {})
        return data;
      } catch (err) {
        return err
      }
    },

    async GET_CONSULTANT_USER({commit, dispatch}, id) {
      try {
        let {data} = await axios.get('/api/admin/consultant/detail/'+ id);
        commit('SET_CONSULTANT', data)
        return data;
      } catch (err) {
        return err
      }
    },

    async GET_SALES_USER({commit, dispatch}, id) {
      try {
        let {data} = await axios.get('/api/admin/sales/detail/'+ id);
        commit('SET_SALES', data)
        return data;
      } catch (err) {
        return err
      }
    },

    async GET_LIST_SALES({commit, dispatch}, page = 1) {
      try {
        let {data} = await axios.get('/api/admin/sales?page=' + page)
        commit('LIST_SALES', data)
        return data

      } catch (err) {
        return err
      }
    },


    SET_SALES_LIST({commit}, data) {
      commit('LIST_SALES', data)
    },


    async GET_LIST_CONSULTANTS({commit, dispatch}, page = 1) {
      try {
        let {data} = await axios.get('/api/admin/consultant?page=' + page)
        commit('LIST_CONSULTANTS', data)
        return data

      } catch (err) {
        return err
      }
    },

    SET_CONSULTANT_DETAULT({commit, dispatch}, data) {
      commit('SET_CONSULTANT', data)
    },

    SET_SALES_DETAULT({commit, dispatch}, data) {
      commit('SET_SALES', data)
    },

    async DELETE_CONSULTANT({commit, dispatch}, id) {
      try {
        let {data} = await axios.delete('/api/admin/consultant/'+ id)
        commit('LIST_CONSULTANTS', data)
        return data

      } catch (err) {
        return err
      }
    },

    async DELETE_SALES({commit, dispatch}, id) {
      try {
        let {data} = await axios.delete('/api/admin/sales/'+ id)
        commit('LIST_SALES', data)
        return data

      } catch (err) {
        return err
      }
    },

    SET_CSV_FILE({commit}, file) {
      commit('CSV_FILE', file)
    },

    async GET_HOLIDAYS({commit}) {
      try {
        let {data} = await axios.get('/api/holidays')
        commit('SET_HOLIDAYS', data)
        return data

      } catch (err) {
        return err
      }
    },

    async GET_ROLES({commit}) {
      try {
        let {data} = await axios.get('/api/admin/roles')
        commit('SET_ROLES', data)
        return data

      } catch (err) {
        return err
      }
    },
  }
}
