var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "body_1_2 container" },
    [
      !_vm.$route.params.id || (_vm.$route.params.id && _vm.consultant.id)
        ? _c("header-component", {
            attrs: {
              title: "[管理者登録]",
              back: { name: "ConsultantUserList" }
            }
          })
        : _c("header-component", { attrs: { title: "[コンサルタント編集]" } }),
      _vm._v(" "),
      _c("ValidationObserver", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value:
              !_vm.$route.params.id ||
              (_vm.$route.params.id && _vm.consultant.id),
            expression: "!$route.params.id || $route.params.id && consultant.id"
          }
        ],
        ref: "observer",
        attrs: { tag: "form" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.previewRegister()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var invalid = ref.invalid
              return [
                _c("div", { staticClass: "form_box" }, [
                  _c("div", { staticClass: "form_box_title" }, [
                    _c("p", [_vm._v("コンサルタント")])
                  ]),
                  _vm._v(" "),
                  _c("dl", [
                    _c("dt", { staticClass: "required required81" }, [
                      _vm._v("ログインID")
                    ]),
                    _vm._v(" "),
                    _c(
                      "dd",
                      [
                        _c("ValidationProvider", {
                          staticClass: "validate",
                          attrs: {
                            name: "id_login",
                            rules: {
                              required: true,
                              uniqueID: true,
                              regex: /^([a-zA-Z0-9_-]){5,32}$/
                            }
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.consultant.id_login,
                                          expression: "consultant.id_login"
                                        }
                                      ],
                                      class: {
                                        form_text: true,
                                        error: errors[0]
                                      },
                                      attrs: {
                                        type: "text",
                                        placeholder: "ログインID",
                                        name: "id_login"
                                      },
                                      domProps: {
                                        value: _vm.consultant.id_login
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.consultant,
                                            "id_login",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("br", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: errors[0],
                                          expression: "errors[0]"
                                        }
                                      ]
                                    }),
                                    _c("span", { staticClass: "error" }, [
                                      _vm._v(_vm._s(errors[0]))
                                    ])
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("dl", [
                    _c("dt", { staticClass: "required required1" }, [
                      _vm._v("氏名")
                    ]),
                    _vm._v(" "),
                    _c(
                      "dd",
                      [
                        _c("ValidationProvider", {
                          staticClass: "validate",
                          attrs: { name: "consultant_name", rules: "required" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.consultant.name,
                                          expression: "consultant.name"
                                        }
                                      ],
                                      class: {
                                        form_text: true,
                                        error: errors[0]
                                      },
                                      attrs: {
                                        type: "text",
                                        placeholder: "氏名",
                                        name: "name"
                                      },
                                      domProps: { value: _vm.consultant.name },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.consultant,
                                            "name",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("br", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: errors[0],
                                          expression: "errors[0]"
                                        }
                                      ]
                                    }),
                                    _c("span", { staticClass: "error" }, [
                                      _vm._v(_vm._s(errors[0]))
                                    ])
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("dl", [
                    _c("dt", { staticClass: "required required81" }, [
                      _vm._v("メールアドレス")
                    ]),
                    _vm._v(" "),
                    _c(
                      "dd",
                      [
                        _c("ValidationProvider", {
                          staticClass: "validate",
                          attrs: {
                            mode: _vm.submit ? "eager" : "passive",
                            name: "emailSales",
                            rules: "required|email"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.consultant.email,
                                          expression: "consultant.email"
                                        }
                                      ],
                                      class: {
                                        form_text: true,
                                        error: errors[0]
                                      },
                                      attrs: {
                                        type: "text",
                                        placeholder: "メールアドレス",
                                        name: "emailSales"
                                      },
                                      domProps: { value: _vm.consultant.email },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.consultant,
                                            "email",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("br", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: errors[0],
                                          expression: "errors[0]"
                                        }
                                      ]
                                    }),
                                    _c("span", { staticClass: "error" }, [
                                      _vm._v(_vm._s(errors[0]))
                                    ])
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("dl", [
                    _c("dt", { staticClass: "required required81" }, [
                      _vm._v("パスワード")
                    ]),
                    _vm._v(" "),
                    _c(
                      "dd",
                      [
                        _c("ValidationProvider", {
                          staticClass: "validate",
                          attrs: {
                            name: "password",
                            rules: !_vm.consultant.id ? "required" : ""
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.consultant.password,
                                          expression: "consultant.password"
                                        }
                                      ],
                                      class: {
                                        form_text: true,
                                        error: errors[0]
                                      },
                                      attrs: {
                                        type: "password",
                                        placeholder: _vm.consultant.id
                                          ? "******"
                                          : "パスワード",
                                        name: "name"
                                      },
                                      domProps: {
                                        value: _vm.consultant.password
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.consultant,
                                            "password",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("br", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: errors[0],
                                          expression: "errors[0]"
                                        }
                                      ]
                                    }),
                                    _c("span", { staticClass: "error" }, [
                                      _vm._v(_vm._s(errors[0]))
                                    ])
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _vm.user.role && _vm.user.role.is_edit_c_user
                    ? _c("dl", [
                        _c("dt", { staticClass: "required required1" }, [
                          _vm._v("権限")
                        ]),
                        _vm._v(" "),
                        _c(
                          "dd",
                          [
                            _c("ValidationProvider", {
                              staticClass: "validate",
                              attrs: { name: "c_role_id", rules: "required" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c(
                                          "select",
                                          {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.consultant.c_role_id,
                                                expression:
                                                  "consultant.c_role_id"
                                              }
                                            ],
                                            class: {
                                              form_text: true,
                                              error: errors[0]
                                            },
                                            attrs: {
                                              name: "c_role_id",
                                              id: ""
                                            },
                                            on: {
                                              change: function($event) {
                                                var $$selectedVal = Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function(o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function(o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                                _vm.$set(
                                                  _vm.consultant,
                                                  "c_role_id",
                                                  $event.target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                )
                                              }
                                            }
                                          },
                                          _vm._l(_vm.roles, function(role) {
                                            return _c(
                                              "option",
                                              { domProps: { value: role.id } },
                                              [_vm._v(_vm._s(role.name))]
                                            )
                                          }),
                                          0
                                        ),
                                        _vm._v(" "),
                                        _c("br", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: errors[0],
                                              expression: "errors[0]"
                                            }
                                          ]
                                        }),
                                        _c("span", { staticClass: "error" }, [
                                          _vm._v(_vm._s(errors[0]))
                                        ])
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        )
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "footer_box footer_box_1_3" }, [
                  _c("p", { staticClass: "btn2" }, [
                    _c("input", {
                      staticClass: "form_box_sub_blue form_box_sub_2",
                      attrs: { type: "button", value: "前の画面に戻る" },
                      on: {
                        click: function($event) {
                          return _vm.backList()
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "btn3" }, [
                    _c("input", {
                      staticClass: "form_box_sub form_box_sub_2",
                      attrs: { type: "submit", value: "保存" }
                    })
                  ])
                ])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }