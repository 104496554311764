<template>
  <div >
    <header-component :margin="true" :title="projectType()"></header-component>
    <div v-show="projectInfo && projectInfo.id">
      <div class="top_contact fx_cece">
        <div class="top_con1">
          <img src="/images/top_qu.png" srcset="/images/top_qu.png 1x, /images/top_qu@2x.png 2x" alt="お問合せ先"><br>お問合せ先
        </div>
        <div class="top_con2">
          <p>不明点や確認事項がありましたら担当営業もしくはこちらまでお問い合わせください。</p>
          <div class="fx_ce">
            <p class="top_con2_p border_left"><img src="/images/top_mail.png"
                                                   srcset="/images/top_mail.png 1x, /images/top_mail@2x.png 2x" alt="メールでのお問合せ">メールでのお問い合わせ<br><span
              class="span_color"><a href="mailto:survey-rcptn@info.bcon.jp">survey-rcptn@info.bcon.jp</a></span></p>
            <p class="top_con2_p"><img src="/images/top_tel.png" srcset="/images/top_tel.png 1x, /images/top_tel@2x.png 2x"
                                       alt="お電話でのお問合せ">電話でのお問い合わせ<br><span>03-3287-3416(平日 10:00～17:00)</span></p>
          </div>
        </div>
      </div>
      <div id="step_bar_box">
        <ol class="step_bar">
          <li >STEP1 診断申込 登録</li>
          <li >STEP2 詳細情報 登録</li>
          <li class="current">STEP3 対象者リストアップロード</li>
        </ol>
      </div>
      <div v-if="!isConfirm">
        <div class="top_box_4_1">
          <div class="top_box_child_4_1">
            <p>アップロードが必要な資料は以下になります。</p>
            <div v-if="(projectInfo.project_type >=1 && projectInfo.project_type <=6) || (projectInfo.project_type >=11 && projectInfo.project_type <=16 && projectInfo.attribute_setting_type == 1)">
              <div class="fx_se">
                <div>
                  <div class="top_img_box_child_4_1">
                    <img src="/images/atari2.jpg" alt="対象者リスト画像" width="358" height="199">
                  </div>
                  <p class="top_box_child_4_1_p">対象者リスト</p>
                </div>
              </div>
            </div>
            <div v-if="projectInfo.project_type >=11 && projectInfo.project_type <=16 && projectInfo.attribute_setting_type != 1">
              <div class="fx_se" v-if="newStep == '2.1'">
                <div>
                  <div class="top_img_box_child_4_4 fin_up">
                    <img src="/images/atari1.jpg" alt="区分コード画像" width="352" height="155" class="top_4_4_o">
                  </div>
                  <p class="top_box_child_4_1_p top_4_4_o">①属性一覧の設定</p>
                </div>
                <div>
                  <div class="top_img_box_child_4_1">
                    <img src="/images/atari2.jpg" alt="対象者リスト画像" width="358" height="199">
                  </div>
                  <p class="top_box_child_4_1_p">②対象者リスト</p>
                </div>
              </div>
              <div class="fx_se" v-else>
                <div>
                  <div class="top_img_box_child_4_1">
                    <img src="/images/atari1.jpg" alt="区分コード画像" width="352" height="155">
                  </div>
                  <p class="top_box_child_4_1_p">&#9312;属性一覧の設定</p>
                </div>
                <div>
                  <div class="top_img_box_child_4_1">
                    <img src="/images/atari2.jpg" alt="対象者リスト画像" width="358" height="199">
                  </div>
                  <p class="top_box_child_4_1_p">&#9313;対象者リスト</p>
                </div>
              </div>
            </div>
            <div v-if="projectInfo.project_type >=21 && projectInfo.project_type <=24">
              <div class="fx_se">

                <div>
                  <div class="top_img_box_child_4_1">
                    <img src="/images/atari2.jpg" alt="対象者リスト画像" width="358" height="199">
                  </div>
                  <p class="top_box_child_4_1_p">対象者リスト</p>
                </div>
              </div>
            </div>
          </div>
          <p class="top_box_4_1_p" v-if="projectInfo.project_type >=11 && projectInfo.project_type <=16 && projectInfo.attribute_setting_type != 1">「{{ newStep == 2.1 ? '対象者リスト' : '属性一覧の設定' }}」を入力→アップロードしてください</p>
          <p class="top_box_4_1_p" v-else>「対象者リスト」を入力→アップロードしてください</p>
          <div class="top_img_box_4_1 fx_se">
            <div>
              <p><img src="/images/up_1.png" alt="フォーマットをダウンロード" srcset="/images/up_1.png 1x, /images/up_1@2x.png 2x"></p>
              <p>フォーマットをダウンロード</p>
            </div>
            <div>
              <p class="arrow"><img src="/images/up_2.png" alt="デスクトップで入力" srcset="/images/up_2.png 1x, /images/up_2@2x.png 2x">
              </p>
              <p>デスクトップで入力</p>
            </div>
            <div>
              <p class="arrow"><img src="/images/up_3.png" alt="ファイルをアップロード" srcset="/images/up_3.png 1x, /images/up_3@2x.png 2x">
              </p>
              <p>ファイルをアップロード</p>
            </div>
          </div>
        </div>

        <div class="footer_box_4_1" v-if="(projectInfo.project_type >=1 && projectInfo.project_type <=6) || (projectInfo.project_type >=11 && projectInfo.project_type <=16 && projectInfo.attribute_setting_type == 1)">
          <div class="fx_se">
            <div>
              <p class="btn-s btn_2_5 btn_3_3 btn_4_1 btn_4_1_blue cur_pointer">
                <!--<a :href="link_download_ppt_invidual+ projectInfo.id">「対象者リスト」フォーマットを<br>ダウンロード</a>-->
                <a v-if="projectInfo.project_type >=1 && projectInfo.project_type <=6" download :href="'/files/excels/個人診断_対象者リスト.xlsx'">「対象者リスト」フォーマットを<br>ダウンロード</a>
                <a v-else download :href="link_download_ptp + projectInfo.id">「対象者リスト」フォーマットを<br>ダウンロード</a>
              </p>
              <p class="footer_box_4_1_d">
                <a href="javascript:;" @click="openTutorial()"><img src="/images/file_d_2.png" alt="入力の仕方を見る" srcset="/images/file_d_2.png 1x, /images/file_d_2@2x.png 2x">
                  入力の仕方を見る</a>
              </p>
            </div>
            <div>
              <p class="btn-s btn_2_5 btn_3_3 btn_4_1 cur_pointer">
                <input type="file" ref="file" @change="uploadPTP()" id="input-invidual" name="02" ><label for="input-invidual" class="cur_pointer">「対象者リスト」ファイルを<br>アップロード</label>
              </p>
              <p class="footer_box_4_1_u">アップロード期日 {{ deadlineSubmit | dateFormat }} </p>
              <p class="footer_box_4_1_u_2">この画面を終了して、後でアップロードする場合<br>は、再度ログインしてください。</p>
            </div>
          </div>
        </div>

        <div class="footer_box_4_1" v-if="projectInfo.project_type >=21 && projectInfo.project_type <=24">
          <div class="fx_se">
            <div>
              <p class="btn-s btn_2_5 btn_3_3 btn_4_1 btn_4_1_blue cur_pointer">
                <!--<a download :href="link_download_pmf + projectInfo.id">「対象者リスト」フォーマットを<br>ダウンロード</a>-->
                <a download :href="'/files/excels/多面診断_多面組合せ表.xlsx'">「対象者リスト」フォーマットを<br>ダウンロード</a>
              </p>
              <p class="footer_box_4_1_d">
                <a href="javascript:;" @click="openTutorial()"><img src="/images/file_d_2.png" alt="入力の仕方を見る" srcset="/images/file_d_2.png 1x, /images/file_d_2@2x.png 2x">
                  入力の仕方を見る</a>
              </p>
            </div>
            <div>
              <p class="btn-s btn_2_5 btn_3_3 btn_4_1 cur_pointer">
                <input type="file" ref="file" v-on:change="uploadPMF()" id="02" name="02" ><label for="02" class="cur_pointer" id="input-label">「対象者リスト」ファイルを<br>アップロード</label>
              </p>
              <p class="footer_box_4_1_u">アップロード期日 {{ deadlineSubmit | dateFormat }} </p>
              <p class="footer_box_4_1_u_2">この画面を終了して、後でアップロードする場合<br>は、再度ログインしてください。</p>
            </div>
          </div>
        </div>

        <div class="footer_box_4_1" v-if="projectInfo.project_type >=11 && projectInfo.project_type <=16 && projectInfo.attribute_setting_type != 1">
          <div class="fx_se">
            <div>
              <p class="btn-s btn_2_5 btn_3_3 btn_4_1 btn_4_1_blue cur_pointer">
                <!--<a @click="newStep == 2.1 ? downloadPTP() : download()">「対象者リスト」フォーマットを<br>ダウンロード</a>-->
                <a v-if="newStep == 2.1" download :href="link_download_ptp + projectInfo.id">「対象者リスト」フォーマットを<br>ダウンロード</a>
                <a v-else download :href="link_download_pai">「属性一覧の設定」フォーマットを<br>ダウンロード</a>
              </p>
              <p class="footer_box_4_1_d">
                <a href="javascript:;" @click="openTutorial()"><img src="/images/file_d_2.png" alt="入力の仕方を見る" srcset="/images/file_d_2.png 1x, /images/file_d_2@2x.png 2x">
                  入力の仕方を見る</a>
              </p>
            </div>
            <div>
              <p class="btn-s btn_2_5 btn_3_3 btn_4_1 cur_pointer">
                <input type="file" ref="file" v-on:change="newStep == 2.1 ? uploadPTP() : upload()" id="02" name="02" >
                <label v-if="newStep == 2.1" for="02" class="cur_pointer" id="input-label">「対象者リスト」ファイルを<br>アップロード</label>
                <label v-else for="02" class="cur_pointer" id="input-label">「属性一覧の設定」ファイルを<br>アップロード</label>
              </p>
              <p class="footer_box_4_1_u">アップロード期日 {{ deadlineSubmit | dateFormat }} </p>
              <p class="footer_box_4_1_u_2">この画面を終了して、後でアップロードする場合<br>は、再度ログインしてください。</p>
            </div>
          </div>
        </div>
      </div>
      <file-confirm v-else @confirm="confirm"></file-confirm>

      <div id="body_2_2" :style="{'display': isOpenTuror ? '' : 'none'}">
        <div class="body_bg_2_2">
          <div class="ls-carousels content_2_2">
            <div class="content_bg_2_2">
              <div class="ls-carousel-item active">
                <p class="content_2_2_p">ようこそ。<br> ビジネスコンサルタントの診断申込へ。</p>
                <p class="content_2_2_img">
                  <img src="/images/img2_2_1.png" alt="ようこそ。ビジネスコンサルタントの企業診断へ。" srcset="/images/img2_2_1.png 1x, /images/img2_2_1@2x.png 2x">
                </p>

              </div>

              <div class="ls-carousel-item">
                <p class="content_2_2_p">詳細情報<br> <span>これから段階的に3ステップの作業をしていただきます。</span></p>
                <p class="content_2_2_img">
                  <img style="width: 577px" width="577" src="/images/img2_2_2x@2x.png" alt="詳細情報" srcset="/images/img2_2_2x@2x.png 1x, /images/img2_2_2x@2x.png 2x">
                </p>
              </div>

              <div class="ls-carousel-item">
                <p class="content_2_2_p">途中でログアウトしても大丈夫。</p>
                <p class="content_2_2_p2">一旦、ログアウトしても再ログインすれば<br>ご自分のステップのステータスへ戻ってこられます。</p>
                <p class="content_2_2_3_img">
                  <img src="/images/img2_2_3.png" alt="途中でログアウトしても大丈夫。" srcset="/images/img2_2_3.png 1x, /images/img2_2_3@2x.png 2x">
                </p>
              </div>

              <div class="ls-carousel-item">
                <p class="content_2_2_p">対象者リスト提出締切日をご確認ください。</p>
                <p class="content_2_2_p2">フォーム上で「回答開始日時」をご指定いただくと<br>対象者リストのアップロード期日が決定いたします。</p>
                <p class="content_2_2_p3">※質問紙の場合は「質問紙到着希望日」が基準となります。</p>
                <p class="content_2_2_4_img">
                  <img src="/images/img2_2_4.png" alt="途中でログアウトしても大丈夫。" srcset="/images/img2_2_4.png 1x, /images/img2_2_4@2x.png 2x">
                </p>
              </div>

              <p><a @click="close()">
                <img src="/images/close.png" alt="閉じる" srcset="/images/close.png 1x, /images/close@2x.png 2x" class="close pointer">
              </a></p>
              <p v-show="carousel > 0">
                <a @click="preCarousel()">
                  <img src="/images/arrow_left.png" alt="前へ" srcset="/images/arrow_left.png 1x, images/arrow_left@2x.png 2x" class="arrow_left pointer">
                </a>
              </p>
              <p v-show="carousel < (carousel_total - 1)">
                <a @click="nextCarousel()">
                  <img src="/images/arrow_right.png" alt="次へ" srcset="/images/arrow_right.png 1x, /images/arrow_right@2x.png 2x" class="arrow_right pointer">
                </a>
              </p>
              <div class="m_m">
                <div class="text-center">
                  <template v-for="n in carousel_total">
                    <a :key="n">
                      <img @click="showCarousel(n - 1)" :src="(n - 1) == carousel ? '/images/blue_m.png' : '/images/gray_m.png'" :srcset="n == (carousel + 1) ? '/images/blue_m.png 1x, /images/blue_m@2x.png 2x' : '/images/gray_m.png 1x, /images/gray_m@2x.png 2x'">
                    </a>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {mapActions, mapMutations, mapState} from 'vuex'
  import {PROJECT_TYPE} from '../../../contstant'
  import FileConfirm from './FileConfirm.vue';
  import moment from 'moment'

  export default {
    data() {
      return {
        link_download_pai: '/api/admin/pai-download',
        link_download_ptp: '/api/admin/ptp-export/',
        link_download_pmf: '/api/admin/pmf-export/',
        link_download_ppt_invidual: '/api/admin/ppt-download/',
        HTML_NO: HTML_NO,
        isConfirm: false,
        deadlineSubmit: null,
        isOpenTuror: false,
        carousel: 0,
        carousel_total: 0,
        isShow: false
      }
    },
    components: {
      'file-confirm': FileConfirm
    },
    computed: {
      ...mapState('project', ['projectInfo', 'query', 'projects', 'newStep']),
      ...mapState('consultantUser', ['user', 'project', 'holidays'])
    },
    watch:{
      'projectInfo.web_start': function() {
        this.getDeadlineSubmit()
        const titleEl = document.querySelector('head title');
        titleEl.textContent = this.projectType() + ' 診断申込登録フォーム';
      }
    },
    methods: {
      ...mapMutations('project', ['PROJECT']),
      ...mapActions('project', ['GET_PROJECT_DETAIL']),
      showCarousel(n) {
        this.carousel = n == undefined ? this.carousel : n;
        $('.ls-carousels .ls-carousel-item').removeClass('active').hide();
        $('.ls-carousels .ls-carousel-item:eq('+this.carousel+')').fadeIn('slow');
      },
      nextCarousel(){
        this.carousel = this.carousel == this.carousel_total ? this.carousel_total : this.carousel + 1;
        this.showCarousel();
      },
      preCarousel(){
        this.carousel = this.carousel == 0 ? 0 : this.carousel - 1;
        this.showCarousel();
      },
      close(){
        this.isShow = false
        this.isOpenTuror = false
        // $('#body_2_2').hide();
      },
      openTutorial() {
        this.isOpenTuror = true
      },
      projectType() {
        let type = ''
        if (PROJECT_TYPE[0][this.projectInfo.project_type]) {
          type = PROJECT_TYPE[0][this.projectInfo.project_type]
        } else if (PROJECT_TYPE[1][this.projectInfo.project_type]) {
          type = PROJECT_TYPE[1][this.projectInfo.project_type]
        }
        return type
      },
      download(){
        this.$store.dispatch('uploadFile/DOWNLOAD', {url: this.link_download_pai, filename: '組織診断_対象者リスト.xlsx'});
        this.$store.dispatch('project/NEW_STEP', 2.0);
      },
      upload() {
        this.isConfirm = true
        this.$store.dispatch('project/NEW_STEP', 2.0);
        this.$store.dispatch('uploadFile/FILE_UPLOADED', this.$refs.file.files[0]);
      },
      downloadPTP(){
        this.$store.dispatch('project/NEW_STEP', 2.1);
        this.$store.dispatch('uploadFile/DOWNLOAD', {url: this.link_download_ptp, filename: '組織診断_対象者リスト.xlsx'});
      },
      uploadPTP(){
        this.isConfirm = true
        this.$store.dispatch('uploadFile/FILE_UPLOADED', this.$refs.file.files[0]);
        // this.$store.dispatch('project/NEW_STEP', 2);
      },
      uploadPMF(){
        this.isConfirm = true
        this.$store.dispatch('uploadFile/FILE_UPLOADED', this.$refs.file.files[0]);
        // this.$store.dispatch('project/NEW_STEP', 2);
      },
      confirm(status) {
        if (status) {
          this.isConfirm = !status
        }
      },
      getDates(startDate, stopDate) {
        var dateArray = [];
        var currentDate = moment(startDate);
        var stopDate = moment(stopDate);
        while (currentDate <= stopDate) {
          dateArray.push(moment(currentDate).format('YYYY-MM-DD'))
          currentDate = moment(currentDate).add(1, 'days');
        }
        return dateArray;
      },
      getDeadlineSubmit() {
        let fiveDate = this.projectInfo.web_start
        let fiveDate2 = null
        if (this.projectInfo.web_start) {
          let days = this.getDates(moment(this.projectInfo.web_start).subtract(30, 'days').format('YYYY-MM-DD'), moment(this.projectInfo.web_start).subtract(1, 'days').format('YYYY-MM-DD'))
          days.sort(function (a, b) {
            return new Date(b) - new Date(a);
          });
          let i = 1
          _.forEach(days, (d) => {
            if (!this.holidays.includes(d) && i <= 5) {
              fiveDate = d
              i = i + 1
            }
          })

          fiveDate = moment(fiveDate).subtract(1, 'days').format('YYYY-MM-DD')
          while (this.holidays.includes(fiveDate)) {
            fiveDate = moment(fiveDate).subtract(1, 'days').format('YYYY-MM-DD')
          }
        }
        if (this.projectInfo.medium_type == 2 || this.projectInfo.medium_type == 3) {
          let infDate = this.projectInfo.paper_preferred_date

          if (infDate) {
            let paper_people = this.projectInfo.paper_people
            let date_medium2 = ''
            switch (true) {
              case (paper_people > 0 && paper_people <= 100):
                date_medium2 = 7
                break
              case (paper_people > 100 && paper_people <= 200):
                date_medium2 = 8
                break
              case (paper_people > 200 && paper_people <= 300):
                date_medium2 = 9
                break
              case (paper_people > 300 && paper_people <= 400):
                date_medium2 = 10
                break
              case (paper_people > 400 && paper_people <= 500):
                date_medium2 = 11
                break
              case (paper_people > 500):
                date_medium2 = 12
                break
              default:
                break
            }
            let days2 = this.getDates(moment(infDate).subtract(30, 'days').format('YYYY-MM-DD'), infDate)
            days2.sort(function(a,b){
              return new Date(b) - new Date(a);
            });
            let i = 0
            _.forEach(days2, (d) => {
              if (!this.holidays.includes(d) && i <= date_medium2) {
                fiveDate2 = d
                i = i + 1
              }
            })
          }
        }


        let deadline = ''
        if (moment(fiveDate).isValid() && moment(fiveDate2).isValid()) {
          if (fiveDate < fiveDate2) {
            deadline = fiveDate
          } else {
            deadline = fiveDate2
          }
        } else if (!moment(fiveDate).isValid() && moment(fiveDate2).isValid()) {
          deadline = fiveDate2
        } else if (moment(fiveDate).isValid() &&  !moment(fiveDate2).isValid()) {
          deadline = fiveDate
        }
        this.deadlineSubmit = moment(deadline).isValid() ? moment(deadline).format('YYYY-MM-DD 23:59') : ''

      },
    },
    created() {
      // let id = this.$route.params.id
      // if (!this.projectInfo.id) {
      //   this.$router.push({
      //     name: 'ProjectDetail',
      //     params: {
      //       id: id
      //     }
      //   })
      // }
      let newStep = this.$route.query.progress
      if (newStep) {
        this.$store.dispatch('project/NEW_STEP', newStep);
      }

      this.getDeadlineSubmit()
      let id = this.$route.params.id ? this.$route.params.id : 1
      this.GET_PROJECT_DETAIL(id);

      if (this.projectInfo.id) {
        const titleEl = document.querySelector('head title');
        titleEl.textContent = this.projectType() + ' 診断申込登録フォーム';
      }


    },
    mounted() {
      this.carousel_total = $('.ls-carousels .ls-carousel-item').length;
    }
  }
</script>

<style scoped lang="scss">
  .cur_pointer{
    cursor: pointer;
  }
  .ls-carousel-item{
    display: none;
  }
  .ls-carousel-item.active{
    display: block;
  }
  .content_2_2{
    margin-top: 0px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  #body_2_2{
    top: 0px;
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 100;
  }

  .body_bg_2_2{
    padding: 15px;
    height: 100vh;
    position: relative;
  }
  .content_2_2_p3 {
    font-size: 12px !important;
  }
  .m_m{
    margin: 0px;
    position: absolute;
    width: 100%;
    bottom: 32px;
  }
  .m_m img {
    margin-left: 20px;
    padding: 5px;
    cursor: pointer;
  }
  .content_bg_2_2{
    position: relative;
    height: 100%;
  }
  .pointer{
    cursor: pointer;
  }
</style>
