<template>
  <div id="login">
    <div class="login_box">
      <img src="/images/1_1_logo.png" srcset="/images/1_1_logo.png 1x, /images/1_1_logo@2x.png 2x" alt="BCon">
      <p>営業部門 ログイン画面</p>
    </div>
    <div class="login_form_box">
      <div class="attention" v-show="loginMessage">
        <p>{{ loginMessage }}</p>
      </div>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(login)">
          <ValidationProvider name="id_login" rules="required" v-slot="{ errors }">
            <p><span>ログインID</span>
              <input :class="{ 'login_form_id': true, 'error': errors[0] }" type="text" name="id_login" v-model="id_login">
            </p>
            <span class="error" v-show="errors[0]">{{ errors[0] }}</span>
          </ValidationProvider>
          <ValidationProvider name="password" rules="required" v-slot="{ errors }">
            <p>パスワード<input :class="{ 'login_form_pass': true, 'error': errors[0] }" type="password" name="password" v-model="password"></p>
            <span class="error" v-show="errors[0]">{{ errors[0] }}</span>
          </ValidationProvider>
          <p><input class="login_form_sub" type="submit" value="ログイン"></p>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        id_login: null,
        password: null,
        loginMessage: null
      };
    },
    created() {

    },
    watch: {
      id_login() {
        this.loginMessage = null
      },
      password() {
        this.loginMessage = null
      }
    },
    methods: {
      login() {
        const {id_login, password} = this
        this.$store.dispatch('AUTH_REQUEST_SALES', {id_login, password}).then(() => {
          this.$router.push({
            name: 'ClientCreate'
          })
        }).catch((e) => {
          this.loginMessage = e.response.data.message

          // setTimeout(() => {
          //   this.loginMessage = null
          // }, 1000)
        })
      }
    }
  };
</script>
<style scoped lang="scss">
  .login-form {
    width: 340px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0 auto;

    > span {
      width: 100%;
    }
  }

  .login-form form {
    width: 100%;
    margin-bottom: 15px;
  }

  .login-form h2 {
    margin: 0 0 15px;
  }

  .form-control, .btn {
    min-height: 38px;
    border-radius: 2px;
  }

  .btn {
    font-size: 15px;
    font-weight: bold;
  }

  .attention {
    width: 485px;
  }
</style>


