<template>
  <div class="body_1_2">
    <div class="page-name" >{{ htmlPage() }}</div>
    <ValidationObserver ref="observer" v-show="!is_preview && project && project.progress_status == 1" v-slot="{ invalid }" tag="form" @submit.prevent="second()">
      <div class="form_box form_box_2_3 form_box_3_1">
        <div class="form_box_title">
          <p>詳細情報</p>
        </div>

        <dl>
          <dt class="form_dt_3_1_1 height_23">組織名 <br> 回答者への案内時に使用</dt>
          <dd><span>ご指定がない場合は、{{ project.client_name }}となります。</span><br>
            <input class="form_text" name="org_name" type="text" v-model="project.org_name"
                   placeholder="例：○○グループ"></dd>
        </dl>

        <dl>
          <dt class="form_dt_3_1_2 height_24">診断名 <br> 回答者への案内時に使用</dt>
          <dd><span>ご指定がない場合は、{{ projectType()}}となります。</span><br>
            <input class="form_text" type="text" name="diagnosis_name" placeholder="例：○○○研修　事前WEB診断"
                   v-model="project.diagnosis_name"></dd>
        </dl>
        <!--<dl class="radio_2" v-if="!(project.project_type >= 11 && project.project_type <= 16)">-->
          <!--<dt class="form_dt_3_1_4 top">属性一覧の設定</dt>-->
          <!--<dd>-->
            <!--<ValidationProvider name="select_kubun_type" rules="required" v-slot="{ errors }" class="validate">-->
              <!--<input :class="{ 'form_radio': true, 'error' : errors[0] }" id="select_kubun_type_1" type="radio" value="1" name="select_kubun_type"-->
                     <!--v-model="project.select_kubun_type">-->
              <!--<label for="select_kubun_type_1"> 回答時に選択</label>-->
              <!--<input :class="{ 'form_radio': true, 'error' : errors[0] }" id="select_kubun_type_2" type="radio" value="2" name="select_kubun_type"-->
                     <!--v-model="project.select_kubun_type">-->
              <!--<label for="select_kubun_type_2">対象者リスト提出時に設定</label>-->
            <!--</ValidationProvider>-->
          <!--</dd>-->
        <!--</dl>-->

        <dl v-if="project.project_type >= 11 && project.project_type <= 16">
          <dt class="required required12 form_dt_3_1_3">属性一覧の設定<img src="images/info.png"
                                                                    srcset="images/info.png 1x, images/info@2x.png 2x"
                                                                    alt="インフォメーション" class="info">
            <p class="info_fukidashi_4">属性一覧とは：回答者の役職・所属などのことです。</p></dt>
          <dd>
            <p class="margin_dd">
              <ValidationProvider name="attribute_setting_type" rules="required" v-slot="{ errors }" class="validate">
              <input :class="{ 'form_radio': true, 'error': errors[0] }" id="attribute_setting_type1" type="radio" value="1"
                     name="attribute_setting_type" v-model="project.attribute_setting_type"><label
                for="attribute_setting_type1">回答時に選択</label>
              <input :class="{ 'form_radio': true, 'error': errors[0] }" id="attribute_setting_type2" type="radio" value="2"
                     name="attribute_setting_type" v-model="project.attribute_setting_type"><label
              for="attribute_setting_type2">対象者リスト提出時に設定</label>
              </ValidationProvider>
            </p>
          </dd>
        </dl>
        <dl v-if="project.medium_type != 2">
          <dt class="required required13 height_up_2_3 height_up_3_1_2 top">ID・パスワード設定</dt>
          <dd>
            <ValidationProvider name="id_setting_type" rules="required" v-slot="{ errors }"
                                class="validate margin_dd fx_st btn_form_3_1">
              <p>ID</p>
              <p class="id_btn1">
                <input :class="{ 'form_radio': true, 'error': errors[0] }" type="radio" name="id_setting_type"
                       v-model="project.id_setting_type" value="1" id="id_setting_type1">
                <label for="id_setting_type1">事務局（貴社）が指定</label>
              </p>
              <p>
                <input :class="{ 'form_radio': true, 'error': errors[0] }" type="radio" name="id_setting_type"
                       v-model="project.id_setting_type" value="2" id="id_setting_type2">
                <label for="id_setting_type2">ビジネスコンサルタントが設定</label>
              </p>
            </ValidationProvider>
            <ValidationProvider name="pass_setting_type" rules="required" v-slot="{ errors }"
                                class="validate margin_dd fx_st btn_form_3_1 btn_form_3_1_2">
              <p>パスワード</p>
              <p class="id_btn2">
                <input :class="{ 'form_radio': true, 'error': errors[0] }" type="radio" name="pass_setting_type"
                       v-model="project.pass_setting_type" value="1" id="pass_setting_type1">
                <label for="pass_setting_type1">事務局（貴社）が指定</label>
              </p>
              <p>
                <input :class="{ 'form_radio': true, 'error': errors[0] }" type="radio" name="pass_setting_type"
                       v-model="project.pass_setting_type" value="2" id="pass_setting_type2">
                <label for="pass_setting_type2">ビジネスコンサルタントが設定</label>
              </p>
            </ValidationProvider>
          </dd>
        </dl>

        <dl>
          <dt class="form_box_2_3_dt height_up_3_1 top">貴社お問合せ先</dt>
          <dd class="margin_dd">
            <p class="form_contact_p">Web画面・質問紙に記載される貴社お問い合わせ先です。</p>
            <div class="fx_st">
              <p>部署・役職</p>
              <p><input class="form_text" type="text" name="inquiry_division" placeholder="◯◯部　部長"
                        v-model="project.inquiry_division" maxlength="50">
              </p>
            </div>
            <div class="fx_st">
              <p>氏名</p>
              <p class="margin_dd2"><input class="form_text" type="text" name="inquiry_name" placeholder="田中　一郎"
                                           v-model="project.inquiry_name" maxlength="20">
              </p>
            </div>
            <div class="fx_st">
              <p class="sch_1">電話番号</p>
              <p class="margin_dd3"><input class="form_text" type="tel" pattern="[0-9]+" maxlength="11" name="inquiry_tel" placeholder="03xxxxxxxx"
                                           v-model="project.inquiry_tel"
                                           onKeyUp="$(this).val($(this).val().replace(/[^\d]/ig, ''))"
                                           autocomplete="none"
                                           @keypress="preventAlphabetInput($event)">
                <br><span>※半角数字のみ入力してください。</span>
              </p>
            </div>
            <ValidationProvider ref="inquiry_email" name="inquiry_email" rules="email" v-slot="{ errors }" class="validate">
              <div class="fx_st">
                <p class="sch_1">メール</p>
                <p class="margin_dd4">
                  <input :class="{ 'form_text': true, 'error': errors[0] }" type="text" maxlength="100"
                         name="inquiry_email"
                         placeholder="Ichiro.t@cliant.co.jp" v-model="project.inquiry_email"
                         onKeyUp="$(this).val($(this).val().replace(/[^a-zA-Z.\-@_\d]/ig, ''))"
                  >
                </p>
              </div>
              <span class="error left-95" v-show="errors[0]">{{ errors[0] }}</span>
            </ValidationProvider>
          </dd>
        </dl>

        <dl v-if="project.medium_type != 1 && project.project_type >= 11 && project.project_type <= 16">
          <dt class="form_dt_3_1_5">質問紙提出先<img src="images/info.png" srcset="images/info.png 1x, images/info@2x.png 2x"
                                               alt="インフォメーション" class="info">
            <p class="info_fukidashi_10">質問紙に記載されます。（下記例）<br>
              <img src="images/sample02.png" alt="サンプル"></p></dt>
          <dd>
            <input class="form_text tel" type="text" name="paper_recipient" placeholder="例：●●部署 ▲▲さん" v-model="project.paper_recipient" maxlength="50">
          </dd>
        </dl>
        <!--v-if="project.project_type >= 11 && project.projec_type <=16"-->
        <!--<dl class="radio_2" v-if="project.project_type >= 11 && project.projec_type <=16">-->
          <dl class="radio_2" v-if="project.project_type == 11">
          <dt class="form_dt_3_1_4 required4 required top">業種別比較</dt>
          <dd>
            <ValidationProvider name="comparison_industry_type" rules="required" v-slot="{ errors }" class="validate">
              <input :class="{ 'form_radio': true, 'error' : errors[0] }" type="radio" name="comparison_industry_type"
                     v-model="project.comparison_industry_type" value="1" id="comparison_industry_type1">
              <label for="comparison_industry_type1">有り</label>
              <input :class="{ 'form_radio': true, 'error' : errors[0] }" type="radio" name="comparison_industry_type"
                     v-model="project.comparison_industry_type" value="0" id="comparison_industry_type2">
              <label for="comparison_industry_type2">無し</label>
              <!--<br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>-->
            </ValidationProvider>
          </dd>
        </dl>

        <dl class="radio_2">
          <dt class="form_dt_3_1_4 top required required3">回答者への案内メール</dt>
          <dd>
            <ValidationProvider name="guidance_email_type" rules="required" v-slot="{ errors }" class="validate">
              <input :class="{ 'form_radio': true, 'error' : errors[0] }" id="guidance_email_type_1" type="radio" value="1" name="guidance_email_type"
                     v-model="project.guidance_email_type">
              <label for="guidance_email_type_1">BConがメール送信</label>
              <input :class="{ 'form_radio': true, 'error' : errors[0] }" id="guidance_email_type_2" type="radio" value="2" name="guidance_email_type"
                     v-model="project.guidance_email_type">
              <label for="guidance_email_type_2">お客様が実施</label>
            </ValidationProvider>
          </dd>
        </dl>

        <dl class="radio_2" v-if="project.project_type == 11">
          <dt class="form_dt_3_1_4 required required3">質問文の主語変更</dt>
          <dd>
            <div style="display: flex; align-items: center">
              <ValidationProvider name="question_subject_type_other" rules="required" v-slot="{ errors }" class="validate">
              <input  :class="{ 'form_radio': true, 'error' : errors[0] }" id="radio_3_1_3" type="radio" value="当社" name="question_subject_type"
                     @change="subjectType()"
                     v-model="question_subject_type_other"><label
              for="radio_3_1_3">当社</label>
              <input  :class="{ 'form_radio': true, 'error' : errors[0] }" id="radio_3_1_4" type="radio" value="私の職場" name="question_subject_type"
                     @change="subjectType()"
                     v-model="question_subject_type_other"><label
              for="radio_3_1_4">私の職場</label>
              <input  v-if="(!isEmpty(question_subject_type_other_text) || other) && question_subject_type_other != '1'" class="form_radio" id="radio_3_1_5" type="radio"
                     name="question_subject_type" @change="subjectType()"
                     v-model="question_subject_type_other"
              :value="question_subject_type_other_text">
              <input  :class="{ 'form_radio': true, 'error' : errors[0] }" id="radio_3_1_5" type="radio"
                     name="question_subject_type" @change="subjectType()"
                     v-model="question_subject_type_other"
                     :value="1"
                     v-else>
              <label class="no-margin" for="radio_3_1_5">その他</label>
              </ValidationProvider>
              <ValidationProvider style="display: inline-block; width: 50%" name="question_subject_type_other_text"
                                  :rules="((question_subject_type_other !== '当社' && question_subject_type_other !== '私の職場' && empty(question_subject_type_other))) || question_subject_type_other == '1' ? 'required' : ''"
                                  v-slot="{ errors }" class="validate">
                <input :class="{ 'form_text tel_number': true, 'error': errors[0] }" type="text" name="other" maxlength="20"
                       placeholder="例：部門、本部、事業部" v-model="question_subject_type_other_text" @input="inputOther()">
                <br v-show="errors[0]"><span style="padding-left: 0px" class="error">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </dd>
        </dl>
        <dl class="radio_2 fx" v-if="project.project_type !== 11 && project.project_type > 11 && project.project_type <= 16">
          <dt>追加質問文</dt>
          <dd class="padding_t">
            <span class="margin_no">※見積・報告書形式については担当営業にお問い合わせください。</span>
            <p>
              <input class="none" id="qestion_type1" type="checkbox"  name="qestion_type1"
                     :checked="qestion_type1" @click="uncheck1()">
              <label class="check_2_3" for="qestion_type1">追加質問が5問以内/追加質問の尺度が下記の7点法を利用</label>
            </p>
            <p class="padding_b">
              <input class="none" id="qestion_type2" type="checkbox"
                     :checked="qestion_type2" name="qestion_type2"  @click="uncheck2()">
              <label class="check_2_3" for="qestion_type2">追加質問が6問以上/追加質問の尺度が7点法以外(個別対応)</label>
            </p>
            <div id="seven" v-if="project.qestion_type == 1">
              <div class="b_bottom">
                <p><span class="margin_no">【7点法尺度】</span></p>
                <p><img style="max-width: 100%" src="images/img3-1-2@2x.png" width="266" height="137"></p>
              </div>
              <div class="b_bottom2">
                <div v-for="(question, i) in project.questions" class="fx_st2">
                  <input type="hidden" v-model="question.id">
                  <div>
                    <p><span class="margin_no">質問文</span></p>
                    <p>
                      <ValidationProvider name="body" rules="max:100"
                                          v-slot="{ errors }" class="validate">
                      <textarea :class="{'error': errors[0] }" v-model="question.body" :name="'body' + i" rows="3" cols="40" id="textarea_3_1" placeholder="質問文を入れてください。" maxlength="200"></textarea>
                        <br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </p>
                  </div>
                  <div>
                    <p><span>回答方式</span></p>
                    <ValidationProvider :name="'format_type' + i" :rules="{required: question.body !== '' }"
                                        v-slot="{ errors }" class="validate">
                    <p>
                      <input :class="{ 'form_radio': true, 'error': errors[0] }" :id="'answer1' + i" type="radio" value="1" :name="'format_type' + i" v-model="question.format_type">
                      <label :for="'answer1' + i">尺度(7点法)</label>
                    </p>
                    <p>
                      <input :class="{ 'form_radio': true, 'error': errors[0] }" :id="'answer2' + i" type="radio" value="2" :name="'format_type' + i" v-model="question.format_type">
                      <label :for="'answer2' + i">自由記入</label>
                    </p>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="action">
                  <button :class="{'add': true}" :disabled="project.questions.length == 5" type="button" @click="addQuestion">＋</button>
                  <button class="del" type="button" @click="removeQuestion">－</button>
                </div>
              </div>
            </div>
          </dd>
        </dl>
      </div>
      <div class="footer_box footer_2_3">
        <p class="btn_1"><input class="form_box_sub" type="submit" value="入力内容を確認する"></p>
      </div>
    </ValidationObserver>
    <div v-show="is_preview && project.progress_status == 1">
      <div class="attention">
        <p>入力内容に誤りがないかご確認ください。(注意：入力内容の保存はまだ完了しておりません。)</p>
      </div>
      <form @submit.prevent="updateProgress()">
        <div class="form_box form_box_1_3">
          <div class="form_box_title">
            <p>詳細情報</p>
          </div>
          <dl class="">
            <dt class="">組織名</dt>
            <dd class="">
              <label v-if="!isEmpty(project.org_name)">{{ project.org_name }}</label>
              <label v-else>{{ project.client_name }}</label>
            </dd>
          </dl>
          <dl class="">
            <dt class="">診断名</dt>
            <dd class="">
              <label v-if="!isEmpty(project.diagnosis_name)">{{ project.diagnosis_name }}</label>
              <label v-else>{{ projectType()}}</label>
            </dd>
          </dl>
          <!--<dl>-->
            <!--<dt>属性一覧の設定</dt>-->
            <!--<dd>{{ select_kubun_types[project.select_kubun_type] ? select_kubun_types[project.select_kubun_type] : '' }}</dd>-->
          <!--</dl>-->
          <dl  v-if="project.project_type >= 11 && project.project_type <= 16">
            <dt>属性一覧の設定</dt>
            <dd>{{ attribute_setting_types[project.attribute_setting_type] ? attribute_setting_types[project.attribute_setting_type] : '' }}</dd>
          </dl>
          <dl class="form_box_1_3_dl" v-if="project.medium_type != 2">
            <dt>ID・パスワード設定</dt>
            <dd class="l_height">ID：　　　　{{ setting_types[project.id_setting_type] ? setting_types[project.id_setting_type] : '' }}<br>
              パスワード：{{ setting_types[project.pass_setting_type] ? setting_types[project.pass_setting_type] : '' }}</dd>
          </dl>
          <dl class="form_box_1_3_dl">
            <dt class="height_up_3_2">貴社お問合せ先</dt>
            <dd class="l_height">部署・役職　　{{ project.inquiry_division }}<br>
              氏名　　　　　{{ project.inquiry_name }}<br>
              電話番号　　　{{ project.inquiry_tel }}<br>
              メール　　　　{{ project.inquiry_email }}<br>
            </dd>
          </dl>
          <dl v-if="project.medium_type != 1 && project.project_type >= 11 && project.project_type <= 16">
            <dt>質問紙提出先</dt>
            <dd>{{ project.paper_recipient }}</dd>
          </dl>
          <dl class="" v-if="project.project_type == 11">
            <dt class="">業種別比較</dt>
            <dd>{{ project.comparison_industry_type == 1 ? '有り' : '無し' }}</dd>
          </dl>
          <dl>
            <dt>回答者への案内メール</dt>
            <dd>
              {{ guidance_email_types[project.guidance_email_type] ? guidance_email_types[project.guidance_email_type] : ''}}
            </dd>
          </dl>
          <dl v-if="project.project_type == 11">
            <dt>質問文の主語変更</dt>
            <dd>{{ project.question_subject_type}}</dd>
          </dl>
          <dl v-if="((project.qestion_type == 1 || project.qestion_type == 2) && project.project_type > 11 && project.project_type <= 16)">
            <dt>追加質問文</dt>
            <dd style="padding-right: 35px;">
              <div v-if="project.qestion_type !== ''">
                {{ project.qestion_type == 1 ? '追加質問が5問以内/追加質問の尺度が下記の7点法を利用' : '追加質問が6問以上/追加質問の尺度が7点法以外(個別対応)'}}
                <!--{{ project.qestion_type == 1 ? '下記の' : '追加質問が6問以上/追加質問の尺度が7点法以外(個別対応)'}}-->
                <!--<p v-if="project.qestion_type == 1" style="margin-bottom: 10px;">-->
                  <!--【7点法尺度】-->
                <!--</p>-->
                <div v-if="project.qestion_type == 1">
                  <div v-for="(question, i) in project.questions">
                    <div>【質問文】</div>
                    <p>{{ question.body }}</p>
                    <div>【回答方式】</div>
                    <p>{{ question.format_type == 1 ? '尺度(7点法)' : '自由記入' }}</p>
                  </div>

                  <!--<table class="table table-bordered">-->
                    <!--<tr>-->
                      <!--<th width="70%">質問文</th>-->
                      <!--<th width="30%">回答方式</th>-->
                    <!--</tr>-->
                    <!--<tr v-for="(question, i) in project.questions">-->
                      <!--<td>{{question.body}}</td>-->
                      <!--<td>-->
                        <!--<label v-if="question.format_type == 1">尺度(7点法)</label>-->
                        <!--<label v-if="question.format_type == 2">自由記入</label>-->
                      <!--</td>-->
                    <!--</tr>-->
                  <!--</table>-->
                </div>
              </div>
            </dd>
          </dl>
        </div>
        <div class="footer_box footer_box_1_3">
          <p class="btn2"><input class="form_box_sub_blue form_box_sub_2" type="button" @click="backInput()" value="前の画面に戻る"></p>
          <p class="btn3 btn_3_2"><input class="form_box_sub form_box_sub_2" type="submit" value="上記の内容で登録する"></p>
        </div>
      </form>
    </div>
    <div class="next-step" v-if="project.progress_status == 2 || project.progress_status == 2.2  || project.progress_status == 2.3">
      <div class="top_box_2_5">
        <p>「詳細情報」の登録を受け付けました。<br>
          受付完了メールを送信しますので内容をご確認ください。<br>
        </p>
      </div>

      <div class="content_box_2_5 fx_se">
        <div class="content_box_child_2_5 content_box_child_3_3" @click="newStep()">
          <img src="images/jizen_img1.png" alt="「詳細情報」を入力する" srcset="images/jizen_img1.png 1x, images/jizen_img1@2x.png 2x">
          <p>引き続き<br>対象者リストを登録する</p>
          <p class="btn-s btn_2_5 btn_3_3"><a href="javascript:;">対象者リスト登録<br>へ進む</a></p>
        </div>
        <div class="content_box_child_2_5 content_box_child_3_3">
          <img src="images/jizen_img2.png" alt="「詳細情報」を入力する" srcset="images/jizen_img2.png 1x, images/jizen_img2@2x.png 2x">
          <p>後で<br>対象者リストを登録する</p>
          <p class="content_box_child_2_5_p">この画面を終了した後で対象者リストを入力する<br>場合は、再度ログインしていただき、「対象者リ<br>スト →アップロード」へお進みください。
          </p>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  import {mapState} from 'vuex'
  import {
    ATTRIBUTE_SETTING_TYPE,
    COMPARE_TYPE,
    SELECT_KUBUN_TYPE,
    SETTING_TYPE,
    USER_CONSULTANT,
    USER_SALES,
    GUIDENCE_EMAIL_TYPE
  } from './../../../contstant'
  import {PROJECT_TYPE} from '../../../contstant'

  export default {
    data() {
      return {
        is_preview: false,
        select_kubun_types: SELECT_KUBUN_TYPE,
        setting_types: SETTING_TYPE,
        compare_types: COMPARE_TYPE,
        question_subject_type_other: '',
        question_subject_type_other_text: '',
        attribute_setting_types: ATTRIBUTE_SETTING_TYPE,
        guidance_email_types: GUIDENCE_EMAIL_TYPE,
        other: false,
        qestion_type1: false,
        qestion_type2: false
      }
    },
    props: {
      preview: {
        type: Boolean,
        default: false
      }
    },
    watch: {
      preview() {
        this.is_preview = this.preview
      },
      'project.question_subject_type': function () {
        if (this.project.question_subject_type !== '' && this.project.question_subject_type !== '当社' && this.project.question_subject_type !== '私の職場') {
          this.question_subject_type_other_text = this.project.question_subject_type
        }

        if (this.question_subject_type_other === '') {
          this.question_subject_type_other = this.project.question_subject_type
        }
      },
      question_subject_type_other_text() {
        if (this.question_subject_type_other_text) {
          this.question_subject_type_other = this.question_subject_type_other_text
          this.project.question_subject_type = this.question_subject_type_other_text
        }
      },
      'project.qestion_type': function() {
        if (this.project.qestion_type == 1) {
          this.qestion_type1 =  true
        }
        if (this.project.qestion_type == 2) {
          this.qestion_type2 =  true
        }
        if (this.project.qestion_type == 1 && this.project.questions.length === 0) {
          const newQ = {
            id: '',
            body: '',
            format_type: ''
          }
          this.project.questions.push(newQ)
        }
      },
      project() {
        if (this.project.qestion_type == 1 && this.project.questions.length === 0) {
          const newQ = {
            id: '',
            body: '',
            format_type: ''
          }
          this.project.questions.push(newQ)
        }

        if (this.project.question_subject_type !== '' && this.project.question_subject_type !== '当社' && this.project.question_subject_type !== '私の職場') {
          this.question_subject_type_other_text = this.project.question_subject_type
        }
      }
    },
    computed: {
      ...mapState('consultantUser', ['project', 'user'])
    },
    methods: {
      preventAlphabetInput ($event) {
        var keyCode = ($event.keyCode ? $event.keyCode : $event.which);
        if (((keyCode < 48 || keyCode > 57))) {
          $event.preventDefault();
        }
      },
      isEmpty(val) {
        return _.isEmpty(val)
      },
      uncheck1() {
        if (this.qestion_type1) {
          this.qestion_type1 = null
          if (this.project.qestion_type == 1) {
            this.project.qestion_type = null
          }

        } else {

          this.project.qestion_type = 1
          if (this.qestion_type2) {
            this.qestion_type2 = null
          }
        }

      },
      uncheck2() {
        if (this.qestion_type2) {
          if (this.project.qestion_type == 2) {
            this.project.qestion_type = null
          }
          this.qestion_type2 = null
        } else {
          this.project.qestion_type = 2
          if (this.qestion_type1) {
            this.qestion_type1 = null
          }
        }

      },
      empty(field) {
        return !_.isEmpty(field)
      },
      async second() {
        const isValid = await this.$refs.observer.validate();
        if (!isValid) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.observer.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            this.$refs.observer.refs[errors[0]["key"]].$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 100);
          return false
        } else {
          this.$wait.start('loading')
          this.$store.dispatch('project/UPDATE_PROJECT', {'project': this.project}).then(() => {
            this.is_preview = true
            this.$emit('hidden', true)
            this.$wait.end('loading')
            window.scrollTo(0, 0)
          }).catch(e => {
            this.$wait.end('loading')
          })
        }

      },
      updateProgress() {
        this.$wait.start('loading')
        let progress = ''
        if (this.project.project_type >= 11 && this.project.project_type <= 16) {
          progress = '2'
        } else if (this.project.project_type >= 21 && this.project.project_type <= 24) {
          progress = '2.2'
        } else if (this.project.project_type >= 1 && this.project.project_type <= 6) {
          progress = '2.3'
        }
        this.$store.dispatch('project/UPDATE_PROJECT', {'project': { progress_status: 2 } }).then(() => {
          this.is_preview = true
          this.$wait.end('loading')
          window.scrollTo(0,0)
        }).catch(e => {
          this.$wait.end('loading')
        })
      },
      newStep() {
        this.$emit('hidden',  false)
        this.$store.dispatch('project/NEW_STEP', 2)
      },
      logout() {
        if (this.user.type === USER_CONSULTANT) {
          this.logoutTo = 'LoginConsultant'
        } else if (this.user.type === USER_SALES) {
          this.logoutTo = 'LoginSales'
        }
        this.$store.dispatch('consultantUser/AUTH_LOGOUT').then(() => {
        }).catch(e => {

        })
      },
      addQuestion() {
        const newQ = {
          id: '',
          body: '',
          format_type: ''
        }
        if (this.project.questions.length <= 4) {
          this.project.questions.push(newQ)
        }
      },
      removeQuestion() {
        if (this.project.questions.length > 1) {
          this.project.questions.pop()
        }
      },
      backInput() {
        this.is_preview = false
        this.$emit('hidden',  false)
      },
      subjectType() {
        if (this.question_subject_type_other === '当社' || this.question_subject_type_other === '私の職場') {
          this.project.question_subject_type = this.question_subject_type_other
          this.question_subject_type_other_text = null
          this.other = false
        }
      },
      inputOther() {
        if (_.isEmpty(this.question_subject_type_other_text)) {
          this.question_subject_type_other = 1
        }
        this.other = true
      },
      projectType() {
        let type = ''
        if (PROJECT_TYPE[0][this.project.project_type]) {
          type = PROJECT_TYPE[0][this.project.project_type]
        } else if (PROJECT_TYPE[1][this.project.project_type]) {
          type = PROJECT_TYPE[1][this.project.project_type]
        }
        return type
      },
      htmlPage() {
        let html = ''
        if (!this.is_preview && this.project.progress_status == 1) {
          if (this.project.project_type !== 11) {
            html = '3-1.html'
          } else {
            html = '3-1-2.html'
          }

        }
        if (this.is_preview && this.project.progress_status == 1) {
          html = '3-2.html'
        }
        if (this.project.progress_status == 2) {
          html = '3-3.html'
        }

        if (!HTML_NO) {
          html = ''
        }

        return html
      }
    },
    mounted() {
      if (this.project.question_subject_type !== '' && this.project.question_subject_type !== '当社' && this.project.question_subject_type !== '私の職場') {
        this.question_subject_type_other_text = this.project.question_subject_type
      }

      if (this.project.qestion_type == 1 && this.project.questions.length === 0) {
        const newQ = {
          id: '',
          body: '',
          format_type: ''
        }
        this.project.questions.push(newQ)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .error {
    &.left-95 {
      padding-left: 95px;
      margin-left: 0;
    }
  }
  .check_2_3 {
    background-color: transparent;
    border: 0;
    padding: 0;
    padding-left: 30px;
    font-size: 14px !important;
    &:before {
      left: 0;
    }
    &:checked {
      &:after {
        opacity: 1;
      }
    }
    &:after {
      top: 0;
      left: 0;
    }
  }
  input[type="radio"]:checked + label:after {
    opacity: 1;
  }
  .none {
    display: none;
  }
  .form_box_3_1 input[type=text].tel_number {
    width: 216px !important;
  }
</style>
