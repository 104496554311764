<template>
  <div class="container">
    <header-component :title="'[営業一覧]'" :back="{name: 'ProjectList'}"></header-component>
    <div style="display: flex; justify-content: space-between; align-items: center; margin: 15px 0" v-if="user.id">
      <div  v-if="user && user.role && user.role.is_edit_c_user">
        <label class="custom-file-upload">
          <input size="60" class="form_box_sub" value="" type="file" @change="changeFile" accept=".csv" ref="csvFile">
          CSVをアップロード
        </label>
        <a class="custom-file-upload" :href="href" @click="exportCsv()">CSVをダウンロード</a>
      </div>

      <router-link class="btn btn-primary" v-if="user && user.role && user.role.is_edit_c_user" :to="{ name: 'SalesCreate'}">作成する</router-link>
    </div>

    <table class="table table-bordered"  v-if="sales">
      <thead class="thead-light">
      <tr>
        <th scope="col">No</th>
        <th scope="col">氏名</th>
        <th scope="col">ログインID</th>
        <th scope="col">アドレスメール</th>
        <th scope="col">営業所</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(sale, i) in sales.data">
        <th> {{ ((sales.current_page - 1) * sales.per_page) + 1 + i }}</th>
        <td> {{ sale.name }}</td>
        <td> {{ sale.id_login }}</td>
        <td> {{ sale.email }}</td>
        <td> {{ sale.office }}</td>
        <td>
          <a href="javascript:;" class="" @click="toDetail(sale.id)" >編集</a>
          <a style="color: red;" href="javascript:;" class="" @click="openDelete(sale.id, sale.name)">削除</a>
        </td>
      </tr>
      </tbody>
    </table>
    <pagination-component :current-page="sales.current_page"
    :per-page="sales.per_page"
    :total="sales.total"
    @changePage="changePage"
    v-if="sales.last_page > 1"
    ></pagination-component>
    <b-modal name="deletePopup" id="deletePopup" ref="deletePopup" :modal-class="['modal-action']" :centered="true"
             :no-close-on-backdrop="true" header-close-label="" hide-footer hide-header>
      <h5>{{dName}}名を削除しますか？</h5>
      <div class="message">
        <div></div>
      </div>
      <div class="modal-footer top-30">
        <b-button class="btn-submit" @click="$bvModal.hide('deletePopup')">キャンセル</b-button>
        <a class="btn btn-danger" @click="deleteSales()">削除</a>
      </div>
    </b-modal>
  </div>

</template>
<script>
  import {mapActions, mapMutations, mapState} from 'vuex'

  export default {
    data() {
      return {
        accept: '.csv',
        dId: null,
        dName: null,
        href: null
      }
    },
    computed: {
      ...mapState('consultantUser', ['sales', 'user'])
    },
    methods: {
      ...mapMutations('consultantUser', ['LIST_SALES', 'SET_SALES']),
      ...mapActions('consultantUser', ['GET_LIST_SALES', 'SET_SALES_DETAULT']),
      changePage(page) {
        this.GET_LIST_SALES(page).then(() => {
          this.$router.push({name: 'SalesList', query: {page: page}})
        })
      },
      toDetail(id) {
        const index = _.findIndex(this.sales, function(o) { return o.id == id; })
        if (index > -1) {
          this.SET_SALES_DETAULT(this.sales[index]);
        }

        this.$router.push({
          name: 'SalesUserEdit',
          params: {
            id: id
          }
        })
      },
      openDelete(id, name) {
        this.$refs['deletePopup'].show()
        this.dId = id
        this.dName = name
      },
      deleteSales() {
        this.$wait.start('loading')
        this.$store.dispatch('consultantUser/DELETE_SALES', this.dId).then(() => {
          this.$refs.deletePopup.hide()
          this.$wait.end('loading')
        })
        this.$refs['deletePopup'].hide()
      },
      async changeFile(e) {
        let files = e.target.files || e.dataTransfer.files;
        console.log(files[0])

        if (files[0] && files[0].size > 0) {
          this.$store.dispatch('consultantUser/SET_CSV_FILE', files[0]);
          this.$router.push(({
            name: 'ImportSales'
          }))
        }
      },
      exportCsv() {
        axios({
          url: '/api/admin/sales/export-csv',
          method: 'GET',
          responseType: 'blob', // important
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', '営業一覧.csv');
          document.body.appendChild(link);
          link.click();
        });
        // axios.get('/api/admin/sales/export-csv')
        // this.href = '/api/admin/sales/export-csv?'
      }
    },
    created() {
      let page = this.$route.query.page ? this.$route.query.page : 1
      this.GET_LIST_SALES(page)
    },
    mounted() {
    }

  }
</script>
<style scoped lang="scss">
  .container {
    width: 1000px;
    margin: 0 auto;
  }

  .custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
  }
</style>
