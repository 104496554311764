import Cookies from 'js-cookie'
import axios from "axios";

export default {
  state: {},
  getters: {},
  mutations: {
    AUTH_REQUEST_PROJECT(state) {
      state.status = 'loading'
    },
    AUTH_SUCCESS_PROJECT(state, token) {
      state.status = 'success'
      state.token = token
    },
    AUTH_ERROR_PROJECT(state) {
      state.status = 'error'
    }
  },
  actions: {
    AUTH_REQUEST_PROJECT({commit, dispatch}, user) {
      return new Promise((resolve, reject) => { // The Promise used for router redirect in login
        commit('AUTH_REQUEST_PROJECT')
        axios({url: '/api/login', data: user, method: 'POST'}).then(resp => {
          const token = resp.data.token
          const loginUser = resp.data.user
          const holidays = resp.data.holidays ? resp.data.holidays : {}
          commit('consultantUser/SET_HOLIDAYS', holidays)
          Cookies.set('user-token', token)
          Cookies.set('rememberMe', user.rememberMe)
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
          commit('consultantUser/AUTH_SUCCESS', token)
          commit('consultantUser/AUTH_USER', loginUser)

          if (loginUser.project && loginUser.project.progress_status) {
            commit('project/NEW_STEP', loginUser.project.progress_status, {root: true})
          }
          resolve(resp)
        })
          .catch(err => {
            commit('AUTH_ERROR_PROJECT', err)
            Cookies.remove('user-token') // if the request fails, remove any possible user token if possible
            Cookies.remove('user-type') // if the request fails, remove any possible user token if possible
            reject(err)
          })
      })
    },
    AUTH_LOGOUT_PROJECT({commit, dispatch}) {
      return new Promise((resolve, reject) => {
        Cookies.remove('user-token')
        // remove the axios default header
        delete axios.defaults.headers.common['Authorization']
        resolve()
      })
    }
  }
}
