<template>
  <div class="container" v-if="user && user.id">
    <header-component v-if="user" :title="'[管理者]'" :back="{name: 'ProjectList'}"></header-component>
    <a href="javascript:;" v-if="user && user.role && user.role.is_edit_c_user" @click="toCreate()" :style="{margin: '15px 0'}" class="btn btn-primary" >作成する</a>

    <table class="table table-bordered" v-if="consultants">
      <thead class="thead-light">
      <tr>
        <th scope="col">No</th>
        <th scope="col">氏名</th>
        <th scope="col">ログインID</th>
        <th scope="col">アドレスメール</th>
        <!--<th scope="col">役職</th>-->
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(consultant, i) in consultants.data">
        <th> {{ ((consultants.current_page - 1) * consultants.per_page) + 1 + i }}</th>
        <td> {{ consultant.name }}</td>
        <td> {{ consultant.id_login }}</td>
        <td> {{ consultant.email }}</td>
        <!--<td> {{ // consultant.division }}</td>-->
        <td>
          <a v-if="(user.role && user.role.is_edit_c_user) || (user.id == consultant.id)" href="javascript:;" class="" @click="toDetail(consultant.id)" >編集</a>
          <a  v-if="user.id !== consultant.id && user.role && user.role.is_edit_c_user" style="color: red;" href="javascript:;" class="" @click="openDelete(consultant.id, consultant.name)">削除</a>
        </td>
      </tr>
      </tbody>
    </table>
    <pagination-component :current-page="consultants.current_page"
                          :per-page="consultants.per_page"
                          :total="consultants.total"
                          @changePage="changePage"
                          v-if="consultants.last_page > 1"
    ></pagination-component>
    <b-modal name="deletePopup" id="deletePopup" ref="deletePopup" :modal-class="['modal-action']" :centered="true"
             :no-close-on-backdrop="true" header-close-label="" hide-footer hide-header>
      <h5>{{dName}}名を削除しますか？</h5>
      <div class="message">
        <div></div>
      </div>
      <div class="modal-footer top-30">
        <b-button class="btn-submit" @click="$bvModal.hide('deletePopup')">キャンセル</b-button>
        <a href="javascript:;" class="btn btn-danger" @click="delUser()">削除</a>
      </div>
    </b-modal>
  </div>

</template>
<script>
  import {mapActions, mapMutations, mapState} from 'vuex'

  export default {
    data() {
      return {
        dId: null,
        dName: null
      }
    },
    computed: {
      ...mapState('consultantUser', ['consultants', 'user'])
    },
    methods: {
      ...mapMutations('consultantUser', ['LIST_CONSULTANTS', 'SET_CONSULTANT']),
      ...mapActions('consultantUser', ['GET_LIST_CONSULTANTS', 'SET_CONSULTANT_DETAULT']),
      changePage(page) {
        this.$router.push({name: 'ConsultantUserList', query: {page: page}})
        this.GET_LIST_CONSULTANTS(page)
      },
      openDelete(id, name) {
        this.$refs['deletePopup'].show()
        this.dId = id
        this.dName = name
      },
      delUser() {
        this.$wait.start('loading')
        this.$store.dispatch('consultantUser/DELETE_CONSULTANT', this.dId).then(() => {
          this.$refs.deletePopup.hide()
          this.$wait.end('loading')
        })
        this.$refs['deletePopup'].hide()
      },
      toDetail(id) {
        // console.log(_.findIndex(this.consultants, id))
        const index = _.findIndex(this.consultants, function(o) { return o.id == id; })
        if (index > -1) {
          this.SET_CONSULTANT_DETAULT(this.consultants[index]);
        }

        this.$router.push({
          name: 'ConsultantUserEdit',
          params: {
            id: id
          }
        })
      },
      toCreate() {
        this.SET_CONSULTANT_DETAULT({});
        this.$router.push({
          name: 'ConsultantUserCreate'
        })
      }
    },
    created() {
      let page = this.$route.query.page ? this.$route.query.page : 1
      this.GET_LIST_CONSULTANTS(page)
      this.SET_CONSULTANT_DETAULT({});
    },
    mounted() {
    }

  }
</script>
<style scoped lang="scss">
  .container {
    width: 1000px;
    margin: 0 auto;
  }
  .table-bordered {
    margin-top: 30px;
  }
</style>
