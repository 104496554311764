import VueRouter from 'vue-router';
import LoginComponent from './../components/project-user/LoginComponent.vue';
import LoginConsultantComponent from '../components/consultant-user/LoginComponent.vue';
import LoginSalesComponent from '../components/sales-user/LoginComponent.vue';
import ProjectListComponent from '../components/consultant-user/project/ListComponent.vue';
import List2 from '../components/consultant-user/project/List2.vue';
import ProjectDetailComponent from '../components/consultant-user/project/DetailComponent.vue';
import ProjectEditComponent from '../components/consultant-user/project/EditComponent.vue';
import FileComponentComponent from '../components/consultant-user/project/FileComponent.vue';

import SalesCreateComponent from '../components/consultant-user/sales/SalesCreateComponent.vue';
import SalesListComponent from '../components/consultant-user/sales/SalesListComponent.vue';
import ImportSalesComponent from '../components/consultant-user/sales/ImportSalesComponent.vue';

import ConsultantUserCreateComponent from '../components/consultant-user/user/ConsultantUserCreateComponent.vue';
import ConsultantUserListComponent from '../components/consultant-user/user/ConsultantUserListComponent.vue';

import ClientRegisterComponent from '../components/sales-user/client/RegisterComponent';
import ApplicationFormComponent from '../components/project-user/apply/ApplicationFormComponent';
import ApplicationFormCompletedComponent from '../components/project-user/apply/ApplicationFormCompletedComponent';
import ApplyPreviewComponent from '../components/project-user/apply/ApplyPreviewComponent';
import NotFound from './../components/404.vue';
import store from '../store'
import {USER_CONSULTANT, USER_PROJECT, USER_SALES, PROJECT_TYPE} from '../contstant';

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters['consultantUser/isAuthenticated']) {
    next()
    return
  }
  if (to.path.indexOf('admin') > -1) {
    next('/admin')
  } else if (to.path.indexOf('sales') > -1) {
    next('/sales')
  } else {
    next('/')
  }
}

function checkUser(to, from, next) {
  if (store.getters['consultantUser/user'] && store.getters['consultantUser/user'].type === USER_CONSULTANT) {
    if ((to.path.indexOf('admin')) < 0) {
      next('/admin')
    }
  } else if (store.getters['consultantUser/user'] && store.getters['consultantUser/user'].type === USER_SALES) {
    if ((to.path.indexOf('sales')) < 0) {
      next('/sales')
    }
  } else if (store.getters['consultantUser/user'] && store.getters['consultantUser/user'].type === USER_PROJECT) {
    let p = store.state.consultantUser.project
    if (p.id) {
      const titleEl = document.querySelector('head title');
      titleEl.textContent =  projectType(p) +' 診断申込 登録フォーム';
    }
    if (to.path.indexOf('admin') > -1 || (to.path.indexOf('sales')) > -1) {
      next('/')
    }
  }
}
function projectType(p) {
  let type = ''
  if (PROJECT_TYPE[0][p.project_type]) {
    type = PROJECT_TYPE[0][p.project_type]
  } else if (PROJECT_TYPE[1][p.project_type]) {
    type = PROJECT_TYPE[1][p.project_type]
  }
  return type
}

const ifNotFound = (to, from, next) => {
  if (!store.getters['consultantUser/isAuthenticated']) {
    if (to.path.indexOf('admin') > -1) {
      next('/admin/login')
    } else if (to.path.indexOf('sales') > -1) {
      next('/sales/login')
    } else {
      next('/login')
    }
  }
}

const ifAuthenticated = (to, from, next) => {
  store.watch(
    (state) => {
      if (!!state.consultantUser.token) {
        if (_.isEmpty(store.getters['consultantUser/user'])) {
          store.dispatch('consultantUser/AUTH_TOKEN').then((data) => {
            checkUser(to, from, next)
          }).catch((e) => {
            next('/')
          })
        } else {
          checkUser(to, from, next)
        }
        next()
        return
      }

      if (to.path.indexOf('admin') > -1) {
        next('/admin/login')
      } else if (to.path.indexOf('sales') > -1) {
        next('/sales/login')
      } else {
        next('/login')
      }
    }
  )
}
const routes = [
  {
    path: '/login',
    name: 'Login',
    component: LoginComponent,
    beforeEnter: ifNotAuthenticated,
    meta: {
      title: '診断申込 ログイン'
    }
  },
  {
    path: '/admin/login',
    name: 'LoginConsultant',
    component: LoginConsultantComponent,
    beforeEnter: ifNotAuthenticated,
    meta: {
      title: '診断グループ　ログイン'
    }
  },
  {
    path: '/sales/login',
    name: 'LoginSales',
    component: LoginSalesComponent,
    beforeEnter: ifNotAuthenticated,
    meta: {
      title: '営業部門 ログイン画面'
    }
  },
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: HomeComponent,
  //   beforeEnter: ifAuthenticated
  // },
  // {
  //   path: '/admin',
  //   name: 'ProjectList',
  //   component: ProjectListComponent,
  //   beforeEnter: ifAuthenticated,
  //   meta: {
  //     title: '案件一覧'
  //   }
  // },
  {
    path: '/admin',
    name: 'ProjectList',
    component: List2,
    beforeEnter: ifAuthenticated,
    meta: {
      title: '案件一覧'
    }
  },
  {
    path: '/admin/users',
    name: 'ConsultantUserList',
    component: ConsultantUserListComponent,
    beforeEnter: ifAuthenticated,
    meta: {
      title: '管理者一覧'
    }
  },
  {
    path: '/admin/users/create',
    name: 'ConsultantUserCreate',
    component: ConsultantUserCreateComponent,
    beforeEnter: ifAuthenticated,
    meta: {
      title: '管理者登録'
    }
  },
  {
    path: '/admin/users/:id/edit',
    name: 'ConsultantUserEdit',
    component: ConsultantUserCreateComponent,
    beforeEnter: ifAuthenticated,
    meta: {
      title: '管理者登録'
    }
  },
  {
    path: '/admin/sales/create',
    name: 'SalesCreate',
    component: SalesCreateComponent,
    beforeEnter: ifAuthenticated,
    meta: {
      title: '営業登録'
    }
  },
  {
    path: '/admin/sales',
    name: 'SalesList',
    component: SalesListComponent,
    beforeEnter: ifAuthenticated,
    meta: {
      title: '営業一覧'
    }
  },
  {
    path: '/admin/sales/import',
    name: 'ImportSales',
    component: ImportSalesComponent,
    beforeEnter: ifAuthenticated,
    meta: {
      title: ''
    }
  },
  {
    path: '/admin/sales/:id/edit',
    name: 'SalesUserEdit',
    component: SalesCreateComponent,
    beforeEnter: ifAuthenticated,
    meta: {
      title: '営業登録'
    }
  },
  {
    path: '/',
    name: 'apply',
    component: ApplicationFormComponent,
    beforeEnter: ifAuthenticated,
    meta: {
      title: ''
    }
  },

  {
    path: '/apply-completed',
    name: 'apply-completed',
    component: ApplicationFormCompletedComponent,
    beforeEnter: ifAuthenticated,
    meta: {
      title: '組織診断サーベイ 診断用資料(必須) アップロード'
    }
  },
  {
    path: '/preview',
    name: 'ApplyPreview',
    component: ApplyPreviewComponent,
    beforeEnter: ifAuthenticated,
    meta: {
      title: '組織診断サーベイ 申込内容確認'
    }
  },
  {
    path: '/admin/projects/:id',
    name: 'ProjectDetail',
    component: ProjectDetailComponent,
    beforeEnter: ifAuthenticated,
    meta: {
      title: '案件詳細'
    }
  },
  {
    path: '/admin/projects/:id/edit',
    name: 'ProjectEdit',
    component: ProjectEditComponent,
    beforeEnter: ifAuthenticated,
    meta: {
      title: '案件編集'
    }
  },
  {
    path: '/admin/projects/:id/file',
    name: 'FileComponent',
    component: FileComponentComponent,
    beforeEnter: ifAuthenticated,
    meta: {
      title: '診断申込登録フォーム'
    }
  },
  // {
  //   path: '/sales/clients',
  //   name: 'ClientList',
  //   component: ClientListComponent,
  //   beforeEnter: ifAuthenticated
  // },
  {
    path: '/sales',
    name: 'ClientCreate',
    component: ClientRegisterComponent,
    beforeEnter: ifAuthenticated,
    meta: {
      title: '営業部門用 案件 登録フォーム'
    }
  },
  {
    path: '*', component: NotFound,
    beforeEnter: ifNotFound
  },
]


const router = new VueRouter({
  mode: 'history',
  routes: routes
});

export default router
