export const USER_CONSULTANT = 1;
export const USER_SALES = 2;
export const USER_PROJECT = 3;
export const PROJECT_TYPE = {
  0: {
    3: "キャリア・ポテンシャル診断",
    // 6: "多面評価診断（360度診断）",
    // 2: "リーダーシップにおける7つの役割診断(本人のみ)",
    // 22: "リーダーシップにおける7つの役割診断(多面)",
    // 1: "BConセールスパーソン診断（本人のみ）",
    // 21: "BConセールスパーソン診断（多面）",
    // 4: "BConパーソナルサーベイ（本人のみ）",
    // 24: "BConパーソナルサーベイ（多面）",
    // 5: "Quintet Index(QI)",
  },
  1: {
    11: "組織効果性サーベイ(OES）",
    // 12: "組織健康度診断",
    // 14: "コンプライアンス診断",
    // 16: "労働組合員診断",
    // 13: "組織活性度調査",
    // 15: "営業診断",
  }
}

export const PROJECT_TYPE_INDEX = {
  0: [
    {
      key: 3,
      value: "キャリア・ポテンシャル診断"
    },
    // {
    //   key: 6,
    //   value: "多面評価診断（360度診断）"
    // },
    // {
    //   key: 2,
    //   value: "リーダーシップにおける7つの役割診断(本人のみ)"
    // },
    // {
    //   key: 22,
    //   value: "リーダーシップにおける7つの役割診断(多面)"
    // },
    // {
    //   key: 1,
    //   value: "BConセールスパーソン診断（本人のみ）"
    // },
    // {
    //   key: 21,
    //   value: "BConセールスパーソン診断（多面）"
    // },
    // {
    //   key: 4,
    //   value: "BConパーソナルサーベイ（本人のみ）"
    // },
    // {
    //   key: 24,
    //   value: "BConパーソナルサーベイ（多面）"
    // },
    // {
    //   key: 5,
    //   value: "Quintet Index(QI)"
    // }
  ],
  1: [
    {
      key: 11,
      value: "組織効果性サーベイ(OES）"
    },
    // {
    //   key: 12,
    //   value: "組織健康度診断"
    // },
    // {
    //   key: 14,
    //   value: "コンプライアンス診断"
    // },
    // {
    //   key: 16,
    //   value: "労働組合員診断"
    // },
    // {
    //   key: 13,
    //   value: "組織活性度調査"
    // },
    // {
    //   key: 15,
    //   value: "営業診断"
    // }
  ]
}
export const REPORT_PREFERRER_DATE = {
  1: 9,
  21: 9,
  2: 9,
  22: 9,
  3: 9,
  4: 9,
  24: 9,
  5: 9,
  6: 9,
  11: 8,
  12: 23,
  13: 23,
  14: 23,
  15: 23,
  16: 23
}

export const PROJECT_STATUS = {
  0: "案件登録済",
    1: "申込済",
    2: "詳細情報登録済",
  3: "リスト登録済",
  4: "診断SG受理済",
  5: "診断SG受理済"
}
export const  INDUSTRY_TYPE = {
  "A": "建設不動産業界",
  "B": "建設業界",
  "B01": "食品製造業",
  "B02": "印刷業",
  "B03": "化学工業",
  "B04": "窯業・土石製品製造業",
  "B05": "鉄鋼・非鉄金属製造業",
  "B06": "金属製品製造業",
  "B07": "一般機器製造業",
  "B08": "電気機器製造業",
  "B09": "輸送機器製造業",
  "B10": "精密・医療機器製造業",
  "B11": "その他製造業",
  "C": "卸売・小売業界",
  "C01": "医薬品・医療用品卸売業",
  "C02": "卸売業、商社",
  "C03": "各種商品小売業",
  "C04": "自動車小売業",
  "D": "運送業",
  "E01": "専門・技術サービス業",
  "E02": "宿泊業、飲食サービス業",
  "E03": "生活関連サービス業、娯楽業",
  "F": "その他製造業",
  "F01": "通信業、放送業",
  "F02": "システム開発、情報処理業",
  "G": "JA",
  "H": "社会福祉法人",
  "I": "労働組合",
  "J": "医療法人",
  "K": "自治体・官公庁",
  "L": "金融業",
  "Z": "その他"
}


export const SCALE_TYPE = {
  1: "1～50名",
  2: "51～100名",
  3: "101～300名",
  4: "301~500名",
  5: "501~1000名",
  6: "1001～3000名",
  7: "3001～5000名",
  8: "5001名以上"
}

export const MEDIUM_TYPE = {
  1: 'Web',
  2: '質問紙',
  3: 'Web + 質問紙'
}

export const SELECT_KUBUN_TYPE = {
  1: '回答時に選択',
  2: '対象者リスト提出時に設定'
}

export const SETTING_TYPE = {
  1: '事務局（貴社）が指定',
  2: 'ビジネスコンサルタントが設定'
}

export const COMPARE_TYPE = {
  1: '有り',
  2: '無し'
}

export const PAPER_SEND_TYPE = {
  1: '1ヶ箇所',
  2: '2ヶ箇所',
  3: '3ヶ箇所'
}

export const ATTRIBUTE_SETTING_TYPE = {
  1: '回答時に選択',
  2: '対象者リスト提出時に設定'
}

export const GUIDENCE_EMAIL_TYPE = {
  1: 'BConがメール送信',
  2: 'お客様が実施'
}

export const QUESTION_FORMAT_TYPE = {
  1: '尺度(7点法)'
}

export const PERSONAL_TARGET = {
  1: '内定者',
  2: '半年-5年未満',
  3: '監督職(主任・係長)',
  4: '役員',
  5: '新人',
  6: '5年以上',
  7: '課長・部長'
}
