var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      style: { display: _vm.isOpenTuror ? "" : "none" },
      attrs: { id: "body_2_2" }
    },
    [
      _c("div", { staticClass: "body_bg_2_2" }, [
        _c("div", { staticClass: "ls-carousels content_2_2" }, [
          _c("div", { staticClass: "content_bg_2_2" }, [
            _vm._m(0),
            _vm._v(" "),
            _vm._m(1),
            _vm._v(" "),
            _vm._m(2),
            _vm._v(" "),
            _vm._m(3),
            _vm._v(" "),
            _c("p", [
              _c(
                "a",
                {
                  on: {
                    click: function($event) {
                      return _vm.close()
                    }
                  }
                },
                [
                  _c("img", {
                    staticClass: "close pointer",
                    attrs: {
                      src: "/images/close.png",
                      alt: "閉じる",
                      srcset:
                        "/images/close.png" +
                        " 1x, " +
                        "/images/close@2x.png" +
                        " 2x"
                    }
                  })
                ]
              )
            ]),
            _vm._v(" "),
            _c(
              "p",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.carousel > 0,
                    expression: "carousel > 0"
                  }
                ]
              },
              [
                _c(
                  "a",
                  {
                    on: {
                      click: function($event) {
                        return _vm.preCarousel()
                      }
                    }
                  },
                  [
                    _c("img", {
                      staticClass: "arrow_left pointer",
                      attrs: {
                        src: "/images/arrow_left.png",
                        alt: "前へ",
                        srcset:
                          "/images/arrow_left.png" +
                          " 1x, " +
                          "images/arrow_left@2x.png" +
                          " 2x"
                      }
                    })
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.carousel < _vm.carousel_total - 1,
                    expression: "carousel < (carousel_total - 1)"
                  }
                ]
              },
              [
                _c(
                  "a",
                  {
                    on: {
                      click: function($event) {
                        return _vm.nextCarousel()
                      }
                    }
                  },
                  [
                    _c("img", {
                      staticClass: "arrow_right pointer",
                      attrs: {
                        src: "/images/arrow_right.png",
                        alt: "次へ",
                        srcset:
                          "/images/arrow_right.png" +
                          " 1x, " +
                          "/images/arrow_right@2x.png" +
                          " 2x"
                      }
                    })
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "m_m" }, [
              _c(
                "div",
                { staticClass: "text-center" },
                [
                  _vm._l(_vm.carousel_total, function(n) {
                    return [
                      _c("a", { key: n }, [
                        _c("img", {
                          attrs: {
                            src:
                              n - 1 == _vm.carousel
                                ? "/images/blue_m.png"
                                : "/images/gray_m.png",
                            srcset:
                              n == _vm.carousel + 1
                                ? "/images/blue_m.png 1x, /images/blue_m@2x.png 2x"
                                : "/images/gray_m.png 1x, /images/gray_m@2x.png 2x"
                          },
                          on: {
                            click: function($event) {
                              return _vm.showCarousel(n - 1)
                            }
                          }
                        })
                      ])
                    ]
                  })
                ],
                2
              )
            ])
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ls-carousel-item active" }, [
      _c("p", { staticClass: "content_2_2_p" }, [
        _vm._v("ようこそ。"),
        _c("br"),
        _vm._v(" ビジネスコンサルタントの診断申込へ。")
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "content_2_2_img" }, [
        _c("img", {
          attrs: {
            src: "/images/img2_2_1.png",
            alt: "ようこそ。ビジネスコンサルタントの企業診断へ。",
            srcset:
              "/images/img2_2_1.png" +
              " 1x, " +
              "/images/img2_2_1@2x.png" +
              " 2x"
          }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ls-carousel-item" }, [
      _c("p", { staticClass: "content_2_2_p" }, [
        _vm._v("詳細情報"),
        _c("br"),
        _vm._v(" "),
        _c("span", [
          _vm._v("これから段階的に3ステップの作業をしていただきます。")
        ])
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "content_2_2_img" }, [
        _c("img", {
          staticStyle: { width: "577px" },
          attrs: {
            width: "577",
            src: "/images/img2_2_2x@2x.png",
            alt: "詳細情報",
            srcset:
              "/images/img2_2_2x@2x.png" +
              " 1x, " +
              "/images/img2_2_2x@2x.png" +
              " 2x"
          }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ls-carousel-item" }, [
      _c("p", { staticClass: "content_2_2_p" }, [
        _vm._v("途中でログアウトしても大丈夫。")
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "content_2_2_p2" }, [
        _vm._v("一旦、ログアウトしても再ログインすれば"),
        _c("br"),
        _vm._v("ご自分のステップのステータスへ戻ってこられます。")
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "content_2_2_3_img" }, [
        _c("img", {
          attrs: {
            src: "/images/img2_2_3.png",
            alt: "途中でログアウトしても大丈夫。",
            srcset:
              "/images/img2_2_3.png" +
              " 1x, " +
              "/images/img2_2_3@2x.png" +
              " 2x"
          }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ls-carousel-item" }, [
      _c("p", { staticClass: "content_2_2_p" }, [
        _vm._v("対象者リスト提出締切日をご確認ください。")
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "content_2_2_p2" }, [
        _vm._v("フォーム上で「回答開始日時」をご指定いただくと"),
        _c("br"),
        _vm._v("対象者リストのアップロード期日が決定いたします。")
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "content_2_2_p3" }, [
        _vm._v("※質問紙の場合は「質問紙到着希望日」が基準となります。")
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "content_2_2_4_img" }, [
        _c("img", {
          attrs: {
            src: "/images/img2_2_4.png",
            alt: "途中でログアウトしても大丈夫。",
            srcset:
              "/images/img2_2_4.png" +
              " 1x, " +
              "/images/img2_2_4@2x.png" +
              " 2x"
          }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }