import Vue from 'vue';
import Vuex from 'vuex';
import consultantUser from './modules/consultantUser';
import salesUser from './modules/salesUser';
import projectUser from './modules/projectUser';
import project from './modules/project';
import client from './modules/client';
import uploadFile from './modules/uploadFile';
import persistedState from "vuex-persistedstate";
import Cookies from "js-cookie";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    consultantUser, salesUser, projectUser, project, client, uploadFile
  },
  plugins: [
    persistedState({
      paths: ['client'],
      storage: {
        getItem: key => Cookies.get(key),
        setItem: (key, value) => Cookies.set(key, value),
        removeItem: key => Cookies.remove(key, { path: '/', domain: window.location.hostname })
      }
    }),
  ],
})
