import Vue from "vue";
import VueI18n from "vue-i18n";
import ar from "vee-validate/dist/locale/ja.json";
import en from "vee-validate/dist/locale/en.json";

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: "ja",
  messages: {
    ja: {
      fields: {
        c_role_id: '権限',
        client_name: "組織名",
        name1: "担当者　氏名",
        name2: "担当者2人目　氏名",
        email1: "担当者　メールアドレス",
        email2: "担当者2人目　メールアドレス",
        email: "ログインID",
        emailSales: 'アドレスメール',
        staff_name: "担当営業名",
        is_multisite_diagnosis: "診断種別選択",
        is_comparison_age: "経年比較",
        password: 'パスワード',
        project_type: "診断種別選択",
        division2: '',
        staff_email1: '共有先メールアドレス①',
        staff_email2: '共有先メールアドレス②',
        apply_zip_code: '〒',
        apply_address1: '都道府県',
        apply_address2: '市区町村番地',
        client_tel: '電話番号',
        zip_code: '〒',
        address1: '都道府県',
        address2: '市区町村番地',
        paper2_address1: '都道府県',
        paper2_address2: '市区町村番地',
        paper1_address1: '都道府県',
        paper1_address2: '市区町村番地',
        industry_type: '業界・業種',
        comparison_industry_type: '業界業種別比較',
        scale_type: '規模',
        select_kubun_type: '区分コードの選択者',
        id_setting_type: 'ID',
        pass_setting_type: 'パスワード',
        web_start1: 'Web回答開始日時',
        web_end1: 'Web回答終了日時',
        report_preferred_date: 'レポート納品希望日',
        paper_preferred_date: '質問紙到着希望日',
        paper_preferred_date3: '質問紙到着希望日',
        paper_preferred_date2: '質問紙提出期限',
        end: '診断終了日時',
        paper_bcon_receipt_date2: '質問紙BCon到着日（任意）',
        paper_bcon_receipt_date: '質問紙BCon到着日（任意）',
        paper_bcon_receipt_date3: '質問紙BCon到着日（任意）',
        report_preferred_date2: '報告書納品希望日',
        web_start2: 'Web回答開始日時',
        web_end2: 'web診断終了日時',
        web_start3: 'web診断開始日時',
        web_end3: 'web診断終了日時',
        id_login: 'ログインID',
        paper1_copies: '質問紙の送付部数①',
        paper2_copies: '質問紙の送付部数②',
        paper_send_type: '質問紙の送付先点数',
        paper_limit_date: '質問紙提出期限',
        paper_limit_date2: '質問紙提出期限',
        paper_limit_date3: '質問紙提出期限',
        paper_people: '質問紙',
        medium_type: '実施方法',
        web_people: 'Web',
        position: '役職',
        division: '所属',
        username: '氏名',
        attribute_setting_type: '属性一覧の設定',
        paper1_division: '部署',
        paper1_name: '氏名',
        paper1_position: '役職',
        paper2_division: '部署',
        paper2_name: '氏名',
        paper2_position: '役職',
        question_subject_type_other_text: 'text'
      },
      validation: ar.messages
    },
    en: {
      fields: {
        client_name: "",
        name1: ""
      },
      validation: en.messages
    }
  }
});

export {i18n};
