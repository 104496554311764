var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.project && _vm.project.id,
          expression: "project && project.id"
        }
      ],
      staticClass: "container"
    },
    [
      _c("header-component", { attrs: { title: _vm.projectType() } }),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c("div", { staticClass: "form_box form_box_1_3" }, [
        _vm._m(2),
        _vm._v(" "),
        _c("dl", [
          _c("dt", [_vm._v("組織名(会社名)")]),
          _vm._v(" "),
          _c("dd", [_vm._v(_vm._s(_vm.project.client_name))])
        ]),
        _vm._v(" "),
        _c("dl", [
          _c("dt", [_vm._v("ご担当者様 所属・役職")]),
          _vm._v(" "),
          _c("dd", [
            _vm.project && _vm.project.user && _vm.project.user[0]
              ? _c("div", [
                  _vm._v(
                    _vm._s(_vm.project.user[0].division) +
                      " " +
                      _vm._s(_vm.project.user[0].position)
                  )
                ])
              : _vm._e()
          ])
        ]),
        _vm._v(" "),
        _c("dl", [
          _c("dt", [_vm._v("ご担当者様 氏名")]),
          _vm._v(" "),
          _c("dd", [
            _vm._v(
              _vm._s(
                _vm.project && _vm.project.user && _vm.project.user[0]
                  ? _vm.project.user[0].name
                  : ""
              )
            )
          ])
        ]),
        _vm._v(" "),
        _c("dl", [
          _c("dt", [_vm._v("ご担当者様 メールアドレス")]),
          _vm._v(" "),
          _c("dd", [
            _vm._v(
              _vm._s(
                _vm.project && _vm.project.user && _vm.project.user[0]
                  ? _vm.project.user[0].email
                  : ""
              )
            )
          ])
        ]),
        _vm._v(" "),
        _c("dl", [
          _c("dt", [_vm._v("ご担当者様2人目 氏名")]),
          _vm._v(" "),
          _c("dd", [
            _vm._v(
              "\n          " +
                _vm._s(
                  _vm.project && _vm.project.user && _vm.project.user[1]
                    ? _vm.project.user[1].name
                    : ""
                ) +
                "\n        "
            )
          ])
        ]),
        _vm._v(" "),
        _c("dl", [
          _c("dt", [_vm._v("ご担当者様2人目 メールアドレス")]),
          _vm._v(" "),
          _c("dd", [
            _vm._v(
              "\n          " +
                _vm._s(
                  _vm.project && _vm.project.user && _vm.project.user[1]
                    ? _vm.project.user[1].email
                    : ""
                ) +
                "\n        "
            )
          ])
        ]),
        _vm._v(" "),
        _c("dl", [
          _c("dt", [_vm._v("電話番号")]),
          _vm._v(" "),
          _c("dd", [
            _vm._v(
              "\n          " + _vm._s(_vm.project.client_tel) + "\n        "
            )
          ])
        ]),
        _vm._v(" "),
        _c("dl", { staticClass: "form_box_1_3_dl" }, [
          _c("dt", [_vm._v("住所")]),
          _vm._v(" "),
          _c("dd", [
            _c("p", [_vm._v("〒" + _vm._s(_vm.project.zip_code))]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                _vm._s(_vm.project.address1) + _vm._s(_vm.project.address2)
              )
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form_box form_box_1_3" }, [
        _vm._m(3),
        _vm._v(" "),
        _c("dl", [
          _c("dt", { attrs: { width: "25%" } }, [_vm._v("実施方法")]),
          _vm._v(" "),
          _c("dd", { attrs: { width: "75%" } }, [
            _vm.project.medium_type == 3
              ? _c("label", [
                  _vm._v(
                    "Web " +
                      _vm._s(_vm.project.web_people) +
                      "名 + 質問紙 " +
                      _vm._s(_vm.project.paper_people) +
                      "名"
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.project.medium_type == 2
              ? _c("label", [
                  _vm._v("質問紙 " + _vm._s(_vm.project.paper_people) + "名")
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.project.medium_type == 1
              ? _c("label", [
                  _vm._v("Web " + _vm._s(_vm.project.web_people) + "名")
                ])
              : _vm._e()
          ])
        ]),
        _vm._v(" "),
        _vm.project.medium_type == 2 || _vm.project.medium_type == 3
          ? _c("dl", [
              _c("dt", [_vm._v("質問紙の送付先拠点数")]),
              _vm._v(" "),
              _c("dd", [
                _vm.project.paper_send_type == 1
                  ? _c("label", [_vm._v("1拠点")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.project.paper_send_type == 2
                  ? _c("label", [_vm._v("2拠点")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.project.paper_send_type == 3
                  ? _c("label", [_vm._v("3拠点以上")])
                  : _vm._e()
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.project.paper_send_type == 1 || _vm.project.paper_send_type == 2
          ? _c("dl", [
              _c("dt", [_vm._v("質問紙の送付先住所①")]),
              _vm._v(" "),
              _c("dd", [
                _c("p", [_vm._v("氏名: " + _vm._s(_vm.project.paper1_name))]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "住所: 〒" +
                      _vm._s(_vm.project.paper1_zip_code) +
                      " " +
                      _vm._s(_vm.project.paper1_address1) +
                      _vm._s(_vm.project.paper1_address2) +
                      _vm._s(_vm.project.paper1_address3)
                  )
                ])
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.project.paper_send_type == 2
          ? _c("dl", [
              _c("dt", [_vm._v("質問紙の送付先住所②")]),
              _vm._v(" "),
              _c("dd", [
                _c("p", [_vm._v("氏名: " + _vm._s(_vm.project.paper2_name))]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "住所: 〒" +
                      _vm._s(_vm.project.paper2_zip_code) +
                      " " +
                      _vm._s(_vm.project.paper2_address1) +
                      _vm._s(_vm.project.paper2_address2) +
                      _vm._s(_vm.project.paper2_address3)
                  )
                ])
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("dl", [
          _c("dt", { staticClass: "top" }, [_vm._v("回答スケジュール")]),
          _vm._v(" "),
          _c("dd", { staticClass: "fx" }, [
            _c("div", { staticClass: "form_box_2_4" }, [
              _c(
                "p",
                {
                  staticClass: "form_box_2_4_p form_box_2_4_2_p",
                  attrs: { id: "margin_left_20" }
                },
                [
                  _c("span", [
                    _vm._v(
                      "対象者リスト提出締切日　" +
                        _vm._s(_vm._f("dateFormat")(_vm.deadlineSubmit))
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _vm.project.medium_type == 1
                ? _c("div", { staticClass: "form_box_360" }, [
                    _c("div", { staticClass: "margin_b_2_4 fx_ce row" }, [
                      _c("p", { staticClass: "col col-140" }, [
                        _vm._v("Web診断開始日時")
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "col margin_l_2_4 stick_2_4" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("datetimeFormat")(_vm.project.web_start)
                          )
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "row margin_b_2_4 margin_b_2_3 fx_ce" },
                      [
                        _c("p", { staticClass: "col-140" }, [
                          _vm._v("Web診断終了日時")
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "col margin_l_2_4" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("datetimeFormat")(_vm.project.web_end)
                            )
                          )
                        ])
                      ]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.project.medium_type == 2
                ? _c("div", { staticClass: "form_box_360" }, [
                    _c("div", { staticClass: "margin_b_2_4 fx_ce row" }, [
                      _c("p", { staticClass: "col col-140" }, [
                        _vm._v("質問紙到着希望日")
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "col margin_l_2_4 stick_2_4" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("dateFormat")(
                              _vm.project.paper_preferred_date
                            )
                          )
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "margin_b_2_4 fx_ce" }, [
                      _c("p", [_vm._v("質問紙提出期限(任意)")]),
                      _vm._v(" "),
                      _c("p", { staticClass: "margin_l_2_4 stick_2_4" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("dateFormat")(_vm.project.paper_limit_date)
                          )
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "margin_b_2_4 fx_ce row" }, [
                      _c("p", { staticClass: "col col-140" }, [
                        _vm._v("質問紙BCon到着日")
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "col margin_l_2_4" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("dateFormat")(
                              _vm.project.paper_bcon_receipt_date
                            )
                          )
                        )
                      ])
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.project.medium_type == 3
                ? _c("div", { staticClass: "form_box_360" }, [
                    _c("div", { staticClass: "margin_b_2_4 fx_ce row" }, [
                      _c("p", { staticClass: "col col-140" }, [
                        _vm._v("Web診断開始日時")
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "col margin_l_2_4 stick_2_4" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("datetimeFormat")(_vm.project.web_start)
                          )
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "row margin_b_2_4 margin_b_2_3 fx_ce" },
                      [
                        _c("p", { staticClass: "col-140" }, [
                          _vm._v("Web診断終了日時")
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "col margin_l_2_4" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("datetimeFormat")(_vm.project.web_end)
                            )
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _vm._m(4),
                    _vm._v(" "),
                    _c("div", { staticClass: "margin_b_2_4 fx_ce row" }, [
                      _c("p", { staticClass: "col col-140" }, [
                        _vm._v("質問紙到着希望日")
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "col margin_l_2_4 stick_2_4" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("dateFormat")(
                              _vm.project.paper_preferred_date
                            )
                          )
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "margin_b_2_4 fx_ce" }, [
                      _c("p", [_vm._v("質問紙提出期限(任意)")]),
                      _vm._v(" "),
                      _c("p", { staticClass: "margin_l_2_4 stick_2_4" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("dateFormat")(_vm.project.paper_limit_date)
                          )
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "margin_b_2_4 fx_ce row" }, [
                      _c("p", { staticClass: "col col-140" }, [
                        _vm._v("質問紙BCon到着日")
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "col margin_l_2_4" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("dateFormat")(
                              _vm.project.paper_bcon_receipt_date
                            )
                          )
                        )
                      ])
                    ])
                  ])
                : _vm._e()
            ])
          ])
        ]),
        _vm._v(" "),
        _c("dl", [
          _c("dt", [_vm._v("報告書納品希望日")]),
          _vm._v(" "),
          _c("dd", [
            _vm._v(
              _vm._s(_vm._f("dateFormat")(_vm.project.report_preferred_date))
            )
          ])
        ]),
        _vm._v(" "),
        _c("dl", { staticClass: "form_box_1_3_dl" }, [
          _c("dt", [_vm._v("該当する業種・組織規模")]),
          _vm._v(" "),
          _c("dd", [
            _vm._v(
              "業種　　　：　" +
                _vm._s(
                  this.industry_types[_vm.project.industry_type]
                    ? this.industry_types[_vm.project.industry_type]
                    : ""
                )
            ),
            _c("br"),
            _vm._v(
              "\n          規模　　　：　" +
                _vm._s(
                  this.scale_types[_vm.project.scale_type]
                    ? this.scale_types[_vm.project.scale_type]
                    : ""
                )
            )
          ])
        ]),
        _vm._v(" "),
        _c("dl", [
          _c("dt", [_vm._v("備考欄")]),
          _vm._v(" "),
          _c("dd", [
            _vm.project &&
            _vm.project.client_memo &&
            _vm.project.client_memo.length > 0
              ? _c(
                  "div",
                  _vm._l(_vm.project.client_memo.split("\n"), function(line) {
                    return _c("div", [_vm._v(_vm._s(line) + "\n          ")])
                  }),
                  0
                )
              : _c("div", [_vm._v("なし")])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form_box form_box_1_3 form_box_5_2" }, [
        _vm._m(5),
        _vm._v(" "),
        _c("dl", [
          _c("dt", [_vm._v("組織名")]),
          _vm._v(" "),
          _c("dd", [_vm._v(_vm._s(_vm.project.org_name))])
        ]),
        _vm._v(" "),
        _c("dl", [
          _c("dt", [_vm._v("診断名")]),
          _vm._v(" "),
          _c("dd", [_vm._v(_vm._s(_vm.project.diagnosis_name))])
        ]),
        _vm._v(" "),
        _vm.project.project_type >= 11 && _vm.project.project_type <= 16
          ? _c("dl", [
              _c("dt", [_vm._v("属性一覧の設定")]),
              _vm._v(" "),
              _c("dd", [
                _vm._v(
                  _vm._s(
                    _vm.attribute_setting_types[
                      _vm.project.attribute_setting_type
                    ]
                      ? _vm.attribute_setting_types[
                          _vm.project.attribute_setting_type
                        ]
                      : ""
                  )
                )
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("dl", { staticClass: "form_box_1_3_dl" }, [
          _c("dt", [_vm._v("ID・パスワード設定")]),
          _vm._v(" "),
          _c("dd", { staticClass: "l_height" }, [
            _vm._v(
              "\n          ID：　　　　" +
                _vm._s(
                  _vm.setting_types[_vm.project.id_setting_type]
                    ? _vm.setting_types[_vm.project.id_setting_type]
                    : ""
                )
            ),
            _c("br"),
            _vm._v(
              "\n          パスワード：" +
                _vm._s(
                  _vm.setting_types[_vm.project.pass_setting_type]
                    ? _vm.setting_types[_vm.project.pass_setting_type]
                    : ""
                ) +
                "\n        "
            )
          ])
        ]),
        _vm._v(" "),
        _c("dl", { staticClass: "form_box_1_3_dl" }, [
          _c("dt", { staticClass: "height_up_3_2" }, [
            _vm._v("貴社お問合せ先")
          ]),
          _vm._v(" "),
          _c("dd", { staticClass: "l_height" }, [
            _vm._v(
              "\n          部署・役職 " + _vm._s(_vm.project.inquiry_division)
            ),
            _c("br"),
            _vm._v("\n          氏名 " + _vm._s(_vm.project.inquiry_name)),
            _c("br"),
            _vm._v("\n          電話 " + _vm._s(_vm.project.inquiry_tel)),
            _c("br"),
            _vm._v("\n          メール " + _vm._s(_vm.project.inquiry_email)),
            _c("br")
          ])
        ]),
        _vm._v(" "),
        _vm.project.medium_type != 1 &&
        _vm.project.project_type >= 11 &&
        _vm.project.project_type <= 16
          ? _c("dl", [
              _c("dt", [_vm._v("質問紙提出先")]),
              _vm._v(" "),
              _c("dd", [_vm._v(_vm._s(_vm.project.paper_recipient))])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.project.project_type == 11
          ? _c("dl", [
              _c("dt", [_vm._v("業種別比較")]),
              _vm._v(" "),
              _c("dd", [
                _vm._v(
                  _vm._s(_vm.project.comparison_industry_type ? "有り" : "無し")
                )
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("dl", [
          _c("dt", [_vm._v("回答者への案内メール")]),
          _vm._v(" "),
          _c("dd", [
            _vm._v(
              _vm._s(
                _vm.guidance_email_types[_vm.project.guidance_email_type]
                  ? _vm.guidance_email_types[_vm.project.guidance_email_type]
                  : ""
              )
            )
          ])
        ]),
        _vm._v(" "),
        _vm.project.project_type == 11
          ? _c("dl", [
              _c("dt", [_vm._v("質問文の主語変更")]),
              _vm._v(" "),
              _c("dd", [_vm._v(_vm._s(_vm.project.question_subject_type))])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.project.qestion_type == 1 ||
        (_vm.project.qestion_type == 2 &&
          _vm.project.project_type > 11 &&
          _vm.project.project_type <= 16)
          ? _c("dl", { staticClass: "fx" }, [
              _c("dt", [_vm._v("追加質問文")]),
              _vm._v(" "),
              _c("dd", { staticClass: "padding_tb" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.project.qestion_type == 1
                        ? "追加質問が5問以内/追加質問の尺度が左記の7点法を利用"
                        : "追加質問が6問以上/追加質問の尺度が7点法以外(個別対応)"
                    ) +
                    "\n          "
                ),
                _vm.project.qestion_type == 1
                  ? _c(
                      "div",
                      _vm._l(_vm.project.questions, function(question, i) {
                        return _c("div", [
                          _c("div", [_vm._v("【質問文】")]),
                          _vm._v(" "),
                          _c("p", [_vm._v(_vm._s(question.body))])
                        ])
                      }),
                      0
                    )
                  : _vm._e()
              ])
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "footer_box footer_box_1_3" }, [
        _c("p"),
        _vm._v(" "),
        _c("p", { staticClass: "btn" }, [
          _c("input", {
            staticClass: "form_box_sub form_box_sub_2",
            attrs: { type: "button", value: "戻る" },
            on: {
              click: function($event) {
                return _vm.backForm()
              }
            }
          })
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "top_contact fx_cece" }, [
      _c("div", { staticClass: "top_con1" }, [
        _c("img", {
          attrs: {
            src: "/images/top_qu.png",
            srcset:
              "/images/top_qu.png" + " 1x, " + "/images/top_qu@2x.png" + " 2x",
            alt: "お問合せ先"
          }
        }),
        _c("br"),
        _vm._v("お問合せ先\n    ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "top_con2" }, [
        _c("p", [
          _vm._v(
            "不明点や確認事項がありましたら直接診断チームまでお問合せください。"
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "fx_ce" }, [
          _c("p", { staticClass: "top_con2_p border_left" }, [
            _c("img", {
              attrs: {
                src: "/images/top_mail.png",
                srcset:
                  "/images/top_mail.png" +
                  " 1x, " +
                  "/images/top_mail@2x.png" +
                  " 2x",
                alt: "メールでのお問合せ"
              }
            }),
            _vm._v("メールでのお問合せ"),
            _c("br"),
            _vm._v(" "),
            _c("span", { staticClass: "span_color" }, [
              _c("a", { attrs: { href: "mailto:survey-rcptn@info.bcon.jp" } }, [
                _vm._v("survey-rcptn@info.bcon.jp")
              ])
            ])
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "top_con2_p" }, [
            _c("img", {
              attrs: {
                src: "/images/top_tel.png",
                srcset:
                  "/images/top_tel.png" +
                  " 1x, " +
                  "/images/top_tel@2x.png" +
                  " 2x",
                alt: "お電話でのお問合せ"
              }
            }),
            _vm._v("お電話でのお問合せ"),
            _c("br"),
            _vm._v(" "),
            _c("span", [_vm._v("03-3287-3416(平日 10:00～17:00)")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "attention" }, [
      _c("p", [_vm._v("以下の内容で診断を受け付けております。")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form_box_title" }, [
      _c("p", [_vm._v("お客様情報")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form_box_title" }, [
      _c("p", [_vm._v("申込内容")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("hr")])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form_box_title" }, [
      _c("p", [_vm._v("詳細情報")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }