<template>
  <div class="container" v-show="project && project.id">
    <!--<div class="page-name" v-show="HTML_NO">5-2.html</div>-->
    <header-component :title="projectType()"></header-component>

    <div class="top_contact fx_cece">
      <div class="top_con1">
        <img src="/images/top_qu.png" srcset="/images/top_qu.png 1x, /images/top_qu@2x.png 2x" alt="お問合せ先"><br>お問合せ先
      </div>
      <div class="top_con2">
        <p>不明点や確認事項がありましたら直接診断チームまでお問合せください。</p>
        <div class="fx_ce">
          <p class="top_con2_p border_left">
            <img src="/images/top_mail.png" srcset="/images/top_mail.png 1x, /images/top_mail@2x.png 2x" alt="メールでのお問合せ">メールでのお問合せ<br>
            <span class="span_color"><a href="mailto:survey-rcptn@info.bcon.jp">survey-rcptn@info.bcon.jp</a></span>
          </p>
          <p class="top_con2_p">
            <img src="/images/top_tel.png" srcset="/images/top_tel.png 1x, /images/top_tel@2x.png 2x" alt="お電話でのお問合せ">お電話でのお問合せ<br>
            <span>03-3287-3416(平日 10:00～17:00)</span>
          </p>
        </div>
      </div>
    </div>

    <div class="attention">
      <p>以下の内容で診断を受け付けております。</p>
    </div>

    <!--<form action="" method="post">-->
      <div class="form_box form_box_1_3">
        <div class="form_box_title">
          <p>お客様情報</p>
        </div>
        <dl>
          <dt>組織名(会社名)</dt>
          <dd>{{ project.client_name }}</dd>
        </dl>
        <dl>
          <dt>ご担当者様 所属・役職</dt>
          <dd><div v-if="project && project.user && project.user[0]">{{ project.user[0].division }} {{ project.user[0].position }}</div></dd>
        </dl>
        <dl>
          <dt>ご担当者様 氏名</dt>
          <dd>{{ project && project.user && project.user[0] ? project.user[0].name : '' }}</dd>
        </dl>
        <dl>
          <dt>ご担当者様 メールアドレス</dt>
          <dd>{{ project && project.user && project.user[0] ? project.user[0].email : '' }}</dd>
        </dl>
        <dl>
          <dt>ご担当者様2人目 氏名</dt>
          <dd>
            {{ project && project.user && project.user[1] ? project.user[1].name : '' }}
          </dd>
        </dl>
        <dl>
          <dt>ご担当者様2人目 メールアドレス</dt>
          <dd>
            {{ project && project.user && project.user[1] ? project.user[1].email : '' }}
          </dd>
        </dl>
        <dl>
          <dt>電話番号</dt>
          <dd>
            {{ project.client_tel }}
          </dd>
        </dl>
        <dl class="form_box_1_3_dl">
          <dt>住所</dt>
          <dd>
            <p>〒{{ project.zip_code }}</p>
            <p>{{ project.address1 }}{{ project.address2 }}</p>
          </dd>
        </dl>
      </div>

      <div class="form_box form_box_1_3">
        <div class="form_box_title">
          <p>申込内容</p>
        </div>
        <dl>
          <dt width="25%">実施方法</dt>
          <dd width="75%">
            <label v-if="project.medium_type == 3">Web {{project.web_people}}名 + 質問紙 {{project.paper_people}}名</label>
            <label v-if="project.medium_type == 2">質問紙 {{project.paper_people}}名</label>
            <label v-if="project.medium_type == 1">Web {{project.web_people}}名</label>
          </dd>
        </dl>
        <dl v-if="project.medium_type == 2 || project.medium_type == 3">
          <dt>質問紙の送付先拠点数</dt>
          <dd>
            <label v-if="project.paper_send_type == 1">1拠点</label>
            <label v-if="project.paper_send_type == 2">2拠点</label>
            <label v-if="project.paper_send_type == 3">3拠点以上</label>
            <!--{{ paper_send_types[project.paper_send_type] ?  paper_send_types[project.paper_send_type] : ''}}-->
          </dd>
        </dl>
        <dl v-if="project.paper_send_type == 1 || project.paper_send_type == 2">
          <dt>質問紙の送付先住所①</dt>
          <dd>
            <p>氏名: {{ project.paper1_name }}</p>
            <p>住所: 〒{{ project.paper1_zip_code }} {{ project.paper1_address1 }}{{ project.paper1_address2 }}{{ project.paper1_address3 }}</p>
          </dd>
        </dl>
        <!--<dl v-if="project.paper_send_type == 1 || project.paper_send_type == 2">-->
          <!--<dt>質問紙の送付部数①</dt>-->
          <!--<dd>{{ project.paper1_copies }}</dd>-->
        <!--</dl>-->
        <dl v-if="project.paper_send_type == 2">
          <dt>質問紙の送付先住所②</dt>
          <dd>
            <p>氏名: {{ project.paper2_name }}</p>
            <p>住所: 〒{{ project.paper2_zip_code}} {{ project.paper2_address1 }}{{ project.paper2_address2 }}{{ project.paper2_address3 }}</p>
          </dd>
        </dl>
        <dl>
          <dt class="top">回答スケジュール</dt>
          <dd class="fx">
            <div class="form_box_2_4">
              <p id="margin_left_20" class="form_box_2_4_p form_box_2_4_2_p">
                <span>対象者リスト提出締切日　{{deadlineSubmit | dateFormat}}</span>
              </p>
              <div v-if="project.medium_type == 1" class="form_box_360">
                <div class="margin_b_2_4 fx_ce row">
                  <p class="col col-140">Web診断開始日時</p>
                  <p class="col margin_l_2_4 stick_2_4">{{ project.web_start | datetimeFormat }}</p>
                </div>
                <div class="row margin_b_2_4 margin_b_2_3 fx_ce">
                  <p class="col-140">Web診断終了日時</p>
                  <p class="col margin_l_2_4">{{ project.web_end | datetimeFormat }}</p>
                </div>
              </div>
              <div v-if="project.medium_type == 2" class="form_box_360">
                <div class="margin_b_2_4 fx_ce row">
                  <p class="col col-140">質問紙到着希望日</p>
                  <p class="col margin_l_2_4 stick_2_4">{{ project.paper_preferred_date | dateFormat }}</p>
                </div>
                <div class="margin_b_2_4 fx_ce">
                  <p>質問紙提出期限(任意)</p>
                  <p class="margin_l_2_4 stick_2_4">{{ project.paper_limit_date | dateFormat }}</p>
                </div>
                <div class="margin_b_2_4 fx_ce row">
                  <p class="col col-140">質問紙BCon到着日</p>
                  <p class="col margin_l_2_4">{{ project.paper_bcon_receipt_date | dateFormat }}</p>
                </div>
              </div>
              <div v-if="project.medium_type == 3" class="form_box_360">
                <div class="margin_b_2_4 fx_ce row">
                  <p class="col col-140">Web診断開始日時</p>
                  <p class="col margin_l_2_4 stick_2_4">{{ project.web_start | datetimeFormat }}</p>
                </div>
                <div class="row margin_b_2_4 margin_b_2_3 fx_ce">
                  <p class="col-140">Web診断終了日時</p>
                  <p class="col margin_l_2_4">{{ project.web_end | datetimeFormat }}</p>
                </div>
                <div>
                  <hr>
                </div>
                <div class="margin_b_2_4 fx_ce row">
                  <p class="col col-140">質問紙到着希望日</p>
                  <p class="col margin_l_2_4 stick_2_4">{{ project.paper_preferred_date | dateFormat }}</p>
                </div>
                <div class="margin_b_2_4 fx_ce">
                  <p>質問紙提出期限(任意)</p>
                  <p class="margin_l_2_4 stick_2_4">{{ project.paper_limit_date | dateFormat }}</p>
                </div>
                <div class="margin_b_2_4 fx_ce row">
                  <p class="col col-140">質問紙BCon到着日</p>
                  <p class="col margin_l_2_4">{{ project.paper_bcon_receipt_date | dateFormat }}</p>
                </div>
              </div>
            </div>
          </dd>
        </dl>
        <dl>
          <dt>報告書納品希望日</dt>
          <dd>{{ project.report_preferred_date | dateFormat }}</dd>
        </dl>
        <dl class="form_box_1_3_dl">
          <dt>該当する業種・組織規模</dt>
          <dd>業種　　　：　{{ this.industry_types[project.industry_type] ? this.industry_types[project.industry_type] : '' }}<br>
            規模　　　：　{{ this.scale_types[project.scale_type] ? this.scale_types[project.scale_type] : '' }}</dd>
        </dl>
        <dl>
          <dt>備考欄</dt>
          <dd><div v-if="project && project.client_memo && project.client_memo.length > 0">
            <div v-for="line in project.client_memo.split('\n')">{{ line }}
            </div>
          </div>
            <div v-else>なし</div></dd>
        </dl>
        <!--<dl>-->
          <!--<dt>個人情報保管期間</dt>-->
          <!--<dd></dd>-->
        <!--</dl>-->
      </div>

      <div class="form_box form_box_1_3 form_box_5_2">
        <div class="form_box_title">
          <p>詳細情報</p>
        </div>
        <dl>
          <dt>組織名</dt>
          <dd>{{ project.org_name }}</dd>
        </dl>
        <dl>
          <dt>診断名</dt>
          <dd>{{ project.diagnosis_name }}</dd>
        </dl>
        <dl  v-if="project.project_type >= 11 && project.project_type <= 16">
          <dt>属性一覧の設定</dt>
          <dd>{{ attribute_setting_types[project.attribute_setting_type] ? attribute_setting_types[project.attribute_setting_type] : '' }}</dd>
        </dl>
        <dl class="form_box_1_3_dl">
          <dt>ID・パスワード設定</dt>
          <dd class="l_height">
            ID：　　　　{{ setting_types[project.id_setting_type] ? setting_types[project.id_setting_type] : '' }}<br>
            パスワード：{{ setting_types[project.pass_setting_type] ? setting_types[project.pass_setting_type] : '' }}
          </dd>
        </dl>
        <dl class="form_box_1_3_dl">
          <dt class="height_up_3_2">貴社お問合せ先</dt>
          <dd class="l_height">
            部署・役職 {{ project.inquiry_division }}<br>
            氏名 {{ project.inquiry_name }}<br>
            電話 {{ project.inquiry_tel }}<br>
            メール {{ project.inquiry_email }}<br>
          </dd>
        </dl>
        <dl v-if="project.medium_type != 1 && project.project_type >= 11 && project.project_type <= 16">
          <dt>質問紙提出先</dt>
          <dd>{{ project.paper_recipient }}</dd>
        </dl>
        <dl v-if="project.project_type == 11">
          <dt>業種別比較</dt>
          <dd>{{ project.comparison_industry_type ? '有り' : '無し' }}</dd>
        </dl>
        <dl>
          <dt>回答者への案内メール</dt>
          <dd>{{ guidance_email_types[project.guidance_email_type] ? guidance_email_types[project.guidance_email_type] : ''}}</dd>
        </dl>
        <dl v-if="project.project_type == 11">
          <dt>質問文の主語変更</dt>
          <dd>{{ project.question_subject_type }}</dd>
        </dl>
        <dl class="fx" v-if="((project.qestion_type == 1 || project.qestion_type == 2 && project.project_type > 11 && project.project_type <= 16))">
          <dt>追加質問文</dt>
          <dd class="padding_tb">
            {{ project.qestion_type == 1 ? '追加質問が5問以内/追加質問の尺度が左記の7点法を利用' : '追加質問が6問以上/追加質問の尺度が7点法以外(個別対応)'}}
            <div v-if="project.qestion_type == 1">
              <div v-for="(question, i) in project.questions">
                <div>【質問文】</div>
                <p>{{question.body}}</p>
              </div>
            </div>
          </dd>
        </dl>
        <!--<dl v-if="project.project_type >= 11 && project.project_type <= 16">-->
          <!--<dt class="hight_up_5_2">属性一覧の設定</dt>-->
          <!--<dd>-->
            <!--<p class="form_box_sub_5_2_p">-->
              <!--<a :href="link_download_pai+ project.id" v-if="parseFloat(project.progress_status) >= '2.1'">ダウンロード</a>-->
            <!--</p>-->
          <!--</dd>-->
        <!--</dl>-->
        <!--<dl v-if="project.project_type >= 11 && project.project_type <= 16">-->
          <!--<dt class="hight_up_5_2">対象者リスト</dt>-->
          <!--<dd>-->
            <!--<p class="form_box_sub_5_2_p">-->
              <!--<a  :href="link_download_ptp+ project.id+'?last=true'" v-if="parseFloat(project.progress_status) >= 3">ダウンロード</a>-->
            <!--</p>-->
          <!--</dd>-->
        <!--</dl>-->
        <!--<dl v-else>-->
          <!--<dt class="hight_up_5_2">{{ project.project_type >= 21 && project.project_type<= 24 ? '対象者リスト' : '属性一覧の設定' }}</dt>-->
          <!--<dd>-->
            <!--<p class="form_box_sub_5_2_p">-->
              <!--<a v-if="parseFloat(project.progress_status) >= 3 && project.project_type >= 1 && project.project_type<= 6" :href="link_download_ppt_invidual+ project.id">ダウンロード</a>-->
              <!--<a v-if="parseFloat(project.progress_status) >= 3 &&project.project_type >= 21 && project.project_type<= 24" :href="link_download_pmf+ project.id">ダウンロード</a>-->
             <!--</p>-->
          <!--</dd>-->
        <!--</dl>-->
      </div>

      <div class="footer_box footer_box_1_3">
        <p></p>
        <p class="btn">
          <input class="form_box_sub form_box_sub_2" type="button"
                 value="戻る" @click="backForm()">
        </p>
      </div>
    <!--</form>-->
  </div>
</template>
<script>
  import {mapActions, mapMutations, mapState} from 'vuex'
  import moment from 'moment'
  import {
    INDUSTRY_TYPE,
    MEDIUM_TYPE,
    ATTRIBUTE_SETTING_TYPE,
    SCALE_TYPE,
    SELECT_KUBUN_TYPE,
    SETTING_TYPE,
    GUIDENCE_EMAIL_TYPE,
    PROJECT_TYPE
  } from './../../../contstant'


  export default {
    data() {
      return {
        select_kubun_types: SELECT_KUBUN_TYPE,
        scale_types: SCALE_TYPE,
        industry_types: INDUSTRY_TYPE,
        medium_types: MEDIUM_TYPE,
        setting_types: SETTING_TYPE,
        guidance_email_types: GUIDENCE_EMAIL_TYPE,
        attribute_setting_types: ATTRIBUTE_SETTING_TYPE,
        HTML_NO: HTML_NO,
        deadlineSubmit: null,
        // link_download_pai: '/api/admin/pai-download',
        link_download_pai: '/api/admin/pai-export/',
        link_download_ptp: '/api/admin/ptp-export/',
        link_download_pmf: '/api/admin/pmf-export/',
        link_download_ppt_invidual: '/api/admin/ppt-download/',
      }
    },
    watch: {
      'project.progress_status': function() {
        if ((this.project.progress_status == 1 || this.project.progress_status == 0) && parseFloat(this.newStep) < 2) {
          this.$router.push({
            name: 'apply'
          })
        }
      },
      'project.web_start': function () {
        this.getDeadlineSubmit()
      },
      'project.report_preferred_date':  function () {
        if (this.holidays.length == 0) {
          this.GET_HOLIDAYS().then(() => {
            this.getDeadlineSubmit()
          })
        } else {
          this.getDeadlineSubmit()
        }
      }
    },
    computed: {
      ...mapState('consultantUser', ['user', 'project', 'holidays']),
      ...mapState('project', ['newStep'])
    },
    methods: {
      ...mapMutations('consultantUser', ['SET_HOLIDAYS']),
      ...mapActions('consultantUser', ['GET_HOLIDAYS']),
      backForm() {
        if (parseFloat(this.project.progress_status) >= 3) {
          this.$router.push({
            name: 'apply-completed'
          })
        } else {
          this.$router.push({
            name: 'apply'
          })
        }

      },
      projectType() {
        let type = ''
        if (PROJECT_TYPE[0][this.project.project_type]) {
          type = PROJECT_TYPE[0][this.project.project_type]
        } else if (PROJECT_TYPE[1][this.project.project_type]) {
          type = PROJECT_TYPE[1][this.project.project_type]
        }
        return type
      },
      getDeadlineSubmit() {
        let fiveDate = this.project.web_start
        let fiveDate2 = null
        if (this.project.web_start) {
          let days = this.getDates(moment(this.project.web_start).subtract(30, 'days').format('YYYY-MM-DD'), moment(this.project.web_start).subtract(1, 'days').format('YYYY-MM-DD'))
          days.sort(function (a, b) {
            return new Date(b) - new Date(a);
          });
          let i = 1
          _.forEach(days, (d) => {
            if (!this.holidays.includes(d) && i <= 5) {
              fiveDate = d
              i = i + 1
            }
          })

          fiveDate = moment(fiveDate).subtract(1, 'days').format('YYYY-MM-DD')
          while (this.holidays.includes(fiveDate)) {
            fiveDate = moment(fiveDate).subtract(1, 'days').format('YYYY-MM-DD')
          }
        }
        if (this.project.medium_type == 2 || this.project.medium_type == 3) {
          let infDate = this.project.paper_preferred_date

          if (infDate) {
            let paper_people = this.project.paper_people
            let date_medium2 = ''
            switch (true) {
              case (paper_people > 0 && paper_people <= 100):
                date_medium2 = 7
                break
              case (paper_people > 100 && paper_people <= 200):
                date_medium2 = 8
                break
              case (paper_people > 200 && paper_people <= 300):
                date_medium2 = 9
                break
              case (paper_people > 300 && paper_people <= 400):
                date_medium2 = 10
                break
              case (paper_people > 400 && paper_people <= 500):
                date_medium2 = 11
                break
              case (paper_people > 500):
                date_medium2 = 12
                break
              default:
                break
            }
            let days2 = this.getDates(moment(infDate).subtract(30, 'days').format('YYYY-MM-DD'), infDate)
            days2.sort(function(a,b){
              return new Date(b) - new Date(a);
            });
            let i = 0
            _.forEach(days2, (d) => {
              if (!this.holidays.includes(d) && i <= date_medium2) {
                fiveDate2 = d
                i = i + 1
              }
            })
          }
        }

        let deadline = ''
        if (moment(fiveDate).isValid() && moment(fiveDate2).isValid()) {
          if (fiveDate < fiveDate2) {
            deadline = fiveDate
          } else {
            deadline = fiveDate2
          }
        } else if (!moment(fiveDate).isValid() && moment(fiveDate2).isValid()) {
          deadline = fiveDate2
        } else if (moment(fiveDate).isValid() &&  !moment(fiveDate2).isValid()) {
          deadline = fiveDate
        }
        this.deadlineSubmit = moment(deadline).isValid() ? moment(deadline).format('YYYY-MM-DD') : ''

      },
      getDates(startDate, stopDate) {
        var dateArray = [];
        var currentDate = moment(startDate);
        var stopDate = moment(stopDate);
        while (currentDate <= stopDate) {
          dateArray.push( moment(currentDate).format('YYYY-MM-DD') )
          currentDate = moment(currentDate).add(1, 'days');
        }
        return dateArray;
      },
    },
    async created() {
     // await this.GET_HOLIDAYS()
    },
    mounted() {
      if (this.holidays.length == 0) {
        this.GET_HOLIDAYS().then(() => {
          this.getDeadlineSubmit()
        })
      } else {
        this.getDeadlineSubmit()
      }
    }
  }
</script>
<style scoped lang="scss">
</style>
