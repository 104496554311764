import axios from "axios";

export default {
  namespaced: true,
  state: {
    file_uploaded: {}
  },
  getters: {},
  mutations: {
    FILE_UPLOADED(state, file) {
        state.file_uploaded = file
	}
  },
  actions: {
		FILE_UPLOADED({commit}, file) {
			commit('FILE_UPLOADED', file)
    },
    async UPLOAD({commit, dispatch, state}, data) {
    	let formData = new FormData();
    	formData.append('file', state.file_uploaded);

			try {
				let res = await axios.post(data.url,
					formData, {
						headers: {
							'Content-Type': 'boundary=${file._boundary}'
						}
					});

				if (res.data.project) {
					commit('consultantUser/SET_PROJECT', res.data.project, {root: true})
				}
				return res.data;
			} catch (e) {
				console.log(e)
				return e.response.data
			}
    },
    
    async DOWNLOAD({commit, dispatch, state}, data) {
    	axios({
  	        method: 'get',
  	        url: data.url,
  	        responseType: 'arraybuffer'
  	      })
  	      .then(response => {
  	    	  const url = window.URL.createObjectURL(new Blob([response.data]))
		      const link = document.createElement('a')
		      link.href = url
		      link.setAttribute('download', data.filename ? data.filename : 'bcon-file.xlsx') //or any other extension
		      document.body.appendChild(link)
		      link.click()
  	      })
  	      .catch(() => {
  		  	console.log('error occured');
  		  });
    },
    
  }
}
