<template>
  <div class="container">
    <header-component></header-component>
    <div v-if="errors.length === 0">
      <div class="top_box_4_2" v-if="csvFile && csvFile.name">
        <div class="content_box_4_2">
          <p>以下のファイルをアップロードしてよろしいですか？</p>
          <p class="content_img_box_4_2"><img src="/images/up_exel.png" alt="アップロードファイル" srcset="/images/up_exel.png 1x, /images/up_exel@2x.png 2x"></p>
          <p class="content_box_4_2_p">{{ csvFile.name }}</p>
        </div>
      </div>

      <div class="footer_4_2">
        <p class="btn_1"><input class="form_box_sub form_box_sub_4_2" type="submit" value="ファイルを送信する" @click="uploadCsv()"></p>
        <p class="btn_2"><input type="file" id="02" name="03"><label for="02" id="input-label">ファイルを変更する</label></p>
      </div>
    </div>
    <div v-else>
      <div class="top_box_4_2">
        <div class="content_box_4_2 content_box_4_3">
          <p>ファイルに以下のエラーが見られました。<br>
            ご確認の上、再アップロードをお願いします。</p>
          <div class="attention_box" v-for="error in errors">
            <p class="attention">{{ error }}</p>
          </div>
        </div>
      </div>

      <div class="footer_4_2">
        <p class="btn btn_2_5 btn_3_3 btn_4_1 btn_4_3">
          <input size="60" class="form_box_sub" id="03" type="file" @change="changeFile" accept=".csv" ref="csvFile">
          <label for="03">ファイルを再アップロード</label></p>
      </div>
    </div>
  </div>
</template>
<script>
  import {mapState, mapActions, mapMutations} from 'vuex'

  export default {
    data() {
      return {
        errors: []
      }
    },
    computed: {
      ...mapState('consultantUser', ['csvFile'])
    },
    methods: {
      ...mapActions('consultantUser', ['SET_CSV_FILE', 'SET_SALES_LIST']),
      ...mapMutations('consultantUser', ['CSV_FILE']),
      changeFile(e) {
        let files = e.target.files || e.dataTransfer.files;

        if (files[0] && files[0].size > 0) {
          this.$store.dispatch('consultantUser/SET_CSV_FILE', files[0]).then(() => {
            this.uploadCsv()
          })
        }
      },
      async uploadCsv() {
        const csv = new FormData();
        csv.append("csv", this.csvFile);

        this.$wait.start('loading');
        try {
          let { data } = await axios.post('/api/admin/sales/import-csv',
            csv, {
              headers: {
                'Content-Type': 'boundary=${csv._boundary}'
              }
            });

          this.SET_SALES_LIST(data).then(() => {
            this.$router.push({
              name: 'SalesList'
            })
          })


          this.$store.dispatch('consultantUser/SET_CSV_FILE', {})
          this.$wait.end('loading');
          if (this.$refs.csvFile) {
            this.$refs.csvFile.value = null
          }

        } catch (e) {
          if (this.$refs.csvFile) {
            this.$refs.csvFile.value = null
          }
          this.errors = e.response.data.message
          this.$store.dispatch('consultantUser/SET_CSV_FILE', {})
          this.$wait.end('loading');
        }
      }
    },
    mounted() {
      console.log(this.csvFile.name)
      if (!this.csvFile.name) {
        console.log('xxxx')
        this.$router.push({
          name: 'SalesList'
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  .cur_pointer{
    cursor: pointer;
  }
</style>
