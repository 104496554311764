<template>
  <div class="body_1_2 container">
    <header-component v-if="!$route.params.id || $route.params.id && consultant.id" :title="'[管理者登録]'" :back="{ name: 'ConsultantUserList'}"></header-component>
    <header-component :title="'[コンサルタント編集]'" v-else></header-component>
    <ValidationObserver ref="observer" v-slot="{ invalid }" tag="form" @submit.prevent="previewRegister()" v-show="!$route.params.id || $route.params.id && consultant.id">
      <div class="form_box">
        <div class="form_box_title">
          <p>コンサルタント</p>
        </div>
        <dl>
          <dt class="required required81">ログインID</dt>
          <dd>
            <ValidationProvider name="id_login" :rules="{required: true, uniqueID: true, regex: /^([a-zA-Z0-9_-]){5,32}$/}" v-slot="{ errors }" class="validate">
              <input :class="{ 'form_text': true, 'error': errors[0] }" type="text" v-model="consultant.id_login"
                     placeholder="ログインID"
                     name="id_login">
              <br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>
            </ValidationProvider>
          </dd>
        </dl>
        <dl>
          <dt class="required required1">氏名</dt>
          <dd>
            <ValidationProvider name="consultant_name" rules="required" v-slot="{ errors }" class="validate">
              <input :class="{ 'form_text': true, 'error': errors[0] }" type="text" v-model="consultant.name"
                     placeholder="氏名"
                     name="name">
              <br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>
            </ValidationProvider>
          </dd>
        </dl>

        <dl>
          <dt class="required required81">メールアドレス</dt>
          <dd>
            <ValidationProvider :mode="submit ? 'eager' : 'passive'" name="emailSales" rules="required|email" v-slot="{ errors }" class="validate">
              <!--<ValidationProvider :mode="submit ? 'eager' : 'passive'" name="emailSales" rules="required|email|uniqueEmail" v-slot="{ errors }" class="validate">-->
              <input :class="{ 'form_text': true, 'error': errors[0] }" type="text" v-model="consultant.email"
                     placeholder="メールアドレス"
                     name="emailSales">
              <br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>
            </ValidationProvider>
          </dd>
        </dl>

        <dl>
          <dt class="required required81">パスワード</dt>
          <dd>
            <ValidationProvider name="password" :rules="!consultant.id ? 'required' : ''" v-slot="{ errors }" class="validate">
              <input :class="{ 'form_text': true, 'error': errors[0] }" type="password" v-model="consultant.password"
                     :placeholder="consultant.id ? '******' : 'パスワード'"
                     name="name">
              <br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>
            </ValidationProvider>
          </dd>
        </dl>

        <!--<dl>-->
          <!--<dt class="required required1">役職</dt>-->
          <!--<dd>-->
            <!--<ValidationProvider name="division" rules="required" v-slot="{ errors }" class="validate">-->
              <!--<input :class="{ 'form_text': true, 'error': errors[0] }" type="text" v-model="consultant.division"-->
                     <!--placeholder="役職"-->
                     <!--name="division">-->
              <!--<br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>-->
            <!--</ValidationProvider>-->
          <!--</dd>-->
        <!--</dl>-->

        <dl v-if="user.role && user.role.is_edit_c_user">
          <dt class="required required1">権限</dt>
          <dd>
            <ValidationProvider name="c_role_id" rules="required" v-slot="{ errors }" class="validate">
              <!--<input :class="{ 'form_text': true, 'error': errors[0] }" type="text" v-model="consultant.c_role_id"-->
              <!--placeholder="役職"-->
              <!--name="division">-->
              <select :class="{ 'form_text': true, 'error': errors[0] }" name="c_role_id" id="" v-model="consultant.c_role_id">
                <!--<option value="">権限</option>-->
                <option :value="role.id" v-for="role in roles">{{ role.name }}</option>
              </select>
              <br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>
            </ValidationProvider>
          </dd>
        </dl>
      </div>
      <div class="footer_box footer_box_1_3">
        <p class="btn2"><input @click="backList()" class="form_box_sub_blue form_box_sub_2" type="button"
                               value="前の画面に戻る"></p>
        <p class="btn3"><input class="form_box_sub form_box_sub_2" type="submit" value="保存"></p>
      </div>
    </ValidationObserver>
  </div>
</template>
<script>
  import { extend } from "vee-validate";
  import {mapActions, mapMutations, mapState} from 'vuex'

  export default {
    data() {
      return {
        is_preview: false,
        submit: false

      }
    },
    watch: {
      user() {
        if (this.user.id && (this.user.id != this.$route.params.id) && (_.isNull(this.user.role) || (!_.isNull(this.user.role) && !this.user.role.is_edit_c_user))) {
          this.$router.push({
            name: 'ConsultantUserList'
          })
        }
      }
    },
    methods: {
      async previewRegister() {
        const isValid = await this.$refs.observer.validate();
        this.submit = true
        if (!isValid) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.observer.errors)
              .map(([key, value]) => ({key, value}))
              .filter(error => error["value"].length);
            this.$refs.observer.refs[errors[0]["key"]].$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 100);
          return
          // ABORT!!
        }

        this.$wait.start('loading')
        this.$store.dispatch('consultantUser/CREATE_CONSULTANT_USER', this.consultant).then(() => {
          this.$router.push({
            name: 'ConsultantUserList'
          })
          this.$wait.end('loading')
        })
      },
      async isUsernameEmail() {
        try {
          if (this.$route.params.id) {
            let {data} = await axios.get('/api/admin/consultant/unique/'+this.consultant.email+'/'+this.$route.params.id);
          } else {
            let {data} = await axios.get('/api/admin/consultant/unique/'+this.consultant.email);
          }

          return true
        } catch (err) {
          return false;
        }
      },
      async isUniqueID() {
        try {
          if (this.$route.params.id) {
            let {data} = await axios.get('/api/admin/consultant/unique-id/'+this.consultant.id_login+'/'+this.$route.params.id);
          } else {
            let {data} = await axios.get('/api/admin/consultant/unique-id/'+this.consultant.id_login);
          }

          return true
        } catch (err) {
          return false;
        }
      },
      ...mapMutations('consultantUser', ['SET_CONSULTANT', 'SET_ROLES']),
      ...mapActions('consultantUser', ['GET_CONSULTANT_USER', 'SET_CONSULTANT_DETAULT', 'GET_ROLES']),
      backList() {
        this.$router.push({
          name: 'ConsultantUserList'
        })
      }
    },
    computed: {
      ...mapState('consultantUser', ['consultant', 'roles', 'user'])
    },
    created() {
      if (this.user && this.user.id && (this.user.id != this.$route.params.id) && ((_.isEmpty(this.user.role) || (!_.isEmpty(this.user.role) && !this.user.role.is_edit_c_user)))) {
        this.$router.push({
          name: 'ConsultantUserList'
        })
      }

      if (this.$route.params && this.$route.params.id !== undefined) {
        this.GET_CONSULTANT_USER(this.$route.params.id).then((data) => {
          if (_.isUndefined(this.consultant.id)) {
            this.$router.push({
              name: 'ConsultantUserList'
            })
          }
        }).catch(e => {
          this.$router.push({
            name: 'ConsultantUserList'
          })
        });
      } else {
        this.SET_CONSULTANT_DETAULT({});
      }
      this.GET_ROLES()
    },
    mounted() {
      if (this.$route.params.id) {
        extend("uniqueEmail", {
          validate: this.isUsernameEmail,
          message: "このメールは存在しました。"
        });
      } else {
        extend("uniqueEmail", {
          validate: this.isUsernameEmail,
          message: "このメールは存在しました。"
        });
      }

      extend("uniqueID", {
        validate: this.isUniqueID,
        message: "このログインIDは存在しました。"
      });


    }
  }
</script>
<style scoped lang="scss">
</style>

