<template>
  <div id="body6_1" v-if="user && user.id">
    <header-component :margin="false"></header-component>
    <div v-if="user && user.id" :style="cssProps">
      <div data-v-55dc702e="" class="attention">
        <p data-v-55dc702e="" v-for="error in holidayErrors">{{ error }}</p>
      </div>
      <div class="fx_st" v-if="user.role.is_edit_c_user">
        <p class="save_btn2 no-margin"><a :href="'javascript:;'" @click="exportHolidayCsv()">カレンダー(エクスポート)</a></p>
        <p class="save_btn2 no-margin"><label for="csv">カレンダー(インポート)</label><input ref="holiday" id="csv" type="file"
                                                                                   value="" @change="holidayImport()">
        </p>
      </div>
      <div id="title6_1" class="fx_st" v-if="user.role.is_edit_c_user">
        <p>
          <!--<span>2020年x月x日 00:00</span>-->
          <br>案件リスト</p>
        <p class="save_btn2"><a :href="'javascript:;'" @click="exportCsv()">CSV出力</a></p>
      </div>
    </div>
    <div class="box">
      <table>
        <thead :style="{width: 'calc(100vw - 100px)'}">
        <tr>
          <th
            :class="{ 'th_1 t_width_1 th_yaji index': true, 'down':  sortType == 'desc' && sortName == 'id' && sortName == 'id', 'up':  sortType == 'asc' && sortName == 'id' && sortName == 'id', 'normal-sort': sortName != 'id' || sortName == '' }"
            @click="changeSort('id')">申込No
          </th>
          <th
            :class="{ 'th_2 th_yaji t_width_2 index': true, 'down':  sortType == 'desc' && sortName == 'client_name', 'up':  sortType == 'asc' && sortName == 'client_name', 'normal-sort': sortName != 'client_name' || sortName == '' }"
            @click="changeSort('client_name')">クライアント名
          </th>
          <th
            :class="{ 'th_3 th_yaji t_width_3 index': true, 'down':  sortType == 'desc' && sortName == 'project_type', 'up':  sortType == 'asc' && sortName == 'project_type', 'normal-sort': sortName != 'project_type' || sortName == '' }"
            @click="changeSort('project_type')">診断名
          </th>
          <th></th>
          <th ref="head"
              :class="{ 'th_17 th_yaji t_width_4': true, 'down':  sortType == 'desc' && sortName == 'staff_name', 'up':  sortType == 'asc' && sortName == 'staff_name', 'normal-sort': sortName != 'staff_name' || sortName == ''}"
              @click="changeSort('staff_name')">担当営業
          </th>
          <th
            :class="{ 'th_4 th_yaji t_width_4': true, 'down':  sortType == 'desc' && sortName == 'medium_type', 'up':  sortType == 'asc' && sortName == 'medium_type', 'normal-sort': sortName != 'medium_type' || sortName == '' }"
            @click="changeSort('medium_type')">形式 (人数)
          </th>
          <th
            :class="{ 'th_5 th_yaji t_width_5': true, 'down':  sortType == 'desc' && sortName == 'progress_status', 'up':  sortType == 'asc' && sortName == 'progress_status', 'normal-sort': sortName != 'progress_status' || sortName == '' }"
            @click="changeSort('progress_status')">ステータス
          </th>
          <th
            :class="{ 'th_6 th_yaji t_width_5': true, 'down':  sortType == 'desc' && sortName == 'preentry_alert_date', 'up':  sortType == 'asc' && sortName == 'preentry_alert_date', 'normal-sort': sortName != 'preentry_alert_date' || sortName == '' }"
            @click="changeSort('preentry_alert_date')">促進メール<br>(開始日)
          </th>
          <th
            :class="{ 'th_7 th_yaji t_width_5': true, 'down':  sortType == 'desc' && sortName == 'web_start', 'up':  sortType == 'asc' && sortName == 'web_start', 'normal-sort': sortName != 'web_start' || sortName == '' }"
            @click="changeSort('web_start')">Web回答<br>開始日時
          </th>
          <th
            :class="{ 'th_8 th_yaji t_width_5 no-wrap': true, 'down':  sortType == 'desc' && sortName == 'web_end', 'up':  sortType == 'asc' && sortName == 'web_end', 'normal-sort': sortName != 'web_end' || sortName == '' }"
            @click="changeSort('web_end')">Web回答<br>終了日時
          </th>
          <th
            :class="{ 'th_9 th_yaji t_width_5 no-wrap normal': true, 'down':  sortType == 'desc' && sortName == 'web_end', 'up':  sortType == 'asc' && sortName == 'paper_preferred_date', 'normal-sort': sortName != 'paper_preferred_date' || sortName == '' }"
            @click="changeSort('paper_preferred_date')">
            質問紙到着<br>希望日
          </th>
          <th
            :class="{ 'th_10 th_yaji t_width_5 normal': true, 'down':  sortType == 'desc' && sortName == 'paper_bcon_receipt_date', 'up':  sortType == 'asc' && sortName == 'paper_bcon_receipt_date', 'normal-sort': sortName != 'paper_bcon_receipt_date' || sortName == '' }"
            @click="changeSort('paper_bcon_receipt_date')">質問紙BCon<br>到着日
          </th>
          <th
            :class="{ 'th_11 th_yaji t_width_5': true, 'down':  sortType == 'desc' && sortName == 'report_preferred_date', 'up':  sortType == 'asc' && sortName == 'report_preferred_date', 'normal-sort': sortName != 'report_preferred_date' || sortName == '' }"
            @click="changeSort('report_preferred_date')">納品希望日
          </th>
          <th :class="{ 'th_12 th_yaji t_width_5': true, 'down':  sortType == 'desc' && sortName == 'submit_deadline', 'up':  sortType == 'asc' && sortName == 'submit_deadline', 'normal-sort': sortName != 'submit_deadline' || sortName == '' }"
              @click="changeSort('submit_deadline')">対象者リスト提出 <br>締切日</th>
          <th
            :class="{ 'th_13 th_yaji t_width_5': true, 'down':  sortType == 'desc' && sortName == 'postentry_alert_date', 'up':  sortType == 'asc' && sortName == 'postentry_alert_date', 'normal-sort': sortName != 'postentry_alert_date' || sortName == '' }"
            @click="changeSort('postentry_alert_date')">促進メール<br>(リスト)
          </th>
          <th
            :class="{ 'th_14 th_yaji t_width_6': true, 'down':  sortType == 'desc' && sortName == 'target_employee_file_name', 'up':  sortType == 'asc' && sortName == 'target_employee_file_name', 'normal-sort': sortName != 'target_employee_file_name' || sortName == '' }"
            @click="changeSort('target_employee_file_name')">リスト
          </th>
          <th :class="{ 'th_15 t_width_7': true }">キャンセル</th>
        </tr>
        </thead>
        <tbody ref="tbody" id="tbody" :style="{width: 'calc(100vw - 100px)'}">
        <tr v-for="(p, i) in projects.data" v-show="projects.data && projects.data.length > 0">
          <td class="th_1_2 t_width_1 white">{{ p.id }}</td>
          <td class="th_yaji t_width_2 white">
            <router-link :to="{ name: 'ProjectDetail', params: { id: p.id }  }">{{ p.client_name }}
            </router-link>
          </td>
          <td class="t_width_3 white">{{ projectType(p.project_type) }}</td>
          <td></td>
          <td class="t_width_4 th_center no-wrap">{{ p.staff_name }}</td>
          <td class="t_width_4">
            <div v-if="p.medium_type == 1">
              <span>Web {{ p.web_people ? p.web_people+'名' : '' }}</span>
            </div>
            <div v-if="p.medium_type == 2">
              <span>質問紙 {{ p.paper_people ? p.paper_people+'名' : '' }}</span>
            </div>
            <div v-if="p.medium_type == 3">
              <span v-if="p.web_people">Web {{ p.web_people ? p.web_people+'名' : '' }}<br></span>
              <span v-if="p.paper_people">質問紙 {{ p.paper_people ? p.paper_people+'名' : '' }}</span>
            </div>

          </td>
          <td class="t_width_5 th_center"><p :class="'sta_'+(parseInt(p.progress_status) + 1)"><span
            v-html="projectStatusNumber(p.progress_status)"></span> {{
            projectStatus(p.progress_status) }}</p></td>
          <td :class="{ 't_width_5': true, 'th_no': !p.preentry_alert_date } ">{{ p.preentry_alert_date}}<span v-if="p.preentry_alert_date"></span>
          </td>
          <td class="t_width_5 no-wrap"><span v-html="datetimeFormat(p.web_start)"></span></td>
          <td class="t_width_5 no-wrap"><span v-html="datetimeFormat(p.web_end)"></span></td>
          <td :class="{'t_width_5 normal': true, 'th_naname': !p.paper_preferred_date}  ">{{ p.paper_preferred_date |
            dateFormat}}
          </td>
          <td :class="{'t_width_5 normal': true, 'th_naname': !p.paper_bcon_receipt_date }">{{
            p.paper_bcon_receipt_date | dateFormat}}
          </td>
          <td class="t_width_5">{{ p.report_preferred_date | dateFormat}}</td>
          <td class="t_width_5">{{ p.submit_deadline | dateFormat }}</td>
          <td :class="{ 't_width_5': true, 'th_no': p.past } ">{{ p.postentry_alert_date && !p.past ? p.postentry_alert_date : (p.past ? '' : 'STEP1未登録') }}
          </td>
          <td :class="{'t_width_6': true, 'th_maru': p.file} "></td>
          <td class="t_width_7 th_center"><p class="d_btn"><a href="javascript:;"
                                                              @click="openDelete(p.id, p.client_name)">削除</a></p></td>
        </tr>
        </tbody>
      </table>
      <pagination-component :current-page="projects.current_page"
                            :per-page="projects.per_page"
                            :total="projects.total"
                            @changePage="changePage"
                            v-if="projects.total > 1"
      ></pagination-component>
      <b-modal name="deletePopup" id="deletePopup" ref="deletePopup" :modal-class="['modal-action']" :centered="true"
               :no-close-on-backdrop="true" header-close-label="" hide-footer hide-header>
        <h5 class="title">{{dName}}を削除しますか？</h5>
        <div class="modal-footer top-30">
          <b-button class="btn-submit" @click="$bvModal.hide('deletePopup')">キャンセル</b-button>
          <a class="btn btn-red" @click="deleteProject()">削除</a>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
  import {mapActions, mapMutations, mapState} from 'vuex'
  import {MEDIUM_TYPE, PROJECT_STATUS, PROJECT_TYPE} from './../../../contstant'
  import moment from 'moment'

  export default {
    data() {
      return {
        dId: null,
        dName: null,
        page: 1,
        sortName: '',
        sortType: '',
        medium_types: MEDIUM_TYPE,
        href: '',
        holidayErrors: [],
        head: 0,
        tblTitle: {
          position: 'absolute',
          top: 0,
          // width: 'calc(100% - 360px)',
          // overflow: 'hidden'
        },
        tblTitleF: {
          position: 'absolute',
          top: 0
        },
        scrollLeft: 0,
        scrollTopY: 0,
        windowWidth : window.innerWidth - 100
      }
    },
    computed: {
      ...mapState('project', ['projects']),
      ...mapState('consultantUser', ['user']),
      cssProps() {
        let head = 0
        this.$nextTick(() => {
          head = this.$refs.hea !== undefined ? this.$refs.head.clientHeight + 'px' : '0'
        })
        return {
          '--table-head': this.head,
        }
      },
    },
    methods: {
      ...mapMutations('project', ['LIST_PROJECTS']),
      ...mapActions('project', ['GET_LIST_PROJECTS']),
      isEmpty(val) {
        return _.isEmpty(val)
      },
      projectType(project_type) {
        let type = ''
        if (PROJECT_TYPE[0][project_type]) {
          type = PROJECT_TYPE[0][project_type]
        } else if (PROJECT_TYPE[1][project_type]) {
          type = PROJECT_TYPE[1][project_type]
        }
        return type
      },
      projectStatus(status) {
        let s = parseInt(status)
        return PROJECT_STATUS[s] ? PROJECT_STATUS[s] : ''
      },
      projectStatusNumber(status) {
        let text = ''
        let newStatus = parseInt(status) + 1
        switch (newStatus) {
          case 1:
            text = '&#9312;'
            break
          case 2:
            text = '&#9313;'
            break
          case 3:
            text = '&#9314;'
            break
          case 4:
            text = '&#9315;'
            break
          case 5:
            text = '&#9316;'
            break
          default:
        }
        return text
      },
      datetimeFormat(date) {
        const day = moment(date).isValid() ? moment(date).format("YYYY年MM月DD日") : ''
        const time = moment(date).isValid() ? moment(date).format("HH:mm") : ''
        return day + '<br>' + time
      },
      dateFormat(date) {
        return moment(date).isValid() ? moment(date).format("YYYY年MM月DD日") : ''
      },
      changePage(page) {
        this.page = page;
        this.refreshList(true);
      },
      changeSort(columnName){
        this.sortName = columnName;
        this.sortType = this.sortType == '' ? 'desc' : this.sortType == 'desc' && this.sortName == columnName ? 'asc' : 'desc';

        this.refreshList(true);
        this.$forceUpdate()
      },
      refreshList(sort = false){
        let query = {};
        if(this.page){
          query['page'] = this.page;
        };
        if(this.sortName){
          query['sortName'] = this.sortName;
          query['sortType'] = this.sortType;
        }
        if (sort) {
          this.$router.push({name: 'ProjectList', query: query});
        }
        this.$store.dispatch('project/PROJECT_QUERY', query)
        this.GET_LIST_PROJECTS({query : query});
      },
      openDelete(id, name) {
        this.$refs['deletePopup'].show()
        this.dId = id
        this.dName = name
      },
      deleteProject() {
        this.$store.dispatch('project/DELETE_PROJECT', this.dId).then(() => {
          this.refreshList();
          this.$refs.deletePopup.hide()
        })
        // this.$refs['deletePopup'].hide()
      },
      exportCsv() {
        axios({
          url: '/api/admin/projects/export-csv',
          method: 'GET',
          responseType: 'blob', // important
        }).then((response) => {
          // const url = window.URL.createObjectURL(new Blob([response.data]));
          // const link = document.createElement('a');
          // link.href = url;
          // link.setAttribute('download', '企業診断_案件リスト.csv');
          // document.body.appendChild(link);
          // link.click();
          let filename = "企業診断_案件リスト.csv";
          // var data = "some data";
          let blob = new Blob([response.data], { type: 'text/csv' });
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, filename);
          }
          else {
            var elem = window.document.createElement('a');
            elem.href = window.URL.createObjectURL(blob);
            elem.download = filename;
            document.body.appendChild(elem);
            elem.click();
            document.body.removeChild(elem);
          }

        });
        // axios.get('/api/admin/sales/export-csv')
        // this.href = '/api/admin/sales/export-csv?'
      },
      exportHolidayCsv() {
        axios({
          url: '/api/admin/calendar/export-csv',
          method: 'GET',
          responseType: 'blob', // important
        }).then((response) => {

          let filename = "休業日.csv";
          // var data = "some data";
          let blob = new Blob([response.data], { type: 'text/csv' });
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, filename);
          }
          else {
            var elem = window.document.createElement('a');
            elem.href = window.URL.createObjectURL(blob);
            elem.download = filename;
            document.body.appendChild(elem);
            elem.click();
            document.body.removeChild(elem);
          }

          // const url = window.URL.createObjectURL(new Blob([response.data]));
          // const link = document.createElement('a');
          // link.href = url;
          // link.setAttribute('download', '営業日.csv');
          // document.body.appendChild(link);
          // link.click();
        });
      },
      async holidayImport() {
        const csv = new FormData();
        csv.append("csv", this.$refs.holiday.files[0]);

        this.$wait.start('loading');
        try {
          let {data} = await axios.post('/api/admin/calendar/import-csv',
            csv, {
              headers: {
                'Content-Type': 'boundary=${csv._boundary}'
              }
            });
          this.$refs.holiday.value = null
          this.$wait.end('loading');
        } catch (e) {
          this.holidayErrors = e.response.data.message
          this.$wait.end('loading');
          this.$refs.holiday.value = null
        }
      },
      scrollMe(e) {
        const el = document.querySelector('#inner_table');
        let coordinate = el.getBoundingClientRect()
        this.scrollLeft = 420 - coordinate.left
        this.scrollTopY = coordinate.top - (290 - document.documentElement.scrollTop) + 'px'
        let left = 410 - this.scrollLeft
        this.tblTitle.left = left + 'px'
        this.$forceUpdate()
      },
      scrollHorizontal() {
        this.tblTitle.position = 'absolute'
      },
      scrollTbody(e) {
        $('thead').css("left", -$("tbody").scrollLeft()); //fix the thead relative to the body scrolling

        $('thead th:nth-child(1)').css("left", $("tbody").scrollLeft()); //fix the first cell of the header
        $('tbody td:nth-child(1)').css("left", $("tbody").scrollLeft()); //fix the first column of tdbody


        $('thead th:nth-child(2)').css("left", 70 + $("tbody").scrollLeft()); //fix the first cell of the header
        $('tbody td:nth-child(2)').css("left", 70 + $("tbody").scrollLeft()); //fix the

        $('thead th:nth-child(3)').css("left", 320 + $("tbody").scrollLeft()); //fix the first cell of the header
        $('tbody td:nth-child(3)').css("left", 320 + $("tbody").scrollLeft()); //fix the

      },
      getWindowWidth() {
        this.windowWidth = window.innerWidth
      }
    },
    created() {
      this.windowWidth = window.innerWidth - 100
      this.$forceUpdate()
      this.page = this.$route.query.page ? this.$route.query.page : 1;
      this.sortName = this.$route.query.sortName  ? this.$route.query.sortName : '';
      this.sortType = this.$route.query.sortType  ? this.$route.query.sortType : '';
      this.refreshList();
    },
    mounted() {
      this.projectType()
    },
    updated() {
      // console.log('&&&&&&')
      this.$nextTick(() => {
        // this.$refs.inner_table.addEventListener('scroll', this.scrollMe)
        // this.$refs.table.addEventListener('scroll', this.scrollHorizontal)
        // this.head = this.$refs.head !== undefined ? this.$refs.head.clientHeight + 'px' : '0'
        // this.$forceUpdate()
        // console.log(this.$refs.head)

        this.$refs.tbody.addEventListener('scroll', this.scrollTbody)
        window.addEventListener('resize', this.getWindowWidth);

      })
    }

  }
</script>
<style scoped lang="scss">
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
  }

  table {
    position: relative;
    width: 100%;
    overflow: hidden;
    border-collapse: collapse;
  }


  /*thead*/
  thead {
    position: relative;
    display: block;
    width: 700px;
    overflow: visible;
  }

  thead th {
    min-width: 120px;
    border: 1px solid #707070;
    font-size: 12px;
    font-family:  "ヒラギノ角ゴ ProN W6", HiraKakuProN-W6, メイリオ, Meiryo, Verdana, Helvetica, Arial, sans-serif;
  }

  thead th:nth-child(1) {
    left: 0;
    min-width: 70px;
    padding-left: 5px;
  }
  thead th:nth-child(1), thead th:nth-child(2), thead th:nth-child(3) {
    position: absolute;
    display: block;
    z-index: 100;
    border-bottom: 0;
  }

  thead th:nth-child(2) {
    left: 70px;
    min-width: 250px;
  }
  thead th:nth-child(3) {
    left: 320px;
    min-width: 160px;
  }
  thead th:nth-child(4) {
    min-width: 480px;
  }


  /*tbody*/
  tbody {
    position: relative;
    display: block;
    width: 700px;
    height: calc(100vh - 450px);
    overflow: scroll;
  }

  tbody td {
    height: 84px;
    min-width: 120px;
    border: 1px solid #707070;
    font-size: 12px;
    font-family:  "ヒラギノ角ゴ ProN W6", HiraKakuProN-W6, メイリオ, Meiryo, Verdana, Helvetica, Arial, sans-serif;
    vertical-align: middle;
  }

  tbody tr td:nth-child(1), tbody tr td:nth-child(2), tbody tr td:nth-child(3) {
    position: absolute;
    display: block;
    z-index: 10;
    background-color: #fff;
    border-bottom-color: transparent;
    border-right-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 83px;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      border-top: 0;
    }
  }
  tbody tr td:nth-child(1) {
    left: 0;
    min-width: 70px;
  }
  tbody tr td:nth-child(2) {
    left: 70px;
    min-width: 250px;
    word-break: break-all;
    max-width: 250px;
  }
  tbody tr td:nth-child(3) {
    left: 320px;
    min-width: 160px;
    border-right: 1px solid #707070;
  }
  tbody tr td:nth-child(4) {
    min-width: 480px;
  }
  thead th {
    height: 62px;
    vertical-align: middle;
    color: #fff !important;
    border-bottom: 0;
  }

  thead td {
    height: 84px;
    vertical-align: middle;
  }

  .th_3, .th_2, .th_1 {
    line-height: 62px;
  }

  .box {
    overflow: hidden;
    margin-top: 30px;
  }

  .white {
    background-color: #fff !important;
  }

  .no-wrap {
    white-space: nowrap;
    word-break: break-all;
  }
  .save_btn2 {
    &.no-margin {
      margin-right: 10px !important;
      margin-bottom: 20px !important;
    }

    label {
      text-decoration: none;
      color: #fff;
      font-size: 18px;
      border-radius: 5px;
      padding: 7px 24px 7px 50px;
      background: linear-gradient(to bottom, #d5d5d5, #717171);
      display: inline;
    }

    input {
      /*display: none*/
      opacity: 0;
    }
  }

  .attention {
    margin: 0;
    margin-bottom: 15px;
    width: 100%;
  }
  .th_1_2 {
    text-align: center;
  }
  .th_no {
    line-height: 84px;
    &:before {
      display: flex;
      align-items: center;
      position: relative;
      justify-content: center;
    }
  }
  .th_maru {
    &:before {
      position: relative;
      text-align: center;
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
    }
  }
  /*.t_width_5 {*/
    /*&.th_no {*/
      /*display: flex;*/
      /*align-items: center;*/
    /*}*/
    /*&.th_maru {*/
      /*display: flex;*/
      /*align-items: center;*/
    /*}*/
  /*}*/
</style>
